/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useContext, useState} from 'react'
import {Link, useNavigate} from 'react-router-dom'
import { logoutApi } from '../../../../app/modules/auth/core/_requests'
import { UserContext } from '../../../../contextStore'

const HeaderUserMenu: FC = () => {
  // const {currentUser, logout} = useAuth()
  const navigate = useNavigate()
  const {setMessage, removeAuth, token} = useContext(UserContext); 

  const logout = () => {
    try {
      logoutApi(token)
      setMessage("success", "Logout Successfully")
      localStorage.clear()
      removeAuth()
      navigate("/login")
    }
    catch (err) {
      setMessage("success", "Logout Successfully")
      localStorage.clear()
      removeAuth()
      navigate("/login")
    }
  }

  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
      data-kt-menu='true'
    >
     

      <div className='menu-item px-5'>
        <Link to={'/crafted/pages/profile'} className='menu-link px-5'>
          My Profile
        </Link>
      </div>

    

      <div className='separator my-2'></div>

      {/* <Languages /> */}

      <div className='menu-item px-5 my-1'>
        <Link to='/crafted/account/settings' className='menu-link px-5'>
          Account Settings
        </Link>
      </div>

      <div className='menu-item px-5'>
        <a onClick={(e) => {
          e.preventDefault()
          logout()
        }} className='menu-link px-5'>
          Sign Out
        </a>
      </div>
    </div>
  )
}

export {HeaderUserMenu}
