import axios, {AxiosResponse} from 'axios'
// import {ID, Response} from '../../../../../../_metronic/helpers'
import {Requestuser, RequestusersQueryResponse} from './_models'
import { ID, Response } from '../../../../../../_metronic/helpers'
// import { ID, Response } from '../../../../../_metronic/helpers'
import { apiUrl } from '../../../../../../apiurl'
const API_URL = apiUrl
const REQUESTUSER_URL = `${API_URL}/requestuser`
const GET_REQUESTUSERS_URL = `${API_URL}/Users/userlist`
const CHANGESREQ_URL =`${API_URL}/requestusers/`

const getRequestusers = (query: string): Promise<RequestusersQueryResponse> => {
  return axios
    .get(`${GET_REQUESTUSERS_URL}?${query}`)
    .then((d: AxiosResponse<any>) => d.data).catch((e) => console.log(e))
}

const getRequestuserById = (id: ID): Promise<Requestuser | undefined> => {
  return axios
    .get(`${REQUESTUSER_URL}/${id}`)
    // .then((response: AxiosResponse<Response<Requestuser>>) => response.data).catch((e) => console.log(e))
    .then((response: Response<any>) => response.data).catch((e) => console.log(e))
}

const createRequestuser = (requestuser: Requestuser): Promise<Requestuser | undefined> => {
  return axios
    .put(REQUESTUSER_URL, requestuser)
    // .then((response: AxiosResponse<Response<Requestuser>>) => response.data).catch((e) => console.log(e))
    .then((response: Response<any>) => response.data).catch((e) => console.log(e))
}

const updateRequestuser = (requestuser: Requestuser): Promise<Requestuser | undefined> => {
  return axios
    .post(`${REQUESTUSER_URL}/${requestuser.id}`, requestuser)
    // .then((response: AxiosResponse<Response<Requestuser>>) => response.data).catch((e) => console.log(e))
    .then((response: Response<any>) => response.data).catch((e) => console.log(e))
}

const deleteRequestuser = (requestuserId: ID): Promise<void> => {
  return axios.delete(`${REQUESTUSER_URL}/${requestuserId}`).then(() => {})
}

const deleteSelectedRequestusers = (requestuserIds: Array<ID>): Promise<void> => {
  const requests = requestuserIds.map((id) => axios.delete(`${REQUESTUSER_URL}/${id}`))
  return axios.all(requests).then(() => {})
}

const changesReqAPI= (exitinguser: any): Promise<any> => {
  return axios
    .post(`${CHANGESREQ_URL}changeuserrequeststatus`, exitinguser)
    // .then((response: AxiosResponse<Response<Exitinguser>>) => response.data).catch((e) => console.log(e))
    .then((response: Response<any>) => response)
}

export {getRequestusers, deleteRequestuser,changesReqAPI , deleteSelectedRequestusers, getRequestuserById, createRequestuser, updateRequestuser}
