import axios, {AxiosResponse} from 'axios'
// import {ID, Response} from '../../../../../../_metronic/helpers'
import {Groupchat, GroupchatsQueryResponse} from './_models'
import {ID, Response} from '../../../../../../_metronic/helpers'
import {apiUrl} from '../../../../../../apiurl'
const API_URL = apiUrl
const POST_GROUP_CHAT_DATA = `${API_URL}/groups/cometChatGroupCreate`
const GROUPCHAT_URL = `${API_URL}/groupchat`
const GET_GROUPCHATS_URL = `${API_URL}/groups/getGroupListingCometChat`
const ALL_COMETCHAT_USER = `${API_URL}/users/allCometChatUser`
const DELETE_COMETCHAT_GROUP = `${API_URL}/groups/chatGroupDelete`
const GET_FIND_ONE_CHAT_GROUP = `${API_URL}/groups/chatGroupFindOne`
const UPDATE_CHAT_GROUP = `${API_URL}/groups/cometChatGroupUpdate`
// cometChatGroupUpdate

const getGroupchats = (query: string): Promise<any> => {
  return axios
    .get(`${GET_GROUPCHATS_URL}?${query}&searchKey=name`)
    .then((d: any) => d.data)
    .catch((e) => console.log(e))
}

const getGroupchatById = (id: ID): Promise<Groupchat | undefined> => {
  return (
    axios
      .get(`${GROUPCHAT_URL}/${id}`)
      // .then((response: AxiosResponse<Response<Groupchat>>) => response.data).catch((e) => console.log(e))
      .then((response: Response<any>) => response.data)
      .catch((e) => console.log(e))
  )
}

// chatGroupFindOne
const getGroup = (id: ID): Promise<Groupchat | undefined> => {
  return (
    axios
      .get(`${GROUPCHAT_URL}/${id}`)
      // .then((response: AxiosResponse<Response<Groupchat>>) => response.data).catch((e) => console.log(e))
      .then((response: Response<any>) => response.data)
      .catch((e) => console.log(e))
  )
}

const updateChatGroup = (id: any, body: any) => {
  return axios
    .patch<any>(`${UPDATE_CHAT_GROUP}/${id}`, body)
    .then((responce: any) => {
      return responce
    })
    .catch((error) => {
      console.error('Error :', error)
      throw error
    })
}

const createGroupChatParticipet = (body: any) => {
  return axios
    .post<any>(`${POST_GROUP_CHAT_DATA}`, body)
    .then((responce: any) => {
      return responce
    })
    .catch((error) => {
      console.error('Error :', error)
      throw error
    })
}

const getAllCometchatUserListing = (query: string): Promise<any> => {
  return axios
    .get(`${ALL_COMETCHAT_USER}?${query}`)
    .then((d: any) => d.data)
    .catch((e) => console.log(e))
}

const createGroupchat = (groupchat: Groupchat): Promise<Groupchat | undefined> => {
  return (
    axios
      .put(GROUPCHAT_URL, groupchat)
      // .then((response: AxiosResponse<Response<Groupchat>>) => response.data).catch((e) => console.log(e))
      .then((response: Response<any>) => response.data)
      .catch((e) => console.log(e))
  )
}
// findOne data
const getFindOneData = (id: any) => {
  return axios.get<any>(`${GET_FIND_ONE_CHAT_GROUP}/${id}`).then((res: any) => {
    return res
  })
}

const updateGroupchat = (groupchat: Groupchat): Promise<Groupchat | undefined> => {
  return (
    axios
      .post(`${GROUPCHAT_URL}/${groupchat.id}`, groupchat)
      // .then((response: AxiosResponse<Response<Groupchat>>) => response.data).catch((e) => console.log(e))
      .then((response: Response<any>) => response.data)
      .catch((e) => console.log(e))
  )
}

const deleteGroupchat = (groupchatId: ID): Promise<void> => {
  return axios.delete(`${GROUPCHAT_URL}/${groupchatId}`).then(() => {})
}

const deleteSelectedGroupchats = (groupchatIds: Array<ID>): Promise<void> => {
  const requests = groupchatIds.map((id) => axios.delete(`${GROUPCHAT_URL}/${id}`))
  return axios.all(requests).then(() => {})
}

const deleteCometChatGroupmanagement = (id: any): Promise<void> => {
  return axios.delete(`${DELETE_COMETCHAT_GROUP}/${id}`).then(() => {})
}

export {
  getGroupchats,
  deleteGroupchat,
  deleteSelectedGroupchats,
  getGroupchatById,
  createGroupchat,
  updateGroupchat,
  createGroupChatParticipet,
  getAllCometchatUserListing,
  deleteCometChatGroupmanagement,
  getFindOneData,
  updateChatGroup,
}
