

import { ID, Response } from "../../../../../../_metronic/helpers"

export type Notification = {
  id?: ID
  name?: string
  avatar?: string
  email?: string
  position?: string
  role?: string
  sr_no?:string
  notification_name?:string
 
}

export type NotificationsQueryResponse = Response<Array<Notification>>

export const initialNotification: Notification = {
  avatar: 'avatars/300-6.jpg',
  position: 'Art Director',
  role: 'Administrator',
  name: '',
  email: '',

}
