import React, {useEffect, useState} from 'react'
import {Form} from 'react-bootstrap'
import {useNavigate, useParams} from 'react-router-dom'
import Multiselect from 'multiselect-react-dropdown'
import {apiUrl} from '../../../apiurl'
import axios from 'axios'
import {checkDisplayValue} from '../../../utils'

const API_URL = apiUrl
const ViewVertical = () => {
  const {id} = useParams()
  const [data, setData] = useState<any>([])
  const [isLoading, setIsLoading] = useState(true)
  const Vertical_one_url = `${API_URL}/verticals/findOneVertical`

  const navigate = useNavigate()
  const navigateVerticalListing = () => {
    // 👇️ navigate to /
    navigate('/vertical')
  }

  useEffect(() => {
    fetchData()
  }, [])

  const fetchData = async () => {
    try {
      setIsLoading(true)
      const res = await axios.get(`${Vertical_one_url}/${id}`)
      setData(res?.data ?? [])
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      console.log('Error -->:', error)
    }
  }

  console.log('Data Users:', data)

  const styles = {
    borderRadius: '0.475rem',
    boxShadow: '0 0 50px 0 rgb(82 63 105 / 15%)',
    backgroundColor: '#fff',
    color: '#7e8299',
    fontWeight: '500',
    margin: '0',
    width: 'auto',
    padding: '1rem 2rem',
    top: 'calc(50% - 2rem)',
    left: 'calc(50% - 4rem)',
  }

  if (isLoading) {
    return <div style={{...styles, position: 'absolute', textAlign: 'center'}}>Processing...</div>
  }

  if (data?.point?.length > 0) {
    var tracksheet = data?.point?.find((point: any) => point?.name === 'tracksheet')

    if (tracksheet) {
      var tracksheetvalue = tracksheet?.value
    }

    var thankyou_card_given = data?.point?.find(
      (point: any) => point?.name === 'thankyou_card_given'
    )

    if (thankyou_card_given) {
      var thankyou_card_given_value = thankyou_card_given?.value
    }

    var thankyou_card_received = data?.point?.find(
      (point: any) => point?.name === 'thankyou_card_received'
    )

    if (thankyou_card_received) {
      var thankyou_card_received_value = thankyou_card_received?.value
    }

    var visitor_attended = data?.point?.find((point: any) => point?.name === 'visitor_attended')

    if (visitor_attended) {
      var visitor_attended_value = visitor_attended?.value
    }

    var visitor_joined = data?.point?.find((point: any) => point?.name === 'visitor_joined')

    if (visitor_joined) {
      var visitor_joined_value = visitor_joined?.value
    }

    var group_meeting_participation = data?.point?.find(
      (point: any) => point?.name === 'group_meeting_participation'
    )

    if (group_meeting_participation) {
      var group_meeting_participation_value = group_meeting_participation?.value
    }

    var session_feedback_points = data?.point?.find(
      (point: any) => point?.name === 'session_feedback_points'
    )

    if (session_feedback_points) {
      var session_feedback_points_value = session_feedback_points?.value
    }

    var help = data?.point?.find((point: any) => point?.name === 'help')

    if (help) {
      var help_value = help?.value
    }

    var session_attendance = data?.point?.find((point: any) => point?.name === 'session_attendance')

    if (session_attendance) {
      var session_attendance_value = session_attendance?.value
    }

    var share_idea = data?.point?.find((point: any) => point?.name === 'share_idea')

    if (share_idea) {
      var share_idea_value = share_idea?.value
    }

    var icaniwill = data?.point?.find((point: any) => point?.name === 'icaniwill')

    if (icaniwill) {
      var icaniwill_value = icaniwill?.value
    }

    var business_referral_sent = data?.point?.find(
      (point: any) => point?.name === 'business_referral_sent'
    )

    if (business_referral_sent) {
      var business_referral_sent_value = business_referral_sent?.value
    }

    var business_referral_received = data?.point?.find(
      (point: any) => point?.name === 'business_referral_received'
    )

    if (business_referral_received) {
      var business_referral_received_value = business_referral_received?.value
    }

    var business_connect_sent = data?.point?.find(
      (point: any) => point?.name === 'business_connect_sent'
    )

    if (business_connect_sent) {
      var business_connect_sent_value = business_connect_sent?.value
    }

    var business_connect_received = data?.point?.find(
      (point: any) => point?.name === 'business_connect_received'
    )

    if (business_connect_received) {
      var business_connect_received_value = business_connect_received?.value
    }

    var business_generate_sent = data?.point?.find(
      (point: any) => point?.name === 'business_generate_sent'
    )

    if (business_generate_sent) {
      var business_generate_sent_value = business_generate_sent?.value
    }

    var business_generate_received = data?.point?.find(
      (point: any) => point?.name === 'business_generate_received'
    )

    if (business_generate_received) {
      var business_generate_received_value = business_generate_received?.value
    }

    var mr_tracking = data?.point?.find((point: any) => point?.name === 'mr_tracking')

    if (mr_tracking) {
      var mr_tracking_value = mr_tracking?.value
    }
  }

  if (data?.chapter?.length > 0) {
    var chapterArray = data?.chapter?.map((item: any) => ({
      key: item?.id?.toString(),
      value: item?.name,
    }))
    console.log('chapterArray=>', chapterArray)
  }

  if (data?.permissionModel?.length > 0) {
    var permissionModelArray = data?.permissionModel?.map((item: any) => ({
      key: item?.id?.toString(),
      value: item?.value?.replace(/_/g, ' ').replace(/^\w/, (c: any) => c?.toUpperCase()),
    }))
    console.log('permissionModelArray=>', permissionModelArray)
  }

  return (
    <>
      <div className='row mx-0 flex-md-block '>
        <div className='col-sm-6 col-md-6 col-lg-6'>
          <h1 className='inv-hst sm-ms-2'> View Vertical </h1>
        </div>
      </div>

      <div className='card mx-3 py-8 px-5 px-sm-8 mt-5 visitor-details ilead__card'>
        <form action=''>
          <div className='row'>
            <div className='col-sm-12 col-md-6 mb-8'>
              <Form.Label className='fs-6 fw-semibold text-primary w-100 text-dark text-start'>
                {' '}
                Vertical Name{' '}
              </Form.Label>
              <Form.Control
                disabled
                type='text'
                placeholder=''
                className='ps-0'
                value={checkDisplayValue(data?.verticals?.name)}
              />
            </div>

            <div className='col-sm-12 col-md-6 mb-8'>
              <Form.Label className='fs-6 fw-semibold text-primary w-100 text-dark text-start'>
                {' '}
                Select Chapter{' '}
              </Form.Label>
              <Multiselect
                disable
                className='ps-0'
                displayValue='value'
                placeholder=''
                onKeyPressFn={function noRefCheck() {}}
                onRemove={function noRefCheck() {}}
                onSearch={function noRefCheck() {}}
                onSelect={function noRefCheck() {}}
                selectedValues={chapterArray}
              />
            </div>
          </div>
          <div className='row'>
            <div className='col-sm-12 mb-8'>
              <Form.Label className='fs-6 fw-semibold text-primary w-100 text-dark text-start'>
                {' '}
                Select Permission{' '}
              </Form.Label>
              <Multiselect
                disable
                className='ps-0'
                displayValue='value'
                placeholder=''
                onKeyPressFn={function noRefCheck() {}}
                onRemove={function noRefCheck() {}}
                onSearch={function noRefCheck() {}}
                onSelect={function noRefCheck() {}}
                selectedValues={permissionModelArray}
              />
            </div>
          </div>

          <div className='row'>
            <div className='col-md-12 col-lg-3 mb-8'>
              <Form.Label className='fs-6 fw-semibold text-primary w-100 text-dark text-start'>
                {' '}
                Thank You Given{' '}
              </Form.Label>
              <Form.Control
                disabled
                type='number'
                placeholder=''
                className='ps-0'
                value={checkDisplayValue(thankyou_card_given_value)}
              />
            </div>

            <div className='col-md-12 col-lg-3 mb-8'>
              <Form.Label className='fs-6 fw-semibold text-primary w-100 text-dark text-start'>
                {' '}
                Thank You Received{' '}
              </Form.Label>
              <Form.Control
                disabled
                type='number'
                placeholder=''
                className='ps-0'
                value={checkDisplayValue(thankyou_card_received_value)}
              />
            </div>

            <div className='col-md-12 col-lg-3 mb-8'>
              <Form.Label className='fs-6 fw-semibold text-primary w-100 text-dark text-start'>
                {' '}
                Visitor Attended{' '}
              </Form.Label>
              <Form.Control
                disabled
                type='number'
                placeholder=''
                className='ps-0'
                value={checkDisplayValue(visitor_attended_value)}
              />
            </div>

            <div className='col-md-12 col-lg-3 mb-8'>
              <Form.Label className='fs-6 fw-semibold text-primary w-100 text-dark text-start'>
                {' '}
                Visitor Joined{' '}
              </Form.Label>
              <Form.Control
                disabled
                type='number'
                placeholder=''
                className='ps-0'
                value={checkDisplayValue(visitor_joined_value)}
              />
            </div>
          </div>

          <div className='row'>
            <div className='col-md-12 col-lg-3 mb-8'>
              <Form.Label className='fs-6 fw-semibold text-primary w-100 text-dark text-start'>
                {' '}
                GM Attended{' '}
              </Form.Label>
              <Form.Control
                disabled
                type='number'
                placeholder=''
                className='ps-0'
                value={checkDisplayValue(group_meeting_participation_value)}
              />
            </div>

            <div className='col-md-12 col-lg-3 mb-8'>
              <Form.Label className='fs-6 fw-semibold text-primary w-100 text-dark text-start'>
                {' '}
                Session Feedback{' '}
              </Form.Label>
              <Form.Control
                disabled
                type='number'
                placeholder=''
                className='ps-0'
                value={checkDisplayValue(session_feedback_points_value)}
              />
            </div>

            <div className='col-md-12 col-lg-3 mb-8'>
              <Form.Label className='fs-6 fw-semibold text-primary w-100 text-dark text-start'>
                {' '}
                Help{' '}
              </Form.Label>
              <Form.Control
                disabled
                type='number'
                placeholder=''
                className='ps-0'
                value={checkDisplayValue(help_value)}
              />
            </div>

            <div className='col-md-12 col-lg-3 mb-8'>
              <Form.Label className='fs-6 fw-semibold text-primary w-100 text-dark text-start'>
                {' '}
                Session Attended{' '}
              </Form.Label>
              <Form.Control
                disabled
                type='number'
                placeholder=''
                className='ps-0'
                value={checkDisplayValue(session_attendance_value)}
              />
            </div>
          </div>

          <div className='row'>
            {/* <div className='col-md-12 col-lg-3 mb-8'>
              <Form.Label className='fs-6 fw-semibold text-primary w-100 text-dark text-start'>
                {' '}
                Share Idea{' '}
              </Form.Label>
              <Form.Control
                disabled
                type='number'
                placeholder=''
                className='ps-0'
                value={checkDisplayValue(share_idea_value)}
              />
            </div> */}

            <div className='col-md-12 col-lg-3 mb-8'>
              <Form.Label className='fs-6 fw-semibold text-primary w-100 text-dark text-start'>
                {' '}
                Tracksheet{' '}
              </Form.Label>
              <Form.Control
                disabled
                type='number'
                placeholder=''
                className='ps-0'
                value={checkDisplayValue(tracksheetvalue)}
              />
            </div>

            <div className='col-md-12 col-lg-3 mb-8'>
              <Form.Label className='fs-6 fw-semibold text-primary w-100 text-dark text-start'>
                {' '}
                I CAN I WILL{' '}
              </Form.Label>
              <Form.Control
                disabled
                type='number'
                placeholder=''
                className='ps-0'
                value={checkDisplayValue(icaniwill_value)}
              />
            </div>

            <div className='col-md-12 col-lg-3 mb-8'>
              <Form.Label className='fs-6 fw-semibold text-primary w-100 text-dark text-start'>
                {' '}
                Business Referral{' '}
              </Form.Label>
              <Form.Control
                disabled
                type='number'
                placeholder=''
                className='ps-0'
                value={checkDisplayValue(business_referral_sent_value)}
              />
            </div>
            <div className='col-md-12 col-lg-3 mb-8'>
              <Form.Label className='fs-6 fw-semibold text-primary w-100 text-dark text-start'>
                {' '}
                Business Referral Received{' '}
              </Form.Label>
              <Form.Control
                disabled
                type='number'
                placeholder=''
                className='ps-0'
                value={checkDisplayValue(business_referral_received_value)}
              />
            </div>
          </div>

          <div className='row'>
            <div className='col-md-12 col-lg-3 mb-8'>
              <Form.Label className='fs-6 fw-semibold text-primary w-100 text-dark text-start'>
                {' '}
                Business Connect{' '}
              </Form.Label>
              <Form.Control
                disabled
                type='number'
                placeholder=''
                className='ps-0'
                value={checkDisplayValue(business_connect_sent_value)}
              />
            </div>

            <div className='col-md-12 col-lg-3 mb-8'>
              <Form.Label className='fs-6 fw-semibold text-primary w-100 text-dark text-start'>
                {' '}
                Business Connect Received{' '}
              </Form.Label>
              <Form.Control
                disabled
                type='number'
                placeholder=''
                className='ps-0'
                value={checkDisplayValue(business_connect_received_value)}
              />
            </div>

            <div className='col-md-12 col-lg-3 mb-8'>
              <Form.Label className='fs-6 fw-semibold text-primary w-100 text-dark text-start'>
                {' '}
                Business Generate Sent{' '}
              </Form.Label>
              <Form.Control
                disabled
                type='number'
                placeholder=''
                className='ps-0'
                value={checkDisplayValue(business_generate_sent_value)}
              />
            </div>

            <div className='col-md-12 col-lg-3 mb-8'>
              <Form.Label className='fs-6 fw-semibold text-primary w-100 text-dark text-start'>
                {' '}
                Gratitude For Business{' '}
              </Form.Label>
              <Form.Control
                disabled
                type='number'
                placeholder=''
                className='ps-0'
                value={checkDisplayValue(business_generate_received_value)}
              />
            </div>
          </div>

          <div className='row'>
            <div className='col-md-12 col-lg-3 mb-8'>
              <Form.Label className='fs-6 fw-semibold text-primary w-100 text-dark text-start'>
                {' '}
                MR Tracking{' '}
              </Form.Label>
              <Form.Control
                disabled
                type='number'
                placeholder=''
                className='ps-0'
                value={checkDisplayValue(mr_tracking_value)}
              />
            </div>
          </div>

          <div className='row'>
            <div className='col-md-12 col-lg-10 text-center pe-6'>
              <button
                type='button'
                className='btn btn-info  waves-effect ms-5 waves-light '
                onClick={navigateVerticalListing}
              >
                Back
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  )
}
export default ViewVertical
