import { ID, Response } from "../../../../../_metronic/helpers"

// import {ID, Response} from '../../../../../../_metronic/helpers'
export type Groupmeeting = {
  id?: ID
  name?: string
  avatar?: string
  email?: string
  position?: string
  role?: string
  group_name?:string
  meeting_date?:string
  chapter?:string
  venue?:string
  address?:string
 
}

export type GroupmeetingsQueryResponse = Response<Array<Groupmeeting>>

export const initialGroupmeeting: Groupmeeting = {
  avatar: 'avatars/300-6.jpg',
  position: 'Art Director',
  role: 'Administrator',
  name: '',
  email: '',

}
