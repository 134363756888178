import React, { useEffect, useState } from 'react'
import { apiUrl } from '../../../apiurl'
import { checkDisplayValue } from '../../../utils'
import axios from 'axios'

const API_URL = apiUrl
const TermAndCondition_URL = `${API_URL}/settings`

export function TermsConditionsPage() {
  const [data, setData] = useState<any>([])
  const [isLoading, setIsLoading] = useState<any>(true)

  useEffect(() => {
    fetchData()
  }, [])

  const fetchData = async () => {
    try {
      const res = await axios.get(
        `${TermAndCondition_URL}?filter[where][id][inq]=6&filter[where][id][inq]=7`
      )


      setData(res?.data ?? [])
      setIsLoading(false)
    } catch (error) {
      console.error('Error -->:', error)
      setIsLoading(false)
    }
  }

  const styles = {
    borderRadius: '0.475rem',
    boxShadow: '0 0 50px 0 rgb(82 63 105 / 15%)',
    backgroundColor: '#fff',
    color: '#7e8299',
    fontWeight: '500',
    margin: '0',
    width: 'auto',
    padding: '1rem 2rem',
    top: 'calc(50% - 2rem)',
    left: 'calc(50% - 4rem)',
  }

  if (isLoading) {
    return <div style={{ ...styles, position: 'absolute', textAlign: 'center' }}>Processing...</div>
  }

  return (
    <>
      <h1 className='inv-hst mb-3 mb-md-0'>
        Terms & Conditions
      </h1>

      <div className='mt-5 row'>
        <div className='col-12'>
          <div className='card ilead__card leader-view'>

            <div className='card-body'>
              <h2>
                {' '}
                {data[0]?.name
                  ? checkDisplayValue(data[0]?.value)
                  : 'Terms and Conditions will be updated soon!'}{' '}
              </h2>

              <p className='text-dark fw-normal fs-7 text-start mt-8'>
                *Terms & Conditions are subject to change without prior notice.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
