import React from "react";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button'
import AddEditNewLeader from "./AddEditNewLeader";

interface MyModalProps {
    show?: boolean;
    onHide?:any;
    id ?: any
  }

const EditNewLeader = ({id, show, onHide}:MyModalProps) => {



    return (
        <>
      
            {/* <div
                className='modal fade visitor-details'
                id={`editnewleader${id}`}
                data-bs-backdrop='static'
                data-bs-keyboard='false'
                tabIndex={-1}
                aria-labelledby='staticBackdropLabel'
                aria-hidden='true'
            >
                <div className='modal-dialog me-0 mb-0 mt-0 border-radius-0'>
                    <div className='modal-content '>

                        <div className='modal-header border-bottom border-gray-300'>
                            <h5 className='modal-title text-primary' id='staticBackdropLabel'>
                                Update Leader
                            </h5>
                            <button
                                type='button'
                                className='btn-close'
                                data-bs-dismiss='modal'
                                aria-label='Close'
                            ></button>
                        </div>
                        <AddEditNewLeader id={id}/>
                    </div>
                </div>
            </div> */}

<Modal
      show={show}
      onHide={onHide(false)}
      backdrop="static"
    //   keyboard={false}
      aria-labelledby="staticBackdropLabel"
    >
      <Modal.Header closeButton>
        <Modal.Title id="staticBackdropLabel">Add New Leader</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {/* <AddEditNewLeader id={id}/> */}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Close
        </Button>
        {/* Add any additional buttons or actions here */}
      </Modal.Footer>
    </Modal>
        </>
    )
}

export default EditNewLeader