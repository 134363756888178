import {ListViewProvider, useListView} from './core/ListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
import {LibrarymanagementsListHeader} from './components/header/LibrarymanagementsListHeader'
import {LibrarymanagementsTable} from './table/LibrarymanagementsTable'
import {LibrarymanagementEditModal} from './librarymanagement-edit-modal/LibrarymanagementEditModal'
import { KTCard } from '../../../../_metronic/helpers'
// import {KTCard} from '../../../../../_metronic/helpers'

const LibrarymanagementsList = () => {
  const {itemIdForUpdate} = useListView()
  return (
    <>
      <>
        <LibrarymanagementsListHeader />
        <LibrarymanagementsTable />
      </>
      {itemIdForUpdate !== undefined && <LibrarymanagementEditModal />}
    </>
  )
}

const LibrarymanagementsListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <LibrarymanagementsList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {LibrarymanagementsListWrapper}
