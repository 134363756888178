import axios, {AxiosResponse} from 'axios'
// import {ID, Response} from '../../../../../../_metronic/helpers'
import {Reminder, RemindersQueryResponse} from './_models'
import {ID, Response} from '../../../../../../_metronic/helpers'
// import { ID, Response } from '../../../../../_metronic/helpers'
import {apiUrl} from '../../../../../../apiurl'

const API_URL = apiUrl
const REMINDER_URL = `${API_URL}/messagecenterreminders/viewReminder`
const DELETE_REMINDERS_URL = `${API_URL}/messagecenterreminders/deleteReminder`
const EDIT_REMINDER_URL = `${API_URL}/messagecenterreminders/editReminder`
const ADD_REMINDER_URL = `${API_URL}/messagecenterreminders/addReminder`

const getReminders = (query: string): Promise<RemindersQueryResponse> => {
  return axios
    .get(`${REMINDER_URL}?${query}`)
    .then((d: AxiosResponse<any>) => d.data)
    .catch((e) => console.log(e))
}

const getReminderById = (id: ID): Promise<Reminder | undefined> => {
  return (
    axios
      .get(`${REMINDER_URL}/${id}`)
      // .then((response: AxiosResponse<Response<Reminder>>) => response.data).catch((e) => console.log(e))
      .then((response: Response<any>) => response.data)
      .catch((e) => console.log(e))
  )
}

const createReminder = (data: any): Promise<Reminder | undefined> => {
  return (
    axios
      .post(ADD_REMINDER_URL, data)
      // .then((response: AxiosResponse<Response<Reminder>>) => response.data).catch((e) => console.log(e))
      .then((response: any) => response)
      .catch((e) => console.log(e))
  )
}

const updateReminder = (reminderId: any, data: any): Promise<Reminder | undefined> => {
  return (
    axios
      .post(`${EDIT_REMINDER_URL}/${reminderId}`, data)
      // .then((response: AxiosResponse<Response<Reminder>>) => response.data).catch((e) => console.log(e))
      .then((response: any) => response.data)
      .catch((e) => console.log(e))
  )
}

const deleteReminder = (reminderId: ID): Promise<void> => {
  return axios
    .post(`${DELETE_REMINDERS_URL}/${reminderId}`)
    .then((response: Response<any>) => response.data)
    .catch((e) => console.log(e))
}

const deleteSelectedReminders = (reminderIds: Array<ID>): Promise<void> => {
  const requests = reminderIds.map((id) => axios.delete(`${REMINDER_URL}/${id}`))
  return axios.all(requests).then(() => {})
}

export {
  getReminders,
  deleteReminder,
  deleteSelectedReminders,
  getReminderById,
  createReminder,
  updateReminder,
}
