import { ListViewProvider, useListView } from './core/ListViewProvider'
import { QueryRequestProvider } from './core/QueryRequestProvider'
import { QueryResponseProvider } from './core/QueryResponseProvider'
import { MonthlyreportsListHeader } from './components/header/MonthlyreportsListHeader'
import { MonthlyreportsTable } from './table/MonthlyreportsTable'
import { MonthlyreportEditModal } from './monthlyreport-edit-modal/MonthlyreportEditModal'
import { KTCard } from '../../../../_metronic/helpers'
// import {KTCard} from '../../../../../_metronic/helpers'

const MonthlyreportsList = () => {
  const { itemIdForUpdate } = useListView()
  return (
    <>
      <KTCard>
        {/* <MonthlyreportsListHeader /> */}
        <MonthlyreportsTable />
      </KTCard>
      {itemIdForUpdate !== undefined && <MonthlyreportEditModal />}
    </>
  )
}

const MonthlyreportsListWrapper = () => (
  // <QueryRequestProvider>
  //   <QueryResponseProvider>
  <ListViewProvider>
    <MonthlyreportsList />
  </ListViewProvider>
  //   </QueryResponseProvider>
  // </QueryRequestProvider>
)

export { MonthlyreportsListWrapper }
