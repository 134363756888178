/* eslint-disable react-hooks/exhaustive-deps */
import {FC, useContext, useState, useEffect, useMemo} from 'react'
import {useQuery} from 'react-query'
// import {
//   createResponseContext,
//   initialQueryResponse,
//   initialQueryState,
//   PaginationState,
//   QUERIES,
//   stringifyRequestQuery,
//   WithChildren,
// } from '../../../../../../_metronic/helpers'
import {getRequestusers} from './_requests'
import {Requestuser} from './_models'
import {useQueryRequest} from './QueryRequestProvider'
import { PaginationState, QUERIES, WithChildren, createResponseContext, initialQueryState, initialQueryResponse, stringifyRequestQuery } from '../../../../../../_metronic/helpers'
// '

const QueryResponseContext = createResponseContext<Requestuser>(initialQueryResponse)
const QueryResponseProvider: FC<WithChildren> = ({children}) => {
  const {state} = useQueryRequest()
  const [query, setQuery] = useState<string>(stringifyRequestQuery(state))
  const updatedQuery = useMemo(() => stringifyRequestQuery(state), [state])

  useEffect(() => {
    if (query !== updatedQuery) {
      setQuery(updatedQuery)
    }
  }, [updatedQuery])

  const {
    isFetching,
    refetch,
    data: response,
  } = useQuery(
    `${QUERIES.REQUESTUSERS_LIST}-${query}`,
    () => {
      return getRequestusers(query?.replaceAll("filter_","").replaceAll("items_per_page","perPage"))
    },
    {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
  )

  return (
    <QueryResponseContext.Provider value={{isLoading: isFetching, refetch, response, query}}>
      {children}
    </QueryResponseContext.Provider>
  )
}

const useQueryResponse = () => useContext(QueryResponseContext)

const useQueryResponseData = () => {
  const {response} = useQueryResponse() as any
  if (!response || !response?.data) {
    return []
  }
  return response?.data || []
}



const useQueryResponsePagination = () => {
  const defaultPaginationState: PaginationState = {
    links: [],
    ...initialQueryState,
  }

  const {response} = useQueryResponse()
  if (!response  ||  !response.pagination) {
    return {}
  }

  return response.pagination
}

const useQueryResponseLoading = (): boolean => {
  const {isLoading} = useQueryResponse()
  return isLoading
}

export {
  QueryResponseProvider,
  useQueryResponse,
  useQueryResponseData,
  useQueryResponsePagination,
  useQueryResponseLoading,
}
