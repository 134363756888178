

import { ID, Response } from "../../../../../../_metronic/helpers"

export type Template = {
  id?: ID
  name?: string
  avatar?: string
  email?: string
  position?: string
  role?: string
  sr_no?:string
  template_name?:string
 
}

export type TemplatesQueryResponse = Response<Array<Template>>

export const initialTemplate: Template = {
  avatar: 'avatars/300-6.jpg',
  position: 'Art Director',
  role: 'Administrator',
  name: '',
  email: '',

}
