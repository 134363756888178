// @ts-nocheck
import {Column} from 'react-table'
import {VisitorInfoCell} from './VisitorInfoCell'
import {VisitorLastLoginCell} from './VisitorLastLoginCell'
import {VisitorTwoStepsCell} from './VisitorTwoStepsCell'
import {VisitorActionsCell} from './VisitorActionsCell'
import {VisitorSelectionCell} from './VisitorSelectionCell'
import {VisitorCustomHeader} from './VisitorCustomHeader'
import {VisitorSelectionHeader} from './VisitorSelectionHeader'
import {Visitor} from '../../core/_models'
import { checkDisplayValue, indiaFormatDate } from '../../../../../../utils'

const visitorsColumns: ReadonlyArray<Column<Visitor>> = [
  // {
  //   Header: (props) => <VisitorSelectionHeader tableProps={props} />,
  //   id: 'selection',
  //   Cell: ({...props}) => <VisitorSelectionCell id={props.data[props.row.index].id} />,
  // },
 
  {
    Header: (props) => <VisitorCustomHeader tableProps={props} title='Event Date' className='min-w-125px   ps-2 fs-5' />,
    accessor: 'event_date',
    // id: 'name',
    Cell:(value)=>{return indiaFormatDate(value?.row?.original?.event_date)}
    // Cell: ({...props}) => <VisitorInfoCell visitor={props.data[props.row.index]} />,
  },

  {
    Header: (props) => <VisitorCustomHeader tableProps={props} title='Name' className='min-w-125px fs-5' />,
    accessor: 'invitee_name',
    // id: 'name',
    Cell:(value)=>{return checkDisplayValue(value?.row?.original?.invitee_name)}

    // Cell: ({...props}) => <VisitorInfoCell visitor={props.data[props.row.index]} />,
  },


  {
    Header: (props) => <VisitorCustomHeader tableProps={props} title='Mobile' className='min-w-125px  fs-5' />,
    accessor: 'cell_no',
    // id: 'name',
    Cell:(value)=>{return checkDisplayValue(value?.row?.original?.cell_no)}
    // Cell: ({...props}) => <VisitorInfoCell visitor={props.data[props.row.index]} />,
  },
  
  {
    Header: (props) => <VisitorCustomHeader tableProps={props} title='Email' className=' fs-5' />,
    accessor: 'email',
    // id: 'name',
    Cell:(value)=>{return checkDisplayValue(value?.row?.original?.email)}
    // Cell: ({...props}) => <VisitorInfoCell visitor={props.data[props.row.index]} />,
  },
  
  {
    Header: (props) => <VisitorCustomHeader tableProps={props} title='Company Name' className=' fs-5' />,
    accessor: 'company',
    // id: 'name',
    Cell:(value)=>{return checkDisplayValue(value?.row?.original?.company)}
    // Cell: ({...props}) => <VisitorInfoCell visitor={props.data[props.row.index]} />,
  },
  
  {
      Header: (props) => (
        <VisitorCustomHeader tableProps={props} title='Actions' className=' min-w-150px' />
      ),
      id: 'actions',
      Cell: ({...props}) => <VisitorActionsCell id={props.data[props.row.index].id} data={props.data[props.row.index]} />,
    },

  
  
]

export {visitorsColumns}
