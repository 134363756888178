/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
// import {initialQueryState, KTIcon, useDebounce} from '../../../../../../../_metronic/helpers'
import { useQueryRequest } from '../../core/QueryRequestProvider'
import { KTIcon, useDebounce, initialQueryState } from '../../../../../../../_metronic/helpers'
// import { KTIcon, initialQueryState, useDebounce } from '../../../../../../_metronic/helpers'

const GroupchatsListSearchComponent = () => {
  const { updateState } = useQueryRequest()
  const [searchTerm, setSearchTerm] = useState<string>('')
  // Debounce search term so that it only gives us latest value ...
  // ... if searchTerm has not been updated within last 500ms.
  // The goal is to only have the API call fire when groupchat stops typing ...
  // ... so that we aren't hitting our API rapidly.
  const debouncedSearchTerm = useDebounce(searchTerm, 150)
  // Effect for API call
  useEffect(
    () => {
      if (debouncedSearchTerm !== undefined && searchTerm !== undefined) {
        updateState({ search: debouncedSearchTerm, ...initialQueryState })
      }
    },
    [debouncedSearchTerm] // Only call effect if debounced search term changes
    // More details about useDebounce: https://usehooks.com/useDebounce/
  )

  return (
    <div className='card-title'>
      {/* begin::Search */}
      <div className="row mx-0 justify-content-end mb-5 mt-6 fs-5 ">
        <div className="d-flex justify-content-end  align-items-center  px-0 ">
          <div >Search: </div> <div className="ps-2"> <input type="search" className="form-control" value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} /> </div>
        </div>
      </div>
      {/* <div className='d-flex align-items-center position-relative my-1'>
        <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-6' />
        <input
          type='text'
          data-kt-groupchat-table-filter='search'
          className='form-control form-control-solid w-250px ps-14'
          placeholder='Search groupchat'
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div> */}
      {/* end::Search */}
    </div>
  )
}

export { GroupchatsListSearchComponent }
