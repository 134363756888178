import {ListViewProvider, useListView} from './core/ListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
import {SummarysessionsListHeader} from './components/header/SummarysessionsListHeader'
import {SummarysessionsTable} from './table/SummarysessionsTable'
import {SummarysessionEditModal} from './summarysession-edit-modal/SummarysessionEditModal'
import { KTCard } from '../../../../_metronic/helpers'
// import {KTCard} from '../../../../../_metronic/helpers'

const SummarysessionsList = () => {
  const {itemIdForUpdate} = useListView()
  return (
    <>
      <KTCard>
        {/* <SummarysessionsListHeader /> */}
        <SummarysessionsTable />
      </KTCard>
      {itemIdForUpdate !== undefined && <SummarysessionEditModal />}
    </>
  )
}

const SummarysessionsListWrapper = () => (
  // <QueryRequestProvider>
  //   <QueryResponseProvider>
      <ListViewProvider>
        <SummarysessionsList />
      </ListViewProvider>
  //   {/* </QueryResponseProvider>
  // </QueryRequestProvider> */}
)

export {SummarysessionsListWrapper}
