import React, {useState, useEffect, useContext} from 'react'
import {KTIcon, toAbsoluteUrl} from '../../../_metronic/helpers'
import {apiUrl} from '../../../apiurl'
import {checkDisplayValue} from '../../../utils'
import axios from 'axios'
import {UserContext} from '../../../contextStore'
import {Link} from 'react-router-dom'
import {accessUser} from '../../../utils/privileges'

const API_URL = apiUrl
const SEARCH_LEADER_URL = `${API_URL}`
const CHAPTER_LIST_URL = `${API_URL}/chapters?filter[where][isdelete]=0&filter[fields][id]=true&filter[fields][name]=true`
const SEARCH_LEADER_Post_URL = `${API_URL}/users`
const SEARCH_IN_LEADER_URL = `${API_URL}/users/search_global_leader`
const STATE_URL = `${API_URL}/states?filter[where][isdelete]=0`
const CITY_URL = `${API_URL}/cities?filter[where][isdelete]=0`

const SearchLeader: React.FC = () => {
  const isUserManagement = accessUser.usermanagement
  const [showBrowseByChapter, setShowBrowseByChapter] = useState(true)
  const [browseByChapterData, setBrowseByChapterData] = useState<any>([])
  const [searchInIleadData, setSearchInIleadData] = useState<any>([])
  const {userDetail, setMessage} = useContext(UserContext)
  const userid = userDetail?.id ?? ''
  const isAdmin = isUserManagement.includes(userid)
  const [browseByChapter, setBrowseByChapter] = useState<any>({
    chapterId: '',
    leaderName: '',
  })
  const [searchInIlead, setSearchInIlead] = useState<any>({
    name: '',
    company: '',
    chapter: '',
    city: '',
    state: '',
    business_category: '',
    keyword: '',
  })
  const [searchLeaderLoading, setSearchLeaderLoading] = useState<any>({
    browseByChapter: false,
    searchInIlead: false,
  })
  const [chapterList, setChapterList] = useState<any>([])
  const [stateList, setStateList] = useState<any>([])
  const [cityList, setCityList] = useState<any>([])

  useEffect(() => {
    // fetchData()
    if (!isAdmin) {
      fetchBrowseByChapter()
    } else {
      fetchStates()
      fetchCity()
    }
    fetchChapters()
    // fetchSearchInLeader()
  }, [])

  useEffect(() => {
    if (showBrowseByChapter) {
      if (isAdmin) {
        if (browseByChapter.chapterId || browseByChapter.leaderName) {
          fetchBrowseByChapter()
        }
      } else {
        fetchBrowseByChapter()
      }
    }
  }, [browseByChapter])

  useEffect(() => {
    if (!showBrowseByChapter) {
      fetchSearchInLeader()
    }
  }, [searchInIlead])

  const fetchBrowseByChapter = async () => {
    setSearchLeaderLoading({...searchLeaderLoading, ...{browseByChapter: true}})
    try {
      const res = await axios.post(`${SEARCH_LEADER_Post_URL}/searchleaders`, {
        chapter_id: browseByChapter.chapterId ? browseByChapter.chapterId : '',
        name: browseByChapter.leaderName ? browseByChapter.leaderName : '',
      })
      setBrowseByChapterData(res?.data ?? [])
      setSearchLeaderLoading({...searchLeaderLoading, ...{browseByChapter: false}})
    } catch (error: any) {
      setMessage('error', error?.message ?? 'Something Went Wrong')
      console.error('Error -->:', error)
      setSearchLeaderLoading({...searchLeaderLoading, ...{browseByChapter: false}})
    }
  }

  const fetchSearchInLeader = async () => {
    setSearchLeaderLoading({...searchLeaderLoading, ...{searchInIlead: true}})
    try {
      let reqPayload = JSON.parse(JSON.stringify(searchInIlead)) as any
      reqPayload.chapter = searchInIlead.chapter == 'all' ? '' : searchInIlead.chapter
      reqPayload.city = searchInIlead.city == 'all' ? '' : searchInIlead.city
      reqPayload.state = searchInIlead.state == 'all' ? '' : searchInIlead.state
      const res = await axios
        .get(SEARCH_IN_LEADER_URL, {params: reqPayload})
        .then((data: any) => data)
      setSearchInIleadData(res?.data ?? [])
      setSearchLeaderLoading({...searchLeaderLoading, ...{searchInIlead: false}})
    } catch (error: any) {
      setMessage('error', error?.message ?? 'Something Went Wrong')
      console.error('Error -->:', error)
      setSearchLeaderLoading({...searchLeaderLoading, ...{searchInIlead: false}})
    }
  }

  const fetchChapters = async () => {
    try {
      let res = await axios.get(`${CHAPTER_LIST_URL}`)
      setChapterList(res?.data ?? [])
    } catch (error) {
      console.error('Error -->:', error)
    }
  }

  const fetchStates = async () => {
    try {
      let res = await axios.get(`${STATE_URL}`)
      setStateList(res?.data ?? [])
    } catch (error) {
      console.error('Error -->:', error)
    }
  }

  const fetchCity = async () => {
    try {
      let res = await axios.get(`${CITY_URL}`)
      setCityList(res?.data ?? [])
    } catch (error) {
      console.error('Error -->:', error)
    }
  }

  const getChapterName = (chapterId: any) => {
    const chapter = chapterList.find((chapter: any) => chapter.id === chapterId)
    return chapter ? chapter?.name : '-'
  }

  const styles = {
    borderRadius: '0.475rem',
    boxShadow: '0 0 50px 0 rgb(82 63 105 / 15%)',
    backgroundColor: '#fff',
    color: '#7e8299',
    fontWeight: '500',
    margin: '0',
    width: 'auto',
    padding: '1rem 2rem',
    top: 'calc(50% - 2rem)',
    left: 'calc(50% - 4rem)',
  }

  // if (isLoading) {
  //   return <div style={{ ...styles, position: 'absolute', textAlign: 'center' }}>Processing...</div>
  // }

  return (
    <>
      <div className='d-flex flex-column flex-md-row justify-content-between align-items-center'>
        <h1 className='inv-hst mb-3 mb-md-0'>Search Leaders</h1>
        {!isAdmin ? (
          <div className='d-flex top__filter'>
            {/* <button data-bs-toggle="modal" data-bs-target="#searchinilead" className="btn btn-primary waves-effect waves-light btn-sm me-3" > Search in IILEAD </button> */}
            <div className='form-group floating-labels me-3'>
              <select
                className='form-select border-top-0 border-left-0 border-right-0 border-bottom rounded-0'
                aria-label='Default select example'
                value={browseByChapter.chapterId}
                onChange={(e) => {
                  setBrowseByChapter({...browseByChapter, ...{chapterId: e.target.value}})
                }}
              >
                <option value=''> All </option>
                {chapterList?.map((i: any) => (
                  <option value={i?.id}> {i?.name} </option>
                ))}
              </select>
            </div>
            <div className='input-group search__input__group  flex-1'>
              <span className='input-group-text border-0 bg-white'>
                <KTIcon iconName='magnifier'></KTIcon>
              </span>
              <input
                type='search'
                id='Search'
                placeholder='Search'
                className='form-control top__search__input'
                value={browseByChapter.leaderName}
                onChange={(e) => {
                  setBrowseByChapter({...browseByChapter, ...{leaderName: e.target.value}})
                }}
              />
              {/* <button type='button'></button> */}
            </div>
          </div>
        ) : (
          ''
        )}
      </div>
      {isAdmin ? (
        <div className='row mx-0 mt-5'>
          <div className='col-sm-10 ps-3 ps-md-0 viw-feedback mob-view-pl-pr '>
            <ul className='nav nav-pills mb-3' id='pills-tab' role='tablist'>
              <li className='nav-item me-0' role='presentation'>
                <button
                  className='nav-link active me-0 fs-4'
                  id='pills-home-tab'
                  data-bs-toggle='pill'
                  data-bs-target='#pills-home'
                  type='button'
                  role='tab'
                  aria-controls='pills-home'
                  aria-selected='false'
                  onClick={() => setShowBrowseByChapter(true)}
                >
                  Browse By Chapter
                </button>
              </li>
              <li className='nav-item' role='presentation'>
                <button
                  className='nav-link fs-4'
                  id='pills-profile-tab'
                  data-bs-toggle='pill'
                  data-bs-target='#pills-profile'
                  type='button'
                  role='tab'
                  aria-controls='pills-profile'
                  aria-selected='true'
                  onClick={() => setShowBrowseByChapter(false)}
                >
                  Search in ILEAD
                </button>
              </li>
            </ul>
          </div>

          <div className='tab-content ps-3 ps-md-0 feedback-detail' id='pills-tabContent'>
            <div
              className='tab-pane fade show active'
              id='pills-home'
              role='tabpanel'
              aria-labelledby='pills-home-tab'
              tabIndex={0}
            >
              <div className='card card_ilead'>
                <form action=''>
                  <div className='visitor-details row p-8'>
                    <div className='col-sm-6'>
                      <select
                        className='form-select ps-0'
                        aria-label='Default select example'
                        value={browseByChapter.chapterId}
                        onChange={(e) => {
                          setBrowseByChapter({...browseByChapter, ...{chapterId: e.target.value}})
                        }}
                      >
                        <option value='' disabled>
                          {' '}
                          Select Chapter{' '}
                        </option>
                        {chapterList?.map((i: any) => (
                          <option selected={i?.id == browseByChapter?.chapterId} value={i?.id}>
                            {' '}
                            {i?.name}{' '}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className='col-sm-6 position-relative'>
                      <div className='form-floating mb-3'>
                        <input
                          type='text'
                          className='form-control mt-n2 ps-0'
                          id='floatingInput'
                          placeholder='Search'
                          value={browseByChapter.leaderName}
                          onChange={(e) => {
                            setBrowseByChapter({
                              ...browseByChapter,
                              ...{leaderName: e.target.value},
                            })
                          }}
                        />
                        <div className='position-absolute' style={{right: '20px', top: '40%'}}>
                          {' '}
                          <i className='fa fs-4 text-gray fa-search'></i>{' '}
                        </div>
                        <label className='ps-0'> Search </label>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <div className='row mt-5 mobile-padding'>
                {browseByChapterData?.map((item: any) => (
                  <div className='col-md-6 col-lg-6 col-xl-4'>
                    <div className='card ilead__card mb-3'>
                      <div className='card-body p-0'>
                        <Link to={`/leaders/${item.id}`}>
                          <div className='p-5 d-flex border-bottom border-secondary'>
                            <div className='symbol symbol-70px bg-white border-dark'>
                              <img
                                src={
                                  item?.profile
                                    ? `${API_URL}/containers/userprofile/download/${item?.profile}`
                                    : `${toAbsoluteUrl('./media/avatars/default-user.png')}`
                                }
                                onError={({currentTarget}: any) => {
                                  currentTarget.onerror = null // prevents looping
                                  currentTarget.src = `${toAbsoluteUrl(
                                    './media/avatars/default-user.png'
                                  )}`
                                }}
                                alt={checkDisplayValue(item?.name)}
                              />{' '}
                              {item?.chapter_id == 5 ? (
                                <div
                                  className='trustees__leader position-absolute'
                                  style={{bottom: '-8px', right: '-8px'}}
                                >
                                  <img src='/media/ilead/trustees__leader.svg' width={24} />
                                </div>
                              ) : (
                                ''
                              )}
                            </div>
                            <div className='flex-1 ps-4 pt-1 text-truncate'>
                              <h2 className='fs-6 text-dark leader__name'>
                                {' '}
                                {checkDisplayValue(item?.name)}{' '}
                              </h2>
                              <span
                                className='leader__company text-truncate text-black d-block'
                                style={{fontWeight: '400'}}
                              >
                                {checkDisplayValue(item?.companyname)}
                              </span>
                              <span
                                className='business_category d-block text-black-50'
                                style={{fontWeight: '400'}}
                              >
                                {checkDisplayValue(item?.business_category)}
                              </span>
                            </div>
                          </div>
                        </Link>
                        <div className='leader__details py-3 px-5 d-flex justify-content-between align-items-center'>
                          <div className='leader__chapter'>
                            {item?.chapter_id ? (
                              <span className='chapter__name text-primary'>
                                {getChapterName(item?.chapter_id)}
                              </span>
                            ) : (
                              ''
                            )}
                          </div>
                          <div className='leader__contact d-flex'>
                            <a
                              href={`mailto:${checkDisplayValue(item?.email)}`}
                              target='_blank'
                              className='symbol symbol-40px leader__email me-2'
                            >
                              <div className='symbol-label fs-2 fw-semibold text-primary'>
                                <img src='/media/ilead/mail.svg' alt='' />
                              </div>
                            </a>
                            <a
                              href={`tel:+91${checkDisplayValue(item?.mobileno)}`}
                              className='symbol symbol-40px leader__mobile'
                            >
                              <div className='symbol-label fs-2 fw-semibold text-success'>
                                <img src='/media/ilead/phone-call.svg' alt='' />
                              </div>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <div
              className='tab-pane fade'
              id='pills-profile'
              role='tabpanel'
              aria-labelledby='pills-profile-tab'
              tabIndex={0}
            >
              <div className='card card_ilead'>
                <form action=''>
                  <div className='visitor-details row p-8'>
                    <div className='col-sm-6'>
                      <div className='form-floating mb-3'>
                        <input
                          type='text'
                          className='form-control ps-0'
                          id='floatingInput'
                          placeholder=''
                          value={searchInIlead?.name}
                          onChange={(e) => {
                            setSearchInIlead({...searchInIlead, ...{name: e.target.value}})
                          }}
                        />
                        <label className='ps-0'> Name </label>
                      </div>
                    </div>

                    <div className='col-sm-6'>
                      <div className='form-floating mb-3'>
                        <input
                          type='text'
                          className='form-control ps-0'
                          id='floatingInput'
                          placeholder=''
                          value={searchInIlead?.company}
                          onChange={(e) => {
                            setSearchInIlead({...searchInIlead, ...{company: e.target.value}})
                          }}
                        />
                        <label className='ps-0'> Company Name </label>
                      </div>
                    </div>

                    <div className='col-sm-6 '>
                      <div className='form-floating mb-3'>
                        <input
                          type='text'
                          className='form-control ps-0'
                          id='floatingInput'
                          placeholder=''
                          value={searchInIlead?.business_category}
                          onChange={(e) => {
                            setSearchInIlead({
                              ...searchInIlead,
                              ...{business_category: e.target.value},
                            })
                          }}
                        />
                        <label className='ps-0'> Business category</label>
                      </div>
                    </div>
                    <div className='col-sm-6  pt-3'>
                      <select
                        className='form-select ps-0'
                        aria-label='Default select example'
                        value={searchInIlead?.chapter}
                        onChange={(e) => {
                          setSearchInIlead({...searchInIlead, ...{chapter: e.target.value}})
                        }}
                      >
                        <option value='' disabled selected>
                          Select Chapter
                        </option>
                        <option value='all'> All </option>
                        {chapterList?.map((i: any) => (
                          <option value={i?.id}> {i?.name} </option>
                        ))}
                      </select>
                    </div>
                    <div className='col-sm-6 mb-8'>
                      <select
                        className='form-select ps-0'
                        aria-label='Default select example'
                        value={searchInIlead?.state}
                        onChange={(e) => {
                          setSearchInIlead({...searchInIlead, ...{state: e.target.value}})
                        }}
                      >
                        <option value='' disabled selected>
                          Select State
                        </option>
                        <option value='all'> All </option>
                        {stateList?.map((i: any) => (
                          <option value={i?.id}> {i?.name} </option>
                        ))}
                      </select>
                    </div>
                    <div className='col-sm-6 mb-8'>
                      <select
                        className='form-select ps-0'
                        aria-label='Default select example'
                        value={searchInIlead?.city}
                        onChange={(e) => {
                          setSearchInIlead({...searchInIlead, ...{city: e.target.value}})
                        }}
                      >
                        <option value='' disabled selected>
                          Select City
                        </option>
                        <option value='all'> All </option>
                        {cityList?.map((i: any) => (
                          <option value={i?.id}> {i?.name} </option>
                        ))}
                      </select>
                    </div>

                    <div className='col-sm-12 position-relative'>
                      <div className='form-floating mb-3'>
                        <input
                          type='text'
                          className='form-control mt-n2 ps-0'
                          id='floatingInput'
                          placeholder='Search'
                          value={searchInIlead?.keyword}
                          onChange={(e) => {
                            setSearchInIlead({...searchInIlead, ...{keyword: e.target.value}})
                          }}
                        />
                        <div className='position-absolute' style={{right: '20px', top: '40%'}}>
                          {' '}
                          <i className='fa fs-4 text-gray fa-search'></i>{' '}
                        </div>
                        <label className='ps-0'> Search Any Keyword </label>
                      </div>
                    </div>
                    {/* {searchInIleadData.length} */}
                  </div>
                </form>
              </div>
              <div className='row mt-5 mobile-padding'>
                {searchInIleadData?.map((item: any) => (
                  <div className='col-md-6 col-lg-6 col-xl-4'>
                    <div className='card ilead__card mb-3'>
                      <div className='card-body p-0'>
                        <Link to={`/leaders/${item.id}`}>
                          <div className='p-5 d-flex border-bottom border-secondary'>
                            <div className='symbol symbol-70px bg-white border-dark'>
                              <img
                                src={
                                  item?.profile
                                    ? `${API_URL}/containers/userprofile/download/${item?.profile}`
                                    : `${toAbsoluteUrl('./media/avatars/default-user.png')}`
                                }
                                onError={({currentTarget}: any) => {
                                  currentTarget.onerror = null // prevents looping
                                  currentTarget.src = `${toAbsoluteUrl(
                                    './media/avatars/default-user.png'
                                  )}`
                                }}
                                alt={checkDisplayValue(item?.name)}
                              />{' '}
                              {item?.chapter_id == 5 ? (
                                <div
                                  className='trustees__leader position-absolute'
                                  style={{bottom: '-8px', right: '-8px'}}
                                >
                                  <img src='/media/ilead/trustees__leader.svg' width={24} />
                                </div>
                              ) : (
                                ''
                              )}
                            </div>
                            <div className='flex-1 ps-4 pt-1 text-truncate'>
                              <h2 className='fs-6 text-dark leader__name'>
                                {' '}
                                {checkDisplayValue(item?.name)}{' '}
                              </h2>
                              <span
                                className='leader__company text-truncate text-black d-block'
                                style={{fontWeight: '400'}}
                              >
                                {checkDisplayValue(item?.companyname)}
                              </span>
                              <span
                                className='business_category d-block text-black-50'
                                style={{fontWeight: '400'}}
                              >
                                {checkDisplayValue(item?.business_category)}
                              </span>
                            </div>
                          </div>
                        </Link>
                        <div className='leader__details py-3 px-5 d-flex justify-content-between align-items-center'>
                          <div className='leader__chapter'>
                            {item?.chapter_id ? (
                              <span className='chapter__name text-primary'>
                                {getChapterName(item?.chapter_id)}
                              </span>
                            ) : (
                              ''
                            )}
                          </div>
                          <div className='leader__contact d-flex'>
                            <a
                              href={`mailto:${checkDisplayValue(item?.email)}`}
                              className='symbol symbol-40px leader__email me-2'
                            >
                              <div className='symbol-label fs-2 fw-semibold text-primary'>
                                <img src='/media/ilead/mail.svg' alt='' />
                              </div>
                            </a>
                            <a
                              href={`tel:+91${checkDisplayValue(item?.mobileno)}`}
                              className='symbol symbol-40px leader__mobile'
                            >
                              <div className='symbol-label fs-2 fw-semibold text-success'>
                                <img src='/media/ilead/phone-call.svg' alt='' />
                              </div>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      ) : (
        ''
      )}

      {!isAdmin ? (
        <div className='row mt-5 mobile-padding'>
          {browseByChapterData?.map((item: any) => (
            <div className='col-md-6 col-lg-6 col-xl-4'>
              <div className='card ilead__card mb-3'>
                <div className='card-body p-0'>
                  <Link to={`/leaders/${item.id}`}>
                    <div className='p-5 d-flex border-bottom border-secondary'>
                      <div className='symbol symbol-70px bg-white border-dark'>
                        <img
                          src={
                            item?.profile
                              ? `${API_URL}/containers/userprofile/download/${item?.profile}`
                              : `${toAbsoluteUrl('./media/avatars/default-user.png')}`
                          }
                          onError={({currentTarget}: any) => {
                            currentTarget.onerror = null // prevents looping
                            currentTarget.src = `${toAbsoluteUrl(
                              './media/avatars/default-user.png'
                            )}`
                          }}
                          alt={checkDisplayValue(item?.name)}
                        />{' '}
                        {item?.chapter_id == 5 ? (
                          <div
                            className='trustees__leader position-absolute'
                            style={{bottom: '-8px', right: '-8px'}}
                          >
                            <img src='/media/ilead/trustees__leader.svg' width={24} />
                          </div>
                        ) : (
                          ''
                        )}
                      </div>
                      <div className='flex-1 ps-4 pt-1 text-truncate'>
                        <h2 className='fs-6 text-dark leader__name'>
                          {' '}
                          {checkDisplayValue(item?.name)}{' '}
                        </h2>
                        <span
                          className='leader__company text-truncate text-black d-block'
                          style={{fontWeight: '400'}}
                        >
                          {checkDisplayValue(item?.companyname)}
                        </span>
                        <span
                          className='business_category d-block text-black-50'
                          style={{fontWeight: '400'}}
                        >
                          {checkDisplayValue(item?.business_category)}
                        </span>
                      </div>
                    </div>
                  </Link>
                  <div className='leader__details py-3 px-5 d-flex justify-content-between align-items-center'>
                    <div className='leader__chapter'>
                      {item?.chapter_id ? (
                        <span className='chapter__name text-primary'>
                          {getChapterName(item?.chapter_id)}
                        </span>
                      ) : (
                        ''
                      )}
                    </div>
                    <div className='leader__contact d-flex'>
                      <a
                        href={`mailto:${checkDisplayValue(item?.email)}`}
                        className='symbol symbol-40px leader__email me-2'
                      >
                        <div className='symbol-label fs-2 fw-semibold text-primary'>
                          <img src='/media/ilead/mail.svg' alt='' />
                        </div>
                      </a>
                      <a
                        href={`tel:+91${checkDisplayValue(item?.mobileno)}`}
                        className='symbol symbol-40px leader__mobile'
                      >
                        <div className='symbol-label fs-2 fw-semibold text-success'>
                          <img src='/media/ilead/phone-call.svg' alt='' />
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        ''
      )}

      {showBrowseByChapter ? (
        searchLeaderLoading.browseByChapter ? (
          <div className='p-8 mt-5'>
            <p className='text-center fs-3 fw-bold fw-light mb-0 text-black'>Loading...</p>
          </div>
        ) : (browseByChapter.chapterId !== '' || browseByChapter.leaderName !== '') &&
          browseByChapterData.length === 0 ? (
          <div className='p-8 mt-5'>
            <p className='text-center fs-3 fw-bold fw-light mb-0 text-black'>No Leader Found</p>
          </div>
        ) : browseByChapter.chapterId === '' &&
          browseByChapter.leaderName === '' &&
          browseByChapterData.length === 0 ? (
          <div className='p-8 mt-5'>
            <p className='text-center fs-3 fw-bold fw-light mb-0 text-black'>Search Leader Name</p>
          </div>
        ) : (
          ''
        )
      ) : (
        ''
      )}

      {!showBrowseByChapter ? (
        searchLeaderLoading.searchInIlead ? (
          <div className='p-8 mt-5'>
            <p className='text-center fs-3 fw-bold fw-light mb-0 text-black'>Loading...</p>
          </div>
        ) : (searchInIlead.name !== '' ||
            searchInIlead.company !== '' ||
            searchInIlead.chapter !== '' ||
            searchInIlead.city !== '' ||
            searchInIlead.state !== '' ||
            searchInIlead.business_category !== '' ||
            searchInIlead.keyword !== '') &&
          searchInIleadData.length === 0 ? (
          <div className='p-8 mt-5'>
            <p className='text-center fs-3 fw-bold fw-light mb-0 text-black'>No Leader Found</p>
          </div>
        ) : (searchInIlead.name === '' ||
            searchInIlead.company === '' ||
            searchInIlead.chapter === '' ||
            searchInIlead.city === '' ||
            searchInIlead.state === '' ||
            searchInIlead.business_category === '' ||
            searchInIlead.keyword === '') &&
          searchInIleadData.length === 0 ? (
          <div className='p-8 mt-5'>
            <p className='text-center fs-3 fw-bold fw-light mb-0 text-black'>Search Leader Name</p>
          </div>
        ) : (
          ''
        )
      ) : (
        ''
      )}

      {/* <SearchinIlead /> */}
    </>
  )
}

export default SearchLeader
