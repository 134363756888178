import {useQuery} from 'react-query'
import {GroupmeetingEditModalForm} from './GroupmeetingEditModalForm'
// import {isNotEmpty, QUERIES} from '../../../../../../_metronic/helpers'
import {useListView} from '../core/ListViewProvider'
import { QUERIES, isNotEmpty } from '../../../../../_metronic/helpers'
import { getGroupmeetingById } from '../core/_requests'
// import { getGroupmeetingById } from '../../../apps/groupmeeting-management/groupmeetings-list/core/_requests'
// import {getGroupmeetingById} from '../core/_requests'

const GroupmeetingEditModalFormWrapper = () => {
  const {itemIdForUpdate, setItemIdForUpdate} = useListView()
  const enabledQuery: boolean = isNotEmpty(itemIdForUpdate)
  const {
    isLoading,
    data: groupmeeting,
    error,
  } = useQuery(
    `${QUERIES.GROUPMEETINGS_LIST}-groupmeeting-${itemIdForUpdate}`,
    () => {
      return getGroupmeetingById(itemIdForUpdate)
    },
    {
      cacheTime: 0,
      enabled: enabledQuery,
      onError: (err) => {
        setItemIdForUpdate(undefined)
        console.error(err)
      },
    }
  )

  if (!itemIdForUpdate) {
    return <GroupmeetingEditModalForm isGroupmeetingLoading={isLoading} groupmeeting={{id: undefined}} />
  }

  if (!isLoading && !error && groupmeeting) {
    return <GroupmeetingEditModalForm isGroupmeetingLoading={isLoading} groupmeeting={groupmeeting} />
  }

  return null
}

export {GroupmeetingEditModalFormWrapper}
