import React, { useContext, useEffect, useRef, useState } from 'react'
import { Button, Dropdown, Form, Image } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import Heart from 'react-animated-heart'
import { KTSVG, toAbsoluteUrl } from '../../../../../_metronic/helpers'
import { Link } from 'react-router-dom'
import { UserContext } from '../../../../../contextStore'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import {
  createCommentApi,
  deletePostApi,
  getCommentApi,
  likePostApi,
  showHidePostApi,
  deleteCommentApi
} from './_requests'
import moment from 'moment'
import { apiUrl } from '../../../../../apiurl'
const API_URL = apiUrl

export function PostFooter({ alloption, commentData, commentIndex, mainData, setMainData }: any) {
  const { setMessage, userDetail } = useContext(UserContext)
  const [isClick, setClick] = useState(false)
  const [data, setData] = useState<any>([])
  const [userId, setUserId] = useState<any>('')
  const [likeList, setLikeList] = useState<any>('')
  const navigate = useNavigate()

  const [loading, setLoading] = useState<any>({
    send: false,
    list: false,
    hide: false,
    delete: false,
    like: false,
  })

  const initialValues = {
    chattext: '',
    thank_you: 0,
    comment: 0,
    isactive: 1,
    isdelete: 0,
    created_by: '1',
    user_topic_id: null,
    user_id: '1',
  }

  const rediecttoLeaderProfile = (id: any) => {
    navigate(`/leaders/${id}`)
  }

  const validationSchema = Yup.object().shape({
    chattext: Yup.string().required('Comment is required').max(250, 'Comments not more than 250 characters'),
  })

  const deleteModelPostRef = useRef<any>()

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setLoading(Object.assign({}, loading, { send: true }))
      try {
        values.user_id = userId
        values.user_topic_id = commentData.id
        // Make the API call
        await createCommentApi(values)
        getPostComment()
        formik.resetForm()
        setMessage('success', 'Commented successfully')
        let newData = mainData
        newData[commentIndex].totalcomments = newData[commentIndex].totalcomments + 1
        setMainData([...newData])
        setLoading(Object.assign({}, loading, { send: false }))
      } catch (error) {
        console.error(error)

        setMessage('error', 'Error creating the announcement')
        setLoading(Object.assign({}, loading, { send: false }))
      }
    },
  })
  const getPostComment = async () => {
    setLoading(Object.assign({}, loading, { list: true }))
    try {
      let responseData = await getCommentApi(commentData.uuid)
      let cData = responseData?.usertopicchats ?? []
      await setData([...cData])
      let newData = mainData
      newData[commentIndex].totalcomments = cData.length
      setMainData([...newData])
      setLoading(Object.assign({}, loading, { list: false }))
    } catch (err) {
      console.error(err)
      setLoading(Object.assign({}, loading, { list: false }))
    }
  }
  const getIcaniwillLeaderList = async () => {
    setLoading(Object.assign({}, loading, { list: true }))
    try {
      let responseData = await getCommentApi(commentData.uuid)
      let likeData = responseData?.usertopiclikes ?? []
      await setLikeList([...likeData])
      setLoading(Object.assign({}, loading, { list: false }))
    } catch (err) {
      console.error(err)
      setLoading(Object.assign({}, loading, { list: false }))
    }
  }

  const showHideAction = async () => {
    setLoading(Object.assign({}, loading, { hide: true }))
    try {
      await showHidePostApi({ user_topic_id: commentData.id })
      let newData = mainData
      newData[commentIndex].isactive = commentData.isactive ? 0 : 1
      setMainData([...newData])
      setLoading(Object.assign({}, loading, { hide: false }))
    } catch (err) {
      console.error(err)
      setLoading(Object.assign({}, loading, { hide: false }))
    }
  }

  const deletePostAction = async () => {
    setLoading(Object.assign({}, loading, { delete: true }))
    try {
      let responseData = await deletePostApi(commentData.id)
      if (responseData.count == 1) {
        let newData = mainData.filter((x: any, id: any) => id != commentIndex)
        setMainData([...newData])
        setMessage('success', 'Delete Successfully')
        deleteModelPostRef?.current?.click()
      }
      setLoading(Object.assign({}, loading, { delete: false }))
    } catch (err) {
      console.error(err)
      setLoading(Object.assign({}, loading, { delete: false }))
    }
  }

  const deleteCommentAction = async (deleteId: any) => {
    try {
      if(deleteId){
        let responseData = await deleteCommentApi(deleteId)
        if (responseData.count == 1) {
          getPostComment()
          setMessage('success', 'Delete Successfully')
        }
      }else{
        setMessage('error', 'Something Went Wrong')
      }
    } catch (err) {
      console.error(err)
      setMessage('error', 'Something Went Wrong')
    }
  }

  const likePostAction = async () => {
    setLoading(Object.assign({}, loading, { like: true }))
    try {
      let responseData = await likePostApi({
        user_topic_id: commentData.id,
        user_id: userId,
      })
      let newData = mainData
      newData[commentIndex].usertopiclikes = responseData.userTopicLikeFinal
      newData[commentIndex].totallikes = responseData.totallikes
      setMainData([...newData])
      setLoading(Object.assign({}, loading, { like: false }))
    } catch (err) {
      console.error(err)
      setLoading(Object.assign({}, loading, { like: false }))
    }
  }

  // List for comment for single post
  const commentList = () => {
    return data && data.length > 0 ? (
      data.map((itemMessage: any, index: any) => {
        return (
          <div className='single_comment_view d-flex align-items-start mb-3' key={index}>
            <Link
              to={`/leaders/${itemMessage?.user?.id}`}
              onClick={() => {
                rediecttoLeaderProfile(itemMessage?.user?.id)
              }}
              data-bs-dismiss='modal'
              aria-label='Close'
            >
              <div className='symbol symbol-40px me-3'>
                <Image
                  src={
                    itemMessage?.user?.profile
                      ? `${API_URL}/containers/userprofile/download/${itemMessage?.user?.profile}`
                      : `${toAbsoluteUrl('./media/avatars/default-user.png')}`
                  }
                  onError={({ currentTarget }: any) => {
                    currentTarget.onerror = null // prevents looping
                    currentTarget.src = `${toAbsoluteUrl('./media/avatars/default-user.png')}`
                  }}
                ></Image>
              </div>
            </Link>
            <Link
              to={`/leaders/${itemMessage?.user?.id}`}
              onClick={() => {
                rediecttoLeaderProfile(itemMessage?.user?.id)
              }}
              data-bs-dismiss='modal'
              aria-label='Close'
            >
              <div className='flex-grow-1 d-flex flex-column commnet_box_right'>
                <div className='commented_user_details mb-1'>
                  <Link to='' className='ilead-text text-hover-primary fs-6 fs-md-5 fw-bold me-2'>
                    {itemMessage.user.name}
                  </Link>
                  <span className='round-separator me-2'></span>
                  <span className='text-gray-600'>{moment(itemMessage.created_at).fromNow()}</span>
                </div>
                <div className='commnet_content modal-wrap-text text-black'>{itemMessage.chat}</div>
              </div>
            </Link>
            {(userDetail.id && userDetail.id == itemMessage.user.id) ?
              <span style={{ position: 'absolute', right: '20px' }} onClick={()=>{
                deleteCommentAction(itemMessage?.id)
              }}>
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon svg-icon-2x'
                />
              </span> : ""}
          </div>
        )
      })
    ) : (
      <div className='single_comment_view d-flex align-items-start'>
        <div className='empty-comments text-center w-100'>
          <Image
            fluid
            src={toAbsoluteUrl('/media/ilead/empty-comments.png')}
            className='w-150px mb-4'
          ></Image>
          <h4 className='text-gray-900'>No Comments Yet</h4>
          <span className='text-gray-500'>Be the first to comment</span>
        </div>
      </div>
    )
  }

  // List for I can I  will for single post
  const icaniwillLeaderList = () => {
    return likeList && likeList.length > 0 ? (
      likeList.map((icaniwll: any, index: any) => {
        return (
          <div className='single_comment_view d-flex align-items-start mb-3' key={index}>
            <Link
              to={`/leaders/${icaniwll?.user?.id}`}
              onClick={() => {
                rediecttoLeaderProfile(icaniwll?.user?.id)
              }}
              data-bs-dismiss='modal'
              aria-label='Close'
            >
              <div className='symbol symbol-40px me-3'>
                <Image
                  src={
                    icaniwll?.user?.profile
                      ? `${API_URL}/containers/userprofile/download/${icaniwll?.user?.profile}`
                      : `${toAbsoluteUrl('./media/avatars/default-user.png')}`
                  }
                  onError={({ currentTarget }: any) => {
                    currentTarget.onerror = null // prevents looping
                    currentTarget.src = `${toAbsoluteUrl('./media/avatars/default-user.png')}`
                  }}
                ></Image>
              </div>
            </Link>
            <Link
              to={`/leaders/${icaniwll?.user?.id}`}
              onClick={() => {
                rediecttoLeaderProfile(icaniwll?.user?.id)
              }}
              data-bs-dismiss='modal'
              aria-label='Close'
            >
              <div className='flex-grow-1 d-flex flex-column commnet_box_right'>
                <div className='commented_user_details mb-1'>
                  <Link to='' className='ilead-text text-hover-primary fs-6 fs-md-5 fw-bold me-2'>
                    {icaniwll.user.name}
                  </Link>
                  <span className='round-separator me-2'></span>
                  <span className='text-gray-600'>{moment(icaniwll.created_at).fromNow()}</span>
                </div>
                {/* <div className='commnet_content text-black'> */}
                {/* {itemMessage.chat} */}
                {/* </div> */}
              </div>
            </Link>
          </div>
        )
      })
    ) : (
      <div className='single_comment_view d-flex align-items-start'>
        <div className='empty-comments text-center w-100'>
          {/* <Image
           fluid
           src={toAbsoluteUrl('/media/ilead/empty-comments.png')}
           className='w-150px mb-4'
         ></Image>
         <h4 className='text-gray-900'>No Comments Yet</h4> */}
          <span className='text-gray-500'>No "I CAN I WILL" by any Leader Yet</span>
        </div>
      </div>
    )
  }

  useEffect(() => {
    setUserId(userDetail?.id ?? '')
  }, [userDetail])

  const isLiked = () => {
    let tempArray = commentData?.usertopiclikes?.filter((x: any) => x.user_id == userId) ?? []
    return tempArray?.length > 0 ? true : false
  }

  return (
    <>
      <div className='comment-count d-flex flex-nowrap justify-content-between px-2 px-4 pb-3 text-gray-700 fs-13'>
        <div
          className='like_counter'
          data-bs-toggle='modal'
          data-bs-target={`#post_icaniwill_leader_view${commentIndex}`}
          onClick={() => {
            getIcaniwillLeaderList()
          }}
        >
          {commentData?.totallikes === 0
            ? ''
            : isLiked() === false && commentData?.totallikes === 1
              ? `I Can I Will by ${commentData?.usertopiclikes[0]?.user?.name}`
              : isLiked() === false && commentData?.totallikes === 2
                ? `I Can I Will by ${commentData?.usertopiclikes[0]?.user?.name} and ${commentData?.totallikes - 1
                } other`
                : isLiked() === false && commentData?.totallikes > 2
                  ? `I Can I Will by ${commentData?.usertopiclikes[0]?.user?.name} and ${commentData?.totallikes - 1
                  } others`
                  : isLiked() === true && commentData?.totallikes === 1
                    ? 'I Can I Will by you'
                    : isLiked() === true && commentData?.totallikes === 2
                      ? `I Can I Will by you and ${commentData?.totallikes - 1} other`
                      : isLiked() === true && commentData?.totallikes > 2
                        ? `I Can I Will by you and ${commentData?.totallikes - 1} others`
                        : ''}
        </div>
        <div
          className='comment_counter'
          data-bs-toggle='modal'
          data-bs-target={`#post_comment_view${commentIndex}`}
          onClick={() => {
            getPostComment()
          }}
        >
          {/* {commentData?.totalcomments ?? 0}  Comments */}
          {`${commentData?.totalcomments
            ? `${commentData?.totalcomments > 1
              ? commentData?.totalcomments + ' Comments'
              : commentData?.totalcomments + ' Comment'
            }`
            : 0 + ' Comment'
            }`}
        </div>
      </div>
      <div className='card-footer px-3 py-3'>
        <ul className='nav py-0 d-flex  justify-content-between flex-nowrap'>
          <li
            className='nav-item w-50 justify-content-center d-flex action position-relative pe-3'
          >
            <div
              onClick={() => {
                if (loading?.like == false) {
                  likePostAction()
                }
              }}
              className='nav-link btn-color-gray-800 px-2 fw-normal w-100 position-relative fs-7 fs-md-6 cursor-pointer'
            >
              <Heart isClick={isLiked()} onClick={() => { }}></Heart>
              {loading?.like ? 'Please Wait...' : 'I Can I Will'}
            </div>
          </li>
          <li
            className='nav-item w-50 justify-content-center d-flex pe-3'
          >
            <Button
              variant='link'
              className='nav-link btn btn-sm btn-color-gray-800 px-2 fw-normal w-100 position-relative fs-7 fs-md-6'
              data-bs-toggle='modal'
              data-bs-target={`#post_comment_view${commentIndex}`}
              onClick={() => {
                getPostComment()
              }}
            >
              <i className='bi bi-chat'></i> Comment
            </Button>
          </li>

          {userDetail?.role_id == 4 ||
            userDetail?.role_id == 5 ||
            userDetail?.role_id == 6 ||
            commentData?.topic_id == 1 || // Announcement
            commentData?.topic_id === 3 || // Help / Referral
            commentData?.topic_id === 7 || // Thank You
            commentData?.topic_id === 10 || // Group Meeting Report
            commentData?.topic_id === 12 || // Ideas / Achievement
            commentData?.topic_id === 20 || // business referral
            commentData?.topic_id === 21 || // business connect
            commentData?.topic_id === 22 ? ( // business generate
            <>
              {/*   className={`card card-flush mb-4 post-card ${item.isactive ? '' : 'hidePost'}`} */}
              <li
                className={`nav-item ${userDetail?.role_id == 4 ||
                  userDetail?.role_id == 5 ||
                  userDetail?.role_id == 6 ||
                  commentData?.canHide ||
                  commentData?.canDeletePost
                  ? 'justify-content-start'
                  : 'justify-content-center'
                  } d-flex flex-1 position-relative`}
              >
                {commentData?.topic_id === 1 ||
                  commentData?.topic_id === 3 ||
                  commentData?.topic_id === 10 ||
                  (commentData?.topic_id === 12 && userDetail?.id == commentData?.user_id) ||
                  (commentData?.topic_id === 7 &&
                    (userDetail?.id == commentData?.thankyou_receive_userid ||
                      userDetail?.id == commentData?.thankyou_send_userid)) ||
                  (commentData?.topic_id === 20 &&
                    (userDetail?.id == commentData?.business_referral_receiver ||
                      userDetail?.id == commentData?.business_referral_sender)) ||
                  (commentData?.topic_id === 21 &&
                    (userDetail?.id == commentData?.business_connect_receiver ||
                      userDetail?.id == commentData?.business_connect_sender)) ||
                  (commentData?.topic_id === 22 &&
                    (userDetail?.id == commentData?.business_generate_receiver ||
                      userDetail?.id == commentData?.business_generate_sender)) ? (
                  // <RWebShare
                  //   data={{
                  //     text: `${commentData?.description ?? ''}`,
                  //     url: `${commentData?.posturl ?? ''}`,
                  //     title: `${commentData?.topics?.name ?? ''}`,
                  //   }}
                  // >
                  //   <Button
                  //     variant='link'
                  //     className='nav-link btn btn-sm btn-color-gray-800 px-2 fw-normal w-100 share-button position-relative fs-7 fs-md-6 action position-relative'
                  //   >
                  //     <i className='bi bi-share-fill'></i>
                  //   </Button>
                  // </RWebShare>
                  <></>
                ) : (
                  <></>
                )}

                {userDetail?.role_id == 4 ||
                  userDetail?.role_id == 5 ||
                  userDetail?.role_id == 6 ||
                  (commentData?.canHide &&
                    !(commentData.topic_id == 20) &&
                    !(commentData.topic_id == 21) &&
                    !(commentData.topic_id == 22)) ||
                  (commentData?.canHide &&
                    commentData.topic_id == 20 &&
                    (userDetail?.id == commentData?.business_referral_receiver ||
                      userDetail?.id == commentData?.business_referral_sender)) ||
                  (commentData?.canHide &&
                    commentData.topic_id == 21 &&
                    (userDetail?.id == commentData?.business_connect_receiver ||
                      userDetail?.id == commentData?.business_connect_sender)) ||
                  (commentData?.canHide &&
                    commentData.topic_id == 22 &&
                    (userDetail?.id == commentData?.business_generate_receiver ||
                      userDetail?.id == commentData?.business_generate_sender)) ? (
                  <>
                    {/* <Dropdown
                      className={`${
                        userDetail?.role_id == 4 ||
                        userDetail?.role_id == 5 ||
                        userDetail?.role_id == 6 ||
                        (commentData?.canHide &&
                          !(commentData.topic_id == 20) &&
                          !(commentData.topic_id == 21) &&
                          !(commentData.topic_id == 22)) ||
                        (commentData?.canHide &&
                          commentData.topic_id == 20 &&
                          (userDetail?.id == commentData?.business_referral_receiver ||
                            userDetail?.id == commentData?.business_referral_sender)) ||
                        (commentData?.canHide &&
                          commentData.topic_id == 21 &&
                          (userDetail?.id == commentData?.business_connect_receiver ||
                            userDetail?.id == commentData?.business_connect_sender)) ||
                        (commentData?.canHide &&
                          commentData.topic_id == 22 &&
                          (userDetail?.id == commentData.business_generate_receiver ||
                            userDetail?.id == commentData.business_generate_sender))
                          ? 'position-relative'
                          : 'position-absolute'
                      } end-0`}
                    >
                      <Dropdown.Toggle
                        variant='link'
                        id='dropdown-basic'
                        className='nav-link btn btn-sm btn-color-gray-800 px-2 me-0 fw-normal w-50px align-items-center'
                      >
                        <i className='bi bi-three-dots-vertical me-0 pe-0'></i>
                      </Dropdown.Toggle>
                      <Dropdown.Menu className='w-150px px-5'>
                        {userDetail?.role_id == 4 ||
                        userDetail?.role_id == 5 ||
                        userDetail?.role_id == 6 ||
                        (commentData?.canHide &&
                          !(commentData.topic_id == 20) &&
                          !(commentData.topic_id == 21) &&
                          !(commentData.topic_id == 22)) ||
                        (commentData?.canHide &&
                          commentData.topic_id == 20 &&
                          (userDetail?.id == commentData?.business_referral_receiver ||
                            userDetail?.id == commentData?.business_referral_sender)) ||
                        (commentData?.canHide &&
                          commentData.topic_id == 21 &&
                          (userDetail?.id == commentData?.business_connect_receiver ||
                            userDetail?.id == commentData?.business_connect_sender)) ||
                        (commentData?.canHide &&
                          commentData.topic_id == 22 &&
                          (userDetail?.id == commentData?.business_generate_receiver ||
                            userDetail?.id == commentData?.business_generate_sender)) ? (
                          <Button
                            variant='link'
                            className='text-start  pt-2 pb-3 d-block'
                            onClick={() => {
                              showHideAction()
                            }}
                            disabled={loading.hide}
                          >
                            {loading.hide ? 'Loading...' : commentData?.isactive ? 'Hide' : 'Show'}
                          </Button>
                        ) : (
                          <> </>
                        )}
                        {userDetail?.role_id == 4 ||
                        userDetail?.role_id == 5 ||
                        userDetail?.role_id == 6 ||
                        commentData?.canDeletePost ? (
                          <Button
                            variant='link'
                            className='text-start pt-2 pb-3 d-block'
                            data-bs-toggle='modal'
                            data-bs-target={`#delete_post_modal${commentIndex}`}
                          >
                            Delete
                          </Button>
                        ) : (
                          <></>
                        )}
                      </Dropdown.Menu>
                    </Dropdown> */}
                  </>
                ) : (
                  <></>
                )}
              </li>
            </>
          ) : (
            <></>
          )}
          {/* ================== Delete Model Start ==================== */}
          <div className='modal fade' tabIndex={-1} id={`delete_post_modal${commentIndex}`}>
            <div className='modal-dialog modal-dialog-centered'>
              <div className='modal-content'>
                <div className='modal-header'>
                  <h5 className='modal-title'>Are you sure you want to delete this post?</h5>
                  <div
                    className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                    data-bs-dismiss='modal'
                    aria-label='Close'
                  >
                    <KTSVG
                      path='/media/icons/duotune/arrows/arr061.svg'
                      className='svg-icon svg-icon-2x'
                    />
                  </div>
                </div>
                <div className='modal-body fs-5'>
                  <p>This will delete this post permanently. You cannot undo this action.</p>
                </div>
                <div className='modal-footer'>
                  <button
                    type='button'
                    className='btn btn-primary'
                    disabled={loading.delete}
                    onClick={() => {
                      deletePostAction()
                    }}
                  >
                    {loading?.delete ? 'Please Wait...' : 'Yes'}
                  </button>
                  <button
                    type='button'
                    className='btn btn-light'
                    data-bs-dismiss='modal'
                    ref={deleteModelPostRef}
                  >
                    No
                  </button>
                </div>
              </div>
            </div>
          </div>
          {/* ================== Delete Model End ==================== */}
        </ul>
      </div>
      {/* ================== Comment Model Start ==================== */}
      <div className='modal fade' tabIndex={-1} id={`post_comment_view${commentIndex}`}>
        <div className='modal-dialog modal-dialog-scrollable modal-fullscreen-md-down modal-dialog-centered modal-md'>
          <div className='modal-content'>
            <div className='modal-header p-3'>
              <h5 className='modal-title text-center w-100'>Comments</h5>
              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon svg-icon-2x'
                />
              </div>
            </div>
            {loading.list ? (
              <h5 className='text-center'>Loading...</h5>
            ) : (
              <div className='modal-body comment-body'>{commentList()}</div>
            )}
            <div className='modal-footer py-2'>
              <div className='symbol symbol-40px'>
                <Image
                  src={
                    userDetail?.profile
                      ? `${API_URL}/containers/userprofile/download/${userDetail?.profile}`
                      : `${toAbsoluteUrl('./media/avatars/default-user.png')}`
                  }
                  onError={({ currentTarget }: any) => {
                    currentTarget.onerror = null // prevents looping
                    currentTarget.src = `${toAbsoluteUrl('./media/avatars/default-user.png')}`
                  }}
                ></Image>
              </div>
              <div className='comment_text_box m-0 flex-1'>
                {/* <Form> */}
                <Form.Control
                  className='border-0 fw-normal text-black fs-6'
                  placeholder='Write your comment...'
                  as='textarea'
                  value={formik.values.chattext}
                  onChange={(e: any) => {
                    if (e.target.value.trimStart().length <= 250) {
                      formik.setFieldValue('chattext', e.target.value.trimStart())
                    }
                  }}
                  autoFocus
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      formik.handleSubmit()
                      return
                    }
                  }}
                />
                <div className={`d-flex justify-content-end ${formik.values.chattext.length >= 250 ? "text-danger" : ""}`}>
                  <b>{250 - formik.values.chattext.length}{" "} Character Left</b>
                </div>
                {/* </Form> */}
              </div>

              <Button
                variant='link'
                type='button'
                className='p-0 m-0 symbol symbol-40px symbol-circle'
                disabled={loading.send}
                onClick={() => {
                  formik.handleSubmit()
                }}
              >
                <div className='symbol-label bg-primary'>
                  <i className='fa-regular fa-paper-plane text-white'></i>
                </div>
              </Button>
            </div>
          </div>
        </div>
      </div>
      {/* ==================Comment Model End ==================== */}

      {/* ================== I CAN I WILL by Leaders Model Start ==================== */}
      <div className='modal fade' tabIndex={-1} id={`post_icaniwill_leader_view${commentIndex}`}>
        <div className='modal-dialog modal-dialog-scrollable modal-fullscreen-md-down modal-dialog-centered modal-md'>
          <div className='modal-content'>
            <div className='modal-header p-3'>
              <h5 className='modal-title text-center w-100'>I CAN I WILL by Leaders</h5>
              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon svg-icon-2x'
                />
              </div>
            </div>
            {loading.list ? (
              <h5 className='text-center'>Loading...</h5>
            ) : (
              <div className='modal-body comment-body'>{icaniwillLeaderList()}</div>
            )}
            {/* <div className='modal-footer py-2'>
              <div className='symbol symbol-40px'>
                <Image
                  src={
                    userDetail?.profile
                      ? `${API_URL}/containers/userprofile/download/${userDetail?.profile}`
                      : `${toAbsoluteUrl('./media/avatars/default-user.png')}`
                  }
                  onError={({ currentTarget }: any) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src = `${toAbsoluteUrl('./media/avatars/default-user.png')}`;
                  }}
                ></Image>
              </div>
              <div className='comment_text_box m-0 flex-1'>
                <Form>
                  <Form.Control
                    className='border-0 fw-normal text-black fs-6'
                    type='text'
                    placeholder='Write your comment...'
                    value={formik.values.chattext}
                    onChange={(e: any) => {
                      formik.setFieldValue('chattext', e.target.value)
                    }}
                    autoFocus
                  />
                </Form>
              </div>

              <Button
                variant='link'
                type='button'
                className='p-0 m-0 symbol symbol-40px symbol-circle'
                disabled={loading.send}
                onClick={() => {
                  formik.handleSubmit()
                }}
              >
                <div className='symbol-label bg-primary'>
                  <i className='fa-regular fa-paper-plane text-white'></i>
                </div>
              </Button>
            </div> */}
          </div>
        </div>
      </div>
      {/* ==================I CAN I WILL by Leaders Model End ==================== */}
    </>
  )
}
