import React, { useRef } from "react";
import AddEditEventForm from "./AddEditEventForm";

const EditEvents = ({id, eventID}:any) => {

    const closebutton = useRef<any>()
    return (
        <>
            <div
                className='modal fade visitor-details'
                // id='editevent'
                id={`editevent${id}`}
                // id={`editevent${eventID}`}
                data-bs-backdrop='static'
                data-bs-keyboard='false'
                tabIndex={-1}
                aria-labelledby='staticBackdropLabel'
                aria-hidden='true'
            >
                <div className='modal-dialog me-0 mb-0 mt-0 border-radius-0'>
                    <div className='modal-content '>

                        {/* <div className='modal-header border-bottom border-gray-300'>
                            <h5 className='modal-title text-primary' id='staticBackdropLabel'>
                                Update Event
                            </h5>
                            <button
                                type='button'
                                className='btn-close'
                                data-bs-dismiss='modal'
                                aria-label='Close'
                                ref={closebutton}
                            ></button>
                        </div> */}
                       {/* <AddEditEventForm invokedFrom={"EditEvent"}/> */}
                       { eventID == id ?
                        <AddEditEventForm id={id}invokedFrom={"EditEvent"}/>
                        : null
                       }

                    </div>
                </div>
            </div>
        </>
    )
}

export default EditEvents