import axios from 'axios'
import {apiUrl} from '../../../../../apiurl'

const API_URL = apiUrl
const GET_ALL_POST = `${API_URL}/usertopics/allpost`
const SET_BOOKMARK_API = `${API_URL}/bookmarkedposts`
const REMOVE_BOOKMARK_API = `${API_URL}/bookmarkedposts/removebookmark`
const CREATE_COMMENT_API = `${API_URL}/usertopicchats`
const GET_COMMENT_API = `${API_URL}/usertopics/singlepost?filter[where][uuid]=`
const HIDE_POST_API = `${API_URL}/usertopics/hidepost`
const DELETE_POST_API = `${API_URL}/usertopics/deletePost?id=`
const POST_LIKE_API = `${API_URL}/usertopiclikes/topiclike`
const DELETE_COMMENT_API = `${API_URL}/usertopicchats`

export function getAllPost(chapterid: any, skip: any, limit: any) {
  return axios
    .get<any>(
      `${GET_ALL_POST}?filter[where][chapter_id]=${chapterid}&filter[skip]=${skip}&filter[limit]=${limit}`
    )
    .then((responce: any) => {
      return responce.data
    })
    .catch((error) => {
      console.error('Error All Post Data:', error)
      throw error
    })
}

export function getAllPostWithFilter(topicid: any, skip: any, limit: any,chapter_id:any) {
  if (topicid === 0) {
    return axios
      .get<any>(
        `${GET_ALL_POST}?filter[where][chapter_id]=${chapter_id}&filter[where][bookmarked]=true&filter[skip]=${skip}&filter[limit]=${limit}&filter[order]=created_at DESC`
      )
      .then((responce: any) => {
        return responce.data
      })
      .catch((error) => {
        console.error('Error All Post Data:', error)
        throw error
      })
  } else {
    return axios
      .get<any>(
        `${GET_ALL_POST}?filter[where][chapter_id]=${chapter_id}&filter[where][topic_id]=${topicid}&filter[skip]=${skip}&filter[limit]=${limit}`
      )
      .then((responce: any) => {
        return responce.data
      })
      .catch((error) => {
        console.error('Error All Post Data:', error)
        throw error
      })
  }
}

export function setBookmarkApi(reqData: any) {
  
  return axios.post<any>(SET_BOOKMARK_API, reqData)
    .then((responce: any) => {
      return responce.data
    })
}

export function removeBookmarkApi(reqData: any) {
  return axios.post<any>(REMOVE_BOOKMARK_API, reqData)
    .then((responce: any) => {
      return responce.data
    })
}

export function createCommentApi(reqData: any) {
  return axios.post<any>(CREATE_COMMENT_API, reqData)
    .then((responce: any) => {
      return responce.data
    })
}

export function getCommentApi(reqData: any) {
  return axios.get<any>(`${GET_COMMENT_API}${reqData}`).then((responce: any) => {
    return responce.data
  })
}

export function showHidePostApi(reqData: any) {
  return axios.post<any>(HIDE_POST_API, reqData).then((responce: any) => {
    return responce.data
  })
}

export function deletePostApi(reqData: any) {
  return axios.delete<any>(`${DELETE_POST_API}${reqData}`).then((responce: any) => {
    return responce.data
  })
}

export function likePostApi(reqData: any) {
  return axios.post<any>(POST_LIKE_API, reqData).then((responce: any) => {
    return responce.data
  })
}

export function deleteCommentApi(deleteId: any) {
  return axios.delete<any>(`${DELETE_COMMENT_API}/${deleteId}`)
    .then((responce: any) => {
      return responce.data
    })
}
