import React, { useState } from "react";
import { useNavigate } from "react-router";
import { VisitorpanelsListWrapper } from "./visitorpanels-list/VisitorpanelsList";
import Select from 'react-select'
import AddDetails from "./AddDetails";
import { QueryResponseProvider } from "./visitorpanels-list/core/QueryResponseProvider";
import { ListViewProvider } from "./visitorpanels-list/core/ListViewProvider";
// import AddDetails

const options = [
    { value: 'eklavya', label: 'Eklavya' },
]





const VisitorPanel = () => {

    const navigate = useNavigate()
    const navigateAddDetails = () => {
        // 👇️ navigate to /
        navigate('/add-details')
    }

    const [selectedOption, setSelectedOption] = useState(null)


    return (
      <><QueryResponseProvider>
      <ListViewProvider>
        <div className='row flex-md-block '>
          <div className='col-sm-6 col-md-6 col-lg-6'>
            <h1 className='inv-hst sm-ms-2'> Visitor Panel </h1>
          </div>

          <div className='col-sm-6  col-md-6 col-lg-6 mb-5 text-end'>
            <button
              data-bs-toggle='modal'
              data-bs-target='#adddetails'
              className='btn btn-primary btn-sm waves-effect waves-light '
            >
              {' '}
              Sent Invitation/Add Details{' '}
            </button>
          </div>
        </div>

        <div className='card mx-3 py-8 px-5 px-sm-8 mt-5 '>
          {/* <div className="d-flex justify-content-start mb-3 mt-6 ">
                    <button type="button" className="btn px-5 py-2 me-3 btn-primary">Copy</button>
                    <button type="button" className="btn px-5 py-2 me-3 btn-success">Excel</button>
                    <button type="button" className="btn px-5 py-2 me-3 btn-warning">CSV</button>
                    <button type="button" className="btn px-5 py-2 me-3 btn-danger">PDF</button>
                    <button type="button" className="btn px-5 py-2 me-3 btn-info">Print</button>
                </div> */}

          {/* <div className="d-flex justify-content-start mb-3 mt-6 fs-5 ">
                    Show <span className="mx-2"><select className=" mb-3 p-1 ms-2 text-dark border border-gray-600 rounded-1 fs-6" aria-label=".form-select-lg example">
                        <option selected>10</option>
                        <option value="1">25</option>
                        <option value="2">50</option>
                        <option value="2">100</option>
                    </select> </span> entries

                </div> */}

          {/* <div className='row mx-0 d-sm-block d-md-none mb-8'>
            <div className='col-sm-12 col-md-6 col-lg-6 col-xl-3 mb-5'>
              <h3 className='text-primary fs-5 fw-bold'> Event Date </h3>
              <input type='text' className='form-control mb-3' placeholder=' ' />
            </div>

            <div className='col-sm-12 col-md-6 col-lg-6 col-xl-3 mb-5'>
              <h3 className='text-primary fs-5 fw-bold'> Invitee Name /</h3>
              <input type='text' className='form-control mb-3' placeholder=' ' />
            </div>
            <div className='col-sm-12 col-md-6 col-lg-6 col-xl-3 mb-5'>
              <h3 className='text-primary fs-5 fw-bold'> Invited By </h3>
              <input type='text' className='form-control mb-3' placeholder=' ' />
            </div>
            <div className='col-sm-12 col-md-6 col-lg-6 col-xl-3 mb-5'>
              <h3 className='text-primary fs-5 fw-bold'> Cell No </h3>
              <input type='text' className='form-control mb-3' placeholder=' ' />
            </div>

            <div className='col-sm-12 col-md-6 col-lg-6 col-xl-3 mb-5'>
              <h3 className='text-primary fs-5 fw-bold'> Company </h3>
              <input type='text' className='form-control mb-3' placeholder='' />
            </div>

            <div className='col-sm-12 col-md-6 col-lg-6 col-xl-3 mb-5'>
              <h3 className='text-primary fs-5 fw-bold'> Chapter </h3>
              <Select
                required
                defaultValue={selectedOption}
                onChange={(selectedOption: any) => {
                  setSelectedOption(selectedOption)
                }}
                options={options}
                className='mb-5 text-dark'
              />
            </div>
            <div className='col-sm-12 col-md-6 col-lg-6 col-xl-3 mb-5'>
              <h3 className='text-primary fs-5 fw-bold'> Invited Status </h3>
              <select
                className='form-select form-select-lg mb-3 mt-2'
                aria-label='.form-select-lg example'
              >
                <option selected>Invite</option>
                <option value='1'>Attended</option>
                <option value='2'>Not Attended</option>
              </select>{' '}
            </div>
            <div className='col-sm-12 col-md-6 col-lg-6 col-xl-3'>
              <h3 className='text-primary fs-5 fw-bold'> Membership Status </h3>
              <select
                className='form-select form-select-lg mb-3 mt-2'
                aria-label='.form-select-lg example'
              >
                <option selected> - </option>
                <option value='1'>Interested</option>
                <option value='2'>Not Interested</option>
                <option value='2'>Joined</option>
              </select>{' '}
            </div>
          </div> */}

          <VisitorpanelsListWrapper />
        </div>

        
            <AddDetails />
          </ListViewProvider>
        </QueryResponseProvider>
      </>
    )

}

export default VisitorPanel