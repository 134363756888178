import {ListViewProvider, useListView} from './core/ListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
import {VisitorpanelsListHeader} from './components/header/VisitorpanelsListHeader'
import {VisitorpanelsTable} from './table/VisitorpanelsTable'
import {VisitorpanelEditModal} from './visitorpanel-edit-modal/VisitorpanelEditModal'
import { KTCard } from '../../../../_metronic/helpers'
// import {KTCard} from '../../../../../_metronic/helpers'

const VisitorpanelsList = () => {
  const {itemIdForUpdate} = useListView()
  return (
    <>
      
        {/* <VisitorpanelsListHeader /> */}
        <VisitorpanelsTable />
      
      {itemIdForUpdate !== undefined && <VisitorpanelEditModal />}
    </>
  )
}

const VisitorpanelsListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <VisitorpanelsList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {VisitorpanelsListWrapper}
