/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect} from 'react'
import {useMutation, useQueryClient} from 'react-query'
// import {MenuComponent} from '../../../../../../../_metronic/assets/ts/components'
// import {ID, KTIcon, QUERIES} from '../../../../../../../_metronic/helpers'
import {useListView} from '../../core/ListViewProvider'
import {useQueryResponse} from '../../core/QueryResponseProvider'
import {deleteTemplate} from '../../core/_requests'
// import { MenuComponent } from '../../../../../../_metronic/assets/ts/components'
// import { ID, KTIcon, QUERIES } from '../../../../../../_metronic/helpers'
import { Link, useNavigate } from 'react-router-dom'
// import EditTemplates from '../../../EditTemplate'
import { MenuComponent } from '../../../../../../../_metronic/assets/ts/components'
import { ID, QUERIES } from '../../../../../../../_metronic/helpers'

type Props = {
  id: ID,
  data:any
}

const TemplateActionsCell: FC<Props> = ({id}) => {
  const {setItemIdForUpdate} = useListView()
 const {query} = useQueryResponse()
  const queryClient = useQueryClient()

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const openEditModal = () => {
    setItemIdForUpdate(id)
  }

  const deleteItem = useMutation(() => deleteTemplate(id), {
    // 💡 response of the mutation is passed to onSuccess
    onSuccess: () => {
      // ✅ update detail view directly
      queryClient.invalidateQueries([`${QUERIES.TEMPLATES_LIST}-${query}`])
    },
  })

  const navigate = useNavigate()
  const navigateEditTemplate = () => {
    // 👇️ navigate to /
    navigate('/edit-template')
  }


  return (
    <>
      {/* <a
        href='#'
        className='btn btn-light-success rounded-circle py-2 px-3 btn-sm me-2'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
        style={{lineHeight: '11px !important'}}
        onClick={navigateViewTemplate}
      >
        <i className="fa-regular fa-eye pe-0 fs-6"></i>
      </a> */}

      <Link
        to={`/edit-template/${id}`}
        className='btn btn-light-warning rounded-circle py-2 px-3 btn-sm me-2'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
        style={{lineHeight: '11px !important'}}
        onClick={navigateEditTemplate}
      >
        <i className="fa-regular fa-pen-to-square pe-0 fs-6"></i>
      </Link>
     
      {/* <a
        href='#'
        className='btn btn-light-danger rounded-circle py-2 px-3 btn-sm'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
        style={{lineHeight: '11px !important'}}
        data-bs-toggle="modal" data-bs-target="#deletModal"
      >
        <i className="fa-solid fa-trash-can pe-0 fs-6"></i>
      </a> */}
      {/* begin::Menu */}

    </>
  )
}

export {TemplateActionsCell}
