/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useContext, useEffect, useRef, useState } from 'react'
import { useMutation, useQueryClient } from 'react-query'
// import {MenuComponent} from '../../../../../../../_metronic/assets/ts/components'
// import {ID, KTIcon, QUERIES} from '../../../../../../../_metronic/helpers'
import { useListView } from '../../core/ListViewProvider'
import { useQueryResponse } from '../../core/QueryResponseProvider'
import { activeOrDeactiveAPI, deleteExitinguser, deleteUserAPI } from '../../core/_requests'
import { MenuComponent } from '../../../../../../_metronic/assets/ts/components'
import { ID, KTIcon, QUERIES } from '../../../../../../_metronic/helpers'
import { Link, useNavigate } from 'react-router-dom'
import EditNewLeader from '../../../EditNewLeader'
import { UserContext } from '../../../../../../contextStore'
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button'
import AddEditNewLeader from '../../../AddEditNewLeader'
import { Offcanvas } from 'react-bootstrap'


type Props = {
  id: ID,
  data :any 
}

const ExitinguserActionsCell: FC<Props> = ({ id ,data }) => {
  const { setItemIdForUpdate } = useListView()
  const { query } = useQueryResponse()
  const queryClient = useQueryClient()
  const [updateState,setUpdateState] =  useState(false)
  const closebutton = useRef<any>()
  // const [active ,setActive ] = useState(data?.isactive == 1 ? true : false)
  const { userDetail, chapterID, reload ,setMessage} = useContext(UserContext);

 
  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const openEditModal = () => {
    setItemIdForUpdate(id)
  }

  const deleteItem = useMutation(() => deleteExitinguser(id), {
    // 💡 response of the mutation is passed to onSuccess
    onSuccess: () => {
      // ✅ update detail view directly
      queryClient.invalidateQueries([`${QUERIES.EXITINGUSERS_LIST}-${query}`])
    },
  })

  const navigate = useNavigate()
  const navigateViewExitinguser = () => {
    // 👇️ navigate to /
    navigate(`/leaders/${id}`)
  }

  const navigateEditExitinguser = () => {
    // 👇️ navigate to /
    navigate('/edit-exitinguser')
  }

  const activeOrDeactive = async (id:any,status:any) => {
      try {

        await activeOrDeactiveAPI({id,status}).then((resp:any)=>{
          console.log("resp",resp)
          if(resp?.status == 200 ) {

      queryClient.invalidateQueries([`${QUERIES.EXITINGUSERS_LIST}-${query}`])
      setMessage('success', 'Status Updated successfully')


          }else if (resp?.status == 500 ) {
            setMessage('error', 'Something went wrong')


          } else {
            setMessage('error', resp?.data?.message)

          }
        }).catch((error:any)=>{

          if (error?.status == 500 ) {
            setMessage('error', 'Something went wrong')

            // alert ("somewent worngs")
          }
        })
      }catch (error) {

      }
  }

  const deleteUser= async (id:any) => {
    try {

      await deleteUserAPI(id).then((resp:any)=>{
        console.log("resp",resp)
        if(resp?.status == 200 ) {

        queryClient.invalidateQueries([`${QUERIES.EXITINGUSERS_LIST}-${query}`])
        closebutton.current.click()
        setMessage('success', 'deleted successfully')


        }else if (resp?.status == 500 ) {
          setMessage('error', 'Something went wrong')


        } else {
          setMessage('error', resp?.data?.message)

        }
      }).catch((error:any)=>{

        if (error?.status == 500 ) {
          setMessage('error', 'Something went wrong')

          // alert ("somewent worngs")
        }
      })
    }catch (error) {

    }
}

useEffect(()=>{

},[id])
  return (
    <>
      <div className=' d-flex '>
        <div className='form-check mt-2 form-switch'>
          <input
            className='form-check-input'
            onClick={(e: any) => {
              // let status = e?.target?.value?.trimStart()
              // console.log(e?.target?.value?.trimStart(),"e?.target?.value?.trimStart()")
              // setActive(e?.target?.checked)
              activeOrDeactive(id, e?.target?.checked)
            }}
            type='checkbox'
            checked={data?.isactive}
            id='flexSwitchCheckChecked'
          />
        </div>
        <a
          href='#'
          className='btn btn-light-success rounded-circle py-2 px-3 btn-sm me-2'
          data-kt-menu-trigger='click'
          data-kt-menu-placement='bottom-end'
          style={{lineHeight: '11px !important'}}
          onClick={navigateViewExitinguser}
        >
          <i className='fa-regular fa-eye pe-0 fs-6'></i>
        </a>

        {/* <Link to={`/leaders/${id}`}>
          
        </Link> */}
        <a
          // href=''
          className='btn btn-light-warning rounded-circle py-2 px-3 btn-sm me-2'
          data-kt-menu-trigger='click'
          data-kt-menu-placement='bottom-end'
          style={{lineHeight: '11px !important'}}
          onClick={() => {
            setUpdateState(true)
          }}

          // data-bs-toggle="modal" data-bs-target={`#editnewleader${id}`}
        >
          <i className='fa-regular fa-pen-to-square pe-0 fs-6'></i>
        </a>
        {/* <EditNewLeader id={id}  show={updateState} onHide={setUpdateState(false)}/> */}
        <Modal
          show={updateState}
          onHide={() => {
            setUpdateState(false)
          }}
          backdrop='static'
          keyboard={false}
          tabIndex={-1}
          className='modal fade visitor-details edit-user'
          aria-labelledby='staticBackdropLabel'
        >
          <Modal.Header closeButton>
            <Modal.Title id='staticBackdropLabel'>Edit Leader</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <AddEditNewLeader id={id}  onHide={() => {
            setUpdateState(false)
          }}/>
          </Modal.Body>
          {/* <Modal.Footer>
            <Button
              variant='secondary'
              onClick={() => {
                setUpdateState(false)
              }}
            >
              Close
            </Button>
            
          </Modal.Footer> */}
        </Modal>
        <a
          href='#'
          className='btn btn-light-danger rounded-circle py-2 px-3 btn-sm'
          data-kt-menu-trigger='click'
          data-kt-menu-placement='bottom-end'
          style={{lineHeight: '11px !important'}}
          data-bs-toggle='modal'
          data-bs-target={`#deletModal${id}`}
        >
          <i className='fa-solid fa-trash-can pe-0 fs-6'></i>
        </a>
        {/* begin::Menu */}

        <div
          className='modal fade'
          id={`deletModal${id}`}
          tabIndex={-1}
          aria-labelledby='exampleModalLabel'
          aria-hidden='true'
        >
          <div className='modal-dialog'>
            <div className='modal-content'>
              {/* <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLabel">Modal title</h5>
        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div> */}
              <div className='modal-body pb-0 position-relative"'>
                <div className='col-12 text-center pt-5 pb-5'>
                <i className="fa-solid fa-trash-can text-danger mb-5" style={{ fontSize: '50px' }}></i>
                <h1 className='mt-10'> Are you sure? </h1>
                <p>You want to delete this Leader.</p>
                </div>
              </div>
              {/* <div className='modal-footer'>
                <button type='button' onClick={()=>{deleteUser(id)}} className='btn btn-primary'>
                  {' '}
                  yes{' '}
                </button>
                <button type='button'  ref={closebutton}className='btn btn-secondary' data-bs-dismiss='modal'>
                  {' '}
                  No{' '}
                </button>
              </div> */}
              <div className="modal-footer justify-content-center">
              <button type="button" onClick={()=>{deleteUser(id)}}  className="btn btn-sm btn-primary me-5"> Confirm </button>
              <button type="button"  ref={closebutton} className="btn btn-sm btn-secondary" data-bs-dismiss="modal"> Cancel </button>
            </div>
            </div>
            

          </div>
        </div>

      </div>
    
    </>
  )
}

export { ExitinguserActionsCell }
