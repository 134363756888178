import { CKEditor } from "ckeditor4-react"

const CustomCkEditor = ({ data, changeData }: any) => {
    console.log("data prop:", data); 
    return (
        <>
            <CKEditor
                initData={data}
                config={{
                    toolbar: [
                        ['Bold', 'Italic'],
                        ['NumberedList', 'BulletedList'],
                        ['Undo', 'Redo']
                    ],
                }}
                onChange={evt => {
                    const editorData = evt.editor.getData();
                    console.log("CKEditor content:", editorData); 
                    changeData(editorData);
                }}
            />
        </>
    );
};

export { CustomCkEditor }