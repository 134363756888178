import React, { useRef } from "react";
import AddEditNewLeader from "./AddEditNewLeader";
import { Button, Modal } from "react-bootstrap";


interface MyModalProps {
    show?: boolean;
    onHide?:any;
    // id ?: any
  }
const AddNewLeader = ({show,onHide}:MyModalProps) => {
    // const closebutton = useRef<any>()



    return (
        <>
      
            {/* <div
                className='modal fade visitor-details'
                id='addnewleader'
                data-bs-backdrop='static'
                data-bs-keyboard='false'
                tabIndex={-1}
                aria-labelledby='staticBackdropLabel'
                aria-hidden='true'
            >
                <div className='modal-dialog me-0 mb-0 mt-0 border-radius-0'>
                    <div className='modal-content '>

                        <div className='modal-header border-bottom border-gray-300'>
                            <h5 className='modal-title text-primary' id='staticBackdropLabel'>
                                Add New Leader
                            </h5>
                            <button
                                type='button'
                                className='btn-close'
                                data-bs-dismiss='modal'
                                aria-label='Close'
                                ref={closebutton}
                            ></button>
                        </div>
                        <AddEditNewLeader />
                    </div>
                </div>
            </div> */}

<Modal
          show={show}
          onHide={() => {
            onHide()
          }}
          backdrop='static'
          keyboard={false}
          tabIndex={-1}
          className='modal fade visitor-details edit-user'
          aria-labelledby='staticBackdropLabel'
        >
          <Modal.Header closeButton>
            <Modal.Title id='staticBackdropLabel'>Add New Leader</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <AddEditNewLeader  onHide={onHide}/>
          </Modal.Body>
          {/* <Modal.Footer>
            <Button
              variant='secondary'
              onClick={() => {
                onHide(false)
              }}
            >
              Close
            </Button> 
            
          </Modal.Footer> */}
        </Modal>
        </>
    )
}

export default AddNewLeader