import React from "react";
import { useMemo, useState, useEffect, useContext, useRef } from 'react'
import { VisitorsListWrapper } from "./visitors-list/VisitorsList";
import SendInvitation from "./SendInvitation";
import { QueryResponseProvider } from "./visitors-list/core/QueryResponseProvider";
import { ListViewProvider } from "../summery-session/summarysessions-list/core/ListViewProvider";


const VisitorHistory = () => {
    const closebutton = useRef<any>()

    return (

        <>
            <QueryResponseProvider>
      <ListViewProvider>
            <div className="row flex-md-block ">
                <div className='col-sm-6 col-md-6 col-lg-6'><h1 className='inv-hst sm-ms-2'> Invitation History </h1></div>
                
            <div className='col-sm-6  col-md-6 col-lg-6 mb-5 text-end'>
                <button data-bs-toggle="modal" data-bs-target="#sendinvitation" className="btn btn-primary waves-effect waves-light btn-sm" ref={closebutton}
                    style={{marginRight:"1rem"}}>
                    Send Invitation
                </button>
            </div>
            </div>


            <div className='card mx-3 py-8 px-5 px-sm-8 mt-5 '>
            <div className='mx-3 mx-md-0 pt-5'>
                <VisitorsListWrapper />
            </div>
            </div>
            


            <SendInvitation />
            </ListViewProvider>
    </QueryResponseProvider>
        </>


    )


}

export default VisitorHistory