/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useContext, useEffect, useState} from 'react'
import {useMutation, useQueryClient} from 'react-query'
// import {MenuComponent} from '../../../../../../../_metronic/assets/ts/components'
// import {ID, KTIcon, QUERIES} from '../../../../../../../_metronic/helpers'
import {useListView} from '../../core/ListViewProvider'
import {useQueryResponse} from '../../core/QueryResponseProvider'
import {changeSessionAttendanceStatus, deleteSessionattendance} from '../../core/_requests'
import { MenuComponent } from '../../../../../../_metronic/assets/ts/components'
import { ID, KTIcon, QUERIES } from '../../../../../../_metronic/helpers'
import { UserContext } from '../../../../../../contextStore'

const SessionattendanceActionsCell: FC<any> = ({id, value}) => {
  const {setItemIdForUpdate} = useListView()
  const {refetch} = useQueryResponse()
  const {query} = useQueryResponse()
  const queryClient = useQueryClient()
  const [loading,setLoading] = useState(false)
  const {setMessage} = useContext(UserContext);

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const openEditModal = (status:any) => {
    changeStatus(id,status)
  }

  const changeStatus = async (id:any,status:any) => {
    setLoading(true)
    try{
      await changeSessionAttendanceStatus(id,status).then((response:any)=>{
        if(response.id){
          refetch()
          setMessage('success',response?.message ?? "Status Changed Successfully")
        }else{
          setMessage('error',response?.message ?? "SomeThing Went Wrong")
        }
        setLoading(false)
      }).catch((error:any)=>{
        setLoading(false)
      })
    }
    catch(error:any){
      setLoading(false)
      setMessage('error',error?.message ?? "SomeThing Went Wrong")
    }
  }

  return (
    <>
      <a
        href='#'
        className='btn btn-light btn-active-light-primary fs-5 btn-sm'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
      >
        {loading ? "Loading..." : (()=>{
          if(value == 0){
            return "Absent"
          }
          else if(value == 1){
            return "Present"
          }
          else if(value == 2){
            return "Substitute Present"
          }
          else{
            return ""
          }
        })()}
        <KTIcon iconName='down' className='fs-5 m-0' />
      </a>
      {/* begin::Menu */}
      <div
        className='menu menu-sub menu-sub-dropdown text-start menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-5 w-200px py-4'
        data-kt-menu='true'
      >
        
        {/* begin::Menu item */}
        <div className='menu-item px-3'>
          <a className='menu-link px-3' onClick={()=>{
            if(value != 1){
              openEditModal(1)
            }
          }}>
            Present
          </a>
        </div>

        {/* end::Menu item */}
        <div className='menu-item px-3'>
          <a className='menu-link px-3' onClick={()=>{
            if(value != 0){
              openEditModal(0)
            }
          }}>
            Absent
          </a>
        </div>
        {/* begin::Menu item */}

        {/* end::Menu item */}
        <div className='menu-item px-3'>
          <a className='menu-link px-3' onClick={()=>{
            if(value != 2){
              openEditModal(2)
            }
          }}>
            Substitute Present
          </a>
        </div>
        {/* begin::Menu item */}

        {/* <div className='menu-item px-3'>
          <a
            className='menu-link px-3'
            data-kt-sessionattendances-table-filter='delete_row'
            // onClick={async () => await deleteItem.mutateAsync()}
          >
            Substitute Present
          </a>
        </div> */}
        {/* end::Menu item */}
      </div>
      {/* end::Menu */}
    </>
  )
}

export {SessionattendanceActionsCell}
