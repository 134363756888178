import {useQuery} from 'react-query'
import {VisitorEditModalForm} from './VisitorEditModalForm'
// import {isNotEmpty, QUERIES} from '../../../../../../_metronic/helpers'
import {useListView} from '../core/ListViewProvider'
import { QUERIES, isNotEmpty } from '../../../../../_metronic/helpers'
import { getVisitorById } from '../core/_requests'
// import { getVisitorById } from '../../../apps/visitor-management/visitors-list/core/_requests'
// import {getVisitorById} from '../core/_requests'

const VisitorEditModalFormWrapper = () => {
  const {itemIdForUpdate, setItemIdForUpdate} = useListView()
  const enabledQuery: boolean = isNotEmpty(itemIdForUpdate)
  const {
    isLoading,
    data: visitor,
    error,
  } = useQuery(
    `${QUERIES.VISITORS_LIST}-visitor-${itemIdForUpdate}`,
    () => {
      return getVisitorById(itemIdForUpdate)
    },
    {
      cacheTime: 0,
      enabled: enabledQuery,
      onError: (err) => {
        setItemIdForUpdate(undefined)
        console.error(err)
      },
    }
  )

  if (!itemIdForUpdate) {
    return <VisitorEditModalForm isVisitorLoading={isLoading} visitor={{id: undefined}} />
  }

  if (!isLoading && !error && visitor) {
    return <VisitorEditModalForm isVisitorLoading={isLoading} visitor={visitor} />
  }

  return null
}

export {VisitorEditModalFormWrapper}
