import { useMemo, useState, useEffect, useContext } from 'react'
import { useTable, ColumnInstance, Row } from 'react-table'
import { CustomHeaderColumn } from './columns/CustomHeaderColumn'
import { CustomRow } from './columns/CustomRow'
import { useQueryResponseData, useQueryResponseLoading } from '../core/QueryResponseProvider'
import { eventsColumns } from './columns/_columns'
import { Event } from '../core/_models'
import { EventsListLoading } from '../components/loading/EventsListLoading'
import { EventsListPagination } from '../components/pagination/EventsListPagination'
import { KTCardBody, defaultEvents } from '../../../../../_metronic/helpers'
import { useQueryRequest } from '../core/QueryRequestProvider'
import { getChaptersList } from '../core/_requests'
import { CopyEventsTable } from './CopyEventsTable'
// import {KTCardBody} from '../../../../../../_metronic/helpers'

const EventsTable = () => {
  const [chapterList, setChapterList] = useState<any>([
    { label: "All", value: "" }
  ])

  const { state, updateState } = useQueryRequest() as any

  const events = useQueryResponseData()
  // const events = defaultEvents
  const isLoading = useQueryResponseLoading()
  const data = useMemo(() => events, [events])

  const columns = useMemo(() => eventsColumns, [])
  const { getTableProps, getTableBodyProps, headers, rows, prepareRow } = useTable({
    columns,
    data,
  })

  const getChapter = async () => {
    await getChaptersList().then((response: any) => {
      if (response && response?.length > 0) {
        let clist = response?.map((x: any) => {
          return { label: x?.name, value: x?.id }
        }) as any ?? []
        clist.unshift({ label: "All", value: "" })
        setChapterList(clist)
      }
      // response 
    }).catch((error: any) => {
      console.log("error", error)
    })
  }

  useEffect(() => {
    getChapter()
  }, [])

  return (
    <KTCardBody className='p-0'>
      <CopyEventsTable />
      <div className='row mx-0 d-sm-block d-md-none mb-8'>
        <div className='col-sm-12 col-md-6 col-lg-6 col-xl-3'>
          <h3 className='text-primary fs-5 fw-bold'> Event Name </h3>
          <input type='text' className='form-control mb-3'
          value={state?.filter?.event_name}
          onChange={(e: any) => {
                    let filter = state?.filter ?? {}
                    filter['event_name'] = e.target.value
                    updateState({...state, ...{filter: filter}})
                  }}
          placeholder=' ' />
        </div>
        <div className='col-sm-12 col-md-6 col-lg-6 col-xl-3'>
          <h3 className='text-primary fs-5 fw-bold'> Event Date </h3>
          <input type='date' className='form-control mb-3' 
          value={state?.filter?.event_date}
          onChange={(e: any) => {
            let filter = state?.filter ?? {}
            filter['event_date'] = e.target.value
            updateState({...state, ...{filter: filter}})
          }}
          placeholder=' ' />
        </div>
        <div className='col-sm-12 col-md-6 col-lg-6 col-xl-3'>
          <h3 className='text-primary fs-5 fw-bold'> Chapter </h3>
          <select
            className='form-select form-select-lg mb-3 mt-2'
            aria-label='.form-select-lg example'
            onChange={(e: any) => {
                let filter = state?.filter ?? {}
                filter['chapter_id'] = e.target.value
                updateState({...state, ...{filter: filter}})
              }}
          >
            {chapterList.map((x: any) => {
               return <option value={x?.value}>{x?.label}</option>
            })}
            {/* <option selected>All</option>
            <option value='1'>Trustee</option>
            <option value='2'>Eklavya</option> */}
          </select>
        </div>
        <div className='col-sm-12 col-md-6 col-lg-6 col-xl-3'>
          <h3 className='text-primary fs-5 fw-bold'> Venue </h3>
          <input type='text' className='form-control mb-3' 
          value={state?.filter?.venue}
          onChange={(e: any) => {
            let filter = state?.filter ?? {}
            filter['venue'] = e.target.value
            updateState({...state, ...{filter: filter}})
          }}
          placeholder=' ' />
        </div>
        <div className='col-sm-12 col-md-6 col-lg-6 col-xl-3'>
          <h3 className='text-primary fs-5 fw-bold'> Address </h3>
          <input type='text' className='form-control mb-3' 
          value={state?.filter?.address}
            onChange={(e: any) => {
              let filter = state?.filter ?? {}
              filter['address'] = e.target.value
              updateState({...state, ...{filter: filter}})
            }}
          placeholder=' ' />
        </div>
        <div className='col-sm-12 col-md-6 col-lg-6 col-xl-3'>
          <h3 className='text-primary fs-5 fw-bold'> </h3>
        </div>
      </div>
      <div className='table-responsive mb-8 data-table-report'>
        <table
          id='kt_table_events'
          className='table align-middle table-row-dashed fs-6 gy-5 dataTable table-striped  no-footer'
          {...getTableProps()}
        >
          <thead>
            <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0 border-bottom border-gray-100'>
              {headers.map((column: ColumnInstance<Event>) => (
                <CustomHeaderColumn key={column.id} column={column} />
              ))}
            </tr>
          </thead>

          <thead className='table-sub-hdr'>
            <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
              {/* {headers.map((column: ColumnInstance<Sessionattendance>) => (
                <input type="text"/>
              ))} */}
              {/* <th className='p-3'> <input type="text" className="form-control mb-3" placeholder=" " /> </th> */}
              <th className='p-3'>
                {' '}
                <input
                  type='text'
                  className='form-control mb-3'
                  placeholder=' '
                  value={state?.filter?.event_name}
                  onChange={(e: any) => {
                    let filter = state?.filter ?? {}
                    filter['event_name'] = e.target.value
                    updateState({...state, ...{filter: filter}})
                  }}
                />
              </th>
              {/* <th className='p-3'> <input type="date" className="form-control mb-3" placeholder="" /> </th> */}
              <th className='p-3'>
                {' '}
                <input
                  type='date'
                  className='form-control mb-3'
                  placeholder=''
                  value={state?.filter?.event_date}
                  onChange={(e: any) => {
                    let filter = state?.filter ?? {}
                    filter['event_date'] = e.target.value
                    updateState({...state, ...{filter: filter}})
                  }}
                />
              </th>
              {/* <th className='p-3'> 
                <select className="form-select form-select-lg mb-3 mt-2" aria-label=".form-select-lg example"
                          onChange={(e:any)=>{
                            let filter = state?.filter ?? {}
                            filter['chapter'] = e.target.value
                            updateState({...state,...{filter:filter}})
                          }}
                >
                  <option selected>All</option>
                  <option value="Trustee">Trustee</option>
                  <option value="Eklavya">Eklavya</option>
                </select>
              </th> */}

              <th className='p-3'>
                {' '}
                <select
                  className='form-select form-select-lg mb-3 mt-2'
                  aria-label='.form-select-lg example'
                  // value={state?.filter?.chapter_id}
                  // value={state?.filter?.chapter}
                  onChange={(e: any) => {
                    let filter = state?.filter ?? {}
                    filter['chapter_id'] = e.target.value
                    updateState({...state, ...{filter: filter}})
                  }}
                >
                  {chapterList.map((x: any) => {
                    return <option value={x?.value}>{x?.label}</option>
                  })}

                  {/* <option value="1">Valmiki</option> */}
                  {/* <option value="2">Eklavya</option> */}
                </select>{' '}
              </th>

              {/* <th className='p-3'> <input type="text" className="form-control mb-3" placeholder=" " /> </th> */}
              <th className='p-3'>
                {' '}
                <input
                  type='text'
                  className='form-control mb-3'
                  placeholder=' '
                  value={state?.filter?.venue}
                  onChange={(e: any) => {
                    let filter = state?.filter ?? {}
                    filter['venue'] = e.target.value
                    updateState({...state, ...{filter: filter}})
                  }}
                />
              </th>
              {/* <th className='p-3'> <input type="text" className="form-control mb-3" placeholder="" /> </th> */}
              <th className='p-3'>
                {' '}
                <input
                  type='text'
                  className='form-control mb-3'
                  placeholder=' '
                  value={state?.filter?.address}
                  onChange={(e: any) => {
                    let filter = state?.filter ?? {}
                    filter['address'] = e.target.value
                    updateState({...state, ...{filter: filter}})
                  }}
                />
              </th>
              <th className='p-3'> &nbsp; </th>
            </tr>
          </thead>

          <tbody className='text-gray-600 text-center fw-bold' {...getTableBodyProps()}>
            {rows.length > 0 ? (
              rows.map((row: Row<Event>, i) => {
                prepareRow(row)
                return <CustomRow row={row} key={`row-${i}-${row.id}`} />
              })
            ) : (
              <tr>
                <td colSpan={7}>
                  <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                    No matching records found
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <EventsListPagination />
      {isLoading && <EventsListLoading />}
    </KTCardBody>
  )
}

export { EventsTable }
// export default EventsTable
