// @ts-nocheck
import {Column} from 'react-table'
import {GroupmeetingInfoCell} from './GroupmeetingInfoCell'
import {GroupmeetingLastLoginCell} from './GroupmeetingLastLoginCell'
import {GroupmeetingTwoStepsCell} from './GroupmeetingTwoStepsCell'
import {GroupmeetingActionsCell} from './GroupmeetingActionsCell'
import {GroupmeetingSelectionCell} from './GroupmeetingSelectionCell'
import {GroupmeetingCustomHeader} from './GroupmeetingCustomHeader'
import {GroupmeetingSelectionHeader} from './GroupmeetingSelectionHeader'
import {Groupmeeting} from '../../core/_models'
import { checkDisplayValue } from '../../../../../../utils'
import moment from 'moment'

const groupmeetingsColumns: ReadonlyArray<Column<Groupmeeting>> = [
  // {
  //   Header: (props) => <GroupmeetingSelectionHeader tableProps={props} />,
  //   id: 'selection',
  //   Cell: ({...props}) => <GroupmeetingSelectionCell id={props.data[props.row.index].id} />,
  // },
  {
    Header: (props) => <GroupmeetingCustomHeader tableProps={props} title='Meeting Date' className='text-center min-w-125px fs-5' />,
    accessor: 'meeting_date',
    Cell: ({value}) => checkDisplayValue(moment(value).format('DD-MM-YYYY')),
    // id: 'name',
    // Cell: ({...props}) => <GroupmeetingInfoCell groupmeeting={props.data[props.row.index]} />,
  },

  {
    Header: (props) => <GroupmeetingCustomHeader tableProps={props} title='Group Name' className='min-w-125px text-center  ps-2 fs-5' />,
    accessor: 'group_name',
    Cell: ({value}) => checkDisplayValue(value),
    // id: 'name',
    // Cell: ({...props}) => <GroupmeetingInfoCell groupmeeting={props.data[props.row.index]} />,
  },

 
  {
    Header: (props) => <GroupmeetingCustomHeader tableProps={props} title='Captain' className='min-w-125px text-center fs-5' />,
    accessor: 'captain',
    Cell: ({value}) => checkDisplayValue(value),
    // id: 'name',
    // Cell: ({...props}) => <GroupmeetingInfoCell groupmeeting={props.data[props.row.index]} />,
  },
  
  {
      Header: (props) => (
        <GroupmeetingCustomHeader tableProps={props} title='Actions' className=' min-w-150px text-center' />
      ),
      id: 'actions',
      accessor: 'id',
      Cell: ({value}) => <GroupmeetingActionsCell id={value} />,
    },

  // {
  //   Header: (props) => (
  //     <GroupmeetingCustomHeader tableProps={props} title='Last login' className='' />
  //   ),
  //   id: 'last_login',
  //   Cell: ({...props}) => <GroupmeetingLastLoginCell last_login={props.data[props.row.index].last_login} />,
  // },
  // {
  //   Header: (props) => (
  //     <GroupmeetingCustomHeader tableProps={props} title='Two steps' className='min-w-125px' />
  //   ),
  //   id: 'two_steps',
  //   Cell: ({...props}) => <GroupmeetingTwoStepsCell two_steps={props.data[props.row.index].two_steps} />,
  // },
  // {
  //   Header: (props) => (
  //     <GroupmeetingCustomHeader tableProps={props} title='Joined day' className='min-w-125px' />
  //   ),
  //   accessor: 'joined_day',
  // },
  
]

export {groupmeetingsColumns}
