import { ID, Response } from "../../../../../_metronic/helpers"

// import {ID, Response} from '../../../../../../_metronic/helpers'
export type Sessionattendance = {
  id?: ID
  name?: string
  avatar?: string
  email?: string
  position?: string
  role?: string
  chapter_name?: string
  leader_name?: string
  event_date?: string
  // attendance?: string
  present_time?: string
  
}

export type SessionattendancesQueryResponse = Response<Array<Sessionattendance>>

export const initialSessionattendance: Sessionattendance = {
  avatar: 'avatars/300-6.jpg',
  position: 'Art Director',
  role: 'Administrator',
  name: '',
  email: '',
  chapter_name: 'Eklavya',
  leader_name: 'Sautik Shah',
  event_date: '27-09-2019',
  present_time: '08:00:00 AM'
}
