/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useContext, useState, useEffect, useMemo } from 'react'
import { useQuery } from 'react-query'
// import {
//   createResponseContext,
//   initialQueryResponse,
//   initialQueryState,
//   PaginationState,
//   QUERIES,
//   stringifyRequestQuery,
//   WithChildren,
// } from '../../../../../../_metronic/helpers'
import { getGroupmanagements } from './_requests'
import { Groupmanagement } from './_models'
import { useQueryRequest } from './QueryRequestProvider'
import { PaginationState, QUERIES, WithChildren, createResponseContext, initialQueryResponse, initialQueryState, stringifyRequestQuery } from '../../../../../_metronic/helpers'

const QueryResponseContext = createResponseContext<Groupmanagement>(initialQueryResponse)
const QueryResponseProvider: FC<WithChildren> = ({ children }) => {
  const { state } = useQueryRequest()
  const [query, setQuery] = useState<string>(stringifyRequestQuery(state))
  const updatedQuery = useMemo(() => stringifyRequestQuery(state), [state])

  useEffect(() => {
    if (query !== updatedQuery) {
      setQuery(updatedQuery)
    }
  }, [updatedQuery])

  const {
    isFetching,
    refetch,
    data: response,
  } = useQuery(
    `${QUERIES.GROUPMANAGEMENTS_LIST}-${query}`,
    () => {
      return getGroupmanagements(query)
    },
    { cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false }
  )

  return (
    <QueryResponseContext.Provider value={{ isLoading: isFetching, refetch, response, query }}>
      {children}
    </QueryResponseContext.Provider>
  )
}

const useQueryResponse = () => useContext(QueryResponseContext)

const useQueryResponseData = () => {
  const { response } = useQueryResponse() as any
  if (!response || !response?.data) {
    return []
  }
  return response?.data || []
}



const useQueryResponsePagination = () => {
  const defaultPaginationState: PaginationState = {
    links: [],
    ...initialQueryState,
  }

  const { response } = useQueryResponse() as any
  if (!response || !response?.perPage || !response?.page) {
    return {}
  }

  return {
    perPage: response?.perPage,
    page: response?.page,
    totalRecords: response?.totalRecords,
  }
}

const useQueryResponseLoading = (): boolean => {
  const { isLoading } = useQueryResponse()
  return isLoading
}

export {
  QueryResponseProvider,
  useQueryResponse,
  useQueryResponseData,
  useQueryResponsePagination,
  useQueryResponseLoading,
}
