import axios, {AxiosResponse} from 'axios'
// import {ID, Response} from '../../../../../../_metronic/helpers'
// import {Visitorpanel, VisitorpanelsQueryResponse} from './_models'
// import { ID, Response } from '../../../_metronic/helpers'
import { apiUrl } from '../../../../apiurl'
import { VisitorpanelsQueryResponse } from '../../visitor-panel/visitorpanels-list/core/_models'
// improt {}
const API_URL = apiUrl
const EVENTDATE_API = `${API_URL}/eventdates`
const GET_VISITORPANELS_URL = `${API_URL}/Leaderpoints/getleaderboard`
const USER_API = `${API_URL}/users`
const GIreportYear =`${apiUrl}/Monthlyleaderpoints/GIreportYear`


export function getLeaderBroad(limit:any ,skip:any,chapterId:any,sortclm:any,order:any,filter:any):Promise<any> {
    return axios
      .get<any>(
        `${GET_VISITORPANELS_URL}?limit=${limit}&skip=${skip}&chapter_id=${chapterId}&sortclm=${sortclm}&sortorder=${order}&${filter}`
      )
      .then((responce: any) => {
        console.log('responce:', responce)

        return responce.data
      })
      .catch((error) => {
        console.error('Error All Post Data:', error)
        throw error
      })
  }

  export function getEventList(chapterID:any,today:any):Promise<any> {
    return axios
      .get<any>(
        `${EVENTDATE_API}/?filter[where][event_date][lte]=${today}&filter[where][chapter_id]=${chapterID}&filter[order]=event_date DESC`
      )
      .then((responce: any) => {
        console.log('responce:', responce)

        return responce.data
      })
      .catch((error) => {
        console.error('Error All Post Data:', error)
        throw error
      })
  }

  export function getUserList(chapterID:any):Promise<any> {
    return axios
      .get<any>(
        `${USER_API}/?filter[where][role_id][neq]=5&filter[where][chapter_id]=${chapterID}&filter[where][isactive]=1&filter[where][isdelete]=0`
      )
      .then((responce: any) => {
        console.log('responce:', responce)

        return responce.data
      })
      .catch((error) => {
        console.error('Error All Post Data:', error)
        throw error
      })
  }

  export function getExportGIreportYear() {
    return axios
      .get<any>(`${GIreportYear}`,{responseType: 'arraybuffer', })
      .then((responce: any) => {
        return responce
      })
      .catch((error) => {
        console.error('Error All  Monthlybusinessleaderpoints Data:', error)
        throw error
      })
  }
