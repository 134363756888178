/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useContext, useEffect, useState } from 'react'
import { CometChatUI } from '../../../cometchat-chat-uikit-react/CometChatWorkspace/src'
// import { Filter } from './components/filter'
// import { Filter } from './components/Filter'
import './style.css'
import { Button } from 'react-bootstrap'
import { COMETCHAT_CONSTANTS } from '../../../consts'
import { connect } from 'react-redux'
import { UserContext } from '../../../contextStore'
import * as actions from '../../../store/action'

function ChatListing(props: any) {
  const { isLoggedIn, userDetail, setAuthAccessUser, setChapterID } = useContext(UserContext)

  const [showChat, setShowChat] = useState(false)

  const handleBackButtonClick = () => {
    window.history.back()
  }

  useEffect(() => {
    if (isLoggedIn && userDetail?.id && userDetail?.name) {
      setTimeout(() => {
        let id = userDetail.id.toString() ?? false
        let name = userDetail?.name ?? false
        props.onLogin(id, COMETCHAT_CONSTANTS.AUTH_KEY, name)
        props.getLoggedinUser()
        setTimeout(() => {
          setShowChat(true)
        })
      }, 1000)
    }
    // dispatch(actions.auth)
  }, [isLoggedIn])

  return (
    <>
      <div className='chat-view full-width position-relative'>
        <div className='msger-header d-flex d-md-none align-items-center'>
          <Button variant='link' onClick={handleBackButtonClick}>
            <i className='fa fa-arrow-left me-3 text-white fs-4'></i>
          </Button>
          <h4 className='text-white mb-0 fw-bold fs-5'>Ilead Messenger</h4>
        </div>
        <div className='position-relative'>
          {showChat &&
            <CometChatUI fetchCometChatAgain={() => { }} />
          }
        </div>
      </div>
    </>
  )
}

const mapStateToProps = (state: any) => {
  return {
    isLoggedIn: state.isLoggedIn,
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    getLoggedinUser: () => dispatch(actions.authCheckState()),
    onLogin: (uid: any, authKey: any) =>
      dispatch(actions.auth(uid, authKey)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ChatListing)

