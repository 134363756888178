// @ts-nocheck
import { Column } from 'react-table'
import { GroupchatInfoCell } from './GroupchatInfoCell'
import { GroupchatLastLoginCell } from './GroupchatLastLoginCell'
import { GroupchatTwoStepsCell } from './GroupchatTwoStepsCell'
import { GroupchatActionsCell } from './GroupchatActionsCell'
import { GroupchatSelectionCell } from './GroupchatSelectionCell'
import { GroupchatCustomHeader } from './GroupchatCustomHeader'
import { GroupchatSelectionHeader } from './GroupchatSelectionHeader'
import { Groupchat } from '../../core/_models'
import { checkDisplayValue } from '../../../../../../../utils'

const groupchatsColumns: ReadonlyArray<Column<Groupchat>> = [
  // {
  //   Header: (props) => <GroupchatSelectionHeader tableProps={props} />,
  //   id: 'selection',
  //   Cell: ({...props}) => <GroupchatSelectionCell id={props.data[props.row.index].id} />,
  // },


  {
    Header: (props) => <GroupchatCustomHeader tableProps={props} title='Group Name' className='min-w-125px text-start  ps-2 fs-5' />,
    accessor: 'name',
    Cell: (value) => checkDisplayValue(value?.row?.original?.name),
    // id: 'name',
    // Cell: ({...props}) => <GroupchatInfoCell groupchat={props.data[props.row.index]} />,
  },

  {
    Header: (props) => (
      <GroupchatCustomHeader tableProps={props} title='Actions' className=' min-w-150px text-center' />
    ),
    id: 'actions',
    Cell: ({ ...props }) => <GroupchatActionsCell id={props.data[props.row.index].guid} />,
  },

  // {
  //   Header: (props) => (
  //     <GroupchatCustomHeader tableProps={props} title='Last login' className='' />
  //   ),
  //   id: 'last_login',
  //   Cell: ({...props}) => <GroupchatLastLoginCell last_login={props.data[props.row.index].last_login} />,
  // },
  // {
  //   Header: (props) => (
  //     <GroupchatCustomHeader tableProps={props} title='Two steps' className='min-w-125px' />
  //   ),
  //   id: 'two_steps',
  //   Cell: ({...props}) => <GroupchatTwoStepsCell two_steps={props.data[props.row.index].two_steps} />,
  // },
  // {
  //   Header: (props) => (
  //     <GroupchatCustomHeader tableProps={props} title='Joined day' className='min-w-125px' />
  //   ),
  //   accessor: 'joined_day',
  // },

]

export { groupchatsColumns }
