import React, { useContext, useRef, useState } from 'react'
import { Button, Dropdown, DropdownButton, Image, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { toAbsoluteUrl } from '../../../../../_metronic/helpers'
import { Link } from 'react-router-dom'
import { apiUrl } from '../../../../../apiurl'
import { deletePostApi, removeBookmarkApi, setBookmarkApi, showHidePostApi } from './_requests'
import { checkDisplayValue } from '../../../../../utils'
import moment from 'moment'
import SVG from 'react-inlinesvg';
import { RWebShare } from 'react-web-share'
import { UserContext } from '../../../../../contextStore'
const API_URL = apiUrl

export function PostHeader({ item, index, data, setData, commentData, commentIndex, setMainData, mainData }: any) {
  const { setMessage, userDetail } = useContext(UserContext)
  const deleteModelPostRef = useRef<any>()
  const [loading, setLoading] = useState<any>({
    send: false,
    list: false,
    hide: false,
    delete: false,
    like: false,
  })
  const setBookMark = () => {
    try {
      let newData = data
      newData[index].isbookmarked = true
      let reqData = newData[index]
      setBookmarkApi({ user_topic_id: reqData?.id, leader_id: userDetail?.id })
      setData([...newData])
    } catch (err) {
      console.error('error', err)
    }
  }

  const removeBookMark = () => {
    try {
      let newData = data
      newData[index].isbookmarked = false
      let reqData = newData[index]
      removeBookmarkApi({ user_topic_id: reqData?.id, leader_id: userDetail?.id })
      setData([...newData])
    } catch (err) {
      console.error('error', err)
    }
  }

  const showHideAction = async () => {
    setLoading(Object.assign({}, loading, { hide: true }))
    try {
      await showHidePostApi({ user_topic_id: commentData.id })
      let newData = mainData
      newData[commentIndex].isactive = commentData.isactive ? 0 : 1
      setMainData([...newData])
      setLoading(Object.assign({}, loading, { hide: false }))
    } catch (err) {
      console.error(err)
      setLoading(Object.assign({}, loading, { hide: false }))
    }
  }

  const deletePostAction = async () => {
    setLoading(Object.assign({}, loading, { delete: true }))
    try {
      let responseData = await deletePostApi(commentData.id)
      if (responseData.count == 1) {
        let newData = mainData.filter((x: any, id: any) => id != commentIndex)
        setMainData([...newData])
        setMessage('success', 'Delete Successfully')
        deleteModelPostRef?.current?.click()
      }
      setLoading(Object.assign({}, loading, { delete: false }))
    } catch (err) {
      console.error(err)
      setLoading(Object.assign({}, loading, { delete: false }))
    }
  }

  return (
    <div className='card-header p-6 align-items-start'>
      {item?.topics?.name == 'Announcement' &&
        Date.parse(item?.created_at) > Date.parse(moment().format()) ? (
        <span className='fs-13 mb-2'>
          <i className='bi bi-pin-fill'></i> Pinned Annoucement
        </span>
      ) : (
        <></>
      )}
      <div className='d-flex justify-content-between flex-nowrap w-100'>
        <div className='d-flex align-items-start'>
          <Link to={`/leaders/${item?.user?.id}`}>
            <div
              className={
                !true
                  ? 'symbol symbol-55px me-4 top-leader-profile'
                  : 'symbol symbol-55px me-4 top-leader-profile'
              }
              style={{ borderColor: item?.leaderGIColor }}
            >
              <Image
                src={
                  item?.user?.profile
                    ? `${API_URL}/containers/userprofile/download/${item?.user?.profile}`
                    : `${toAbsoluteUrl('./media/avatars/default-user.png')}`
                }
                onError={({ currentTarget }: any) => {
                  currentTarget.onerror = null // prevents looping
                  currentTarget.src = `${toAbsoluteUrl('./media/avatars/default-user.png')}`
                }}
              ></Image>
            </div>
          </Link>
          <div className='flex-grow-1'>
            <Link
              to={`/leaders/${item?.user?.id}`}
              className='text-black text-hover-primary fs-5 fs-md-4 fw-bold'
            >
              {checkDisplayValue(item?.user?.name)}
            </Link>
            <span className='text-gray-600 fw-semibold d-block fs-13'>
              {checkDisplayValue(item?.user?.companyname)}
            </span>
            <div className='d-flex align-items-center'>
              <span className='text-gray-600 fw-semibold d-block fs-13 me-3'>
                {moment(item?.updated_at).fromNow()}
              </span>
              <span className='round-separator me-3'></span>
              {item?.chapter_id === 0 ? (
                <Image src={toAbsoluteUrl('/media/ilead/publicpost.png')} height={13}></Image>
              ) : item?.group_id != null ? (
                <Image src={toAbsoluteUrl('/media/ilead/groupicon.png')} height={13}></Image>
              ) : item?.chapter_id != 0 &&
                item?.group_id === null &&
                item?.self_post_id === null ? (
                <Image src={toAbsoluteUrl('/media/ilead/privatepost.png')} height={13}></Image>
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
        <div className='card-toolbar my-0'>
          <div className='m-0 d-flex flex-row align-items-end'>
            {item?.topic_id == 1 ? (
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip className='tooltop-auto-width text-black'>{item?.topics?.name}</Tooltip>}
              >
                <div className='post-type'>
                  <div>
                    <SVG
                      src="/media/ilead/post-type/announcement.svg"
                      width={20}
                      height={20}
                    />
                  </div>
                </div>
              </OverlayTrigger>
            ) : item?.topic_id == 3 ? (
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip className='tooltop-auto-width text-black'>{item?.topics?.name} </Tooltip>}
              >
                <div className='post-type'>
                  <div>
                    <SVG
                      src="/media/ilead/post-type/ask_help.svg"
                      width={20}
                      height={20}
                    />
                  </div>
                </div>
              </OverlayTrigger>
            ) : item?.topic_id == 14 ? (
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip className='tooltop-auto-width text-black'>{item?.topics?.name} </Tooltip>}
              >
                <div className='post-type'>
                  <div>
                    <SVG
                      src="/media/ilead/post-type/tracksheet.svg"
                      width={20}
                      height={20}
                    />
                  </div>
                </div>
              </OverlayTrigger>
            ) : item?.topic_id == 12 ? (
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip className='tooltop-auto-width text-black'>{item?.topics?.name} </Tooltip>}
              >
                <div className='post-type'>
                  <div>
                    <SVG
                      src="/media/ilead/post-type/share_idea.svg"
                      width={20}
                      height={20}
                    />
                  </div>
                </div>
              </OverlayTrigger>
            ) : item?.topic_id == 7 ? (
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip className='tooltop-auto-width text-black'>{item?.topics?.name} </Tooltip>}
              >
                <div className='post-type'>
                  <div>
                    <SVG
                      src="/media/ilead/post-type/thank_you.svg"
                      width={20}
                      height={20}
                    />
                  </div>
                </div>
              </OverlayTrigger>
            ) : item?.topic_id == 20 ? (
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip className='tooltop-auto-width text-black'>{item?.topics?.name} </Tooltip>}
              >
                <div className='post-type'>
                  <div>
                    <SVG
                      src="/media/ilead/post-type/business_referral.svg"
                      width={20}
                      height={20}
                    />
                  </div>
                </div>
              </OverlayTrigger>
            ) : item?.topic_id == 8 ? (
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip className='tooltop-auto-width text-black'>{item?.topics?.name} </Tooltip>}
              >
                <div className='post-type'>
                  <div>
                    <SVG
                      src="/media/ilead/post-type/tyca_presentation.svg"
                      width={20}
                      height={20}
                    />
                  </div>
                </div>
              </OverlayTrigger>
            ) : item?.topic_id == 11 ? (
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip className='tooltop-auto-width text-black'>{item?.topics?.name} </Tooltip>}
              >
                <div className='post-type'>
                  <div>
                    <SVG
                      src="/media/ilead/post-type/visitor_invitation.svg"
                      width={20}
                      height={20}
                    />
                  </div>
                </div>
              </OverlayTrigger>
            ) : item?.topic_id == 10 ? (
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip className='tooltop-auto-width text-black'>{item?.topics?.name} </Tooltip>}
              >
                <div className='post-type'>
                  <div>
                    <SVG
                      src="/media/ilead/post-type/group_meeting.svg"
                      width={20}
                      height={20}
                    />
                  </div>
                </div>
              </OverlayTrigger>
            ) : item?.topic_id == 16 ? (
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip className='tooltop-auto-width text-black'>{item?.topics?.name} </Tooltip>}
              >
                <div className='post-type'>
                  <div>
                    <SVG
                      src="/media/ilead/post-type/session_flow.svg"
                      width={20}
                      height={20}
                    />
                  </div>
                </div>
              </OverlayTrigger>
            ) : item?.topic_id == 17 ? (
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip className='tooltop-auto-width text-black'>{item?.topics?.name} </Tooltip>}
              >
                <div className='post-type'>
                  <div>
                    <SVG
                      src="/media/ilead/post-type/tyca_questions.svg"
                      width={20}
                      height={20}
                    />
                  </div>
                </div>
              </OverlayTrigger>
            ) : item?.topic_id == 9 ? (
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip className='tooltop-auto-width text-black'>{item?.topics?.name} </Tooltip>}
              >
                <div className='post-type'>
                  <div>
                    <SVG
                      src="/media/ilead/post-type/feedback.svg"
                      width={20}
                      height={20}
                    />
                  </div>
                </div>
              </OverlayTrigger>
            ) : item?.topic_id == 21 ? (
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip className='tooltop-auto-width text-black'>{item?.topics?.name} </Tooltip>}
              >
                <div className='post-type'>
                  <div>
                    <SVG
                      src="/media/ilead/post-type/business_referral.svg"
                      width={20}
                      height={20}
                    />
                  </div>
                </div>
              </OverlayTrigger>
            ) : item?.topic_id == 22 ? (
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip className='tooltop-auto-width text-black'>{item?.topics?.name} </Tooltip>}
              >
                <div className='post-type'>
                  <div>
                    <SVG
                      src="/media/ilead/post-type/business_generated.svg"
                      width={20}
                      height={20}
                    />
                  </div>
                </div>
              </OverlayTrigger>
            ) : item?.topic_id == 23 ? (
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip className='tooltop-auto-width text-black'>{item?.topics?.name} </Tooltip>}
              >
                <div className='post-type'>
                  <div>
                    <SVG
                      src="/media/ilead/post-type/mr_tracking.svg"
                      width={20}
                      height={20}
                    />
                  </div>
                </div>
              </OverlayTrigger>
            ) : item?.topic_id == 15 ? (
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip className='tooltop-auto-width text-black'>{item?.topics?.name}</Tooltip>}
              >
                <div className='post-type'>
                  <div>
                    <SVG
                      src="/media/ilead/post-type/birthday.svg"
                      width={20}
                      height={20}
                    />
                  </div>
                </div>
              </OverlayTrigger>
            ) : item?.topic_id == 19 ? (
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip className='tooltop-auto-width text-black'>{item?.topics?.name}</Tooltip>}
              >
                <div className='post-type icaniwilltype'>
                  <div>
                    <SVG
                      src="/media/ilead/post-type/icaniwill.svg"
                      width={20}
                      height={20}
                    />
                  </div>
                </div>
              </OverlayTrigger>
            ) : item?.topic_id == 12 ? (
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip className='tooltop-auto-width text-black'>{item?.topics?.name} </Tooltip>}
              >
                <div className='post-type'>
                  <div>
                    <SVG
                      src="/media/ilead/post-type/share_idea.svg"
                      width={20}
                      height={20}
                    />
                  </div>
                </div>
              </OverlayTrigger>
            ) : (
              <></>
            )}
            <Dropdown
              align="end"
              id="dropdown-menu-align-end"
              className='post-action-dropdown me-7'
              style={{ marginTop: '-15px' }}
            >
              <Dropdown.Toggle id="dropdown-autoclose-true">
                <i className="fa-solid fa-ellipsis fs-2x text-black pe-0"></i>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {item?.isbookmarked ? (
                  <Dropdown.Item eventKey="1" className='d-flex align-items-center px-5 py-3 fs-6' onClick={() => {
                    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                    removeBookMark()
                  }}>
                    <i
                      className='fas fa-bookmark cursor-pointer ilead-text me-2' style={{ fontSize: '24px' }}></i>
                    Remove Bookmark

                  </Dropdown.Item>
                ) : (
                  <Dropdown.Item eventKey="1" className='d-flex align-items-center px-5 py-3 fs-6' onClick={() => {
                    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                    setBookMark()
                  }}>
                    <i
                      className='las la-bookmark fs-2x cursor-pointer text-black me-2'></i>
                    Bookmark 
                  </Dropdown.Item>
                )}
                {commentData?.topic_id === 1 ||
                  commentData?.topic_id === 3 ||
                  commentData?.topic_id === 10 ||
                  (commentData?.topic_id === 12 && userDetail?.id == commentData?.user_id) ||
                  (commentData?.topic_id === 7 &&
                    (userDetail?.id == commentData?.thankyou_receive_userid ||
                      userDetail?.id == commentData?.thankyou_send_userid)) ||
                  (commentData?.topic_id === 20 &&
                    (userDetail?.id == commentData?.business_referral_receiver ||
                      userDetail?.id == commentData?.business_referral_sender)) ||
                  (commentData?.topic_id === 21 &&
                    (userDetail?.id == commentData?.business_connect_receiver ||
                      userDetail?.id == commentData?.business_connect_sender)) ||
                  (commentData?.topic_id === 22 &&
                    (userDetail?.id == commentData?.business_generate_receiver ||
                      userDetail?.id == commentData?.business_generate_sender)) ? (
                  <Dropdown.Item eventKey="2" className='px-5 py-3'>

                    <RWebShare
                      data={{
                        text: `${commentData?.description ?? ''}`,
                        url: `${commentData?.posturl ?? ''}`,
                        title: `${commentData?.topics?.name ?? ''}`,
                      }}
                    >
                      <Button
                        variant='link'
                        className='nav-link p-0 w-auto h-auto fs-6'
                      >
                        <i className='las la-share fs-2x text-black me-2'></i>
                        Share 
                      </Button>
                    </RWebShare>
                  </Dropdown.Item>
                ) : (
                  <></>
                )}

                <div>
                  {commentData?.canHide ? (
                    <Dropdown.Item eventKey="3" className='px-5 py-3'>
                      {userDetail?.role_id == 4 ||
                        userDetail?.role_id == 5 ||
                        userDetail?.role_id == 6 ||
                        (commentData?.canHide &&
                          !(commentData.topic_id == 20) &&
                          !(commentData.topic_id == 21) &&
                          !(commentData.topic_id == 22)) ||
                        (commentData?.canHide &&
                          commentData.topic_id == 20 &&
                          (userDetail?.id == commentData?.business_referral_receiver ||
                            userDetail?.id == commentData?.business_referral_sender)) ||
                        (commentData?.canHide &&
                          commentData.topic_id == 21 &&
                          (userDetail?.id == commentData?.business_connect_receiver ||
                            userDetail?.id == commentData?.business_connect_sender)) ||
                        (commentData?.canHide &&
                          commentData.topic_id == 22 &&
                          (userDetail?.id == commentData?.business_generate_receiver ||
                            userDetail?.id == commentData?.business_generate_sender)) ? (
                        <Button
                          variant='link'
                          className='text-start d-flex p-0 w-auto h-auto me-2 fs-6'
                          onClick={() => {
                            showHideAction()
                          }}
                          disabled={loading.hide}
                        >
                          <i className='las la-low-vision fs-2x text-black'></i>
                          {loading.hide ? 'Loading...' : commentData?.isactive ? 'Hide' : 'Show'}
                        </Button>
                      ) : (
                        <> </>
                      )}
                    </Dropdown.Item>
                  ) : (
                    <> </>
                  )}
                  {commentData?.canDeletePost ? (
                    <Dropdown.Item eventKey="3" className='px-5 py-3'>
                      {userDetail?.role_id == 4 ||
                        userDetail?.role_id == 5 ||
                        userDetail?.role_id == 6 ||
                        commentData?.canDeletePost ? (
                        <Button
                          variant='link'
                          className='text-start d-flex p-0 w-auto h-auto me-2 fs-6'
                          data-bs-toggle='modal'
                          data-bs-target={`#delete_post_modal${commentIndex}`}
                        >
                          <i className='las la-trash fs-2x text-black'></i>
                          Delete
                        </Button>
                      ) : (
                        <></>
                      )}
                    </Dropdown.Item>
                  ) : (
                    <></>
                  )}
                </div>

              </Dropdown.Menu>
            </Dropdown>

            {/* <span className='mt-2 fs-13 post-type text-end'>{item?.topics?.name ?? ''}</span> */}
          </div>
        </div>
      </div>
    </div >
  )
}
