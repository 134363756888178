/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useContext, useEffect, useState} from 'react'
import {useMutation, useQueryClient} from 'react-query'
// import {MenuComponent} from '../../../../../../../_metronic/assets/ts/components'
// import {ID, KTIcon, QUERIES} from '../../../../../../../_metronic/helpers'
import {useListView} from '../../core/ListViewProvider'
import {useQueryResponse} from '../../core/QueryResponseProvider'
import {deleteLibrarymanagement} from '../../core/_requests'
import {MenuComponent} from '../../../../../../_metronic/assets/ts/components'
import {ID, KTIcon, QUERIES} from '../../../../../../_metronic/helpers'
import {useNavigate} from 'react-router-dom'
import AddEditSection from '../../../AddEditSection'
import AddSection from '../../../AddSection'
import {UserContext} from '../../../../../../contextStore'
import {Modal} from 'react-bootstrap'
// import AddLibrarymanagements from '../../../AddLibrarymanagement'
// import EditLibrarymanagements from '../../../EditLibrarymanagement'

type Props = {
  id: ID
}

const LibrarymanagementActionsCell: FC<Props> = ({id}) => {
  const {setItemIdForUpdate} = useListView()
  const {setMessage, removeAuth, chapterID} = useContext(UserContext)
  const {query} = useQueryResponse()
  const queryClient = useQueryClient()
  const [show, setShow] = useState(false)
  const [deleteId, setDeleteId] = useState<any>()
  const [deleteShow, setDeleteShow] = useState(false)
  // console.log('deleteId', deleteId, id)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const openEditModal = () => {
    setItemIdForUpdate(id)
  }

  const deleteItem = useMutation(() => deleteLibrarymanagement(id), {
    // 💡 response of the mutation is passed to onSuccess
    onSuccess: () => {
      // ✅ update detail view directly
      queryClient.invalidateQueries([`${QUERIES.LIBRARYMANAGEMENTS_LIST}-${query}`])
    },
  })
  const deleteItemById = async (id: any) => {
    const deletById = await deleteLibrarymanagement(deleteId)
      .then((res: any) => {
        console.log('res', res)
        if (res?.data?.count == 1) {
          setMessage('success', 'Deleted successful')
          queryClient.invalidateQueries([`${QUERIES.LIBRARYMANAGEMENTS_LIST}-${query}`])
        }
      })
      .catch((error: any) => {
        console.log('4444444', error)
        if (error?.response?.data?.error?.message) {
          setMessage('error', error?.response?.data?.error?.message)
        } else {
          setMessage('error', 'Something went wrong')
        }
      })
  }

  const navigate = useNavigate()
  const navigateViewLibrarymanagement = (id:any) => {
    // 👇️ navigate to /
    navigate(`/view-section/${id}`)
  }

  return (
    <>
      <a
        // href='#'
        className='btn btn-light-success rounded-circle py-2 px-3 btn-sm me-2'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
        style={{lineHeight: '11px !important'}}
        onClick={()=>{navigateViewLibrarymanagement(id)}}
      >
        <i className='fa-regular fa-eye pe-0 fs-6'></i>
      </a>

      <a
        // href=''
        className='btn btn-light-warning rounded-circle py-2 px-3 btn-sm me-2'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
        style={{lineHeight: '11px !important'}}
        onClick={() => {
          setShow(true)
        }}
        // data-bs-toggle="modal"
        // data-bs-target="#editnewsection"
      >
        <i className='fa-regular fa-pen-to-square pe-0 fs-6'></i>
      </a>

      <a
        // href='#'
        className='btn btn-light-danger rounded-circle py-2 px-3 btn-sm'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
        style={{lineHeight: '11px !important'}}
        onClick={() => {
          // setDeleteId(id)
          setDeleteShow(true)
        }}
        // data-bs-toggle='modal'
        // data-bs-target='#deletModal'
      >
        <i className='fa-solid fa-trash-can pe-0 fs-6'></i>
      </a>
      {/* begin::Menu */}
      {/* <EditLibrarymanagements /> */}

      <div
        className='modal fade'
        id='deletModal'
        tabIndex={-1}
        aria-labelledby='exampleModalLabel'
        aria-hidden='true'
      >
        <div className='modal-dialog'>
          <div className='modal-content'>
            {/* <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLabel">Modal title</h5>
        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div> */}
            <div className='modal-body pb-0'>
              <div className='col-12 text-center pt-5 pb-5'>
                <i
                  className='fa-solid fa-trash-can text-danger mb-10'
                  style={{fontSize: '80px'}}
                ></i>
                <h3>
                  {' '}
                  Are you sure want to delete this section, All associated media will be deleted.{' '}
                </h3>
              </div>
            </div>
            <div className='modal-footer'>
              <button
                type='button'
                className='btn btn-primary'
                onClick={() => {
                  deleteItemById(29)
                }}
              >
                {' '}
                yes{' '}
              </button>
              <button type='button' className='btn btn-secondary' data-bs-dismiss='modal'>
                {' '}
                No{' '}
              </button>
            </div>
          </div>
        </div>
      </div>
      <AddSection show={show} handleClose={handleClose} id={id} />
      <Deleteshow show={deleteShow} handleClose={setDeleteShow} id={id} />
      {/* <AddEditSection  handleClose={handleClose} /> */}
      {/* <div className="modal fade" id="editnewsection" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title text-primary fs-4 fw-bold" id="exampleModalLabel"> Edit Section </h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                       <AddEditSection />
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            <button type="button" className="btn btn-primary">Save changes</button>
                        </div>
                    </div>
                </div>
            </div> */}
    </>
  )
}

//  import React from 'react'

const Deleteshow: FC<any> = ({show, handleClose, id}: any) => {
  const {setMessage, removeAuth, chapterID} = useContext(UserContext)
  const [deleteId, setDeleteId] = useState<any>()
  const {query} = useQueryResponse()
  const queryClient = useQueryClient()

  useEffect(() => {
    // if (id && show) {
    setDeleteId(id)
    // }
  }, [])

  const deleteItemById = async (id: any) => {
    const deletById = await deleteLibrarymanagement(id)
      .then((res: any) => {
        console.log('res', res)
        if (res?.data?.count == 1) {
          setMessage('success', 'Deleted successful')
          queryClient.invalidateQueries([`${QUERIES.LIBRARYMANAGEMENTS_LIST}-${query}`])
          handleClose()
        }
      })
      .catch((error: any) => {
        console.log('4444444', error)
        if (error?.response?.data?.error?.message) {
          setMessage('error', error?.response?.data?.error?.message)
        } else {
          setMessage('error', 'Something went wrong')
        }
      })
  }

  console.log('90', id)
  return (
    <>
      <Modal show={show} onHide={handleClose} className='pb-0'>
        {/* <Modal.Header closeButton>
          <h5 className='modal-title text-primary fs-4 fw-bold' id='exampleModalLabel'>
            {' '}
            {id ? 'Edit Section' : 'Add Section'}{' '}
          </h5>
        </Modal.Header> */}
        <Modal.Body>
          <div className='col-12 text-center pt-5 pb-5'>
            <i className='fa-solid fa-trash-can text-danger mb-10' style={{fontSize: '80px'}}></i>
            <h3>
              {' '}
              Are you sure want to delete this section, All associated media will be deleted.{' '}
            </h3>
          </div>
          {/* <form action='' onSubmit={formik.handleSubmit} noValidate>
              <div className='row mx-0  align-items-center my-5 px-3 '>
                <div className='col-sm-12 col-lg-12 mb-5'>
                  {' '}
                  <input
                    type='text'
                    className='form-control'
                    value={formik?.values?.title}
                    onChange={(event: any) => {
                      formik.setFieldValue('title', event.target.value.trimStart())
                    }}
                    placeholder='Enter section title.'
                  />
                  {formik.touched.title && formik.errors.title && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.title}</span>
                      </div>
                    </div>
                  )}
                </div>

                <div className='col-md-12 col-lg-12 text-center'>
                  <button type='submit' className='btn btn-primary waves-effect waves-light btn-sm'>
                    {' '}
                    Submit{' '}
                  </button>
                </div>
              </div>
            </form> */}
          <div className='modal-footer'>
            <button
              type='button'
              className='btn btn-primary'
              onClick={() => {
                console.log('---', id)
                deleteItemById(id)
              }}
            >
              {' '}
              yes{' '}
            </button>
            <button
              type='button'
              className='btn btn-secondary'
              onClick={() => {
                handleClose(false)
              }}
              data-bs-dismiss='modal'
            >
              {' '}
              No{' '}
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export {LibrarymanagementActionsCell}
