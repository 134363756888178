import { useEffect, useMemo, useState } from 'react'
import { useTable, ColumnInstance, Row } from 'react-table'
import { CustomHeaderColumn } from './columns/CustomHeaderColumn'
import { CustomRow } from './columns/CustomRow'
import { useQueryResponseData, useQueryResponseLoading } from '../core/QueryResponseProvider'
import { notificationsColumns } from './columns/_columns'
import { Notification } from '../core/_models'
import { NotificationsListLoading } from '../components/loading/NotificationsListLoading'
import { NotificationsListPagination } from '../components/pagination/NotificationsListPagination'
import { KTCardBody, defaultNotifications } from '../../../../../../_metronic/helpers'
import { useQueryRequest } from '../core/QueryRequestProvider'
import { getNotificationType } from '../core/_requests'
import { CopyNotificationsTable } from './CopyNotificationsTable'
// import { KTCardBody, defaultNotifications } from '../../../../../_metronic/helpers'
// import {KTCardBody} from '../../../../../../_metronic/helpers'

const NotificationsTable = () => {
  const [notificationTypeList,setNotificationTypeList] = useState<any>([
    {label:"Select Template Type",value:""}
  ])
  const [inputType, setInputType] = useState('text');
  const{state,updateState} = useQueryRequest() as any
  const notifications = useQueryResponseData()
  // const notifications = defaultNotifications
  const isLoading = useQueryResponseLoading()
  const data = useMemo(() => notifications, [notifications])
  const columns = useMemo(() => notificationsColumns, [])
  const { getTableProps, getTableBodyProps, headers, rows, prepareRow } = useTable({
    columns,
    data,
  })

  const getNotificationTypeList = async () => {
    try{
      await getNotificationType().then((response:any)=>{
        if(response && response?.data && response?.data?.length > 0 && response.status){
          let notifyTypelist = response?.data?.map((x:any)=>{
            return {label:x?.reminder_template_text ?? "", value:x?.id ?? ""}
          }) as any ?? []
          notifyTypelist.unshift({label:"Select Template Type",value:""})
          setNotificationTypeList(notifyTypelist)
        }
        // response 
      }).catch((error:any)=>{
        console.log("error",error)
      })
    }
    catch(error){
      console.log("error",error)
    }
  }

  useEffect(()=>{
    getNotificationTypeList()
  },[])

  return (
    <KTCardBody className='p-0'>
      <CopyNotificationsTable />
      <div className='table-responsive mb-8 data-table-report'>
        <table
          id='kt_table_notifications'
          className='table align-middle table-row-dashed fs-6 gy-5 dataTable table-striped  no-footer'
          {...getTableProps()}
        >
          <thead>
            <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0 border-bottom border-gray-100'>
              {headers.map((column: ColumnInstance<Notification>) => (
                <CustomHeaderColumn key={column.id} column={column} />
              ))}
            </tr>
          </thead>
          <thead className='table-sub-hdr'>

            <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
              {/* {headers.map((column: ColumnInstance<Sessionattendance>) => (
                <input type="text"/>
              ))} */}
              <th className='p-3'> 
                <input type="text" 
                  className="form-control mb-3" 
                  placeholder="Leader Name"
                  value={state?.filter?.leader_name}
                  onChange={(e:any)=>{
                    let filter = state?.filter ?? {}
                    filter['leader_name'] = e.target.value
                    updateState({...state,...{filter:filter}})
                  }}
                /> 
              </th>
              <th className='p-3'> 
                <select className="form-select form-select-lg mb-3 mt-2" 
                  aria-label=".form-select-lg example"
                  value={state?.filter?.template_type}
                  onChange={(e:any)=>{
                    let filter = state?.filter ?? {}
                    filter['template_type'] = e.target.value
                    updateState({...state,...{filter:filter}})
                  }}
                >
                  {notificationTypeList.map((x:any)=>{
                    return <option value={x?.value}>{x?.label}</option>
                  })}
                </select> 
              </th>

              <th className='p-3'>
                <select className="form-select form-select-lg mb-3 mt-2" 
                  aria-label=".form-select-lg example"
                  value={state?.filter?.notification_type}
                  onChange={(e:any)=>{
                    let filter = state?.filter ?? {}
                    filter['notification_type'] = e.target.value
                    updateState({...state,...{filter:filter}})
                  }}
                >
                  <option value=""> Select Notification Type </option>
                  <option value="1"> SMS </option>
                  <option value="2"> Email</option>
                  <option value="3"> Push Notification</option>
                </select> 
              </th>

              <th className='p-3'> 
                <input 
                 type={inputType}
                  // type="date" 
                  className="form-control mb-3" 
                  placeholder="Send Date"
                  value={state?.filter?.created_at}
                  onChange={(e:any)=>{
                    let filter = state?.filter ?? {}
                    filter['created_at'] = e.target.value
                    updateState({...state,...{filter:filter}})
                  }
                }
                  onFocus={() => setInputType('date')}
                  onBlur={() => setInputType('text')} 
                /> 
              </th>

            </tr>
          </thead>

          <tbody className='text-gray-600 text-center fw-bold' {...getTableBodyProps()}>
            {rows.length > 0 ? (
              rows.map((row: Row<Notification>, i) => {
                prepareRow(row)
                return <CustomRow row={row} key={`row-${i}-${row.id}`} />
              })
            ) : (
              <tr>
                <td colSpan={7}>
                  <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                    No matching records found
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <NotificationsListPagination />
      {isLoading && <NotificationsListLoading />}
    </KTCardBody>
  )
}

export { NotificationsTable }
