import {ListViewProvider, useListView} from './core/ListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
// import {VerticalsListHeader} from './components/header/VerticalsListHeader'
import {VerticalEditModal} from './vertical-edit-modal/VerticalEditModal'
import {KTCard} from '../../../../_metronic/helpers'
import { VerticalsTable } from './table/VerticalsTable'
// import { KTCard } from '../../../../_metronic/helpers'
// import {KTCard} from '../../../../../_metronic/helpers'

const VerticalsList = () => {
  const {itemIdForUpdate} = useListView()
  return (
    <>
      <>
        <VerticalsTable />
      </>
      {itemIdForUpdate !== undefined && <VerticalEditModal />}
    </>
  )
}

const VerticalsListWrapper = () => (
  <ListViewProvider>
    <VerticalsList />
  </ListViewProvider>
)

export {VerticalsListWrapper}
