import React, { useContext } from "react";
import SessionattendancesPage from "./SessionattendancesPage";
import { SessionattendancesListWrapper } from "./sessionattendances-list/SessionattendancesList";
// import { useQueryRequest } from "./sessionattendances-list/core/QueryRequestProvider";
import * as XLSX from 'xlsx'
import jsPDF from 'jspdf';
import axios from 'axios'
import 'jspdf-autotable';
import download from 'downloadjs'
import html2canvas from "html2canvas"
import { apiUrl } from "../../../apiurl";
import { UserContext } from "../../../contextStore";
const API_URL = apiUrl
const SESSIONATTENDANCE = `${API_URL}/sessionattendances/sessionAttendanceReport`

const SessionAttendance = () => {
    const {token} = useContext(UserContext)
    const copyTable = () => {
        try{
            // Get a reference to the table
            let table = document.getElementById('copy_kt_table_sessionattendances') as any
    
            // Make the table briefly visible
            table.style.display = 'table'
    
            // Create a range object to select the table content
            const range = document.createRange();
            range.selectNode(table);
    
            // Create a selection object and add the range
            const selection = window.getSelection() as any
            selection.removeAllRanges();
            selection.addRange(range);
    
            // Execute the copy command
            document.execCommand('copy');
    
            // Deselect the content
            selection.removeAllRanges();
    
            // Hide the table again (if it was originally hidden)
            table.style.display = 'none';
    
            // Optionally, provide feedback to the user
            alert('Table data copied to clipboard');
        }
        catch(err){
            // Get a reference to the table
            let table = document.getElementById('copy_kt_table_sessionattendances') as any

            // Hide the table again (if it was originally hidden)
            table.style.display = 'none';
        }
    }
    
    const ExportToExcel = async () => {
        try{
          await axios
            .get(`${SESSIONATTENDANCE}`, {
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                token
              },
              responseType: "blob",
            })
            .then((res: any) => {
              const content = res.headers["content-type"];
              
              download(res.data, "SessionAttendance.xls", content);
            })
            .catch((error: any) => {
              
              console.log("error", error)
            });
      
        }catch(err){
          console.log("error", err)
        }
      } 

    // function ExportToExcel() {
    //     try{
    //         // Acquire Data (reference to the HTML table)
    //         let table = document.getElementById("copy_kt_table_sessionattendances") as any
    
    //         // Make the table briefly visible
    //         table.style.display = 'table'
    
    //         // Extract Data (create a workbook object from the table)
    //         var workbook = XLSX.utils.table_to_book(table);
    
    //         // Hide the table again (if it was originally hidden)
    //         table.style.display = 'none';
    
    //         // Process Data (add a new row)
    //         var ws = workbook.Sheets["Sheet1"];
    //         // XLSX.utils.sheet_add_aoa(ws, [["Created "+new Date().toISOString()]], {origin:-1});
    
    //         // Package and Release Data (`writeFile` tries to write and save an XLSB file)
    //         XLSX.writeFile(workbook, "session_attendance.xlsb");
    //     }
    //     catch(err){
    //         // Get a reference to the table
    //         let table = document.getElementById('copy_kt_table_sessionattendances') as any

    //         // Hide the table again (if it was originally hidden)
    //         table.style.display = 'none';
    //     }
    // }

    function convertTableToCSV() {
        try{
            let table = document.getElementById("copy_kt_table_sessionattendances") as any
    
            // Make the table briefly visible
            table.style.display = 'table'
    
            const rows = table.querySelectorAll("tr") as any;
            let csv = [];
    
            for (const row of rows) {
                const cells = row.querySelectorAll("td, th") as any
                const rowData = Array.from(cells).map((cell:any) => cell.textContent);
                csv.push(rowData.join(","));
            }
    
            // Hide the table again (if it was originally hidden)
            table.style.display = 'none';
    
            const csvContent = "data:text/csv;charset=utf-8," + csv.join("\n");
            const encodedURI = encodeURI(csvContent);
            const link = document.createElement("a");
            link.setAttribute("href", encodedURI);
            link.setAttribute("download", "session_attendance.csv");
            document.body.appendChild(link);
            link.click();
        }
        catch(err){
            // Get a reference to the table
            let table = document.getElementById('copy_kt_table_sessionattendances') as any

            // Hide the table again (if it was originally hidden)
            table.style.display = 'none';
        }
    }

    function convertTableToPDF() {
        try{
            const doc = new jsPDF() as any;

            // Define the source table
            let table = document.getElementById("copy_kt_table_sessionattendances") as any

            // Make the table briefly visible
            // table.style.display = 'table'

            // Convert the table to a data array
            const tableData = [] as any;
            const rows = table.querySelectorAll("tr");
            rows.forEach(function (row:any) {
                const rowData = [] as any;
                const cells = row.querySelectorAll("td, th");
                cells.forEach(function (cell:any) {
                    rowData.push(cell.textContent);
                });
                tableData.push(rowData);
            });

            // Set table column widths
            const columnWidths = [50, 30, 40];

            // Create the PDF table
            doc.autoTable({
                head: [tableData[0]], // First row as header
                body: tableData.slice(1), // Exclude header row
                theme: "grid",
                margin: { top: 10 },
                startY: 20,
                columnStyles: {
                    0: { columnWidth: columnWidths[0] },
                    1: { columnWidth: columnWidths[1] },
                    2: { columnWidth: columnWidths[2] },
                },
            });

            // Save the PDF with a file name
            doc.save("session_attendances.pdf");
        }
        catch(err){
            // Get a reference to the table
            let table = document.getElementById('copy_kt_table_sessionattendances') as any

            // Hide the table again (if it was originally hidden)
            table.style.display = 'none';
        }
    }

    const convertTableToPrint = () => {
        try{
            // Define the source table
            let table = document.getElementById("copy_kt_table_sessionattendances") as any

            // Make the table briefly visible
            table.style.display = 'table'

            html2canvas(document.querySelector('#copy_kt_table_sessionattendances') as any).then(function (canvas:any) {
                let imgWidth = 210
                let pageHeight = 290
                let imgHeight = (canvas.height * imgWidth) / canvas.width
                let heightLeft = imgHeight
            
                let doc = new jsPDF('p', 'mm')
                let position = 0
                let pageData = canvas.toDataURL('image/jpeg', 1.0)
                let imgData = encodeURIComponent(pageData)
                doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight)
                doc.setLineWidth(5)
                doc.setDrawColor(255, 255, 255)
                doc.rect(0, 0, 210, 295)
                heightLeft -= pageHeight
            
                while (heightLeft >= 0) {
                    position = heightLeft - imgHeight
                    doc.addPage()
                    doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight)
                    doc.setLineWidth(5)
                    doc.setDrawColor(255, 255, 255)
                    doc.rect(0, 0, 210, 295)
                    heightLeft -= pageHeight
                }
                doc.autoPrint();
                //This is a key for printing
                doc.output('dataurlnewwindow');
            })

            // Hide the table again (if it was originally hidden)
            table.style.display = 'none';
        }
        catch(err){
            // Get a reference to the table
            let table = document.getElementById('copy_kt_table_sessionattendances') as any

            // Hide the table again (if it was originally hidden)
            table.style.display = 'none';
        }
    }

    return (

        <>
        <h1 className='inv-hst'>Session Attendance </h1>
            <div className='card mx-3 mx-md-0 py-8 px-5 px-sm-8 mt-5 ilead__card'>

                <div className="d-flex justify-content-start mb-3 mt-6 d-sm-block d-none">
                    <button type="button" className="btn px-3 px-sm-5 py-2 me-1 me-sm-3 btn-primary" onClick={()=>{
                        copyTable()
                    }}>Copy</button>
                    <button type="button" className="btn px-3 px-sm-5 py-2 me-1 me-sm-3 btn-success" onClick={()=>{
                        ExportToExcel()
                    }}>Excel</button>
                    <button type="button" className="btn px-3 px-sm-5 py-2 me-1 me-sm-3 btn-warning" onClick={()=>{
                        convertTableToCSV()
                    }}>CSV</button>
                    <button type="button" className="btn px-3 px-sm-5 py-2 me-1 me-sm-3 btn-danger" onClick={()=>{
                        convertTableToPDF()
                    }}>PDF</button>
                    <button type="button" className="btn px-3 px-sm-5 py-2 me-1 me-sm-3 btn-info" onClick={()=>{
                        convertTableToPrint()
                    }}>Print</button>
                </div>

               {/* <div className="d-flex justify-content-start mb-3 mt-6 fs-5 d-sm-block d-none">
                    Show <span className="mx-2"><select className=" mb-3 p-1 ms-2 text-dark border border-gray-600 rounded-1 fs-6" aria-label=".form-select-lg example">
                        <option selected>10</option>
                        <option value="1">25</option>
                        <option value="2">50</option>
                        <option value="2">100</option>
                    </select> </span> entries

                </div> */}


                {/* <div className='row mx-0 d-sm-block d-md-none mb-8'>

                    <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                        <h3 className="text-primary fs-5 fw-bold"> Chapter </h3>
                        <select className="form-select form-select-lg mb-3 mt-2" aria-label=".form-select-lg example">
                            <option selected>All</option>
                            <option value="1">Valmiki</option>
                            <option value="2">Eklavya</option>
                        </select>
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                        <h3 className="text-primary fs-5 fw-bold"> Leader Name</h3>
                        <input type="text" className="form-control mb-3" onChange={(e:any)=>{
                            console.log("sssssss",e.target.value)
                            updateState({...state,...{'leadername':e.target.value}})
                        }} placeholder="" value={state?.leadername} />
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                        <h3 className="text-primary fs-5 fw-bold"> Event Date  </h3>
                        <input type="date" className="form-control mb-3" placeholder="" />
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                        <h3 className="text-primary fs-5 fw-bold"> Attendance </h3>
                        <select className="form-select form-select-lg mb-3 mt-3" aria-label=".form-select-lg example">
                            <select className="form-select form-select-lg mb-3  mt-2" aria-label=".form-select-lg example">
                                <option selected>All</option>
                                <option value="1">Attended</option>
                                <option value="2">No Attended</option>
                                <option value="2">Substitute Attended</option>
                            </select>
                        </select>
                    </div>
                    </div>*/}

                 <SessionattendancesListWrapper />
            </div>

        </>
    )


}

export default SessionAttendance