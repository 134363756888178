import {useQuery} from 'react-query'
import {LibrarymanagementEditModalForm} from './LibrarymanagementEditModalForm'
// import {isNotEmpty, QUERIES} from '../../../../../../_metronic/helpers'
import {useListView} from '../core/ListViewProvider'
import { QUERIES, isNotEmpty } from '../../../../../_metronic/helpers'
import { getLibrarymanagementById } from '../core/_requests'
// import { getLibrarymanagementById } from '../../../apps/librarymanagement-management/librarymanagements-list/core/_requests'
// import {getLibrarymanagementById} from '../core/_requests'

const LibrarymanagementEditModalFormWrapper = () => {
  const {itemIdForUpdate, setItemIdForUpdate} = useListView()
  const enabledQuery: boolean = isNotEmpty(itemIdForUpdate)
  const {
    isLoading,
    data: librarymanagement,
    error,
  } = useQuery(
    `${QUERIES.LIBRARYMANAGEMENTS_LIST}-librarymanagement-${itemIdForUpdate}`,
    () => {
      return getLibrarymanagementById(itemIdForUpdate)
    },
    {
      cacheTime: 0,
      enabled: enabledQuery,
      onError: (err) => {
        setItemIdForUpdate(undefined)
        console.error(err)
      },
    }
  )

  // if (!itemIdForUpdate) {
  //   return <LibrarymanagementEditModalForm isLibrarymanagementLoading={isLoading} librarymanagement={{id: undefined}} />
  // }

  // if (!isLoading && !error && librarymanagement) {
  //   return <LibrarymanagementEditModalForm isLibrarymanagementLoading={isLoading} librarymanagement={librarymanagement} />
  // }

  return null
}

export {LibrarymanagementEditModalFormWrapper}
