import React, { useEffect, useContext, useState, SetStateAction, Dispatch } from 'react'
import moment from 'moment'
import { UserContext } from '../../../contextStore'
import { getMemberJoin } from './_request'

interface MyComponentProps {
    closeCard: Dispatch<SetStateAction<boolean>>;
}

const PointListingCardMemberJoin: React.FC<MyComponentProps> = ({ closeCard }) => {
    const [limitCardJoin, setLimitCardJoin] = useState<number>(10)
    const [searchCardJoin, setSearchCarDJoin] = useState<String>('')
    const [dataCardJoin, setDataCardJoin] = useState<any>([])
    const { userDetail } = useContext(UserContext)
    const userid = userDetail?.id ?? ''

    useEffect(() => {
        fetchJoinMember()
    }, [limitCardJoin, searchCardJoin])
    const fetchJoinMember = async () => {
        try {
            const res = await getMemberJoin(userid, searchCardJoin)
            setDataCardJoin(res ?? [])
            // setIsLoading(false)
        } catch (error) {
            console.error('Error -->:', error)
            // setIsLoading(false)
        }
    }

    return (
        <>

            <div className='modal' tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" style={{ display: 'block' }}>
                <div className='modal-dialog modal-lg leader-counter-modal ' role='document'>
                    <div className='modal-content'>
                        <div className='modal-header text-left border-bottom-0 border-radius-0'>
                            <h4 className='text-white'>Member(s) Joined</h4>
                            <button type='button' onClick={() => { closeCard(false) }} className='close' data-dismiss='modal' aria-label='Close'>
                                <span aria-hidden='true' className='text-white fs-2'>×</span>
                            </button>
                        </div>
                        <div className='modal-body border-radius-0'>
                            <div className="row mb-4">
                                <div className="col-sm-6 mb-4">
                                    <div className="dataTables_length" id="thankyou_receive_tbl_length">
                                        <label>Show
                                            <select name="thankyou_receive_tbl_length" aria-controls="thankyou_receive_tbl" className=""
                                                onChange={(event: any) => {
                                                    setLimitCardJoin(+event.target.value)
                                                }}>
                                                <option value="10">10</option>
                                                <option value="25">25</option>
                                                <option value="50">50</option>
                                                <option value="100">100</option></select> entries</label>
                                    </div>
                                </div>
                                <div className="col-sm-6 text-md-end text-sm-start mb-4" >
                                    <div id="thankyou_receive_tbl_filter" className="dataTables_filter">
                                        <label> Search:
                                            <input name="myInput"
                                                onChange={(event: any) => {
                                                    // let idSearch=`&filter[where][id][like]=%${event.target.value}%`
                                                    // let name=`&filter[where][name][like]=%${event.target.value}%`
                                                    let reasonSearch = `&filter[where][invitee_name][like]=%${event.target.value}%`
                                                    setSearchCarDJoin(`${reasonSearch}`)
                                                }} /> </label>
                                    </div>
                                </div>
                            </div>
                            <div className='card invitation-history'>
                                <table className='table table-striped'>
                                    <thead>
                                        <tr>
                                            <th scope='col'>Id</th>
                                            <th scope='col'>Visitor(s) Name</th>
                                            <th scope='col'> Event Date </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            dataCardJoin.length > 0 ?
                                                (dataCardJoin.map(function (object: any, i: any) {

                                                    return (<tr key={i}>
                                                        <td data-label='Id'> {object.id} </td>
                                                        <td data-label='Sender Name'>  {object?.invitee_name}</td>
                                                        <td data-label='Date'> {moment(object.event_date).format("DD-MM-YYYY")}</td>
                                                    </tr>)

                                                })) : (
                                                    <tr>
                                                        <td colSpan={3}>Not Found</td>
                                                    </tr>
                                                )}
                                      
                                    </tbody>
                                </table>
                            </div>

                            <div className='row mt-8'> <nav aria-label="Page navigation example">
                                <ul className="pagination justify-content-end">
                                    <li className="page-item disabled">
                                        <a className="page-link" href="#" tabIndex={-1}>Previous</a>
                                    </li>
                                    <li className="page-item"><a className="page-link" href="#">1</a></li>
                                    <li className="page-item"><a className="page-link" href="#">2</a></li>
                                    <li className="page-item"><a className="page-link" href="#">3</a></li>
                                    <li className="page-item">
                                        <a className="page-link" href="#">Next</a>
                                    </li>
                                </ul>
                            </nav></div>

                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export { PointListingCardMemberJoin }
