// @ts-nocheck
import {Column} from 'react-table'
import {NotificationCustomHeader} from './NotificationCustomHeader'
import {Notification} from '../../core/_models'
import moment from 'moment'

const getNotificationName = (notification:any) => {
  if (notification == 1) {
      return 'SMS';
  } else if(notification == 2) {
      return 'Email';
  }else {
      return 'Push Notification';
  }
}

const getSendName = (value:any="") => {
  try{
    if(!value) return "-"
    return moment(value).format('DD-MM-YYYY')
  }
  catch(err){
    return "-"
  }
}

const notificationsColumns: ReadonlyArray<Column<Notification>> = [
  // {
  //   Header: (props) => <NotificationSelectionHeader tableProps={props} />,
  //   id: 'selection',
  //   Cell: ({...props}) => <NotificationSelectionCell id={props.data[props.row.index].id} />,
  // },
 
  {
    Header: (props) => <NotificationCustomHeader tableProps={props} title='Leader name' className='text-start min-w-125px   ps-2 fs-5' />,
    accessor: 'leader_name',
    // id: 'name',
    // Cell: ({...props}) => <NotificationInfoCell notification={props.data[props.row.index]} />,
  },

  {
    Header: (props) => <NotificationCustomHeader tableProps={props} title='Template Type' className='text-center min-w-125px fs-5' />,
    accessor: 'template_type',
    // id: 'name',
    Cell: ({row}) => <span data-toggle="tooltip" data-placement="top" title={row?.original?.description ?? ""}>{row?.original?.template_type ?? ""}</span>,
  },

  {
    Header: (props) => <NotificationCustomHeader tableProps={props} title='Notification Type' className='text-center min-w-125px fs-5' />,
    accessor: 'notification_type',
    // id: 'name',
    Cell: ({value}) => getNotificationName(value),
  },


  {
    Header: (props) => <NotificationCustomHeader tableProps={props} title='Send Date' className='text-center min-w-125px fs-5' />,
    accessor: 'created_at',
    // id: 'name',
    Cell: ({row}) => getSendName(row?.original?.created_at ?? ""),
  },


  // {
  //     Header: (props) => (
  //       <NotificationCustomHeader tableProps={props} title='Actions' className='text-center min-w-150px' />
  //     ),
  //     id: 'actions',
  //     Cell: ({...props}) => <NotificationActionsCell id={props.data[props.row.index].id} />,
  //   },

  
]

export {notificationsColumns}
