import { ID, Response } from "../../../../../_metronic/helpers"

// import {ID, Response} from '../../../../../../_metronic/helpers'
export type Summarysession = {
  id?: ID
  name?: string
  avatar?: string
  email?: string
  position?: string
  role?: string
  a?: string
  vi?: string
  va?: string
  vj?: string
  tg?: string
  tr?: string
  gma?: string
  tu?: string
  fg?: string
  h?: string
  iciw?: string
  mr?: string
  points?: string
 
}

export type SummarysessionsQueryResponse = Response<Array<Summarysession>>

export const initialSummarysession: Summarysession = {
  avatar: 'avatars/300-6.jpg',
  position: 'Art Director',
  role: 'Administrator',
  name: '',
  email: '',

}
