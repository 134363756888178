/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useRef, useState } from 'react'
import { useMutation, useQueryClient } from 'react-query'
// import {MenuComponent} from '../../../../../../../_metronic/assets/ts/components'
// import {ID, KTIcon, QUERIES} from '../../../../../../../_metronic/helpers'
import { useListView } from '../../core/ListViewProvider'
import { useQueryResponse } from '../../core/QueryResponseProvider'
import { deleteEvent } from '../../core/_requests'
import { MenuComponent } from '../../../../../../_metronic/assets/ts/components'
import { ID, KTIcon, QUERIES } from '../../../../../../_metronic/helpers'
import { useNavigate } from 'react-router-dom'
import AddEvents from '../../../AddEvent'
import EditEvents from '../../../EditEvent'
import axios from 'axios'
import {apiUrl} from '../../../../../../apiurl'
// import { useQueryResponse } from "./events-list/core/QueryResponseProvider";

type Props = {
  id: any
}

const API_URL = apiUrl

const EventActionsCell: FC<Props> = ({ id}) => {

  const { setItemIdForUpdate } = useListView()
  const { query } = useQueryResponse()
  const queryClient = useQueryClient()
  const DELETE_EVENT_URL = `${API_URL}/eventdates/deleteevent`
  const closeButton = useRef<any>()
  const { refetch } = useQueryResponse()
  const [eventID, setEventID] = useState<any>('')

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const navigate = useNavigate()
  const navigateViewEvent = () => {
    
    // 👇️ navigate to /
    navigate(`/view-event/${id}`)
  }

  const navigateEditEvent = () => {
    // 👇️ navigate to /
    navigate(`/edit-event/${id}`)
  }

  const navigateDeleteEvent = () => {
    // 👇️ navigate to /
    // navigate(`/edit-event/${id}`)
    deleteEvent()
  }

  const deleteEvent = async () => {

    try {
      const res = await axios.get(`${DELETE_EVENT_URL}/${id}`)
      // setIsLoading(false)
      if(res){
        closeButton.current.click()
        refetch()
      }
    } catch (error) {
        console.log('Error -->:', error)
        // setIsLoading(false)
    }
}

const eventId = () => {
  setEventID(id)
}


  return (
    <>
      <a
        href='#'
        className='btn btn-light-success rounded-circle py-2 px-3 btn-sm me-2'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
        style={{lineHeight: '11px !important'}}
        data-bs-toggle="modal" data-bs-target="#viewevent"
        onClick={navigateViewEvent}
      >
        <i className="fa-regular fa-eye pe-0 fs-6"></i>
      </a>

      <a
        // href=''
        href='#'
        className='btn btn-light-warning rounded-circle py-2 px-3 btn-sm me-2'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
        style={{ lineHeight: '11px !important' }}
        data-bs-toggle="modal" data-bs-target={`#editevent${id}`}
        // data-bs-toggle="modal" data-bs-target={`#editevent${eventID}`}
        // onClick={openEditModal}
        // onClick={navigateEditEvent => }

        onClick = { () => eventId()}
      >
        <i className="fa-regular fa-pen-to-square pe-0 fs-6"></i>
      </a>

      <a
        href='#'
        className='btn btn-light-danger rounded-circle py-2 px-3 btn-sm'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
        style={{ lineHeight: '11px !important' }}
        data-bs-toggle="modal" data-bs-target={`#deletModal_${id}`}
      >
        <i className="fa-solid fa-trash-can pe-0 fs-6"></i>
      </a>
      {/* begin::Menu */}
      {
        // eventID ?
        <EditEvents id={id} eventID={eventID} />
        // : null
      }
      {/* <EditEvents id={id} eventID={eventID} /> */}
      {/* <ViewEvents /> */}

      <div className="modal fade" id={`deletModal_${id}`} tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            {/* <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLabel">Delete Event </h5>
        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" ref={closeButton}></button>
      </div> */}
            <div className="modal-body pb-0">
              <div className='col-12 text-center pt-5 pb-5'>
                <i className="fa-solid fa-trash-can text-danger mb-10" style={{ fontSize: '80px' }}></i>
                <h3 > Are you sure you want to delete this Event? </h3>
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-primary" onClick={navigateDeleteEvent}> yes </button>
              <button type="button" className="btn btn-secondary" data-bs-dismiss="modal"> No </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export { EventActionsCell }
