import {ListViewProvider, useListView} from './core/ListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
import {RemindersListHeader} from './components/header/RemindersListHeader'
import {RemindersTable} from './table/RemindersTable'
import {ReminderEditModal} from './reminder-edit-modal/ReminderEditModal'
import {KTCard} from '../../../../../_metronic/helpers'
// import { KTCard } from '../../../../_metronic/helpers'
// import {KTCard} from '../../../../../_metronic/helpers'

const RemindersList = () => {
  const {itemIdForUpdate} = useListView()
  return (
    <>
      <>
        {/* <RemindersListHeader /> */}
        <RemindersTable />
      </>
      {itemIdForUpdate !== undefined && <ReminderEditModal />}
    </>
  )
}

const RemindersListWrapper = () => (
  <ListViewProvider>
    <RemindersList />
  </ListViewProvider>
)

export {RemindersListWrapper}
