// @ts-nocheck
import {Column} from 'react-table'
import {EventInfoCell} from './EventInfoCell'
import {EventLastLoginCell} from './EventLastLoginCell'
import {EventTwoStepsCell} from './EventTwoStepsCell'
import {EventActionsCell} from './EventActionsCell'
import {EventSelectionCell} from './EventSelectionCell'
import {EventCustomHeader} from './EventCustomHeader'
import {EventSelectionHeader} from './EventSelectionHeader'
import {Event} from '../../core/_models'
import { checkDisplayValue, formatDate } from '../../../../../../utils'
import moment from 'moment'

const eventsColumns: ReadonlyArray<Column<Event>> = [
  // {
  //   Header: (props) => <EventSelectionHeader tableProps={props} />,
  //   id: 'selection',
  //   Cell: ({...props}) => <EventSelectionCell id={props.data[props.row.index].id} />,
  // },
 
  {
    Header: (props) => <EventCustomHeader tableProps={props} title='Event Name' className='min-w-125px   ps-2 fs-5' />,
    accessor: 'event_name',
    // id: 'name',
    Cell: ({value}) => checkDisplayValue(value),
  },

  {
    Header: (props) => <EventCustomHeader tableProps={props} title='Event Date' className='min-w-125px fs-5' />,
    accessor: 'event_date',
    // id: 'name',
    Cell: ({value}) => value ? moment(checkDisplayValue(value)).format("DD-MM-YYYY") : "-",
  },


  {
    Header: (props) => <EventCustomHeader tableProps={props} title='Chapter' className='min-w-125px  fs-5' />,
    accessor: 'chapter',
    // id: 'name',
    Cell: ({value}) => checkDisplayValue(value),
  },
  
  {
    Header: (props) => <EventCustomHeader tableProps={props} title='Venue' className=' fs-5' />,
    accessor: 'venue',
    // id: 'name',
    Cell: ({value}) => checkDisplayValue(value),
  },
  
  {
    Header: (props) => <EventCustomHeader tableProps={props} title='Address' className=' fs-5' />,
    accessor: 'address',
    // id: 'name',
    Cell: ({value}) => checkDisplayValue(value),
  },
  
  {
      Header: (props) => (
        <EventCustomHeader tableProps={props} title='Actions' className=' min-w-150px' />
      ),
      id: 'actions',
      // accessor:'id',
      Cell: ({...props}) => <EventActionsCell id={props.data[props.row.index].id} />,
  },

  // { 
  //   Header: (props) => (
  //     <EventCustomHeader tableProps={props} title='Last login' className='' />
  //   ),
  //   id: 'last_login',
  //   Cell: ({...props}) => <EventLastLoginCell last_login={props.data[props.row.index].last_login} />,
  // },
  // {
  //   Header: (props) => (
  //     <EventCustomHeader tableProps={props} title='Two steps' className='min-w-125px' />
  //   ),
  //   id: 'two_steps',
  //   Cell: ({...props}) => <EventTwoStepsCell two_steps={props.data[props.row.index].two_steps} />,
  // },
  // {
  //   Header: (props) => (
  //     <EventCustomHeader tableProps={props} title='Joined day' className='min-w-125px' />
  //   ),
  //   accessor: 'joined_day',
  // },
  
]

const tempColumns: ReadonlyArray<Column<Event>> = [
  {
    Header: (props) => <EventCustomHeader tableProps={props} title='Event Name' className='min-w-125px   ps-2 fs-5' />,
    accessor: 'event_name',
    // id: 'name',
    // Cell: ({...props}) => <EventInfoCell event={props.data[props.row.index]} />,
  },

  {
    Header: (props) => <EventCustomHeader tableProps={props} title='Event Date' className='min-w-125px fs-5' />,
    accessor: 'event_date',
    // id: 'name',
    // Cell: ({...props}) => <EventInfoCell event={props.data[props.row.index]} />,
  },


  {
    Header: (props) => <EventCustomHeader tableProps={props} title='Chapter' className='min-w-125px  fs-5' />,
    accessor: 'chapter',
    // id: 'name',
    // Cell: ({...props}) => <EventInfoCell event={props.data[props.row.index]} />,
  },
  
  {
    Header: (props) => <EventCustomHeader tableProps={props} title='Venue' className=' fs-5' />,
    accessor: 'venue',
    // id: 'name',
    // Cell: ({...props}) => <EventInfoCell event={props.data[props.row.index]} />,
  },
  
  {
    Header: (props) => <EventCustomHeader tableProps={props} title='Address' className=' fs-5' />,
    accessor: 'address',
    // id: 'name',
    // Cell: ({...props}) => <EventInfoCell event={props.data[props.row.index]} />,
  },
]

export {eventsColumns, tempColumns}
