import { useEffect } from 'react'
import { Button, Image, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { FilterDropdown } from './FilterDropdown'
import { ScrollContainer } from 'react-indiana-drag-scroll'
import 'react-indiana-drag-scroll/dist/style.css'
import { allFilterData, fixFilterData } from '../../../../utils/postFilter'
import SVG from 'react-inlinesvg';

export function Filter({
  filterList1,
  setFilterList1,
  filterList2,
  filterId,
  setFilterId
}: any) {

  useEffect(() => {
    let tempFixFilterData = JSON.parse(JSON.stringify(fixFilterData)) as any
    let checkArray = tempFixFilterData.find((x: any) => x.id == filterId) as any
    if (!checkArray) {
      let newArray = tempFixFilterData
      let newObj = allFilterData.find((x: any) => x.id == filterId) as any
      newArray.push(newObj)
      setFilterList1(newArray)
    }
  }, [filterId])

  return (
    <div className='post-filter px-3 px-md-0 py-3 pb-7 d-flex flex-nowrap flex-row mb-1'>
      <div className='main-filter'>

        <div className='d-flex flex-nowrap'>
          <div className='newicon-filter'>
            <ScrollContainer>
              {filterList2.map((x: any) => {
                return <OverlayTrigger
                  placement="bottom"
                  overlay={<Tooltip className='tooltop-auto-width text-black'>{x?.label}</Tooltip>}
                >
                  {x?.label == "All" ? (
                    <Button variant='primary'
                      className={`me-2 order-1 main-button ${x?.id == filterId ? "active" : ""}`}
                      onClick={() => {
                        setFilterId(x?.id ?? "")
                      }}
                    >
                      <SVG
                        src="/media/ilead/post-type/all_post.svg"
                        width={24}
                        height={24}
                      />
                    </Button>
                  ) : x?.label == "Announcement" ? (
                    <Button variant='primary'
                      className={`me-2 order-1 main-button ${x?.id == filterId ? "active" : ""}`}
                      onClick={() => {
                        setFilterId(x?.id ?? "")
                      }}
                    >
                      <SVG
                        src="/media/ilead/post-type/announcement.svg"
                        width={24}
                        height={24}
                      />
                    </Button>
                  ) : x?.label == "Help / Referral" ? (
                    <Button variant='primary'
                      className={`me-2 order-1 main-button ${x?.id == filterId ? "active" : ""}`}
                      onClick={() => {
                        setFilterId(x?.id ?? "")
                      }}
                    >
                      <SVG
                        src="/media/ilead/post-type/ask_help.svg"
                        width={24}
                        height={24}
                      />
                    </Button>
                  ) : x?.label == "Tracksheet" ? (
                    <Button variant='primary'
                      className={`me-2 order-1 main-button ${x?.id == filterId ? "active" : ""}`}
                      onClick={() => {
                        setFilterId(x?.id ?? "")
                      }}
                    >
                      <SVG
                        src="/media/ilead/post-type/tracksheet.svg"
                        width={24}
                        height={24}
                      />
                    </Button>
                  ) : x?.label == "Bookmarked Post" ? (
                    <Button variant='primary'
                      className={`me-2 order-1 main-button ${x?.id == filterId ? "active" : ""}`}
                      onClick={() => {
                        setFilterId(x?.id ?? "")
                      }}
                    >
                      <SVG
                        src="/media/ilead/post-type/bookmark.svg"
                        width={24}
                        height={24}
                      />
                    </Button>
                  ) : x?.label == "Share Idea" ? (
                    <Button variant='primary'
                      className={`me-2 order-2 main-button ${x?.id == filterId ? "active" : ""}`}
                      onClick={() => {
                        setFilterId(x?.id ?? "")
                      }}
                    >
                      <SVG
                        src="/media/ilead/post-type/share_idea.svg"
                        width={24}
                        height={24}
                      />
                    </Button>
                  ) : x?.label == "Thank You" ? (
                    <Button variant='primary'
                      className={`d-none d-md-flex me-2 order-2 main-button ${x?.id == filterId ? "active" : ""}`}
                      onClick={() => {
                        setFilterId(x?.id ?? "")
                      }}
                    >
                      <SVG
                        src="/media/ilead/post-type/thank_you.svg"
                        width={24}
                        height={24}
                      />
                    </Button>
                  ) : x?.label == "Business Referral" ? (
                    <Button variant='primary'
                      className={`d-none d-md-flex me-2 order-2 main-button ${x?.id == filterId ? "active" : ""}`}
                      onClick={() => {
                        setFilterId(x?.id ?? "")
                      }}
                    >
                      <SVG
                        src="/media/ilead/post-type/business_referral.svg"
                        width={24}
                        height={24}
                      />
                    </Button>
                  ) : x?.label == "Invite Visitor" ? (
                    <Button variant='primary'
                      className={`d-none d-md-flex me-2 order-2 main-button ${x?.id == filterId ? "active" : ""}`}
                      onClick={() => {
                        setFilterId(x?.id ?? "")
                      }}
                    >
                      <SVG
                        src="/media/ilead/post-type/visitor_invitation.svg"
                        width={24}
                        height={24}
                      />
                    </Button>
                  ) : x?.label == "TYCA" ? (
                    <Button variant='primary'
                      className={`d-none d-md-flex me-2 order-2 main-button ${x?.id == filterId ? "active" : ""}`}
                      onClick={() => {
                        setFilterId(x?.id ?? "")
                      }}
                    >
                      <SVG
                        src="/media/ilead/post-type/tyca_presentation.svg"
                        width={24}
                        height={24}
                      />
                    </Button>
                  ) : (
                    <></>
                  )
                  }
                  {/* {x?.label} */}
                </OverlayTrigger>
              })}
            </ScrollContainer>
          </div>
          <button
            type='button'
            className='main-button order-2 btn btn-primary d-flex align-items-center justify-content-center'
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='top-end'
          >
            <i className="fa-solid fa-ellipsis-vertical text-dark pe-0" style={{ fontSize: '20px' }}></i>
          </button>

          <FilterDropdown filterList2={filterList2} filterId={filterId} setFilterId={setFilterId} />
        </div>

      </div >
      <div className='main-filte'>
        {/* <div className='m-0'>
          <button
            type='button'
            className='btn btn-clean btn-sm btn-icon btn-icon-primary btn-active-light-primary me-n3'
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='top-end'
          >
            <i className='bi bi-funnel fs-2x'></i>
          </button>

          <FilterDropdown filterList2={filterList2} filterId={filterId} setFilterId={setFilterId} />
        </div> */}
        {/* <button
          type='button'
          className='order-2 btn btn-primary d-flex align-items-center justify-content-center'
          data-kt-menu-trigger='click'
          data-kt-menu-placement='bottom-end'
          data-kt-menu-flip='top-end'
        >
          <i className="fa-solid fa-ellipsis-vertical text-dark pe-0" style={{ fontSize: '20px' }}></i>
        </button>

        <FilterDropdown filterList2={filterList2} filterId={filterId} setFilterId={setFilterId} /> */}
      </div>
    </div >
  )
}
