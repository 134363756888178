/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useContext, useEffect, useState } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { useListView } from '../../core/ListViewProvider'
import { useQueryResponse } from '../../core/QueryResponseProvider'
import { changesReqAPI, deleteRequestuser } from '../../core/_requests'
import { useNavigate } from 'react-router-dom'
import { MenuComponent } from '../../../../../../../_metronic/assets/ts/components'
import { ID, QUERIES } from '../../../../../../../_metronic/helpers'
import { UserContext } from '../../../../../../../contextStore'



type Props = {
  id: ID,
  data: any
}

const RequestuserActionsCell: FC<Props> = ({ id ,data}) => {
  const { setItemIdForUpdate } = useListView()
  const { query } = useQueryResponse()
  const queryClient = useQueryClient()
  const [Id,setID] = useState<ID>()
  const [active ,setActive ] = useState(data?.isaccept ? true : false)
  const { userDetail, chapterID, reload ,setMessage} = useContext(UserContext);
  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const openEditModal = () => {
    setItemIdForUpdate(id)
  }

  useEffect(()=>{
    setID(id)
  },[id])
  const deleteItem = useMutation(() => deleteRequestuser(id), {
    // 💡 response of the mutation is passed to onSuccess
    onSuccess: () => {
      // ✅ update detail view directly
      queryClient.invalidateQueries([`${QUERIES.REQUESTUSERS_LIST}-${query}`])
    },
  })

  const navigate = useNavigate()
  const navigateViewRequestuser = () => {
    // 👇️ navigate to /
    navigate('/view-requestuser')
  }

  const navigateEditRequestuser = () => {
    // 👇️ navigate to /
    navigate('/edit-requestuser')
  }


  const changesReq = async (id:any,status:any) => {
    try {
      if(status == 1){
        status = "approve"
      }
      else if(status == 2){
        status = "reject"
      }
      await changesReqAPI({id,status}).then((resp:any)=>{
        console.log("resp",resp)
        if(resp?.status == 200 ) {

          setMessage('success', 'Status Updated successfully')
          queryClient.invalidateQueries([`${QUERIES.REQUESTUSERS_LIST}-${query}`])
        }
      }).catch((error:any)=>{

        if (error?.status == 500 ) {
          setMessage('error', 'Something went wrong')

          // alert ("somewent worngs")
        }else {
          console.log("error1error",error)
          if (error?.response?.data?.error?.message){

            setMessage('error', `${error?.response?.data?.error?.message}`)
            
          } else {
            setMessage('error', 'Something went wrong')
          }
        }
      })
      setActive(false)
    }catch (error) {
      setMessage('error', 'Something went wrong')
      console.log("error",error)
    }
}
console.log("data",data)
console.log("active",active)

  return (
    <>

      <select className="form-select" aria-label="Default select example" 
        onChange={(e:any)=>{
          changesReq(id,e?.target?.value)
        }}
        value={data?.isaccept}
      >
        <option value={0}> - </option>
        <option value={1}>Approve</option>
        <option value={2}>Reject</option>
        
      </select>
    </>
  )
}

export { RequestuserActionsCell }
