/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect} from 'react'
import {useMutation, useQueryClient} from 'react-query'
// import {MenuComponent} from '../../../../../../../_metronic/assets/ts/components'
// import {ID, KTIcon, QUERIES} from '../../../../../../../_metronic/helpers'
import {useListView} from '../../core/ListViewProvider'
import {useQueryResponse} from '../../core/QueryResponseProvider'
import {deleteGroupmeeting} from '../../core/_requests'
import { MenuComponent } from '../../../../../../_metronic/assets/ts/components'
import { ID, KTIcon, QUERIES } from '../../../../../../_metronic/helpers'
import { useNavigate } from 'react-router-dom'
// import AddGroupmeetings from '../../../AddGroupmeeting'
// import EditGroupmeetings from '../../../EditGroupmeeting'
import { getGroupmeetingById } from '../../core/_requests'

type Props = {
  id: ID
}

const GroupmeetingActionsCell: FC<Props> = ({id}) => {
  const {setItemIdForUpdate} = useListView()
  // const {query} = useQueryResponse()
  // const queryClient = useQueryClient()

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  // const openEditModal = () => {
  //   setItemIdForUpdate(id)
  // }

  // const deleteItem = useMutation(() => deleteGroupmeeting(id), {
  //   // 💡 response of the mutation is passed to onSuccess
  //   onSuccess: () => {
  //     // ✅ update detail view directly
  //     queryClient.invalidateQueries([`${QUERIES.GROUPMEETINGS_LIST}-${query}`])
  //   },
  // })

  const navigate = useNavigate()
  const navigateViewGroupmeeting = () => {
    // 👇️ navigate to /
    navigate(`/view-groupmeeting/${id}`)
  }

  const navigateEditGroupmeeting = () => {
    // 👇️ navigate to /
    navigate(`/edit-groupmeeting/${id}`)
  }


  return (
    <>
      <a
        href='#'
        className='btn btn-light-success rounded-circle py-2 px-3 btn-sm me-2'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
        style={{lineHeight: '11px !important'}}
        onClick={navigateViewGroupmeeting}
      >
        <i className="fa-regular fa-eye pe-0 fs-6"></i>
      </a>

      <a
        href=''
        className='btn btn-light-warning rounded-circle py-2 px-3 btn-sm me-2'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
        style={{lineHeight: '11px !important'}}
        data-bs-toggle="modal" data-bs-target="#editgroupmeeting"
        onClick={navigateEditGroupmeeting}
      >
        <i className="fa-regular fa-pen-to-square pe-0 fs-6"></i>
      </a>
     
    {/*  <a
        href='#'
        className='btn btn-light-danger rounded-circle py-2 px-3 btn-sm'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
        style={{lineHeight: '11px !important'}}
        data-bs-toggle="modal" data-bs-target="#deletModal"
      >
        <i className="fa-solid fa-trash-can pe-0 fs-6"></i>
      </a>
      
     
 
      <div className="modal fade" id="deletModal" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLabel">Modal title</h5>
        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div className="modal-body pb-0">
      <div className='col-12 text-center pt-5 pb-5'>
         <i className="fa-solid fa-trash-can text-danger mb-10" style={{fontSize: '80px'}}></i> 
         <h3 > Are you sure you want to delete this Groupmeeting? </h3>
         </div>
      </div>
      <div className="modal-footer">
      <button type="button" className="btn btn-primary"> yes </button>
      <button type="button" className="btn btn-secondary" data-bs-dismiss="modal"> No </button>
      </div>
    </div>
  </div>
</div> */}
    </>
  )
}

export {GroupmeetingActionsCell}
