import React, {useContext, useEffect, useRef, useState} from 'react'
import {Form} from 'react-bootstrap'
import {useNavigate, useParams} from 'react-router-dom'
import * as Yup from 'yup'
import {apiUrl} from '../../../apiurl'
import {useFormik} from 'formik'
import axios from 'axios'
import {UserContext} from '../../../contextStore'
import {createVertical, updateVertical} from './vertical-list/core/_requests'
import {isNotEmpty} from '../../../_metronic/helpers'
import Select from 'react-select'

const API_URL = apiUrl
const CHAPTER_URL = `${API_URL}/chapters?filter[where][isdelete]=0&filter[fields][id]=true&filter[fields][name]=true`
const Vertical_one_url = `${API_URL}/verticals/findOneVertical`
const PERMISSION_URL = `${API_URL}/verticals/privilegesModel`

const validationForm = Yup.object().shape({
  name: Yup.string().required('This field is required'),
  chapterIds: Yup.array().required('This field is required'),
  permissionIds: Yup.array().required('This field is required'),
})

const AddEditVertical = () => {
  const {id} = useParams()
  const [data, setData] = useState<any>([])
  const [isLoading, setLoading] = useState<any>(false)
  const [chapterList, setChapterList] = useState<any>([])
  const [permissionList, setPermissionList] = useState<any>([])
  const {userDetail, setMessage} = useContext(UserContext)
  const userId = userDetail?.id ?? ''
  const closeModel = useRef<any>()
  const navigate = useNavigate()
  const navigateVerticalListing = () => {
    // 👇️ navigate to /
    navigate('/vertical')
  }
  const [selectedChapters, setSelectedChapters] = useState<any>([])
  const [selectedPermission, setSelectedPermission] = useState<any>([])

  useEffect(() => {
    getChapterList()
    getPermissionList()
    fetchData()
  }, [])

  const fetchData = async () => {
    try {
      if (id) {
        const res = await axios.get(`${Vertical_one_url}/${id}`)
        setData(res?.data ?? [])
      }
    } catch (error) {
      console.log('Error -->:', error)
    }
  }

  useEffect(() => {
    getChapterList()
  }, [selectedChapters])

  const getChapterList = async () => {
    try {
      const res = await axios.get(`${CHAPTER_URL}&filter[where][or][0][v_id]=0${data?.verticals?.id ? `&filter[where][or][1][v_id]=${data?.verticals?.id}`:''}`)
      if (res?.data) {
        const list = res?.data?.map((x: any) => {
          var label = x?.name
          var value = x?.id
          return {value: value, label: label}
        })
        setChapterList(list)
      }
    } catch (err: any) {
      let message = err?.message
        ? err.message
        : err?.responseMessage
        ? err.responseMessage
        : ('Something went wrong' as any)
      console.log('error', message)
    }
  }

  const getPermissionList = async () => {
    try {
      const res = await axios.get(PERMISSION_URL)
      if (res?.data) {
        const list = res?.data?.map((x: any) => {
          var key = Object.keys(x)[0]
          var value = x[key]
          return {value: parseInt(key), label: value}
        })
        setPermissionList(list)
      }
    } catch (err: any) {
      let message = err?.message
        ? err.message
        : err?.responseMessage
        ? err.responseMessage
        : ('Something went wrong' as any)
      console.log('error', message)
    }
  }

  const initialValues = {
    name: '',
    created_id: userId,
    chapterIds: [],
    permissionIds: [],
    thankyou_card_given: 0.0,
    thankyou_card_received: 0.0,
    visitor_attended: 0.0,
    visitor_joined: 0.0,
    group_meeting_participation: 0.0,
    session_feedback_points: 0.0,
    help: 0.0,
    session_attendance: 0.0,
    share_idea: 0.0,
    tracksheet: 0.0,
    icaniwill: 0.0,
    business_referral_sent: 0.0,
    business_referral_received: 0.0,
    business_connect_sent: 0.0,
    business_connect_received: 0.0,
    business_generate_sent: 0.0,
    business_generate_received: 0.0,
    mr_tracking: 0.0,
  }

  const chapterIds = selectedChapters?.map((item: any) => item?.value)
  const permissionIds = selectedPermission?.map((item: any) => item?.value)

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationForm,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      setLoading(true)
      var postData = {
        name: values?.name,
        created_id: userId,
        chapterIds: chapterIds ? chapterIds : [],
        permissionIds: permissionIds ? permissionIds : [],
        thankyou_card_given: values?.thankyou_card_given,
        thankyou_card_received: values?.thankyou_card_received,
        visitor_attended: values?.visitor_attended,
        visitor_joined: values?.visitor_joined,
        group_meeting_participation: values?.group_meeting_participation,
        session_feedback_points: values?.session_feedback_points,
        help: values?.help,
        session_attendance: values?.session_attendance,
        share_idea: values?.share_idea,
        tracksheet: values?.tracksheet,
        icaniwill: values?.icaniwill,
        business_referral_sent: values?.business_referral_sent,
        business_referral_received: values?.business_referral_received,
        business_connect_sent: values?.business_connect_sent,
        business_connect_received: values?.business_connect_received,
        business_generate_sent: values?.business_generate_sent,
        business_generate_received: values?.business_generate_received,
        mr_tracking: values?.mr_tracking,
      }
      try {
        if (isNotEmpty(id)) {
          await updateVertical(id, postData)
            .then((res: any) => {
              if (res?.status == 200) {
                setMessage('success', res?.data?.message ?? 'Vertical updated.')
                closeModel.current.click()
              } else {
                setMessage('error', res?.response?.data?.error?.message ?? 'Something went wrong.')
              }
              setLoading(false)
            })
            .catch((error: any) => {
              if (error) {
                setMessage('error', 'Something went wrong.')
              }
              setLoading(false)
            })
        } else if (!id) {
          await createVertical(postData)
            .then((res: any) => {
              if (res?.status == 200) {
                setMessage('success', res?.data?.message ?? 'Vertical added.')
                closeModel.current.click()
              } else {
                setMessage('error', res?.response?.data?.error?.message ?? 'Something went wrong.')
              }
              setLoading(false)
            })
            .catch((error: any) => {
              if (error) {
                setMessage('error', 'Something went wrong.')
              }
              setLoading(false)
            })
        }
      } catch (ex) {
        console.error(ex)
      }
    },
  })

  useEffect(() => {
    if (id) {
      formik.setFieldValue('name', data?.verticals?.name ?? '')
      if (data?.point?.length > 0) {
        var tracksheet = data?.point?.find((point: any) => point?.name === 'tracksheet')
        if (tracksheet) {
          var tracksheetvalue = tracksheet?.value
        }
        var thankyou_card_given = data?.point?.find(
          (point: any) => point?.name === 'thankyou_card_given'
        )
        if (thankyou_card_given) {
          var thankyou_card_given_value = thankyou_card_given?.value
        }
        var thankyou_card_received = data?.point?.find(
          (point: any) => point?.name === 'thankyou_card_received'
        )
        if (thankyou_card_received) {
          var thankyou_card_received_value = thankyou_card_received?.value
        }
        var visitor_attended = data?.point?.find((point: any) => point?.name === 'visitor_attended')
        if (visitor_attended) {
          var visitor_attended_value = visitor_attended?.value
        }
        var visitor_joined = data?.point?.find((point: any) => point?.name === 'visitor_joined')
        if (visitor_joined) {
          var visitor_joined_value = visitor_joined?.value
        }
        var group_meeting_participation = data?.point?.find(
          (point: any) => point?.name === 'group_meeting_participation'
        )
        if (group_meeting_participation) {
          var group_meeting_participation_value = group_meeting_participation?.value
        }
        var session_feedback_points = data?.point?.find(
          (point: any) => point?.name === 'session_feedback_points'
        )
        if (session_feedback_points) {
          var session_feedback_points_value = session_feedback_points?.value
        }
        var help = data?.point?.find((point: any) => point?.name === 'help')
        if (help) {
          var help_value = help?.value
        }
        var session_attendance = data?.point?.find(
          (point: any) => point?.name === 'session_attendance'
        )
        if (session_attendance) {
          var session_attendance_value = session_attendance?.value
        }
        var share_idea = data?.point?.find((point: any) => point?.name === 'share_idea')
        if (share_idea) {
          var share_idea_value = share_idea?.value
        }
        var icaniwill = data?.point?.find((point: any) => point?.name === 'icaniwill')
        if (icaniwill) {
          var icaniwill_value = icaniwill?.value
        }
        var business_referral_sent = data?.point?.find(
          (point: any) => point?.name === 'business_referral_sent'
        )
        if (business_referral_sent) {
          var business_referral_sent_value = business_referral_sent?.value
        }
        var business_referral_received = data?.point?.find(
          (point: any) => point?.name === 'business_referral_received'
        )
        if (business_referral_received) {
          var business_referral_received_value = business_referral_received?.value
        }
        var business_connect_sent = data?.point?.find(
          (point: any) => point?.name === 'business_connect_sent'
        )
        if (business_connect_sent) {
          var business_connect_sent_value = business_connect_sent?.value
        }
        var business_connect_received = data?.point?.find(
          (point: any) => point?.name === 'business_connect_received'
        )
        if (business_connect_received) {
          var business_connect_received_value = business_connect_received?.value
        }
        var business_generate_sent = data?.point?.find(
          (point: any) => point?.name === 'business_generate_sent'
        )
        if (business_generate_sent) {
          var business_generate_sent_value = business_generate_sent?.value
        }
        var business_generate_received = data?.point?.find(
          (point: any) => point?.name === 'business_generate_received'
        )
        if (business_generate_received) {
          var business_generate_received_value = business_generate_received?.value
        }
        var mr_tracking = data?.point?.find((point: any) => point?.name === 'mr_tracking')
        if (mr_tracking) {
          var mr_tracking_value = mr_tracking?.value
        }
        formik.setFieldValue('thankyou_card_given', thankyou_card_given_value ?? 0.0)
        formik.setFieldValue('thankyou_card_received', thankyou_card_received_value ?? 0.0)
        formik.setFieldValue('visitor_attended', visitor_attended_value ?? 0.0)
        formik.setFieldValue('visitor_joined', visitor_joined_value ?? 0.0)
        formik.setFieldValue(
          'group_meeting_participation',
          group_meeting_participation_value ?? 0.0
        )
        formik.setFieldValue('session_feedback_points', session_feedback_points_value ?? 0.0)
        formik.setFieldValue('help', help_value ?? 0.0)
        formik.setFieldValue('session_attendance', session_attendance_value ?? 0.0)
        formik.setFieldValue('share_idea', share_idea_value ?? 0.0)
        formik.setFieldValue('tracksheet', tracksheetvalue ?? 0.0)
        formik.setFieldValue('icaniwill', icaniwill_value ?? 0.0)
        formik.setFieldValue('business_referral_sent', business_referral_sent_value ?? 0.0)
        formik.setFieldValue('business_referral_received', business_referral_received_value ?? 0.0)
        formik.setFieldValue('business_connect_sent', business_connect_sent_value ?? 0.0)
        formik.setFieldValue('business_connect_received', business_connect_received_value ?? 0.0)
        formik.setFieldValue('business_generate_sent', business_generate_sent_value ?? 0.0)
        formik.setFieldValue('business_generate_received', business_generate_received_value ?? 0.0)
        formik.setFieldValue('mr_tracking', mr_tracking_value ?? 0.0)
      }
      if (data?.chapter?.length > 0) {
        const setCh = data?.chapter?.map((x: any) => {
          var label = x?.name
          var value = x?.id
          return {value: value, label: label}
        })
        if (setCh?.length > 0) {
          setSelectedChapters(setCh ?? [])
        }
      }
      if (data?.permissionModel?.length > 0) {
        const setPr = data?.permissionModel?.map((x: any) => {
          var label = x?.value.replace(/_/g, ' ').replace(/^\w/, (c: any) => c?.toUpperCase())
          var value = x?.id
          return {value: value, label: label}
        })
        if (setPr?.length > 0) {
          setSelectedPermission(setPr ?? [])
        }
      }
    }
  }, [data])

  console.log(selectedPermission)

  return (
    <>
      <form
        action=''
        onSubmit={(e) => {
          e.preventDefault()
          formik.handleSubmit(e)
        }}
      >
        <div className='row'>
          <div className='col-sm-12 col-md-6 mb-8'>
            <Form.Label className='fs-6 fw-semibold text-primary w-100 text-dark text-start'>
              {' '}
              Vertical Name <span className='text-danger'> * </span>{' '}
            </Form.Label>
            <Form.Control
              required
              type='text'
              placeholder=''
              className='ps-0'
              onChange={(e) => formik.setFieldValue('name', e.target.value)}
              value={formik.values.name}
            />
          </div>

          <div className='col-sm-12 col-md-6 mb-8'>
            <Form.Label className='fs-6 fw-semibold text-primary w-100 text-dark text-start'>
              {' '}
              Select Chapter <span className='text-danger'> * </span>{' '}
            </Form.Label>
            <Select
              isMulti
              closeMenuOnSelect={false}
              options={chapterList}
              value={selectedChapters}
              onChange={(selectedOptions) => {
                setSelectedChapters(selectedOptions)
              }}
            />
          </div>
        </div>
        <div className='row'>
          <div className='col-sm-12 mb-8'>
            <Form.Label className='fs-6 fw-semibold text-primary w-100 text-dark text-start'>
              {' '}
              Select Permission <span className='text-danger'> * </span>{' '}
            </Form.Label>
            <Select
              isMulti
              closeMenuOnSelect={false}
              options={permissionList}
              value={selectedPermission}
              onChange={(selectedOptions) => {
                setSelectedPermission(selectedOptions)
              }}
            />
          </div>
        </div>
        <div className='row'>
          <div className='col-md-12 col-lg-3 mb-8'>
            <Form.Label className='fs-6 fw-semibold text-primary w-100 text-dark text-start'>
              {' '}
              Thank You Given{' '}
            </Form.Label>
            <Form.Control
              type='number'
              onWheel={(e) => (e.target as HTMLInputElement).blur()}
              min={0}
              defaultValue={0}
              placeholder=''
              className='ps-0'
              onChange={(e) => formik.setFieldValue('thankyou_card_given', e.target.value)}
              value={formik.values.thankyou_card_given}
            />
          </div>

          <div className='col-md-12 col-lg-3 mb-8'>
            <Form.Label className='fs-6 fw-semibold text-primary w-100 text-dark text-start'>
              {' '}
              Thank You Received{' '}
            </Form.Label>
            <Form.Control
              type='number'
              onWheel={(e) => (e.target as HTMLInputElement).blur()}
              min={0}
              defaultValue={0}
              placeholder=''
              className='ps-0'
              onChange={(e) => formik.setFieldValue('thankyou_card_received', e.target.value)}
              value={formik.values.thankyou_card_received}
            />
          </div>

          <div className='col-md-12 col-lg-3 mb-8'>
            <Form.Label className='fs-6 fw-semibold text-primary w-100 text-dark text-start'>
              {' '}
              Visitor Attended{' '}
            </Form.Label>
            <Form.Control
              type='number'
              onWheel={(e) => (e.target as HTMLInputElement).blur()}
              min={0}
              defaultValue={0}
              placeholder=''
              className='ps-0'
              onChange={(e) => formik.setFieldValue('visitor_attended', e.target.value)}
              value={formik.values.visitor_attended}
            />
          </div>

          <div className='col-md-12 col-lg-3 mb-8'>
            <Form.Label className='fs-6 fw-semibold text-primary w-100 text-dark text-start'>
              {' '}
              Visitor Joined{' '}
            </Form.Label>
            <Form.Control
              type='number'
              onWheel={(e) => (e.target as HTMLInputElement).blur()}
              min={0}
              defaultValue={0}
              placeholder=''
              className='ps-0'
              onChange={(e) => formik.setFieldValue('visitor_joined', e.target.value)}
              value={formik.values.visitor_joined}
            />
          </div>
        </div>

        <div className='row'>
          <div className='col-md-12 col-lg-3 mb-8'>
            <Form.Label className='fs-6 fw-semibold text-primary w-100 text-dark text-start'>
              {' '}
              GM Attended{' '}
            </Form.Label>
            <Form.Control
              type='number'
              onWheel={(e) => (e.target as HTMLInputElement).blur()}
              min={0}
              defaultValue={0}
              placeholder=''
              className='ps-0'
              onChange={(e) => formik.setFieldValue('group_meeting_participation', e.target.value)}
              value={formik.values.group_meeting_participation}
            />
          </div>

          <div className='col-md-12 col-lg-3 mb-8'>
            <Form.Label className='fs-6 fw-semibold text-primary w-100 text-dark text-start'>
              {' '}
              Session Feedback{' '}
            </Form.Label>
            <Form.Control
              type='number'
              onWheel={(e) => (e.target as HTMLInputElement).blur()}
              min={0}
              defaultValue={0}
              placeholder=''
              className='ps-0'
              onChange={(e) => formik.setFieldValue('session_feedback_points', e.target.value)}
              value={formik.values.session_feedback_points}
            />
          </div>

          <div className='col-md-12 col-lg-3 mb-8'>
            <Form.Label className='fs-6 fw-semibold text-primary w-100 text-dark text-start'>
              {' '}
              Help{' '}
            </Form.Label>
            <Form.Control
              type='number'
              onWheel={(e) => (e.target as HTMLInputElement).blur()}
              min={0}
              defaultValue={0}
              placeholder=''
              className='ps-0'
              onChange={(e) => formik.setFieldValue('help', e.target.value)}
              value={formik.values.help}
            />
          </div>

          <div className='col-md-12 col-lg-3 mb-8'>
            <Form.Label className='fs-6 fw-semibold text-primary w-100 text-dark text-start'>
              {' '}
              Session Attended{' '}
            </Form.Label>
            <Form.Control
              type='number'
              onWheel={(e) => (e.target as HTMLInputElement).blur()}
              min={0}
              defaultValue={0}
              placeholder=''
              className='ps-0'
              onChange={(e) => formik.setFieldValue('session_attendance', e.target.value)}
              value={formik.values.session_attendance}
            />
          </div>
        </div>

        <div className='row'>
          {/* <div className='col-md-12 col-lg-3 mb-8'>
            <Form.Label className='fs-6 fw-semibold text-primary w-100 text-dark text-start'>
              {' '}
              Share Idea{' '}
            </Form.Label>
            <Form.Control
              type='number'
              onWheel={(e) => (e.target as HTMLInputElement).blur()}
              min={0}
              defaultValue={0}
              placeholder=''
              className='ps-0'
              onChange={(e) => formik.setFieldValue('share_idea', e.target.value)}
              value={formik.values.share_idea}
            />
          </div> */}

          <div className='col-md-12 col-lg-3 mb-8'>
            <Form.Label className='fs-6 fw-semibold text-primary w-100 text-dark text-start'>
              {' '}
              Tracksheet{' '}
            </Form.Label>
            <Form.Control
              type='number'
              onWheel={(e) => (e.target as HTMLInputElement).blur()}
              min={0}
              defaultValue={0}
              placeholder=''
              className='ps-0'
              onChange={(e) => formik.setFieldValue('tracksheet', e.target.value)}
              value={formik.values.tracksheet}
            />
          </div>

          <div className='col-md-12 col-lg-3 mb-8'>
            <Form.Label className='fs-6 fw-semibold text-primary w-100 text-dark text-start'>
              {' '}
              I CAN I WILL{' '}
            </Form.Label>
            <Form.Control
              type='number'
              onWheel={(e) => (e.target as HTMLInputElement).blur()}
              min={0}
              defaultValue={0}
              placeholder=''
              className='ps-0'
              onChange={(e) => formik.setFieldValue('icaniwill', e.target.value)}
              value={formik.values.icaniwill}
            />
          </div>

          <div className='col-md-12 col-lg-3 mb-8'>
            <Form.Label className='fs-6 fw-semibold text-primary w-100 text-dark text-start'>
              {' '}
              Business Referral{' '}
            </Form.Label>
            <Form.Control
              type='number'
              onWheel={(e) => (e.target as HTMLInputElement).blur()}
              min={0}
              defaultValue={0}
              placeholder=''
              className='ps-0'
              onChange={(e) => formik.setFieldValue('business_referral_sent', e.target.value)}
              value={formik.values.business_referral_sent}
            />
          </div>
        

        
          <div className='col-md-12 col-lg-3 mb-8'>
            <Form.Label className='fs-6 fw-semibold text-primary w-100 text-dark text-start'>
              {' '}
              Business Referral Received{' '}
            </Form.Label>
            <Form.Control
              type='number'
              onWheel={(e) => (e.target as HTMLInputElement).blur()}
              min={0}
              defaultValue={0}
              placeholder=''
              className='ps-0'
              onChange={(e) => formik.setFieldValue('business_referral_received', e.target.value)}
              value={formik.values.business_referral_received}
            />
          </div>
          </div>
          <div className='row'>

          <div className='col-md-12 col-lg-3 mb-8'>
            <Form.Label className='fs-6 fw-semibold text-primary w-100 text-dark text-start'>
              {' '}
              Business Connect{' '}
            </Form.Label>
            <Form.Control
              type='number'
              onWheel={(e) => (e.target as HTMLInputElement).blur()}
              min={0}
              defaultValue={0}
              placeholder=''
              className='ps-0'
              onChange={(e) => formik.setFieldValue('business_connect_sent', e.target.value)}
              value={formik.values.business_connect_sent}
            />
          </div>

          <div className='col-md-12 col-lg-3 mb-8'>
            <Form.Label className='fs-6 fw-semibold text-primary w-100 text-dark text-start'>
              {' '}
              Business Connect Received{' '}
            </Form.Label>
            <Form.Control
              type='number'
              onWheel={(e) => (e.target as HTMLInputElement).blur()}
              min={0}
              defaultValue={0}
              placeholder=''
              className='ps-0'
              onChange={(e) => formik.setFieldValue('business_connect_received', e.target.value)}
              value={formik.values.business_connect_received}
            />
          </div>

          <div className='col-md-12 col-lg-3 mb-8'>
            <Form.Label className='fs-6 fw-semibold text-primary w-100 text-dark text-start'>
              {' '}
              Business Generate Sent{' '}
            </Form.Label>
            <Form.Control
              type='number'
              onWheel={(e) => (e.target as HTMLInputElement).blur()}
              min={0}
              defaultValue={0}
              placeholder=''
              className='ps-0'
              onChange={(e) => formik.setFieldValue('business_generate_sent', e.target.value)}
              value={formik.values.business_generate_sent}
            />
          </div>
        

       
          <div className='col-md-12 col-lg-3 mb-8'>
            <Form.Label className='fs-6 fw-semibold text-primary w-100 text-dark text-start'>
              {' '}
              Gratitude For Business{' '}
            </Form.Label>
            <Form.Control
              type='number'
              onWheel={(e) => (e.target as HTMLInputElement).blur()}
              min={0}
              defaultValue={0}
              placeholder=''
              className='ps-0'
              onChange={(e) => formik.setFieldValue('business_generate_received', e.target.value)}
              value={formik.values.business_generate_received}
            />
          </div>
          </div>
          <div className='row'>
          <div className='col-md-12 col-lg-3 mb-8'>
            <Form.Label className='fs-6 fw-semibold text-primary w-100 text-dark text-start'>
              {' '}
              MR Tracking{' '}
            </Form.Label>
            <Form.Control
              type='number'
              onWheel={(e) => (e.target as HTMLInputElement).blur()}
              min={0}
              defaultValue={0}
              placeholder=''
              className='ps-0'
              onChange={(e) => formik.setFieldValue('mr_tracking', e.target.value)}
              value={formik.values.mr_tracking}
            />
          </div>
        </div>

        <div className='row'>
          <div className='col-md-12 col-lg-10 text-center pe-6'>
            <button
              type='submit'
              className='btn btn-primary  waves-effect waves-light '
              disabled={
                isLoading ||
                !formik.values.name ||
                !selectedChapters.length ||
                !selectedPermission.length
              }
            >
              Save
            </button>
            <button
              type='button'
              className='btn btn-info  waves-effect ms-5 waves-light '
              onClick={navigateVerticalListing}
              ref={closeModel}
            >
              Cancel
            </button>
          </div>
        </div>
      </form>
    </>
  )
}
export default AddEditVertical
