// @ts-nocheck
import {Column} from 'react-table'
import {SummarysessionInfoCell} from './SummarysessionInfoCell'
import {SummarysessionLastLoginCell} from './SummarysessionLastLoginCell'
import {SummarysessionTwoStepsCell} from './SummarysessionTwoStepsCell'
import {SummarysessionActionsCell} from './SummarysessionActionsCell'
import {SummarysessionSelectionCell} from './SummarysessionSelectionCell'
import {SummarysessionCustomHeader} from './SummarysessionCustomHeader'
import {SummarysessionSelectionHeader} from './SummarysessionSelectionHeader'
import {Summarysession} from '../../core/_models'

const summarysessionsColumns: ReadonlyArray<Column<Summarysession>> = [
  // {
  //   Header: (props) => <SummarysessionSelectionHeader tableProps={props} />,
  //   id: 'selection',
  //   Cell: ({...props}) => <SummarysessionSelectionCell id={props.data[props.row.index].id} />,
  // },
 
  {
    Header: (props) => <SummarysessionCustomHeader tableProps={props} title='Leader Name' className='min-w-125px  ps-2 fs-5' />,
    // accessor: 'user_name',
    accessor: 'name',
    // id: 'name',
    // Cell: ({...props}) => <SummarysessionInfoCell summarysession={props.data[props.row.index]} />,
  },

  {
    Header: (props) => <SummarysessionCustomHeader tableProps={props} title='A' className=' fs-5' />,
    // accessor: 'a',
    accessor: 'isattended',
    // id: 'name',
    // Cell: ({...props}) => <SummarysessionInfoCell summarysession={props.data[props.row.index]} />,
  },


  {
    Header: (props) => <SummarysessionCustomHeader tableProps={props} title='VI' className=' fs-5' />,
    // accessor: 'vi',
    accessor: 'visitors_invited',
    
    // id: 'name',
    // Cell: ({...props}) => <SummarysessionInfoCell summarysession={props.data[props.row.index]} />,
  },
  
  {
    Header: (props) => <SummarysessionCustomHeader tableProps={props} title='VA' className=' fs-5' />,
    // accessor: 'va',
    accessor: 'visitors_attended',
    // id: 'name',
    // Cell: ({...props}) => <SummarysessionInfoCell summarysession={props.data[props.row.index]} />,
  },
  
  {
    Header: (props) => <SummarysessionCustomHeader tableProps={props} title='VJ' className=' fs-5' />,
    // accessor: 'vj',
    accessor: 'visitors_joined',
    // id: 'name',
    // Cell: ({...props}) => <SummarysessionInfoCell summarysession={props.data[props.row.index]} />,
  },
  
  {
    Header: (props) => <SummarysessionCustomHeader tableProps={props} title='TG' className=' fs-5' />,
    // accessor: 'tg',
    accessor: 'thankyou_given',
    // id: 'name',
    // Cell: ({...props}) => <SummarysessionInfoCell summarysession={props.data[props.row.index]} />,
  },
  
  {
    Header: (props) => <SummarysessionCustomHeader tableProps={props} title='TR' className=' fs-5' />,
    // accessor: 'tr',
    accessor: 'thankyou_received',
    // id: 'name',
    // Cell: ({...props}) => <SummarysessionInfoCell summarysession={props.data[props.row.index]} />,
  },
  
  {
    Header: (props) => <SummarysessionCustomHeader tableProps={props} title='GMA' className=' fs-5' />,
    // accessor: 'gma',
    accessor: 'group_meeting_attended',
    // id: 'name',
    // Cell: ({...props}) => <SummarysessionInfoCell summarysession={props.data[props.row.index]} />,
  },
  
  {
    Header: (props) => <SummarysessionCustomHeader tableProps={props} title='TU' className=' fs-5' />,
    // accessor: 'tu',
    accessor: 'tracksheet_count',
    // id: 'name',
    // Cell: ({...props}) => <SummarysessionInfoCell summarysession={props.data[props.row.index]} />,
  },
  
  {
    Header: (props) => <SummarysessionCustomHeader tableProps={props} title='FG' className=' fs-5' />,
    // accessor: 'fg',
    accessor: 'isfeedbackgiven',
    // id: 'name',
    // Cell: ({...props}) => <SummarysessionInfoCell summarysession={props.data[props.row.index]} />,
  },
  
  {
    Header: (props) => <SummarysessionCustomHeader tableProps={props} title='H' className=' fs-5' />,
    // accessor: 'h',
    accessor: 'help_posts',
    // id: 'name',
    // Cell: ({...props}) => <SummarysessionInfoCell summarysession={props.data[props.row.index]} />,
  },
  
  {
    Header: (props) => <SummarysessionCustomHeader tableProps={props} title='ICIW' className=' fs-5' />,
    // accessor: 'iciw',
    accessor: 'icaniwill_count',
    // id: 'name',
    // Cell: ({...props}) => <SummarysessionInfoCell summarysession={props.data[props.row.index]} />,
  },

  {
    Header: (props) => <SummarysessionCustomHeader tableProps={props} title='Points' className=' fs-5' />,
    // accessor: 'points',
    accessor: 'total_points',
  },

  // {
  //   Header: (props) => (
  //     <SummarysessionCustomHeader tableProps={props} title='Last login' className='' />
  //   ),
  //   id: 'last_login',
  //   Cell: ({...props}) => <SummarysessionLastLoginCell last_login={props.data[props.row.index].last_login} />,
  // },
  // {
  //   Header: (props) => (
  //     <SummarysessionCustomHeader tableProps={props} title='Two steps' className='min-w-125px' />
  //   ),
  //   id: 'two_steps',
  //   Cell: ({...props}) => <SummarysessionTwoStepsCell two_steps={props.data[props.row.index].two_steps} />,
  // },
  // {
  //   Header: (props) => (
  //     <SummarysessionCustomHeader tableProps={props} title='Joined day' className='min-w-125px' />
  //   ),
  //   accessor: 'joined_day',
  // },
  // {
  //   Header: (props) => (
  //     <SummarysessionCustomHeader tableProps={props} title='Actions' className='text-end min-w-100px' />
  //   ),
  //   id: 'actions',
  //   Cell: ({...props}) => <SummarysessionActionsCell id={props.data[props.row.index].id} />,
  // },
]

export {summarysessionsColumns}
