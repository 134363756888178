import { ListViewProvider, useListView } from './core/ListViewProvider'
import { QueryRequestProvider } from './core/QueryRequestProvider'
import { QueryResponseProvider } from './core/QueryResponseProvider'
import { GroupmanagementsListHeader } from './components/header/GroupmanagementsListHeader'
import { GroupmanagementsTable } from './table/GroupManagementsTable'
import { GroupmanagementEditModal } from './groupmanagement-edit-modal/GroupManagementEditModal'
import { KTCard } from '../../../../_metronic/helpers'
// import {KTCard} from '../../../../../_metronic/helpers'

const GroupmanagementsList = () => {
  const { itemIdForUpdate } = useListView()
  return (
    <>
   
        {/* <GroupmanagementsListHeader /> */}
        <GroupmanagementsTable />
    
      {itemIdForUpdate !== undefined && <GroupmanagementEditModal />}
    </>
  )
}

const GroupmanagementsListWrapper = () => (

  <ListViewProvider>
    <GroupmanagementsList />
  </ListViewProvider>

)

export { GroupmanagementsListWrapper }
