import axios from 'axios'
import {apiUrl} from '../../../apiurl'

const API_URL = apiUrl
const ThankYouCardReceived_URL = `${API_URL}/thankyouhistories?filter[where][isdelete]=0&filter[include]=sender`
const ThankYouCardGiven_URL = `${API_URL}/thankyouhistories?filter[where][isdelete]=0&filter[include]=receiver`
const MemberInvited_URL  = `${API_URL}/invitionhistories?filter[where][isdelete]=0&filter[where][isvisited]=1`
const MemberJoinCard_URL  = `${API_URL}/invitionhistories?filter[where][isdelete]=0&filter[where][isjoin]=4`
const User_URL =`${API_URL}/users`
const IMAGE_UPLOAD = `${API_URL}/containers/userprofile/upload`

export function getThankYouCardGiven(userid:any) {
    return axios
      .get<any>(`${ThankYouCardGiven_URL}&filter[skip]=0&filter[where][send_by]=${userid}`)
      .then((responce: any) => {
        return responce.data
      })
      .catch((error) => {
        console.error('Error All  Monthlybusinessleaderpoints Data:', error)
        throw error
      })
  }

export function getThankYouCardReceived(userid:any) {
    return axios
      .get<any>(`${ThankYouCardReceived_URL}&filter[skip]=0&filter[where][receive_by]=${userid}`)
      .then((responce: any) => {
        return responce.data
      })
      .catch((error) => {
        console.error('Error All  Monthlybusinessleaderpoints Data:', error)
        throw error
      })
  }

export function getMemberInvited(userid:any,searchCard:any) {
    return axios
      .get<any>(`${MemberInvited_URL}${searchCard}&filter[skip]=0&filter[where][invited_by]=${userid}`)
      .then((responce: any) => {
        return responce.data
      })
      .catch((error) => {
        console.error('Error All  Monthlybusinessleaderpoints Data:', error)
        throw error
      })
  }

  export function getMemberJoin(userid:any,searchCard:any) {
    return axios
      .get<any>(`${MemberJoinCard_URL}${searchCard}&filter[skip]=0&filter[where][invited_by]=${userid}`)
      .then((responce: any) => {
        return responce.data
      })
      .catch((error) => {
        console.error('Error All  Monthlybusinessleaderpoints Data:', error)
        throw error
      })
  }

  export function EditProfile(userid:any,body:any) {
    return axios
      .patch<any>(`${User_URL}/${userid}`,body)
      .then((responce: any) => {
        return responce
      })
      .catch((error) => {
        console.error('Error All  Profile upData :', error)
        throw error
      })
  }

  export function UploadFiile(formData:any) {
    return axios
      .patch<any>(`${IMAGE_UPLOAD}`,formData,{
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((responce: any) => {
        return responce.data
      })
      .catch((error) => {
        console.error('Error All  Profile upData :', error)
        throw error
      })
  }