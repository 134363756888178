/* eslint-disable react-hooks/exhaustive-deps */
import clsx from 'clsx'
import {Link, useLocation} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../helpers'
import {useLayout} from '../../core'
import {Header} from './Header'
import {Navbar} from './Navbar'
import {Image} from 'react-bootstrap'
import {useContext, useEffect, useState} from 'react'
import {UserContext} from '../../../../contextStore'
import {apiUrl} from '../../../../apiurl'
import axios from 'axios'
const API_URL = apiUrl
export function HeaderWrapper() {
  const {userDetail} = useContext(UserContext)
  const [GIcolor, setGIcolor] = useState<string | null>('')
  const user_data_URL = `${API_URL}/users/${userDetail?.id ? userDetail?.id : ''}`

  const {config, classes} = useLayout()
  const location = useLocation()
  const hideHeaderRoutes = ['/chat-list']
  // if (hideHeaderRoutes.includes(location.pathname)) {
  //   return null // Return null to hide the header for specific routes
  // }
  // if (!config.app?.header?.display) {
  //   return null
  // }

  const fetchData = async () => {
    try {
      const res = await axios.get(user_data_URL)
      if (res?.data?.GIColor) {
        setGIcolor(res?.data?.GIColor)
      }
    } catch (error) {
      console.error('Error -->:', error)
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  return (
    <div id='kt_app_header' className='app-header d-block d-lg-none'>
      <div
        id='kt_app_header_container'
        className={clsx(
          'app-container flex-lg-grow-1',
          classes.headerContainer.join(' '),
          config?.app?.header?.default?.containerClass
        )}
      >
        {config?.app?.sidebar?.display && (
          <>
            <div
              className='d-flex align-items-center d-lg-none ms-n2 me-2'
              title='Show sidebar menu'
            >
              <div
                className='btn btn-icon btn-active-color-primary w-60px h-60px'
                id='kt_app_sidebar_mobile_toggle'
              >
                <div
                  className='cursor-pointer symbol symbol-circle symbol-45px symbol-md-45px me-3'
                  style={{
                    borderColor: GIcolor ? GIcolor : '',
                    borderStyle: GIcolor ? 'solid' : '',
                    borderWidth: GIcolor ? '2px' : '',
                  }}
                >
                  <Image
                    src={
                      userDetail.profile
                        ? `${API_URL}/containers/userprofile/download/${userDetail.profile}`
                        : `${toAbsoluteUrl('./media/avatars/default-user.png')}`
                    }
                    onError={({currentTarget}: any) => {
                      currentTarget.onerror = null // prevents looping
                      currentTarget.src = `${toAbsoluteUrl('./media/avatars/default-user.png')}`
                    }}
                  />
                </div>
              </div>
              <div className='d-flex align-items-center flex-grow-1 flex-lg-grow-0'>
                <Link to='/dashboard' className='d-lg-none ml-3'>
                  <img
                    alt='Logo'
                    src={toAbsoluteUrl('/media/logos/ilead-logo.jpg')}
                    className='h-40px'
                  />
                </Link>
              </div>
            </div>
          </>
        )}

        {!(config?.layoutType === 'dark-sidebar' || config?.layoutType === 'light-sidebar') && (
          <div className='d-flex align-items-center flex-grow-1 flex-lg-grow-0 me-lg-15'>
            <Link to='/dashboard'>
              {config.layoutType !== 'dark-header' ? (
                <img
                  alt='Logo'
                  src={toAbsoluteUrl('/media/logos/ilead-logo.jpg')}
                  className='h-20px h-lg-30px app-sidebar-logo-default'
                />
              ) : (
                <>
                  <img
                    alt='Logo'
                    src={toAbsoluteUrl('/media/logos/ilead-logo.jpg')}
                    className='h-20px h-lg-30px app-sidebar-logo-default theme-light-show'
                  />
                  <img
                    alt='Logo'
                    src={toAbsoluteUrl('/media/logos/ilead-logo.jpg')}
                    className='h-20px h-lg-30px app-sidebar-logo-default theme-dark-show'
                  />
                </>
              )}
            </Link>
          </div>
        )}

        <div
          id='kt_app_header_wrapper'
          className='d-flex align-items-stretch justify-content-between flex-lg-grow-1'
        >
          {config?.app?.header?.default?.content === 'menu' &&
            config?.app?.header?.default.menu?.display && (
              <div
                className='app-header-menu app-header-mobile-drawer align-items-stretch'
                data-kt-drawer='true'
                data-kt-drawer-name='app-header-menu'
                data-kt-drawer-activate='{default: true, lg: false}'
                data-kt-drawer-overlay='true'
                data-kt-drawer-width='300px'
                data-kt-drawer-direction='end'
                data-kt-drawer-toggle='#kt_app_header_menu_toggle'
                data-kt-swapper='true'
                data-kt-swapper-mode="{default: 'append', lg: 'prepend'}"
                data-kt-swapper-parent="{default: '#kt_app_body', lg: '#kt_app_header_wrapper'}"
              >
                <Header />
              </div>
            )}
          <Navbar />
        </div>
      </div>
    </div>
  )
}
