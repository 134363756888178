import React from "react";



const AddEditSection = () => {
    return (
        <>
         <form action="">
                <div className="row mx-0  align-items-center my-5 px-3 ">
                    
                    <div className="col-sm-12 col-lg-12 mb-5"> <input type="text" className="form-control" placeholder="Enter section title" /> </div>
                    <div className="col-md-12 col-lg-12 text-center">
                        <button type="submit" className="btn btn-primary waves-effect waves-light btn-sm" > Submit </button>
                    </div>
                </div>
                </form>
        
        </>
    )
}

export default AddEditSection