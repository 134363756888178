import React from "react";
import { useMemo, useState, useEffect, useContext, useRef } from 'react'
import AddEditEventForm from "./AddEditEventForm";


const AddEvents = () => {
    

    return (
        <>
            <div
                className='modal fade visitor-details'
                id='addevent'
                data-bs-backdrop='static'
                data-bs-keyboard='false'
                tabIndex={-1}
                aria-labelledby='staticBackdropLabel'
                aria-hidden='true'
            >
                <div className='modal-dialog me-0 mb-0 mt-0 border-radius-0'>
                    <div className='modal-content '>

                        {/* <div className='modal-header border-bottom border-gray-300'>
                            <h5 className='modal-title text-primary' id='staticBackdropLabel'>
                                Add Event
                            </h5>
                            <button
                                type='button'
                                className='btn-close'
                                data-bs-dismiss='modal'
                                aria-label='Close'
                                ref={closebutton}
                            ></button>
                        </div> */}
                       <AddEditEventForm invokedFrom={"AddEvent"}/>
                       {/* <AddEditEventForm closebutton={closebutton}/> */}
                    </div>
                </div>
            </div>
        </>
    )
}

export default AddEvents
