// @ts-nocheck
import {Column} from 'react-table'
import {VisitorpanelInfoCell} from './VisitorpanelInfoCell'
import {VisitorpanelLastLoginCell} from './VisitorpanelLastLoginCell'
import {VisitorpanelTwoStepsCell} from './VisitorpanelTwoStepsCell'
import {VisitorpanelActionsCell} from './VisitorpanelActionsCell'
import {VisitorpanelMembershipCell} from './VisitorpanelMembershipCell'
import {VisitorpanelSelectionCell} from './VisitorpanelSelectionCell'
import {VisitorpanelCustomHeader} from './VisitorpanelCustomHeader'
import {VisitorpanelSelectionHeader} from './VisitorpanelSelectionHeader'
import {Visitorpanel} from '../../core/_models'
import { formatDate,checkDisplayValue, indiaFormatDate } from '../../../../../../utils'
const visitorpanelsColumns: ReadonlyArray<Column<Visitorpanel>> = [
  // {
  //   Header: (props) => <VisitorpanelSelectionHeader tableProps={props} />,
  //   id: 'selection',
  //   Cell: ({...props}) => <VisitorpanelSelectionCell id={props.data[props.row.index].id} />,
  // },
 
  {
    Header: (props) => <VisitorpanelCustomHeader tableProps={props} title='Event Date' className='min-w-125px  ps-2 fs-5 ' />,
    accessor: 'event_date',
    // id: 'name',
    Cell:(value)=>{return indiaFormatDate(value?.row?.original?.event_date)}
    // Cell: ({...props}) => <VisitorpanelInfoCell visitorpanel={props.data[props.row.index]} />,
  },

  {
    Header: (props) => <VisitorpanelCustomHeader tableProps={props} title='Invitee Name' className=' fs-5' />,
    accessor: 'invitee_name',
    // id: 'name',
    Cell:(value)=>{return checkDisplayValue(value?.row?.original?.invitee_name)}
    // Cell: ({...props}) => <VisitorpanelInfoCell visitorpanel={props.data[props.row.index]} />,
  },


  {
    Header: (props) => <VisitorpanelCustomHeader tableProps={props} title='Invited By' className=' fs-5' />,
    accessor: 'name',
    // id: 'name',
    Cell:(value)=>{return checkDisplayValue(value?.row?.original?.name)}
    // Cell: ({...props}) => <VisitorpanelInfoCell visitorpanel={props.data[props.row.index]} />,
  },
  
  {
    Header: (props) => <VisitorpanelCustomHeader tableProps={props} title='Cell No' className=' fs-5' />,
    accessor: 'cell_no',
    // id: 'name',
    Cell:(value)=>{return checkDisplayValue(value?.row?.original?.cell_no)}

    // Cell: ({...props}) => <VisitorpanelInfoCell visitorpanel={props.data[props.row.index]} />,
  },
  
  {
    Header: (props) => <VisitorpanelCustomHeader tableProps={props} title='Company' className=' fs-5' />,
    accessor: 'company',
    // id: 'name',
    Cell:(value)=>{return checkDisplayValue(value?.row?.original?.company)}
    // Cell: ({...props}) => <VisitorpanelInfoCell visitorpanel={props.data[props.row.index]} />,
  },
  
  {
    Header: (props) => <VisitorpanelCustomHeader tableProps={props} title='Chapter' className=' min-w-200px fs-5' />,
    accessor: 'chapter_name',
    // id: 'name',
    Cell:(value)=>{return checkDisplayValue(value?.row?.original?.chapter_name)}
    
    // Cell: ({...props}) => <VisitorpanelInfoCell visitorpanel={props.data[props.row.index]} />,
  },
  
  
  {
      Header: (props) => (
        <VisitorpanelCustomHeader tableProps={props} title='Invited Status' className='  fs-5' />
      ),
      id: 'invitation_histories.isvisited',
      // Cell: ({...props}) => <VisitorpanelActionsCell id={props.value.id} />,
      Cell: (value) => {
        return <VisitorpanelActionsCell id={value?.row?.original?.id} value={value?.row?.original?.isvisited} />},
  
    },

    {
      Header: (props) => (
        <VisitorpanelCustomHeader tableProps={props} title='Membership Status' className='min-w-100px fs-5' />
      ),
      id: 'invitation_histories.isjoin',
      // Cell: ({...props}) => <VisitorpanelMembershipCell id={value?.row?.original?.id} value={value?.row?.original?.isvisited} />,
      Cell: (value) => {
        return <VisitorpanelMembershipCell id={value?.row?.original?.id} value={value?.row?.original?.isjoin} />},
    },

  // {
  //   Header: (props) => (
  //     <VisitorpanelCustomHeader tableProps={props} title='Last login' className='' />
  //   ),
  //   id: 'last_login',
  //   Cell: ({...props}) => <VisitorpanelLastLoginCell last_login={props.data[props.row.index].last_login} />,
  // },
  // {
  //   Header: (props) => (
  //     <VisitorpanelCustomHeader tableProps={props} title='Two steps' className='min-w-125px' />
  //   ),
  //   id: 'two_steps',
  //   Cell: ({...props}) => <VisitorpanelTwoStepsCell two_steps={props.data[props.row.index].two_steps} />,
  // },
  // {
  //   Header: (props) => (
  //     <VisitorpanelCustomHeader tableProps={props} title='Joined day' className='min-w-125px' />
  //   ),
  //   accessor: 'joined_day',
  // },
  // {
  //   Header: (props) => (
  //     <VisitorpanelCustomHeader tableProps={props} title='Actions' className='text-end min-w-100px' />
  //   ),
  //   id: 'actions',
  //   Cell: ({...props}) => <VisitorpanelActionsCell id={props.data[props.row.index].id} />,
  // },
]

export {visitorpanelsColumns}
