// @ts-nocheck
import {Column} from 'react-table'
import {ExitinguserInfoCell} from './ExitinguserInfoCell'
import {ExitinguserLastLoginCell} from './ExitinguserLastLoginCell'
import {ExitinguserTwoStepsCell} from './ExitinguserTwoStepsCell'
import {ExitinguserActionsCell} from './ExitinguserActionsCell'
import {ExitinguserSelectionCell} from './ExitinguserSelectionCell'
import {ExitinguserCustomHeader} from './ExitinguserCustomHeader'
import {ExitinguserSelectionHeader} from './ExitinguserSelectionHeader'
import {Exitinguser} from '../../core/_models'
import { checkDisplayValue } from '../../../../../../utils'

const exitingusersColumns: ReadonlyArray<Column<Exitinguser>> = [

  {
    Header: (props) => <ExitinguserCustomHeader tableProps={props} title='Chapter Name' className='min-w-125px   ps-2 fs-5' />,
    accessor: 'chapters.name',
    // id: 'name',
    // Cell: ({...props}) => <ExitinguserInfoCell exitinguser={props.data[props.row.index]} />,
    Cell:(value)=>{return checkDisplayValue(value?.row?.original?.chapter_name)}
  },

  {
    Header: (props) => <ExitinguserCustomHeader tableProps={props} title='Leader Name' className='min-w-125px fs-5' />,
    accessor: 'name',
    // id: 'name',
    // Cell: ({...props}) => <ExitinguserInfoCell exitinguser={props.data[props.row.index]} />,
    Cell:(value)=>{return checkDisplayValue(value?.row?.original?.name)}
  },


  {
    Header: (props) => <ExitinguserCustomHeader tableProps={props} title='Mobile' className='min-w-125px  fs-5' />,
    accessor: 'mobileno',
    // id: 'name',
    // Cell: ({...props}) => <ExitinguserInfoCell exitinguser={props.data[props.row.index]} />,
    Cell:(value)=>{return checkDisplayValue(value?.row?.original?.mobileno)}

  },
  
  {
    Header: (props) => <ExitinguserCustomHeader tableProps={props} title='Email' className=' fs-5' />,
    accessor: 'email',
    // id: 'name',
    // Cell: ({...props}) => <ExitinguserInfoCell exitinguser={props.data[props.row.index]} />,
    Cell:(value)=>{return checkDisplayValue(value?.row?.original?.email)}
    
  },
  
  {
    Header: (props) => <ExitinguserCustomHeader tableProps={props} title='Company Name' className=' fs-5' />,
    accessor: 'companyname',
    // id: 'name',
    // Cell: ({...props}) => <ExitinguserInfoCell exitinguser={props.data[props.row.index]} />,
    Cell:(value)=>{return checkDisplayValue(value?.row?.original?.companyname)}


  },
  
  {
    Header: (props) => <ExitinguserCustomHeader tableProps={props} title='OTP' className=' fs-5' />,
    accessor: 'verificationcode',
    // id: 'name',
    // Cell: ({...props}) => <ExitinguserInfoCell exitinguser={props.data[props.row.index]} />,
    Cell:(value)=>{return checkDisplayValue(value?.row?.original?.verificationcode)}

  },

  {
    Header: (props) => <ExitinguserCustomHeader tableProps={props} title='Active' className=' fs-5' />,
    accessor: 'isactive',
    // id: 'name',
    // Cell: ({...props}) => <ExitinguserInfoCell exitinguser={props.data[props.row.index]} />,
    Cell:(value)=>{return checkDisplayValue(value?.row?.original?.isactive ? "Yes" : "No")}

  },


  {
      Header: (props) => (
        <ExitinguserCustomHeader tableProps={props} title='Actions' className=' min-w-150px' />
      ),
      id: 'actions',
      Cell: ({...props}) => <ExitinguserActionsCell id={props.data[props.row.index].id} data = {props.data[props.row.index]} />,
    }


]

export {exitingusersColumns}
