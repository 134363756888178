// import { Button } from '@mui/material'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { Button, Form } from 'react-bootstrap'
import { CKEditor } from 'ckeditor4-react';
import { useNavigate, useLocation } from 'react-router-dom'
import * as Yup from 'yup'
import moment from 'moment'
import { apiUrl } from '../../../apiurl'
import { useFormik } from 'formik'
import { UserContext } from '../../../contextStore'
import axios from 'axios';
import { checkDisplayValue } from '../../../utils';

const API_URL = apiUrl
const IMAGE_UPLOAD = `${API_URL}/containers/topicimages/upload`
const CHAPTER_LIST_URL = `${API_URL}/chapters`
const Date_LIST_URL = `${API_URL}/eventdates`
const SESSION_URL = `${API_URL}/savesession`

const validationForm = Yup.object().shape({
  event_id: Yup.number().required('This field is required'),
  sessiondesc: Yup.string(),
  eventname: Yup.string().required('This field is required'),
  postonwall: Yup.number(),
  image: Yup.array()
})

const AddSessionFeedback = () => {

  const { state } = useLocation()
  const navigate = useNavigate()
  const navigateListSessionFeedback = () => {
    // 👇️ navigate to /
    navigate('/session-feedback')
  }
  const [chapterList, setChapterList] = useState<any>([])
  const [dateList, setDateList] = useState<any>([])
  const [isLoading, setIsLoading] = useState(true)
  const [chapterId, setChapterId] = useState<any>('')
  const { userDetail, setMessage } = useContext(UserContext)
  const [loading, setLoading] = useState(false)
  const todays_date = new Date().toISOString().split('T')[0]
  const [ispostonwall, setispostonwall] = useState<number>(0)
  const closebutton = useRef<any>()

  useEffect(() => {
    fetchChaptersList()
  }, [])

  useEffect(() => {
    fetchDateList()
  }, [chapterId])

  const fetchChaptersList = async () => {
    try {
      const res = await axios.get(
        `${CHAPTER_LIST_URL}?filter[where][isdelete]=0`
      )

      setChapterList(res?.data ?? [])
      setIsLoading(false)
    } catch (error) {
      console.error('Error -->:', error)
      setIsLoading(false)
    }
  }

  const fetchDateList = async () => {
    try {
      const res = await axios.get(
        `${Date_LIST_URL}?filter[limit]=4&filter[where][event_date][gte]=${todays_date}&filter[where][chapter_id]=${chapterId}&filter[order]=event_date DESC`
      )

      setDateList(res?.data ?? [])
      setIsLoading(false)
    } catch (error) {
      console.error('Error -->:', error)
      setIsLoading(false)
    }
  }

  const handlepostonwall = (checked: boolean) => {
    setispostonwall(() => {
      const updatedisPostonWall = checked ? 1 : 0
      return updatedisPostonWall
    })
  }

  const initialValues = {
    event_id: '',
    sessiondesc: '',
    eventname: '',
    postonwall: '',
    image: '',
  }

  const formik = useFormik({
    initialValues,
    validationSchema: validationForm,
    onSubmit: async (values, { setStatus, resetForm, setSubmitting }) => {
      setLoading(true)
      try {
        const postData = {
          event_id: values.event_id,
          sessiondesc: values.sessiondesc,
          eventname: values.eventname,
          postonwall: ispostonwall,
          image: values.image,
        }
        await axios.post(SESSION_URL, postData)
          .then((res: any) => {
            if (res?.status == 200) {
              setMessage('success', res?.data?.msg)
              setLoading(false)
              resetForm()
              setChapterId('')
              setispostonwall(0)
              closebutton.current.click()
            }
          })
          .catch((error: any) => {
            if (error?.response?.data?.error?.message) {
              setMessage('error', error?.response?.data?.error?.message)
            } else {
              setMessage('error', 'Something Went Wrong')
            }
            setLoading(false)
            resetForm()
            setChapterId('')
          })
      } catch (error: any) {
        setMessage('error', error?.message ? error?.message : 'Something Went Wrong')
        setLoading(false)
        resetForm()
        setChapterId('')
      }
    },
  })

  return (
    <>
      <div className='row flex-md-block '>
        <div className='col-sm-12 col-md-6 col-lg-6'>
          <h1 className='inv-hst sm-ms-2'> Add/Update Session Details </h1>
        </div>

        <div className='col-sm-12  col-md-6 col-lg-6 text-end pe-5'>
          <button
            onClick={navigateListSessionFeedback}
            className='btn btn-info waves-effect waves-light '
            ref={closebutton}
          >
            {' '}
            Back{' '}
          </button>
        </div>
      </div>

      <div className='card  p-8 mt-5'>
        <Form onSubmit={formik.handleSubmit}>
          <div className='row mt-5 '>
            <div className='col-md-12 col-lg-6 mb-10'>
              <label className='fw-semibold fs-7  text-primary' > Chapter Name </label>
              <Form.Select aria-label='Default select example' required className='border-left-0 border-top-0 border-right-0 border-radius-0 ps-0' value={chapterId}
                onChange={(e) => setChapterId(e.target.value)}>
                {chapterList?.map((i: any) => (
                  <>
                    <option value={i?.id}> {i?.name} </option>
                  </>
                ))}
              </Form.Select>
            </div>
            <div className='col-md-12 col-lg-6 mb-10'>
              <label className='fw-semibold fs-7 text-primary' > Session Date </label>
              <Form.Select aria-label='Default select example' required className='border-left-0 border-top-0 border-right-0 border-radius-0 ps-0' value={formik.values.event_id}
                onChange={(e: any) => {
                  formik.setFieldValue('event_id', e.target.value)
                }}>
                {dateList?.map((i: any) => (
                  <>
                    <option value={i?.id}>
                      {' '}
                      {checkDisplayValue(moment(i?.event_date).format('MMM DD, YYYY'))}{' '}
                    </option>
                  </>
                ))}
              </Form.Select>
            </div>
          </div>

          <div className='col-12  mb-10'>
            <label className='fw-semibold fs-5' > Session Name <span className='text-danger fs-8'> * </span></label>
            <Form.Control required type='text' placeholder='' className='border-left-0 border-top-0 border-right-0 border-radius-0 ps-0' onChange={(e) => {
              formik.setFieldValue('eventname', e.target.value)
            }} value={formik.values.eventname} />
          </div>

          <div className='col-12 mt-10'>
            <label className='fw-semibold fs-5 mb-3'>Add/Update Session Flow <span className='text-danger fs-8'> * </span></label>
            <CKEditor />

          </div>
          <div className='row mt-10'>
            <div className="form-check col-sm-8">
              <input className="form-check-input" type="checkbox" onChange={(e) => handlepostonwall(e.target.checked)}
                checked={ispostonwall === 1} id="flexCheckChecked" />
              <label className="form-check-label" >
                Do Want to post this session flow on dashboard?
              </label>
            </div>
            <div className='col-sm-4'> <Button variant='primary' type='submit' className=' w-100' disabled={formik.isSubmitting || loading}>
              Save
            </Button> </div>
          </div>

        </Form>
      </div>


    </>
  )
}

export default AddSessionFeedback
