import React from "react";
import { TemplatesListWrapper } from "./templates-list/TemplatesList";



const TemplateListing = () => {

return(

    <>
      <h1 className='inv-hst'> Templates </h1>
            <div className='mx-3 mx-md-0 pt-5'>

            <TemplatesListWrapper />

            </div>
    
    
    </>
)


}

export default TemplateListing