import React, {useEffect, useState} from 'react'
import {Form} from 'react-bootstrap'
import {useNavigate, useParams} from 'react-router-dom'
import Multiselect from 'multiselect-react-dropdown'
import {apiUrl} from '../../../../apiurl'
import axios from 'axios'
import {checkDisplayValue} from '../../../../utils'
import moment from 'moment'

const API_URL = apiUrl
const ViewReminder = () => {
  const {id} = useParams()
  const [data, setData] = useState<any>([])
  const [isLoading, setIsLoading] = useState(true)
  const VIEWBYID_REMINDER_URL = `${API_URL}/messagecenterreminders/viewOneReminder`
  const USER_URL = `${API_URL}/users`
  const CHAPTER_URL = `${API_URL}/chapters`
  const GROUP_URL = `${API_URL}/groups`
  const [userNames, setUserNames] = useState<any>([])
  const [chapterNames, setChapterNames] = useState<any>([])
  const [groupNames, setGroupNames] = useState<any>([])

  const navigate = useNavigate()
  const navigateReminderListing = () => {
    // 👇️ navigate to /
    navigate('/message-center-reminders')
  }

  useEffect(() => {
    fetchData()
  }, [])

  const fetchData = async () => {
    try {
      const res = await axios.get(`${VIEWBYID_REMINDER_URL}/${id}`)
      setData(res?.data ?? [])
      setIsLoading(false)

      // Fetch User Names
      const numericUserIds = res?.data?.users.filter((user: any) => !isNaN(user))
      const userPromises = numericUserIds.map((userId: any) => {
        const apiUrl = `${USER_URL}/${userId}`
        return axios.get(apiUrl).then((response) => response?.data)
      })
      const resolvedUserNames = await Promise.all(userPromises)
      console.log('Resolved User Names:', resolvedUserNames)
      setUserNames(resolvedUserNames)

      // Fetch Chapter Names
      const numericChapterIds = res?.data?.batches.filter((chapter: any) => !isNaN(chapter))
      const chapterPromises = numericChapterIds.map((chapterId: any) => {
        const apiUrl = `${CHAPTER_URL}/${chapterId}`
        return axios.get(apiUrl).then((response) => response?.data)
      })
      const resolvedChapterNames = await Promise.all(chapterPromises)
      console.log('Resolved Chapter Names:', resolvedChapterNames)
      setChapterNames(resolvedChapterNames)

      // Fetch Group Names
      const numericGroupIds = res?.data?.groups.filter((group: any) => !isNaN(group))
      const groupPromises = numericGroupIds.map((groupId: any) => {
        const apiUrl = `${GROUP_URL}/${groupId}`
        return axios.get(apiUrl).then((response) => response?.data)
      })
      const resolvedGroupNames = await Promise.all(groupPromises)
      console.log('Resolved Group Names:', resolvedGroupNames)
      setGroupNames(resolvedGroupNames)
    } catch (error) {
      //   console.log('Error -->:', error)
      setIsLoading(false)
    }
  }

  console.log('User Names:', userNames) // Debugging: Check if user names are correctly populated
  console.log('Data Users:', data.users) // Debugging: Check the data.users array

  const styles = {
    borderRadius: '0.475rem',
    boxShadow: '0 0 50px 0 rgb(82 63 105 / 15%)',
    backgroundColor: '#fff',
    color: '#7e8299',
    fontWeight: '500',
    margin: '0',
    width: 'auto',
    padding: '1rem 2rem',
    top: 'calc(50% - 2rem)',
    left: 'calc(50% - 4rem)',
  }

  if (isLoading) {
    return <div style={{...styles, position: 'absolute', textAlign: 'center'}}>Processing...</div>
  }

  const chnameOptions = data?.batches?.map((chapter: any) => {
    console.log('chaprNames', chapter)
    console.log('chapterNameschapterNames', chapterNames)
    const chapterNameObj = chapterNames.find((name: any) => name?.id.toString() === chapter);
    const chapterName = chapterNameObj ? chapterNameObj?.name : chapter;
    console.log('chapterNamechapterName', chapterName);
    return { key: chapterName, value: chapterName };
  });
  

  const grnameOptions = data?.groups?.map((group: any) => {
    const groupNameObj = groupNames.find((name: any) => name?.id.toString() === group)
    const groupKey = groupNameObj ? groupNameObj?.name : group
    return {key: groupKey, value: groupKey}
    // return {key: groupName || group, value: groupName || group}
  })

  const usrnameOptions = data?.users?.map((user: any) => {
    const userNameObj = userNames.find((name: any) => name?.id.toString() === user)
    const userKey = userNameObj ? userNameObj?.name : user
    return {key: userKey, value: userKey}
    // return {key: userName || user, value: userName || user}
  })

  //   console.log('Response -->:', data)

  return (
    <>
      <div className='row mx-0 flex-md-block '>
        <div className='col-sm-6 col-md-6 col-lg-6'>
          <h1 className='inv-hst sm-ms-2'> View Reminder </h1>
        </div>
      </div>

      <div className='card mx-3 py-8 px-5 px-sm-8 mt-5 visitor-details ilead__card'>
        <form action=''>
          <div className='row'>
            <div className='col-sm-12 col-md-6 mb-8'>
              <Form.Label className='fs-6 fw-semibold text-primary w-100 text-dark text-start'>
                {' '}
                Event Title{' '}
              </Form.Label>
              <Form.Control
                disabled
                type='text'
                placeholder=''
                className='ps-0'
                value={checkDisplayValue(data?.title)}
              />
            </div>

            <div className='col-sm-12 col-md-6 mb-8'>
              <Form.Label className='fs-6 fw-semibold text-primary w-100 text-dark text-start'>
                {' '}
                Notification Template{' '}
              </Form.Label>
              <Form.Select
                aria-label='Default select example'
                className='ps-0'
                disabled
                value={checkDisplayValue(data?.notificationtemplateid)}
              >
                <option> Select Notification Template </option>
                <option value={data?.notificationtemplateid}> General </option>
              </Form.Select>
            </div>
          </div>

          <div className='row'>
            <div className='col-12 mb-8'>
              <Form.Label className='fs-6 fw-semibold text-primary w-100 text-dark text-start'>
                {' '}
                Event Description{' '}
              </Form.Label>
              <Form.Control
                disabled
                as='textarea'
                rows={4}
                placeholder=''
                className='ps-0'
                value={checkDisplayValue(data?.description)}
              />
            </div>
          </div>

          <div className='row'>
            <div className='col-md-12 col-lg-6  mb-8'>
              <Form.Label className='fs-6 fw-semibold text-primary w-100 text-dark text-start'>
                {' '}
                Event Time{' '}
              </Form.Label>
              <Form.Control
                disabled
                type='time'
                placeholder='Presentation  Title'
                className='ps-0'
                value={data?.event_time}
              />
            </div>

            <div className='col-md-12 col-lg-6  mb-8'>
              <Form.Label className='fs-6 fw-semibold text-primary w-100 text-dark text-start'>
                {' '}
                Select Chapter{' '}
              </Form.Label>
              <Multiselect
                disable
                className='ps-0'
                displayValue='key'
                placeholder=''
                onKeyPressFn={function noRefCheck() {}}
                onRemove={function noRefCheck() {}}
                onSearch={function noRefCheck() {}}
                onSelect={function noRefCheck() {}}
                selectedValues={chnameOptions}
              />
            </div>
          </div>

          <div className='row'>
            <div className='col-md-12 col-lg-6  mb-8'>
              <Form.Label className='fs-6 fw-semibold text-primary w-100 text-dark text-start'>
                {' '}
                Select Group{' '}
              </Form.Label>
              <Multiselect
                disable
                className='ps-0'
                displayValue='key'
                placeholder=''
                onKeyPressFn={function noRefCheck() {}}
                onRemove={function noRefCheck() {}}
                onSearch={function noRefCheck() {}}
                onSelect={function noRefCheck() {}}
                selectedValues={grnameOptions}
              />
            </div>

            <div className='col-md-12 col-lg-6  mb-8'>
              <Form.Label className='fs-6 fw-semibold text-primary w-100 text-dark text-start'>
                {' '}
                Select Leaders{' '}
              </Form.Label>
              <Multiselect
                disable
                className='ps-0'
                displayValue='key'
                placeholder=''
                onKeyPressFn={function noRefCheck() {}}
                onRemove={function noRefCheck() {}}
                onSearch={function noRefCheck() {}}
                onSelect={function noRefCheck() {}}
                selectedValues={usrnameOptions}
              />
            </div>
          </div>

          <div className='row'>
            <div className='col-sm-12 col-md-6 mb-8'>
              <Form.Label className='fs-6 fw-semibold text-primary w-100 text-dark text-start'>
                {' '}
                Select Post On Behalf{' '}
              </Form.Label>
              <Form.Select
                aria-label='Default select example'
                className='ps-0'
                disabled
                value={data?.onbehalfuser_id}
              >
                <option >Select Post On Behalf</option>
                <option value={52}> Shyam Taneja </option>
                <option value={64}> Team ILead </option>
                <option value={140}> Neeru Gupta </option>
                <option value={141}> Sama Taneja </option>
                <option value={259}> Anil Gupta </option>
              </Form.Select>
            </div>

            <div className='col-sm-12 col-md-6 mb-8'>
              <Form.Label className='fs-6 fw-semibold text-primary w-100 text-dark text-start'>
                {' '}
                DataSourse{' '}
              </Form.Label>
              <Form.Control
                disabled
                type='text'
                placeholder='Data Source Url'
                className='ps-0'
                value={data?.datasource}
              />
            </div>
          </div>

          <div className='row'>
            <div className='col-12 mb-8'>
              {['checkbox'].map((type) => (
                <div key={`inline-${type}`} className='mb-3'>
                  <Form.Check
                    inline
                    disabled
                    checked={data?.notification_type?.is_sendsms}
                    label='SMS'
                    name='group1'
                    // type={type}
                    id={`inline-${type}-1`}
                  />
                  <Form.Check
                    inline
                    disabled
                    checked={data?.notification_type?.is_sendemail}
                    label='Email'
                    name='group1'
                    // type={type}
                    id={`inline-${type}-2`}
                  />
                  <Form.Check
                    inline
                    disabled
                    checked={data?.notification_type?.is_sendpush}
                    label='Push'
                    // type={type}
                    id={`inline-${type}-3`}
                  />
                </div>
              ))}
            </div>
          </div>

          <div className='row'>
            <div className='col-md-12 col-lg-10 text-center pe-6'>
              <button
                type='button'
                className='btn btn-info  waves-effect ms-5 waves-light '
                onClick={navigateReminderListing}
              >
                Back
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  )
}
export default ViewReminder
