import {useMemo} from 'react'
import {useTable, ColumnInstance, Row} from 'react-table'
import {CustomHeaderColumn} from './columns/CustomHeaderColumn'
import {CustomRow} from './columns/CustomRow'
import {useQueryResponseData, useQueryResponseLoading} from '../core/QueryResponseProvider'
import {chaptersColumns} from './columns/_columns'
import {Chapter} from '../core/_models'
import {ChaptersListLoading} from '../components/loading/ChaptersListLoading'
import {ChaptersListPagination} from '../components/pagination/ChaptersListPagination'
import {KTCardBody, defaultChapters} from '../../../../../_metronic/helpers'
import {useQueryRequest} from '../core/QueryRequestProvider'
import {CopyChapterTable} from './CopyChapterTable'
// import { KTCardBody, defaultChapters } from '../../../../../_metronic/helpers'
// import {KTCardBody} from '../../../../../../_metronic/helpers'

const ChaptersTable = () => {
  const chapters = useQueryResponseData()
  const isLoading = useQueryResponseLoading()
  const {state, updateState} = useQueryRequest() as any
  const data = useMemo(() => chapters, [chapters])
  const columns = useMemo(() => chaptersColumns, [])
  const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
    columns,
    data,
  })

  return (
    <KTCardBody className='p-0'>
      <CopyChapterTable />
      <div className='table-responsive mb-8 data-table-report'>
        <table
          id='kt_table_chapters'
          className='table align-middle table-row-dashed fs-6 gy-5 dataTable table-striped  no-footer'
          {...getTableProps()}
        >
          <thead>
            <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0 border-bottom border-gray-100'>
              {headers.map((column: ColumnInstance<Chapter>) => (
                <CustomHeaderColumn key={column.id} column={column} />
              ))}
            </tr>
          </thead>

          <thead className='table-sub-hdr'>
            <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
              <th className='p-2'>
                {' '}
                <input
                  type='text'
                  className='form-control mb-3'
                  placeholder=' Name'
                  onChange={(e: any) => {
                    let filter = state?.filter ?? {}
                    filter['name'] = e.target.value
                    updateState({...state, ...{filter: filter}})
                  }}
                  value={state?.filter?.name}
                />{' '}
              </th>

              {/* <th className='p-2'>
                {' '}
                <input
                  type='text'
                  className='form-control mb-3'
                  placeholder=' State'
                  onChange={(e: any) => {
                    let filter = state?.filter ?? {}
                    filter['state'] = e.target.value
                    updateState({...state, ...{filter: filter}})
                  }}
                  value={state?.filter?.state}
                />{' '}
              </th>

              <th className='p-2'>
                {' '}
                <input
                  type='text'
                  className='form-control mb-3'
                  placeholder=' City'
                  onChange={(e: any) => {
                    let filter = state?.filter ?? {}
                    filter['city'] = e.target.value
                    updateState({...state, ...{filter: filter}})
                  }}
                  value={state?.filter?.city}
                />{' '}
              </th> */}

              <th className='p-2'>
                {' '}
                <input
                  type='text'
                  className='form-control mb-3'
                  placeholder=' Created By'
                  onChange={(e: any) => {
                    let filter = state?.filter ?? {}
                    filter['created_by'] = e.target.value
                    updateState({...state, ...{filter: filter}})
                  }}
                  value={state?.filter?.created_by}
                />{' '}
              </th>

              <th className='p-2'> &nbsp; </th>
            </tr>
          </thead>

          <tbody className='text-gray-600 text-left fw-bold' {...getTableBodyProps()}>
            {rows.length > 0 ? (
              rows.map((row: Row<Chapter>, i) => {
                prepareRow(row)
                return <CustomRow row={row} key={`row-${i}-${row.id}`} />
              })
            ) : (
              <tr>
                <td colSpan={7}>
                  <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                    No matching records found
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <ChaptersListPagination />
      {isLoading && <ChaptersListLoading />}
    </KTCardBody>
  )
}

export {ChaptersTable}
