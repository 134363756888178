import React, { useEffect, useState } from "react";
import { ExtingusersListWrapper } from "./exitingusers-list/ExitingusersList";
import AddNewLeader from "./AddNewLeader";
import { RequestusersListWrapper } from "./request-access/requestusers-list/RequestusersList";
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import html2canvas from "html2canvas"
import * as XLSX from 'xlsx'
import { QueryRequestProvider } from "./exitingusers-list/core/QueryRequestProvider";
import { QueryResponseProvider } from "./exitingusers-list/core/QueryResponseProvider";
import { paddingCountAPI } from "./exitingusers-list/core/_requests";

const UsersListing = () => {

    const [addModal,setAddModal] =useState<boolean>(false)
    const [paddingcount,setPaddingCount] =useState<any>("")


    function convertTableToPDF() {
        try{
            const doc = new jsPDF() as any;
    
            // Define the source table
            let table = document.getElementById("copy_kt_table_extingusers") as any
    
            // Make the table briefly visible
            // table.style.display = 'table'
    
            // Convert the table to a data array
            const tableData = [] as any;
            const rows = table.querySelectorAll("tr");
            rows.forEach(function (row:any) {
                const rowData = [] as any;
                const cells = row.querySelectorAll("td, th");
                cells.forEach(function (cell:any) {
                    rowData.push(cell.textContent);
                });
                tableData.push(rowData);
            });
    
            // Set table column widths
            const columnWidths = [20, 20, 25, 40, 35, 20, 20];
    
            // Create the PDF table
            doc.autoTable({
                head: [tableData[0]], // First row as header
                body: tableData.slice(1), // Exclude header row
                theme: "grid",
                margin: { top: 9 },
                startY: 20,
                columnStyles: {
                    0: { columnWidth: columnWidths[0] },
                    1: { columnWidth: columnWidths[1] },
                    2: { columnWidth: columnWidths[2] },
                    3: { columnWidth: columnWidths[3] },
                    4: { columnWidth: columnWidths[4] },
                    5: { columnWidth: columnWidths[5] },
                    6: { columnWidth: columnWidths[6] },
                    7: { columnWidth: columnWidths[7] }
                },
            });
    
            // Save the PDF with a file name
            doc.save("ExistingUser.pdf");
        }
        catch(err){
            // Get a reference to the table
            let table = document.getElementById('copy_kt_table_extingusers') as any
    
            // Hide the table again (if it was originally hidden)
            table.style.display = 'none';
        }
      }
      
      const copyTable = () => {
        try{
            // Get a reference to the table
            let table = document.getElementById('copy_kt_table_extingusers') as any
    
            // Make the table briefly visible
            table.style.display = 'table'
    
            // Create a range object to select the table content
            const range = document.createRange();
            range.selectNode(table);
    
            // Create a selection object and add the range
            const selection = window.getSelection() as any
            selection.removeAllRanges();
            selection.addRange(range);
    
            // Execute the copy command
            document.execCommand('copy');
    
            // Deselect the content
            selection.removeAllRanges();
    
            // Hide the table again (if it was originally hidden)
            table.style.display = 'none';
    
            // Optionally, provide feedback to the user
            alert('Table data copied to clipboard');
        }
        catch(err){
            // Get a reference to the table
            let table = document.getElementById('copy_kt_table_extingusers') as any
    
            // Hide the table again (if it was originally hidden)
            table.style.display = 'none';
        }
      }
      
      function ExportToExcel() {
        try{
            // Acquire Data (reference to the HTML table)
            let table = document.getElementById("copy_kt_table_extingusers") as any
    
            // Make the table briefly visible
            table.style.display = 'table'
    
            // Extract Data (create a workbook object from the table)
            var workbook = XLSX.utils.table_to_book(table);
    
            // Hide the table again (if it was originally hidden)
            table.style.display = 'none';
    
            // Process Data (add a new row)
            var ws = workbook.Sheets["Sheet1"];
            // XLSX.utils.sheet_add_aoa(ws, [["Created "+new Date().toISOString()]], {origin:-1});
    
            // Package and Release Data (`writeFile` tries to write and save an XLSB file)
            XLSX.writeFile(workbook, "ExistingUser.xlsb");
        }
        catch(err){
            // Get a reference to the table
            let table = document.getElementById('copy_kt_table_extingusers') as any
    
            // Hide the table again (if it was originally hidden)
            table.style.display = 'none';
        }
    }
    
    function convertTableToCSV() {
        try{
            let table = document.getElementById("copy_kt_table_extingusers") as any
    
            // Make the table briefly visible
            table.style.display = 'table'
    
            const rows = table.querySelectorAll("tr") as any;
            let csv = [];
    
            for (const row of rows) {
                const cells = row.querySelectorAll("td, th") as any
                const rowData = Array.from(cells).map((cell:any) => cell.textContent);
                csv.push(rowData.join(","));
            }
    
            // Hide the table again (if it was originally hidden)
            table.style.display = 'none';
    
            const csvContent = "data:text/csv;charset=utf-8," + csv.join("\n");
            const encodedURI = encodeURI(csvContent);
            const link = document.createElement("a");
            link.setAttribute("href", encodedURI);
            link.setAttribute("download", "ExistingUser.csv");
            document.body.appendChild(link);
            link.click();
        }
        catch(err){
            // Get a reference to the table
            let table = document.getElementById('copy_kt_table_extingusers') as any
    
            // Hide the table again (if it was originally hidden)
            table.style.display = 'none';
        }
      }
      const convertTableToPrint = () => {
        try{
            // Define the source table
            let table = document.getElementById("copy_kt_table_extingusers") as any
    
            // Make the table briefly visible
            table.style.display = 'table'
    
            html2canvas(document.querySelector('#copy_kt_table_extingusers') as any).then(function (canvas:any) {
                let imgWidth = 210
                let pageHeight = 290
                let imgHeight = (canvas.height * imgWidth) / canvas.width
                let heightLeft = imgHeight
            
                let doc = new jsPDF('p', 'mm')
                let position = 0
                let pageData = canvas.toDataURL('image/jpeg', 1.0)
                let imgData = encodeURIComponent(pageData)
                doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight)
                doc.setLineWidth(5)
                doc.setDrawColor(255, 255, 255)
                doc.rect(0, 0, 210, 295)
                heightLeft -= pageHeight
            
                while (heightLeft >= 0) {
                    position = heightLeft - imgHeight
                    doc.addPage()
                    doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight)
                    doc.setLineWidth(5)
                    doc.setDrawColor(255, 255, 255)
                    doc.rect(0, 0, 210, 295)
                    heightLeft -= pageHeight
                }
                doc.autoPrint();
                //This is a key for printing
                doc.output('dataurlnewwindow');
            })
    
            // Hide the table again (if it was originally hidden)
            table.style.display = 'none';
        }
        catch(err){
            // Get a reference to the table
            let table = document.getElementById('copy_kt_table_extingusers') as any
    
            // Hide the table again (if it was originally hidden)
            table.style.display = 'none';
        }
      }



      function convertTableToPDFRequestUser() {
        try{
            const doc = new jsPDF() as any;
    
            // Define the source table
            let table = document.getElementById("copy_kt_table_requestusers") as any
    
            // Make the table briefly visible
            // table.style.display = 'table'
    
            // Convert the table to a data array
            const tableData = [] as any;
            const rows = table.querySelectorAll("tr");
            rows.forEach(function (row:any) {
                const rowData = [] as any;
                const cells = row.querySelectorAll("td, th");
                cells.forEach(function (cell:any) {
                    rowData.push(cell.textContent);
                });
                tableData.push(rowData);
            });
    
            // Set table column widths
            const columnWidths = [20, 20, 25, 40, 35, 20, 20];
    
            // Create the PDF table
            doc.autoTable({
                head: [tableData[0]], // First row as header
                body: tableData.slice(1), // Exclude header row
                theme: "grid",
                margin: { top: 9 },
                startY: 20,
                columnStyles: {
                    0: { columnWidth: columnWidths[0] },
                    1: { columnWidth: columnWidths[1] },
                    2: { columnWidth: columnWidths[2] },
                    3: { columnWidth: columnWidths[3] },
                    4: { columnWidth: columnWidths[4] },
                    5: { columnWidth: columnWidths[5] },
                    6: { columnWidth: columnWidths[6] },
                    7: { columnWidth: columnWidths[7] }
                },
            });
    
            // Save the PDF with a file name
            doc.save("RequestUsers.pdf");
        }
        catch(err){
            // Get a reference to the table
            let table = document.getElementById('copy_kt_table_requestusers') as any
    
            // Hide the table again (if it was originally hidden)
            table.style.display = 'none';
        }
      }
      
      const copyTableRequestUser = () => {
        try{
            // Get a reference to the table
            let table = document.getElementById('copy_kt_table_requestusers') as any
    
            // Make the table briefly visible
            table.style.display = 'table'
    
            // Create a range object to select the table content
            const range = document.createRange();
            range.selectNode(table);
    
            // Create a selection object and add the range
            const selection = window.getSelection() as any
            selection.removeAllRanges();
            selection.addRange(range);
    
            // Execute the copy command
            document.execCommand('copy');
    
            // Deselect the content
            selection.removeAllRanges();
    
            // Hide the table again (if it was originally hidden)
            table.style.display = 'none';
    
            // Optionally, provide feedback to the user
            alert('Table data copied to clipboard');
        }
        catch(err){
            // Get a reference to the table
            let table = document.getElementById('copy_kt_table_requestusers') as any
    
            // Hide the table again (if it was originally hidden)
            table.style.display = 'none';
        }
      }
      
      function ExportToExcelRequestUser() {
        try{
            // Acquire Data (reference to the HTML table)
            let table = document.getElementById("copy_kt_table_requestusers") as any
    
            // Make the table briefly visible
            table.style.display = 'table'
    
            // Extract Data (create a workbook object from the table)
            var workbook = XLSX.utils.table_to_book(table);
    
            // Hide the table again (if it was originally hidden)
            table.style.display = 'none';
    
            // Process Data (add a new row)
            var ws = workbook.Sheets["Sheet1"];
            // XLSX.utils.sheet_add_aoa(ws, [["Created "+new Date().toISOString()]], {origin:-1});
    
            // Package and Release Data (`writeFile` tries to write and save an XLSB file)
            XLSX.writeFile(workbook, "RequestUsers.xlsb");
        }
        catch(err){
            // Get a reference to the table
            let table = document.getElementById('copy_kt_table_requestusers') as any
    
            // Hide the table again (if it was originally hidden)
            table.style.display = 'none';
        }
    }
    
    function convertTableToCSVRequestUser() {
        try{
            let table = document.getElementById("copy_kt_table_requestusers") as any
    
            // Make the table briefly visible
            table.style.display = 'table'
    
            const rows = table.querySelectorAll("tr") as any;
            let csv = [];
    
            for (const row of rows) {
                const cells = row.querySelectorAll("td, th") as any
                const rowData = Array.from(cells).map((cell:any) => cell.textContent);
                csv.push(rowData.join(","));
            }
    
            // Hide the table again (if it was originally hidden)
            table.style.display = 'none';
    
            const csvContent = "data:text/csv;charset=utf-8," + csv.join("\n");
            const encodedURI = encodeURI(csvContent);
            const link = document.createElement("a");
            link.setAttribute("href", encodedURI);
            link.setAttribute("download", "RequestUsers.csv");
            document.body.appendChild(link);
            link.click();
        }
        catch(err){
            // Get a reference to the table
            let table = document.getElementById('copy_kt_table_requestusers') as any
    
            // Hide the table again (if it was originally hidden)
            table.style.display = 'none';
        }
      }
      const convertTableToPrintRequestUser = () => {
        try{
            // Define the source table
            let table = document.getElementById("copy_kt_table_requestusers") as any
    
            // Make the table briefly visible
            table.style.display = 'table'
    
            html2canvas(document.querySelector('#copy_kt_table_requestusers') as any).then(function (canvas:any) {
                let imgWidth = 210
                let pageHeight = 290
                let imgHeight = (canvas.height * imgWidth) / canvas.width
                let heightLeft = imgHeight
            
                let doc = new jsPDF('p', 'mm')
                let position = 0
                let pageData = canvas.toDataURL('image/jpeg', 1.0)
                let imgData = encodeURIComponent(pageData)
                doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight)
                doc.setLineWidth(5)
                doc.setDrawColor(255, 255, 255)
                doc.rect(0, 0, 210, 295)
                heightLeft -= pageHeight
            
                while (heightLeft >= 0) {
                    position = heightLeft - imgHeight
                    doc.addPage()
                    doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight)
                    doc.setLineWidth(5)
                    doc.setDrawColor(255, 255, 255)
                    doc.rect(0, 0, 210, 295)
                    heightLeft -= pageHeight
                }
                doc.autoPrint();
                //This is a key for printing
                doc.output('dataurlnewwindow');
            })
    
            // Hide the table again (if it was originally hidden)
            table.style.display = 'none';
        }
        catch(err){
            // Get a reference to the table
            let table = document.getElementById('copy_kt_table_requestusers') as any
    
            // Hide the table again (if it was originally hidden)
            table.style.display = 'none';
        }
      }


      const getPaddingcount= async ()=>{
        try {
          const res = await paddingCountAPI()
          setPaddingCount(res?.data?.paddingCount)
          } catch (error) {
            console.log("error",error)
          }
      } 


      useEffect(()=>{
        getPaddingcount()
      },[])

    return (


        <>

            <div className="row flex-md-block "> 
                <div className='col-sm-6 col-md-6 col-lg-6'><h1 className='inv-hst sm-ms-2'> User Management </h1></div>

                <div className='col-sm-6  col-md-6 col-lg-6 mb-5 text-end'>
                    <button data-bs-toggle="modal" data-bs-target="#addnewleader" onClick={()=>{setAddModal(true)}} className="btn btn-primary btn-sm waves-effect waves-light "> Add New Leader </button></div>
            </div>


            <div className="row align-items-center mt-5 mx-0">
                <div className='col-sm-10 ps-3 ps-md-0 viw-feedback mob-view-pl-pr '>
                    <ul className='nav nav-pills mb-3' id='pills-tab' role='tablist'>
                        <li className='nav-item me-0' role='presentation'>
                            <button
                                className='nav-link  me-0'
                                id='pills-home-tab'
                                data-bs-toggle='pill'
                                data-bs-target='#pills-home'
                                type='button'
                                role='tab'
                                aria-controls='pills-home'
                                aria-selected='false'
                            >
                                Request Access
                            </button>
                        </li>
                        <li className='nav-item' role='presentation'>
                            <button
                                className='nav-link active'
                                id='pills-profile-tab'
                                data-bs-toggle='pill'
                                data-bs-target='#pills-profile'
                                type='button'
                                role='tab'
                                aria-controls='pills-profile'
                                aria-selected='true'
                            >
                                Existing Leaders
                            </button>
                        </li>
                    </ul>
                </div>

                <div className='col-sm-2 d-flex justify-content-end'>
                    <div>
                        <div className="bg-primary req-acs-cont-bdr text-white text-center mx-auto px-4 py-0 fw-bold fs-4 d-flex justify-content-center align-items-center"> {paddingcount}</div>
                        <p className="text-center fs-6 text-dark"> Pending Request </p>
                    </div>
                </div>
            </div>

            <div className='mx-3 mx-md-0 pt-5'>
                <div className='tab-content feedback-detail' id='pills-tabContent'>
                    <div
                        className='tab-pane fade  '
                        id='pills-home'
                        role='tabpanel'
                        aria-labelledby='pills-home-tab'
                        tabIndex={0}
                    >
                        <div className="d-flex justify-content-start mb-3 mt-6 d-sm-block d-none">
                            <button type="button" className="btn px-3 px-sm-5 py-2 me-1 me-sm-3 btn-primary" onClick={()=>{copyTableRequestUser()}} >Copy</button>
                            <button type="button" className="btn px-3 px-sm-5 py-2 me-1 me-sm-3 btn-success" onClick={()=>{ExportToExcelRequestUser()}}>Excel</button>
                            <button type="button" className="btn px-3 px-sm-5 py-2 me-1 me-sm-3 btn-warning" onClick={()=>{convertTableToCSVRequestUser()}}>CSV</button>
                            <button type="button" className="btn px-3 px-sm-5 py-2 me-1 me-sm-3 btn-danger" onClick={()=>{convertTableToPDFRequestUser()}} >PDF</button>
                            <button type="button" className="btn px-3 px-sm-5 py-2 me-1 me-sm-3 btn-info" onClick={()=>{convertTableToPrintRequestUser()}} >Print</button>
                        </div>

                        {/* <div className="d-flex justify-content-start mb-3 mt-6 fs-5 d-sm-block d-none">
                            Show <span className="mx-2"><select className=" mb-3 p-1 ms-2 text-dark border border-gray-600 rounded-1 fs-6" aria-label=".form-select-lg example">
                                <option selected>10</option>
                                <option value="1">25</option>
                                <option value="2">50</option>
                                <option value="2">100</option>
                            </select> </span> entries

                        </div> */}
                        
                        <RequestusersListWrapper />
                    </div>

                    <div
                        className='tab-pane fade show active'
                        id='pills-profile'
                        role='tabpanel'
                        aria-labelledby='pills-profile-tab'
                        tabIndex={0}
                    >
                        <div className="d-flex justify-content-start mb-3 mt-6 d-sm-block d-none">
                        <button type="button" className="btn px-3 px-sm-5 py-2 me-1 me-sm-3 btn-primary" onClick={()=>{copyTable()}}>Copy</button>
                            <button type="button" className="btn px-3 px-sm-5 py-2 me-1 me-sm-3 btn-success" onClick={()=>{ExportToExcel()}}>Excel</button>
                            <button type="button" className="btn px-3 px-sm-5 py-2 me-1 me-sm-3 btn-warning"onClick={()=>{convertTableToCSV()}}>CSV</button>
                            <button type="button" className="btn px-3 px-sm-5 py-2 me-1 me-sm-3 btn-danger" onClick={()=>{convertTableToPDF()}}>PDF</button>
                            <button type="button" className="btn px-3 px-sm-5 py-2 me-1 me-sm-3 btn-primary" onClick={()=>{convertTableToPrint()}}>Print</button>
                        </div>

                        {/* <div className="d-flex justify-content-start mb-3 mt-6 fs-5 d-sm-block d-none">
                            Show <span className="mx-2"><select className=" mb-3 p-1 ms-2 text-dark border border-gray-600 rounded-1 fs-6" aria-label=".form-select-lg example">
                                <option selected>10</option>
                                <option value="1">25</option>
                                <option value="2">50</option>
                                <option value="2">100</option>
                            </select> </span> entries

                        </div> */}
                        <QueryRequestProvider>
                          <QueryResponseProvider>
                            <ExtingusersListWrapper />
                            <AddNewLeader  show={addModal} onHide={()=>{setAddModal(false)}} />
                          </QueryResponseProvider>
                        </QueryRequestProvider>

                    </div>
                </div>

            </div>

        </>

    )

}

export default UsersListing