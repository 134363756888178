/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import {useQueryResponseLoading, useQueryResponsePagination} from '../../core/QueryResponseProvider'
import {useQueryRequest} from '../../core/QueryRequestProvider'
import TablePagination from '@mui/material/TablePagination'
import Stack from '@mui/material/Stack'
import Pagination from '@mui/material/Pagination'

const mappedLabel = (label: string): string => {
  if (label === '&laquo; Previous') {
    return 'Previous'
  }

  if (label === 'Next &raquo;') {
    return 'Next'
  }

  return label
}

const ChaptersListPagination = () => {
  const pagination = useQueryResponsePagination()
  const isLoading = useQueryResponseLoading()
  const {updateState} = useQueryRequest()
  const updatePage = (page: number | null) => {
    if (!page || isLoading || pagination.page === page) {
      return
    }

    updateState({page, items_per_page: pagination?.perPage || 10})
  }

  return (
    <div className='row'>
      {/* <div className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start'> Showing 1 to 10 of 3,371 entries </div> */}
      <div className='col-md-12 d-flex flex-column flex-md-row flex-md-row-reverse justify-content-between custom-agp-pagination align-items-center'>
        {pagination?.totalRecords ? (
          <>
            <Stack spacing={2}>
              <Pagination
                count={Math.ceil(pagination?.totalRecords / pagination?.perPage)}
                defaultPage={1}
                page={pagination?.page}
                showFirstButton
                showLastButton
                onChange={(e: any, p: any) => {
                  updateState({
                    page: p,
                    // , perPage: pagination.perPage || 10
                  })
                }}
              />
            </Stack>
            <TablePagination
              className='cust-pagination mb-0 pt-0'
              component='p'
              count={pagination.totalRecords}
              page={pagination.page - 1}
              onPageChange={(e: any) => {
                updateState({page: pagination?.page, items_per_page: e.target.value})
              }}
              rowsPerPage={pagination.perPage}
              // onRowsPerPageChange={(e: any) => {
              //   updateState({page: pagination.page, perPage: e.target.value})
              // }}
              onRowsPerPageChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                const newPerPage = parseInt(event.target.value, 10)
                const totalPages = Math.ceil(pagination?.totalRecords / newPerPage)
                let newPage = pagination?.page

                // Check if the current page exceeds the new total number of pages
                if (newPage > totalPages) {
                  newPage = totalPages
                }

                updateState({page: newPage, items_per_page: newPerPage as 10 | 30 | 50 | 100})
              }}
              nextIconButtonProps={{style: {display: 'none'}}}
              backIconButtonProps={{style: {display: 'none'}}}
            />
          </>
        ) : (
          ''
        )}
      </div>
    </div>
  )
}

export {ChaptersListPagination}
