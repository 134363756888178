/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useLayoutEffect, useRef, useState } from 'react'
import { BiGi } from './components/BiGi'
// import {Filter} from './components/Filter'
import { PostListing } from './components/PostListing'
import { WritePostModal } from './components/create-post/WritePostModal'
import { Filter } from './components/filter'
import { CometChatUI } from '../../../cometchat-chat-uikit-react/CometChatWorkspace/src'
import { allFilterData, fixFilterData } from '../../../utils/postFilter'
import { ICANIWILLModal } from './components/icaniwill-modal/ICANIWILLModal'
import { getCheckICANIWILL } from './components/_requests'
// import { Filter } from './components/filter'
// import { Filter } from './components/Filter'
import { getICIWMessage } from './components/_requests'
import { SubmitMRTracking } from './components/create-post/modal/SubmitMRTracking'
import { Carousel, Image } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { toAbsoluteUrl } from '../../../_metronic/helpers'
import { PinnedPost } from './components/post-type/PinnedPost'
import { BirthDayPostRightSide } from './components/post-type/BirthDayPostRightSide'
// import { BirthDayPostRightSide } from './components/post-type/BirthDayPostRightSide'

const DashboardPage: FC = () => {
  const [filterList1, setFilterList1] = useState<any>(fixFilterData)
  const [filterList2, setFilterList2] = useState<any>(allFilterData)
  const [filterId, setFilterId] = useState<any>('all')
  const [data, setData] = useState<any>({})
  const [Loading, setIsLoading] = useState<any>(true)
  const [showModal, setShowModal] = useState(false)
  const [chatLoading, setChatLoading] = useState(true)
  const [isBirthaveData, setIsBirthaveData] = useState(true)
  // let isBirthaveData=false
  useEffect(() => {
    setTimeout(() => {
      setChatLoading(false)
    }, 2500)
    fetchData()
    // setShowModal(false);
  }, [])

  useEffect(() => {
    setShowModal(data.result === false)
  }, [data.result])

  const fetchData = async () => {
    try {
      const ICanIWill = await getCheckICANIWILL()
      console.log("ICanIWillICanIWill", ICanIWill)
      setData(ICanIWill ?? [])
      setIsLoading(false)
    } catch (error) {
      console.error('Error -->:', error)
      setIsLoading(false)
    }
  }

  const [chatRightSideBar, setChatRightSideBar] = useState(false)
  const [message, setMessage] = useState<any>({})
  // useState for a Tusday Auto Open MR model
  const [ShowMrModel, setShowMrModel] = useState<boolean>(true)

  const fetchRecice = async () => {
    try {
      const res = await getICIWMessage()
      setMessage(res ?? {})
      // setIsLoading(false)
    } catch (error) {
      console.error('Error -->:', error)
      // setIsLoading(false)
    }
  }
  const handleOutsideClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (event.target === event.currentTarget) {
      setChatRightSideBar(false)
    }
  }

  const removeNotify = () => {
    // if (document.getElementById('comet_chat_unread_count')) {
    //   document?.getElementById('comet_chat_unread_count')?.className = ""
    // }
    // if (document.getElementById('comet_chat_unread_count_tabbar')) {
    //   document?.getElementById('comet_chat_unread_count_tabbar')?.className = ""
    // }
  }

  const fetchCometChatAgain = () => {
    setChatLoading(true)
    setTimeout(() => {
      setChatLoading(false)
    }, 650)
  }

  useEffect(() => {
    fetchRecice()
  }, [])

  const callNotification = () => {
    alert("callNotification")
    try{
      Notification.requestPermission().then(permission => {
        if (permission === 'granted') {
          alert("permission === 'granted' if me")
          new Notification('Hello World 0')
          setTimeout(()=>{
            new Notification('Hello World 1000')
          },1000)
          setTimeout(()=>{
            new Notification('Hello World 5000')
          },5000)
          setTimeout(()=>{
            new Notification('Hello World 10000')
          },10000)
          setTimeout(()=>{
            new Notification('Hello World 12000')
          },12000)
          // if ('PushManager' in window) {
          //   navigator.serviceWorker.ready
          //     .then(registration => {
          //       return registration.pushManager.subscribe({
          //         userVisibleOnly: true,
          //         // applicationServerKey: yourVAPIDKey, // Replace with your own VAPID key
          //       });
          //     })
          //     .then(subscription => {
          //       // Send the subscription to your server
          //     })
          //     .catch(error => {
          //       console.error('Error subscribing:', error);
          //     });
          // }
        }
        else{
          alert("permission === 'granted' else me")
          alert(Notification.permission)
          Notification.requestPermission().then(permission => {
            if (permission === 'granted') {
              new Notification("Title No 121");
            }
          });
        }
      }).catch((err)=>alert(JSON.stringify(err)))
    }
    catch(error){
      alert(JSON.stringify(error))
      alert("Notification alert in catch")
    }
  }

  const showNotification = () => {
    alert("Show Notification")
    try{
      new Notification("Show Notification Me 0");
      setTimeout(()=>{
        new Notification("Show Notification Me 2000");
      },2000)
      setTimeout(()=>{
        new Notification("Show Notification Me 5000");
      },5000)
    }
    catch(error){
      alert(JSON.stringify(error))
      alert("Error While Show Notification")
    }
  }

  return (
    <>
      <ICANIWILLModal showModal={showModal} data={data} />

      {message?.isMRDayValue && <SubmitMRTracking show={ShowMrModel} setShow={setShowMrModel} />}

      <div className='row flex-row flex-row-reverse mobile-noMargin justify-content-center overflow-hidden'>
        <div className='col-xl-6 right-wrapper mb-1' style={{ maxWidth: '600px' }}>
          <div className='fixed-div d-flex d-md-block flex-column flex-md-row flex-column-reverse'>
            {/*  */}
            <div className='row mb-5' style={{ width: 'inherit' }}>
              <div className={`col-md-${isBirthaveData ? '6' : "12"} post-listing`}>
                <h3 className='px-4 px-md-0'>Announcement 
                  {/* <button onClick={()=>callNotification()}>Call Notification</button> */}
                  {/* <button onClick={()=>showNotification()}>Show Notification</button> */}
                 </h3>
                <PinnedPost></PinnedPost>
              </div >
              {
                isBirthaveData && (
                  <div className={`col-md-6 post-listing ps-md-0 mt-5 mt-md-0`}>
                    <h3 className='px-4 px-md-0'>Birthday</h3>
                    <BirthDayPostRightSide setIsBirthaveData={setIsBirthaveData}></BirthDayPostRightSide>
                  </div>
                )
              }

            </div >
            <BiGi></BiGi>
            {/* Desktop Chat */}
            <div
              className='d-none d-md-flex align-items-center justify-content-center bg-primary cursor-pointer chat-button symbol-circle symbol symbol-45'
              onClick={() => {
                setChatRightSideBar(true)
                // if(document.getElementById('comet_chat_unread_count')){
                //   document?.getElementById('comet_chat_unread_count')?.className = ""
                // }
                // if(document.getElementById('comet_chat_unread_count_tabbar')){
                //   document?.getElementById('comet_chat_unread_count_tabbar')?.className = ""
                // }
              }}
              style={{ bottom: '50px', right: '50px', width: '56px', height: '56px' }}
            >
              <i className="bi bi-chat-dots-fill text-white" style={{ fontSize: '24px' }}>
              </i>
              <span id="comet_chat_unread_count"></span>
              {/* <span className="position-absolute top-0 start-100 translate-middle p-2 bg-success border border-light rounded-circle blink" id="comet_chat_unread_count"> */}
              {/* <span className="visually-hidden">New alerts</span> */}
              {/* </span> */}
            </div>
            < div className='chat-view d-none d-md-block chat-sidebar' onClick={handleOutsideClick} style={{ width: 'inherit' }}>
              <div className={`chatSidebar-main ${chatRightSideBar == (true) ? "active" : ""}`}  onClick={handleOutsideClick}>
                <div className='chatSidebar bg-white h-100'>
                  <div
                    className='close-btn symbol symbol-30 position-absolute cursor-pointer'
                    style={{ left: '-50px' }}
                    onClick={() => setChatRightSideBar(false)}
                  >
                    <div className='symbol-label bg-dark' style={{ borderRadius: '0.475rem 0 0 0.475rem' }}>
                      <i className="las la-times text-white"></i>
                    </div>
                  </div>
                  {!chatLoading ? <CometChatUI fetchCometChatAgain={fetchCometChatAgain} /> : ''}
                </div>
              </div>
            </div >
          </div >
        </div >
        <div className='col-xl-6 dashboard-wrapper' style={{ maxWidth: '600px' }}>
          <Filter
            filterList1={filterList1}
            setFilterList1={setFilterList1}
            filterList2={filterList2}
            setFilterList2={setFilterList2}
            filterId={filterId}
            setFilterId={setFilterId}
          ></Filter>
          <PostListing filterId={filterId}></PostListing>
          <WritePostModal></WritePostModal>
        </div>
      </div >
    </>
  )
}

const DashboardWrapper: FC = () => {
  return <DashboardPage />
}

export { DashboardWrapper }
