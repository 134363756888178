// @ts-nocheck
import {Column} from 'react-table'
import {VerticalActionsCell} from './VerticalActionsCell'
import {VerticalCustomHeader} from './VerticalCustomHeader'
import { checkDisplayValue } from '../../../../../../utils'

const verticalsColumns: ReadonlyArray<Column<any>> = [

  {
    Header: (props) => <VerticalCustomHeader tableProps={props} title='Name' className='text-start min-w-125px fs-5' />,
    accessor: 'name',
    // id: 'name',
    Cell: ({value}) => checkDisplayValue(value),
  },

  {
    Header: (props) => <VerticalCustomHeader tableProps={props} title='Created By' className='text-start min-w-125px fs-5' />,
    accessor: 'createdByName',
    // id: 'name',
    Cell: ({value}) => checkDisplayValue(value),
  },

  {
    Header: (props) => (
      <VerticalCustomHeader tableProps={props} title='Actions' className='text-start min-w-150px' />
    ),
    id: 'actions',
    Cell: ({...props}) => <VerticalActionsCell id={props.data[props.row.index].id} />,
  },
  
]

export {verticalsColumns}
