import {ListViewProvider, useListView} from './core/ListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
 import { RequestusersTable } from './table/RequestusersTable'
import { RequestuserEditModal } from './requestuser-edit-modal/RequestuserEditModal'
import { KTCard } from '../../../../../_metronic/helpers'
// import {KTCard} from '../../../../../_metronic/helpers'

const RequestusersList = () => {
  const {itemIdForUpdate} = useListView()
  return (
    <>
      
        {/* <requestusersListHeader /> */}
        <RequestusersTable />
      
      {itemIdForUpdate !== undefined && <RequestuserEditModal />}
    </>
  )
}

const RequestusersListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <RequestusersList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {RequestusersListWrapper}
