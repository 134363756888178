import React, { useEffect, useState } from 'react'
import { toAbsoluteUrl } from '../../../_metronic/helpers'
import { Link } from 'react-router-dom'
import { apiUrl } from '../../../apiurl'
import { checkDisplayValue } from '../../../utils'
import axios from 'axios'

const API_URL = apiUrl
const ICIWF_URL = `${API_URL}/users`
const secretariat_URL = `${API_URL}/ileadleaderships`
const leadercoordinators_URL = `${API_URL}/users/leadercoordinators`
const leaderdirectors_URL = `${API_URL}/leaderdirectors`

const ICIWFUUPL = () => {
  const [data, setData] = useState<any>([])
  const [leaderdirectorsdata, setLeaderDirectorsData] = useState<any>([])
  const [leadercoordinatorsdata, setLeaderCoordinatorsData] = useState<any>([])
  const [secretariatdata, setSecretariatData] = useState<any>([])
  const [isLoading, setIsLoading] = useState<any>(true)
  const [leaderId, setLeaderID] = useState<any>('')

  let ajitTaneja: any = {
    name: 'AJIT TANEJA',
    description: "An entrepreneur with values in mind. She owns a brand called “myfrend” for soft toys that she manufactured & marketed. Has been passionate to help children of Blind People Association by training them to make soft toys and earn livelihood",
    profile: 'Mrs_Ajit_Taneja.jpg'
  }

  useEffect(() => {
    fetchData()
    fetchDataSecretariat()
    fetchDataLeaderCoordinators()
    fetchDataLeaderDirectors()
  }, [])

  const fetchData = async () => {
    try {
      const res = await axios.get(
        `${ICIWF_URL}?filter[where][isdelete]=0&filter[where][isactive]=1&filter[where][ileadleadership][neq]=null&filter[order]=ileadleadership ASC`
      )
      let dataa = [...res?.data, ajitTaneja] as any
      setData(dataa ?? [])
      setIsLoading(false)
    } catch (error) {
      console.error('Error -->:', error)
      setIsLoading(false)
    }
  }

  console.log('AJIT taneja', data)

  const fetchDataSecretariat = async () => {
    try {
      const res = await axios.get(`${secretariat_URL}?filter[where][usertitle]=ILEAD Secretariat`)
      setSecretariatData(res?.data ?? [])
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
    }
  }

  const fetchDataLeaderCoordinators = async () => {
    try {
      const res = await axios.get(`${leadercoordinators_URL}?chapter_id=6&filter[include]=user`)
      setLeaderCoordinatorsData(res?.data ?? [])
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
    }
  }

  const fetchDataLeaderDirectors = async () => {
    try {
      const res = await axios.get(
        `${leaderdirectors_URL}?filter[where][isdelete]=0&filter[include]=user`
      )
      setLeaderDirectorsData(res?.data ?? [])
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
    }
  }

  const handleLeaderClick = (id: any) => {
    setLeaderID(id)
  }

  const getFormattedPhoneNumber = (mobileNo: any) => {
    return `+91${mobileNo}`
  }

  const styles = {
    borderRadius: '0.475rem',
    boxShadow: '0 0 50px 0 rgb(82 63 105 / 15%)',
    backgroundColor: '#fff',
    color: '#7e8299',
    fontWeight: '500',
    margin: '0',
    width: 'auto',
    padding: '1rem 2rem',
    top: 'calc(50% - 2rem)',
    left: 'calc(50% - 4rem)',
  }

  if (isLoading) {
    return <div style={{ ...styles, position: 'absolute', textAlign: 'center' }}>Processing...</div>
  }

  return (
    <>
      <h1 className='inv-hst mb-3 mb-md-0 d-md-none'>ICIWF/UUPL</h1>
      {/* ILEAD Trustees */}
      <div className='row mobile-padding mb-7'>
        <div className='col-12'>
          <h1 className='mb-7'>ILEAD Trustees</h1>
        </div>
        <div className='col-12'>
          {data?.map((item: any) => (
            <div className='card ilead__card mb-5' key={item?.id} onClick={() => handleLeaderClick(item?.id)}>
              <div className='card-body'>
                <div className='d-flex flex-column flex-md-row align-items-center align-items-md-start'>
                  <Link to={`/leaders/${item?.id}`} className='symbol symbol-160px symbol-lg-200 symbol-fixed me-0 me-md-7'>
                    <img
                      src={
                        item?.profile
                          ? `${API_URL}/containers/userprofile/download/${item?.profile}`
                          : `${toAbsoluteUrl('./media/avatars/default-user.png')}`
                      }
                      alt=''
                      className='object-fit-cover'
                      onError={({ currentTarget }: any) => {
                        currentTarget.onerror = null // prevents looping
                        currentTarget.src = `${toAbsoluteUrl('./media/avatars/default-user.png')}`
                      }}
                    />
                  </Link>
                  <div className='leader__details pt-3 w-100'>
                    <div className='details__heading pb-5 border-bottom border-secondary w-100 mb-5'>
                      <Link to={`/leaders/${item?.id}`}>
                        <h3 className='fs-2 fw-bold mb-2 text-center text-md-start'>{item?.name ? checkDisplayValue(item?.name) : ''}</h3>
                        <h5 className='text-primary fw-semibold text-center text-md-start'>{item?.qualification ? checkDisplayValue(item?.qualification) : ''}</h5>
                      </Link>
                    </div>
                    <p className='text-dark fs-6' style={{ fontWeight: '400', lineHeight: '28px' }} dangerouslySetInnerHTML={{ __html: item?.description }}></p>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      {/* ILEAD Directors */}
      <div className='row mobile-padding'>
        <div className='col-12'>
          <h1 className='mb-7'>ILEAD Directors</h1>
        </div>
      </div>
      <div className='row mobile-padding mb-7'>
        {leaderdirectorsdata?.map((item: any) => (
          <div className='col-md-3 col-lg-3 col-xl-3 mb-5'>
            <div className='card ilead__card overflow-hidden main__leaders'>
              <div className='card-body p-0'>
                <div className='p-5 border-bottom mobile-border-none border-secondary d-flex d-md-block'>
                  <Link to={`/leaders/${item?.user?.id}`} className='symbol symbol-100 bg-white border-dark mb-4 me-4 me-md-0'>
                    <img
                      src={
                        item?.user?.profile
                          ? `${API_URL}/containers/userprofile/download/${item?.user?.profile}`
                          : `${toAbsoluteUrl('./media/avatars/default-user.png')}`
                      }
                      alt=''
                      className='w-100'
                      onError={({ currentTarget }: any) => {
                        currentTarget.onerror = null // prevents looping
                        currentTarget.src = `${toAbsoluteUrl('./media/avatars/default-user.png')}`
                      }}
                    />
                  </Link>
                  <div className='leader__details text-truncate'>
                    <Link to={`/leaders/${item?.user?.id}`}>
                      <h2 className='fs-6 text-dark leader__name text-md-center text-truncate'>
                        {checkDisplayValue(item?.user?.name)}
                      </h2>
                      <div className='leader__chapter mb-5 mb-md-0'>
                        <span className='chapter__name text-primary d-block text-md-center text-truncate'>
                          {checkDisplayValue(item?.title)}
                        </span>
                      </div>
                    </Link>
                    <div className='leader__contact justify-content-md-center d-flex d-md-none'>
                      <a href={`mailto:${item?.user?.email}`} className="symbol symbol-40px leader__email me-2">
                        <div className="symbol-label fs-2 fw-semibold text-primary">
                          <img src='/media/ilead/mail.svg' alt='' />
                        </div>
                      </a>
                      <a href={`tel:${getFormattedPhoneNumber(item?.user?.mobileno)}`} className="symbol symbol-40px leader__mobile me-2">
                        <div className="symbol-label fs-2 fw-semibold text-success">
                          <img src='/media/ilead/phone-call.svg' alt='' />
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
                <div className='p-5 d-none d-md-block'>
                  <div className='leader__contact justify-content-center d-flex'>
                    <a href={`mailto:${item?.user?.email}`} className="symbol symbol-40px leader__email me-2">
                      <div className="symbol-label fs-2 fw-semibold text-primary">
                        <img src='/media/ilead/mail.svg' alt='' />
                      </div>
                    </a>
                    <a href={`tel:${getFormattedPhoneNumber(item?.user?.mobileno)}`} className="symbol symbol-40px leader__mobile me-2">
                      <div className="symbol-label fs-2 fw-semibold text-success">
                        <img src='/media/ilead/phone-call.svg' alt='' />
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      {/* ILEAD Coordinators */}
      <div className='row mobile-padding'>
        <div className='col-12'>
          <h1 className='mb-7'>ILEAD Coordinators</h1>
        </div>
      </div>
      <div className='row mobile-padding mb-7'>
        {leadercoordinatorsdata?.map((item: any) => (
          <div className='col-md-3 col-lg-3 col-xl-3 mb-5'>
            <div className='card ilead__card overflow-hidden main__leaders'>
              <div className='card-body p-0'>
                <div className='p-5 border-bottom mobile-border-none border-secondary d-flex d-md-block'>
                  <Link to={`/leaders/${item?.id}`} className='symbol symbol-100 bg-white border-dark mb-4 me-4 me-md-0'>
                    <img
                      src={
                        item?.profile
                          ? `${API_URL}/containers/userprofile/download/${item?.profile}`
                          : `${toAbsoluteUrl('./media/avatars/default-user.png')}`
                      }
                      alt=''
                      className='w-100'
                      onError={({ currentTarget }: any) => {
                        currentTarget.onerror = null // prevents looping
                        currentTarget.src = `${toAbsoluteUrl('./media/avatars/default-user.png')}`
                      }}
                    />
                  </Link>
                  <div className='leader__details text-truncate'>
                    <Link to={`/leaders/${item?.id}`}>
                      <h2 className='fs-6 text-dark leader__name text-md-center text-truncate'>
                        {checkDisplayValue(item?.name)}
                      </h2>
                      <div className='leader__chapter mb-5 mb-md-0'>
                        <span className='chapter__name text-primary d-block text-md-center text-truncate'>
                          {checkDisplayValue(item?.title)}
                        </span>
                      </div>
                    </Link>
                    <div className='leader__contact justify-content-md-center d-flex d-md-none'>
                      <a href={`mailto:${item?.email}`} className="symbol symbol-40px leader__email me-2">
                        <div className="symbol-label fs-2 fw-semibold text-primary">
                          <img src='/media/ilead/mail.svg' alt='' />
                        </div>
                      </a>
                      <a href={`tel:${getFormattedPhoneNumber(item?.mobileno)}`} className="symbol symbol-40px leader__mobile me-2">
                        <div className="symbol-label fs-2 fw-semibold text-success">
                          <img src='/media/ilead/phone-call.svg' alt='' />
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
                <div className='p-5 d-none d-md-block'>
                  <div className='leader__contact justify-content-center d-flex'>
                    <a href={`mailto:${item?.email}`} className="symbol symbol-40px leader__email me-2">
                      <div className="symbol-label fs-2 fw-semibold text-primary">
                        <img src='/media/ilead/mail.svg' alt='' />
                      </div>
                    </a>
                    <a href={`tel:${getFormattedPhoneNumber(item?.mobileno)}`} className="symbol symbol-40px leader__mobile me-2">
                      <div className="symbol-label fs-2 fw-semibold text-success">
                        <img src='/media/ilead/phone-call.svg' alt='' />
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      {/* ILEAD Secretariat */}
      <div className='row mobile-padding'>
        <div className='col-12'>
          <h1 className='mb-7'>ILEAD Secretariat</h1>
        </div>
      </div>
      <div className='row mobile-padding'>
        {secretariatdata?.map((item: any) => (
          <div className='col-md-3 col-lg-3 col-xl-3 mb-5'>
            <div className='card ilead__card overflow-hidden main__leaders'>
              <div className='card-body p-0'>
                <div className='p-5 border-bottom mobile-border-none border-secondary d-flex d-md-block'>
                  <Link to={`/leaders/${item?.user?.id}`} className='symbol symbol-100 bg-white border-dark mb-4 me-4 me-md-0'>
                    <img
                      src={
                        item?.user?.profile
                          ? `${API_URL}/containers/userprofile/download/${item?.user?.profile}`
                          : `${toAbsoluteUrl('./media/avatars/default-user.png')}`
                      }
                      alt=''
                      className='w-100'
                      onError={({ currentTarget }: any) => {
                        currentTarget.onerror = null // prevents looping
                        currentTarget.src = `${toAbsoluteUrl('./media/avatars/default-user.png')}`
                      }}
                    />
                  </Link>
                  <div className='leader__details text-truncate'>
                    <Link to={`/leaders/${item?.user?.id}`}>
                      <h2 className='fs-6 text-dark leader__name text-md-center text-truncate'>
                        <strong> {checkDisplayValue(item?.user?.name)} </strong>
                      </h2>
                      <div className='leader__chapter mb-5 mb-md-0'>
                        <span className='chapter__name text-primary d-block text-md-center text-truncate'>
                          {checkDisplayValue(item?.user?.name)}
                        </span>
                      </div>
                    </Link>
                    <div className='leader__contact justify-content-md-center d-flex d-md-none'>
                      <a href={`mailto:${item?.user?.email}`} className="symbol symbol-40px leader__email me-2">
                        <div className="symbol-label fs-2 fw-semibold text-primary">
                          <img src='/media/ilead/mail.svg' alt='' />
                        </div>
                      </a>
                      <a href={`tel:${getFormattedPhoneNumber(item?.user?.mobileno)}`} className="symbol symbol-40px leader__mobile me-2">
                        <div className="symbol-label fs-2 fw-semibold text-success">
                          <img src='/media/ilead/phone-call.svg' alt='' />
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
                <div className='p-5'>
                  <div className='leader__contact justify-content-center d-flex'>
                    <a href={`mailto:${item?.user?.email}`} className="symbol symbol-40px leader__email me-2">
                      <div className="symbol-label fs-2 fw-semibold text-primary">
                        <img src='/media/ilead/mail.svg' alt='' />
                      </div>
                    </a>
                    <a href={`tel:${getFormattedPhoneNumber(item?.user?.mobileno)}`} className="symbol symbol-40px leader__mobile me-2">
                      <div className="symbol-label fs-2 fw-semibold text-success">
                        <img src='/media/ilead/phone-call.svg' alt='' />
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      {/*  */}
    </>
  )
}

export default ICIWFUUPL
