/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useRef, useState } from 'react'
import { Query, useMutation, useQueryClient } from 'react-query'
// import {MenuComponent} from '../../../../../../../_metronic/assets/ts/components'
// import {ID, KTIcon, QUERIES} from '../../../../../../../_metronic/helpers'
import { useListView } from '../../core/ListViewProvider'
import { useQueryResponse } from '../../core/QueryResponseProvider'
import { deleteCometChatGroupmanagement, deleteGroupchat } from '../../core/_requests'
// import { MenuComponent } from '../../../../../../_metronic/assets/ts/components'
// import { ID, KTIcon, QUERIES } from '../../../../../../_metronic/helpers'
import { Link, useNavigate } from 'react-router-dom'
import EditGroup from '../../../../EditGroup'
import { ID, QUERIES } from '../../../../../../../_metronic/helpers'
import { MenuComponent } from '../../../../../../../_metronic/assets/ts/components'
import EditChatGroup from '../../../EditChatGroup'
// import AddEditNewGroup from '../../../AddEditNewGroup'
// import EditGroup from '../../../EditGroup'
// import AddGroupchats from '../../../AddGroupchat'
// import EditGroupchats from '../../../EditGroupchat'


type Props = {
  id: ID
}

const GroupchatActionsCell: FC<Props> = ({ id }) => {

  const closeButton = useRef<any>()
  const [loading, setLoading] = useState<any>(false)
  const { setItemIdForUpdate } = useListView()
  const { query } = useQueryResponse()
  const queryClient = useQueryClient()

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const deleteChatGroup = async () => {
    setLoading(true);
    const res = await deleteCometChatGroupmanagement(id)
    setLoading(false);
    queryClient.invalidateQueries([`${QUERIES.GROUPCHATS_LIST}-${query}`])
    closeButton.current.click()
  }

  const openEditModal = () => {
    setItemIdForUpdate(id)
  }

  const deleteItem = useMutation(() => deleteGroupchat(id), {
    // 💡 response of the mutation is passed to onSuccess
    onSuccess: () => {
      // ✅ update detail view directly
      queryClient.invalidateQueries([`${QUERIES.GROUPCHATS_LIST}-${query}`])
    },
  })

  const navigate = useNavigate()
  const navigateEditChatGroup = () => {
    // 👇️ navigate to /
    navigate(`/edit-chat-group/${id}`)
  }

  return (
    <>
      {/* <a
        href='#'
        className='btn btn-light-success rounded-circle py-2 px-3 btn-sm me-2'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
        style={{lineHeight: '11px !important'}}
        onClick={navigateViewGroupchat}
      >
        <i className="fa-regular fa-eye pe-0 fs-6"></i>
      </a> */}

      <Link
        to={`/edit-chat-group/${id}`}
        className='btn btn-light-warning rounded-circle py-2 px-3 btn-sm me-2'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
        style={{ lineHeight: '11px !important' }}
      // onClick={navigateEditChatGroup}
      >
        <i className="fa-regular fa-pen-to-square pe-0 fs-6"></i>
      </Link>

      <a
        href='#'
        className='btn btn-light-danger rounded-circle py-2 px-3 btn-sm'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
        style={{ lineHeight: '11px !important' }}
        data-bs-toggle="modal" data-bs-target={`#deletModal_${id}`}
      >
        <i className="fa-solid fa-trash-can pe-0 fs-6"></i>
      </a>



      <div className="modal fade" id={`deletModal_${id}`} tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">Delete chat group </h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" ref={closeButton}></button>
            </div>
            <div className="modal-body pb-0">
              <div className='col-12 text-center pt-5 pb-5'>
                <i className="fa-solid fa-trash-can text-danger mb-10" style={{ fontSize: '80px' }}></i>
                <h3 > Are you sure you want to delete this Group? </h3>
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-primary" disabled={loading} onClick={() => {
                deleteChatGroup()
              }}> {loading ? 'Please wait' : 'yes'} </button>
              <button type="button" className="btn btn-secondary" data-bs-dismiss="modal"> No </button>
            </div>
          </div>
        </div>
      </div>


    </>

  )
}

export { GroupchatActionsCell }
