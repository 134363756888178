import React from 'react'
import {Chart as ChartJS, ArcElement, Tooltip, Legend} from 'chart.js'
import {Pie} from 'react-chartjs-2'

ChartJS.register(ArcElement, Tooltip, Legend)

const MonthlyReportPieChart = (attandanceGraph: any) => {
  const data_value = attandanceGraph.attandanceGraph
  const data = {
    labels: ['Presence', 'Absence'],
    datasets: [
      {
        label: '# of Votes',
        data: [data_value?.attendadantPercentage, data_value?.absentPercentage],
        backgroundColor: ['rgb(0, 185, 172)', 'rgb(253, 98, 94)'],
        borderColor: ['rgba(0, 185, 172, 1)', 'rgba(253, 98, 94, 1)'],
        borderWidth: 1,
      },
    ],
  }

  return (
    <>
      <Pie data={data} />
    </>
  )
}

export default MonthlyReportPieChart
