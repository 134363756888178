import React, {useContext, useEffect, useRef, useState} from 'react'
import {Button, Form} from 'react-bootstrap'
import {CKEditor} from 'ckeditor4-react'
import {useNavigate, useLocation, Link} from 'react-router-dom'
import {Select} from '@mui/material'
import * as Yup from 'yup'
import moment from 'moment'
import {apiUrl} from '../../../apiurl'
import {useFormik} from 'formik'
import {UserContext} from '../../../contextStore'
import axios from 'axios'
import {checkDisplayValue} from '../../../utils'
import {CustomCkEditor} from './CKEditor'
import {toAbsoluteUrl} from '../../../_metronic/helpers'

const API_URL = apiUrl
const IMAGE_UPLOAD = `${API_URL}/containers/topicimages/upload`
const CHAPTER_LIST_URL = `${API_URL}/chapters`
const Date_LIST_URL = `${API_URL}/eventdates`
const SESSION_URL = `${API_URL}/sessionfeedbacks/savesession`
const Session_Feedback = `${API_URL}/sessionfeedbacks`
const GIVE_FEEDBACK = `${API_URL}/sessionfeedbacks/submitFeedback`
const VIEW_FEEDBACK = `${API_URL}/sessionfeedbacks/viewFeedback`

const validationForm = Yup.object().shape({
  event_id: Yup.number().required('This field is required'),
  sessiondesc: Yup.string(),
  eventname: Yup.string().required('This field is required'),
  postonwall: Yup.number(),
  image: Yup.array(),
})

const validation = Yup.object().shape({
  event_id: Yup.number(),
  rating: Yup.number(),
  tycarating: Yup.number(),
  ssrating: Yup.number(),
  feedback_by: Yup.number(),
  comments: Yup.string().max(500, 'Maximum 500 characters'),
})
const ListSessionFeedback = () => {
  const [chapterList, setChapterList] = useState<any>([])
  const [dateList, setDateList] = useState<any>([])
  const [isLoading, setIsLoading] = useState(true)
  const [chapterId, setChapterId] = useState<any>('')
  const [eventId, seteventId] = useState<any>('')
  const {userDetail, setMessage, chapterID} = useContext(UserContext)
  const [loading, setLoading] = useState(false)
  const todays_date = new Date().toISOString().split('T')[0]
  const [ispostonwall, setispostonwall] = useState<number>(0)
  const closebutton = useRef<any>()
  const [ckEditorKey, setCkEditorKey] = useState(0)
  const [sessiondescription, setsessiondescription] = useState<any>({value: ''})
  const [feedbackData, setFeedbackData] = useState<any>({})
  const [feedbackDataOne, setFeedbackDataOne] = useState<any>({})
  const [pastDate, setPastDate] = useState<any>(false)
  const [viewFeedBack, setViewFeedBack] = useState<any>(false)
  const [pastEventId, setPasteventId] = useState<any>('')
  const [pastEventData, setPasteventData] = useState<any>('')
  const MAX_CHARACTERS = 500
  const [messageCount, setMessageCount] = useState(MAX_CHARACTERS)
  const [ratings, setRatings] = useState(0)
  const [tycaratings, setTYCARatings] = useState(0)
  const [ssratings, setSSRatings] = useState(0)

  useEffect(() => {
    if (pastEventData?.chapter_id == chapterID && (chapterId == '')) {
      setChapterId(chapterID)
      formik.setFieldValue('event_id', pastEventData?.id)
      seteventId(pastEventData?.id)
    }
  }, [chapterId, pastEventData, chapterID, eventId])

  useEffect(() => {
    setChapterId(chapterID)
    if (pastEventData.chapter_id == chapterID) {
      formik.setFieldValue('event_id', pastEventData.id)
      seteventId(pastEventData.id)
    }
  }, [chapterID, pastEventData])

  useEffect(() => {
    fetchChaptersList()
    fetchDateList1()
  }, [])

  useEffect(() => {
    fetchDateList()
  }, [chapterId])

  useEffect(() => {
    fetchByID()
  }, [eventId])

  useEffect(() => {
    checkfeedback()
  }, [eventId])

  useEffect(()=>{
    fetchDateList1()
    checkfeedback()
    fetchByID()
    seteventId('')
  },[chapterID])

  const fetchByID = async () => {
    try {
      if (eventId) {
        const res = await axios.get(`${VIEW_FEEDBACK}?event_id=${eventId}`)
        setFeedbackData(res?.data ?? {})
        setIsLoading(false)
      }
    } catch (error) {
      console.error('Error -->:', error)
      setIsLoading(false)
    }
  }

  const fetchChaptersList = async () => {
    try {
      const res = await axios.get(`${CHAPTER_LIST_URL}?filter[where][isdelete]=0`)
      setChapterList(res?.data ?? [])
      setIsLoading(false)
    } catch (error) {
      console.error('Error -->:', error)
      setIsLoading(false)
    }
  }

  const fetchDateList = async () => {
    try {
      const res = await axios.get(
        `${Date_LIST_URL}?filter[limit]=4&filter[where][event_date][gte]=${todays_date}&filter[where][chapter_id]=${chapterId}&filter[order]=event_date ASC`
      )
      setDateList(res?.data ?? [])
      setIsLoading(false)
    } catch (error) {
      console.error('Error -->:', error)
      setIsLoading(false)
    }
  }

  const fetchDateList1 = async () => {
    try {
      const res1 = await axios.get(
        `${Date_LIST_URL}?filter[order]=event_date DESC&filter[limit]=1&filter[where][event_date][lt]=${todays_date}&filter[where][chapter_id]=${chapterID}`
      )
      if (res1?.data[0]?.id) {
        setPasteventData(res1?.data[0] ?? {})
        setPasteventId(res1?.data[0]?.id ?? '')
      }
      if (pastEventId == eventId) {
        const res2 = await axios.get(
          `${Session_Feedback}?filter[where][event_id]=${pastEventId}&filter[where][feedback_by]=${userDetail?.id}`
        )
        if (res2?.data[0]?.id) {
          setViewFeedBack(true)
          setFeedbackDataOne(res2?.data[0] ?? {})
          setPastDate(false)
        } else {
          setPastDate(true)
          setViewFeedBack(false)
        }
      }
      setIsLoading(false)
    } catch (error) {
      console.error('Error -->:', error)
      setIsLoading(false)
    }
  }

  const checkfeedback = async () => {
    try {
      if (pastEventId == eventId) {
        const res2 = await axios.get(
          `${Session_Feedback}?filter[where][event_id]=${pastEventId}&filter[where][feedback_by]=${userDetail?.id}`
        )
        if (res2?.data[0]?.id) {
          setViewFeedBack(true)
          setFeedbackDataOne(res2?.data[0] ?? {})
          setPastDate(false)
        } else {
          setPastDate(true)
          setViewFeedBack(false)
        }
      }
    } catch (error) {
      console.error('Error -->:', error)
    }
  }

  const handlepostonwall = (checked: boolean) => {
    setispostonwall(() => {
      const updatedisPostonWall = checked ? 1 : 0
      return updatedisPostonWall
    })
  }

  const initialValues = {
    event_id: '',
    sessiondesc: '',
    eventname: '',
    postonwall: '',
    image: '',
  }

  const initialFeedback = {
    event_id: '',
    feedback_by: '',
    rating: '',
    tycarating: '',
    ssrating: '',
    comments: '',
  }

  const formikFeedback = useFormik({
    initialValues: initialFeedback,
    validationSchema: validation,
    onSubmit: async (values, {setStatus, resetForm, setSubmitting}) => {
      setLoading(true)
      try {
        console.log('==>', values.event_id)
        const postData = {
          event_id: pastEventId ?? '',
          feedback_by: userDetail?.id ?? '',
          rating: ratings,
          tycarating: tycaratings,
          ssrating: ssratings,
          comments: values.comments,
        }
        await axios
          .post(GIVE_FEEDBACK, postData)
          .then((res: any) => {
            if (res?.status == 200) {
              setMessage('success', 'Feedback Submitted')
              setRatings(0)
              setTYCARatings(0)
              setSSRatings(0)
              resetForm()
              setMessageCount(MAX_CHARACTERS)
              setLoading(false)
              checkfeedback()
              setViewFeedBack(true)
            }
          })
          .catch((error: any) => {
            if (error?.response?.data?.error?.message) {
              setMessage('error', error?.response?.data?.error?.message)
            } else {
              setMessage('error', 'Something Went Wrong')
            }
            setLoading(false)
            resetForm()
          })
      } catch (error: any) {
        setMessage('error', error?.message ? error?.message : 'Something Went Wrong')
        setLoading(false)
        resetForm()
      }
    },
  })

  const formik = useFormik({
    initialValues,
    validationSchema: validationForm,
    onSubmit: async (values, {setStatus, resetForm, setSubmitting}) => {
      setLoading(true)
      try {
        console.log('==>', values.event_id)
        const postData = {
          event_id: values.event_id,
          sessiondesc: sessiondescription.value,
          eventname: values.eventname,
          postonwall: ispostonwall,
          image: values.image,
        }
        await axios
          .post(SESSION_URL, postData)
          .then((res: any) => {
            if (res?.status == 200) {
              setMessage('success', res?.data?.msg)
              setLoading(false)
              resetForm()
              setChapterId('')
              setispostonwall(0)
              seteventId('')
              setsessiondescription({value: ''})
              setCkEditorKey(ckEditorKey + 1)
              setFeedbackData({})
              closebutton.current.click()
            }
          })
          .catch((error: any) => {
            if (error?.response?.data?.error?.message) {
              setMessage('error', error?.response?.data?.error?.message)
            } else {
              setMessage('error', 'Something Went Wrong')
            }
            setLoading(false)
            resetForm()
            setChapterId('')
          })
      } catch (error: any) {
        setMessage('error', error?.message ? error?.message : 'Something Went Wrong')
        setLoading(false)
        resetForm()
        setChapterId('')
      }
    },
  })

  const handleCommentsChange = (event: any) => {
    formikFeedback.setFieldValue('comments', event.target.value.trimStart())
    const currentLength = event.target.value.length
    const remainingCharacters = MAX_CHARACTERS - currentLength
    setMessageCount(remainingCharacters)
    formikFeedback.handleChange(event)
  }

  useEffect(() => {
    if (feedbackData && pastEventId != eventId) {
      formik.setFieldValue('eventname', feedbackData?.event_name)
      setCkEditorKey(ckEditorKey - 1)
      setsessiondescription({value: feedbackData?.schedule_description})
    }
  }, [feedbackData, eventId])

  console.log('data', pastEventData)

  return (
    <>
      <div className='d-flex flex-column flex-md-row justify-content-between align-items-center'>
        <h1 className='inv-hst mb-3 mb-md-0'>Session Feedback</h1>
        <div className='row col-md-6 col-sm-12 top__filter'>
          <div className='form-group col-lg-6 col-md-6 ps-0 ps-md-3 col-sm-6 mb-5 mb-sm-0 floating-labels'>
            <select
              className='form-select border-left-0 w-100 border-right-0 border-top-0'
              aria-label='Default select example'
              value={chapterId}
              onChange={(e) => {
                setChapterId(e.target.value)
                formik.setFieldValue('event_id', '')
                seteventId('')
                formik.setFieldValue('eventname', '')
                setFeedbackData({})
              }}
            >
              <option value=''> Select Chapter Name </option>
              {chapterList?.map((i: any) => (
                <>
                  <option value={i?.id}> {i?.name} </option>
                </>
              ))}
            </select>
          </div>

          <div className='form-group col-lg-6 col-md-6 col-sm-6 ps-0 ps-md-3 floating-labels'>
            <select
              className='form-select border-left-0 w-100 border-right-0 border-top-0'
              aria-label='Default select example'
              onChange={(e: any) => {
                formik.setFieldValue('event_id', e.target.value)
                seteventId(e.target.value)
                formik.setFieldValue('eventname', '')
                setFeedbackData({})
              }}
              value={formik.values.event_id}
            >
              <option value=''> Select Event Date </option>
              {chapterId == chapterID && chapterID == pastEventData?.chapter_id ? (
                <option value={pastEventData?.id}>
                  {' '}
                  {checkDisplayValue(moment(pastEventData?.event_date).format('DD-MM-YYYY'))}{' '}
                </option>
              ) : (
                ''
              )}
              {dateList?.map((i: any) => (
                <>
                  <option value={i?.id}>
                    {checkDisplayValue(moment(i?.event_date).format('DD-MM-YYYY'))}{' '}
                  </option>
                </>
              ))}
            </select>
          </div>
        </div>
      </div>

      <div className='card p-8 mt-5 ilead__card'>
        <div className='row mx-0'>
          <div className='col-md-12 col-lg-2'></div>
          <div className='col-md-12 col-lg-10 text-end pe-6'>
            <button
              data-bs-toggle='modal'
              data-bs-target='#addupdatefdbk'
              className='btn btn-success btn-sm mb-5 waves-effect waves-light '
            >
              Add/Update Session Details{' '}
            </button>
            <Link
              to='/view-feedback'
              className='btn btn-primary btn-sm waves-effect ms-5 waves-light mb-5'
            >
              {' '}
              View Feedback{' '}
            </Link>
          </div>
        </div>

        {pastEventId == eventId && pastEventData.chapter_id == chapterID ? (
          <>
            <div className='row'>
              <div className='col-md-7 ps-0 '>
                {viewFeedBack ? (
                  <div className='row border-end border-gray-500 px-0 m-0'>
                    <div className='col-sm-12 mb-3'>
                      <div className='border position-relative overflow-hidden border-gray-300 d-flex flex-column justify-content-center p-2'>
                        <div className='fs-8 fw-bold text-center mb-3'>
                          Value Addition Session Rating
                        </div>

                        <div className='d-flex justify-content-between align-items-center'>
                          <div className=' text-center mb-3'>
                            {feedbackDataOne?.rating <= 1 ? (
                              <i
                                className='fa-regular fa-face-frown'
                                style={{fontSize: '50px'}}
                              ></i>
                            ) : (
                              ''
                            )}
                            {feedbackDataOne?.rating == 2 ? (
                              <i
                                className='fa-regular fa-face-frown-open'
                                style={{
                                  fontSize: '50px',
                                  boxShadow: 'rgba(227,55,30, 0.5) 0 0 10px',
                                  borderRadius: '50%',
                                }}
                              ></i>
                            ) : (
                              ''
                            )}
                            {feedbackDataOne?.rating == 3 ? (
                              <i
                                className='fa-regular fa-face-meh'
                                style={{
                                  fontSize: '50px',
                                  boxShadow: 'rgba(227,55,30, 0.5) 0 0 20px',
                                  borderRadius: '50%',
                                }}
                              ></i>
                            ) : (
                              ''
                            )}
                            {feedbackDataOne?.rating == 4 ? (
                              <i
                                className='fa-regular fa-face-smile'
                                style={{
                                  fontSize: '50px',
                                  boxShadow: 'rgba(227,55,30, 0.5) 0 0 30px',
                                  borderRadius: '50%',
                                }}
                              ></i>
                            ) : (
                              ''
                            )}
                            {feedbackDataOne?.rating == 5 ? (
                              <i
                                className='fa-regular fa-face-smile-beam'
                                style={{
                                  fontSize: '50px',
                                  boxShadow: 'rgba(227,55,30, 0.5) 0 0 40px',
                                  borderRadius: '50%',
                                }}
                              ></i>
                            ) : (
                              ''
                            )}
                          </div>
                          <div className='text-center mb-2'>
                            {feedbackDataOne?.rating == 0 ? (
                              <>
                                <span className='fa fa-star'></span>
                                <span className='fa fa-star'></span>
                                <span className='fa fa-star'></span>
                                <span className='fa fa-star'></span>
                                <span className='fa fa-star'></span>
                              </>
                            ) : (
                              ''
                            )}
                            {feedbackDataOne?.rating == 1 ? (
                              <>
                                <span className='fa fa-star' style={{color: '#e3371e'}}></span>
                                <span className='fa fa-star'></span>
                                <span className='fa fa-star'></span>
                                <span className='fa fa-star'></span>
                                <span className='fa fa-star'></span>
                              </>
                            ) : (
                              ''
                            )}
                            {feedbackDataOne?.rating == 2 ? (
                              <>
                                <span className='fa fa-star' style={{color: '#e3371e'}}></span>
                                <span className='fa fa-star' style={{color: '#e3371e'}}></span>
                                <span className='fa fa-star'></span>
                                <span className='fa fa-star'></span>
                                <span className='fa fa-star'></span>
                              </>
                            ) : (
                              ''
                            )}
                            {feedbackDataOne?.rating == 3 ? (
                              <>
                                <span className='fa fa-star' style={{color: '#e3371e'}}></span>
                                <span className='fa fa-star' style={{color: '#e3371e'}}></span>
                                <span className='fa fa-star' style={{color: '#e3371e'}}></span>
                                <span className='fa fa-star'></span>
                                <span className='fa fa-star'></span>
                              </>
                            ) : (
                              ''
                            )}
                            {feedbackDataOne?.rating == 4 ? (
                              <>
                                <span className='fa fa-star' style={{color: '#e3371e'}}></span>
                                <span className='fa fa-star' style={{color: '#e3371e'}}></span>
                                <span className='fa fa-star' style={{color: '#e3371e'}}></span>
                                <span className='fa fa-star' style={{color: '#e3371e'}}></span>
                                <span className='fa fa-star'></span>
                              </>
                            ) : (
                              ''
                            )}
                            {feedbackDataOne?.rating == 5 ? (
                              <>
                                <span className='fa fa-star' style={{color: '#e3371e'}}></span>
                                <span className='fa fa-star' style={{color: '#e3371e'}}></span>
                                <span className='fa fa-star' style={{color: '#e3371e'}}></span>
                                <span className='fa fa-star' style={{color: '#e3371e'}}></span>
                                <span className='fa fa-star' style={{color: '#e3371e'}}></span>
                              </>
                            ) : (
                              ''
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className='col-sm-12 mb-3'>
                      <div className='border position-relative overflow-hidden border-gray-300 d-flex flex-column justify-content-center p-2'>
                        <div className='fs-8 fw-bold text-center mb-3'>TYCA Session Rating</div>
                        <div className='d-flex justify-content-between align-items-center'>
                          <div className=' text-center mb-3'>
                            {feedbackDataOne?.tycarating <= 1 ? (
                              <i
                                className='fa-regular fa-face-frown'
                                style={{fontSize: '50px'}}
                              ></i>
                            ) : (
                              ''
                            )}
                            {feedbackDataOne?.tycarating == 2 ? (
                              <i
                                className='fa-regular fa-face-frown-open'
                                style={{
                                  fontSize: '50px',
                                  boxShadow: 'rgba(227,55,30, 0.5) 0 0 10px',
                                  borderRadius: '50%',
                                }}
                              ></i>
                            ) : (
                              ''
                            )}
                            {feedbackDataOne?.tycarating == 3 ? (
                              <i
                                className='fa-regular fa-face-meh'
                                style={{
                                  fontSize: '50px',
                                  boxShadow: 'rgba(227,55,30, 0.5) 0 0 20px',
                                  borderRadius: '50%',
                                }}
                              ></i>
                            ) : (
                              ''
                            )}
                            {feedbackDataOne?.tycarating == 4 ? (
                              <i
                                className='fa-regular fa-face-smile'
                                style={{
                                  fontSize: '50px',
                                  boxShadow: 'rgba(227,55,30, 0.5) 0 0 30px',
                                  borderRadius: '50%',
                                }}
                              ></i>
                            ) : (
                              ''
                            )}
                            {feedbackDataOne?.tycarating == 5 ? (
                              <i
                                className='fa-regular fa-face-smile-beam'
                                style={{
                                  fontSize: '50px',
                                  boxShadow: 'rgba(227,55,30, 0.5) 0 0 40px',
                                  borderRadius: '50%',
                                }}
                              ></i>
                            ) : (
                              ''
                            )}
                          </div>
                          <div className='text-center mb-2'>
                            {feedbackDataOne?.tycarating == 0 ? (
                              <>
                                <span className='fa fa-star'></span>
                                <span className='fa fa-star'></span>
                                <span className='fa fa-star'></span>
                                <span className='fa fa-star'></span>
                                <span className='fa fa-star'></span>
                              </>
                            ) : (
                              ''
                            )}
                            {feedbackDataOne?.tycarating == 1 ? (
                              <>
                                <span className='fa fa-star' style={{color: '#e3371e'}}></span>
                                <span className='fa fa-star'></span>
                                <span className='fa fa-star'></span>
                                <span className='fa fa-star'></span>
                                <span className='fa fa-star'></span>
                              </>
                            ) : (
                              ''
                            )}
                            {feedbackDataOne?.tycarating == 2 ? (
                              <>
                                <span className='fa fa-star' style={{color: '#e3371e'}}></span>
                                <span className='fa fa-star' style={{color: '#e3371e'}}></span>
                                <span className='fa fa-star'></span>
                                <span className='fa fa-star'></span>
                                <span className='fa fa-star'></span>
                              </>
                            ) : (
                              ''
                            )}
                            {feedbackDataOne?.tycarating == 3 ? (
                              <>
                                <span className='fa fa-star' style={{color: '#e3371e'}}></span>
                                <span className='fa fa-star' style={{color: '#e3371e'}}></span>
                                <span className='fa fa-star' style={{color: '#e3371e'}}></span>
                                <span className='fa fa-star'></span>
                                <span className='fa fa-star'></span>
                              </>
                            ) : (
                              ''
                            )}
                            {feedbackDataOne?.tycarating == 4 ? (
                              <>
                                <span className='fa fa-star' style={{color: '#e3371e'}}></span>
                                <span className='fa fa-star' style={{color: '#e3371e'}}></span>
                                <span className='fa fa-star' style={{color: '#e3371e'}}></span>
                                <span className='fa fa-star' style={{color: '#e3371e'}}></span>
                                <span className='fa fa-star'></span>
                              </>
                            ) : (
                              ''
                            )}
                            {feedbackDataOne?.tycarating == 5 ? (
                              <>
                                <span className='fa fa-star' style={{color: '#e3371e'}}></span>
                                <span className='fa fa-star' style={{color: '#e3371e'}}></span>
                                <span className='fa fa-star' style={{color: '#e3371e'}}></span>
                                <span className='fa fa-star' style={{color: '#e3371e'}}></span>
                                <span className='fa fa-star' style={{color: '#e3371e'}}></span>
                              </>
                            ) : (
                              ''
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className='col-sm-12 mb-3'>
                      <div className='border position-relative overflow-hidden border-gray-300 d-flex flex-column justify-content-center p-2'>
                        <div className='fs-8 fw-bold text-center mb-3'>
                          Success Sharing Session Rating
                        </div>
                        <div className='d-flex justify-content-between align-items-center'>
                          <div className=' text-center mb-3'>
                            {feedbackDataOne?.ssrating <= 1 ? (
                              <i
                                className='fa-regular fa-face-frown'
                                style={{fontSize: '50px'}}
                              ></i>
                            ) : (
                              ''
                            )}
                            {feedbackDataOne?.ssrating == 2 ? (
                              <i
                                className='fa-regular fa-face-frown-open'
                                style={{
                                  fontSize: '50px',
                                  boxShadow: 'rgba(227,55,30, 0.5) 0 0 10px',
                                  borderRadius: '50%',
                                }}
                              ></i>
                            ) : (
                              ''
                            )}
                            {feedbackDataOne?.ssrating == 3 ? (
                              <i
                                className='fa-regular fa-face-meh'
                                style={{
                                  fontSize: '50px',
                                  boxShadow: 'rgba(227,55,30, 0.5) 0 0 20px',
                                  borderRadius: '50%',
                                }}
                              ></i>
                            ) : (
                              ''
                            )}
                            {feedbackDataOne?.ssrating == 4 ? (
                              <i
                                className='fa-regular fa-face-smile'
                                style={{
                                  fontSize: '50px',
                                  boxShadow: 'rgba(227,55,30, 0.5) 0 0 30px',
                                  borderRadius: '50%',
                                }}
                              ></i>
                            ) : (
                              ''
                            )}
                            {feedbackDataOne?.ssrating == 5 ? (
                              <i
                                className='fa-regular fa-face-smile-beam'
                                style={{
                                  fontSize: '50px',
                                  boxShadow: 'rgba(227,55,30, 0.5) 0 0 40px',
                                  borderRadius: '50%',
                                }}
                              ></i>
                            ) : (
                              ''
                            )}
                          </div>
                          <div className='text-center mb-2'>
                            {feedbackDataOne?.ssrating == 0 ? (
                              <>
                                <span className='fa fa-star'></span>
                                <span className='fa fa-star'></span>
                                <span className='fa fa-star'></span>
                                <span className='fa fa-star'></span>
                                <span className='fa fa-star'></span>
                              </>
                            ) : (
                              ''
                            )}
                            {feedbackDataOne?.ssrating == 1 ? (
                              <>
                                <span className='fa fa-star' style={{color: '#e3371e'}}></span>
                                <span className='fa fa-star'></span>
                                <span className='fa fa-star'></span>
                                <span className='fa fa-star'></span>
                                <span className='fa fa-star'></span>
                              </>
                            ) : (
                              ''
                            )}
                            {feedbackDataOne?.ssrating == 2 ? (
                              <>
                                <span className='fa fa-star' style={{color: '#e3371e'}}></span>
                                <span className='fa fa-star' style={{color: '#e3371e'}}></span>
                                <span className='fa fa-star'></span>
                                <span className='fa fa-star'></span>
                                <span className='fa fa-star'></span>
                              </>
                            ) : (
                              ''
                            )}
                            {feedbackDataOne?.ssrating == 3 ? (
                              <>
                                <span className='fa fa-star' style={{color: '#e3371e'}}></span>
                                <span className='fa fa-star' style={{color: '#e3371e'}}></span>
                                <span className='fa fa-star' style={{color: '#e3371e'}}></span>
                                <span className='fa fa-star'></span>
                                <span className='fa fa-star'></span>
                              </>
                            ) : (
                              ''
                            )}
                            {feedbackDataOne?.ssrating == 4 ? (
                              <>
                                <span className='fa fa-star' style={{color: '#e3371e'}}></span>
                                <span className='fa fa-star' style={{color: '#e3371e'}}></span>
                                <span className='fa fa-star' style={{color: '#e3371e'}}></span>
                                <span className='fa fa-star' style={{color: '#e3371e'}}></span>
                                <span className='fa fa-star'></span>
                              </>
                            ) : (
                              ''
                            )}
                            {feedbackDataOne?.ssrating == 5 ? (
                              <>
                                <span className='fa fa-star' style={{color: '#e3371e'}}></span>
                                <span className='fa fa-star' style={{color: '#e3371e'}}></span>
                                <span className='fa fa-star' style={{color: '#e3371e'}}></span>
                                <span className='fa fa-star' style={{color: '#e3371e'}}></span>
                                <span className='fa fa-star' style={{color: '#e3371e'}}></span>
                              </>
                            ) : (
                              ''
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className='col-sm-12 mb-3 '>
                      <strong className='fs-8 text-dark text-start'>Your Comment:</strong>
                      <p>{checkDisplayValue(feedbackDataOne?.comments)}</p>
                    </div>
                  </div>
                ) : pastDate ? (
                  <form onSubmit={formikFeedback.handleSubmit}>
                    <div className='row border-end border-gray-500 px-0 m-0'>
                      <p className='ps-3 fs-6 fw-bold text-dark text-start'>Submit Feedback</p>
                      <div className='col-sm-12 mb-3'>
                        <div className='border position-relative overflow-hidden border-gray-300 d-flex flex-column justify-content-center p-2'>
                          <div className='fs-8 fw-bold text-center mb-3'>
                            Value Addition Session Rating
                          </div>

                          <div className='d-flex justify-content-between align-items-center'>
                            <div className=' text-center mb-3'>
                              {ratings <= 1 ? (
                                <i
                                  className='fa-regular fa-face-frown'
                                  style={{fontSize: '50px'}}
                                ></i>
                              ) : (
                                ''
                              )}
                              {ratings == 2 ? (
                                <i
                                  className='fa-regular fa-face-frown-open'
                                  style={{
                                    fontSize: '50px',
                                    boxShadow: 'rgba(227,55,30, 0.5) 0 0 10px',
                                    borderRadius: '50%',
                                  }}
                                ></i>
                              ) : (
                                ''
                              )}
                              {ratings == 3 ? (
                                <i
                                  className='fa-regular fa-face-meh'
                                  style={{
                                    fontSize: '50px',
                                    boxShadow: 'rgba(227,55,30, 0.5) 0 0 20px',
                                    borderRadius: '50%',
                                  }}
                                ></i>
                              ) : (
                                ''
                              )}
                              {ratings == 4 ? (
                                <i
                                  className='fa-regular fa-face-smile'
                                  style={{
                                    fontSize: '50px',
                                    boxShadow: 'rgba(227,55,30, 0.5) 0 0 30px',
                                    borderRadius: '50%',
                                  }}
                                ></i>
                              ) : (
                                ''
                              )}
                              {ratings == 5 ? (
                                <i
                                  className='fa-regular fa-face-smile-beam'
                                  style={{
                                    fontSize: '50px',
                                    boxShadow: 'rgba(227,55,30, 0.5) 0 0 40px',
                                    borderRadius: '50%',
                                  }}
                                ></i>
                              ) : (
                                ''
                              )}
                            </div>
                            <div className='text-center mb-2'>
                              <span
                                className={`fa fa-star cursor-pointer`}
                                style={ratings >= 1 && ratings != 0 ? {color: '#e3371e'} : {}}
                                onClick={() => {
                                  setRatings(1)
                                }}
                              ></span>
                              <span
                                className={`fa fa-star cursor-pointer`}
                                style={ratings >= 2 ? {color: '#e3371e'} : {}}
                                onClick={() => {
                                  setRatings(2)
                                }}
                              ></span>
                              <span
                                className={`fa fa-star cursor-pointer`}
                                style={ratings >= 3 ? {color: '#e3371e'} : {}}
                                onClick={() => {
                                  setRatings(3)
                                }}
                              ></span>
                              <span
                                className={`fa fa-star cursor-pointer`}
                                style={ratings >= 4 ? {color: '#e3371e'} : {}}
                                onClick={() => {
                                  setRatings(4)
                                }}
                              ></span>
                              <span
                                className={`fa fa-star cursor-pointer`}
                                style={ratings >= 5 ? {color: '#e3371e'} : {}}
                                onClick={() => {
                                  setRatings(5)
                                }}
                              ></span>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className='col-sm-12 mb-3'>
                        <div className='border position-relative overflow-hidden border-gray-300 d-flex flex-column justify-content-center p-2'>
                          <div className='fs-8 fw-bold text-center mb-3'>TYCA Session Rating</div>
                          <div className='d-flex justify-content-between align-items-center'>
                            <div className=' text-center mb-3'>
                              {tycaratings <= 1 ? (
                                <i
                                  className='fa-regular fa-face-frown'
                                  style={{fontSize: '50px'}}
                                ></i>
                              ) : (
                                ''
                              )}
                              {tycaratings == 2 ? (
                                <i
                                  className='fa-regular fa-face-frown-open'
                                  style={{
                                    fontSize: '50px',
                                    boxShadow: 'rgba(227,55,30, 0.5) 0 0 10px',
                                    borderRadius: '50%',
                                  }}
                                ></i>
                              ) : (
                                ''
                              )}
                              {tycaratings == 3 ? (
                                <i
                                  className='fa-regular fa-face-meh'
                                  style={{
                                    fontSize: '50px',
                                    boxShadow: 'rgba(227,55,30, 0.5) 0 0 20px',
                                    borderRadius: '50%',
                                  }}
                                ></i>
                              ) : (
                                ''
                              )}
                              {tycaratings == 4 ? (
                                <i
                                  className='fa-regular fa-face-smile'
                                  style={{
                                    fontSize: '50px',
                                    boxShadow: 'rgba(227,55,30, 0.5) 0 0 30px',
                                    borderRadius: '50%',
                                  }}
                                ></i>
                              ) : (
                                ''
                              )}
                              {tycaratings == 5 ? (
                                <i
                                  className='fa-regular fa-face-smile-beam'
                                  style={{
                                    fontSize: '50px',
                                    boxShadow: 'rgba(227,55,30, 0.5) 0 0 40px',
                                    borderRadius: '50%',
                                  }}
                                ></i>
                              ) : (
                                ''
                              )}
                            </div>
                            <div className='text-center mb-2'>
                              <span
                                className={`fa fa-star cursor-pointer`}
                                style={
                                  tycaratings >= 1 && tycaratings != 0 ? {color: '#e3371e'} : {}
                                }
                                onClick={() => {
                                  setTYCARatings(1)
                                }}
                              ></span>
                              <span
                                className={`fa fa-star cursor-pointer`}
                                style={tycaratings >= 2 ? {color: '#e3371e'} : {}}
                                onClick={() => {
                                  setTYCARatings(2)
                                }}
                              ></span>
                              <span
                                className={`fa fa-star cursor-pointer`}
                                style={tycaratings >= 3 ? {color: '#e3371e'} : {}}
                                onClick={() => {
                                  setTYCARatings(3)
                                }}
                              ></span>
                              <span
                                className={`fa fa-star cursor-pointer`}
                                style={tycaratings >= 4 ? {color: '#e3371e'} : {}}
                                onClick={() => {
                                  setTYCARatings(4)
                                }}
                              ></span>
                              <span
                                className={`fa fa-star cursor-pointer`}
                                style={tycaratings >= 5 ? {color: '#e3371e'} : {}}
                                onClick={() => {
                                  setTYCARatings(5)
                                }}
                              ></span>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className='col-sm-12 mb-3'>
                        <div className='border position-relative overflow-hidden border-gray-300 d-flex flex-column justify-content-center p-2'>
                          <div className='fs-8 fw-bold text-center mb-3'>
                            Success Sharing Session Rating
                          </div>
                          <div className='d-flex justify-content-between align-items-center'>
                            <div className=' text-center mb-3'>
                              {ssratings <= 1 ? (
                                <i
                                  className='fa-regular fa-face-frown'
                                  style={{fontSize: '50px'}}
                                ></i>
                              ) : (
                                ''
                              )}
                              {ssratings == 2 ? (
                                <i
                                  className='fa-regular fa-face-frown-open'
                                  style={{
                                    fontSize: '50px',
                                    boxShadow: 'rgba(227,55,30, 0.5) 0 0 10px',
                                    borderRadius: '50%',
                                  }}
                                ></i>
                              ) : (
                                ''
                              )}
                              {ssratings == 3 ? (
                                <i
                                  className='fa-regular fa-face-meh'
                                  style={{
                                    fontSize: '50px',
                                    boxShadow: 'rgba(227,55,30, 0.5) 0 0 20px',
                                    borderRadius: '50%',
                                  }}
                                ></i>
                              ) : (
                                ''
                              )}
                              {ssratings == 4 ? (
                                <i
                                  className='fa-regular fa-face-smile'
                                  style={{
                                    fontSize: '50px',
                                    boxShadow: 'rgba(227,55,30, 0.5) 0 0 30px',
                                    borderRadius: '50%',
                                  }}
                                ></i>
                              ) : (
                                ''
                              )}
                              {ssratings == 5 ? (
                                <i
                                  className='fa-regular fa-face-smile-beam'
                                  style={{
                                    fontSize: '50px',
                                    boxShadow: 'rgba(227,55,30, 0.5) 0 0 40px',
                                    borderRadius: '50%',
                                  }}
                                ></i>
                              ) : (
                                ''
                              )}
                            </div>
                            <div className='text-center mb-2'>
                              <span
                                className={`fa fa-star cursor-pointer`}
                                style={ssratings >= 1 && ssratings != 0 ? {color: '#e3371e'} : {}}
                                onClick={() => {
                                  setSSRatings(1)
                                }}
                              ></span>
                              <span
                                className={`fa fa-star cursor-pointer`}
                                style={ssratings >= 2 ? {color: '#e3371e'} : {}}
                                onClick={() => {
                                  setSSRatings(2)
                                }}
                              ></span>
                              <span
                                className={`fa fa-star cursor-pointer`}
                                style={ssratings >= 3 ? {color: '#e3371e'} : {}}
                                onClick={() => {
                                  setSSRatings(3)
                                }}
                              ></span>
                              <span
                                className={`fa fa-star cursor-pointer`}
                                style={ssratings >= 4 ? {color: '#e3371e'} : {}}
                                onClick={() => {
                                  setSSRatings(4)
                                }}
                              ></span>
                              <span
                                className={`fa fa-star cursor-pointer`}
                                style={ssratings >= 5 ? {color: '#e3371e'} : {}}
                                onClick={() => {
                                  setSSRatings(5)
                                }}
                              ></span>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className='col-sm-12 mb-3 '>
                        <label className='fs-8 text-dark text-start'>Any Comments</label>
                        <textarea
                          required
                          rows={4}
                          placeholder=''
                          className='form-control'
                          onChange={handleCommentsChange}
                          maxLength={MAX_CHARACTERS}
                        ></textarea>
                        <p className='fs-8 text-dark text-start'>
                          Available Characters: {messageCount}
                        </p>
                        <button
                          type='submit'
                          className='btn btn-sm mt-5 btn-primary ms-0 waves-effect waves-light '
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </form>
                ) : (
                  <>
                    <strong className='text-danger'>NO DATA FOUND</strong>
                  </>
                )}
              </div>
              <div className='col-md-5 ps-0'>
                <div className='text-dark fw-bold fs-5'>
                  {' '}
                  <span>
                    {' '}
                    <i className='fa-solid fa-calendar-days fs-4 text-dark me-2'></i>{' '}
                  </span>{' '}
                  {feedbackData?.event_date
                    ? checkDisplayValue(moment(feedbackData?.event_date).format('DD-MM-YYYY'))
                    : '-'}{' '}
                  <div className='text-dark fw-bold fs-5'> Session Details </div>
                  <strong className='text-danger'>
                    {checkDisplayValue(feedbackData?.event_name)}
                  </strong>
                  <p dangerouslySetInnerHTML={{__html: feedbackData?.schedule_description}}></p>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            {eventId ?  (
              <>
                <div className='row mx-0 border-bottom border-gray-300'>
                  <div className='col-lg-4 col-md-12'>
                    <div className='text-dark fw-bold fs-5'> Submit Feedback </div>
                  </div>
                </div>

                <div className='row mx-0 mt-10'>
                  <div className='col-md-12 col-lg-8 text-dark fw-bold fs-3'>
                    You Can Only Submit Feedback After Session Has Been Completed.
                  </div>
                  <div className='col-lg-4 col-md-12'>
                    <div className='text-dark fw-bold fs-5'>
                      {' '}
                      <span>
                        {' '}
                        <i className='fa-solid fa-calendar-days fs-4 text-dark me-2'></i>{' '}
                      </span>{' '}
                      {feedbackData?.event_date
                        ? checkDisplayValue(moment(feedbackData?.event_date).format('DD-MM-YYYY'))
                        : '-'}{' '}
                      <div className='text-dark fw-bold fs-5'> Session Details </div>
                      <strong className='text-danger'>
                        {checkDisplayValue(feedbackData?.event_name)}
                      </strong>
                      <p dangerouslySetInnerHTML={{__html: feedbackData?.schedule_description}}></p>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <strong className='text-danger fw-bold fs-2'>
                Please Select Chapter And Event Date.
              </strong>
            )}
          </>
        )}
      </div>

      <div
        className='modal fade visitor-details'
        id='addupdatefdbk'
        data-bs-backdrop='static'
        data-bs-keyboard='false'
        tabIndex={-1}
        aria-labelledby='staticBackdropLabel'
        aria-hidden='true'
      >
        <div className='modal-dialog me-0 mb-0 mt-0 border-radius-0'>
          <div className='modal-content '>
            <div className='modal-header border-bottom border-gray-300'>
              <h5 className='modal-title text-primary' id='staticBackdropLabel'>
                Add/Update Session Details
              </h5>
              <button
                type='button'
                className='btn-close'
                data-bs-dismiss='modal'
                aria-label='Close'
                ref={closebutton}
                onClick={() => {
                  formik.resetForm()
                  setChapterId('')
                  setispostonwall(0)
                  setCkEditorKey(ckEditorKey + 1)
                  setsessiondescription({value: ''})
                  seteventId('')
                  setFeedbackData({})
                }}
              ></button>
            </div>
            <Form onSubmit={formik.handleSubmit}>
              <div className='modal-body'>
                <div className='row mt-5 '>
                  <div className='col-md-12 col-lg-6 mb-10'>
                    <label className='fw-semibold fs-7  text-primary'> Chapter Name </label>
                    <Form.Select
                      aria-label='Default select example'
                      required
                      className='border-left-0 border-top-0 border-right-0 border-radius-0 ps-0'
                      value={chapterId}
                      onChange={(e) => {
                        setChapterId(e.target.value)
                        formik.setFieldValue('event_id', '')
                        seteventId('')
                        formik.setFieldValue('eventname', '')
                        setFeedbackData({})
                      }}
                    >
                      <option value=''>Select Chapter</option>
                      {chapterList?.map((i: any) => (
                        <>
                          <option value={i?.id}> {i?.name} </option>
                        </>
                      ))}
                    </Form.Select>
                  </div>
                  <div className='col-md-12 col-lg-6 mb-10'>
                    <label className='fw-semibold fs-7 text-primary'> Session Date </label>
                    <Form.Select
                      aria-label='Default select example'
                      required
                      className='border-left-0 border-top-0 border-right-0 border-radius-0 ps-0'
                      onChange={(e: any) => {
                        formik.setFieldValue('event_id', e.target.value)
                        seteventId(e.target.value)
                        formik.setFieldValue('eventname', '')
                        setFeedbackData({})
                      }}
                      value={formik.values.event_id}
                    >
                      <option value=''>Select Event Date</option>
                      {dateList?.map((i: any) => (
                        <>
                          <option value={i?.id}>
                            {console.log('===>', i.id)}
                            {checkDisplayValue(moment(i?.event_date).format('MMM DD, YYYY'))}{' '}
                          </option>
                        </>
                      ))}
                    </Form.Select>
                  </div>
                </div>

                <div className='col-12  mb-10'>
                  <label className='fw-semibold fs-5'>
                    {' '}
                    Session Name <span className='text-danger fs-8'> * </span>
                  </label>
                  <Form.Control
                    required
                    type='text'
                    placeholder=''
                    className='border-left-0 border-top-0 border-right-0 border-radius-0 ps-0'
                    onChange={(e) => {
                      formik.setFieldValue('eventname', e.target.value)
                    }}
                    value={formik.values.eventname}
                  />
                </div>

                <div className='col-12 mt-10'>
                  <label className='fw-semibold fs-5 mb-3'>
                    Add/Update Session Flow <span className='text-danger fs-8'> * </span>
                  </label>
                  <CustomCkEditor
                    key={ckEditorKey}
                    data={sessiondescription.value}
                    changeData={(data: any) => {
                      setsessiondescription({value: data})
                    }}
                  />
                </div>
                <div className='row mx-0 mt-10'>
                  <div className='form-check col-sm-12 mb-20 checkbox-bdr'>
                    <input
                      className='form-check-input'
                      style={{border: '1px solid #ccc'}}
                      type='checkbox'
                      onChange={(e) => handlepostonwall(e.target.checked)}
                      checked={ispostonwall === 1}
                      id='flexCheckChecked'
                    />
                    <label className='form-check-label' htmlFor='flexCheckChecked'>
                      Do Want to post this session flow on dashboard?
                    </label>
                  </div>
                  <div className='col-sm-12'>
                    {' '}
                    <Button
                      variant='primary'
                      type='submit'
                      className=' w-100'
                      disabled={
                        formik.isSubmitting ||
                        loading ||
                        !formik.values.event_id ||
                        !formik.values.eventname
                      }
                    >
                      Save
                    </Button>{' '}
                  </div>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </>
  )
}

export default ListSessionFeedback
