import {useMemo} from 'react'
import {useTable, ColumnInstance, Row} from 'react-table'
import {CustomHeaderColumn} from './columns/CustomHeaderColumn'
import {CustomRow} from './columns/CustomRow'
import {useQueryResponseData, useQueryResponseLoading} from '../core/QueryResponseProvider'
import {remindersColumns} from './columns/_columns'
import {Reminder} from '../core/_models'
import {RemindersListLoading} from '../components/loading/RemindersListLoading'
import {RemindersListPagination} from '../components/pagination/RemindersListPagination'
import {KTCardBody, defaultReminders} from '../../../../../../_metronic/helpers'
import {useQueryRequest} from '../core/QueryRequestProvider'
import {checkDisplayValue} from '../../../../../../utils'
// import { KTCardBody, defaultReminders } from '../../../../../_metronic/helpers'
// import {KTCardBody} from '../../../../../../_metronic/helpers'

const CopyReminderTable = () => {
  const reminders = useQueryResponseData()
  const isLoading = useQueryResponseLoading()
  const {state, updateState} = useQueryRequest() as any
  const data = useMemo(() => reminders, [reminders])
  const columns = useMemo(() => remindersColumns, [])
  const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
    columns,
    data,
  })

  return (
    <table
      id='copy_kt_table_reminders'
      className='table align-middle table-row-dashed fs-6 gy-5 dataTable table-striped  no-footer'
      {...getTableProps()}
      style={{display: 'none'}}
    >
      <thead>
        <tr className='text-center text-muted fw-bolder fs-7 text-uppercase gs-0 border-bottom border-gray-100'>
          <td>TITLE</td>
          <td>NOTIFICATION TYPE</td>
        </tr>
      </thead>

      <tbody className='text-gray-600 text-center fw-bold' {...getTableBodyProps()}>
        {data.length > 0 ? (
          data.map((row: any, i: any) => {
            return (
              <tr key={i}>
                <td>{checkDisplayValue(row?.title)}</td>
                <td>{checkDisplayValue(row?.notification_type)}</td>
              </tr>
            )
          })
        ) : (
          <tr>
            <td colSpan={7}>
              <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                No matching records found
              </div>
            </td>
          </tr>
        )}
      </tbody>
    </table>
  )
}

export {CopyReminderTable}
