import React, {useContext, useEffect, useMemo, useState} from 'react'
import {Button, Image} from 'react-bootstrap'
import {Link} from 'react-router-dom'
import {ScrollMenu, VisibilityContext} from 'react-horizontal-scrolling-menu'
import 'react-horizontal-scrolling-menu/dist/styles.css'
import {LeftArrow, RightArrow} from './post-type/Arrow'
import moment from 'moment'
import {
  currentPerformanceApi,
  getBusinessMonthlyPerformanceApi,
  getTopPerformanceApi,
} from './_requests'
import {UserContext} from '../../../../contextStore'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {apiUrl} from '../../../../apiurl'
import {BIGIStarMemberLeaderLoadingSkeleton} from './post-type/BIGIStarMemberLeaderLoadingSkeleton'

export function BiGi() {
  const API_URL = apiUrl
  const {userDetail, chapterID, reload} = useContext(UserContext)
  const [currentPerformanceData, setCurrentPerformanceData] = useState<any>({})
  const [groupButton, setGroupButton] = useState(true)
  // const [loading, setLoading] = useState(false)
  const [loadingBI, setLoadingBI] = useState(false)
  const [loadingGI, setLoadingGI] = useState(false)
  const [topPerformanceData, setTopPerformanceData] = useState<any>([])
  const [topBusinessPerformanceData, setTopBusinessPerformanceData] = useState<any>([])

  const getCurrentUserData = async () => {
    let responseData = await currentPerformanceApi()
    setCurrentPerformanceData(responseData ?? {})
  }

  type scrollVisibilityApiType = React.ContextType<typeof VisibilityContext>
  function onWheel(apiObj: scrollVisibilityApiType, ev: React.WheelEvent): void {
    const isThouchpad = Math.abs(ev.deltaX) !== 0 || Math.abs(ev.deltaY) < 6

    if (isThouchpad) {
      ev.stopPropagation()
      return
    }

    if (ev.deltaY < 0) {
      apiObj.scrollNext()
    } else if (ev.deltaY > 0) {
      apiObj.scrollPrev()
    }
  }

  const allMemberPerformanceList = useMemo(() => {
    let data = [...topPerformanceData]
    console.log('performer data =======>', data)
    if (loadingGI || loadingBI) {
      return (
        <div className='text-center'>
          <BIGIStarMemberLeaderLoadingSkeleton />
        </div>
      )
    }

    return (
      <>
        {data[0] ? (
          <ScrollMenu LeftArrow={LeftArrow} RightArrow={RightArrow} onWheel={onWheel}>
            {data.map((item: any) => (
              <Link to={`/leaders/${item.id}`}>
                <div
                  className='member-box'
                  style={{
                    borderColor: item?.leaderGIColor ? item?.leaderGIColor : '',
                    borderStyle: item?.leaderGIColor ? 'solid' : '',
                    borderWidth: item?.leaderGIColor ? '2px' : '',
                  }}
                >
                  <div className='member-pic'>
                    <Image
                      src={
                        item.profile
                          ? `${API_URL}/containers/userprofile/download/${item?.profile}`
                          : `${toAbsoluteUrl('./media/avatars/default-user.png')}`
                      }
                      className='position-absolute w-100 h-100 object-fit-cover'
                      onError={({currentTarget}: any) => {
                        currentTarget.onerror = null // prevents looping
                        currentTarget.src = `${toAbsoluteUrl('./media/avatars/default-user.png')}`
                      }}
                    ></Image>
                  </div>
                  <div className='member-details position-relative p-3 w-100 h-100 d-flex flex-column justify-content-end'>
                    <span className='points fw-bold ilead-text'>{item?.total_points ?? ''}</span>
                    <h6 className='text-white text-truncate fw-normal mb-0'>{item?.name ?? ''}</h6>
                  </div>
                </div>
              </Link>
            ))}
          </ScrollMenu>
        ) : (
          ''
        )}
      </>
    )
  }, [topPerformanceData, loadingBI, loadingGI])
  const allMemberBusinessPerformanceList = useMemo(() => {
    let data = [...topBusinessPerformanceData]
    console.log('performer data =======>', data)
    if (loadingGI || loadingBI) {
      return (
        <div className='text-center'>
          <BIGIStarMemberLeaderLoadingSkeleton />
        </div>
      )
    }

    return (
      <>
        {data[0] ? (
          <ScrollMenu LeftArrow={LeftArrow} RightArrow={RightArrow} onWheel={onWheel}>
            {data.map((item: any) => (
              <Link to={`/leaders/${item.id}`}>
                <div
                  className='member-box'
                  style={{
                    borderColor: item?.leaderGIColor ? item?.leaderGIColor : '',
                    borderStyle: item?.leaderGIColor ? 'solid' : '',
                    borderWidth: item?.leaderGIColor ? '2px' : '',
                  }}
                >
                  <div className='member-pic'>
                    <Image
                      src={
                        item.profile
                          ? `${API_URL}/containers/userprofile/download/${item?.profile}`
                          : `${toAbsoluteUrl('./media/avatars/default-user.png')}`
                      }
                      className='position-absolute w-100 h-100 object-fit-cover'
                      onError={({currentTarget}: any) => {
                        currentTarget.onerror = null // prevents looping
                        currentTarget.src = `${toAbsoluteUrl('./media/avatars/default-user.png')}`
                      }}
                    ></Image>
                  </div>
                  <div className='member-details position-relative p-3 w-100 h-100 d-flex flex-column justify-content-end'>
                    <span className='points fw-bold ilead-text'>{item?.total_points ?? ''}</span>
                    <h6 className='text-white text-truncate fw-normal mb-0'>{item?.name ?? ''}</h6>
                  </div>
                </div>
              </Link>
            ))}
          </ScrollMenu>
        ) : (
          ''
        )}
      </>
    )
  }, [topBusinessPerformanceData, loadingBI, loadingGI])

  const getTopPerformance = async (chapterId: any, all: any, group: any) => {
    setLoadingGI(true)
    try {
      let response = await getTopPerformanceApi(chapterId, all, group)
      let responseData = response ?? []
      setTopPerformanceData([...responseData])
      setLoadingGI(false)
    } catch (err) {
      console.error(err)
      setLoadingGI(false)
    }
  }

  const getBusinessTopPerformers = async (chapterId: any, all: any, group: any) => {
    setLoadingBI(true)
    try {
      let response = await getBusinessMonthlyPerformanceApi(chapterId, all, group)
      let responseData = response ?? []
      setTopBusinessPerformanceData([...responseData])
      setLoadingBI(false)
    } catch (err) {
      console.error(err)
      setLoadingBI(false)
    }
  }

  const onClickMonthAchievers = () => {
    let chapterId = chapterID ?? ''
    getTopPerformance(chapterId, true, false)
    getBusinessTopPerformers(chapterId, true, false)
  }

  const onClickYearAchievers = () => {
    let chapterId = chapterID ?? ''
    getTopPerformance(chapterId, false, true)
    getBusinessTopPerformers(chapterId, false, true)
  }

  useEffect(() => {
    getCurrentUserData()
    if (groupButton) {
      onClickMonthAchievers()
    } else {
      onClickYearAchievers()
    }
  }, [])
  useEffect(() => {
    if (reload) {
      getCurrentUserData()
      if (groupButton) {
        onClickMonthAchievers()
      } else {
        onClickYearAchievers()
      }
    }
  }, [reload])

  return (
    <div
      className='gibi-container card ilead__card overflow-hidden mb-7 position-relative top-0'
      style={{width: 'inherit'}}
    >
      <ul className='nav nav-tabs nav-line-tabs nav-line-tabs-2x mb-5 fs-6 position-absolute position-md-relative end-0 top-0 border-0 pe-5 pt-3 '>
        <li className='nav-item'>
          <a
            className='nav-link text-dark fw-bolder'
            data-bs-toggle='tab'
            href='#kt_tab_pane_4'
            onClick={() => {
              let chapterId = userDetail?.chapter_id ?? ''
              setTopPerformanceData([])
              getBusinessTopPerformers(chapterId, true, false)
              getTopPerformance(chapterId, true, false)
              setGroupButton(true)
            }}
          >
            BI'{moment().format('YY')}
            <br className='d-block d-md-none' /> {currentPerformanceData?.bi?.rating ?? 0}
          </a>
        </li>
        <li className='nav-item'>
          <a
            className='nav-link active text-dark fw-bolder'
            data-bs-toggle='tab'
            href='#kt_tab_pane_5'
            onClick={() => {
              setGroupButton(true)
              if (groupButton) {
                onClickMonthAchievers()
              } else {
                onClickYearAchievers()
              }
            }}
          >
            {moment().format('MMM')}'{moment().format('YY')}
            <br className='d-block d-md-none' /> GI {currentPerformanceData?.rating ?? ''}
          </a>
        </li>
      </ul>
      <div className='tab-content' id='myTabContent'>
        {/* begin:BI Members */}
        <div className='tab-pane fade' id='kt_tab_pane_4' role='tabpanel'>
          {/* begin:header */}
          <div className='d-flex align-items-center justify-content-between'>
            <h3 className='fw-bolder fs-6'>Star Member Leaders</h3>
            <div>
              <Button
                variant='link'
                className={groupButton ? 'me-5 active' : 'me-5'}
                onClick={() => {
                  setGroupButton(true)
                  onClickMonthAchievers()
                }}
              >
                All
              </Button>
              <Button
                variant='link'
                className={!groupButton ? 'active' : ''}
                onClick={() => {
                  setGroupButton(false)
                  onClickYearAchievers()
                }}
              >
                My Groups
              </Button>
            </div>
          </div>
          {/* end:header */}
          {/* begin:member listing */}
          <div className='all bigi-members'>{allMemberBusinessPerformanceList}</div>
          {/* end:member listing */}
        </div>
        {/* end:BI Members */}
        <div className='tab-pane fade show active' id='kt_tab_pane_5' role='tabpanel'>
          {/* begin:header */}
          <div className='d-flex align-items-center justify-content-between'>
            <h3 className='fw-bolder fs-6'>Star Member Leaders</h3>
            <div>
              <Button
                variant='link'
                className={groupButton ? 'me-5 active' : 'me-5'}
                onClick={() => {
                  setGroupButton(true)
                  onClickMonthAchievers()
                }}
              >
                All
              </Button>
              <Button
                variant='link'
                className={!groupButton ? 'active' : ''}
                onClick={() => {
                  setGroupButton(false)
                  onClickYearAchievers()
                }}
              >
                My Groups
              </Button>
            </div>
          </div>
          {/* end:header */}
          {/* begin:member listing */}

          <div className='all bigi-members'>
            {/* begin:member box */}
            {allMemberPerformanceList}
            {/* end:member box */}
          </div>
          {/* end:member listing */}
        </div>
      </div>
    </div>
  )
}
