import { useEffect, useMemo, useState } from 'react'
import { useTable, ColumnInstance, Row } from 'react-table'
import { CustomHeaderColumn } from './columns/CustomHeaderColumn'
import { CustomRow } from './columns/CustomRow'
import { useQueryResponseData, useQueryResponseLoading } from '../core/QueryResponseProvider'
import { notificationsColumns } from './columns/_columns'
import { Notification } from '../core/_models'
import { NotificationsListLoading } from '../components/loading/NotificationsListLoading'
import { NotificationsListPagination } from '../components/pagination/NotificationsListPagination'
import { KTCardBody } from '../../../../../../_metronic/helpers'

const CopyNotificationsTable = () => {
  const notifications = useQueryResponseData()
  // const notifications = defaultNotifications
  const isLoading = useQueryResponseLoading()
  const data = useMemo(() => notifications, [notifications])
  const columns = useMemo(() => notificationsColumns, [])
  const { getTableProps, getTableBodyProps, headers, rows, prepareRow } = useTable({
    columns,
    data,
  })

  return (
    <table
      id='copy_kt_table_notifications'
      className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
      {...getTableProps()}
      style={{ display: 'none' }}
    >
      <thead>
        <tr className='text-center text-muted fw-bolder fs-7 text-uppercase gs-0 border-bottom border-gray-100'>
          {headers.map((column: ColumnInstance<Notification>) => (
            <CustomHeaderColumn key={column.id} column={column} />
          ))}
        </tr>
      </thead>

      <tbody className='text-gray-600 text-center fw-bold' {...getTableBodyProps()}>
        {rows.length > 0 ? (
          rows.map((row: Row<Notification>, i) => {
            prepareRow(row)
            return <CustomRow row={row} key={`row-${i}-${row.id}`} />
          })
        ) : (
          <tr>
            <td colSpan={7}>
              <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                No matching records found
              </div>
            </td>
          </tr>
        )}
      </tbody>
    </table>
  )
}

export { CopyNotificationsTable }
