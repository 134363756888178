import React, {useContext, useEffect, useState} from 'react'
import {Tab, Table, Tabs} from 'react-bootstrap'
import {allActivityPoint} from './_requests'
import {UserContext} from '../../contextStore'
import './demo.css'
import moment from 'moment'

export function ActivityPointsModal() {
  const [activityPointData, setActivityPointData] = useState<any>({data: []})
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const {userDetail} = useContext(UserContext)

  const currentMonth = moment().format('MMM YY')
  const currentYear = moment().format('YYYY')
  useEffect(() => {
    fetchData()
  }, [])

  const fetchData = async () => {
    try {
      const allPointDataList = await allActivityPoint(userDetail.id)
      setActivityPointData(() => allPointDataList ?? {data: []})
      setIsLoading(false)
    } catch (error) {
      console.error('Error -->:', error)
      setIsLoading(false)
    }
  }
  return (
    <>
      <div
        className='modal fade ilead-default-modal'
        id='ActivityPointsModal'
        aria-hidden='true'
        aria-labelledby='ActivityPointsModalLabel'
      >
        <div className='modal-dialog modal-md modal-dialog-centered'>
          <div className='modal-content'>
            <div className='modal-header py-5'>
              <h3 className='modal-title fs-3 fw-bold' id='ActivityPointsModalToggleLabel'>
                Activity Points
              </h3>
              <button
                type='button'
                className='btn-close text-white'
                data-bs-target='#ActivityPointsModal'
                data-bs-toggle='modal'
                data-bs-dismiss='modal'
              ></button>
            </div>
            {/* GI DATA */}
            <div className='modal-body pt-7 write-post-wrapper'>
              <Tabs defaultActiveKey='gi' id='activity-tab' className='mb-3 custom-tab'>
                <Tab eventKey='gi' title={`GI ${currentMonth} : ${activityPointData.total}`}>
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th>Activity Name</th>
                        <th className='text-center'>Points</th>
                        <th className='text-center'>Activities</th>
                      </tr>
                    </thead>
                    <tbody>
                      {activityPointData?.data
                        .filter((item: any) => item.board_flag === 'GI')
                        .map((item: any, index: any) => (
                          <tr key={index}>
                            <td>{item.displayname}</td>
                            <td className='text-center'>{item.value}</td>
                            <td className='text-center'>{item.activity}</td>
                          </tr>
                        ))}
                    </tbody>
                  </Table>
                </Tab>

                {/* BI DATA */}
                <Tab
                  eventKey='bi'
                  title={`BI ${currentYear} : ${activityPointData.bi_total ?? 0}`}
                  className='text-dark'
                >
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th>Activity Name</th>
                        <th className='text-center'>Points</th>
                        <th className='text-center'>Activities</th>
                      </tr>
                    </thead>
                    <tbody>
                      {activityPointData?.data
                        .filter((item: any) => item.board_flag === 'BI')
                        .map((item: any, index: any) => (
                          <tr key={index}>
                            <td>{item.displayname}</td>
                            <td className='text-center'>{item.value}</td>
                            <td className='text-center'>{item.activity}</td>
                          </tr>
                        ))}
                    </tbody>
                  </Table>
                </Tab>
              </Tabs>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
