import {ListViewProvider, useListView} from './core/ListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
import {VisitorsListHeader} from './components/header/VisitorsListHeader'
import {VisitorsTable} from './table/VisitorsTable'
 import {VisitorEditModal} from './visitor-edit-modal/VisitorEditModal'
import { KTCard } from '../../../../_metronic/helpers'
// import {KTCard} from '../../../../../_metronic/helpers'

const VisitorsList = () => {
  const {itemIdForUpdate} = useListView()
  return (
    <>
      
        {/* <VisitorsListHeader /> */}
        <VisitorsTable />
      
      {itemIdForUpdate !== undefined && <VisitorEditModal />}
    </>
  )
}

const VisitorsListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <VisitorsList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {VisitorsListWrapper}
