import React, {useContext, useEffect, useRef, useState} from 'react'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {Button, Image, Modal, Form, Tooltip, OverlayTrigger} from 'react-bootstrap'
// import {Button, Image ,Modal} from 'react-bootstrap'
import {apiUrl} from '../../../apiurl'
import {checkDisplayValue, getFileUrl} from '../../../utils'
import axios from 'axios'
import moment from 'moment'
import * as Yup from 'yup'
import {UserContext} from '../../../contextStore'
import {GroupListAPI, TycaQuestionsPost, addGroupDetails, deleteTycaPdfFile, userProfileDetials} from './_request'
import Webcam from 'react-webcam'
import {RWebShare} from 'react-web-share'
import AddTycaPresentation from './AddTycaPresentation'
import {accessUser} from '../../../utils/privileges'
import { useFormik } from 'formik'

const API_URL = apiUrl
const TYCA_URL = `${API_URL}/userpresentations`
const IMAGE_UPLOAD = `${API_URL}/containers/topicimages/upload`
const TYCA_URL_Post = `${API_URL}/tycaquestions`

const TycaPresentation = () => {
  const [data, setData] = useState<any>([])
  const [eventData, setEventData] = useState<any>([])
  const [isLoading, setIsLoading] = useState<any>(true)
  const [showDetailTyca, setShowDetailTyca] = useState<any>('')
  const {userDetail, setMessage, reload, chapterID} = useContext(UserContext)
  const [filter, setFilter] = useState<any>()
  const [filterDate, setFilterDate] = useState<any>()
  const tycasuperadmin = accessUser.tycasuperadmin
  const chapterId = chapterID ?? ''
  const group_id = userDetail?.group_id ?? ''
  const userId = userDetail?.id ?? ''
  const roleId = userDetail?.role_id ?? ''
  const [uploadPopup, setUploadPopup] = useState<any>(false)
  const [image, setImage] = useState<any>(null)
  const [imageName, setImageName] = useState<any>(null)
  const [tyca_id, setTyca_id] = useState<any>(null)
  const [showWebcam, setShowWebcam] = useState(false)
  const [webcamAvailable, setWebcamAvailable] = useState<boolean>(false)
  const webcamRef = useRef<Webcam | null>(null)
  const [webCamImageFor, setWebCamImageFor] = useState<string>('')
  const [canTYCA, setCanTYCA] = useState<boolean>(false)
  const [updataProfileLoading, setupdataProfileLoading] = useState<boolean>(false)
  const FACING_MODE_USER = 'user'
  const FACING_MODE_ENVIRONMENT = 'environment'
  const [facingMode, setFacingMode] = React.useState(FACING_MODE_USER)
  const [editTyca, setEditTyca] = useState<any>('')

  const [deleteModal, setDeleteModal] = useState<boolean>(false)
  const [deletetId, setDeletetId] = useState<string>('')

  const [isShowUserManagement, setIsShowTycaSuperAdminManagement] = useState(false)

  const [grouptData, setGroupData] = useState<any>([])
  const [fileForAdmin, setFileForAdmin] = useState<any>(null)
  const [fileNameForAdmin, setFileNameForAdmin ] = useState<any>(null)
  const [seletedGrpId, setSeletedGrpId] = useState<string>('')

  useEffect(() => {
    fetchData()
  }, [filter, filterDate, reload])

  // useEffect(() => {
  //   fetchData()
  // }, [filter, filterDate])

  useEffect(() => {
    eventDate()
    fetchDatauser()
    fetchGroup()
  }, [])

  useEffect(() => {
    if (tycasuperadmin.includes(userId)) {
      setIsShowTycaSuperAdminManagement(true)
    }
  }, [])

  const fetchData = async () => {
    try {
      const res = await axios.get(
        `${TYCA_URL}/alltyca?filter[where][chapter_id]=${chapterId}${
          filter ? `&filter[where][type]=${filter}` : ''
        }${filterDate ? `&filter[where][eventId]=${filterDate}` : ''}`
      )
      setData(res?.data ?? [])
      setIsLoading(false)
    } catch (error) {
      console.error('Error -->:', error)
      setIsLoading(false)
    }
  }

  const fetchDatauser = async () => {
    try {
      const res = await userProfileDetials(userId)
      // setData(res?.data ?? [])
      setCanTYCA(res?.data?.canTYCA)
      // setIsLoading(false)
    } catch (error) {
      // setIsLoading(false)
    }
  }

  const eventDate = async () => {
    try {
      const res = await axios.get(`${API_URL}/eventdates?filter[where][chapter_id]=${chapterId}`)
      setEventData(res?.data ?? [])
      setIsLoading(false)
    } catch (error) {
      console.error('Error -->:', error)
      setIsLoading(false)
    }
  }

  const handleButtonClick = async (pdf: any) => {
    if (pdf) {
      let url = await getFileUrl(`${API_URL}/containers/topicimages/download/${pdf}`,`${API_URL}/containers/public/download/${pdf}` as any) as any
      window.open(url, '_blank')
    }
  }

  const handleReload = () => {
    fetchData()
  }
  const submitHandler = async () => {
    if (image == null) {
      setMessage('error', 'Please select the image/file')
      return
    }
    try {
      const formData = new FormData()
      imageName.name
        ? formData.append('file', imageName)
        : formData.append('file', imageName, 'webcom.png')
      // formData.append('file', imageName, 'webcom.png')

      const response = await axios
        .post(`${IMAGE_UPLOAD}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then(async (res: any) => {
          const FileName = res?.data?.result?.files?.file[0]?.name
          const data = {
            tyca_id: tyca_id,
            // pdf: 'ILead.pdf',
            pdf: FileName,
            group_id: group_id,
            created_by: userId,
          }
          const response = await TycaQuestionsPost(data)
            .then(async (res: any) => {
              setUploadPopup(false)
              setImage(null)
              setImageName(null)
              fetchData()
              setMessage('success', 'TYCA Question Upload Successfully')
            })
            .catch((error: any) => {
              setMessage('error', 'Something Went Wrong')
            })
        })
        .catch((error: any) => {
          setMessage('error', 'Something Went Wrong')
        })
    } catch (error: any) {
      setMessage('error', 'Something Went Wrong')
    }
  }

  //
  const onChangeHandler = (event: any) => {
    const file = event.target.files[0]
    if (file) {
      var pattern = /image-*/
      // setSelectedImage(file.name)
      if (!file.type.match(pattern) && file.type !== 'application/pdf') {
        setMessage('error', 'Invalid format, only Images and Pdf !')
        return
      }
      setImageName(file)
      setImage(URL.createObjectURL(event.target.files[0]))

      
    } else {
      setImageName(null)
      setImageName(null)
    }
  }

  

  const checkCameraPermission = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({video: true})
      setWebcamAvailable(true)
      stream.getTracks().forEach((track) => track.stop())
    } catch (error) {
      setWebcamAvailable(false)
    }
  }

  const toggleWebcam = async () => {
    if (!webcamAvailable) {
      await checkCameraPermission() // Check camera permission when the camera button is clicked
      setShowWebcam(true)
    } else {
      setShowWebcam(true)
    }
  }

  const captureImage = async (fileOf: any) => {
    if (webcamRef.current) {
      const imageSrc = webcamRef.current.getScreenshot()
      setImage(imageSrc)
      await uploadImage(imageSrc)
    }
    setShowWebcam(false)
  }
  const toggleCameraFacingMode = () => {
    setFacingMode((prevFacingMode) =>
      prevFacingMode === FACING_MODE_USER ? FACING_MODE_ENVIRONMENT : FACING_MODE_USER
    )
  }

  const uploadImage = async (imageData: any) => {
    try {
      if (!imageData) {
        console.error('No image captured')
        return
      }

      const img = new window.Image()
      // const img = new Image()
      img.src = imageData

      await new Promise((resolve) => (img.onload = resolve))

      const canvas = document.createElement('canvas')
      canvas.width = img.width
      canvas.height = img.height

      const ctx = canvas.getContext('2d')
      if (ctx) {
        ctx.drawImage(img, 0, 0)
      } else {
        console.error('Canvas context is null')
        return
      }

      const jpgDataUrl = canvas.toDataURL('image/jpeg', 1)
      // Convert the data URL back to a Blob
      const byteCharacters = atob(imageData.split(',')[1])
      const byteArrays = new Uint8Array(byteCharacters.length)
      for (let i = 0; i < byteCharacters.length; i++) {
        byteArrays[i] = byteCharacters.charCodeAt(i)
      }
      const jpgBlob = new Blob([byteArrays], {type: 'image/jpeg'})

      setImageName(jpgBlob)
    } catch (error) {
      setMessage('error', 'Something Went Wrong')
    }
  }

  // visit Grp Details 
  const InitDataAddGroups={
    groupvisittime : '',
    groupvisitaddress: '',
    groupvisitaddresslink:"",
    id:""
  }

  const addGroupDetailsSchema = Yup.object().shape({
    groupvisittime: Yup.string(),
    groupvisitaddress : Yup.string(),
    groupvisitaddresslink:  Yup.string()
    .matches(
      /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
      'Please enter a valid URL.'
    ),
    id:Yup.string(),
    
  })

  // visit Grp Details  formik 

  const formik = useFormik({
    initialValues: InitDataAddGroups,
    validationSchema: addGroupDetailsSchema,
    onSubmit: async (values, {setSubmitting,resetForm}) => {
      setSubmitting(true)
      try {
        console.log("values ",values )

        await addGroupDetails({...values,id:showDetailTyca})
        .then(async (res: any) => {
          if (res) {
            await fetchData()
            setMessage('success', 'Visit details updated.')
            setShowDetailTyca('')
            resetForm()
            setSubmitting(false)
            setSubmitting(true)
          }
        })
        .catch((error: any) => {
          if (error?.response?.data?.error?.message) {
            setMessage('error', error?.response?.data?.error?.message)
          } else {
            setMessage('error', 'Something Went Wrong')
          }

        })
        
          // await eventPastCreate(values).then((res:any)=>{
          //   console.log("res",res?.data)
          //   if(res?.data?.id ){
          //     resetForm()
          //     refetch()
          //     // navigate('/visitor-panel')
          //     closeButton.current.click()
          //     setMessage('success', 'Invitations send successfully')
          //     // handleClose()
          //   }
          // }).catch((error:any)=>{
          //   console.log("error",error)
          //   if (error?.response?.data?.error?.message) {
          //     console.log("error?.response?",error?.response?.status)
          //     if(error?.response?.status == 500 )
          //     {
          //     setMessage('error', 'Something went wrong')
          //     }else{

          //       setMessage('error', error?.response?.data?.error?.message)
          //     }
          //   } else {
          //     setMessage('error', 'Something went wrong')
          //   }
          // })
        
        
      } catch (ex) {
        console.error(ex)
      } finally {
        setSubmitting(false)
        // cancel(true)
      }
    },
  })


  const  fetchGroup= async () => {
    try {
      const responce = await GroupListAPI("5","")

      if (responce) {
        setGroupData(responce?.data?.data ?? [])
      }

    } catch (error) {
      console.error("error", error)
    }
  }

  const adminseleteFilehandler = (event: any) => {
    const file = event.target.files[0]
    if (file) {
      var pattern = /image-*/
      // setSelectedImage(file.name)
      if (!file.type.match(pattern) && file.type !== 'application/pdf') {
        setMessage('error', 'Invalid format, only Images and Pdf !')
        return
      }
      setFileNameForAdmin(file)
      // formikforUploadAdmin.setFieldValue("pdf",file?.name)
      setFileForAdmin(URL.createObjectURL(event.target.files[0]))
      
    } else {
      setFileNameForAdmin(null)
      setFileForAdmin(null)
    }
  }

console.log("fileNameForAdmin",fileNameForAdmin)
console.log("fileForAdmin",fileForAdmin)
const adminUploadFile = async (tycaId:any ,groupId:any) => {
  if ( !fileNameForAdmin && fileNameForAdmin == null) {
    setMessage('error', 'Please select the image/file')
    return
  }
  if ( !groupId && groupId == null) {
    setMessage('error', 'Please select the image/file')
    return
  }
  try {
    const formData = new FormData()
    // fileNameForAdmin?.name
    formData.append('file', fileNameForAdmin)
      
    // formData.append('file', imageName, 'webcom.png')

    const response = await axios
      .post(`${IMAGE_UPLOAD}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(async (res: any) => {
        const FileName = res?.data?.result?.files?.file[0]?.name
        const data = {
          tyca_id: tycaId,
          // pdf: 'ILead.pdf',
          pdf: FileName,
          group_id: groupId,
          created_by: userId,
        }
        const response = await TycaQuestionsPost(data)
          .then(async (res: any) => {
            setUploadPopup(false)
            setImage(null)
            setImageName(null)
            fetchData()
            setMessage('success', 'TYCA Question Upload Successfully')
          })
          .catch((error: any) => {
            setMessage('error', 'Something Went Wrong')
          })
      })
      .catch((error: any) => {
        setMessage('error', 'Something Went Wrong')
      })
  } catch (error: any) {
    setMessage('error', 'Something Went Wrong')
  }
}
  

  const styles = {
    borderRadius: '0.475rem',
    boxShadow: '0 0 50px 0 rgb(82 63 105 / 15%)',
    backgroundColor: '#fff',
    color: '#7e8299',
    fontWeight: '500',
    margin: '0',
    width: 'auto',
    padding: '1rem 2rem',
    top: 'calc(50% - 2rem)',
    left: 'calc(50% - 4rem)',
  }

  if (isLoading) {
    return <div style={{...styles, position: 'absolute', textAlign: 'center'}}>Processing...</div>
  }

  // const fileuploadAmin={
  //   group_id : '',
  //   pdf: ''
  // }
  // const fileuploadAminSchema = Yup.object().shape({
  //   group_id: Yup.string().required("Required"),
  //   pdf : Yup.string(),
  // })

  // const formikforUploadAdmin = useFormik({
  //   initialValues: {group_id:"",pdf:""},
  //   validationSchema: fileuploadAminSchema,
  //   onSubmit: async (values, {setSubmitting,resetForm}) => {
  //     setSubmitting(true)
  //     try {
  //       console.log("values ",values )

  //       // await addGroupDetails({...values,id:showDetailTyca})
  //       // .then(async (res: any) => {
  //       //   if (res) {
  //       //     await fetchData()
  //       //     setMessage('success', 'Visit details updated.')
  //       //     setShowDetailTyca('')
  //       //     resetForm()
  //       //     setSubmitting(false)
  //       //     setSubmitting(true)
  //       //   }
  //       // })
  //       // .catch((error: any) => {
  //       //   if (error?.response?.data?.error?.message) {
  //       //     setMessage('error', error?.response?.data?.error?.message)
  //       //   } else {
  //       //     setMessage('error', 'Something Went Wrong')
  //       //   }

  //       // })
        
        
  //     } catch (ex) {
  //       console.error(ex)
  //     } finally {
  //       setSubmitting(false)
  //       // cancel(true)
  //     }
  //   },
  // })

  
  const deleteTycaPdf = async (id: any) => {
    const deleteData = {
      id: id,
    }
    try {
      const responce = await deleteTycaPdfFile(deleteData)
      if (responce) {
        fetchData()
        setMessage('success', 'TYCA Question Delete Successfully')
      }
    } catch (error) {
      setMessage('error', 'Something Went Wrong')
    }
  }
  console.log('--sssss---')
  console.log('--sssss---', moment('2019-03-01T00:00:00.000Z').utc())
  console.log('--sssss---', moment().isAfter(moment('2019-03-01T00:00:00.000Z').utc()))
  console.log('--sssss---', moment('2019-03-01T00:00:00.000Z').utc() > moment() ? '' : '')
  return (
    <>
      <div className='d-flex flex-column flex-md-row justify-content-between align-items-center border-bottom border-secondary pb-5'>
        <h1 className='inv-hst mb-3 mb-md-0'>TYCA Presentation</h1>
        <div className='d-flex flex-wrap  top__filter'>
          <div className='form-group floating-labels me-3 flex-1'>
            <select
              className='form-select border-top-0 h-100 border-left-0 border-right-0 border-bottom rounded-0'
              aria-label='Default select example'
              value={filter}
              onChange={(e) => setFilter(e.target.value)}
              style={{width: '155px'}}
            >
              <option> Upcomming </option>
              <option value='all'> All </option>
            </select>
          </div>
          <div className='input-group search__input__group flex-1 me-3 tyca-filter' style={{ maxWidth: "155px" }}>
            <select
              className='form-select border-top-0 border-left-0 border-right-0 border-bottom rounded-0'
              aria-label='.form-select-sm example'
              value={filterDate}
              onChange={(e) => setFilterDate(e.target.value)}
            >
              <option value=''>All</option>
              {eventData?.map((item: any) => (
                <option value={item?.event_date}>
                  {checkDisplayValue(moment(item?.event_date).format('MMM DD, YYYY'))}
                </option>
              ))}
            </select>
          </div>
          {isShowUserManagement ? (
            <div className=' flex-1 text-start flex-sm-end  me-sm-0 me-3  mt-3 mt-sm-0 text-nowrap '>
              <button data-bs-toggle="modal" data-bs-target="#addtyca" className="btn btn-primary h-100 btn-sm py-4 py-sm-0 waves-effect waves-light "> Add New Tyca </button>
            </div>
          ) : null}

          {/* <div className=' flex-1 text-end mt-sm-0 mt-5 me-sm-0 me-3 '>
            <button
              data-bs-toggle='modal'
              data-bs-target='#addtyca'
              className='btn btn-primary btn-sm waves-effect waves-light '
            >
              {' '}
              Add New Tyca{' '}
            </button>
          </div> */}
        </div>
      </div>

      {/* <h1 className='inv-hst mb-3 text-primary'>TYCA Presentation</h1>
      <div className='card mob-vie-card tyca-hdr'>
        <div className='row  mx-0 mt-5 justify-content-between tyca-hdr'>
          <div className='col-md-4 mb-3'>
            <div className='card rounded-0 py-2'>
              <div className='form-group floating-labels'>
                <select
                  className='form-select border-0 rounded-0'
                  aria-label='Default select example'
                  value={filter}
                  onChange={(e) => setFilter(e.target.value)}
                >
                  <option> Upcomming Presentation </option>
                  <option value='all'> All Presentation </option>
                </select>
              </div>
            </div>
          </div>

          <div className='col-md-6 mb-3'>
            <div className='filter-div row tyca-filter mx-0'>
              <div className='col-md-4'>
                {' '}
                <h4 className='text-dark fw-normal'> Filter By Date</h4>{' '}
              </div>
              <div className='col-md-8 card rounded-0 py-3'>
                <select
                  className='form-select border-0 form-select-sm pt-1 pb-0'
                  aria-label='.form-select-sm example'
                  value={filterDate}
                  onChange={(e) => setFilterDate(e.target.value)}
                >
                  <option value=''>All</option>
                  {eventData.map((item: any) => (
                    <option value={item?.event_date}>
                      {checkDisplayValue(moment(item?.event_date).format('MMM DD, YYYY'))}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      {!data[0] ? (
        <div className='row mx-0 no-presentation'>
          <div className='col-sm-12   '>
            <div className='my-6 rounded-3 px-6 py-8'>
              <h3 className='fs-4 text-center text-dark'> There are no TYCA presentation ! </h3>
            </div>
          </div>
        </div>
      ) : (
        <div className='row mt-5 mb-5'>
          {data?.map((item: any) => (
            <div className='col-md-6 col-lg-6 mb-4'>
              <div className='card ilead__card h-100'>
                <div className='card-body pb-3'>
                  {/* TYCA INFORMATION */}
                  <div className='presentation_details border-bottom pb-4 d-flex justify-content-between align-items-center'>
                    <h3 className='fs-4 pe-4 mb-0' style={{lineHeight: '1.5'}}>
                      {' '}
                      {item?.title ? checkDisplayValue(item?.title) : ''}{' '}
                    </h3>
                    <div className='d-flex flex-nowrap'>
                      {item?.pdf && (
                        <OverlayTrigger
                          placement='top'
                          overlay={
                            <Tooltip className='tooltop-auto-width text-black'>
                              View TYCA Presentation
                            </Tooltip>
                          }
                        >
                          <div
                            className='symbol symbol-circle symbol-40px cursor-pointer hover-scale'
                            onClick={() => handleButtonClick(item?.pdf)}
                          >
                            <div className='symbol-label bg-info'>
                              <i className='bi bi-file-pdf text-white fs-4'></i>
                            </div>
                          </div>
                        </OverlayTrigger>
                      )}
                      { isShowUserManagement ?  (item?.pdf &&  moment(item?.success_date) >= moment()) && (<OverlayTrigger
                            placement='top'
                            overlay={
                              <Tooltip className='tooltop-auto-width text-black'>
                                Upload TYCA Presentation
                              </Tooltip>
                            }
                          >
                            <div
                              className='symbol symbol-circle symbol-40px cursor-pointer hover-scale ms-3'
                              onClick={() => {
                                setUploadPopup(true)
                                setTyca_id(item?.id)
                              }}
                            >
                              <div className='symbol-label bg-blue'>
                                <i className='bi-cloud-arrow-up text-white fs-4'></i>
                              </div>
                            </div>
                          </OverlayTrigger>)  : 
                      ( 
                        (item?.pdf &&
                        (item?.iscaptain == true ) &&
                        (item?.tycaquestion?.filter((item: any) => item?.group_id == group_id)
                          ?.length > 0 ) ? (
                          <></>
                        ) : (
                            <OverlayTrigger
                            placement='top'
                            overlay={
                              <Tooltip className='tooltop-auto-width text-black'>
                                Upload TYCA Presentation
                              </Tooltip>
                            }
                          >
                            <div
                              className='symbol symbol-circle symbol-40px cursor-pointer hover-scale ms-3'
                              onClick={() => {
                                setUploadPopup(true)
                                setTyca_id(item?.id)
                              }}
                            >
                              <div className='symbol-label bg-blue'>
                                <i className='bi-cloud-arrow-up text-white fs-4'></i>
                              </div>
                            </div>
                          </OverlayTrigger>
                        ))
                      ) 
                        }
                      {/* {item?.pdf &&
                        item?.iscaptain === true &&
                        (item?.tycaquestion?.filter((item: any) => item?.group_id == group_id)
                          ?.length > 0 ? (
                          ''
                        ) : (
                          <OverlayTrigger
                            placement='top'
                            overlay={
                              <Tooltip className='tooltop-auto-width text-black'>
                                Upload TYCA Presentation
                              </Tooltip>
                            }
                          >
                            <div
                              className='symbol symbol-circle symbol-40px cursor-pointer hover-scale ms-3'
                              onClick={() => {
                                setUploadPopup(true)
                                setTyca_id(item?.id)
                              }}
                            >
                              <div className='symbol-label bg-blue'>
                                <i className='bi-cloud-arrow-up text-white fs-4'></i>
                              </div>
                            </div>
                          </OverlayTrigger>
                        ))} */}
                      {isShowUserManagement &&
                        moment(item?.success_date) >= moment()
                        &&(
                          <OverlayTrigger
                            placement='top'
                            overlay={
                              <Tooltip className='tooltop-auto-width text-black'>
                                Edit TYCA Presentation
                              </Tooltip>
                            }
                          >
                            <div
                              className='symbol symbol-circle symbol-40px cursor-pointer hover-scale ms-3'

                            >
                              <button style={{ border: 'none', background: 'none' }} data-bs-toggle="modal" data-bs-target={`#addtyca`} onClick={() => {
                                setEditTyca(item?.id)
                              }}>
                                <div className='symbol-label bg-blue'>
                                  <i className='bi bi-pencil-square text-white fs-4'></i>
                                </div>
                              </button>
                            </div>
                          </OverlayTrigger>
                        )}
                    </div>
                  </div>
                  {/* TYCA POSTED USER DETAILS */}
                  <div className='py-4 d-flex border-bottom'>
                    <div className='symbol symbol-70px bg-white border-dark'>
                      <img
                        src={
                          item?.user?.profile
                            ? `${API_URL}/containers/userprofile/download/${item?.user?.profile}`
                            : `${toAbsoluteUrl('./media/avatars/default-user.png')}`
                        }
                        onError={({currentTarget}: any) => {
                          currentTarget.onerror = null // prevents looping
                          currentTarget.src = `${toAbsoluteUrl('./media/avatars/default-user.png')}`
                        }}
                        alt={item?.user?.name ? checkDisplayValue(item?.user?.name) : ''}
                      />
                    </div>
                    <div className='flex-1 ps-4 pt-1 d-flex text-truncate justify-content-between align-items-center'>
                      <div className='text-truncate pe-3'>
                        <h2 className='fs-6 text-dark leader__name'>
                          {item?.user?.name ? checkDisplayValue(item?.user?.name) : ''}
                        </h2>
                        <span
                          className='leader__company text-truncate text-black d-block'
                          style={{fontWeight: '400'}}
                        >
                          {item?.user?.companyname
                            ? checkDisplayValue(item?.user?.companyname)
                            : ''}
                        </span>
                        <span
                          className='business_category d-block text-black-50 text-truncate'
                          style={{fontWeight: '400'}}
                        >
                          {item?.user?.business_category
                            ? checkDisplayValue(item?.user?.business_category)
                            : ''}
                        </span>
                      </div>
                      {item?.user?.website?.length > 0 ? (
                        <>
                          <a
                            href={item?.user?.website ? checkDisplayValue(item?.user?.website) : ''}
                            target='_blank'
                            className='symbol symbol-40px leader__email hover-scale'
                          >
                            <div className='symbol-label fs-2 fw-semibold'>
                              <i className='bi bi-globe2' style={{fontSize: '16px'}}></i>
                            </div>
                          </a>
                        </>
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                  {/* TYCA INFORMATION */}

                   {/* TYCA Visit Details */}
                  <div className='border-bottom border-gray-500 py-3'>
                    { (item?.available_time_slot ||
                      item?.addressforvisit ||
                      item?.googlemaplink )&& (showDetailTyca != item?.id)  && (
                        <div>
                      <p className='fw-semibold underline'> <u>Group Visit Details </u>
                      {isShowUserManagement && (moment().isBefore(moment(item?.success_date))) && (<button className="addtimeslotbutton" id="addtimeslotbutton67"
                      onClick={() => {
                        setShowDetailTyca(item?.id)
                        formik.setFieldValue('groupvisittime',item?.available_time_slot ?  item?.available_time_slot :"")
                        formik.setFieldValue('groupvisitaddress', item?.addressforvisit ?  item?.addressforvisit :"")
                        formik.setFieldValue('groupvisitaddresslink', item?.googlemaplink ?  item?.googlemaplink :"")
                      }}
                      data-id="67" style={{color: 'rgb(187, 187, 187)' ,  backgroundColor: "white" ,  border: "none" , display: "inline-block"}}>
                        <i className="fa fa-pencil"></i></button>)}
                      </p>
                      <p>Date And Time:{' '} {item?.available_time_slot ? item?.available_time_slot : " - "} </p>
                      <p>Address: {' '} {item?.addressforvisit ? item?.addressforvisit : " - "}</p>
                      {/* <p>Address: {item?.googlemaplink ? item?.googlemaplink : " - "}</p> */}
                      <p style={{wordBreak: 'break-all'}}>
                        
                        <a target='_blank' href={item?.googlemaplink ? item?.googlemaplink : "#"}>
                          <span className='marker-tyca px-2 py-1  rounded-circle me-5 bg-primary'>
                            <i className='bi bi-geo-alt-fill text-white'></i>
                          </span>
                          <span style={{color: '#F26522'}}>Directions</span>
                        </a>
                      </p>
                    </div>
                      )}
                    

                    { isShowUserManagement && !item?.available_time_slot &&
                      !item?.addressforvisit &&
                      !item?.googlemaplink &&
                      showDetailTyca != item?.id && (moment().isBefore(moment(item?.success_date))) && (
                        <h3
                          className='fs-5 text-primary text-center py-5 text-underline fw-normal'
                          onClick={() => {
                            setShowDetailTyca(item?.id)
                            formik.setFieldValue('groupvisittime',item?.available_time_slot ?  item?.available_time_slot :"")
                            formik.setFieldValue('groupvisitaddress', item?.addressforvisit ?  item?.addressforvisit :"")
                            formik.setFieldValue('groupvisitaddresslink', item?.googlemaplink ?  item?.googlemaplink :"")
                          }}
                        >
                          Add Group Visit Details
                        </h3>
                      )}
                    {showDetailTyca == item?.id && (
                      <form    onSubmit={formik.handleSubmit} noValidate>
                        <div className='form-floating mb-3'>
                          <input
                            type='text'
                            className='form-control'
                            placeholder='Leave a comment here'
                            id='floatingTextarea'
                            required
                            value={formik?.values?.groupvisittime}
                            onChange={(event: any) => {
                              formik.setFieldValue('groupvisittime', event.target.value.trimStart())
                            }}
                          />
                          <label>
                            {' '}
                            Enter Date And Time For Visit Here{' '}
                            {/* <span className='text-danger'> * </span>{' '} */}
                          </label>
                          {formik.touched.groupvisittime && formik?.errors.groupvisittime && (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>
                                <span role='alert'>{formik.errors?.groupvisittime}</span>
                              </div>
                            </div>
                          )}
                        </div>

                        <div className='form-floating mb-3'>
                          <input
                            type='text'
                            className='form-control'
                            placeholder='Leave a comment here'
                            id='floatingTextarea'
                            required
                            value={formik?.values?.groupvisitaddress}
                            onChange={(event: any) => {
                              formik.setFieldValue('groupvisitaddress', event.target.value.trimStart())
                            }}
                          />
                          <label>
                            {' '}
                            Enter Address For Personal Visit Here{' '}
                            {/* <span className='text-danger'> * </span>{' '} */}
                          </label>
                          {formik.touched.groupvisitaddress && formik?.errors.groupvisitaddress && (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>
                                <span role='alert'>{formik.errors?.groupvisitaddress}</span>
                              </div>
                            </div>
                          )}
                        </div>

                        <div className='form-floating mb-3'>
                          <input
                            type='text'
                            className='form-control'
                            placeholder='Leave a comment here'
                            id='floatingTextarea'
                            required
                            value={formik?.values?.groupvisitaddresslink}
                            onChange={(event: any) => {
                              formik.setFieldValue('groupvisitaddresslink', event.target.value.trimStart())
                            }}
                          />
                          <label>
                            {' '}
                            Enter Google Map Link for Address Here{' '}
                            {/* <span className='text-danger'> * </span>{' '} */}
                          </label>
                          {formik.touched.groupvisitaddresslink && formik?.errors.groupvisitaddresslink && (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>
                                <span role='alert'>{formik.errors?.groupvisitaddresslink}</span>
                              </div>
                            </div>
                          )}
                        </div>

                        <div d-flex>
                          <button  disabled={formik.isSubmitting}className='btn btn-primary h-100 btn-sm waves-effect waves-light m-1'  >
                            {' '}
                            Submit{' '}
                          </button>
                          {/* <input type="submit"  className='btn btn-primary h-100 btn-sm waves-effect waves-light m-1'  ></input> */}
                          <button  disabled={formik.isSubmitting}
                            className='btn btn-light-secondary btn-sm waves-effect text-dark waves-light m-1'
                            onClick={() => {
                              setShowDetailTyca(false)
                            }}
                          >
                            {' '}
                            Cancel{' '}
                          </button>
                        </div>
                      </form>
                    )}
                  </div>
                  {/* TYCA Visit Details end  */} 
                  {/*  */}

                  <div className='row py-4'>
                    <div className='col-3'>
                      {/* <OverlayTrigger
                        placement="bottom"
                        overlay={<Tooltip className='tooltop-auto-width text-black'>Upload TYCA Presentation</Tooltip>}
                      > */}
                      <div className='d-flex flex-column align-items-center'>
                        <div className='symbol symbol-40px symbol-circle'>
                          <div
                            className={`symbol-label fs-2 fw-semibold ${
                              item?.accept_date &&
                              (moment().isBefore(moment(item?.accept_date))
                                ? 'bg-primary'
                                : 'bg-light-dark')
                            }`}
                          >
                            <i
                              className={`fa-regular fa-handshake ${
                                item?.accept_date &&
                                (moment().isBefore(moment(item?.accept_date))
                                  ? 'text-white'
                                  : 'text-dark')
                              }`}
                            ></i>
                          </div>
                        </div>
                        <span
                          className='text-black d-block mt-3 text-center'
                          style={{fontWeight: '400'}}
                        >
                          {item?.accept_date
                            ? checkDisplayValue(moment(item?.accept_date).format('MMM DD, YYYY'))
                            : ''}
                        </span>
                      </div>
                      {/* </OverlayTrigger> */}
                    </div>
                    <div className='col-3'>
                      <div className='d-flex flex-column align-items-center'>
                        <div className='symbol symbol-40px symbol-circle'>
                          <div
                            className={`symbol-label fs-2 fw-semibold ${
                              item?.date &&
                              (moment().isBefore(moment(item?.date))
                                ? 'bg-primary'
                                : 'bg-light-dark')
                            }`}
                          >
                            <i
                              className={`fa-regular fa-file-powerpoint ${
                                item?.date &&
                                (moment().isBefore(moment(item?.date)) ? 'text-white' : 'text-dark')
                              }`}
                            ></i>
                          </div>
                        </div>
                        <span
                          className='text-black d-block mt-3 text-center'
                          style={{fontWeight: '400'}}
                        >
                          {item?.date
                            ? checkDisplayValue(moment(item?.date).format('MMM DD, YYYY'))
                            : ''}
                        </span>
                      </div>
                    </div>
                    <div className='col-3'>
                      <div className='d-flex flex-column align-items-center'>
                        <div className='symbol symbol-40px symbol-circle'>
                          <div
                            className={`symbol-label fs-2 fw-semibold ${
                              item?.question_date &&
                              (moment().isBefore(moment(item?.question_date))
                                ? 'bg-primary'
                                : 'bg-light-dark')
                            }`}
                          >
                            <i
                              className={`fa-solid fa-question ${
                                item?.question_date &&
                                (moment().isBefore(moment(item?.question_date))
                                  ? 'text-white'
                                  : 'text-dark')
                              }`}
                            ></i>
                          </div>
                        </div>
                        <span
                          className='text-black d-block mt-3 text-center'
                          style={{fontWeight: '400'}}
                        >
                          {item?.question_date
                            ? checkDisplayValue(moment(item?.question_date).format('MMM DD, YYYY'))
                            : ''}
                        </span>
                      </div>
                    </div>
                    <div className='col-3'>
                      <div className='d-flex flex-column align-items-center'>
                        <div className='symbol symbol-40px symbol-circle'>
                          <div
                            className={`symbol-label fs-2 fw-semibold ${
                              item?.success_date &&
                              (moment().isBefore(moment(item?.success_date))
                                ? 'bg-primary'
                                : 'bg-light-dark')
                            }`}
                          >
                            <i
                              className={`fa-solid fa-check ${
                                item?.success_date &&
                                (moment().isBefore(moment(item?.success_date))
                                  ? 'text-white'
                                  : 'text-dark')
                              }`}
                            ></i>
                          </div>
                        </div>
                        <span
                          className='text-black d-block mt-3 text-center'
                          style={{fontWeight: '400'}}
                        >
                          {item?.success_date
                            ? checkDisplayValue(moment(item?.success_date).format('MMM DD, YYYY'))
                            : ''}
                        </span>
                      </div>
                    </div>
                  </div>

                  {/* TYCA Question upload Admin  */} 
                  {/* <div className='row mx-0'>
                    <div className='col-sm-12 col-md-12 col-lg-6'>
                      <h3 className='text-primary fs-5 fw-bold text-nowrap'> TYCA Questions</h3>
                      <select
                        className='form-select form-select-lg mb-3 mt-3'
                        aria-label='.form-select-lg example'
                      >
                        <option value=''>All</option>
                      </select>
                    </div>
                    <div className='col-sm-12 col-md-12 col-lg-6'>
                      <h3 className='text-primary fs-5 fw-bold'> &nbsp; </h3>
                      <div className='input-group'>
                        <input
                          type='file'
                          className='form-control py-4'
                          id='inputGroupFile04'
                          aria-describedby='inputGroupFileAddon04'
                          aria-label='Upload'
                        />
                      </div>
                    </div>
                    <div className='col-sm-12 col-md-6 col-lg-4 col-xl-2 text-end mt-8 text-end mt-6'>
                      <button type='submit' className='btn px-5 py-2  btn-success'>
                        {' '}
                        Submit{' '}
                      </button>
                    </div>
                  </div> */}

                  {
                    isShowUserManagement &&  moment() <= moment(item?.success_date) && moment() >= moment(item?.question_date) && (<UploadForm tycaId={item?.id} refetch={fetchData}></UploadForm>)
                  }
                  {/*  */}
                </div>


                {/* TYCA Questions */}
                {item?.tycaquestion[0] ? (
                  <div className='card-footer '>
                    <h4 className='text-center text-primary fw-bold fs-5'> TYCA Questions </h4>
                    <div className='d-flex flex-wrap justify-content-center mt-4'>
                      {item?.tycaquestion?.map((i: any) => (
                        <>
                          <div className='d-flex flex-sm-wrap text-center position-relative me-3'>
                            <div className=' position-relative'>
                              {/* add delete button */}
                              <Button
                                variant='primary'
                                className='btn-sm mb-3 text-nowrap'
                                onClick={() => handleButtonClick(i?.pdf)}
                              >
                                {i.group?.name ? checkDisplayValue(i?.group?.name) : 'Beta 1'}
                              </Button>

                              {isShowUserManagement ||
                              (userDetail?.iscaptain === true && item?.user_id == userId) ? (
                                <div className='delete-tyca-container'>
                                  <div className='btn-sm mb-3 rounded-circle  px-2  delete-user-question'>
                                    <i
                                      className='bi bi-trash3 text-dark position-relative   pe-0'
                                      style={{fontSize: '14px', zIndex: '99'}}
                                      onClick={() => {
                                        setDeleteModal(true)
                                        setDeletetId(i?.id)
                                      }}
                                    ></i>
                                  </div>
                                </div>
                              ) : (
                                ''
                              )}
                            </div>
                          </div>
                        </>
                      ))}
                    </div>
                  </div>
                ) : (
                  ''
                )}
                {/*  */}
              </div>
            </div>
          ))}

          <Modal
            show={deleteModal}
            onHide={() => {
              setDeleteModal(false)
              setDeletetId('')
            }}
          >
            <Modal.Header closeButton>
              <Modal.Title>Delete TYCA Questions </Modal.Title>
            </Modal.Header>
            <Modal.Body>Are you sure you want to delete this File?</Modal.Body>
            <Modal.Footer>
            <Button
                variant='primary'
                onClick={() => {
                  setDeleteModal(false)
                  deleteTycaPdf(deletetId)
                }}
              >
                Yes
              </Button>
              <Button
                variant='secondary'
                onClick={() => {
                  setDeleteModal(false)
                  setDeletetId('')
                }}
              >
                No
              </Button>
              
            </Modal.Footer>
          </Modal>
          <Modal
            show={uploadPopup}
            onHide={() => {
              setUploadPopup(false)
              setImage(null)
              setImageName(null)
              setTyca_id(null)
            }}
            dialogClassName='modal-90w'
            aria-labelledby='example-custom-modal-styling-title'
          >
            <Modal.Header closeButton>
              <Modal.Title id='example-custom-modal-styling-title'>
                Upload TYCA Question
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form.Group>
                <p>*Note : Only image(jpg/png) or file(PDF) is allowed to upload !</p>
                <div className='row mx-0 fdbk-icon mt-10'>
                  <div className=' col-4 text-center'>
                    {/* <div className='me-3 px-4 py-3 position-relative btn btn-primary'> */}
                    <Button
                      variant='primary'
                      className='me-3 px-4 py-3'
                      disabled={updataProfileLoading}
                      onClick={() => {
                        toggleWebcam()
                        setWebCamImageFor('profile')
                      }}
                    >
                      <i className='bi bi-camera-fill pe-0' style={{fontSize: '20px'}}></i>
                    </Button>
                    {showWebcam && webcamAvailable ? (
                      <div className='webcam-popup'>
                        <div className='webcam-popup-content'>
                          <Webcam
                            audio={false}
                            ref={webcamRef}
                            videoConstraints={{
                              facingMode: facingMode,
                            }}
                          />
                          <div className='webcam-buttons'>
                            <Button
                              variant='primary'
                              className='me-3 px-4 py-3'
                              onClick={captureImage}
                            >
                              <i className='bi bi-camera-fill pe-0' style={{fontSize: '20px'}}></i>
                            </Button>
                            <Button
                              variant='primary'
                              className='me-3 px-4 py-3'
                              onClick={toggleCameraFacingMode}
                            >
                              <i
                                className='bi bi-arrow-repeat   pe-0'
                                style={{fontSize: '20px'}}
                              ></i>
                            </Button>
                            <Button
                              variant='secondary'
                              onClick={() => {
                                setShowWebcam(false)
                              }}
                            >
                              Cancel
                            </Button>
                          </div>
                        </div>
                      </div>
                    ) : (
                      ''
                    )}
                    {/* </div> */}
                  </div>
                  <div className=' col-4 text-center'>
                    <div className='me-3 px-4 py-3 position-relative btn btn-primary'>
                      <Form.Control
                        type='file'
                        accept='image/jpeg, image/png' // Specify accepted file types
                        className='position-absolute w-100 h-100 start-0 top-0 opacity-0'
                        onChange={onChangeHandler} // Handle file input change
                      />
                      <i className='bi bi-image-fill pe-0' style={{fontSize: '20px'}}></i>
                    </div>
                  </div>
                  <div className=' col-4 text-center'>
                    <div className='me-3 px-4 py-3 position-relative btn btn-primary'>
                      <Form.Control
                        type='file'
                        accept='application/pdf,' // Specify accepted file types
                        className='position-absolute w-100 h-100 start-0 top-0 opacity-0'
                        onChange={onChangeHandler} // Handle file input change
                      />
                      <i className='bi bi-folder-fill pe-0' style={{fontSize: '20px'}}></i>
                    </div>
                  </div>
                </div>
              </Form.Group>
              {image && (
                <div className='col mx-2 p-2'>
                  {imageName &&
                    (imageName?.type.match(/image-*/) ? (
                      <div className='col'>
                        <div
                          className='white-close-image'
                          style={{position: 'relative', width: '130px'}}
                        >
                          <img
                            alt='Captured'
                            // className='w-200px'
                            style={{width: 130, height: 90}}
                            src={image}
                          />
                          <button
                            type='button'
                            className='btn-close text-white fs-4'
                            style={{
                              backgroundColor: 'red',
                              position: 'absolute',
                              right: '5px',
                              top: '5px',
                            }}
                            onClick={() => {
                              setImage(null)
                              setImageName(null)
                            }}
                          />
                        </div>
                      </div>
                    ) : (
                      <div className='col'>
                        <div
                          className='white-close-image'
                          style={{position: 'relative', width: '130px'}}
                        >
                          <img
                            alt='Captured'
                            // className='w-200px'
                            style={{width: 130, height: 90}}
                            src={toAbsoluteUrl('/media/ilead/pdf.png')}
                          />
                          <button
                            type='button'
                            className='btn-close text-white fs-4'
                            style={{
                              backgroundColor: 'red',
                              position: 'absolute',
                              right: '5px',
                              top: '5px',
                            }}
                            onClick={() => {
                              setImage(null)
                              setImageName(null)
                            }}
                          />
                        </div>
                      </div>
                    ))}
                </div>
              )}
            </Modal.Body>
            <Modal.Footer>
              <Button
                onClick={() => {
                  submitHandler()
                }}
              >
                Submit
              </Button>
              <Button
                onClick={() => {
                  setUploadPopup(false)
                  setImage(null)
                  setImageName(null)
                  setTyca_id(null)
                }}
              >
                cancel
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      )}

      {!data[0] ? (
        // <div className='row mx-0 no-presentation'>
        //   <div className='col-sm-12   '>
        //     <div className='my-6 rounded-3 px-6 py-8'>
        //       <h3 className='fs-4'> There are no TYCA presentation ! </h3>
        //     </div>
        //   </div>
        // </div>
        <></>
      ) : (
        // <div className='card mob-vie-card sm-ms-3 p-5 mt-5 direciry-all tyca-card-view'>
        //   <div className='row  mx-0 p-3 mb-3 all-user'>
        //     {data?.map((item: any) => (
        //       <div className='col-md-6 col-lg-6 col-xl-4 p-8 px-3 mb-5  '>
        //         <div style={{ display: "flex", justifyContent: "space-between" }}>
        //           <h3 className='mb-7 fs-4'> {item?.title ? checkDisplayValue(item.title) : ''} </h3>
        //           <div style={{ display: "flex", justifyContent: "space-between" }}>
        //             {
        //               item?.pdf && (<Button
        //                 className='card-icon m-1'
        //                 type='button'
        //                 onClick={() => handleButtonClick(item?.pdf)}
        //               >
        //                 <i className='bi bi-file-pdf'></i>
        //               </Button>)

        //             }
        //             {
        //               item?.pdf && item?.iscaptain === true && (item?.tycaquestion.filter((item: any) => item.group_id == group_id).length > 0 ? ("")
        //                 : (
        //                   <Button
        //                     className='card-icon m-1'
        //                     type='button'
        //                     onClick={() => {
        //                       setUploadPopup(true)
        //                       setTyca_id(item.id)
        //                     }}
        //                   >
        //                     <i className='fa-solid fa-cloud-arrow-up fs-1' />
        //                   </Button>
        //                 ))
        //             }
        //           </div>

        //         </div>

        //         <div className='thumbnail-box mx-auto text-center'>
        //           <img
        //             src={
        //               item?.user?.profile
        //                 ? `${API_URL}/containers/userprofile/download/${item?.user?.profile}`
        //                 : `${toAbsoluteUrl('./media/avatars/default-user.png')}`
        //             }
        //             onError={({ currentTarget }: any) => {
        //               currentTarget.onerror = null // prevents looping
        //               currentTarget.src = `${toAbsoluteUrl('./media/avatars/default-user.png')}`
        //             }}
        //             alt=''
        //             className='thumbnail-img'
        //           />
        //         </div>
        //         <p className='mt-3 fw-bold'>
        //           {' '}
        //           {item?.user?.name ? checkDisplayValue(item.user.name) : ''}{' '}
        //         </p>
        //         <p className=' fw-semibold '>
        //           {' '}
        //           {item?.user?.companyname ? checkDisplayValue(item.user.companyname) : ''}{' '}
        //         </p>
        //         <p className=' fw-semibold '>
        //           {' '}
        //           {item?.user?.business_category
        //             ? checkDisplayValue(item.user.business_category)
        //             : ''}{' '}
        //         </p>
        //         <p>
        //           {' '}
        //           <i className='fa fa-globe'></i>{' '}
        //           {item?.user?.website ? checkDisplayValue(item.user.website) : ''}
        //         </p>

        //         <hr className='mt-5 mb-3' />
        //         <div className='row mx-0'>
        //           <div className='col-6 pt-4 pb-3'>
        //             <Button className={`card-icon ${item?.accept_date && (moment().isBefore(moment(item?.accept_date)) ? "active" : "")}`} type='button'>
        //               <i className='fa-regular fa-handshake'></i>
        //             </Button>
        //             <p className='text-center fs-7 fw-medium py-3 '>
        //               {' '}
        //               {item?.accept_date
        //                 ? checkDisplayValue(moment(item?.accept_date).format('MMM DD, YYYY'))
        //                 : ''}{' '}
        //             </p>
        //           </div>
        //           <div className='col-6 pt-4 pb-3'>
        //             <Button className={`card-icon ${item?.date && (moment().isBefore(moment(item?.date)) ? "active" : "")}`} type='button'>
        //               <i className='fa-regular fa-file-powerpoint'></i>
        //             </Button>
        //             <p className='text-center fs-7 fw-medium py-3 '>
        //               {' '}
        //               {item?.date
        //                 ? checkDisplayValue(moment(item?.date).format('MMM DD, YYYY'))
        //                 : ''}{' '}
        //             </p>
        //           </div>
        //           <div className='col-6 pt-0 pb-3'>
        //             <Button className={`card-icon ${item?.question_date && (moment().isBefore(moment(item?.question_date)) ? "active" : "")}`} type='button'>
        //               <i className='fa-solid fa-question'></i>
        //             </Button>
        //             <p className='text-center fs-7 fw-medium py-3 '>
        //               {' '}
        //               {item?.question_date
        //                 ? checkDisplayValue(moment(item?.question_date).format('MMM DD, YYYY'))
        //                 : ''}{' '}
        //             </p>
        //           </div>
        //           <div className='col-6 pt-0 pb-3'>
        //             <Button className={`card-icon ${item?.success_date && (moment().isBefore(moment(item?.success_date)) ? "active" : "")}`} type='button'>
        //               <i className='fa-solid fa-check'></i>
        //             </Button>
        //             <p className='text-center fs-7 fw-medium py-3 '>
        //               {' '}
        //               {item?.success_date
        //                 ? checkDisplayValue(moment(item?.success_date).format('MMM DD, YYYY'))
        //                 : ''}{' '}
        //             </p>
        //           </div>
        //         </div>
        //         {item?.tycaquestion[0] ? (
        //           <>
        //             <hr className='mt-5 mb-3' />
        //             <h4 className='text-center text-dark fw-bold fs-5'> TYCA Questions </h4>
        //             <div className='row mx-0'>
        //               {item?.tycaquestion.map((i: any) => (
        //                 <div className='beta-btn col-6 pt-4 pb-3'>
        //                   {' '}
        //                   <Button
        //                     className=' rounded-xs px-6 py-2 w-auto btn btn-primary btn btn-primary'
        //                     type='button'
        //                     onClick={() => handleButtonClick(i?.pdf)}
        //                   >
        //                     {' '}
        //                     {i.group?.name ? checkDisplayValue(i?.group?.name) : ''}{' '}
        //                   </Button>{' '}
        //                 </div>
        //               ))}
        //             </div>
        //           </>
        //         ) : (
        //           ''
        //         )}
        //       </div>
        //     ))}
        //   </div>
        //   <Modal
        //     show={uploadPopup}
        //     onHide={() => {
        //       setUploadPopup(false)
        //       setImage(null)
        //       setImageName(null)
        //       setTyca_id(null)
        //     }}
        //     dialogClassName='modal-90w'
        //     aria-labelledby='example-custom-modal-styling-title'
        //   >
        //     <Modal.Header closeButton>
        //       <Modal.Title id='example-custom-modal-styling-title'>
        //         Upload TYCA Question
        //       </Modal.Title>
        //     </Modal.Header>
        //     <Modal.Body>
        //       <Form.Group>
        //         <p>*Note : Only image(jpg/png) or file(PDF) is allowed to upload !</p>
        //         <div className='row mx-0 fdbk-icon mt-10'>
        //           <div className=' col-4 text-center'>
        //             <Button
        //               variant='primary'
        //               className='me-3 px-4 py-3'
        //               disabled={updataProfileLoading}
        //               onClick={() => {
        //                 toggleWebcam()
        //                 setWebCamImageFor('profile')
        //               }}
        //             >
        //               <i className='bi bi-camera-fill pe-0' style={{ fontSize: '20px' }}></i>
        //             </Button>
        //             {showWebcam && webcamAvailable ? (
        //               <div className='webcam-popup'>
        //                 <div className='webcam-popup-content'>
        //                   <Webcam
        //                     audio={false}
        //                     ref={webcamRef}
        //                     videoConstraints={{
        //                       facingMode: facingMode,
        //                     }}
        //                   />
        //                   <div className='webcam-buttons'>
        //                     <Button
        //                       variant='primary'
        //                       className='me-3 px-4 py-3'
        //                       onClick={captureImage}
        //                     >
        //                       <i className='bi bi-camera-fill pe-0' style={{ fontSize: '20px' }}></i>
        //                     </Button>
        //                     <Button
        //                       variant='primary'
        //                       className='me-3 px-4 py-3'
        //                       onClick={toggleCameraFacingMode}
        //                     >
        //                       <i
        //                         className='bi bi-arrow-repeat   pe-0'
        //                         style={{ fontSize: '20px' }}
        //                       ></i>
        //                     </Button>
        //                     <Button
        //                       variant='secondary'
        //                       onClick={() => {
        //                         setShowWebcam(false)
        //                       }}
        //                     >
        //                       Cancel
        //                     </Button>
        //                   </div>
        //                 </div>
        //               </div>
        //             ) : (
        //               ''
        //             )}
        //           </div>
        //           <div className=' col-4 text-center'>
        //             <div className='me-3 px-4 py-3 position-relative btn btn-primary'>
        //               <Form.Control
        //                 type='file'
        //                 accept='image/jpeg, image/png'
        //                 className='position-absolute w-100 h-100 start-0 top-0 opacity-0'
        //                 onChange={onChangeHandler}
        //               />
        //               <i className='bi bi-image-fill pe-0' style={{ fontSize: '20px' }}></i>
        //             </div>
        //           </div>
        //           <div className=' col-4 text-center'>
        //             <div className='me-3 px-4 py-3 position-relative btn btn-primary'>
        //               <Form.Control
        //                 type='file'
        //                 accept='application/pdf,'
        //                 className='position-absolute w-100 h-100 start-0 top-0 opacity-0'
        //                 onChange={onChangeHandler}
        //               />
        //               <i className='bi bi-folder-fill pe-0' style={{ fontSize: '20px' }}></i>
        //             </div>
        //           </div>
        //         </div>
        //       </Form.Group>
        //       {image && (
        //         <div className='col mx-2 p-2'>
        //           {imageName &&
        //             (imageName?.type.match(/image-*/) ? (
        //               <div className='col'>
        //                 <div
        //                   className='white-close-image'
        //                   style={{ position: 'relative', width: '130px' }}
        //                 >
        //                   <img
        //                     alt='Captured'
        //                     style={{ width: 130, height: 90 }}
        //                     src={image}
        //                   />
        //                   <button
        //                     type='button'
        //                     className='btn-close text-white fs-4'
        //                     style={{
        //                       backgroundColor: 'red',
        //                       position: 'absolute',
        //                       right: '5px',
        //                       top: '5px',
        //                     }}
        //                     onClick={() => {
        //                       setImage(null)
        //                       setImageName(null)
        //                     }}
        //                   />
        //                 </div>
        //               </div>
        //             ) : (
        //               <div className='col'>
        //                 <div
        //                   className='white-close-image'
        //                   style={{ position: 'relative', width: '130px' }}
        //                 >
        //                   <img
        //                     alt='Captured'
        //                     style={{ width: 130, height: 90 }}
        //                     src={toAbsoluteUrl('/media/ilead/pdf.png')}
        //                   />
        //                   <button
        //                     type='button'
        //                     className='btn-close text-white fs-4'
        //                     style={{
        //                       backgroundColor: 'red',
        //                       position: 'absolute',
        //                       right: '5px',
        //                       top: '5px',
        //                     }}
        //                     onClick={() => {
        //                       setImage(null)
        //                       setImageName(null)
        //                     }}
        //                   />
        //                 </div>
        //               </div>
        //             ))}
        //         </div>
        //       )}
        //     </Modal.Body>
        //     <Modal.Footer>
        //       <Button
        //         onClick={() => {
        //           submitHandler()
        //         }}
        //       >
        //         Submit
        //       </Button>
        //       <Button
        //         onClick={() => {
        //           setUploadPopup(false)
        //           setImage(null)
        //           setImageName(null)
        //           setTyca_id(null)
        //         }}
        //       >
        //         cancel
        //       </Button>
        //     </Modal.Footer>
        //   </Modal>
        // </div>
        <></>
      )}

      <AddTycaPresentation HandleReload={handleReload} editTyca={editTyca} setEditTyca={setEditTyca} />
    </>
  )
}

export {TycaPresentation}

const UploadForm = ( {tycaId,refetch}:any ) => {
  const [grouptData, setGroupData] = useState<any>([])

  const [fileForAdmin, setFileForAdmin] = useState<any>(null)
  const [fileNameForAdmin, setFileNameForAdmin ] = useState<any>(null)
  const {userDetail, setMessage, reload, chapterID} = useContext(UserContext)
  const userId = userDetail.id ? userDetail.id :""

  const fileuploadAmin = {
    group_id: '',
    pdf: '',
  }
  const fileuploadAminSchema = Yup.object().shape({
    group_id: Yup.string().required(' Group is required '),
    pdf: Yup.string().required('File is required'),
    // pdf:
  })

  const  fetchGroup= async () => {
    try {
      const responce = await GroupListAPI(chapterID,"")

      if (responce) {
        setGroupData(responce?.data?.data ?? [])
      }

    } catch (error) {
      console.error("error", error)
    }
  }
  useEffect(()=>{
    fetchGroup()
  },[])


  const formikforUploadAdmin = useFormik({
      initialValues: fileuploadAmin,
      validationSchema: fileuploadAminSchema,
      onSubmit: async (values, {setSubmitting,resetForm}) => {
        setSubmitting(true)
        try {
          console.log("values ",values )

            const formData = new FormData()
            formData.append('file', fileNameForAdmin)
            // formData.append('file', imageName, 'webcom.png')
      
            const response = await axios
              .post(`${IMAGE_UPLOAD}`, formData, {
                headers: {
                  'Content-Type': 'multipart/form-data',
                },
              })
              .then(async (res: any) => {
                const FileName = res?.data?.result?.files?.file[0]?.name
                const data = {
                  tyca_id: tycaId,
                  pdf: FileName,
                  created_by: userId,
                  ...{group_id:+values?.group_id}
                }
                const response = await TycaQuestionsPost(data)
                  .then(async (res: any) => {
                    // setUploadPopup(false)
                    // setImage(null)
                    refetch()
                    setFileNameForAdmin(null)
                    setFileForAdmin(null)
                    resetForm()
                    setMessage('success', 'TYCA Question Upload Successfully')
                  })
                  .catch((error: any) => {
                    setMessage('error', 'Something Went Wrong')
                  })
              })
              .catch((error: any) => {
                setMessage('error', 'Something Went Wrong')
              })
          
          
        } catch (ex) {
          console.error(ex)
        } finally {
          setSubmitting(false)
          // cancel(true)
        }
      },
    })

    const adminseleteFilehandler = (event: any) => {
      formikforUploadAdmin.setFieldValue("pdf","")
      const file = event.target.files[0]
      if (file) {
        var pattern = /image-*/
        // setSelectedImage(file.name)
        if (!file.type.match(pattern) && file.type !== 'application/pdf') {
          setMessage('error', 'Invalid format, only Images and Pdf !')
          return
        }
        setFileNameForAdmin(file)
        formikforUploadAdmin.setFieldValue("pdf",file?.name)
        setFileForAdmin(URL.createObjectURL(event.target.files[0]))
        
      } else {
        setFileNameForAdmin(null)
        setFileForAdmin(null)
      }
    }

  return (
    <div >
      <form  className='row mx-0'  onSubmit={formikforUploadAdmin.handleSubmit} noValidate > 
      <div className='col-sm-12 col-md-12 col-lg-6'>
        <h3 className='text-primary fs-5 fw-bold text-nowrap'> TYCA Questions</h3>
        <select
          className='form-select form-select-lg mb-3 mt-3'
          aria-label='.form-select-lg example'
          onChange={(e)=>{
            formikforUploadAdmin.setFieldValue('group_id', e?.target?.value)
          }}
        >
          <option value=''>Select Group</option>
                      {grouptData?.map((items: any) => ( 
                      <option 
                      selected={formikforUploadAdmin.values.group_id == items?.id} 
                      value={items?.id}>{items?.name}</option>))}
        </select>
        {formikforUploadAdmin.touched.group_id && formikforUploadAdmin?.errors.group_id && (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>
                                <span role='alert'>{formikforUploadAdmin.errors?.group_id}</span>
                              </div>
                            </div>
      )}
      </div>
      
      <div className='col-sm-12 col-md-12 col-lg-6'>
        <h3 className='text-primary fs-5 fw-bold'> &nbsp; </h3>
        <div className='input-group'>
          <input
            type='file'
            className='form-control py-4'
            // id='inputGroupFile04'
            aria-describedby='inputGroupFileAddon04'
            aria-label='Upload'
            // placeholder={fileNameForAdmin?.name}
            onChange={adminseleteFilehandler}
          />
          { formikforUploadAdmin?.values?.pdf &&(
            <p>Selected File: {formikforUploadAdmin?.values?.pdf}</p>
          )}
        </div>
        {formikforUploadAdmin.touched.pdf && formikforUploadAdmin?.errors.pdf && (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>
                                <span role='alert'>{formikforUploadAdmin.errors?.pdf}</span>
                              </div>
                            </div>)}
      </div>
      
      <div className='col-sm-12 col-md-6 col-lg-4 col-xl-2 text-end mt-8 text-end mt-6'>
        <button type='submit' className='btn px-5 py-2  btn-success'>
          {' '}
          Submit{' '}
        </button>
      </div>
      </form>
    </div>
  )
}

// {
//   /* <div className='col-md-6 col-lg-6 col-xl-4 py-8 px-3 mb-5 '>
//             <h3 className='mb-7 fs-4'> Sadsad </h3>
//             <div className='thumbnail-box mx-auto text-center'>
//               <img
//                 src={toAbsoluteUrl('./media/avatars/default-user.png')}
//                 alt=''
//                 className='thumbnail-img'
//               />
//             </div>
//             <p className='mt-3 fw-bold'> Gaurav Shah </p>
//             <p className=' fw-semibold '> ICIWF </p>
//             <p className=' fw-semibold '> Bsiness Category </p>
//             <p>
//               {' '}
//               <i className='fa fa-globe'></i>
//             </p>

//             <hr className='mt-5 mb-3' />
//             <div className='row mx-0'>
//               <div className='col-6 pt-4 pb-3'>
//                 <Button className='card-icon' type='button'>
//                   <i className='fa-regular fa-handshake'></i>
//                 </Button>
//                 <p className='text-center fs-7 fw-medium py-3 '> Dec 27, 2019 </p>
//               </div>
//               <div className='col-6 pt-4 pb-3'>
//                 <Button className='card-icon' type='button'>
//                   <i className='fa-regular fa-file-powerpoint'></i>
//                 </Button>
//                 <p className='text-center fs-7 fw-medium py-3 '> Dec 27, 2019 </p>
//               </div>
//               <div className='col-6 pt-0 pb-3'>
//                 <Button className='card-icon' type='button'>
//                   <i className='fa-solid fa-question'></i>
//                 </Button>
//                 <p className='text-center fs-7 fw-medium py-3 '> Dec 27, 2019 </p>
//               </div>
//               <div className='col-6 pt-0 pb-3'>
//                 <Button className='card-icon' type='button'>
//                   <i className='fa-solid fa-check'></i>
//                 </Button>
//                 <p className='text-center fs-7 fw-medium py-3 '> Dec 27, 2019 </p>
//               </div>
//             </div>
//           </div>

//           <div className='col-md-6 col-lg-6 col-xl-4 py-8 px-3 mb-5 '>
//             <h3 className='mb-7 fs-4'> Sadsad </h3>
//             <div className='thumbnail-box mx-auto text-center'>
//               <img
//                 src={toAbsoluteUrl('./media/avatars/default-user.png')}
//                 alt=''
//                 className='thumbnail-img'
//               />
//             </div>
//             <p className='mt-3 fw-bold'> Gaurav Shah </p>
//             <p className=' fw-semibold '> ICIWF </p>
//             <p className=' fw-semibold '> Bsiness Category </p>
//             <p>
//               {' '}
//               <i className='fa fa-globe'></i>
//             </p>

//             <hr className='mt-5 mb-3' />
//             <div className='row mx-0'>
//               <div className='col-6 pt-4 pb-3'>
//                 <Button className='card-icon' type='button'>
//                   <i className='fa-regular fa-handshake'></i>
//                 </Button>
//                 <p className='text-center fs-7 fw-medium py-3 '> Jan 2, 2020 </p>
//               </div>
//               <div className='col-6 pt-4 pb-3'>
//                 <Button className='card-icon' type='button'>
//                   <i className='fa-regular fa-file-powerpoint'></i>
//                 </Button>
//                 <p className='text-center fs-7 fw-medium py-3 '> Jan 2, 2020 </p>
//               </div>
//               <div className='col-6 pt-0 pb-3'>
//                 <Button className='card-icon' type='button'>
//                   <i className='fa-solid fa-question'></i>
//                 </Button>
//                 <p className='text-center fs-7 fw-medium py-3 '> Jan 2, 2020 </p>
//               </div>
//               <div className='col-6 pt-0 pb-3'>
//                 <Button className='card-icon' type='button'>
//                   <i className='fa-solid fa-check'></i>
//                 </Button>
//                 <p className='text-center fs-7 fw-medium py-3 '> Jan 2, 2020 </p>
//               </div>
//             </div>
//           </div>

//           <div className='col-md-6 col-lg-6 col-xl-4 py-8 px-3 mb-5 '>
//             <h3 className='mb-7 fs-4'> Presentation On Leadership </h3>
//             <div className='thumbnail-box mx-auto text-center'>
//               <img
//                 src={toAbsoluteUrl('./media/avatars/default-user.png')}
//                 alt=''
//                 className='thumbnail-img'
//               />
//             </div>
//             <p className='mt-3 fw-bold'> Darshak Prajapati </p>
//             <p className=' fw-semibold '> Sufalam Technology Pvt Ltd </p>
//             <p className=' fw-semibold '> Information Technology's </p>
//             <p>
//               {' '}
//               <i className='fa fa-globe'></i> Https://Sfalam.Atlassian.Net/
//             </p>
//             <Button className='eye-icon' type='button'>
//               <img
//                 src={toAbsoluteUrl('./media/svg/files/pdf-white.svg')}
//                 alt=''
//                 style={{width: '20px'}}
//               />
//             </Button>

//             <hr className='mt-5 mb-3'  />

//             <div className='row mx-0'>
//               <div className='col-6 pt-4 pb-3'>
//                 <Button className='card-icon' type='button'>
//                   <i className='fa-regular fa-handshake'></i>
//                 </Button>
//                 <p className='text-center fs-7 fw-medium py-3 '> Dec 2, 2022 </p>
//               </div>
//               <div className='col-6 pt-4 pb-3'>
//                 <Button className='card-icon' type='button'>
//                   <i className='fa-regular fa-file-powerpoint'></i>
//                 </Button>
//                 <p className='text-center fs-7 fw-medium py-3 '> Dec 2, 2022 </p>
//               </div>
//               <div className='col-6 pt-0 pb-3'>
//                 <Button className='card-icon' type='button'>
//                   <i className='fa-solid fa-question'></i>
//                 </Button>
//                 <p className='text-center fs-7 fw-medium py-3 '> Dec 2, 2022 </p>
//               </div>
//               <div className='col-6 pt-0 pb-3'>
//                 <Button className='card-icon' type='button'>
//                   <i className='fa-solid fa-check'></i>
//                 </Button>
//                 <p className='text-center fs-7 fw-medium py-3 '> Dec 2, 2022 </p>
//               </div>
//             </div>

//             <hr className='mt-5 mb-3' />
//             <h4 className='text-center text-dark fw-bold fs-5'> TYCA Qestions </h4>
//             <div className='beta-btn py-2'>
//               {' '}
//               <Button
//                 className=' rounded-xs px-6 py-2 w-auto btn btn-primary btn btn-primary'
//                 type='button'
//               >
//                 {' '}
//                 Beta 1{' '}
//               </Button>{' '}
//             </div>
//           </div> */
// }



