import axios, {AxiosResponse} from 'axios'
import {apiUrl} from '../../../../apiurl'
import {Response} from '../../../../_metronic/helpers'

const API_URL = apiUrl

export const FEEDBACK_URL = `${API_URL}/supportfeedbacks`

const giveFeedback = (data: any): Promise<any> => {
  return axios.post(FEEDBACK_URL, data).then((res: AxiosResponse<Response<any>>) => res)
}

export {giveFeedback}
