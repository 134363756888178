import {createContext, useEffect, useState} from 'react'
import {ToastContainer, toast} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const authUserDetail = () => {
  try {
    let userTmp = localStorage.getItem('authUserDetail')
    return userTmp ? JSON.parse(userTmp) : ({} as any)
  } catch (err) {
    localStorage.clear()
    return {}
  }
}

const authAccessUser = () => {
  try {
    let accessTmp = localStorage.getItem('authAccessUser')
    return accessTmp ? JSON.parse(accessTmp) : ({} as any)
  } catch (err) {
    return {}
  }
}

const getChapterId = () => {
  try {
    let chapterId = localStorage.getItem('chapterID')
    return chapterId
  } catch (err) {
    localStorage.clear()
    return {}
  }
}

const authToken = localStorage.getItem('authToken') ? localStorage.getItem('authToken') : ''
const authLoggedIn = localStorage.getItem('authLoggedIn')
  ? localStorage.getItem('authLoggedIn')
  : false

const UserDataValue = {
  isLoggedIn: authLoggedIn,
  token: authToken,
  role: null,
  userDetail: authUserDetail(),
  accessUser: authAccessUser(),
  chapterID: getChapterId(),
  reload: false,
} as any

export const UserContext = createContext(UserDataValue)

export const ContextStore = ({children}: any) => {
  const [userData, setUserData] = useState<any>(UserDataValue)

  const setAuthAccessUser = (user: any) => {
    localStorage.setItem('authAccessUser', JSON.stringify(user))
    userData.accessUser = user
    setUserData(userData)
  }

  const setAuth = (token: any, role: any, isLoggedIn: any, user: any) => {
    localStorage.setItem('authLoggedIn', isLoggedIn)
    localStorage.setItem('authToken', token)
    localStorage.setItem('authUserDetail', JSON.stringify(user))
  }

  const removeAuth = () => {
    localStorage.clear()
    setUserData({
      token: '',
      role: '',
      isLoggedIn: false,
      userDetail: {},
    })
  }
  const setChapterID = (chapterID: any) => {
    localStorage.setItem('chapterID', chapterID)
    userData.chapterID = chapterID
    setUserData(userData)
  }

  const setMessage = (status: any, message: any, time: any = 2) => {
    if (message || message !== '') {
      if (status == 'success') {
        toast.success(message, {
          toastId: message,
          position: 'top-center',
          autoClose: time * 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        })
      } else if (status == 'error') {
        toast.error(message, {
          toastId: message,
          position: 'top-center',
          autoClose: time * 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        })
      }
    }
  }

  const dashBoardReload = () => {
    setUserData((prevData: any) => {
      // Update the reload property to true
      const updatedData = {
        ...prevData,
        reload: true,
      }

      setTimeout(() => {
        setUserData((prevData: any) => ({
          ...prevData,
          reload: false,
        }))
      }, 2000)

      return updatedData
    })
  }

  return (
    <UserContext.Provider
      value={{
        ...(userData as any),
        setAuth,
        removeAuth,
        setMessage,
        dashBoardReload,
        setAuthAccessUser,
        setChapterID,
      }}
    >
      <ToastContainer limit={1} />
      {children}
    </UserContext.Provider>
  )
}
