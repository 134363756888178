import React from "react";
import { Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Multiselect from 'multiselect-react-dropdown';
import AddEditReminder from "./AddEditReminder";

const AddReminder = () => {

    const navigate = useNavigate()

    return (

        <>
            <div className="row mx-0 flex-md-block ">
                <div className='col-sm-6 col-md-6 col-lg-6'><h1 className='inv-hst sm-ms-2'> Add Reminder </h1></div>
            </div>


            <div className='card mx-3 py-8 px-5 px-sm-8 mt-5 visitor-details ilead__card'>
              <AddEditReminder />
            </div>


        </>
    )


}
export default AddReminder