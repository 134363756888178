// @ts-nocheck
import {Column} from 'react-table'
import {ChapterInfoCell} from './ChapterInfoCell'
import {ChapterLastLoginCell} from './ChapterLastLoginCell'
import {ChapterTwoStepsCell} from './ChapterTwoStepsCell'
import {ChapterActionsCell} from './ChapterActionsCell'
import {ChapterSelectionCell} from './ChapterSelectionCell'
import {ChapterCustomHeader} from './ChapterCustomHeader'
import {ChapterSelectionHeader} from './ChapterSelectionHeader'
import {Chapter} from '../../core/_models'
import { checkDisplayValue } from '../../../../../../utils' 

const chaptersColumns: ReadonlyArray<Column<Chapter>> = [
  // {
  //   Header: (props) => <ChapterSelectionHeader tableProps={props} />,
  //   id: 'selection',
  //   Cell: ({...props}) => <ChapterSelectionCell id={props.data[props.row.index].id} />,
  // },
 


  {
    Header: (props) => <ChapterCustomHeader tableProps={props} title='Name' className='text-start min-w-125px fs-5' />,
    accessor: 'name',
    // id: 'name',
    Cell: ({value}) => checkDisplayValue(value),
  },

  // {
  //   Header: (props) => <ChapterCustomHeader tableProps={props} title='State' className='text-start min-w-125px fs-5' />,
  //   accessor: 'state',
  //   // id: 'name',
  //   Cell: ({value}) => checkDisplayValue(value),
  // },

  // {
  //   Header: (props) => <ChapterCustomHeader tableProps={props} title='City' className='text-start min-w-125px fs-5' />,
  //   accessor: 'city',
  //   // id: 'name',
  //   Cell: ({value}) => checkDisplayValue(value),
  // },

  {
    Header: (props) => <ChapterCustomHeader tableProps={props} title='Created By' className='text-start min-w-125px fs-5' />,
    accessor: 'createdByName',
    // id: 'name',
    Cell: ({value}) => checkDisplayValue(value),
  },

  {
      Header: (props) => (
        <ChapterCustomHeader tableProps={props} title='Actions' className='text-start min-w-150px' />
      ),
      id: 'actions',
      Cell: ({...props}) => <ChapterActionsCell id={props.data[props.row.index].id} />,
    },

  
]

export {chaptersColumns}
