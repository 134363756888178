import React, {useContext, useEffect, useRef, useState} from 'react'
import {Button, Form, Image} from 'react-bootstrap'
import {KTSVG, toAbsoluteUrl} from '../../../../../../_metronic/helpers'
import {createGoals, deleteGoals, editGoals, listingForManageGoal} from '../_requests'
import {formatTargetDate} from './formatTargetDate'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {UserContext} from '../../../../../../contextStore'
import moment from 'moment'

export function ManageGoal() {
  // const [visible, setVisible] = useState(true)
  const {setMessage,dashBoardReload} = useContext(UserContext)
  const [manageGoalListing, setManageGoalListing] = useState<any>([])
  const [data, setData] = useState<any>([])
  const [isLoading, setIsLoading] = useState(true)
  const [editedGoalId, setEditedGoalId] = useState<any>(null)
  const {userDetail} = useContext(UserContext)
  const [manageGoalId, setManageGoalId] = useState<any>('')
  const [deleteId, setDeleteId] = useState<any>('')
  const [editModal, setEditModel] = useState<any>(false)
  const closeButton = useRef<any>()
  const closeEditModalButton = useRef<any>()
 
  //validation
  const editManageGoalSchema = Yup.object().shape({
    description: Yup.string().required('Description is required')
    .max(500, 'Maximum 500 characters allow').nullable(),
    current_state: Yup.string().required('Current state is required')
    .max(100, 'Maximum 100 characters allow').nullable(),
    goal: Yup.string().required('Goal is required')
    .max(100, 'Maximum 100 characters allow').nullable(),
    target_date: Yup.date().required('Date is required'),
  })

  const initialValue = {
    description: '',
    goal: '',
    current_state: '',
    target_date: `${moment()}`,
  }
  useEffect(() => {
    
    fetchData()
  }, [])

  const fetchData = async () => {
    try {
      const listingLeaderData = await listingForManageGoal(userDetail.id)
      setManageGoalListing(() => listingLeaderData ?? [])
      setIsLoading(false)
    } catch (error) {
      console.error('Error -->:', error)
      setIsLoading(false)
    }
  }

  const handleEditGoal = (goalId: any) => {
    setEditedGoalId(goalId)
    const editedGoal = manageGoalListing.find((item: any) => item.id === goalId)
    if (editedGoal) {
      formik.setFieldValue('description', editedGoal.description)
      formik.setFieldValue('goal', editedGoal.goal)
      formik.setFieldValue('current_state', editedGoal.current_state)
      formik.setFieldValue('target_date', editedGoal.target_date)
    }
  }

  useEffect(() => {
    if (!editModal) {
      formik.resetForm()
    }
  }, [editModal])

  const formik = useFormik({
    initialValues: initialValue,
    validationSchema: editManageGoalSchema,
    onSubmit: async (values, {resetForm}) => {
      try {
        const postManageGoal = {
          created_by: userDetail.id,
          current_state: values.current_state,
          description: values.description,
          goal: values.goal,
          isdelete: 0,
          target_date: values.target_date,
          user_id: userDetail.id,
        }
        if (editedGoalId) {
          await editGoals(editedGoalId, postManageGoal)
          setMessage('success', 'My Goal is Edited')
          setEditedGoalId(null)
          closeEditModalButton.current.click()
          setTimeout(() => {
            resetForm()
          }, 2000)
        } else {
          await createGoals(postManageGoal)
          closeEditModalButton.current.click()
          setMessage('success', 'My Goal is Created')
          setTimeout(() => {
            resetForm()
          }, 2000)
        }
        await fetchData()
        setManageGoalListing((prevListing: any) =>
          prevListing.map((item: any) =>
            item.id === editedGoalId ? {...item, ...postManageGoal} : item
          )
        )
        fetchData()
        dashBoardReload()
      } catch (error) {
        console.error('Error -->:', error)
        setIsLoading(false)
      }
    },
  })

  //delete api
  const handleDeleteGoal = async (goalId: any) => {
    try {
      await deleteGoals(goalId)
      fetchData()
    } catch (error) {
      console.error('Error -->:', error)
      setIsLoading(false)
    }
  }
  useEffect(() => {
    const hideDivAfterTimeout = () => {
      setTimeout(() => {
        // setVisible(false)
      }, 7000)
    }

    hideDivAfterTimeout()
  }, [])
  return (
    <>
      <div
        className='modal fade ilead-default-modal'
        id='ManageGoalModal'
        aria-hidden='true'
        aria-labelledby='ManageGoalModalLabel'
      >
        <div className='modal-dialog modal-dialog modal-dialog-scrollable modal-fullscreen-md-down modal-md'>
          <div className='modal-content'>
            <div className='modal-header py-5'>
              <h3 className='modal-title fs-3 fw-bold' id='ManageGoalModalToggleLabel'>
                Manage Goals
              </h3>
              <button
                type='button'
                className='btn-close text-white'
                data-bs-target='#write_post_modal'
                data-bs-toggle='modal'
                data-bs-dismiss='modal'
                ref={closeButton}
              ></button>
            </div>
            <div className='modal-body pt-7 write-post-wrapper'>
              {manageGoalListing.length <= 0 ? (
                <div className='single_comment_view d-flex align-items-start py-7'>
                  <div className='empty-comments text-center w-100'>
                    <Image
                      fluid
                      src={toAbsoluteUrl('/media/ilead/goal-empty.png')}
                      className='w-100 mb-4'
                    ></Image>
                    <h4 className='text-gray-900'>Set Your First Goal</h4>
                    <span className='text-gray-500 d-block'>
                      “Setting goals is the first step in turning the invisible into the visible.”
                    </span>
                  </div>
                </div>
              ) : (
                <>
                  {manageGoalListing.map((item: any) => (
                    <div className='goal_box mb-4 p-5 w-100'>
                      <div className='d-flex justify-content-between'>
                        <p className='fw-bold fs-6 text-start'>
                          {' '}
                          My Goal is to {item.description} from {item.current_state} to {item.goal}{' '}
                          by {formatTargetDate(item.target_date)}
                        </p>
                        <div className='ps-3 d-flex'>
                          <Button
                            variant='link'
                            className='btn btn-icon btn-action btn-circle p-3 me-2'
                            style={{background: '#eee', height: '40px', width: '40px'}}
                            data-bs-target='#AddEditGoalModalModal'
                            data-bs-toggle='modal'
                            data-bs-dismiss='modal'
                            // onClick={() => handleEditGoal(item.id)}
                            onClick={() => {
                              setEditModel(true)
                              handleEditGoal(item.id)
                            }}
                          >
                            <i className='fa-solid fa-pen text-gray'></i>
                          </Button>
                          <Button
                            variant='link'
                            className='btn btn-icon btn-action btn-circle p-3 me-2'
                            style={{background: '#eee', height: '40px', width: '40px'}}
                            data-bs-toggle='modal'
                            data-bs-target='#delete_manage_goal'
                            data-bs-dismiss='modal'
                            onClick={() => {
                              setDeleteId(item.id)
                            }}
                          >
                            <i className='fa-solid fa-trash-can text-gray'></i>
                          </Button>
                        </div>
                      </div>
                      <div className='d-flex justify-content-between'>
                        <h3 className='fs-7 fw-semibold ilead-text'>Goal: {item.goal}</h3>
                        <h3 className='fs-7 fw-semibold fw-normal text-gray'>
                          by: {formatTargetDate(item.target_date)}{' '}
                        </h3>
                      </div>
                    </div>
                  ))}
                </>
              )}
            </div>
            <div className='modal-footer py-3'>
              <Button
                variant='primary'
                className='w-100'
                data-bs-target='#AddEditGoalModalModal'
                data-bs-toggle='modal'
                data-bs-dismiss='modal'
                onClick={() => setEditModel(false)}
              >
                Add Goal
              </Button>
            </div>
          </div>
        </div>
      </div>
      {/* Milestone Reached */}

      {/* ================== Delete Model Start ==================== */}
      <div
        className='modal fade ilead-default-modal'
        aria-hidden='true'
        id='delete_manage_goal'
        aria-labelledby='DeleteGoalModalModalLabel'
      >
        <div className='modal-dialog modal-dialog-centered'>
          <div className='modal-content'>
            <div className='modal-header py-5'>
              <h5 className='modal-title fs-3 fw-bold'>Delete Goal</h5>
              <button
                type='button'
                className='btn-close text-white'
                data-bs-target='#ManageGoalModal'
                data-bs-toggle='modal'
                data-bs-dismiss='modal'
              ></button>
            </div>
            <div className='modal-body fs-5'>
              <p>Are you sure you want to delete this goal?</p>
            </div>
            <div className='modal-footer'>
              <button
                type='button'
                className='btn btn-primary'
                data-bs-target='#ManageGoalModal'
                data-bs-toggle='modal'
                data-bs-dismiss='modal'
                onClick={() => {
                  handleDeleteGoal(deleteId)
                  setMessage('success', 'Deleted successfully')
                }}
              >
                Delete
              </button>
              <button
                type='button'
                className='btn btn-light'
                data-bs-target='#ManageGoalModal'
                data-bs-toggle='modal'
                data-bs-dismiss='modal'
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* ================== Delete Model End ==================== */}
      {/* create make goal start */}
      <div
        className='modal fade ilead-default-modal'
        id='AddEditGoalModalModal'
        aria-hidden='true'
        aria-labelledby='AddEditGoalModalModalLabel'
      >
        <div className='modal-dialog modal-dialog-scrollable modal-fullscreen-md-down modal-md'>
          <div className='modal-content'>
            <div className='modal-header py-5'>
              <h3 className='modal-title fs-3 fw-bold' id='AddEditGoalModalModalToggleLabel'>
                {editModal ? 'Edit' : 'Add'} Goal
              </h3>
              <button
                type='button'
                className='btn-close text-white'
                data-bs-target='#ManageGoalModal'
                data-bs-toggle='modal'
                data-bs-dismiss='modal'
                ref={closeEditModalButton}
                onClick={() => {
                  formik.setFieldValue('description', '')
                  formik.setFieldValue('goal', '')
                  formik.setFieldValue('current_state', '')
                  formik.setFieldValue('target_date', '')
                  formik.resetForm()
                  formik.resetForm()
                  setEditedGoalId(null)
                }}
              ></button>
            </div>
            <div className='modal-body pt-7 write-post-wrapper'>
              <form
                onSubmit={formik.handleSubmit}
                // formik.handleSubmit
              >
                <Form.Group>
                  <Form.Label className='text-dark fw-semibold required'>My goal is to</Form.Label>
                </Form.Group>
                <Form.Group className='mt-7'>
                  <Form.Control
                    as='textarea'
                    rows={10}
                    placeholder='Describe your goal...'
                    className='ilead-input'
                    onChange={(e: any) => {
                      formik.setFieldValue('description', e.target.value)
                    }}
                    value={formik?.values?.description}
                  />
                  {formik?.touched?.description && formik?.errors?.description && (
                    <div className='fv-plugins-message-container text-danger'>
                      <span role='alert'>{formik?.errors?.description as any}</span>
                    </div>
                  )}
                  {/* error msg * Please enter the goal ! */}
                </Form.Group>
                <div className='row'>
                  <Form.Group className='mt-7 col-6'>
                    <Form.Label className='text-dark fw-semibold required'>From</Form.Label>
                    <Form.Control
                      type='text'
                      placeholder='Current state'
                      className='ilead-input'
                      onChange={(e: any) => {
                        formik.setFieldValue('current_state', e.target.value)
                      }}
                      value={formik?.values?.current_state}
                    />
                    {formik?.touched?.current_state && formik?.errors?.current_state && (
                      <div className='fv-plugins-message-container text-danger'>
                        <span role='alert'>{formik?.errors?.current_state as any}</span>
                      </div>
                    )}
                    {/* error msg * Please enter your current state ! */}
                  </Form.Group>
                  <Form.Group className='mt-7 col-6'>
                    <Form.Label className='text-dark fw-semibold required'>To</Form.Label>
                    <Form.Control
                      type='text'
                      placeholder='Goal'
                      className='ilead-input'
                      onChange={(e: any) => {
                        formik.setFieldValue('goal', e.target.value)
                      }}
                      value={formik?.values?.goal}
                    />
                    {formik?.touched?.goal && formik?.errors?.goal && (
                      <div className='fv-plugins-message-container text-danger'>
                        <span role='alert'>{formik?.errors?.goal as any}</span>
                      </div>
                    )}
                    {/* error msg * Please enter your target ! */}
                  </Form.Group>
                </div>
                <Form.Group className='mt-7'>
                  <Form.Label className='text-dark fw-semibold required'>By</Form.Label>
                  <Form.Control
                    type='date'
                    placeholder='25 Jul 2023'
                    className='ilead-input'
                    onChange={(e: any) => {
                      formik.setFieldValue('target_date', moment(e.target.value))
                    }}
                    value={
                      formik?.values?.target_date
                        ? new Date(formik?.values?.target_date).toISOString().split('T')[0]
                        : ''
                    }
                  />
                  {formik?.touched?.target_date && formik?.errors?.target_date && (
                    <div className='fv-plugins-message-container text-danger'>
                      <span role='alert'>{formik?.errors?.target_date as any}</span>
                    </div>
                  )}
                  {/* error msg * Please select the date! and by default today date will show */}
                </Form.Group>
                <Button
                  variant='primary'
                  type='submit'
                  // data-bs-toggle={formik.isValid ? 'modal' : undefined}
                  // data-bs-dismiss={formik.isValid ? 'modal' : undefined}
                  className='w-100 mt-7'
                  disabled={!formik.dirty || !formik?.values?.goal  || !formik?.values?.current_state || !formik?.values?.description}
                >
                  {editModal ? 'EDIT' : 'ADD'} GOAL
                </Button>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* create make goal end */}
    </>
  )
  // document.getElementById('element')
}
