import React, { useContext, useEffect, useRef, useState } from 'react'
import {
  Button,
  Carousel,
  Dropdown,
  DropdownButton,
  Image,
  Modal,
  OverlayTrigger,
  Tooltip,
  Form,
} from 'react-bootstrap'

import { Link, useNavigate } from 'react-router-dom'
import { apiUrl } from '../../../../../apiurl'
import Heart from 'react-animated-heart'
import { KTSVG, toAbsoluteUrl } from '../../../../../_metronic/helpers'
import {
  createCommentApi,
  deletePostApi,
  getAllPostWithFilter,
  getCommentApi,
  likePostApi,
  removeBookmarkApi,
  setBookmarkApi,
  showHidePostApi,
} from './_requests'
import { checkDisplayValue } from '../../../../../utils'
import moment from 'moment'
import SVG from 'react-inlinesvg'
import { RWebShare } from 'react-web-share'
import { UserContext } from '../../../../../contextStore'
import * as Yup from 'yup'
import { PostFooter } from './PostFooter'
import { useFormik } from 'formik'
import ShowPinnedPostModal from './showPinnedPost'
import { AnnouncementAndBirthdayLoadingSkeleton } from './AnnouncementAndBirthdayLoadingSkeleton'
// import { ViewPinPost } from './viewPinPost'
const API_URL = apiUrl

export function PinnedPost() {
  const { setMessage, userDetail, reload, chapterID } = useContext(UserContext)
  const [currentPostData, setCurrentPostData] = useState<any>({})
  const deleteModelPostRef = useRef<any>()
  const [pinnedData, setPinnedData] = useState<any>([])
  const handleShow = () => setShow(true)
  const [show, setShow] = useState(false)
  const [userId, setUserId] = useState<any>('')
  const [indexValue, setIndexValue] = useState<any>('')
  const [data, setData] = useState<any>([])
  const handleClose = () => setShow(false)
  const [likeList, setLikeList] = useState<any>('')
  const navigate = useNavigate()
  const [indexSlider, setIndexSlider] = useState(0)
  const [showPinnedPost, setShowPinnedPost] = useState<any>(false)
  const [showPinnedPostData, setShowPinnedPostData] = useState<any>({})
  const [showPinnedPostIndex, setShowPinnedPostIndex] = useState<any>({})
  const [postloading, setPostLoading] = useState(false);
  const handleSelect = (selectedIndex: any) => {
    setIndexSlider(selectedIndex)
  }
  const [loading, setLoading] = useState<any>({
    send: false,
    list: false,
    hide: false,
    delete: false,
    like: false,
  })
  const setBookMark = () => {
    try {

      let newData = data
      newData[indexValue].isbookmarked = true
      let reqData = newData[indexValue]
      setBookmarkApi({ user_topic_id: reqData?.id, leader_id: userDetail?.id })
      setData([...newData])
    } catch (err) {
      console.error('error', err)
    }
  }

  const removeBookMark = () => {
    try {
      let newData = data
      newData[indexValue].isbookmarked = false
      let reqData = newData[indexValue]
      removeBookmarkApi({ user_topic_id: reqData?.id, leader_id: userDetail?.id })
      setData([...newData])
    } catch (err) {
      console.error('error', err)
    }
  }

  const showHideAction = async () => {
    setLoading(Object.assign({}, loading, { hide: true }))
    try {
      await showHidePostApi({ user_topic_id: currentPostData?.id })
      let newData = pinnedData
      newData[indexValue].isactive = currentPostData?.isactive ? 0 : 1
      setPinnedData([...newData])
      setLoading(Object.assign({}, loading, { hide: false }))
    } catch (err) {
      console.error(err)
      setLoading(Object.assign({}, loading, { hide: false }))
    }
  }

  const deletePostAction = async () => {
    setLoading(Object.assign({}, loading, { delete: true }))
    try {
      let responseData = await deletePostApi(currentPostData?.id)
      if (responseData.count == 1) {
        let newData = pinnedData.filter((x: any, id: any) => id != indexValue)
        setPinnedData([...newData])
        setMessage('success', 'Delete Successfully')
        deleteModelPostRef?.current?.click()
      }
      setLoading(Object.assign({}, loading, { delete: false }))
    } catch (err) {
      console.error(err)
      setLoading(Object.assign({}, loading, { delete: false }))
    }
  }

  const getPostComment = async () => {
    setLoading(Object.assign({}, loading, { list: true }))
    try {
      let responseData = await getCommentApi(currentPostData?.uuid)

      let cData = responseData?.usertopicchats ?? []
      await setData([...cData])
      setLoading(Object.assign({}, loading, { list: false }))
    } catch (err) {
      console.error(err)
      setLoading(Object.assign({}, loading, { list: false }))
    }
  }
  const rediecttoLeaderProfile = (id: any) => {
    navigate(`/leaders/${id}`)
  }
  const extractVideoID = (url: string) => {
    const urlParams = new URLSearchParams(new URL(url).search)
    return urlParams.get('v')
  }
  const likePostAction = async () => {

    setLoading(Object.assign({}, loading, { like: true }))
    try {
      let responseData = await likePostApi({
        user_topic_id: currentPostData?.id,
        user_id: userId,
      })
      let newData = pinnedData
      newData[indexValue].usertopiclikes = responseData.userTopicLikeFinal
      newData[indexValue].totallikes = responseData.totallikes
      setPinnedData([...newData])
      setLoading(Object.assign({}, loading, { like: false }))
    } catch (err) {
      console.error('rajjjjjjjjjjjjjjjjj', err)
      setLoading(Object.assign({}, loading, { like: false }))
    }
  }

  const convertTextToLinks = (text: any) => {
    const urlRegex = /(https?:\/\/[^\s]+|www[^\s]+)/
    const parts = text.split(urlRegex)

    return parts.map((part: any, index: any) => {
      if (part.match(urlRegex)) {
        let url = part

        if (!part.startsWith('https://www.youtube.com')) {
          if (url.startsWith('www.')) {
            url = 'http://' + part
          }
          return (
            <a key={index} href={url} target='_blank' rel='noopener noreferrer'>
              {part}
            </a>
          )
        } else {
          const videoID = extractVideoID(part)
          if (videoID) {
            const embedURL = `//www.youtube.com/embed/${videoID}`
            return (
              <div key={index}>
                <a href={part} target='_blank' rel='noopener noreferrer'>
                  {part}
                </a>
                <iframe
                  title='video'
                  width='450'
                  height='250'
                  src={embedURL}
                  frameBorder='0'
                  allowFullScreen
                ></iframe>
              </div>
            )
          }
        }
      } else {
        return <span key={index}>{part}</span>
      }
    })
  }

  const handleToshow = (file: any) => {
    if (file) {
      const url = `${API_URL}/containers/topicimages/download/${file}`
      window.open(url, '_blank')
    }
  }

  const getIcaniwillLeaderList = async () => {
    setLoading(Object.assign({}, loading, { list: true }))
    try {
      let responseData = await getCommentApi(currentPostData.uuid)
      let likeData = responseData?.usertopiclikes ?? []
      await setLikeList([...likeData])
      setLoading(Object.assign({}, loading, { list: false }))
    } catch (err) {
      console.error(err)
      setLoading(Object.assign({}, loading, { list: false }))
    }
  }

  const pinPostDataFetch = async () => {
    try {
      setPostLoading(true)
      const responseData = await getAllPostWithFilter(1, 0, 15, chapterID)

      const currentMoment = moment().tz('Asia/Kolkata');

      const filteredData = responseData.filter((item: any) => {
        if (item?.deadline_announcement) {
          const deadlineMoment = moment.utc(item.deadline_announcement).tz('Asia/Kolkata').add(1, 'days');
          return deadlineMoment.isSameOrAfter(currentMoment);
        }

        return true;
      });

      setPinnedData(() => filteredData ?? [])
      setData(() => responseData ?? [])
      setPostLoading(false)
    } catch (error) {
      setPostLoading(false)
      console.error('somthing want wrong', error)
    }
  }
  const validationSchema = Yup.object().shape({
    chattext: Yup.string().required('Comment is required').max(250, 'Comments not more than 250 characters'),
  })
  const initialValues = {
    chattext: '',
    thank_you: 0,
    comment: 0,
    isactive: 1,
    isdelete: 0,
    created_by: '1',
    user_topic_id: null,
    user_id: '1',
  }
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setLoading(Object.assign({}, loading, { send: true }))
      try {
        values.user_id = userId
        values.user_topic_id = pinnedData.id
        // Make the API call
        await createCommentApi(values)
        getPostComment()
        formik.resetForm()
        setMessage('success', 'Commented successfully')
        let newData = pinnedData
        newData[indexValue].totalcomments = newData[indexValue].totalcomments + 1
        setPinnedData([...newData])
        setLoading(Object.assign({}, loading, { send: false }))
      } catch (error) {
        console.error(error)

        setMessage('error', 'Error creating the announcement')
        setLoading(Object.assign({}, loading, { send: false }))
      }
    },
  })

  useEffect(() => {
    if (reload) {
      pinPostDataFetch()
    } else {
      pinPostDataFetch()
    }
  }, [reload])


  // List for comment for single post
  const commentList = () => {
    return data && data.length > 0 ? (
      data.map((itemMessage: any, index: any) => {
        return (
          <div className='single_comment_view d-flex align-items-start mb-3' key={index}>
            <Link
              to={`/leaders/${itemMessage?.user?.id}`}
              onClick={() => {
                rediecttoLeaderProfile(itemMessage?.user?.id)
              }}
              data-bs-dismiss='modal'
              aria-label='Close'
            >
              <div className='symbol symbol-40px me-3'>
                <Image
                  src={
                    itemMessage?.user?.profile
                      ? `${API_URL}/containers/userprofile/download/${itemMessage?.user?.profile}`
                      : `${toAbsoluteUrl('./media/avatars/default-user.png')}`
                  }
                  onError={({ currentTarget }: any) => {
                    currentTarget.onerror = null // prevents looping
                    currentTarget.src = `${toAbsoluteUrl('./media/avatars/default-user.png')}`
                  }}
                ></Image>
              </div>
            </Link>
            <Link
              to={`/leaders/${itemMessage?.user?.id}`}
              onClick={() => {
                rediecttoLeaderProfile(itemMessage?.user?.id)
              }}
              data-bs-dismiss='modal'
              aria-label='Close'
            >
              <div className='flex-grow-1 d-flex flex-column commnet_box_right'>
                <div className='commented_user_details mb-1'>
                  <Link to='' className='ilead-text text-hover-primary fs-6 fs-md-5 fw-bold me-2'>
                    {itemMessage.user.name}
                  </Link>
                  <span className='round-separator me-2'></span>
                  <span className='text-gray-600'>{moment(itemMessage.created_at).fromNow()}</span>
                </div>
                <div className='commnet_content modal-wrap-text text-black'>{itemMessage.chat}</div>
              </div>
            </Link>
          </div>
        )
      })
    ) : (
      <div className='single_comment_view d-flex align-items-start'>
        <div className='empty-comments text-center w-100'>
          <Image
            fluid
            src={toAbsoluteUrl('/media/ilead/empty-comments.png')}
            className='w-150px mb-4'
          ></Image>
          <h4 className='text-gray-900'>No Comments Yet</h4>
          <span className='text-gray-500'>Be the first to comment</span>
        </div>
      </div>
    )
  }

  const icaniwillLeaderList = () => {
    return likeList && likeList.length > 0 ? (
      likeList.map((icaniwll: any, index: any) => {
        return (
          <div className='single_comment_view d-flex align-items-start mb-3' key={index}>
            <Link
              to={`/leaders/${icaniwll?.user?.id}`}
              onClick={() => {
                rediecttoLeaderProfile(icaniwll?.user?.id)
              }}
              data-bs-dismiss='modal'
              aria-label='Close'
            >
              <div className='symbol symbol-40px me-3'>
                <Image
                  src={
                    icaniwll?.user?.profile
                      ? `${API_URL}/containers/userprofile/download/${icaniwll?.user?.profile}`
                      : `${toAbsoluteUrl('./media/avatars/default-user.png')}`
                  }
                  onError={({ currentTarget }: any) => {
                    currentTarget.onerror = null // prevents looping
                    currentTarget.src = `${toAbsoluteUrl('./media/avatars/default-user.png')}`
                  }}
                ></Image>
              </div>
            </Link>
            <Link
              to={`/leaders/${icaniwll?.user?.id}`}
              onClick={() => {
                rediecttoLeaderProfile(icaniwll?.user?.id)
              }}
              data-bs-dismiss='modal'
              aria-label='Close'
            >
              <div className='flex-grow-1 d-flex flex-column commnet_box_right'>
                <div className='commented_user_details mb-1'>
                  <Link to='' className='ilead-text text-hover-primary fs-6 fs-md-5 fw-bold me-2'>
                    {icaniwll.user.name}
                  </Link>
                  <span className='round-separator me-2'></span>
                  <span className='text-gray-600'>{moment(icaniwll.created_at).fromNow()}</span>
                </div>
                {/* <div className='commnet_content text-black'> */}
                {/* {itemMessage.chat} */}
                {/* </div> */}
              </div>
            </Link>
          </div>
        )
      })
    ) : (
      <div className='single_comment_view d-flex align-items-start'>
        <div className='empty-comments text-center w-100'>
          {/* <Image
           fluid
           src={toAbsoluteUrl('/media/ilead/empty-comments.png')}
           className='w-150px mb-4'
         ></Image>
         <h4 className='text-gray-900'>No Comments Yet</h4> */}
          <span className='text-gray-500'>No "I CAN I WILL" by any Leader Yet</span>
        </div>
      </div>
    )
  }

  return (
    <div className='card ilead__card overflow-hidden post-card' style={{ height: '230px' }}>
      {pinnedData.length > 0 ? (
        <Carousel controls={false} activeIndex={indexSlider} onSelect={handleSelect}>
          {pinnedData.map((item: any, index: any) => (
            <Carousel.Item>
              <Button
                variant='link'
                className='p-0 w-100'
                // data-bs-toggle="modal"
                // href={`#pinned_post_${index}`}
                onClick={() => {
                  setShowPinnedPost(true)
                  setShowPinnedPostData(item)
                  setShowPinnedPostIndex(index)
                  // setCurrentPostData(item)
                  // setIndexValue(index)

                  // setIndexSlider(15)
                }}
              >
                <div className='card-header p-3 align-items-start pe-none'>
                  <div className='d-flex justify-content-between flex-nowrap w-100'>
                    <div className='d-flex align-items-start'>
                      <Link to=''>
                        <div
                          className='symbol symbol-45px me-4 top-leader-profile'
                          style={{ borderColor: item?.leaderGIColor }}
                        >
                          <Image
                            src={
                              item?.user?.profile
                                ? `${API_URL}/containers/userprofile/download/${item?.user?.profile}`
                                : `${toAbsoluteUrl('./media/avatars/default-user.png')}`
                            }
                            onError={({ currentTarget }: any) => {
                              currentTarget.onerror = null // prevents looping
                              currentTarget.src = `${toAbsoluteUrl(
                                './media/avatars/default-user.png'
                              )}`
                            }}
                          ></Image>
                        </div>
                      </Link>
                      <div className='flex-grow-1'>
                        <Link
                          to={`/leaders/${item?.user?.id}`}
                          className='text-black text-hover-primary fs-6 fs-md-4 fw-bold d-flex'
                        >
                          {checkDisplayValue(item?.user?.name)}
                        </Link>
                        <div className='d-flex align-items-center'>
                          <span className='text-gray-600 fw-semibold d-block fs-13 me-3'>
                            {moment(item?.updated_at).fromNow()}
                          </span>
                          <span className='round-separator me-3'></span>
                          {/* <Image
                          src={toAbsoluteUrl('/media/ilead/publicpost.png')}
                          height={13}
                        ></Image> */}
                          {item?.chapter_id === 0 ? (
                            <Image
                              src={toAbsoluteUrl('/media/ilead/publicpost.png')}
                              height={13}
                            ></Image>
                          ) : item?.group_id != null ? (
                            <Image
                              src={toAbsoluteUrl('/media/ilead/groupicon.png')}
                              height={13}
                            ></Image>
                          ) : item?.chapter_id != 0 &&
                            item?.group_id === null &&
                            item?.self_post_id === null ? (
                            <Image
                              src={toAbsoluteUrl('/media/ilead/privatepost.png')}
                              height={13}
                            ></Image>
                          ) : (
                            ''
                          )}
                        </div>
                      </div>
                    </div>
                    <div className='card-toolbar my-0'>
                      <div className='m-0 d-flex flex-row align-items-end'>
                        {item?.topic_id == 1 ? (
                          <OverlayTrigger
                            placement='bottom'
                            overlay={
                              <Tooltip className='tooltop-auto-width text-black'>
                                {item?.topics?.name}
                              </Tooltip>
                            }
                          >
                            <div className='post-type'>
                              <div>
                                <SVG
                                  src='/media/ilead/post-type/announcement.svg'
                                  width={20}
                                  height={20}
                                />
                              </div>
                            </div>
                          </OverlayTrigger>
                        ) : (
                          <></>
                        )}

                        {/* <span className='mt-2 fs-13 post-type text-end'>{item?.topics?.name ?? ''}</span> */}
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className={`card-body p-3 pb-7 position-relative ${item?.usertopicmedia?.length > 0 ? 'pe-none' : ''
                    }`}
                  style={{ minHeight: '165px' }}
                >
                  {item.description.trim() == 'Top GI Performers' ||
                    item.description.trim() == 'Top BI Performers' ||
                    (item?.usertopicmedia && item?.usertopicmedia.length > 0) ? (
                    <div
                      className={`pinned__image ${item.description.trim() === 'Top GI Performers' ||
                        item.description.trim() === 'Top BI Performers'
                        ? 'w-100'
                        : 'position-absolute'
                        }`}
                    >
                      {item.description.trim() === 'Top GI Performers' ||
                        item.description.trim() === 'Top BI Performers' ? (
                        <div className='flex-row  row mx-0'>
                          <div className='col-12 text-center mb-2'>
                            {' '}
                            {checkDisplayValue(item?.description)}
                          </div>

                          <div key={index} className='d-flex justify-content-center'>
                            {JSON.parse(item.others).data.map((item2: any, index: any) => (
                              <div className='symbol symbol-70px me-2'>
                                <div className='symbol-label'>
                                  <Image
                                    style={{
                                      width: '100%',
                                      position: 'inherit',
                                      borderRadius: '0.375rem',
                                    }}
                                    src={
                                      item2?.profile
                                        ? `${API_URL}/containers/userprofile/download/${item2?.profile}`
                                        : `${toAbsoluteUrl('./media/avatars/default-user.png')}`
                                    }
                                    onError={({ currentTarget }: any) => {
                                      currentTarget.onerror = null
                                      currentTarget.src = `${toAbsoluteUrl(
                                        './media/avatars/default-user.png'
                                      )}`
                                    }}
                                  ></Image>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      ) : (
                        <div>
                          <Image
                            style={{ width: '100%' }}
                            src={
                              item?.usertopicmedia[0]?.file
                                ? `${API_URL}/containers/topicimages/download/${item?.usertopicmedia[0]?.file}`
                                : `${toAbsoluteUrl('./media/avatars/default-user.png')}`
                            }
                            onError={({ currentTarget }: any) => {
                              currentTarget.onerror = null
                              currentTarget.src = `${toAbsoluteUrl(
                                './media/avatars/default-user.png'
                              )}`
                            }}
                          ></Image>
                        </div>
                      )}
                    </div>
                  ) : (
                    ''
                  )}

                  <div
                    className={`pinned_content description-content ${item?.usertopicmedia.length == 0 &&
                      !(item.description.trim() == 'Top GI Performers') &&
                      !(item.description.trim() == 'Top BI Performers')
                      ? 'only-content'
                      : ''
                      }`}
                  >
                    <div className='fs-7 fs-md-6 fw-normal text-black mb-5 fiveline-truncate text-start'>
                      {item.description.trim() == 'Top GI Performers' ||
                        item.description.trim() == 'Top BI Performers'
                        ? ''
                        : convertTextToLinks(checkDisplayValue(item?.description))}
                    </div>
                  </div>
                </div>
              </Button>
            </Carousel.Item>
          ))}
          {pinnedData.map((item: any, index: any) => {
            ; <div className='modal fade' tabIndex={-1} id={`pinned_post_${index}`}>
              <div className='modal-dialog modal-dialog-centered'>
                <div className='modal-content'>
                  <div className='modal-header'>
                    {item?.topics?.id &&
                      Date.parse(item?.created_at) > Date.parse(moment().format())
                      ? 'Pinned Annoucement'
                      : ''}
                  </div>
                  <div className='modal-body'></div>
                </div>
              </div>
            </div>
          })}
        </Carousel>
      ) : (
        <>
          {postloading ? <AnnouncementAndBirthdayLoadingSkeleton /> : <div className="no-announcement-message">Currently, no announcement is available.</div>}

        </>
      )}

      {/* ===== delete =====*/}
      <div className='modal fade' tabIndex={-1} id={`delete_post_modal${indexValue}`}>
        <div className='modal-dialog modal-dialog-centered'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title'>Are you sure you want to delete this post?</h5>
              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon svg-icon-2x'
                />
              </div>
            </div>
            <div className='modal-body fs-5'>
              <p>This will delete this post permanently. You cannot undo this action.</p>
            </div>
            <div className='modal-footer'>
              <button
                type='button'
                className='btn btn-primary'
                disabled={loading.delete}
                onClick={() => {
                  deletePostAction()
                }}
              >
                {loading?.delete ? 'Please Wait...' : 'Yes'}
              </button>
              <button
                type='button'
                className='btn btn-light'
                data-bs-dismiss='modal'
                ref={deleteModelPostRef}
              >
                No
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className='modal fade' tabIndex={-1} id={`post_comment_view_${indexValue}`}>
        <div className='modal-dialog modal-dialog-scrollable modal-fullscreen-md-down modal-dialog-centered modal-md'>
          <div className='modal-content'>
            <div className='modal-header p-3'>
              <h5 className='modal-title text-center w-100'>Comments</h5>
              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon svg-icon-2x'
                />
              </div>
            </div>
            {loading.list ? (
              <h5 className='text-center'>Loading...</h5>
            ) : (
              <div className='modal-body comment-body'>{commentList()}</div>
            )}
            <div className='modal-footer py-2'>
              <div className='symbol symbol-40px'>
                <Image
                  src={
                    userDetail?.profile
                      ? `${API_URL}/containers/userprofile/download/${userDetail?.profile}`
                      : `${toAbsoluteUrl('./media/avatars/default-user.png')}`
                  }
                  onError={({ currentTarget }: any) => {
                    currentTarget.onerror = null // prevents looping
                    currentTarget.src = `${toAbsoluteUrl('./media/avatars/default-user.png')}`
                  }}
                ></Image>
              </div>
              <div className='comment_text_box m-0 flex-1'>
                {/* <Form> */}
                <Form.Control
                  className='border-0 fw-normal text-black fs-6'
                  as='textarea'
                  placeholder='Write your comment...'
                  value={formik.values.chattext}
                  onChange={(e: any) => {
                    if (e.target.value.trimStart().length <= 250) {
                      formik.setFieldValue('chattext', e.target.value.trimStart())
                    }
                  }}
                  autoFocus
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      formik.handleSubmit()
                      return
                    }
                  }}
                />
                <div className={`d-flex justify-content-end ${formik.values.chattext.length >= 250 ? "text-danger" : ""}`}>
                  <b>{250 - formik.values.chattext.length}{" "} Character Left</b>
                </div>
                {/* </Form> */}
              </div>

              <Button
                variant='link'
                type='button'
                className='p-0 m-0 symbol symbol-40px symbol-circle'
                disabled={loading.send}
                onClick={() => {
                  formik.handleSubmit()
                }}
              >
                <div className='symbol-label bg-primary'>
                  <i className='fa-regular fa-paper-plane text-white'></i>
                </div>
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div className='modal fade' tabIndex={-1} id={`post_icaniwill_leader_view${indexValue}`}>
        <div className='modal-dialog modal-dialog-scrollable modal-fullscreen-md-down modal-dialog-centered modal-md'>
          <div className='modal-content'>
            <div className='modal-header p-3'>
              <h5 className='modal-title text-center w-100'>I CAN I WILL by Leaders</h5>
              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon svg-icon-2x'
                />
              </div>
            </div>
            {loading.list ? (
              <h5 className='text-center'>Loading...</h5>
            ) : (
              <div className='modal-body comment-body'>{icaniwillLeaderList()}</div>
            )}
          </div>
        </div>
      </div>
      <ShowPinnedPostModal
        show={showPinnedPost}
        handleClose={() => {
          setShowPinnedPost(false)
        }}
        data={showPinnedPostData}
        index={showPinnedPostIndex}
        pinnedData={pinnedData}
        setPinnedData={setPinnedData}
      />
    </div>
  )
}
