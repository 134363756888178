import React, { useContext, useEffect, useState } from 'react'
import { Form, Image, InputGroup } from 'react-bootstrap'
import { UserContext } from '../../../../../../contextStore'
import { listingForAllUser } from '../_requests'
import { apiUrl } from '../../../../../../apiurl'
import { toAbsoluteUrl } from '../../../../../../_metronic/helpers'

const API_URL = apiUrl;
export function SearchLeaderListing({ onClick,onSelectLeader }: any) {
  const { userDetail } = useContext(UserContext)
  const [leaderListing, setLeaderListing] = useState<any>([])
  const [isLoading, setIsLoading] = useState(true)
  const [leaderName, setLeaderName] = useState<any>('')
  useEffect(() => {
    fetchData()
  }, [userDetail.id,leaderName])

  const fetchData = async () => {
    try {
      const listingLeaderData = await listingForAllUser(userDetail.id, leaderName);
      setLeaderListing(() => listingLeaderData ?? []);
      setIsLoading(false)
    } catch (error) {
      console.error('Error -->:', error)
      setIsLoading(false)
    }

  }

  return (
    <div className='leader-listing'>
      <Form.Group className='mb-3'>
        <InputGroup>
          <InputGroup.Text id='SearchLeader' className='bg-white ilead-input-group'>
            <i className='fa-solid fa-magnifying-glass'></i>
          </InputGroup.Text>
          <Form.Control
            placeholder='Type leader name'
            aria-label='LeaderSearch'
            aria-describedby='LeaderSearch'
            className='border-start-0 ps-0 ilead-input-group'
            onChange={(e: any) => setLeaderName(e.target.value)}
          />
        </InputGroup>
      </Form.Group>
      {leaderListing.map((item: any) => (
        <div
          className='small_leader_box d-flex align-items-center mb-5 cursor-pointer'
          onClick={() => {
            onClick(true)
            onSelectLeader(item.id);
            
          }}
        >
          <div className='symbol symbol-45px me-3'>
            {/* <Image src={item.photo}></Image> */}
            <Image
              src={
                item.profile
                  ? `${API_URL}/containers/userprofile/download/${item.profile}`
                  : `${toAbsoluteUrl('./media/avatars/default-user.png')}`
              }
              onError={({currentTarget}: any) => {
                currentTarget.onerror = null // prevents looping
                currentTarget.src = `${toAbsoluteUrl('./media/avatars/default-user.png')}`
              }}
            />
          </div>
          <div className='leader_details'>
            <div className='text-black fs-6 fs-md-5 fw-bold mb-0'>{item.name}</div>
            <span className='fs-14 text-gray-600'>{item.companyname}</span>
          </div>
        </div>
      ))}
    </div>
  )
}
