import axios, {AxiosResponse} from 'axios'
// import {ID, Response} from '../../../../../../_metronic/helpers'
import {Chapter, ChaptersQueryResponse} from './_models'
import {ID, Response} from '../../../../../_metronic/helpers'
// import { ID, Response } from '../../../../../_metronic/helpers'
import {apiUrl} from '../../../../../apiurl'

const API_URL = apiUrl
const CHAPTER_URL = `${API_URL}/chapters/findAllChapters`
const CHAPTER_ONE_URL = `${API_URL}/chapters/findOneChapters`
const DELETE_CHAPTERS_URL = `${API_URL}/chapters/deleteChapters`
const EDIT_CHAPTER_URL = `${API_URL}/chapters/updateChapters`
const ADD_CHAPTER_URL = `${API_URL}/chapters/createChapters`

const getChapters = (query: string): Promise<ChaptersQueryResponse> => {
  return axios
    .get(`${CHAPTER_URL}?${query}`)
    .then((d: AxiosResponse<any>) => d.data)
    .catch((e) => console.log(e))
}

const getChapterById = (id: ID): Promise<Chapter | undefined> => {
  return (
    axios
      .get(`${CHAPTER_ONE_URL}/${id}`)
      // .then((response: AxiosResponse<Response<Chapter>>) => response.data).catch((e) => console.log(e))
      .then((response: Response<any>) => response.data)
      .catch((e) => console.log(e))
  )
}

const createChapter = (data: any): Promise<Chapter | undefined> => {
  return (
    axios
      .post(ADD_CHAPTER_URL, data)
      // .then((response: AxiosResponse<Response<Chapter>>) => response.data).catch((e) => console.log(e))
      .then((response: any) => response)
      .catch((e) => console.log(e))
  )
}

const updateChapter = (chapterId: any, data: any): Promise<Chapter | undefined> => {
  return (
    axios
      .patch(`${EDIT_CHAPTER_URL}/${chapterId}`, data)
      // .then((response: AxiosResponse<Response<Chapter>>) => response.data).catch((e) => console.log(e))
      .then((response: any) => response)
      .catch((e) => console.log(e))
  )
}

const deleteChapter = (chapterId: ID): Promise<void> => {
  return axios
    .delete(`${DELETE_CHAPTERS_URL}/${chapterId}`)
    .then((response: any) => response)
    .catch((e) => e)
}

const deleteSelectedChapters = (chapterIds: Array<ID>): Promise<void> => {
  const requests = chapterIds.map((id) => axios.delete(`${CHAPTER_URL}/${id}`))
  return axios.all(requests).then(() => {})
}

export {
  getChapters,
  deleteChapter,
  deleteSelectedChapters,
  getChapterById,
  createChapter,
  updateChapter,
}
