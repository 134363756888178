import React, {useCallback, useContext, useEffect, useRef, useState} from 'react'
import {Button, OverlayTrigger, Tooltip} from 'react-bootstrap'
import {useDropzone} from 'react-dropzone'
import {useNavigate, useParams} from 'react-router-dom'
import * as Yup from 'yup'
import {createGroupmeeting} from './groups-list/core/_requests'
import moment from 'moment'
import {apiUrl} from '../../../apiurl'
import axios from 'axios'
import {useFormik} from 'formik'
import {UserContext} from '../../../contextStore'

const API_URL = apiUrl
const IMAGE_UPLOAD = `${API_URL}/containers/topicimages/upload`
const Group_name = `${API_URL}/groups`
const Group_Members = `${API_URL}/users`
const Meeting_Points = `${API_URL}/groupmeetingpoints`
const groupMeetingReport = `${API_URL}/groupmeetingreports/editreport`
const VIEWBYID_GROUPMEETING_URL = `${API_URL}/groupmeetingreports/viewreport`

const groupMRForm = Yup.object().shape({
  comments: Yup.string().min(5, 'Minimum 5 charaters').max(500, 'Maximum 500 characters'),
  agenda: Yup.string().required('This field is required'),
  meetingdate: Yup.string().required('This field is required'),
  starttime: Yup.string(),
  endtime: Yup.string()
    .required('This field is required')
    .test('is-greater', 'End time must be greater than start time', function (value) {
      const {starttime} = this.parent
      if (starttime && value) {
        const startTimeMoment = moment(starttime, 'HH:mm')
        const endTimeMoment = moment(value, 'HH:mm')
        return endTimeMoment.isAfter(startTimeMoment)
      }
      return true
    }),
  participants: Yup.array(),
  group_id: Yup.number(),
  user_id: Yup.string(),
  meetingpoints: Yup.array(),
  filename: Yup.array(),
})

const EditGroupMeetingReport = () => {
  const {id} = useParams()
  //   const {acceptedFiles, getRootProps, getInputProps} = useDropzone()
  //   const files = acceptedFiles.map((file: any) => (
  //     <li>
  //       {' '}
  //       {file?.path} - {file?.size} bytes{' '}
  //     </li>
  //   ))

  const navigate = useNavigate()
  const navigateGroupMeetingReport = () => {
    // 👇️ navigate to /
    navigate('/group-meeting-report')
  }
  const [loading, setLoading] = useState(false)
  const {userDetail, setMessage} = useContext(UserContext)
  const userid = userDetail?.id ?? ''
  const groupid = userDetail?.group_id ?? ''
  const userName = userDetail?.name ?? ''
  const [groupName, setGroupName] = useState<any>('')
  const [groupMember, setGroupMember] = useState<any>([])
  const [meetingPointsName, setMeetingPointsName] = useState<any>([])
  const [meetingPoints, setMeetingPoints] = useState<number[]>(() => meetingPointsName.map(() => 0))
  const [errorMessage, setErrorMessage] = useState(false)
  const closeModal = useRef<any>()
  const [selectedFile, setSelectedFile] = useState<any>(null)
  const [imageName, setImageName] = useState<any>('')
  const [data, setData] = useState<any>([])
  const [selectedParticipants, setSelectedParticipants] = useState<string[]>([])
  const participantsAsString = selectedParticipants.map((participant) => participant.toString())
  const [error, setError] = useState('')

  const onDrop = useCallback((acceptedFiles: any) => {
    console.log('acceptedFiles', acceptedFiles[0]?.type)
    setError('')
    if (acceptedFiles[0].type == 'image/jpeg' || acceptedFiles[0]?.type == 'image/png') {
      const file = acceptedFiles[0]
      setSelectedFile(file)
    } else {
      setError('Only JPEG and PNG files are allowed.')
    }
  }, [])

  const {getRootProps, getInputProps} = useDropzone({
    onDrop,
    accept: ['image/jpeg', 'image/png'] as any, // Specify accepted file types (e.g., images)
    maxFiles: 1, // Maximum number of files to upload (1 in this case)
  })

  useEffect(() => {
    fetchGroupName()
    fetchGroupMemberName()
    fetchMeetingPointsName()
    fetchGroupMember()
  }, [])

  useEffect(() => {
    fetchGroupMember()
  }, [data])

  useEffect(() => {
    setMeetingPoints(meetingPointsName.map(() => 0))
  }, [meetingPointsName])

  useEffect(() => {}, [meetingPoints])

  useEffect(() => {
    if (selectedFile) {
      setLoading(true)
      try {
        uploadImage()
      } catch (e) {
        setMessage('error', 'Failed to upload image.')
      }
      setLoading(false)
    }
    console.log('imageNameimageName', imageName)
  }, [selectedFile])

  useEffect(() => {
    fetchData()
  }, [])

  const fetchData = async () => {
    try {
      const res = await axios.get(`${VIEWBYID_GROUPMEETING_URL}/${id}`)
      setData(res?.data ?? [])
    } catch (error) {
      console.log('Error -->:', error)
    }
  }

  console.log('viewbyiddata', data)

  const fetchGroupName = async () => {
    try {
      const res = await axios.get(`${Group_name}/${groupid}`)
      setGroupName(res?.data?.name ?? [])
    } catch (error) {
      console.error('Error -->:', error)
    }
  }

  const fetchGroupMemberName = async () => {
    try {
      const res = await axios.get(
        `${Group_Members}/?filter[where][group_id]=${groupid}&filter[where][isactive]=1&filter[where][isdelete]=0`
      )
      setGroupMember(res?.data ?? [])
    } catch (error) {
      console.error('Error -->:', error)
    }
  }

  const fetchGroupMember = async () => {
    try {
      if (data?.group_id) {
        const res = await axios.get(
          `${Group_Members}/?filter[where][group_id]=${data.group_id}&filter[where][isactive]=1&filter[where][isdelete]=0`
        )
        const dataa = [...res?.data] ?? []
        setGroupMember(dataa)
      }
    } catch (error) {
      console.error('Error -->:', error)
    }
  }

  const fetchMeetingPointsName = async () => {
    try {
      const res = await axios.get(`${Meeting_Points}?filter[isdelete]=0`)
      setMeetingPointsName(res?.data ?? [])
    } catch (error) {
      console.error('Error -->:', error)
    }
  }

  const handleParticipantSelection = (participantId: string) => {
    setSelectedParticipants((prevSelectedParticipants) => {
      if (prevSelectedParticipants.includes(participantId)) {
        return prevSelectedParticipants.filter((id) => id !== participantId)
      } else {
        return [...prevSelectedParticipants, participantId]
      }
    })
  }

  const handleMeetingPointChange = (index: number, checked: boolean) => {
    setMeetingPoints((prevMeetingPoints) => {
      const updatedMeetingPoints = [...prevMeetingPoints]
      updatedMeetingPoints[index] = checked ? 1 : 0
      return updatedMeetingPoints
    })
  }

  const uploadImage = async () => {
    try {
      if (selectedFile) {
        const formData = new FormData()
        formData.append('file', selectedFile)

        const response = await axios.post(IMAGE_UPLOAD, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Content-Disposition': `attachment; filename="${selectedFile.name}"`,
          },
        })

        if (response.status === 200) {
          setImageName(response?.data?.result?.files?.file[0]?.name)
          console.log('Image uploaded successfully3:', response?.data?.result?.files?.file[0]?.name)
        } else {
          console.error('Image upload failed:', response.statusText)
        }
      }
    } catch (error) {
      console.error('Error uploading image:', error)
    }
  }

  const initialValues = {
    group_id: '',
    agenda: '',
    meetingdate: '',
    starttime: '',
    endtime: '',
    participants: [],
    comments: '',
    filename: '',
    meetingpoints: [],
    user_id: '',
  }

  const groupMRFormFormik = useFormik({
    initialValues,
    validationSchema: groupMRForm,
    onSubmit: async (values, {setStatus, resetForm, setSubmitting}) => {
      setLoading(true)
      if (selectedParticipants.length === 0) {
        setErrorMessage(true)
        return
      }
      try {
        const postData = {
          group_id: data?.group_id ?? groupid,
          agenda: values.agenda,
          meetingdate: values.meetingdate,
          starttime: values.starttime,
          endtime: values.endtime,
          participants: participantsAsString ?? '',
          comments: values.comments,
          filename: imageName ?? '',
          meetingpoints: meetingPoints ?? '',
          user_id: data?.submitted_by ?? userid,
        }
        await axios
          .post(`${groupMeetingReport}/${id}`, postData)
          .then((res: any) => {
            if (res?.data) {
              setMessage('success', 'Group meeting report updated')
              setLoading(false)
              //   setSelectedFile('')
              setErrorMessage(false)
              setMeetingPoints([])
              setSelectedFile(null)
              //   setArrayOfFile([])
              setSelectedParticipants([])
              setImageName('')
              //   sendSmsAndNotification({
              //     thankyouhistoryid: null,
              //     group_meeting_report_id: res?.data?.id ?? '',
              //     user_topic_id: res?.data?.usertopicdata?.id ?? '',
              //     userid: userDetail?.id ?? '',
              //     accesstoken: localStorage.getItem('authToken'),
              //   })
              resetForm()
              closeModal.current.click()
            }
          })
          .catch((error: any) => {
            if (error?.response?.data?.error?.message) {
              setMessage('error', error?.response?.data?.error?.message)
            } else {
              setMessage('error', 'Something Went Wrong')
            }
            setLoading(false)
          })
      } catch (error: any) {
        setMessage('error', error?.message ? error?.message : 'Something Went Wrong')
        setLoading(false)
      }
    },
  })

  function convertURLToBlob(imageURL: any) {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest()
      xhr.responseType = 'blob'

      xhr.onload = () => {
        if (xhr.status === 200) {
          resolve(xhr.response)
        } else {
          reject(new Error(`Failed to fetch image: ${xhr.status} - ${xhr.statusText}`))
        }
      }

      xhr.onerror = () => {
        reject(new Error('Network error'))
      }

      xhr.open('GET', imageURL)
      xhr.send()
    })
  }

  useEffect(() => {
    //     const imageFile = convertURLToBlob(`${API_URL}/containers/userprofile/download/${data?.image}`)
    if (id) {
      console.log('logg', data?.groupMeetingPointIds ? data?.groupMeetingPointIds : [])
      groupMRFormFormik.setFieldValue('agenda', data ? data?.agenda_of_meeting : '')
      groupMRFormFormik.setFieldValue(
        'meetingdate',
        data?.meeting_date ? moment(data?.meeting_date).format('YYYY-MM-DD') : ''
      )
      groupMRFormFormik.setFieldValue('starttime', data ? data?.start_time : '')
      groupMRFormFormik.setFieldValue('endtime', data ? data?.end_time : '')
      groupMRFormFormik.setFieldValue('comments', data ? data?.comments : '')
      setSelectedParticipants(data?.participantIds ? data?.participantIds : [])
      setMeetingPoints(data?.groupMeetingPointIds ? data?.groupMeetingPointIds : [])

      //   setSelectedParticipants(
      //     data?.participantIds.map((id: number) => id.toString()) || []
      //   );
      convertURLToBlob(`${API_URL}/containers/topicimages/download/${data?.image}`)
        .then((imageBlob: any) => {
          // Set the Blob as selectedFile
          setSelectedFile(new File([imageBlob], data?.image)) // Create a File object from the Blob
          setImageName(data?.image)
          console.log('selecttttt', selectedFile)
        })
        .catch((error) => {
          console.error(error)
        })
    }
  }, [data])

  console.log(groupMRFormFormik)
  console.log(selectedFile)

  return (
    <>
      <div className='row flex-md-block '>
        <div className='col-sm-12 col-md-6 col-lg-6'>
          <h1 className='inv-hst sm-ms-2'> Edit Group Meeting Reports </h1>
        </div>

        <div className='col-sm-12  col-md-6 col-lg-6 text-end pe-5'>
          <button
            onClick={navigateGroupMeetingReport}
            className='btn btn-info btn-sm waves-effect waves-light '
            ref={closeModal}
          >
            {' '}
            Back{' '}
          </button>
        </div>
      </div>

      <div className='card  p-8 mt-5 visitor-details'>
        <form
          onSubmit={(e) => {
            e.preventDefault()
            groupMRFormFormik.handleSubmit(e)
          }}
        >
          <div className='row mb-8'>
            <div className='col-sm-12 col-md-6 col-lg-9'>
              <label className='form-label fs-5'>Report Submitted For Group</label>
              <p className='pb-5 fs-5 fw-semibold text-start text-gray-700'> {data?.groupName} </p>
            </div>
            <div className='col-sm-12 col-md-6 col-lg-3'>
              <label className='form-label fs-5'>
                Agenda of the meeting <span className='text-danger'> * </span>
              </label>
              <select
                className='form-select border border-gray-400'
                aria-label='Default select example'
                onChange={(e) => {
                  groupMRFormFormik.setFieldValue('agenda', e.target.value)
                }}
                value={groupMRFormFormik.values.agenda}
              >
                <option>Select Option</option>
                <option value='TYCA Discussion'>TYCA Discussion</option>
                <option value='Goal Review'>Goal Review</option>
                <option value='Other'>Other</option>
              </select>
              {groupMRFormFormik.touched.agenda && groupMRFormFormik.errors.agenda && (
                <div className='fv-plugins-message-container text-danger'>
                  <span role='alert'>{groupMRFormFormik.errors.agenda as any}</span>
                </div>
              )}
            </div>
          </div>

          <div className='row mb-8'>
            <div className='col-sm-12 col-md-12 col-lg-4'>
              <label className='form-label fs-5'>
                Meeting Date <span className='text-danger'> * </span>
              </label>
              <input
                type='date'
                className='form-control'
                id=''
                placeholder=''
                onChange={(e) => {
                  groupMRFormFormik.setFieldValue('meetingdate', e.target.value)
                  console.log('date', e.target.value)
                }}
                value={groupMRFormFormik.values.meetingdate}
                required
              />
              {groupMRFormFormik.touched.meetingdate && groupMRFormFormik.errors.meetingdate && (
                <div className='fv-plugins-message-container text-danger'>
                  <span role='alert'>{groupMRFormFormik.errors.meetingdate as any}</span>
                </div>
              )}
            </div>
            <div className='col-sm-12 col-md-12 col-lg-4'>
              <label className='form-label fs-5'>
                Meeting Start Time <span className='text-danger'> * </span>
              </label>
              <input
                type='time'
                className='form-control'
                id=''
                placeholder=''
                onChange={(e) => {
                  groupMRFormFormik.setFieldValue('starttime', e.target.value)
                }}
                value={groupMRFormFormik.values.starttime}
                required
              />
              {groupMRFormFormik.touched.starttime && groupMRFormFormik.errors.starttime && (
                <div className='fv-plugins-message-container text-danger'>
                  <span role='alert'>{groupMRFormFormik.errors.starttime as any}</span>
                </div>
              )}{' '}
            </div>
            <div className='col-sm-12 col-md-12 col-lg-4'>
              <label className='form-label fs-5'>
                Meeting End Time <span className='text-danger'> * </span>
              </label>
              <input
                type='time'
                className='form-control'
                id=''
                placeholder=''
                onChange={(e) => {
                  groupMRFormFormik.setFieldValue('endtime', e.target.value)
                }}
                value={groupMRFormFormik.values.endtime}
                required
              />
              {groupMRFormFormik.touched.endtime && groupMRFormFormik.errors.endtime && (
                <div className='fv-plugins-message-container text-danger'>
                  <span role='alert'>{groupMRFormFormik.errors.endtime as any}</span>
                </div>
              )}{' '}
            </div>
          </div>

          <div className='row mb-8'>
            <div className='col-sm-12 col-md-12'>
              <label className='form-label mb-3 fs-5'>
                Participants <span className='text-danger'> * </span>
              </label>
              {groupMember.map((item: any) => (
                <div className='row col-8'>
                  <div className='col-6'>
                    <div className='form-check'>
                      <>
                        <input
                          key={item.id}
                          className='form-check-input border'
                          type='checkbox'
                          value=''
                          id='flexCheckChecked'
                          onChange={() => handleParticipantSelection(item.id)}
                          checked={selectedParticipants.includes(item.id)}
                        />
                        <label key={item.id} className='form-check-label'>
                          {item.name}
                        </label>
                      </>
                    </div>
                  </div>
                </div>
              ))}
              {errorMessage && selectedParticipants.length === 0 ? (
                <div className='fv-plugins-message-container text-danger'>
                  <span role='alert'>This field is required</span>
                </div>
              ) : (
                ''
              )}
            </div>
          </div>
          <div className='row mt-5'>
            <label className='form-label fs-5'>What Went Well / What Can Be Improved</label>
          </div>
          {meetingPointsName.map((item: any, index: number) => (
            <div
              key={index}
              className='row border mx-0 p-2 align-items-center border-gray-300 box-1'
            >
              <div className='col-sm-12 col-md-8'>{item?.title}</div>
              <div className='col-sm-12 col-md-4 text-end position-relative'>
                <label className='toggle mt-2 me-6'>
                  <input
                    type='checkbox'
                    onChange={(e) => handleMeetingPointChange(index, e.target.checked)}
                    checked={meetingPoints[index] === 1}
                  />
                  <span className='slider'></span>
                  <span className='labels' data-on='Went Well' data-off='Can be Improved'></span>
                </label>
              </div>
            </div>
          ))}
          <div className='col-sm-12 col-md-12 mt-6'>
            <div className='form-floating mb-8'>
              <textarea
                className='form-control'
                placeholder=' '
                id='floatingTextarea'
                required
                style={{height: '80px'}}
                onChange={(e) => {
                  groupMRFormFormik.setFieldValue('comments', e.target.value.trimStart())
                }}
                value={groupMRFormFormik.values.comments}
              ></textarea>
              <label>
                {' '}
                Any Other Comments <span className='text-danger'> * </span>
              </label>
              {groupMRFormFormik.touched.comments && groupMRFormFormik.errors.comments && (
                <div className='fv-plugins-message-container text-danger'>
                  <span role='alert'>{groupMRFormFormik.errors.comments as any}</span>
                </div>
              )}
            </div>
          </div>
          <div className='col-12 mt-6'>
            <section className='container px-0'>
              <h4 className='fw-normal fs-6 border-gray-600'>
                {' '}
                Upload Group Photo
                <OverlayTrigger
                  overlay={
                    <Tooltip id='tooltip-disabled' className='text-white'>
                      (Supported files: .jpg, .jpeg, .png <br /> Max File Size: 10MB){' '}
                    </Tooltip>
                  }
                >
                  <span className='d-inline-block'>
                    <i className='fa-solid fs-3 text-primary ms-3 fa-circle-info'></i>
                  </span>
                </OverlayTrigger>
                {/* <span> 
                                                <i className="fa-solid fs-3 text-primary ms-3 fa-circle-info" data-bs-toggle="tooltip" data-bs-placement="top" title="Tooltip on top"></i> 
                                                </span> */}
              </h4>
              {error && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{error}</span>
                  </div>
                </div>
              )}
              <div {...getRootProps({className: 'dropzone'})}>
                <input
                  onClick={() => {
                    setError('')
                  }}
                  {...getInputProps()}
                />
                {selectedFile ? (
                  <div style={{position: 'relative'}}>
                    <div>
                      <h2>
                        <i
                          className='fa fa-trash fa-lg text-dark '
                          onClick={(e) => {
                            e.stopPropagation()
                            setSelectedFile(null)
                            setImageName(null)
                          }}
                          style={{
                            position: 'absolute',
                            top: '-42px',
                            right: '-34px',
                            backgroundColor: '#f89494',
                            padding: '4px',
                            borderRadius: '50%',
                            zIndex: '1',
                          }}
                        ></i>
                      </h2>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                      }}
                    >
                      <img
                        src={URL.createObjectURL(selectedFile)}
                        alt='Selected'
                        style={{maxWidth: '100%', maxHeight: '70px'}}
                      />
                      <p>{selectedFile?.name}</p>{' '}
                    </div>
                  </div>
                ) : (
                  <p>Drag 'n' drop some files here, or click to select files</p>
                )}
              </div>
            </section>
          </div>

          <div className='col-sm-12 col-md-3 mt-6'>
            <label className='form-label fs-5'>Submitted By:</label>
            <p className='fs-5 fw-semibold text-start text-gray-700 pb-1 mt-2 border-bottom border-gray-300'>
              {' '}
              {data?.submittedByName}{' '}
            </p>
          </div>

          <div className='col-12 mt-15 text-center pe-5'>
            <Button
              variant='primary'
              type='submit'
              className='w-auto'
              style={{backgroundColor: '#f5f5f5'}}
              disabled={
                groupMRFormFormik.isSubmitting ||
                selectedParticipants.length === 0 ||
                !groupMRFormFormik.values.agenda ||
                !groupMRFormFormik.values.endtime ||
                !groupMRFormFormik.values.starttime ||
                !groupMRFormFormik.values.comments ||
                loading
              }
              onClick={() => {
                if (selectedParticipants.length === 0) {
                  setErrorMessage(true)
                }
              }}
            >
              Submit
            </Button>
          </div>
        </form>
      </div>
    </>
  )
}

export default EditGroupMeetingReport
