import {useEffect, useMemo, useState} from 'react'
import {useTable, ColumnInstance, Row} from 'react-table'
import {CustomHeaderColumn} from './columns/CustomHeaderColumn'
import {CustomRow} from './columns/CustomRow'
import {useQueryResponseData, useQueryResponseLoading} from '../core/QueryResponseProvider'
import {requestusersColumns} from './columns/_columns'
import {Requestuser, RequestusersQueryResponse} from '../core/_models'
// import { KTCardBody, defaultRequestusers } from '../../../../../_metronic/helpers'
import {RequestusersListPagination} from '../components/pagination/RequestusersListPagination'
import {RequestusersListLoading} from '../components/loading/RequestusersListLoading'
import {KTCardBody, defaultRequestusers} from '../../../../../../_metronic/helpers'
import {getChapterList} from '../../../../auth/core/_requests'
// import {KTCardBody} from '../../../../../../_metronic/helpers'
import {initialQueryState} from '../../../../../../_metronic/helpers'
import {useQueryRequest} from '../core/QueryRequestProvider'
import { checkDisplayValue } from '../../../../../../utils'

const RequestusersTable = () => {
  const requestusers = useQueryResponseData()
  // const requestusers = defaultRequestusers
  const {updateState} = useQueryRequest()
  const isLoading = useQueryResponseLoading()
  const data = useMemo(() => requestusers, [requestusers])
  const columns = useMemo(() => requestusersColumns, [])
  const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
    columns,
    data,
  })

  const [chapterList, setchapterList] = useState([])
  const [reqData, setReqData] = useState<any>({
    name: '',
    mobileno: '',
    company: '',
    chapter_id: '',
    companyname: '',
    // isactive: '',
  })

  const getchpterList = async () => {
    try {
      const res = await getChapterList()
      setchapterList(res?.data)
    } catch (error) {}
  }

  useEffect(() => {
    getchpterList()
  }, [])

  const filterData = () => {
    updateState({
      filter: reqData,
      ...initialQueryState,
    })
  }

  useEffect(() => {
    filterData()
  }, [reqData])
  return (
    <KTCardBody className='p-0'>
      <div className='table-responsive mb-8 data-table-report'>
        <table
          id='kt_table_requestusers'
          className='table align-middle table-row-dashed fs-6 gy-5 dataTable table-striped  no-footer'
          {...getTableProps()}
        >
          <thead>
            <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0 border-bottom border-gray-100'>
              {headers.map((column: ColumnInstance<Requestuser>) => (
                <CustomHeaderColumn key={column.id} column={column} />
              ))}
            </tr>
          </thead>

          <thead className='table-sub-hdr'>
            <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
              <th className='p-3'>
                {' '}
                <select
                  className='form-select form-select-lg mb-3 mt-2'
                  onChange={(e: any) => {
                    console.log('3', e?.target?.value)
                    setReqData({...reqData, ...{chapter_id: e?.target?.value?.trimStart()}})
                  }}
                  aria-label='.form-select-lg example'
                >
                  {/* <option selected>All</option>
                <option value="2">Eklavya</option> */}
                  <option selected={reqData?.chapter_id == ''} value=''>
                    {' '}
                    ALL{' '}
                  </option>
                  {chapterList.map((item: any, i: any) => {
                    return (
                      <option selected={reqData?.chapter_id == item?.id} value={item.id}>
                        {item.name}
                      </option>
                    )
                  })}
                </select>{' '}
              </th>
              <th className='p-3'>
                {' '}
                <input
                  type='text'
                  className='form-control mb-3'
                  onChange={(e: any) => {
                    setReqData({...reqData, ...{name: e?.target?.value?.trimStart()}})
                  }}
                  placeholder=' '
                />{' '}
              </th>
              <th className='p-3'>
                {' '}
                <input
                  type='text'
                  className='form-control mb-3'
                  onChange={(e: any) => {
                    setReqData({...reqData, ...{mobileno: e?.target?.value?.trimStart()}})
                  }}
                  placeholder=''
                />{' '}
              </th>
              <th className='p-3'>
                {' '}
                <input
                  type='text'
                  className='form-control mb-3'
                  onChange={(e: any) => {
                    setReqData({...reqData, ...{email: e?.target?.value?.trimStart()}})
                  }}
                  placeholder=''
                />{' '}
              </th>
              <th className='p-3'>
                {' '}
                <input
                  type='text'
                  className='form-control mb-3'
                  onChange={(e: any) => {
                    setReqData({...reqData, ...{companyname: e?.target?.value?.trimStart()}})
                  }}
                  placeholder=''
                />{' '}
              </th>
              <th className='p-3'> &nbsp; </th>
              <th className='p-3'>
                {' '}
                <select
                  className='form-select form-select-lg mb-3 mt-2'
                  aria-label='.form-select-lg example'
                >
                  <option selected>All</option>
                  <option value='2'>Yes</option>
                  <option value='2'>No</option>
                </select>{' '}
              </th>
              <th className='p-3'> &nbsp; </th>
            </tr>
          </thead>

          <tbody className='text-gray-600 text-center fw-bold' {...getTableBodyProps()}>
            {rows.length > 0 ? (
              rows.map((row: Row<any>, i) => {
                prepareRow(row)
                return <CustomRow row={row as any} key={`row-${i}-${row.id}`} />
              })
            ) : (
              <tr>
                <td colSpan={7}>
                  <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                    No matching records found
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
        <table
          id='copy_kt_table_requestusers'
          className='table align-middle table-row-dashed fs-6 gy-5 dataTable table-striped  no-footer'
          {...getTableProps()}
          style={{display: 'none'}}
        >
          <thead>
            <tr className='text-center text-muted fw-bolder fs-7 text-uppercase gs-0 border-bottom border-gray-100'>
              {headers.map((column: ColumnInstance<any>) => {
                if (column.id == 'isaccept') {
                  return <></>
                }
                return <CustomHeaderColumn key={column.id} column={column} />
              })}
            </tr>
          </thead>

          <tbody className='text-gray-600 text-center fw-bold' {...getTableBodyProps()}>
            {rows.length > 0 ? (
              rows.map((row: Row<any>, i) => {
                return (
                  <tr key={i}>
                    <td>
                        {checkDisplayValue(row?.original?.chapter_name)}
                    </td>
                    <td>
                        {checkDisplayValue(row?.original?.name)}
                    </td>
                    <td>
                        {checkDisplayValue(row?.original?.mobileno)}
                    </td>
                    <td>
                        {checkDisplayValue(row?.original?.email)}
                    </td>
                    <td>
                        {checkDisplayValue(row?.original?.companyname)}
                    </td>
                    <td>
                        {checkDisplayValue(row?.original?.verificationcode)}
                    </td>
                    <td>
                        {checkDisplayValue(row?.original?.isactive ? "Yes" : "No")}
                    </td>
                </tr>
                )
              })
            ) : (
              <tr>
                <td colSpan={7}>
                  <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                    No matching records found
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <RequestusersListPagination />
      {isLoading && <RequestusersListLoading />}
    </KTCardBody>
  )
}

export {RequestusersTable}
