import React, { useContext, useEffect, useState } from "react";
import { Form, Spinner } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { getChaptersList, getTemplateById, updateTemplate } from "./templates-list/core/_requests";
import { useFormik } from "formik";
import * as Yup from 'yup'
import { UserContext } from "../../../../contextStore";

const EditTemplate = () => {
    const { id } = useParams()
    const navigate = useNavigate()
    const { userDetail, setMessage } = useContext(UserContext)
    const [chapterList, setChapterList] = useState<any>([])
    const [loading, setLoading] = useState<any>({ editData: false, saveData: false })
    const [templateData, setTemplateData] = useState<any>({})

    const editTemplateValidation = Yup.object().shape({
        sms_text: Yup.string().required('This Field is required'),
        email_subject: Yup.string().required('This Field is required'),
        email_text: Yup.string().required('This Field is required'),
        push_text: Yup.string().required('This Field is required'),
    })

    const initialValues = {
        name: '',
        sms_text: '',
        email_subject: '',
        email_text: '',
        push_text: '',
        default_url_data: ''
    }

    const getChapter = async () => {
        await getChaptersList().then((response: any) => {
            if (response && response?.length > 0) {
                let clist = response?.map((x: any) => {
                    return { label: x?.name, value: x?.id }
                }) as any ?? []
                setChapterList(clist)
            }
        }).catch((error: any) => {
            console.log("error", error)
        })
    }

    const formik = useFormik({
        initialValues,
        validationSchema: editTemplateValidation,
        onSubmit: async (values: any) => {
            setLoading({ ...loading, ...{ saveData: true } })
            try {
                let newPayload = {} as any
                if (userDetail?.id == 1 || userDetail?.id == 2 || userDetail?.id == 3 || userDetail?.id == 5) {
                    newPayload.template_repeat = "daily";
                    newPayload.default_url_data = values.default_url_data;
                } else {
                    newPayload.template_repeat = null;
                    newPayload.default_url_data = null;
                }
                newPayload.name = templateData?.name
                newPayload.sms_text = values?.sms_text
                newPayload.email_subject = values?.email_subject
                newPayload.email_text = values?.email_text
                newPayload.push_text = values?.push_text
                await updateTemplate(id,newPayload).then((data:any)=>{
                    if(data?.id){
                        setMessage('success','Notification Templates Updated Successfully')
                    }
                    else{
                        setMessage('error',data?.message ?? "Something Went Wrong")
                    }
                }).catch((error:any)=>{
                    setMessage('error',error?.message ?? "Something Went Wrong")
                    
                })
                setLoading({ ...loading, ...{ saveData: false } })
                setTimeout(()=>{
                    navigate('/message-center-template')
                },500)
                // alert(JSON.stringify(newPayload))
            } catch (error: any) {
                setMessage('error',error?.message ?? "Something Went Wrong")
                setLoading({ ...loading, ...{ saveData: false } })
            }
        },
    }) as any

    const getTemplate = async () => {
        try {
            setLoading({ ...loading, ...{ editData: true } })
            await getTemplateById(id).then((data: any) => {
                if (data?.id) {
                    setTemplateData(data)
                    formik.setFieldValue('name', data?.name ?? "")
                    formik.setFieldValue('sms_text', data?.sms_text ?? "")
                    formik.setFieldValue('email_subject', data?.email_subject ?? "")
                    formik.setFieldValue('email_text', data?.email_text ?? "")
                    formik.setFieldValue('push_text', data?.push_text ?? "")
                    formik.setFieldValue('default_url_data', data?.default_url_data ?? "")
                }
            }).catch((err: any) => {
                console.log("Error :: ", err)
            })
            setLoading({ ...loading, ...{ editData: false } })
        }
        catch (err) {
            setLoading({ ...loading, ...{ editData: false } })
            console.log("Error :: ", err)
        }
    }

    useEffect(() => {
        getChapter()
        getTemplate()
    }, [])

    return (
        <>
            <h1 className='inv-hst'> Edit Templates </h1>
            {
                loading.editData ?
                    <div
                        style={{
                            height: '550px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <Spinner animation='border' />
                    </div> :
                    <div className='card  py-8 px-5 px-sm-8 mt-5 ilead__card'>
                        <form action="">
                            <div className="row">
                                <div className='col-12  mb-10'>
                                    <label className='fw-semibold fs-5' > Template Name <span className='text-danger fs-8'> * </span></label>
                                    <Form.Control
                                        type='text'
                                        value={formik.values.name}
                                        disabled
                                        className='border-left-0 border-top-0 border-right-0 rounded-0 ps-0'
                                    />
                                </div>
                            </div>

                            <div className="row ">
                                <div className="col-md-12 col-lg-6 mb-8">
                                    <label className="fs-4 fw-bold"> SMS Text <span className="text-danger"> * </span></label>
                                    <textarea
                                        className='border-top-0 border-left-0 border-right-0 w-100 border-bottom border-secondary form-control py-3 px-0 fs-6 fw-normal text-start'
                                        rows={5}
                                        onChange={(e:any)=>{
                                            formik.setFieldValue('sms_text',e.target.value)
                                        }}
                                    >
                                        {formik.values.sms_text}
                                    </textarea>
                                    {formik?.touched?.sms_text && formik?.errors?.sms_text && (
                                        <div className='fv-plugins-message-container mt-5'>
                                            <span role='alert' className={'text-danger'}>
                                                {formik?.errors?.sms_text}
                                            </span>
                                        </div>
                                    )}
                                </div>
                                <div className="col-md-12 col-lg-6 mb-8">
                                    <label className="fs-4 fw-bold"> Email Subject <span className="text-danger"> * </span> </label>
                                    <textarea
                                        className='border-top-0 border-left-0 border-right-0 w-100 border-bottom border-secondary form-control py-3 px-0 fs-6 fw-normal text-start'
                                        rows={5}
                                        onChange={(e:any)=>{
                                            formik.setFieldValue('email_subject',e.target.value)
                                        }}
                                    >
                                        {formik.values.email_subject}
                                    </textarea>
                                    {formik?.touched?.email_subject && formik?.errors?.email_subject && (
                                        <div className='fv-plugins-message-container mt-5'>
                                            <span role='alert' className={'text-danger'}>
                                                {formik?.errors?.email_subject}
                                            </span>
                                        </div>
                                    )}
                                </div>
                            </div>

                            <div className="row mb-6">
                                <div className="col-md-12 col-lg-6 mb-8">
                                    <label className="fs-4 fw-bold"> Email Text <span className="text-danger"> * </span></label>
                                    <textarea
                                        className='border-top-0 border-left-0 border-right-0 w-100 border-bottom border-secondary form-control py-3 px-0 fs-6 fw-normal text-start'
                                        rows={5}
                                        onChange={(e:any)=>{
                                            formik.setFieldValue('email_text',e.target.value)
                                        }}
                                    >
                                        {formik.values.email_text}
                                    </textarea>
                                    {formik?.touched?.email_text && formik?.errors?.email_text && (
                                        <div className='fv-plugins-message-container mt-5'>
                                            <span role='alert' className={'text-danger'}>
                                                {formik?.errors?.email_text}
                                            </span>
                                        </div>
                                    )}
                                </div>
                                <div className="col-md-12 col-lg-6 mb-8">
                                    <label className="fs-4 fw-bold"> Push Text <span className="text-danger"> * </span> </label>
                                    <textarea
                                        className='border-top-0 border-left-0 border-right-0 w-100 border-bottom border-secondary form-control py-3 px-0 fs-6 fw-normal text-start'
                                        rows={5}
                                        onChange={(e:any)=>{
                                            formik.setFieldValue('push_text',e.target.value)
                                        }}
                                    >
                                        {formik.values.push_text}
                                    </textarea>
                                    {formik?.touched?.push_text && formik?.errors?.push_text && (
                                        <div className='fv-plugins-message-container mt-5'>
                                            <span role='alert' className={'text-danger'}>
                                                {formik?.errors?.push_text}
                                            </span>
                                        </div>
                                    )}
                                </div>
                                <div className="col-md-12 col-lg-6 mb-8">
                                    {(templateData?.id == 1 || templateData?.id == 2 || templateData?.id == 3 || templateData?.id == 5) ?
                                    <select
                                        className="form-select form-select-lg mb-3  mt-2"
                                        aria-label=".form-select-lg example"
                                        name="default_url_data" 
                                        id="batch" 
                                        data-placeholder="Select Chapter"
                                        value={formik?.values?.default_url_data}
                                        onChange={(e:any)=>{
                                            formik.setFieldValue('default_url_data',e.target.value)
                                        }}
                                    >
                                        <option value="0" disabled>Select Chapters</option>
                                        <option value="all" selected={templateData.default_url_data === 'all'}>All Chapters</option>
                                        <option value="active" selected={templateData.default_url_data === 'active'}>Active Chapters</option>
                                        <option value="inactive" selected={templateData.default_url_data === 'inactive'}>In-Active Chapters</option>
                                        {chapterList &&
                                            chapterList.map((chapter: any) => (
                                                <option
                                                    key={chapter?.value}
                                                    value={chapter?.value}
                                                    selected={templateData?.default_url_data === chapter?.value}
                                                >
                                                    {chapter.label}
                                                </option>
                                            ))
                                        }
                                    </select>
                                    : ""}
                                </div>
                                <div className="col-md-12 col-lg-6 mb-8 text-end mt-2 pe-6'">
                                    {/* <div className='col-md-12 col-lg-10 text-center pe-6'> */}
                                        <button 
                                            type="button" 
                                            className='btn btn-primary  waves-effect waves-light' 
                                            disabled={loading?.saveData} 
                                            onClick={() => formik.handleSubmit()}
                                        >
                                            {loading?.saveData ? "Please Wait..." : "Update"}
                                        </button>
                                        <button type="button"
                                            className='btn btn-info  waves-effect ms-5 waves-light '
                                            onClick={() => { navigate('/message-center-template') }}
                                        >
                                            Cancel
                                        </button>
                                    {/* </div> */}
                                </div>
                            </div>
                            {/* <div className='row'>
                                
                            </div> */}
                        </form>
                    </div>
            }


        </>
    )
}

export default EditTemplate