import {ListViewProvider, useListView} from './core/ListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
// import {ExtingusersListHeader} from './components/header/ExtingusersListHeader'
// import {ExtingusersTable} from './table/ExtingusersTable'
// import {ExtinguserEditModal} from './extinguser-edit-modal/ExtinguserEditModal'
import { KTCard } from '../../../../_metronic/helpers'
import { ExtingusersTable } from './table/ExitingusersTable'
import { ExitinguserEditModal } from './exitinguser-edit-modal/ExitinguserEditModal'
// import {KTCard} from '../../../../../_metronic/helpers'

const ExtingusersList = () => {
  const {itemIdForUpdate} = useListView()
  return (
    <>
      
        {/* <ExtingusersListHeader /> */}
        <ExtingusersTable />
      
      {itemIdForUpdate !== undefined && <ExitinguserEditModal />}
    </>
  )
}

const ExtingusersListWrapper = () => (
  
      <ListViewProvider>
        <ExtingusersList />
      </ListViewProvider>
  
)

export {ExtingusersListWrapper}
