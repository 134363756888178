import axios, { AxiosResponse } from 'axios'
import { Event, EventsQueryResponse } from './_models'
import { ID, Response } from '../../../../../_metronic/helpers'
import { apiUrl } from '../../../../../apiurl'

const API_URL = apiUrl
const EVENT_URL = `${API_URL}/eventdates/eventlist`
const ADD_EVENT_URL = `${API_URL}/eventdates/addeventlist`
const EDIT_EVENT_URL = `${API_URL}/eventdates/editeventlist`
const VIEW_EVENT_URL = `${API_URL}/eventdates/vieweventlist`
const GET_EVENTS_URL = `${API_URL}/users/query`
const GET_CHAPTER_LIST_URL = `${API_URL}/chapters?filter[where][isdelete]=0`

const getEvents = (query: string): Promise<EventsQueryResponse> => {

  return axios
    .get(`${EVENT_URL}?${query}`)
    .then((d: AxiosResponse<any>) => d.data).catch((e) => console.log(e))
}

const viewEventData = (id: any): Promise<any> => {
  return axios
    .get(`${VIEW_EVENT_URL}/${id}`)
    .then((d: AxiosResponse<any>) => d.data).catch((e) => console.log(e))
}

const getChaptersList = (): Promise<any> => {

  return axios
    .get(`${GET_CHAPTER_LIST_URL}&filter[where][show_in_visitor_invitation]=1`)
    .then((d: AxiosResponse<any>) => d.data)
}

const getEventData = (): Promise<any> => {

  return axios
    .get(`${GET_CHAPTER_LIST_URL}`)
    .then((d: AxiosResponse<any>) => d.data)
}

const addEvent = (event: Event): Promise<Event | undefined> => {

  return axios.post<any>(`${ADD_EVENT_URL}`, event).then((res: any) => res)
}

const editEvent = (event: Event): Promise<Event | undefined> => {

    return axios.post<any>(`${EDIT_EVENT_URL}`, event).then((res: any) => res)
  }

const getEventById = (id: ID): Promise<Event | undefined> => {
  return axios
    .get(`${EVENT_URL}/${id}`)
    .then((response: Response<any>) => response.data).catch((e) => console.log(e))
}

const createEvent = (event: Event): Promise<Event | undefined> => {
  return axios
    .put(EVENT_URL, event)
    .then((response: Response<any>) => response.data).catch((e) => console.log(e))
}

const updateEvent = (event: Event): Promise<Event | undefined> => {
  return axios
    .post(`${EVENT_URL}/${event.id}`, event)
    .then((response: Response<any>) => response.data).catch((e) => console.log(e))
}

const deleteEvent = (eventId: ID): Promise<void> => {
  return axios.delete(`${EVENT_URL}/${eventId}`).then(() => { })
}

const deleteSelectedEvents = (eventIds: Array<ID>): Promise<void> => {
  const requests = eventIds.map((id) => axios.delete(`${EVENT_URL}/${id}`))
  return axios.all(requests).then(() => { })
}

export { getEvents, deleteEvent, deleteSelectedEvents, getEventById, createEvent, updateEvent, getChaptersList, addEvent, editEvent, getEventData, viewEventData }
