import {useMemo} from 'react'
import {useTable, ColumnInstance, Row} from 'react-table'
import {CustomHeaderColumn} from './columns/CustomHeaderColumn'
import {CustomRow} from './columns/CustomRow'
import {useQueryResponseData, useQueryResponseLoading} from '../core/QueryResponseProvider'
import {summarysessionsColumns} from './columns/_columns'
import {Summarysession} from '../core/_models'
import {SummarysessionsListLoading} from '../components/loading/SummarysessionsListLoading'
import {SummarysessionsListPagination} from '../components/pagination/SummarysessionsListPagination'
import { KTCardBody, defaultSummarysessions } from '../../../../../_metronic/helpers'
import { checkDisplayValue } from '../../../../../utils'
// import {KTCardBody} from '../../../../../../_metronic/helpers'

const CopySummarysessionsTable = () => {
  const summarysessions = useQueryResponseData()
  // const summarysessions = defaultSummarysessions
  const isLoading = useQueryResponseLoading()
  const data = useMemo(() => summarysessions, [summarysessions])
  const columns = useMemo(() => summarysessionsColumns, [])
  const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
    columns,
    data,
  })

  return (
        <table
          id='copy_kt_table_summarysessions'
          className='table align-middle table-row-dashed fs-6 gy-5 dataTable table-striped  no-footer'
          {...getTableProps()}
          style={{ display: 'none' }}
        >
          <thead>
            <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0 border-bottom border-gray-100'>
              {headers.map((column: ColumnInstance<Summarysession>) => (
                <CustomHeaderColumn key={column.id} column={column} />
              ))}
            </tr>
          </thead>
  
          <tbody className='text-gray-600 fw-bold' {...getTableBodyProps()}>
          {data.length > 0 ? (
          data.map((row: any, i: any) => {

            return <tr key={i}>
              <td>
                {/* {checkDisplayValue(row?.leader_name)} */}
                {checkDisplayValue(row?.name)}
              </td>
              <td>
                {checkDisplayValue(row?.isattended)}
              </td>
              <td>
                {checkDisplayValue(row?.visitors_invited)}
              </td>
              <td>
                {checkDisplayValue(row?.visitors_attended)}
              </td>
              <td>
                {checkDisplayValue(row?.visitors_joined)}
              </td>
              <td>
                {checkDisplayValue(row?.thankyou_given)}
              </td>
              <td>
                {checkDisplayValue(row?.thankyou_received)}
              </td>
              <td>
                {checkDisplayValue(row?.group_meeting_attended)}
              </td>
              <td>
                {checkDisplayValue(row?.tracksheet_count)}
              </td>
              <td>
                {checkDisplayValue(row?.isfeedbackgiven)}
              </td>
              <td>
                {checkDisplayValue(row?.help_posts)}
              </td>
              <td>
                {checkDisplayValue(row?.icaniwill_count)}
              </td>
              <td>
                {checkDisplayValue(row?.total_points)}
              </td>
            </tr>
          })
            ) : (
              <tr>
                <td colSpan={7}>
                  <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                    No matching records found
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
  )
}

export {CopySummarysessionsTable}
