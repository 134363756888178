import {lazy, FC, Suspense, useContext, useEffect, useState} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {MenuTestPage} from '../pages/MenuTestPage'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'
import {TermsConditionsPage} from '../pages/terms-conditions/TermsConditionsPage'
import InvitationHistory from '../modules/invitation/InvitationHistory'
import SearchLeader from '../modules/search-leader/SearchLeader'
import Library from '../modules/Library/Library'
// import LeaderProfile from '../modules/leader-profile/LeaderProfile'
import ICIWFUUPL from '../modules/iciwf-uupl/ICIWFUUPL'
import FeedbackQuery from '../modules/feedback/FeedbackQuery'
import {LeaderProfile} from '../modules/leader-profile/LeaderProfile'
import {SummarySession} from '../modules/summery-session/SummarySession'
import {LeaderDirectory} from '../modules/directory/LeaderDirectory'
import {TycaPresentation} from '../modules/tyca/TycaPresentation'
import {IndexTab} from '../modules/index/Index'
import ChatListing from '../modules/ChatListing/ChatListing'
import {LeaderView} from '../modules/search-leader/ViewLeader'
// import ListSessionFeedback from '../modules/SessionFeedback.tsx/ListSessionFeedback'
// import AddSessionFeedback from '../modules/SessionFeedback.tsx/AddSessionFeedback'
// import ViewFeedback from '../modules/SessionFeedback.tsx/ViewFeedback'
import MonthlyReport from '../modules/monthly-report/MonthlyReport'

import SessionAttendance from '../modules/session-attendance/SessionAttendance'
import VisitorPanel from '../modules/visitor-panel/VisitorPanel'
import AddDetails from '../modules/visitor-panel/AddDetails'
import EventManagement from '../modules/event-management/EventManagement'
import AddEvents from '../modules/event-management/AddEvent'
import ViewEvents from '../modules/event-management/ViewEvent'
import EditEvents from '../modules/event-management/EditEvent'
// import GroupMeetingListing from '../modules/group-meeting-report/GroupMeeting'
import ViewGroupMeetingReports from '../modules/group-meeting-report/ViewGroupMeetingReport'
import AddGroupMeetingReport from '../modules/group-meeting-report/AddGroupMeetingReport'
import EditGroupMeetingReport from '../modules/group-meeting-report/EditGroupMeetingReport'
import GroupManagementListing from '../modules/group-management/GroupManagementListing'
import ChatGroupListing from '../modules/group-management/chat-group/ChatGroupListing'
import AddEditChatGroup from '../modules/group-management/chat-group/AddEditChatGroup'
import AddChatGroup from '../modules/group-management/chat-group/AddChatGroup'
import EditChatGroup from '../modules/group-management/chat-group/EditChatGroup'
import LibraryManageListing from '../modules/library-management/LibraryManagement'
import AddSection from '../modules/library-management/AddSection'
import AddEditSection from '../modules/library-management/AddEditSection'
import ViewSection from '../modules/library-management/ViewSection'
import AddLibraryDetails from '../modules/library-management/AddLibraryDetails'
import TemplateListing from '../modules/message-center/template/TemplateListing'
import EditTemplate from '../modules/message-center/template/EditTemplate'
import GroupMeetingModule from '../modules/group-meeting-report/GroupMeeting'
import NotificationsListing from '../modules/message-center/notifications/NotificationsListing'
import ReminderListings from '../modules/message-center/reminders/ReminderListing'
import AddReminder from '../modules/message-center/reminders/AddReminder'
import AddEditReminder from '../modules/message-center/reminders/AddEditReminder'
import EditReminder from '../modules/message-center/reminders/EditReminder'
import ViewReminder from '../modules/message-center/reminders/ViewReminder'
import LeaderBoardListing from '../modules/leader-board/LeaderBoardListing'
import GroupMeetingForm from '../modules/group-meeting-report/GroupMeetingForm'
import UsersListing from '../modules/user-management/UserListing'
import AddNewLeader from '../modules/user-management/AddNewLeader'
import AddEditNewLeader from '../modules/user-management/AddEditNewLeader'
import EditNewLeader from '../modules/user-management/EditNewLeader'
import ListSessionFeedback from '../modules/session-feedback/ListSessionFeedback'
import AddSessionFeedback from '../modules/session-feedback/AddSessionFeedback'
import ViewFeedback from '../modules/session-feedback/ViewFeedback'
import VisitorHistory from '../modules/visitor-invitation/VisitorHistory'
import GroupManagementModule from '../modules/group-management/GroupManagementListing'
import ChatGroupManagementModule from '../modules/group-management/chat-group/ChatGroupListing'
import VerticalListings from '../modules/vertical/VerticalListing'
import {accessUser} from '../../utils/privileges'

import Meeting from '../pages/ZoomMeeting/ZoomComponent'
import {UserContext} from '../../contextStore'
import AddVertical from '../modules/vertical/AddVertical'
import EditVertical from '../modules/vertical/EditVertical'
import ViewVertical from '../modules/vertical/ViewVertical'
// import SponsorshipAdsListings from '../modules/sponsorship-ads/SponsorshipAdsListing'
// import AddSponsorshipAds from '../modules/sponsorship-ads/AddSponsorshipAds'
// import EditSponsorshipAds from '../modules/sponsorship-ads/EditSponsorshipAds'
// import ViewSponsorshipAds from '../modules/sponsorship-ads/ViewSponsorshipAds'
import ChapterListings from '../modules/chapters/ChapterListing'
import AddChapter from '../modules/chapters/AddChapter'
import AddEditChapter from '../modules/chapters/AddEditChapter'
import EditChapter from '../modules/chapters/EditChapter'
import ViewChapter from '../modules/chapters/ViewChapter'
import {apiUrl} from '../../apiurl'
import axios from 'axios'

const API_URL = apiUrl

const PrivateRoutes = () => {
  const VERTICAL_PERMISSION_URL = `${API_URL}/verticalspermissions/userPermission`
  const [data, setData] = useState<any>({
    invition_history: 1,
    tyca_presentation: 1
  })
  useEffect(() => {
    fetchVerticalData()
  }, [])
  // const InvitationHistory = lazy(() => import('../modules/invitation/InvitationHistory'))
  // const SearchLeader = lazy(() => import('../modules/search-leader/SearchLeader'))
  // const Library = lazy(() => import('../modules/Library/Library'))
  // const ICIWFUUPL = lazy(() => import('../modules/iciwf-uupl/ICIWFUUPL'))
  // const FeedbackQuery = lazy(() => import('../modules/feedback/FeedbackQuery'))
  const {userDetail} = useContext(UserContext)
  const userid = userDetail?.id ?? ''
  const showMonthlyReport = () => {
    return (userDetail?.iscaptain == 1 || userDetail?.iscaptain == true || accessUser.sessionsummary.find((x: any) => x == userid))
  }

  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))

  const fetchVerticalData = async () => {
    try {
      const res = await axios.get(VERTICAL_PERMISSION_URL)
      setData(res?.data)
      console.log('userPermission=>', data)
    } catch (error) {
      console.error('Error -->:', error)
    }
  }

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        {/* User Routes */}
        <Route path='dashboard' element={<DashboardWrapper />} />
        <Route path='chat-list' element={<ChatListing />} />
        {data?.invition_history == 1 ? (
          <Route path='invitation-history' element={<InvitationHistory />} />
        ) : null}
        <Route path='leaders' element={<SearchLeader />} />
        <Route path='leaders/:id' element={<LeaderView />} />
        <Route path='library' element={<Library />} />
        <Route path='leader-profile' element={<LeaderProfile />} />
        <Route path='directory' element={<LeaderDirectory />} />
        <Route path='iciwf-uupl' element={<ICIWFUUPL />} />
        <Route path='terms-conditions' element={<TermsConditionsPage />} />
        <Route path='feedback' element={<FeedbackQuery />} />
        {data?.tyca_presentation == 1 ? <Route path='tyca' element={<TycaPresentation />} /> : null}
        <Route path='index' element={<IndexTab />} />
        <Route path='builder' element={<BuilderPageWrapper />} />
        <Route path='menu-test' element={<MenuTestPage />} />
        <Route path='vertical' element={<VerticalListings />} />
        <Route path='add-vertical' element={<AddVertical />} />
        <Route path='edit-vertical/:id' element={<EditVertical />} />
        <Route path='view-vertical/:id' element={<ViewVertical />} />

        {/* Admin Routes */}
        {accessUser.sessionsummary.find((x: any) => x == userid) ? (
          <>
            <Route path='summery-session' element={<SummarySession />} />
            
          </>
        ) : (
          ''
        )}

        {showMonthlyReport() ? <Route path='monthly-report' element={<MonthlyReport />} /> : null}

        {accessUser.sessionfeedback.find((x: any) => x == userid) ? (
          <>
            <Route path='session-feedback' element={<ListSessionFeedback />} />
            <Route path='add-session-feedback' element={<AddSessionFeedback />} />
            <Route path='view-feedback' element={<ViewFeedback />} />{' '}
          </>
        ) : (
          ''
        )}

        {accessUser.sessionattenance.find((x: any) => x == userid) ? (
          <>
            <Route path='session-attendance' element={<SessionAttendance />} />
          </>
        ) : (
          ''
        )}

        {accessUser.paneladmin.includes(userid) || accessUser.panelsuperadmin.includes(userid) ? (
          <>
            <Route path='visitor-panel' element={<VisitorPanel />} />
            <Route path='add-details' element={<AddDetails />} />
          </>
        ) : (
          ''
        )}

        {accessUser.groupmeetingreport.find((x: any) => x == userid) ? (
          <>
            <Route path='group-meeting-report' element={<GroupMeetingModule />} />
            <Route path='view-groupmeeting/:id' element={<ViewGroupMeetingReports />} />
            <Route path='add-groupmeeting' element={<AddGroupMeetingReport />} />
            <Route path='edit-groupmeeting/:id' element={<EditGroupMeetingReport />} />
          </>
        ) : (
          ''
        )}

        {accessUser.visitorpanel.find((x: any) => x == userid) ? (
          <>
            <Route path='visitors-list' element={<VisitorHistory />} />
          </>
        ) : (
          ''
        )}

        {accessUser.usermanagement.find((x: any) => x == userid) ? (
          <>
            <Route path='libraries-management' element={<LibraryManageListing />} />
            <Route path='add-section' element={<AddSection />} />
            <Route path='add-edit-section' element={<AddEditSection />} />
            <Route path='view-section/:id' element={<ViewSection />} />
            <Route path='add-library-details' element={<AddLibraryDetails />} />
            <Route path='leader-board' element={<LeaderBoardListing />} />
            <Route path='message-center-template' element={<TemplateListing />} />
            <Route path='edit-template/:id' element={<EditTemplate />} />
            <Route path='message-center-notifications' element={<NotificationsListing />} />
            <Route path='message-center-reminders' element={<ReminderListings />} />
            <Route path='add-reminder' element={<AddReminder />} />
            <Route path='edit-reminder/:id' element={<EditReminder />} />
            <Route path='view-reminder/:id' element={<ViewReminder />} />
            <Route path='add-edit-reminder' element={<AddEditReminder />} />
            <Route path='users-list' element={<UsersListing />} />
            <Route path='add-new-leader' element={<AddNewLeader />} />
            <Route path='edit-leader' element={<EditNewLeader />} />
            <Route path='addedit-new-leader' element={<AddEditNewLeader />} />
            <Route path='event-management' element={<EventManagement />} />
            <Route path='add-event' element={<AddEvents />} />
            <Route path='edit-event/:id' element={<EditEvents />} />
            <Route path='view-event/:id' element={<ViewEvents />} />
            <Route path='add-edit-groupmeeting' element={<GroupMeetingForm />} />
            <Route path='group-management' element={<GroupManagementModule />} />
            <Route path='chat-group-management' element={<ChatGroupManagementModule />} />
            <Route path='add-edit-chat-group' element={<AddEditChatGroup />} />
            <Route path='add-chat-group' element={<AddChatGroup />} />
            <Route path='edit-chat-group/:id' element={<EditChatGroup />} />
            {/* <Route path='sponsorship-ads' element={<SponsorshipAdsListings />} />
            <Route path='add-sponsorshipads' element={<AddSponsorshipAds />} />
            <Route path='edit-sponsorshipads/:id' element={<EditSponsorshipAds />} />
            <Route path='view-sponsorshipads/:id' element={<ViewSponsorshipAds />} /> */}
            <Route path='chapters' element={<ChapterListings />} />
            <Route path='add-chapter' element={<AddChapter />} />
            <Route path='edit-chapter/:id' element={<EditChapter />} />
            <Route path='view-chapter/:id' element={<ViewChapter />} />
            <Route path='add-edit-chapter' element={<AddEditChapter />} />
          </>
        ) : (
          ''
        )}

        {/* Lazy Modules */}
        <Route
          path='crafted/pages/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/pages/wizards/*'
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/widgets/*'
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/chat/*'
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/user-management/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />

        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
