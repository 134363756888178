import {useMemo} from 'react'
import {useTable, ColumnInstance, Row} from 'react-table'
import {CustomHeaderColumn} from './columns/CustomHeaderColumn'
import {CustomRow} from './columns/CustomRow'
import {useQueryResponseData, useQueryResponseLoading} from '../core/QueryResponseProvider'
import {groupmeetingsColumns} from './columns/_columns'
import {Groupmeeting} from '../core/_models'
import {GroupmeetingsListLoading} from '../components/loading/GroupmeetingsListLoading'
import {GroupmeetingsListPagination} from '../components/pagination/GroupmeetingsListPagination'
import {KTCardBody, defaultGroupmeetings} from '../../../../../_metronic/helpers'
import {useQueryRequest} from '../core/QueryRequestProvider'
// import {KTCardBody} from '../../../../../../_metronic/helpers'

const GroupmeetingsTable = () => {
  const {state, updateState} = useQueryRequest() as any
  const groupmeetings = useQueryResponseData()
  // const groupmeetings = defaultGroupmeetings
  const isLoading = useQueryResponseLoading()
  const data = useMemo(() => groupmeetings, [groupmeetings])
  const columns = useMemo(() => groupmeetingsColumns, [])
  const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
    columns,
    data,
  })

  return (
    <KTCardBody className='p-0'>
      <div className='table-responsive mb-8 data-table-report'>
        <table
          id='kt_table_groupmeetings'
          className='table align-middle table-row-dashed fs-6 gy-5 dataTable table-striped  no-footer'
          {...getTableProps()}
        >
          <thead>
            <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0 border-bottom border-gray-100'>
              {headers?.map((column: ColumnInstance<Groupmeeting>) => (
                <CustomHeaderColumn key={column?.id} column={column} />
              ))}
            </tr>
          </thead>

          <thead className='table-sub-hdr'>
            <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
              {/* {headers.map((column: ColumnInstance<Sessionattendance>) => (
                <input type="text"/>
              ))} */}
              <th className='p-3'>
                {' '}
                <input
                  type='date'
                  className='form-control mb-3'
                  placeholder=''
                  value={state?.filter?.meeting_date}
                  onChange={(e: any) => {
                    let filter = state?.filter ?? {}
                    filter['meeting_date'] = e?.target?.value
                    updateState({...state, ...{filter: filter}})
                  }}
                />{' '}
              </th>
              <th className='p-3'>
                {' '}
                <input
                  type='text'
                  className='form-control mb-3'
                  placeholder=' '
                  value={state?.filter?.group_name}
                  onChange={(e: any) => {
                    let filter = state?.filter ?? {}
                    filter['group_name'] = e?.target?.value
                    updateState({...state, ...{filter: filter}})
                  }}
                />{' '}
              </th>
              <th className='p-3'>
                {' '}
                <input
                  type='txtx'
                  className='form-control mb-3'
                  placeholder=''
                  value={state?.filter?.captain}
                  onChange={(e: any) => {
                    let filter = state?.filter ?? {}
                    filter['captain'] = e?.target?.value
                    updateState({...state, ...{filter: filter}})
                  }}
                />{' '}
              </th>
              <th className='p-3'> &nbsp; </th>
            </tr>
          </thead>

          <tbody className='text-gray-600 text-center fw-bold' {...getTableBodyProps()}>
            {rows.length > 0 ? (
              rows.map((row: Row<Groupmeeting>, i) => {
                prepareRow(row)
                return <CustomRow row={row} key={`row-${i}-${row?.id}`} />
              })
            ) : (
              <tr>
                <td colSpan={7}>
                  <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                    No matching records found
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <GroupmeetingsListPagination />
      {isLoading && <GroupmeetingsListLoading />}
    </KTCardBody>
  )
}

export {GroupmeetingsTable}
