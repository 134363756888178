import {ListViewProvider, useListView} from './core/ListViewProvider'
// import {QueryRequestProvider} from './core/QueryRequestProvider'
// import {QueryResponseProvider} from './core/QueryResponseProvider'
import {EventsListHeader} from './components/header/EventsListHeader'
import {EventsTable} from './table/EventsTable'
import {EventEditModal} from './event-edit-modal/EventEditModal'
import { KTCard } from '../../../../_metronic/helpers'
// import {KTCard} from '../../../../../_metronic/helpers'

const EventsList = () => {
  const {itemIdForUpdate} = useListView()
  return (
    <>
      
        {/* <EventsListHeader /> */}
        <EventsTable />
      
      {itemIdForUpdate !== undefined && <EventEditModal />}
    </>
  )
}

const EventsListWrapper = () => (
      <ListViewProvider>
        <EventsList />
      </ListViewProvider>
)
// <QueryRequestProvider>
  {/* <QueryResponseProvider> */}
  // </QueryResponseProvider>
// </QueryRequestProvider>

export {EventsListWrapper}
