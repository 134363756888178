/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useRef, useState, useContext } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { useListView } from '../../core/ListViewProvider'
import { useQueryResponse } from '../../core/QueryResponseProvider'
import { deleteVisitor, shareInvitationWhatappAPI } from '../../core/_requests'
import { MenuComponent } from '../../../../../../_metronic/assets/ts/components'
import { ID, QUERIES } from '../../../../../../_metronic/helpers'
import { useNavigate } from 'react-router-dom'
import { RWebShare } from 'react-web-share'
import moment from 'moment'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { apiUrl } from '../../../../../../apiurl'
import { ResetDateChapter } from '../../../ResetDateChapter'
import { UserContext } from '../../../../../../contextStore'
const API_URL = apiUrl

type Props = {
  id: ID,
  data: any
}

const validationForm = Yup.object().shape({
  event_id: Yup.number().required('event date is required'),
  chapter_id: Yup.string().required('chapter is required')
})
const VisitorActionsCell: FC<Props> = ({ id, data }) => {
  const { setMessage } = useContext(UserContext)
  const { setItemIdForUpdate } = useListView()
  const { query } = useQueryResponse()
  const queryClient = useQueryClient()
  const [whatsappInvi, setWhatsappInvi] = useState<any>("")
  const [show, setShow] = useState(false);
  const [chapterList, setChapterList] = useState<[]>([])
  const [dateList, setDateList] = useState<any>([])
  const [chapterId, setChapterId] = useState<any>('')
  const todays_date = new Date().toISOString().split('T')[0]
  const whatsappBtnRef = useRef<any>(null)

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const openEditModal = () => {
    setItemIdForUpdate(id)
  }

  const deleteItem = useMutation(() => deleteVisitor(id), {
    // 💡 response of the mutation is passed to onSuccess
    onSuccess: () => {
      // ✅ update detail view directly
      queryClient.invalidateQueries([`${QUERIES.VISITORS_LIST}-${query}`])
    },
  })

  const navigate = useNavigate()
  const navigateViewVisitor = () => {
    // 👇️ navigate to /
    navigate('/view-visitor')
  }

  const navigateEditVisitor = () => {
    // 👇️ navigate to /
    navigate('/edit-visitor')
  }

  const whatsappAPI = async (eventid: any, invitee_name: any) => {
    try{
      await shareInvitationWhatappAPI(eventid, invitee_name).then((resp: any) => {
        setWhatsappInvi(resp?.data?.message ?? resp?.data?.message)
        if (resp?.data?.message) {
          setTimeout(() => {
            whatsappBtnRef.current.click()
          }, 500)
        } else {
          setMessage("error", "Something Went Wrong")
        }
      }).catch((erorr: any) => {
        setMessage("error", "Something Went Wrong")
        console.log(erorr, "erorr?.data")
      })
    }
    catch (err) {
      setMessage("error", "Something Went Wrong")
    }
  }

  const initialValues = {
    chapter_id: '',
    event_id: '',
  }

  const formik = useFormik({
    initialValues,
    validationSchema: validationForm,
    onSubmit: async (values, { setStatus, resetForm, setSubmitting }) => {
      // setLoading(true)
      // try {

      //   await sendInvitation(postData)
      //     .then((res: any) => {
      //       if (res?.status == 200) {
      //         setMessage('success', 'Invitation sent successfully')
      //         setLoading(false)
      //         resetForm()
      //         setChapterId('')
      //         shareNotification({
      //           thankyouhistoryid: null,
      //           user_topic_id: res?.data?.usertopic?.id ?? '',
      //           userid: userDetail?.id ?? '',
      //           accesstoken: localStorage.getItem('authToken'),
      //         })
      //         fetchData()
      //         closebutton.current.click()
      //       }
      //     })
      //     .catch((error: any) => {
      //       if (error?.response?.data?.error?.message) {
      //         setMessage('error', error?.response?.data?.error?.message)
      //       } else {
      //         setMessage('error', 'Something Went Wrong')
      //       }
      //       setLoading(false)
      //       resetForm()
      //       setChapterId('')
      //     })
      // } catch (error: any) {
      //   setMessage('error', error?.message ? error?.message : 'Something Went Wrong')
      //   setLoading(false)
      //   resetForm()
      //   setChapterId('')
      // }
    },
  })


  return (

    <>
      <div className='d-flex'>
        <div style={{ display: "none" }}>
          <RWebShare
            data={{
              text: whatsappInvi?.replaceAll('&', 'and')?.replaceAll('\n', '%0a'),
              url: " ",
              title: "Send Invitation"
            }}
            onClick={() => console.info("share successful!")}
            disableNative={true}
          >
            <button ref={whatsappBtnRef}>btn</button>
          </RWebShare>
        </div>
        {
          (data.isvisited == 1 || data.isvisited == 3) && (data.isjoin == 1 || data.isjoin == 2) ?
            (<>
              <a
                // href='#'
                className='btn btn-light-warning rounded-circle py-2 px-3 btn-sm me-2'
                data-kt-menu-trigger='click'
                data-kt-menu-placement='bottom-end'
                style={{ lineHeight: '11px !important' }}
                data-bs-toggle='modal'
                data-bs-target='#viewvisitor'
                onClick={handleShow}
              >
                {/* <i className="fa-regular fa-eye pe-0 fs-6"></i> */}
                <i className='fa-solid fa-rotate pe-0 fs-6'></i>
              </a>
              {Date.parse(data?.event_date) >= Date.parse(moment().format()) ? (<div onClick={() => {
                // whatsappAPI()
                // console.log('datadata--data', data)
                whatsappAPI(data?.event_id ?? '', data?.invitee_name ?? '')
              }}>
                <a
                  href=''
                  className='btn btn-light-success rounded-circle py-2 px-3 btn-sm me-2'
                  data-kt-menu-trigger='click'
                  data-kt-menu-placement='bottom-end'
                  style={{ lineHeight: '11px !important' }}
                  onClick={() => {
                    // whatsappAPI()
                    console.log('datadata--data', data)
                    whatsappAPI(data?.event_id ?? '', data?.invitee_name ?? '')
                  }}
                  data-bs-toggle='modal'
                  data-bs-target='#editvisitor'
                >
                  <i className='fa-brands fa-whatsapp pe-0 fs-6'></i>
                  {/* <RWebShare
                    data={{
                      text: whatsappInvi?.replaceAll("&", "and")?.replaceAll("\n", "%0a") ?? whatsappInvi?.replaceAll("&", "and")?.replaceAll("\n", "%0a"),
                      // text: `raj %0agovind`,
                      url: `${" " ?? ' '}`,
                      title: `${"Send Invitation " ?? ''}`,
                    }}
                    // disableNative={whatsappInvi ?   false : true  }
                    onClick={(e: any) => {
                      // whatsappAPI(data?.event_id ?? '', data?.invitee_name ?? '')
                    }}
                  >
                    <i className='fa-brands fa-whatsapp pe-0 fs-6'></i>
                  </RWebShare> */}
                </a>
              </div>) : (<></>)}</>) :
            (<>
              {
                data?.isvisited == 2 ? (<div className='rounded-pill bg-info text-white d-flex align-items-center text-center px-3 py-1 fs-7'>
                  Attended
                </div>) : (<></>)
              }
            </>)
        }
        {/* <a
          className='btn btn-light-warning rounded-circle py-2 px-3 btn-sm me-2'
          data-kt-menu-trigger='click'
          data-kt-menu-placement='bottom-end'
          style={{lineHeight: '11px !important'}}
          data-bs-toggle='modal'
          data-bs-target='#viewvisitor'
          onClick={handleShow}
        >
          <i className='fa-solid fa-rotate pe-0 fs-6'></i>
        </a>
        {Date.parse(data?.event_date) <= Date.parse(moment().format()) ? (<div onClick={() => {
              // whatsappAPI()
              console.log('datadata--data', data)
              whatsappAPI(data?.event_id ?? '', data?.invitee_name ?? '')
            }}>
          <a
            href=''
            className='btn btn-light-success rounded-circle py-2 px-3 btn-sm me-2'
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
            style={{lineHeight: '11px !important'}}
            onClick={() => {
              // whatsappAPI()
              console.log('datadata--data', data)
              // whatsappAPI(data?.event_id ?? '', data?.invitee_name ?? '')
            }}
            data-bs-toggle='modal'  
            data-bs-target='#editvisitor'
          >
    
          <RWebShare
          data={{
            text: whatsappInvi?.replaceAll("&","and")?.replaceAll("\n","%0a") ?? whatsappInvi?.replaceAll("&","and")?.replaceAll("\n","%0a"),
            // text: `raj %0agovind`,
            // url: `${"" ?? ''}`,
            title: `${"Send Invitation " ?? ''}`,
          }}
          // disableNative={whatsappInvi ?   false : true  }
          onClick={(e:any) => {
          // whatsappAPI(data?.event_id ?? '', data?.invitee_name ?? '')
        }}
        >
            <i className='fa-brands fa-whatsapp pe-0 fs-6'></i>
        </RWebShare>
        </a>
        </div>):(<></>)}
        
        {
          data?.isvisited == 2 ? (<div className='rounded-pill bg-info text-white d-flex align-items-center text-center px-3 py-1 fs-7'>
          Attended
        </div>):(<></>) 
        } */}

      </div>
      {/* <a
        href='#'
        className='btn btn-light-danger rounded-circle py-2 px-3 btn-sm'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
        style={{ lineHeight: '11px !important' }}
        data-bs-toggle="modal" data-bs-target="#deletModal"
      >
        <i className="fa-solid fa-trash-can pe-0 fs-6"></i>
      </a> */}
      {/* <Button variant="primary" onClick={handleShow}>
        Launch demo modal
      </Button> */}
      <ResetDateChapter show={show} setShow={setShow} id={id}></ResetDateChapter>
      {/* <Modal className='reset-date-psw' show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Select Resend Date And Chapter</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <form method='' action='' className='' noValidate>
                <div className='row'>
                  <div className='col-md-12 mb-3'>
                    <div className='form-group floating-labels'>
                      <select
                        className='form-select border-top-0 border-left-0 border-right-0 border-bottom rounded-0'
                        aria-label='Default select example'
                        value={chapterId}
                        onChange={(e:any ) =>{ 
                          setChapterId(e.target.value)
                          formik.setFieldValue('chapter_id', e.target.value)
                        }}
                      >
                        <option> Select Chapter </option>
                        {chapterList?.map((i: any) => (
                          <>
                            <option value={i?.id}> {i?.name} </option>
                          </>
                        ))}
                      </select>
                    </div>
                  </div>

                  <div className='col-md-12 mb-5'>
                    <div className='form-group floating-labels'>
                      <select
                        className='form-select border-top-0 border-left-0 border-right-0 border-bottom rounded-0'
                        aria-label='Default select example'
                        value={formik.values.event_id}
                        onChange={(e: any) => {
                          formik.setFieldValue('event_id', e.target.value)
                        }}
                      >
                        <option selected style={{background: '6610f2', height: '50px'}}>
                          Select Event Date
                        </option>
                        {dateList?.map((i: any) => (
                          <>
                            <option value={i?.id}>
                              {' '}
                              {checkDisplayValue(moment(i?.event_date).format('MMM DD, YYYY'))}{' '}
                            </option>
                          </>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                <div className='d-flex justify-content-end'>
                  <button
                    className='btn bg-secondary me-4'
                    type='button'
                    data-bs-dismiss='modal'
                    id='closemodel'
                    onClick={() => {
                      // setChapterId('')
                      // formik.setFieldValue('event_id', '')
                    }}
                  >
                    Close
                  </button>
                  <button
                    type='submit'
                    className='btn btn-success'
                    disabled={formik.isSubmitting || !formik.isValid}
                    id='modalresend'
                  >
                    Resend
                  </button>
                </div>
              </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>  */}
    </>
  )
}

export { VisitorActionsCell }
