import React, { useContext, useEffect } from "react";
import AddEditSection from "./AddEditSection";
import { Modal } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from 'yup'
import { createLibrarymanagement, getLibrarymanagementById, updateLibrarymanagement } from "./librarymanagements-list/core/_requests";
import { UserContext } from "../../../contextStore";
import { useQueryClient } from "react-query";
import { useQueryResponse } from "./librarymanagements-list/core/QueryResponseProvider";
import { QUERIES } from "../../../_metronic/helpers";


const AddSection = ({show,handleClose,id}:any) => {
  const {query, refetch} = useQueryResponse()
  const queryClient = useQueryClient()

  const { setMessage, removeAuth, chapterID } = useContext(UserContext)

    const editLibrarymanagementSchema = Yup.object().shape({
        title: Yup.string()
          .min(3, 'Title must be more than 2 characters.')
          .max(50, 'Title must be less than 50 characters.')
          .required('Please enter section title'),
      })

      const formik = useFormik({
        initialValues: {title:''},
        validationSchema: editLibrarymanagementSchema,
        onSubmit: async (values, {setSubmitting,resetForm}) => {
          setSubmitting(true)
          try {
            if (id) {
              values = {...values ,...{id:id}}
              await updateLibrarymanagement(values).then((res:any)=>{
                console.log("res",res)
                if(res?.id ){
                  setMessage('success', 'section Updated successful')
                  queryClient.invalidateQueries([`${QUERIES.LIBRARYMANAGEMENTS_LIST}-${query}`])
                  handleClose()
                  resetForm()
                  
                }
              }).catch((error:any)=>{
                console.log("error",error)
                if (error?.response?.data?.error?.message) {
                  setMessage('error', error?.response?.data?.error?.message)
                } else {
                  setMessage('error', 'Something went wrong')
                }
              })
              setSubmitting(false)
            } else {
              values={...values ,...{isactive:1,isdelete:0}}
              await createLibrarymanagement(values).then((res:any)=>{
                console.log("res",res)
                if(res?.id ){
                  setMessage('success', 'section Added successful')
                  refetch()
                  queryClient.refetchQueries([`${QUERIES.LIBRARYMANAGEMENTS_LIST}-${query}`])
                  handleClose()
                  resetForm()
                }
              }).catch((error:any)=>{
                console.log("error",error)
                if (error?.response?.data?.error?.message) {
                  setMessage('error', error?.response?.data?.error?.message)
                } else {
                  setMessage('error', 'Something went wrong')
                }
              })
              setSubmitting(false)
            }
            
          } catch (ex) {
            console.error(ex)
          } finally {
            setSubmitting(true)
            // cancel(true)
          }
        },
      })

      useEffect(() => {
        formik.resetForm()
      }, [handleClose])
    const fetchById=async ()=>{
      const getbyId = await getLibrarymanagementById(id)
      console.log("getbyId",getbyId)
      formik.setFieldValue("title",getbyId?.title ? getbyId?.title : "" )
    }

      useEffect(()=>{
        if(id && show){

          fetchById()
          // const getbyId = await getLibrarymanagementById(id)
        }
      },[id,show])
       
    return (
      <>
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <h5 className='modal-title text-primary fs-4 fw-bold' id='exampleModalLabel'>
              {' '}{
                id ?  ("Edit Section") : ("Add Section") 
              }
              {' '}
            </h5>
          </Modal.Header>
          <Modal.Body>
            <form action='' onSubmit={formik.handleSubmit} noValidate>
              <div className='row mx-0  align-items-center my-5 px-3 '>
                <div className='col-sm-12 col-lg-12 mb-5'>
                  {' '}
                  <input
                    type='text'
                    className='form-control'
                    value={formik?.values?.title}
                    onChange={(event: any) => {
                      formik.setFieldValue('title', event.target.value.trimStart())
                    }}
                    placeholder='Enter section title'
                  />
                  {formik.touched.title && formik.errors.title && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.title}</span>
                      </div>
                    </div>
                  )}
                </div>

                <div className='col-md-12 col-lg-12 text-center' style={{display:"flex",flexDirection:"row-reverse"}}>
                  <button type='submit' disabled={formik.isSubmitting} className='btn btn-primary waves-effect waves-light btn-sm'>
                    {' '}
                    Submit{' '}
                  </button>
                </div>
              </div>
            </form>
          </Modal.Body>
        </Modal>

        {/* <div className="modal fade" id="addnewsection" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title text-primary fs-4 fw-bold" id="exampleModalLabel"> Add Section </h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                       <AddEditSection />
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            <button type="button" className="btn btn-primary">Save changes</button>
                        </div>
                    </div>
                </div>
            </div> */}
      </>
    )

}
export default AddSection