// @ts-nocheck
import {Column} from 'react-table'
import {RequestuserActionsCell} from './RequestuserActionsCell'
import {RequestuserCustomHeader} from './RequestuserCustomHeader'
import {Requestuser} from '../../core/_models'
import { checkDisplayValue } from '../../../../../../../utils'

const requestusersColumns: ReadonlyArray<Column<Requestuser>> = [

  {
    Header: (props) => <RequestuserCustomHeader tableProps={props} title='Chapter Name' className='min-w-125px   ps-2 fs-5' />,
    accessor: 'chapters.name',
    // id: 'name',
    // Cell: ({...props}) => <RequestuserInfoCell requestuser={props.data[props.row.index]} />,
    Cell:(value)=>{return checkDisplayValue(value?.row?.original?.chapter_name)}

  },

  {
    Header: (props) => <RequestuserCustomHeader tableProps={props} title='Leader Name' className='min-w-125px fs-5' />,
    accessor: 'name',
    // id: 'name',
    // Cell: ({...props}) => <RequestuserInfoCell requestuser={props.data[props.row.index]} />,
  },


  {
    Header: (props) => <RequestuserCustomHeader tableProps={props} title='Mobile' className='min-w-125px  fs-5' />,
    accessor: 'mobileno',
    // id: 'name',
    // Cell: ({...props}) => <RequestuserInfoCell requestuser={props.data[props.row.index]} />,
  },
  
  {
    Header: (props) => <RequestuserCustomHeader tableProps={props} title='Email' className=' fs-5' />,
    accessor: 'email',
    // id: 'name',
    // Cell: ({...props}) => <RequestuserInfoCell requestuser={props.data[props.row.index]} />,
  },
  
  {
    Header: (props) => <RequestuserCustomHeader tableProps={props} title='Company Name' className=' fs-5' />,
    accessor: 'companyname',
    // id: 'name',
    // Cell: ({...props}) => <RequestuserInfoCell requestuser={props.data[props.row.index]} />,
  },
  
  {
    Header: (props) => <RequestuserCustomHeader tableProps={props} title='OTP' className=' fs-5' />,
    accessor: 'otp',
    // id: 'name',
    // Cell: ({...props}) => <RequestuserInfoCell requestuser={props.data[props.row.index]} />,
  },

  {
    Header: (props) => <RequestuserCustomHeader tableProps={props} title='Active' className=' fs-5' />,
    accessor: 'active',
    // id: 'name',
    // Cell: ({...props}) => <RequestuserInfoCell requestuser={props.data[props.row.index]} />,
    Cell:(value)=>{return checkDisplayValue(value?.row?.original?.active)}
  },


  {
      Header: (props) => (
        <RequestuserCustomHeader tableProps={props} title='Actions' className=' min-w-150px' />
      ),
      id: 'isaccept',
      Cell: ({...props}) => <RequestuserActionsCell id={props.data[props.row.index].id} data={props.data[props.row.index]} />,
    }


]

export {requestusersColumns}
