import axios, {AxiosResponse} from 'axios'
import {apiUrl, redirectAdminURL} from '../../../../apiurl'
import {Response} from '../../../../_metronic/helpers'

const API_URL = apiUrl
const Resend_Invitation = `${API_URL}/invitionhistories/saveInvition`
const Share_Invitation = `${API_URL}/invitionhistories/getwhatsappinvitationdata`
const SEND_NOTIFICATION_PHP_URL = `${API_URL}/usertopics/sendsmsandnotficationapi`

const sendInvitation = (data: any): Promise<any> => {
  return axios.post(Resend_Invitation, data).then((res: AxiosResponse<Response<any>>) => res)
}

const shareInvitation = (eventid: any, invitee_name: any): Promise<any> => {
  return axios
    .post(Share_Invitation, {eventid, invitee_name})
    .then((res: AxiosResponse<Response<any>>) => res)
}

const shareNotification = (data: any) => {
  return axios
    .post<any>(SEND_NOTIFICATION_PHP_URL, data)
    .then((responce: any) => {
      return responce.data
    })
    .catch((error) => {
      console.error('Error thank you history Data:', error)
      throw error
    })
}
const shareInvitationWhatappAPI = (eventid: any, invitee_name: any): Promise<any> => {
  return axios
    .post(Share_Invitation, {eventid, invitee_name})
    .then((res: AxiosResponse<Response<any>>) => res)
}

export {sendInvitation, shareInvitation, shareNotification,shareInvitationWhatappAPI}
