/* eslint-disable react-hooks/exhaustive-deps */
import {FC, useContext, useState, useEffect, useMemo} from 'react'
import {useQuery} from 'react-query'
import {getSummarysessions} from './_requests'
import {Summarysession} from './_models'
import {useQueryRequest} from './QueryRequestProvider'
import { UserContext } from '../../../../../contextStore'
import { PaginationState, QUERIES, WithChildren, createResponseContext,  initialQueryResponse, initialQueryState, stringifyRequestQuery } from '../../../../../_metronic/helpers'

const QueryResponseContext = createResponseContext<Summarysession>(initialQueryResponse)
const QueryResponseProvider: FC<WithChildren> = ({children}) => {
  const {state} = useQueryRequest()
  const [query, setQuery] = useState<string>(stringifyRequestQuery(state))
  const updatedQuery = useMemo(() => stringifyRequestQuery(state), [state])
  const { refreshTable } = useContext(UserContext);

  useEffect(() => {
    if (query !== updatedQuery) {
      setQuery(updatedQuery)
    }
  }, [updatedQuery])

  const {
    isFetching,
    refetch,
    data: response,
  } = useQuery(
    `${QUERIES.SUMMARYSESSIONS_LIST}-${query}`,
    () => {
      return getSummarysessions(query)
    },
    {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
  )

  return (
    <QueryResponseContext.Provider value={{isLoading: isFetching, refetch, response, query}}>
      {children}
    </QueryResponseContext.Provider>
  )
}

const useQueryResponse = () => useContext(QueryResponseContext)

const useQueryResponseData = () => {
  const {response} = useQueryResponse() as any
  if (!response || !response?.data) {
    return []
  }
  return response?.data || []
}



const useQueryResponsePagination = () => {
  // const defaultPaginationState: PaginationState = {
  //   links: [],
  //   ...initialQueryState,
  // }

  // const {response} = useQueryResponse()
  // if (!response || !response.payload || !response.payload.pagination) {
  //   return defaultPaginationState
  // }

  // return response.payload.pagination

  const defaultPaginationState: PaginationState = {
    links: [],
    ...initialQueryState,
  }

  const {response} = useQueryResponse() as any

  if (!response || !response?.perPage || !response?.page ) {
    return {}
  }

  return {
    perPage: response?.perPage,
    page: response?.page,
    totalRecords: response?.totalRecords,
  }
}

const useQueryResponseLoading = (): boolean => {
  const {isLoading} = useQueryResponse()
  return isLoading
}

export {
  QueryResponseProvider,
  useQueryResponse,
  useQueryResponseData,
  useQueryResponsePagination,
  useQueryResponseLoading,
}
