import { Button, FloatingLabel, Form } from 'react-bootstrap'
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import Select from 'react-select'
import { useDropzone } from 'react-dropzone'
import { UserContext } from '../../../contextStore'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { addTYCAQuestion, eventDates, findOneTyca, searchLeaderDetail, updateTYCA } from './_request'
// import { formatISODateToYYYYMMDD } from '../../pages/dashboard/components/create-post/modal/formatTargetDate'
import { apiUrl } from '../../../apiurl'
import axios from 'axios'
import { toAbsoluteUrl } from '../../../_metronic/helpers'
import moment from 'moment'
import { formatDate } from '../../../utils'
const API_URL = apiUrl
const IMAGE_UPLOAD = `${API_URL}/containers/topicimages/upload`



// const options = [
//   { value: 'chocolate', label: 'Chocolate' },
//   { value: 'strawberry', label: 'Strawberry' },
//   { value: 'vanilla', label: 'Vanilla' },
// ]

const AddTycaPresentation = (props: any) => {
  const [selectedOption, setSelectedOption] = useState(null)
  const { userDetail, setMessage,chapterID } = useContext(UserContext)
  // const chapter_id = userDetail.chapter_id
  const chapter_id = chapterID  

  const [searchValue, setSearchValue] = useState<any>('')
  const [searchLeaderData, setSearchLeaderData] = useState<any>([])
  const [eventDate, setEventDateData] = useState<any>([])
  const [selectLeaderId, setSelectLeaderId] = useState('')
  const [selectedFile, setSelectedFile] = useState<File | null>(null)
  const [imageName, setImageName] = useState<String | null>('')
  const [isLoading, setIsLoading] = useState(false);

  const closebutton = useRef<any>()
  
  var editId = props.editTyca
  // search leader name api 
  const searchLeaderName = async () => {
    try {
      const responce = await searchLeaderDetail(chapter_id, searchValue)

      if (responce) {
        setSearchLeaderData(responce.data)
      }

    } catch (error) {
      console.error("error", error)
    }
  }

  // event dates
  const eventDateDetail = async () => {
    try {
      const responce = await eventDates(chapter_id)

      if (responce) {
        setEventDateData(responce.data)

      }

    } catch (error) {
      console.error("error", error)
    }
  }

  useEffect(() => {
    eventDateDetail()

  }, [])
  const onDrop = useCallback((acceptedFiles: any) => {
    const file = acceptedFiles[0]
    setSelectedFile(file)
  }, [])

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: 'pdf/*' as any,
    maxFiles: 1,
  })
  useEffect(() => {
    if (selectedFile) {

      try {

        if (selectedFile.type === 'application/pdf') {
          uploadImage();
        } else {
          setSelectedFile(null)
          setMessage('error', 'Please select a PDF file for upload.');
        }
      } catch (e) {
        setMessage('error', 'Failed to upload PDF.')
      }

    }

  }, [selectedFile])

  const uploadImage = async () => {
    try {
      if (selectedFile) {
        const formData = new FormData()
        formData.append('file', selectedFile)

        const response = await axios.post(IMAGE_UPLOAD, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Content-Disposition': `attachment; filename="${selectedFile.name}"`,
          },
        })

        if (response.status === 200) {
          setImageName(response?.data?.result?.files?.file[0]?.name)

        } else {
          console.error('Image upload failed:', response.statusText)
        }
      }
    } catch (error) {
      console.error('Error uploading image:', error)
    }
  }


  // initial value
  const initialValues = {
    title: "",
    presentation: "",
    accept_date: "",
    question: "",
    success: "",
    fileName: "",
    leaderId: ""
  }

  const validationSchema = Yup.object().shape({
    title: Yup.string().required('title is required'),
    presentation: Yup.string().required('presentation date is required'),
    accept_date: Yup.string().required('accept date is required'),
    question: Yup.string().required('question date is required'),
    success: Yup.string().required('success date is required'),
  })

  const handleSelectChange = (selectedOption: any) => {

    // const
    setSelectedOption(selectedOption);
    setSelectLeaderId(selectedOption?.value);
    formik.setFieldValue("leaderId", selectedOption?.value);
  };
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm }) => {
      setIsLoading(true)
      try {


        if (editId) {
          const editData = {

            leader_id: values.leaderId,
            title: values.title,
            fileName: imageName,
            presentation: values.presentation,
            accept_date: values.accept_date,
            question: values.question,
            success: values.success
          }
          await updateTYCA(editId, editData)
            .then((res: any) => {
              if (res) {
                closebutton.current.click()
                setSelectLeaderId('')
                setSelectedFile(null)
                setImageName('')
                props.HandleReload()
                setIsLoading(false)
                setMessage('success', 'Edit Tyca Successfully')
                resetForm()
              }
            })
            .catch((error: any) => {
              setIsLoading(false)
              if (error?.response?.data?.error?.message) {
                setMessage('error', error?.response?.data?.error?.message)
              } else {
                setMessage('error', 'Something Went Wrong')
              }

            })

        } else {
          const postData = {
            leader_id: values.leaderId,
            title: values.title,
            fileName: imageName,
            presentation: values.presentation,
            accept_date: values.accept_date,
            question: values.question,
            success: values.success
          }
          await addTYCAQuestion(postData)
            .then((res: any) => {
              if (res) {
                closebutton.current.click()
                setSelectLeaderId('')
                setSelectedFile(null)
                setImageName('')
                props.HandleReload()
                setIsLoading(false)
                setMessage('success', 'Add Tyca Successfully')
                resetForm()
              }
            })
            .catch((error: any) => {
              setIsLoading(false)
              if (error?.response?.data?.error?.message) {
                setMessage('error', error?.response?.data?.error?.message)
              } else {
                setMessage('error', 'Something Went Wrong')
              }

            })
        }


      } catch (error) {
        setIsLoading(false)
        setMessage('error', 'Something went wrong')
      }
    },
  })
  const fatchByIdData = async () => {

    await findOneTyca(editId)
      .then((res: any) => {
        if (res) {

          if (res?.title) {
            formik.setFieldValue("title", res?.title)
          }
          if (res.user_id) {
            const selectedValue = searchLeaderData.find((item: any) => item.id === res.user_id);
            console.log(selectedValue, "selected value hai na")
            const re: any = {
              value: selectedValue.id,
              label: selectedValue.name
            }
            setSelectedOption(re);
            formik.setFieldValue("leaderId", res.user_id);

          }


          if (res?.date) {
            formik.setFieldValue("presentation", res?.date)
            // if (!eventDate.some(
            //   (item: any) =>
            //     formatDate(item?.date) === formatDate(res?.date)
            // )) {
            //   setEventDateData([...eventDate, {event_date: res?.date}])
            // }
          }
          if (res?.accept_date) {
            // if (!eventDate.some(
            //     (item: any) =>
            //       formatDate(item?.accept_date) == formatDate(res?.accept_date)
            //   )
            // ) {
            //   setEventDateData([...eventDate, {event_date: res?.accept_date}])
            // }
            
            formik.setFieldValue("accept_date", res?.accept_date)
          }
          if (res?.question_date) {
            // if (!eventDate.some(
            //     (item: any) =>
            //       formatDate(item?.question_date) == formatDate(res?.question_date)
            //   )
            // ) {
            //   setEventDateData([...eventDate, {event_date: res?.question_date}])
            // }
            formik.setFieldValue("question", res?.question_date);
          }
          if (res?.success_date) {
            // if ( !eventDate.some(
            //     (item: any) =>
            //       formatDate(item?.success_date) == formatDate(res?.success_date)
            //   )
            // ) {
            //   setEventDateData([...eventDate, {event_date: res?.success_date}])
            // }
            formik.setFieldValue("success",res?.success_date)
          }
          if (res?.pdf) {
            setImageName(res?.pdf)
          }

        }
      })
      .catch((error: any) => {
        if (error?.response?.data?.error?.message) {
          // setMessage('error', error?.response?.data?.error?.message)
          console.log(error?.response?.data?.error?.message)
        } else {
          // setMessage('error', 'Something Went Wrong')
        }

      })

  }

  console.log(formik, "formik value ")

  useEffect(() => {
    searchLeaderName()
    eventDateDetail()
    if (editId) {
      fatchByIdData()
    }
  }, [editId])

  console.log("eventDate",eventDate)
  return (
    <>
      <div
        className='modal fade visitor-details'
        id={'addtyca'}
        data-bs-backdrop='static'
        data-bs-keyboard='false'
        tabIndex={-1}
        aria-labelledby='staticBackdropLabel'
        aria-hidden='true'
      >
        <div className='modal-dialog me-0 mb-0 mt-0 border-radius-0'>
          <div className='modal-content '>

            <div className='modal-header border-bottom border-gray-300'>
              <h5 className='modal-title text-primary' id='staticBackdropLabel'>
                {props.editTyca ? "Edit TYCA Presentation" : "Add New TYCA Presentation"}
              </h5>
              <button
                type='button'
                className='btn-close'
                data-bs-dismiss='modal'
                aria-label='Close'
                ref={closebutton}
                onClick={() => {
                  props.setEditTyca('')
                  setSelectLeaderId('')
                  setSelectedFile(null)
                  setImageName(null)
                  setSelectedOption(null)
                  formik.setFieldValue('title', '')
                  formik.setFieldValue('accept_date', '')
                  formik.setFieldValue('presentation', '')
                  formik.setFieldValue('question', '')
                  formik.setFieldValue('success', '')
                }}
              ></button>
            </div>
            <Form onSubmit={formik.handleSubmit}>
              <div className='modal-body'>

                <div className='col-12'>
                  <Form.Control
                    type="text"
                    placeholder="Presentation  Title"
                    onChange={(e) => {
                      formik.setFieldValue('title', e.target.value)
                    }}
                    value={formik.values.title}
                  />
                  {formik?.touched?.title && formik?.errors?.title && (
                    <div className='fv-plugins-message-container text-danger'>
                      <span role='alert'>{formik?.errors?.title as any}</span>
                    </div>
                  )}
                </div>

                <div className='col-12 fw-semibold mt-5'>

                  <Select
                    value={selectedOption}
                    onChange={handleSelectChange}
                    options={searchLeaderData?.map((item: any) => ({
                      value: item.id,
                      label: item.name,
                    }))}
                  />
                </div>
                <div className='row mt-5'>
                  <div className='col-md-12 col-lg-6'>
                    <Form.Select aria-label='Default select example'
                      onChange={(e) => {
                        formik.setFieldValue('accept_date', e.target.value)
                      }}
                      value={formik.values.accept_date}
                    >
                      <option value="">Select Accept Date</option>
                      {eventDate?.map((item: any) => (
                        <option key={formatDate(item?.event_date)} value={item?.event_date}>
                          {formatDate(item?.event_date)}
                        </option>
                      ))}
                      { editId && !(eventDate.find(
                        (item: any) =>
                          item?.event_date == formik.values.accept_date
                      )) ? <option key={formik.values.accept_date} value={formik.values.accept_date}> {formatDate(formik.values.accept_date)} </option> : <></>}

                    </Form.Select>
                    {formik?.touched?.accept_date && formik?.errors?.accept_date && (
                      <div className='fv-plugins-message-container text-danger'>
                        <span role='alert'>{formik?.errors?.accept_date as any}</span>
                      </div>
                    )}
                  </div>
                  <div className='col-md-12 col-lg-6'>
                    <Form.Select aria-label='Default select example'
                      onChange={(e) => {
                        formik.setFieldValue('presentation', e.target.value)
                      }}
                      value={formik.values.presentation}
                    >
                      <option value="">Selete Presentation Date</option>
                      {eventDate?.map((item: any) => (
                        <option key={formatDate(item?.event_date)} value={item?.event_date}>
                          {formatDate(item?.event_date)}
                        </option>
                      ))}
                      { editId && !(eventDate.some(
                        (item: any) =>
                        item?.event_date == formik.values.presentation
                      )) && <option key={formik.values.presentation} value={formik.values.presentation}> {formatDate(formik.values.presentation)} </option>}

                    </Form.Select>
                    {formik?.touched?.presentation && formik?.errors?.presentation && (
                      <div className='fv-plugins-message-container text-danger'>
                        <span role='alert'>{formik?.errors?.presentation as any}</span>
                      </div>
                    )}
                  </div>
                </div>

                <div className='row mt-5'>
                  <div className='col-md-12 col-lg-6'>
                    <Form.Select aria-label='Default select example'

                      onChange={(e) => {
                        formik.setFieldValue('question', e.target.value)
                      }}
                      value={formik.values.question}
                    >
                      <option value="">Selete Question Date</option>
                      {eventDate?.map((item: any) => (
                        <option key={formatDate(item?.event_date)} value={item?.event_date}>
                          {formatDate(item?.event_date)}
                        </option>
                      ))}
                      { editId && !(eventDate.some(
                        (item: any) =>
                        item?.event_date == formik.values.question
                      )) && <option key={formik.values.question} value={formik.values.question}> {formatDate(formik.values.question)} </option>}

                    </Form.Select>
                    {formik?.touched?.question && formik?.errors?.question && (
                      <div className='fv-plugins-message-container text-danger'>
                        <span role='alert'>{formik?.errors?.question as any}</span>
                      </div>
                    )}
                  </div>
                  <div className='col-md-12 col-lg-6'>
                    <Form.Select aria-label='Default select example'
                      onChange={(e) => {
                        formik.setFieldValue('success', e.target.value)
                      }}
                      value={formik.values.success}>
                      <option value="">Select Success Date</option>
                      {eventDate?.map((item: any) => (
                        <option key={formatDate(item?.event_date)} value={item?.event_date}>
                          {formatDate(item?.event_date)}
                        </option>
                      ))}
                      { editId && !(eventDate.some(
                        (item: any) =>
                        item?.event_date == formik.values.success
                      )) && <option key={formik.values.success} value={formik.values.success}> {formatDate(formik.values.success)} </option>}

                    </Form.Select>
                    {formik?.touched?.success && formik?.errors?.success && (
                      <div className='fv-plugins-message-container text-danger'>
                        <span role='alert'>{formik?.errors?.success as any}</span>
                      </div>
                    )}
                  </div>
                </div>

                <div className='col-12 mt-10'>
                  <section className='container'>
                    <h4 className='fw-normal fs-6 border-gray-600'> Upload Presentation </h4>
                    <div {...getRootProps({ className: 'dropzone' })}>
                      <input {...getInputProps()}
                      // required 
                      />
                      {imageName ?
                        <div style={{ position: 'relative' }}>
                          <div  >
                            <i className="fa fa-trash fa-lg text-dark " onClick={(e) => {
                              e.stopPropagation();
                              setSelectedFile(null)
                              setSelectedOption(null)
                              setImageName(null)
                            }} style={{ position: 'absolute', top: '-42px', right: '-34px', backgroundColor: '#f89494', padding: "4px", borderRadius: "50%" }}></i></div>
                          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                            <img
                              src={toAbsoluteUrl('/media/ilead/pdf.png')}
                              alt=''
                              className='w-50px'
                              style={{ display: 'block', margin: '0 auto' }}
                            />
                            <p>{imageName}</p> </div></div>
                        : <p>Drag 'n' drop some files here, or click to select files</p>}

                      {/* <p>Drag 'n' drop some files here, or click to select files</p> */}
                    </div>
                  </section>
                </div>

                {/* <Button variant='primary' type='submit' className='mt-10 w-100'>
                  Submit
                </Button> */}
                <div className='modal-footer position-absolute bottom-0 w-100 pe-18'>
                  <Button variant='primary' type='submit' className='w-100' style={{ backgroundColor: '#f5f5f5' }} disabled={isLoading} >
                    {isLoading ? "Please wait" : "Submit"}
                  </Button>

                </div>

              </div>


            </Form>
          </div>
        </div>
      </div>
    </>
  )
}

export default AddTycaPresentation
