import React from "react";
import { Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Multiselect from 'multiselect-react-dropdown';
import AddEditChapter from "./AddEditChapter";

const EditChapter = () => {


    return (

        <>
            <div className="row mx-0 flex-md-block ">
                <div className='col-sm-6 col-md-6 col-lg-6'><h1 className='inv-hst sm-ms-2'> Edit Chapter </h1></div>
            </div>


            <div className='card mx-3 py-8 px-5 px-sm-8 mt-5 col-lg-6 col-md-8 col-sm-12 mx-auto visitor-details ilead__card'>
              <AddEditChapter />
            </div>


        </>
    )


}
export default EditChapter