import React, { useState, useContext, useRef, useEffect } from 'react'
import { Button, Form } from 'react-bootstrap'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import TextareaAutosize from 'react-textarea-autosize'
import { UserContext } from '../../../../../../contextStore'
import './webcamPopup.css'
import { createEventPostApi, createZoomMeetingApi } from '../_requests'
import axios from 'axios'
import Datetime from 'react-datetime';
import "react-datetime/css/react-datetime.css";
import moment from 'moment-timezone';

export function CreateZoomMeeting() {
    const [currentDate,setCurrentDate] = useState<any>("")
    const closebutton = useRef<any>()
    const [loading, setLoading] = useState<any>(false)
    const MAX_CHARACTERS = 1600
    const [messageCount, setMessageCount] = useState<any>(MAX_CHARACTERS)
    const { setMessage, userDetail, dashBoardReload, chapterID } = useContext(UserContext)
    const initialValues = {
        chapter_id: '',
        event_title: '',
        event_date_time: '',
        display_date_time: '',
        event_duration: '',
        descriptiontext: '',
    }

    const timeDuration = [
        {label:'30 Minutes',value:30},
        {label:'1:00 Hour',value:60},
        {label:'1:30 Hours',value:90},
        {label:'2:00 Hours',value:120}
    ]

    const validationSchema = Yup.object().shape({
        event_title: Yup.string().required('Event Title is required'),
        event_date_time: Yup.string()
            .required('Event Date and Time is required')
            .test('has-future-date',
                'Event Date and Time is must be Future Date and Time',
            (value) => currentDate.getTime() < new Date(value).getTime()
          ),
        event_duration: Yup.string().required('Event Duration is required'),
        descriptiontext: Yup.string().required('Description is required'),
    })

    const handleDescriptionChange = (event: any) => {
        const currentLength = event.target.value.length
        const remainingCharacters = MAX_CHARACTERS - currentLength
        setMessageCount(remainingCharacters)
        formik.handleChange(event)
    }

    const convertMinsToHrsMins = (minutes:any) =>  {
        let newObj = timeDuration.find((x:any)=>x.value == minutes)
        return newObj?.label ?? ""
    }

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: async (values: any, { resetForm }) => {
            setLoading(true)
            try {
                const postData = {
                    topic_id: 25,
                    topic_name: values.event_title,
                    event_date_time: values.event_date_time,
                    event_duration: values.event_duration,
                    descriptiontext: values.descriptiontext.trim(),
                    isactive: 1,
                    isdelete: 0,
                    created_by: userDetail.id,
                    user_id: userDetail.id,
                    chapter_id: userDetail?.chapter_id,
                } as any

                let zoomResponse = await createZoomMeetingApi(postData)

                if (zoomResponse?.data && zoomResponse?.data?.id) {
                    let zoomObj = {
                        topic_name: values.event_title,
                        event_date_time: moment(values.event_date_time).format('lll'),
                        event_duration: convertMinsToHrsMins(values.event_duration),
                        zoom_id: zoomResponse?.data?.id,
                        zoom_password: zoomResponse?.data?.password,
                        zoom_meeting_url: `https://zoom.us/j/${zoomResponse?.data?.id}?pwd=${zoomResponse?.data?.password}`
                    } as any
                    postData.zoom_description = JSON.stringify(zoomObj) as any
                    // `${postData.descriptiontext}\n Event Title: ${postData.topic_name}\n Event Date and Time: ${}\n Event Duration: ${convertMinsToHrsMins(postData.event_duration)}\n Zoom Meeting Id: ${zoomResponse?.data?.id}\n Zoom Meeting Password: ${zoomResponse?.data?.password}\n Zoom Meeting URL: https://zoom.us/j/${zoomResponse?.data?.id}?pwd=${zoomResponse?.data?.password}`
                    // API call
                    await createEventPostApi(postData).then((res: any) => {
                        if (res) {
                            setMessage('success', 'Event created successfully')
                            resetForm()
                            setMessageCount(MAX_CHARACTERS)
                            formik.setFieldValue('descriptiontext', '')
                            formik.resetForm()
                            setLoading(false)
                            dashBoardReload()
                            closebutton?.current?.click()
                        }
                    }).catch((error: any) => {
                        if (error?.response?.data?.error?.message) {
                            setMessage('error', error?.response?.data?.error?.message)
                        } else {
                            setMessage('error', 'Something Went Wrong')
                        }
                        setLoading(false)
                    })
                }
                else {
                    setMessage('error', 'Something went wrong')
                    setLoading(false)
                }
            } catch (error) {
                setLoading(false)
                setMessage('error', 'Something went wrong')
            }
        },
    })


    useEffect(()=>{
        let d =  new Date()
        const oneMinuteAgo = new Date(d.getTime() - 120000);
        setCurrentDate(oneMinuteAgo)
    },[])

    return (
        <>
            <div
                className='modal fade ilead-default-modal'
                id='CreateZoomMeetingModal'
                aria-hidden='true'
                aria-labelledby='CreateZoomMeetingModalLabel'
            >
                <div className='modal-dialog modal-dialog modal-dialog-scrollable modal-fullscreen-md-down modal-md'>
                    <div className='modal-content'>
                        <div className='modal-header py-5'>
                            <h3 className='modal-title fs-3 fw-bold' id='CreateZoomMeetingModalToggleLabel'>
                                Create Zoom Event
                            </h3>
                            <button
                                type='button'
                                className='btn-close text-white'
                                data-bs-target='#write_post_modal'
                                data-bs-toggle='modal'
                                data-bs-dismiss='modal'
                                onClick={() => {
                                    setMessageCount(MAX_CHARACTERS)
                                    formik.setFieldValue('descriptiontext', '')
                                    formik.resetForm()
                                }}
                                ref={closebutton}
                            ></button>
                        </div>
                        <div className='modal-body pt-7 write-post-wrapper'>
                            <form
                                className='mb-7'
                                onSubmit={(e) => {
                                    formik.handleSubmit(e)
                                }}
                            >
                                {/* <h3 className='text-black fw-bold fs-4 mb-6 required'>Select Chapter to Post</h3> */}
                                <Form.Group className='mb-3'>
                                    <Form.Label className='text-dark fw-semibold required'>Event Title</Form.Label>
                                    <Form.Control
                                        type='text'
                                        placeholder='Event Title'
                                        className='ilead-input'
                                        onChange={(e) => {
                                            formik.setFieldValue('event_title', e.target.value)
                                        }}
                                        value={formik.values.event_title}
                                    />
                                    {formik.touched.event_title && formik.errors.event_title && (
                                        <div className='fv-plugins-message-container text-danger'>
                                            <span role='alert'>{formik.errors.event_title as any}</span>
                                        </div>)}
                                </Form.Group>
                                <Form.Group className='mb-3'>
                                    <Form.Label className='text-dark fw-semibold required'>Event Date And Time</Form.Label>
                                    <Datetime
                                        // utc
                                        dateFormat="DD/MM/YYYY" // Set the date display format
                                        timeFormat="hh:mm A"
                                        onChange={(e: any) => {
                                            if(e.toISOString()){
                                                formik.setFieldValue('event_date_time', moment(e.toISOString()).tz("Asia/Kolkata").format())
                                                formik.setFieldValue('display_date_time', moment(e.toISOString()).tz("Asia/Kolkata").format('lll'))
                                            }
                                        }}
                                    />
                                    {formik.touched.event_date_time && formik.errors.event_date_time && (
                                        <div className='fv-plugins-message-container text-danger'>
                                            <span role='alert'>{formik.errors.event_date_time as any}</span>
                                        </div>)}
                                </Form.Group>
                                <Form.Group className='mb-3'>
                                    <Form.Label className='text-dark fw-semibold required'>Event Duration</Form.Label>
                                    <Form.Select
                                        aria-label='Default select example'
                                        className='ilead-input'
                                        value={formik.values.event_duration}
                                        onChange={(e) => {
                                            formik.setFieldValue('event_duration', e.target.value !== "" ? parseInt(e.target.value) : "")
                                        }}
                                    >
                                        <option value={""}>Select Duration</option>
                                        {timeDuration.map((x:any)=> <option value={x?.value}>{x?.label}</option>)}
                                    </Form.Select>
                                    {/* <Form.Control
                                        type='number'
                                        placeholder='Event Duration'
                                        className='ilead-input'
                                        onChange={(e) => {
                                            formik.setFieldValue('event_duration', e.target.value !== "" ? parseInt(e.target.value) : "")
                                        }}
                                        value={formik.values.event_duration}
                                    /> */}
                                    {formik.touched.event_duration && formik.errors.event_duration && (
                                        <div className='fv-plugins-message-container text-danger'>
                                            <span role='alert'>{formik.errors.event_duration as any}</span>
                                        </div>)}
                                </Form.Group>
                              
                                <Form.Group className='mb-3'>
                                    <TextareaAutosize
                                        minRows={3}
                                        autoFocus
                                        name='descriptiontext'
                                        className='custom-text-area form-control  ilead-input'
                                        placeholder='Description'
                                        onChange={handleDescriptionChange}
                                        maxLength={MAX_CHARACTERS}
                                        value={formik.values.descriptiontext}
                                    />
                                    <p className='text-gray-500 fs-13 required'>
                                        Available Characters
                                    </p>
                                    {formik?.touched?.descriptiontext && formik?.errors?.descriptiontext && (
                                        <div className='fv-plugins-message-container text-danger'>
                                            <span role='alert'>{formik?.errors?.descriptiontext as any}</span>
                                        </div>
                                    )}
                                </Form.Group>


                                <Button
                                    variant='primary'
                                    type='submit'
                                    // data-bs-toggle={formik.isValid ? 'modal' : undefined}
                                    // data-bs-dismiss={formik.isValid ? 'modal' : undefined}
                                    className='w-100 mt-7'
                                    disabled={formik.isSubmitting}
                                >
                                    Submit
                                </Button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            {/* begin: Ask for Help Modal */}
        </>
    )
    // document.getElementById('element')
}
