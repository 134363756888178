// @ts-nocheck
import { Column } from 'react-table'
import { SessionattendanceInfoCell } from './SessionattendanceInfoCell'
import { SessionattendanceLastLoginCell } from './SessionattendanceLastLoginCell'
import { SessionattendanceTwoStepsCell } from './SessionattendanceTwoStepsCell'
import { SessionattendanceActionsCell } from './SessionattendanceActionsCell'
import { SessionattendanceSelectionCell } from './SessionattendanceSelectionCell'
import { SessionattendanceCustomHeader } from './SessionattendanceCustomHeader'
import { SessionattendanceSelectionHeader } from './SessionattendanceSelectionHeader'
import { Sessionattendance } from '../../core/_models'
import moment from 'moment'
import { checkDisplayValue } from '../../../../../../utils'


const sessionattendancesColumns: ReadonlyArray<Column<Sessionattendance>> = [
  // {
  //   Header: (props) => <SessionattendanceSelectionHeader tableProps={props} />,
  //   id: 'selection',
  //   Cell: ({...props}) => <SessionattendanceSelectionCell id={props.data[props.row.index].id} />,
  // },
  {
    Header: (props) => <SessionattendanceCustomHeader tableProps={props} title='Chapter name' className='min-w-125px fs-5' />,
    accessor: 'chapter_name',
    // id: 'name',
    // Cell: ({...props}) => <SessionattendanceInfoCell sessionattendance={props.data[props.row.index]} />,
  },
  {
    Header: (props) => <SessionattendanceCustomHeader tableProps={props} title='Leader Name' className='min-w-125px fs-5' />,
    accessor: 'leader_name',
    // id: 'name',
    // Cell: ({...props}) => <SessionattendanceInfoCell sessionattendance={props.data[props.row.index]} />,
  },

  {
    Header: (props) => <SessionattendanceCustomHeader tableProps={props} title='Event Date' className='min-w-125px fs-5' />,
    accessor: 'event_date',
    // id: 'name',
    Cell: ({...props}) => props?.value ? moment(props?.value).format('DD-MM-YYYY') : checkDisplayValue(props?.value),
  },
  {
    Header: (props) => (
      <SessionattendanceCustomHeader tableProps={props} title='Attendance' className='min-w-100px fs-5' />
    ),
    accessor: 'isattended',
    // id: 'actions',
    Cell: ({ ...props }) => <SessionattendanceActionsCell id={props.data[props.row.index]._id} value={props?.value}/>,
  },
  {
    Header: (props) => <SessionattendanceCustomHeader tableProps={props} title='Present Time' className='min-w-125px fs-5' showSorting={false} />,
    accessor: 'scanned_at',
    Cell: ({...props}) => props?.value ? moment(props?.value,"HH:mm:ss").format('hh:mm:ss A') : checkDisplayValue(props?.value),
  },
  // {
  //   Header: (props) => (
  //     <SessionattendanceCustomHeader tableProps={props} title='Last login' className='min-w-125px' />
  //   ),
  //   id: 'last_login',
  //   Cell: ({...props}) => <SessionattendanceLastLoginCell last_login={props.data[props.row.index].last_login} />,
  // },
  // {
  //   Header: (props) => (
  //     <SessionattendanceCustomHeader tableProps={props} title='Two steps' className='min-w-125px' />
  //   ),
  //   id: 'two_steps',
  //   Cell: ({...props}) => <SessionattendanceTwoStepsCell two_steps={props.data[props.row.index].two_steps} />,
  // },
  // {
  //   Header: (props) => (
  //     <SessionattendanceCustomHeader tableProps={props} title='Joined day' className='min-w-125px' />
  //   ),
  //   accessor: 'joined_day',
  // },
  // {
  //   Header: (props) => (
  //     <SessionattendanceCustomHeader tableProps={props} title='Actions' className='text-end min-w-100px' />
  //   ),
  //   id: 'actions',
  //   Cell: ({...props}) => <SessionattendanceActionsCell id={props.data[props.row.index].id} />,
  // },
]

export { sessionattendancesColumns }
