// @ts-nocheck
import {Column} from 'react-table'
import {LibrarymanagementInfoCell} from './LibrarymanagementInfoCell'
import {LibrarymanagementLastLoginCell} from './LibrarymanagementLastLoginCell'
import {LibrarymanagementTwoStepsCell} from './LibrarymanagementTwoStepsCell'
import {LibrarymanagementActionsCell} from './LibrarymanagementActionsCell'
import {LibrarymanagementSelectionCell} from './LibrarymanagementSelectionCell'
import {LibrarymanagementCustomHeader} from './LibrarymanagementCustomHeader'
import {LibrarymanagementSelectionHeader} from './LibrarymanagementSelectionHeader'
import {Librarymanagement} from '../../core/_models'
import { checkDisplayValue } from '../../../../../../utils'

const librarymanagementsColumns: ReadonlyArray<Column<Librarymanagement>> = [
  // {
  //   Header: (props) => <LibrarymanagementSelectionHeader tableProps={props} />,
  //   id: 'selection',
  //   Cell: ({...props}) => <LibrarymanagementSelectionCell id={props.data[props.row.index].id} />,
  // },
 
  {
    Header: (props) => <LibrarymanagementCustomHeader tableProps={props} title='Title' className='min-w-125px   ps-2 fs-5' />,
    accessor: 'title',
    // id: 'name',
    Cell: ({value}) => checkDisplayValue(value),
    // Cell: ({...props}) => <LibrarymanagementInfoCell librarymanagement={props.data[props.row.index]} />,
  },

  
  
  {
      Header: (props) => (
        <LibrarymanagementCustomHeader tableProps={props} title='Actions' className='text-center min-w-150px' />
      ),
      id: 'actions',
      Cell: ({...props}) => <LibrarymanagementActionsCell id={props.data[props.row.index].id} />,
    },

  // {
  //   Header: (props) => (
  //     <LibrarymanagementCustomHeader tableProps={props} title='Last login' className='' />
  //   ),
  //   id: 'last_login',
  //   Cell: ({...props}) => <LibrarymanagementLastLoginCell last_login={props.data[props.row.index].last_login} />,
  // },
  // {
  //   Header: (props) => (
  //     <LibrarymanagementCustomHeader tableProps={props} title='Two steps' className='min-w-125px' />
  //   ),
  //   id: 'two_steps',
  //   Cell: ({...props}) => <LibrarymanagementTwoStepsCell two_steps={props.data[props.row.index].two_steps} />,
  // },
  // {
  //   Header: (props) => (
  //     <LibrarymanagementCustomHeader tableProps={props} title='Joined day' className='min-w-125px' />
  //   ),
  //   accessor: 'joined_day',
  // },
  
]

export {librarymanagementsColumns}
