import React, { useEffect } from "react";
import { toAbsoluteUrl } from "../../../_metronic/helpers";
import { Button } from "react-bootstrap";
import { apiUrl } from "../../../apiurl";




const IndexDetailsBI = ({ dataBI, i }: { dataBI: any, i: any }) => {

  useEffect(() => {
    window.history.pushState(null, 'i can i will', window.location.href)
    window.addEventListener('popstate', function (event: any) {
    })
  })
  useEffect(() => {
    window.history.pushState(null, 'i can i will', window.location.href)
    window.addEventListener('popstate', function (event: any) {
    })
  })
  useEffect(() => {
    window.history.pushState(null, 'i can i will', window.location.href)
    window.addEventListener('popstate', function (event: any) {
    })
  })

  const clickOnbutton = () => {
    window.history.pushState({}, 'i can i will', `${window.location.origin}/dashboard`);
    window.history.pushState({}, 'i can i will', `${window.location.origin}/dashboard`);
  }

  return (

    <>
      <div className='modal fade reset-date-psw index_detail_modal_class' tabIndex={-1} id={`index_detail_modal11${i}`}>
        <div className='modal-dialog  growth-listing'>
          <div className='modal-content'>
            <div className='modal-header py-5'>
              <h3 className="modal-title fs-3 fw-bold" style={{ color: "white" }} id="writePostModalToggleLabel"> Business Index Detail </h3>
              <button
                type='button'
                className='text-white border-0 bg-transparent close-btn'
                data-bs-dismiss='modal'
                aria-label='Close'
                onClick={()=>clickOnbutton()}
              > <i className="fa-solid fa-xmark fs-2 text-white" ></i> </button>
            </div>

            <div className='modal-body'>
              <div className='col-lg-12 col-xl-12 d-flex flex-nowrap mb-5'>
                <div className='col-2'>
                  <div className='thumbnail-box'>
                    <img
                      style={{ border: '1px solid #555' }}
                      src={dataBI?.user?.profile ? `${apiUrl}/containers/userprofile/download/${dataBI?.user?.profile}` : `${toAbsoluteUrl('./media/avatars/default-user.png')}`}
                      alt={`Not Found.`}
                      onError={({ currentTarget }: any) => {
                        currentTarget.onerror = null // prevents looping
                        currentTarget.src = `${toAbsoluteUrl('./media/avatars/default-user.png')}`
                      }}
                      className='thumbnail-img'
                    />
                  </div>
                </div>
                <div className='col-8 ps-3'>
                  <p className='mb-2 fw-bold' data-bs-toggle='modal' data-bs-target={`#index_detail_modal11${i}`}> {dataBI?.user?.name} </p>
                  <p className='mb-2 fw-semibold '> {dataBI?.user?.companyname} </p>
                </div>

                <Button type='button'>
                  <h5 className='fw-semibold fe-6 text-center text-white'>{dataBI?.total_points}</h5>
                  <p className='fw-normal fe-7 mb-0 text-center'> Point </p>
                </Button>
              </div>

              <hr className="mt-15 mb-8" />


              <div className="row">
                <div className="col-xl-6 col-md-6 col-sm-12 leader-perform">
                  <div className="leader_box">
                    <button type="button" className="thanks_count" data-bs-toggle="modal" data-bs-target="#exampleModal">
                      {dataBI?.business_referral_sent}
                    </button>
                    <p className="mt-3 fw-6"> Business Referral Given </p>
                  </div>
                </div>
                <div className="col-xl-6 col-md-6 col-sm-12 leader-perform">
                  <div className="leader_box">
                    <button type="button" className="thanks_count" data-bs-toggle="modal" data-bs-target="#exampleModal">
                      {dataBI?.business_referral_received}
                    </button>
                    <p className="mt-3 fw-6"> Business Referral Received </p>
                  </div>
                </div>
                <div className="col-xl-6 col-md-6 col-sm-12 leader-perform">
                  <div className="leader_box">
                    <button type="button" className="thanks_count" data-bs-toggle="modal" data-bs-target="#exampleModal">
                      {dataBI?.business_generate_sent}
                    </button>
                    <p className="mt-3 fw-6">  Business Generate Sent </p>
                  </div>
                </div>
                <div className="col-xl-6 col-md-6 col-sm-12 leader-perform">
                  <div className="leader_box">
                    <button type="button" className="thanks_count" data-bs-toggle="modal" data-bs-target="#exampleModal">
                      {dataBI?.business_generate_received}
                    </button>
                    <p className="mt-3 fw-6">  Business Generate Received </p>
                  </div>
                </div>
                <div className="col-xl-6 col-md-6 col-sm-12 leader-perform">
                  <div className="leader_box">
                    <button type="button" className="thanks_count" data-bs-toggle="modal" data-bs-target="#exampleModal">
                      {dataBI?.business_connect_sent}
                    </button>
                    <p className="mt-3 fw-6">  Business Connect Sent </p>
                  </div>
                </div>
                <div className="col-xl-6 col-md-6 col-sm-12 leader-perform">
                  <div className="leader_box">
                    <button type="button" className="thanks_count" data-bs-toggle="modal" data-bs-target="#exampleModal">
                      {dataBI?.business_connect_received}
                    </button>
                    <p className="mt-3 fw-6"> Business Connect Received</p>
                  </div>
                </div>

                {/* <div className="col-xl-6 col-md-6 col-sm-12 leader-perform">
                  <div className="leader_box"><button type="button" className="thanks_count" data-bs-toggle="modal" data-bs-target="#exampleModal">12</button></div>
                </div> */}
              </div>

            </div>
          </div>
        </div>
      </div>



    </>

  )

}

export default IndexDetailsBI