import { useEffect, useMemo, useState } from 'react'
import { useTable, ColumnInstance, Row } from 'react-table'
import { CustomHeaderColumn } from './columns/CustomHeaderColumn'
import { CustomRow } from './columns/CustomRow'
import { useQueryResponseData, useQueryResponseLoading } from '../core/QueryResponseProvider'
import { groupmanagementsColumns } from './columns/_columns'
import { Groupmanagement } from '../core/_models'
import { GroupmanagementsListLoading } from '../components/loading/GroupManagementsListLoading'
import { GroupmanagementsListPagination } from '../components/pagination/GroupManagementsListPagination'
import { KTCardBody, defaultGroupmanagements } from '../../../../../_metronic/helpers'
import { useQueryRequest } from '../core/QueryRequestProvider'
import { getChapterList } from '../core/_requests'
import { checkDisplayValue } from '../../../../../utils'



// import {KTCardBody} from '../../../../../../_metronic/helpers'

const GroupmanagementsTable = () => {
  const groupmanagements = useQueryResponseData()
  // const groupmanagements = defaultGroupmanagements
  const isLoading = useQueryResponseLoading()
  const data = useMemo(() => groupmanagements, [groupmanagements])
  const columns = useMemo(() => groupmanagementsColumns, [])
  const { getTableProps, getTableBodyProps, headers, rows, prepareRow } = useTable({
    columns,
    data,
  })
  const [chapterList, setChapterList] = useState<any>([
    { label: "All", value: "" }
  ])

  const { state, updateState } = useQueryRequest() as any

  const getChapter = async () => {
    await getChapterList().then((response: any) => {
      if (response && response?.length > 0) {

        let clist = response?.map((x: any) => {
          return { label: x?.name, value: x?.id }
        }) as any ?? []
        clist.unshift({ label: "All", value: "" })
        setChapterList(clist)

      }
      // response 
    }).catch((error: any) => {
      console.log("error", error)
    })
  }

  useEffect(() => {
    getChapter()
  }, [])

  return (
    <KTCardBody className='p-0'>
      <div className='table-responsive mb-8 data-table-report'>
        <table
          id='kt_table_groupmanagements'
          className='table align-middle table-row-dashed fs-6 gy-5 dataTable table-striped  no-footer'
          {...getTableProps()}
        >
          <thead>
            <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0 border-bottom border-gray-100'>
              {headers.map((column: ColumnInstance<Groupmanagement>) => (
                <CustomHeaderColumn key={column.id} column={column} />
              ))}
            </tr>
          </thead>

          <thead className='table-sub-hdr'>

            <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
              {/* {headers.map((column: ColumnInstance<Sessionattendance>) => (
                <input type="text"/>
              ))} */}
              <th className='p-3'> <input type="text" className="form-control mb-1" placeholder=""
                value={state?.filter?.group_name}
                onChange={(e: any) => {
                  let filter = state?.filter ?? {}
                  filter['group_name'] = e.target.value
                  updateState({ ...state, ...{ filter: filter } })
                }} /> </th>
              <th className='p-3'>
                <select className="form-select mb-1" aria-label="Default select example"
                  onChange={(e: any) => {
                    let filter = state?.filter ?? {}
                    filter['chapter_id'] = e.target.value
                    updateState({ ...state, ...{ filter: filter } })
                  }}>
                  {chapterList.map((x: any) => {
                    return <option value={x?.value}>{x?.label}</option>
                  })}

                </select>
              </th>


              <th className='p-3'> &nbsp; </th>
            </tr>
          </thead>

          <tbody className='text-gray-600 text-center fw-bold' {...getTableBodyProps()}>
            {rows.length > 0 ? (
              rows.map((row: Row<Groupmanagement>, i) => {
                prepareRow(row)
                return <CustomRow row={row} key={`row-${i}-${row.id}`} />
              })
            ) : (
              <tr>
                <td colSpan={7}>
                  <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                    No matching records found
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>

        <table
          id='copy_kt_table_groupmanagements'
          className='table align-middle table-row-dashed fs-6 gy-5 dataTable table-striped  no-footer'
          {...getTableProps()}
          style={{ display: 'none' }}
        >
          <thead>
            <tr className='text-center text-muted fw-bolder fs-7 text-uppercase gs-0 border-bottom border-gray-100'>
              {headers.map((column: ColumnInstance<any>) => {
                if (column.id == 'actions') {
                  return <></>
                }
                return < CustomHeaderColumn key={column.id} column={column} />
              })}
            </tr>
          </thead>



          <tbody className='text-gray-600 text-center fw-bold' {...getTableBodyProps()}>
            {rows.length > 0 ? (
              rows.map((row: Row<Groupmanagement>, i) => {
                prepareRow(row)
                return <tr key={i}>
                  <td>{checkDisplayValue(row?.values?.name)}</td>
                  <td>{checkDisplayValue(row?.values?.chapter)}</td>
                </tr>
              })
            ) : (
              <tr>
                <td colSpan={7}>
                  <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                    No matching records found
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <GroupmanagementsListPagination />
      {isLoading && <GroupmanagementsListLoading />}
    </KTCardBody>
  )
}

export { GroupmanagementsTable }
