import { useEffect, useMemo, useState } from 'react'
import { useTable, ColumnInstance, Row } from 'react-table'
import { CustomHeaderColumn } from './columns/CustomHeaderColumn'
import { CustomRow } from './columns/CustomRow'
import { useQueryResponseData, useQueryResponseLoading } from '../core/QueryResponseProvider'
// import {extingusersColumns} from './columns/_columns'
// import {Extinguser} from '../core/_models'
// import {ExtingusersListLoading} from '../components/loading/ExtingusersListLoading'
// import {ExtingusersListPagination} from '../components/pagination/ExtingusersListPagination'
// import { KTCardBody, defaultExtingusers } from '../../../../../_metronic/helpers'
import { exitingusersColumns } from './columns/_columns'
import { Exitinguser, ExitingusersQueryResponse } from '../core/_models'
import { KTCardBody, defaultExitingusers } from '../../../../../_metronic/helpers'
import { ExitingusersListPagination } from '../components/pagination/ExitingusersListPagination'
import { ExitingusersListLoading } from '../components/loading/ExitingusersListLoading'
import { useQueryRequest } from '../core/QueryRequestProvider'
import { getChapterList } from '../../../auth/core/_requests'
// import {initialQueryState} from '../../../../../../_metronic/helpers'
import { initialQueryState } from '../../../../../_metronic/helpers'
import { checkDisplayValue } from '../../../../../utils'



const ExtingusersTable = () => {
  const extingusers = useQueryResponseData()
  // const extingusers = defaultExitingusers
  const {updateState} = useQueryRequest()

  const isLoading = useQueryResponseLoading()
  const data = useMemo(() => extingusers, [extingusers])
  const columns = useMemo(() => exitingusersColumns, [])
  const { getTableProps, getTableBodyProps, headers, rows, prepareRow } = useTable({
    columns,
    data,
  })

  const [chapterList,setchapterList] =useState([])
  const [reqData, setReqData] = useState<any>({
    name: '',
    mobileno: '',
    company: '',
    chapter_id: '',
    companyname: '',
    isactive: '',
  })

  const getchpterList= async ()=>{
    try {
        const res = await getChapterList()
        setchapterList(res?.data)
    } catch (error) {

    }
  }

  useEffect(()=>{
    getchpterList()
  },[])

  const filterData = () => {
    updateState({
      filter: reqData,
      ...initialQueryState,
    })
  }

  useEffect(()=>{
    filterData()
  },[reqData])




  

  return (
    <KTCardBody className='p-0'>
      <div className='table-responsive mb-8 data-table-report'>
        <table
          id='kt_table_extingusers'
          className='table align-middle table-row-dashed fs-6 gy-5 dataTable table-striped  no-footer'
          {...getTableProps()}
        >
          <thead>
            <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0 border-bottom border-gray-100'>
              {headers.map((column: ColumnInstance<Exitinguser>) => (
                <CustomHeaderColumn key={column.id} column={column} />
              ))}
            </tr>
          </thead>

          <thead className='table-sub-hdr'>

            <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
              <th className='p-3'>
                 <select className="form-select form-select-lg mb-3 mt-2" 
                 onChange={(e:any)=>{
                  console.log("3",e?.target?.value)
                  setReqData({...reqData, ...{chapter_id: e?.target?.value?.trimStart()}})
                }}
                 aria-label=".form-select-lg example">
                {/* <option selected>All</option>
                <option value="2">Eklavya</option> */}
                <option selected={reqData?.chapter_id == ""} value=""> ALL </option>
                {chapterList.map((item:any,i:any)=>{
                  return(<option selected={reqData?.chapter_id == item?.id} value={item.id}>{item.name}</option>)
                })}
              </select> </th>
              <th className='p-3'> <input type="text" className="form-control mb-3" 
              onChange={(e:any)=>{
                setReqData({...reqData, ...{name: e?.target?.value?.trimStart()}})
              }}
               placeholder=" " /> </th>
              <th className='p-3'> <input type="text" className="form-control mb-3"
              onChange={(e:any)=>{
                setReqData({...reqData, ...{mobileno: e?.target?.value?.trimStart()}})
              }}
              placeholder="" /> </th>
              <th className='p-3'> <input type="text" className="form-control mb-3"
              onChange={(e:any)=>{
                setReqData({...reqData, ...{email: e?.target?.value?.trimStart()}})
              }}
              placeholder="" /> </th>
              <th className='p-3'> <input type="text" className="form-control mb-3" 
              onChange={(e:any)=>{
                setReqData({...reqData, ...{companyname: e?.target?.value?.trimStart()}})
              }}
              placeholder="" /> </th>
              <th className='p-3'> &nbsp; </th>
              <th className='p-3'> <select className="form-select form-select-lg mb-3 mt-2" aria-label=".form-select-lg example"
              onChange={(e:any)=>{
                setReqData({...reqData, ...{isactive: e?.target?.value?.trimStart()}})
              }}>
                <option selected={reqData?.chapter_id == ""} value="">All</option>
                <option selected={reqData?.chapter_id == "1"} value="1">Yes</option>
                <option selected={reqData?.chapter_id == "0"} value="0">No</option>
              </select>  </th>
              <th className='p-3'> &nbsp; </th>
            </tr>
          </thead>

          <tbody className='text-gray-600 text-center fw-bold' {...getTableBodyProps()}>
            {rows.length > 0 ? (
              rows.map((row: any, i) => {
                prepareRow(row)
                return <CustomRow row={row as any} key={`row-${i}-${row.id}`} />
              })
            ) : (
              <tr>
                <td colSpan={7}>
                  <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                    No matching records found
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>

        {/* copy for export   */}
        <table
          id='copy_kt_table_extingusers'
          className='table align-middle table-row-dashed fs-6 gy-5 dataTable table-striped  no-footer'
          {...getTableProps()}
          style={{ display: 'none' }}
        >
          <thead>
                <tr 
                className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0 border-bottom border-gray-100'
                >
                    {headers.map((column: ColumnInstance<any>) => {
                        if(column.id == "actions") {
                          return <></>
                        }
                    return <CustomHeaderColumn key={column.id} column={column} />
                    })}
                </tr>
            </thead>

          <tbody className='text-gray-600 text-center fw-bold' {...getTableBodyProps()}>
            {rows.length > 0 ? (
              rows.map((row: any, i) => {
                console.log("row",row)
                return <tr key={i}>
                    <td>
                        {checkDisplayValue(row?.original?.chapter_name)}
                    </td>
                    <td>
                        {checkDisplayValue(row?.original?.name)}
                    </td>
                    <td>
                        {checkDisplayValue(row?.original?.mobileno)}
                    </td>
                    <td>
                        {checkDisplayValue(row?.original?.email)}
                    </td>
                    <td>
                        {checkDisplayValue(row?.original?.companyname)}
                    </td>
                    <td>
                        {checkDisplayValue(row?.original?.verificationcode)}
                    </td>
                    <td>
                        {checkDisplayValue(row?.original?.isactive ? "Yes" : "No")}
                    </td>
                </tr>
            })
            ) : (
              <tr>
                <td colSpan={7}>
                  <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                    No matching records found
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <ExitingusersListPagination />
      {isLoading && <ExitingusersListLoading />}
    </KTCardBody>
  )
}

export { ExtingusersTable }
