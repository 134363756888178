let accessUser = {
  announcement: [
    1, 52, 64, 140, 141, 64, 112, 9, 139, 15, 170, 59, 162, 25, 96, 182, 192, 19, 94, 56, 170, 14,
    178, 56, 149, 332, 59, 146, 236, 33, 283, 243, 327, 266, 17, 285, 19, 62, 228
  ],

  hidedelete: [
    1, 52, 64, 140, 141, 64, 112, 9, 139, 15, 170, 59, 162, 25, 96, 182, 192, 149, 177, 107, 19, 94, 332,
  ],

  addedittyca: [
    1, 52, 64, 140, 141, 64, 112, 9, 139, 15, 170, 59, 162, 25, 96, 182, 192, 149, 177, 107, 19, 94, 332,
  ],

  sessionfeedback: [
    1, 52, 64, 140, 141, 64, 112, 9, 139, 15, 170, 59, 162, 25, 96, 182, 192, 19, 94, 332,
  ],

  visitorpanel: [
    1, 52, 64, 140, 141, 64, 112, 9, 139, 15, 170, 59, 162, 25, 96, 182, 192, 62, 33, 119, 19, 94, 332,
  ],

  //Chapter Admin For Invitation Panel 
  paneladmin : [2, 59, 162, 25, 96, 182, 192, 119],
  //Super Admin For Invitation Panel 
  panelsuperadmin : [1, 52, 64, 140, 141, 9, 64,19,94],

  usermanagement: [1, 52, 64, 140, 141, 64, 112, 9, 19, 94, 332],

  sessionattenance: [
    1, 52, 64, 140, 141, 64, 112, 9, 139, 15, 170, 59, 162, 25, 96, 182, 62, 33, 119, 19, 94, 332
  ],

  groupmeetingreport: [
    1, 52, 64, 140, 141, 64, 112, 9, 139, 15, 170, 59, 162, 25, 96, 182, 192, 19, 94, 332,
  ],

  chapterdropdown: [1, 52, 64, 140, 141, 64, 112, 9, 19, 94, 332],

  tycasuperadmin: [
    1, 52, 64, 140, 141, 112, 9, 64, 139, 15, 170, 59, 162, 25, 96, 182, 192, 19, 94, 332,
  ],

  sessionsummary: [
    1, 52, 64, 140, 141, 64, 112, 9, 139, 15, 170, 59, 162, 25, 96, 182, 192, 19, 94, 332,
  ],

  secretariatRole: [1, 52, 64, 140, 141, 64, 112, 9, 19, 94, 332]
}

export {accessUser}
