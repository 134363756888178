

import { ID, Response } from "../../../../../../_metronic/helpers"

export type Reminder = {
  id?: ID
  name?: string
  avatar?: string
  email?: string
  position?: string
  role?: string
  title?:string
  notification_type?:string
 
}

export type RemindersQueryResponse = Response<Array<Reminder>>

export const initialReminder: Reminder = {
  avatar: 'avatars/300-6.jpg',
  position: 'Art Director',
  role: 'Administrator',
  name: '',
  email: '',

}
