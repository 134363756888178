import {useQuery} from 'react-query'
import {NotificationEditModalForm} from './NotificationEditModalForm'
// import {isNotEmpty, QUERIES} from '../../../../../../_metronic/helpers'
import {useListView} from '../core/ListViewProvider'
// import { QUERIES, isNotEmpty } from '../../../../../_metronic/helpers'
import { getNotificationById } from '../core/_requests'
import { QUERIES, isNotEmpty } from '../../../../../../_metronic/helpers'
// import { getNotificationById } from '../../../apps/notification-management/notifications-list/core/_requests'
// import {getNotificationById} from '../core/_requests'

const NotificationEditModalFormWrapper = () => {
  const {itemIdForUpdate, setItemIdForUpdate} = useListView()
  const enabledQuery: boolean = isNotEmpty(itemIdForUpdate)
  const {
    isLoading,
    data: notification,
    error,
  } = useQuery(
    `${QUERIES.NOTIFICATIONS_LIST}-notification-${itemIdForUpdate}`,
    () => {
      return getNotificationById(itemIdForUpdate)
    },
    {
      cacheTime: 0,
      enabled: enabledQuery,
      onError: (err) => {
        setItemIdForUpdate(undefined)
        console.error(err)
      },
    }
  )

  if (!itemIdForUpdate) {
    return <NotificationEditModalForm isNotificationLoading={isLoading} notification={{id: undefined}} />
  }

  if (!isLoading && !error && notification) {
    return <NotificationEditModalForm isNotificationLoading={isLoading} notification={notification} />
  }

  return null
}

export {NotificationEditModalFormWrapper}
