import { ID, Response } from "../../../../../_metronic/helpers"

// import {ID, Response} from '../../../../../../_metronic/helpers'
export type Visitor = {
  id?: ID
  avatar?: string
  position?: string
  role?: string
  event_date?:string,
  name?:string,
  mobile?:string,
  email?:string,
  company_name?:string,
 
}

export type VisitorsQueryResponse = Response<Array<Visitor>>

export const initialVisitor: Visitor = {
  avatar: 'avatars/300-6.jpg',
  position: 'Art Director',
  role: 'Administrator',
  name: '',
  email: '',

}
