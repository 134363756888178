import React, {useEffect, useState} from 'react'
import {GroupmeetingsListWrapper} from './groups-list/GroupmeetingsList'
import {useNavigate} from 'react-router-dom'
import {QueryRequestProvider, useQueryRequest} from './groups-list/core/QueryRequestProvider'
import {getChaptersList} from './groups-list/core/_requests'
import {QueryResponseProvider} from './groups-list/core/QueryResponseProvider'

const GroupMeetingListing = () => {
  const [chapterList, setChapterList] = useState<any>([{label: 'All', value: ''}])
  const {state, updateState} = useQueryRequest() as any


  const navigate = useNavigate()
  const navigateAddGroupMeeting = () => {
    navigate('/add-groupmeeting')
  }

  const getChapter = async () => {
    await getChaptersList()
      .then((response: any) => {
        if (response && response?.length > 0) {
          let clist =
            (response?.map((x: any) => {
              return {label: x?.name, value: x?.id}
            }) as any) ?? []
          clist.unshift({label: 'All', value: ''})
          setChapterList(clist)
          console.log('responseresponseresponse', clist)
        }
      })
      .catch((error: any) => {
        console.log('error', error)
      })
  }

  useEffect(() => {
    getChapter()
  }, [])

  return (
    <>
      
          <div className='row flex-md-block '>
            <div className='col-sm-6 col-md-6 col-lg-6'>
              <h1 className='inv-hst sm-ms-2'> Group Meeting Reports </h1>
            </div>

            <div className='col-sm-6  col-md-6 col-lg-6 mb-5 text-end'>
              <button
                className='btn btn-primary waves-effect waves-light btn-sm'
                onClick={navigateAddGroupMeeting}
              >
                {' '}
                Submit New{' '}
              </button>
            </div>
          </div>

          <div className='card mx-3 py-8 px-5 px-sm-8 mt-5 '>
            <div className='row mb-6'>
              <div className='col-lg-3 col-md-4 col-sm-12'>
                <select
                  className='form-select'
                  aria-label='Default select example'
                  onChange={(e: any) => {
                    let filter = state?.filter ?? {}
                    filter['chapter_id'] = e.target.value
                    console.log('stste', filter['chapter_id'])
                    updateState({...state, ...{filter: filter}})
                    console.log('stste', {...state, ...{filter: filter}})
                  }}
                  value={state?.filter?.chapter_id}
                >
                  {chapterList.map((x: any) => {
                    return <option value={x?.value}>{x?.label}</option>
                  })}
                </select>
              </div>
            </div>

            <GroupmeetingsListWrapper />
          </div>
    </>
  )
}

const GroupMeetingModule = () => {
  return (
    <QueryRequestProvider>
        <QueryResponseProvider>
        <GroupMeetingListing/>
        </QueryResponseProvider>
      </QueryRequestProvider>
  )
}

export default GroupMeetingModule
