import React from "react";
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import PropTypes from "prop-types";
import { CometChat } from "@cometchat-pro/chat";

import { CometChatNavBar } from "./CometChatNavBar";
import { CometChatMessages } from "../Messages";
import { CometChatIncomingCall, CometChatIncomingDirectCall } from "../Calls";
import { CometChatContextProvider } from "../../util/CometChatContext";
import * as enums from "../../util/enums.js";
import { theme } from "../../resources/theme";
import Translator from "../../resources/localization/translator";
import { unifiedStyle, unifiedSidebarStyle, unifiedMainStyle } from "./style";

class CometChatUI extends React.Component {
	loggedInUser = null;

	constructor(props) {
		super(props);
		this.state = {
			sidebarview: false,
			activeTab: localStorage.getItem("COMETCHAT_SIDEBAR_VIEW") ? localStorage.getItem("COMETCHAT_SIDEBAR_VIEW") : "SIDEBAR_CHATS",
		};

		this.navBarRef = React.createRef();
		this.contextProviderRef = React.createRef();
	}

	componentDidMount() {
		if (
			this.props.chatWithUser.length === 0 &&
			this.props.chatWithGroup.length === 0
		) {
			this.toggleSideBar();
		}
	}

	navBarAction = (action, type, item) => {
		switch (action) {
			case enums.ACTIONS["ITEM_CLICKED"]:
				this.itemClicked(item, type);
				break;
			case enums.ACTIONS["TOGGLE_SIDEBAR"]:
				this.toggleSideBar();
				break;
			default:
				break;
		}
	};

	itemClicked = (item, type) => {
		this.contextProviderRef.setTypeAndItem(type, item);
		this.toggleSideBar();
	};

	actionHandler = (action, item, count, ...otherProps) => {
		switch (action) {
			case enums.ACTIONS["TOGGLE_SIDEBAR"]:
				this.toggleSideBar();
				break;
			case enums.GROUP_MEMBER_SCOPE_CHANGED:
			case enums.GROUP_MEMBER_KICKED:
			case enums.GROUP_MEMBER_BANNED:
				this.groupUpdated(action, item, count, ...otherProps);
				break;
			default:
				break;
		}
	};

	toggleSideBar = () => {
		const sidebarview = this.state.sidebarview;
		this.setState({ sidebarview: !sidebarview });
	};

	/**
	 If the logged in user is banned, kicked or scope changed, update the chat window accordingly
	 */
	groupUpdated = (key, message, group, options) => {
		switch (key) {
			case enums.GROUP_MEMBER_BANNED:
			case enums.GROUP_MEMBER_KICKED: {
				if (
					this.contextProviderRef.state.type ===
					CometChat.ACTION_TYPE.TYPE_GROUP &&
					this.contextProviderRef.state.item.guid === group.guid &&
					options.user.uid === this.loggedInUser.uid
				) {
					this.contextProviderRef.setItem({});
					this.contextProviderRef.setType("");
				}
				break;
			}
			case enums.GROUP_MEMBER_SCOPE_CHANGED: {
				if (
					this.contextProviderRef.state.type ===
					CometChat.ACTION_TYPE.TYPE_GROUP &&
					this.contextProviderRef.state.item.guid === group.guid &&
					options.user.uid === this.loggedInUser.uid
				) {
					const newObject = Object.assign(
						{},
						this.contextProviderRef.state.item,
						{ scope: options["scope"] }
					);
					this.contextProviderRef.setItem(newObject);
					this.contextProviderRef.setType(CometChat.ACTION_TYPE.TYPE_GROUP);
				}
				break;
			}
			default:
				break;
		}
	};
	

	render() {
		let messageScreen = (
			<CometChatMessages
				theme={this.props.theme}
				lang={this.props.lang}
				_parent='unified'
				actionGenerated={this.actionHandler}
			/>
		);

		return (
			<CometChatContextProvider
				ref={(el) => (this.contextProviderRef = el)}
				user={this.props.chatWithUser}
				group={this.props.chatWithGroup}
				language={this.props.lang}
			>
				{this.state.sidebarview ? <>
					<ul className="nav justify-content-center nav-pills nav-fill d-flex flex-nowrap">
						<li className="nav-item flex-1">
							<a className={`nav-link ${this.state.activeTab == "SIDEBAR_CHATS" ? "active" : ""}`} aria-current="page" href="#"
								onClick={() => {
									this.setState({ activeTab: "SIDEBAR_CHATS"});
									localStorage.setItem("COMETCHAT_SIDEBAR_VIEW","SIDEBAR_CHATS")
								}}><i className="fa-solid fa-comments me-2"></i> Recent Chats</a>
						</li>
						<li className="nav-item">
							<a className={`nav-link ${this.state.activeTab == "SIDEBAR_USERS" ? "active" : ""}`} aria-current="page" href="#"
								onClick={() => {
									this.setState({ activeTab: "SIDEBAR_USERS" });
									localStorage.setItem("COMETCHAT_SIDEBAR_VIEW","SIDEBAR_USERS")
								}}><i className="fa-solid fa-user me-2"></i>Users</a>
						</li>
						<li className="nav-item">
							<a className={`nav-link ${this.state.activeTab == "SIDEBAR_GROUPS" ? "active" : ""}`} href="#"
								onClick={() => {
									this.setState({ activeTab: "SIDEBAR_GROUPS" });
									localStorage.setItem("COMETCHAT_SIDEBAR_VIEW","SIDEBAR_GROUPS")
								}}><i className="fa-solid fa-user-group me-2"></i> Groups</a>
						</li>
					</ul>
				</> :
					<i class="fa fa-arrow-left text-dark position-absolute" aria-hidden="true" style={{fontSize:"20px", left:'15px', top:'28px', zIndex:'2', cursor:'pointer'}} onClick={() => {
						this.props.fetchCometChatAgain()
						this.setState({ sidebarview: true });
					}}></i>
				}

				<div
					css={unifiedStyle(this.props)}
					className='cometchat cometchat--unified'
					dir={Translator.getDirection(this.props.lang)}
					style={{
						height: window.location.pathname == "/chat-list"
							? "calc( 100vh - 90px)" :
							window.location.pathname == "/dashboard" ? "" : "",	
					}}
				>
					{this.state.sidebarview ?
						<div
							css={unifiedSidebarStyle(this.state, this.props)}
							className='unified__sidebar'
							style={{ width: "100%" }}
						// style={{width: "calc( 100vh - 60px)"}}
						>
							<CometChatNavBar
								ref={(el) => (this.navBarRef = el)}
								theme={this.props.theme}
								actionGenerated={this.navBarAction}
								activeTab={this.state.activeTab}
								changeTab={(tab) => {
									localStorage.setItem("COMETCHAT_SIDEBAR_VIEW",tab)
									this.setState({ activeTab: tab });
								}}
							/>
						</div> :
						<div
							css={unifiedMainStyle(this.state, this.props)}
							className='unified__main'
							style={{ width: "100%" }}
						>
							{messageScreen}
						</div>
					}
					<CometChatIncomingCall
						theme={this.props.theme}
						lang={this.props.lang}
						actionGenerated={this.actionHandler}
					/>
					<CometChatIncomingDirectCall
						theme={this.props.theme}
						lang={this.props.lang}
						actionGenerated={this.actionHandler}
					/>
				</div>
			</CometChatContextProvider>
		);
	}
}

// Specifies the default values for props:
CometChatUI.defaultProps = {
	lang: Translator.getDefaultLanguage(),
	theme: theme,
	chatWithUser: "",
	chatWithGroup: "",
};

CometChatUI.propTypes = {
	lang: PropTypes.string,
	theme: PropTypes.object,
	chatWithUser: PropTypes.string,
	chatWithGroup: PropTypes.string,
};

export { CometChatUI };
