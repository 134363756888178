import React, { useEffect, useState } from 'react'
import { Form } from 'react-bootstrap'
import SVG from 'react-inlinesvg';

/* eslint-disable jsx-a11y/anchor-is-valid */
export function FilterDropdown({ filterList2, filterId, setFilterId }: any) {
  const [filterData, setFilterData] = useState<any>("all")

  useEffect(() => {
    setFilterData(filterId)
  }, [filterId])


  return (
    <div
      className='menu menu-sub menu-sub-dropdown w-250px w-md-300px mobile-filter'
      data-kt-menu='true'
    >
      <div className='bg-white'>
        <div className='px-7 py-5 d-flex justify-content-between align-items-center'>
          <div className='fs-5 text-dark fw-bolder'>Filter Post</div>
          <div className='cursor-pointer' data-kt-menu-dismiss='true'>
            <i className='bi bi-x-lg fs-3'></i>
          </div>
        </div>

        <div className='separator border-gray-300'></div>

        <div className='px-0 pt-4  filter-scroll-area'>
          {filterList2.map((x: any) => {
            return (
              <>
                {x.id == 'all' ? (
                  <Form.Check type="radio" id={`filter_post_${x.id}`}>
                    <div className='filter-item-icon px-5'>
                      <Form.Check.Input
                        name='postfilter'
                        type='radio'
                        className='mb-5 cursor-pointer'
                        value={x.id}
                        checked={x.id == filterData}
                        onChange={(e: any) => {
                          setFilterData(x.id)
                        }}
                      />
                      <Form.Check.Label>
                        <SVG
                          src="/media/ilead/post-type/all_post.svg"
                          className='me-3'
                          width={20}
                          height={20} />
                        {x.label}
                      </Form.Check.Label>
                    </div>
                  </Form.Check>
                ) : x.id == 1 ? (
                  <Form.Check type="radio" id={`filter_post_${x.id}`}>
                    <div className='filter-item-icon px-5'>
                      <Form.Check.Input
                        name='postfilter'
                        type='radio'
                        className='mb-5 cursor-pointer'
                        value={x.id}
                        checked={x.id == filterData}
                        onChange={(e: any) => {
                          setFilterData(x.id)
                        }}
                      />
                      <Form.Check.Label>
                        <SVG
                          src="/media/ilead/post-type/announcement.svg"
                          className='me-3'
                          width={20}
                          height={20} />
                        {x.label}
                      </Form.Check.Label>
                    </div>
                  </Form.Check>
                ) : x.id == 3 ? (
                  <Form.Check type="radio" id={`filter_post_${x.id}`}>
                    <div className='filter-item-icon px-5'>
                      <Form.Check.Input
                        name='postfilter'
                        type='radio'
                        className='mb-5 cursor-pointer'
                        value={x.id}
                        checked={x.id == filterData}
                        onChange={(e: any) => {
                          setFilterData(x.id)
                        }}
                      />
                      <Form.Check.Label>
                        <SVG
                          src="/media/ilead/post-type/ask_help.svg"
                          className='me-3'
                          width={20}
                          height={20} />
                        {x.label}
                      </Form.Check.Label>
                    </div>
                  </Form.Check>
                ) : x.id == 12 ? (
                  <Form.Check type="radio" id={`filter_post_${x.id}`}>
                    <div className='filter-item-icon px-5'>
                      <Form.Check.Input
                        name='postfilter'
                        type='radio'
                        className='mb-5 cursor-pointer'
                        value={x.id}
                        checked={x.id == filterData}
                        onChange={(e: any) => {
                          setFilterData(x.id)
                        }}
                      />
                      <Form.Check.Label>
                        <SVG
                          src="/media/ilead/post-type/share_idea.svg"
                          className='me-3'
                          width={20}
                          height={20} />
                        {x.label}
                      </Form.Check.Label>
                    </div>
                  </Form.Check>
                ) : x.id == 7 ? (
                  <Form.Check type="radio" id={`filter_post_${x.id}`}>
                    <div className='filter-item-icon px-5'>
                      <Form.Check.Input
                        name='postfilter'
                        type='radio'
                        className='mb-5 cursor-pointer'
                        value={x.id}
                        checked={x.id == filterData}
                        onChange={(e: any) => {
                          setFilterData(x.id)
                        }}
                      />
                      <Form.Check.Label>
                        <SVG
                          src="/media/ilead/post-type/thank_you.svg"
                          className='me-3'
                          width={20}
                          height={20} />
                        {x.label}
                      </Form.Check.Label>
                    </div>
                  </Form.Check>
                ) : x.id == 20 ? (
                  <Form.Check type="radio" id={`filter_post_${x.id}`}>
                    <div className='filter-item-icon px-5'>
                      <Form.Check.Input
                        name='postfilter'
                        type='radio'
                        className='mb-5 cursor-pointer'
                        value={x.id}
                        checked={x.id == filterData}
                        onChange={(e: any) => {
                          setFilterData(x.id)
                        }}
                      />
                      <Form.Check.Label>
                        <SVG
                          src="/media/ilead/post-type/business_referral.svg"
                          className='me-3'
                          width={20}
                          height={20} />
                        {x.label}
                      </Form.Check.Label>
                    </div>
                  </Form.Check>
                ) : x.id == 14 ? (
                  <Form.Check type="radio" id={`filter_post_${x.id}`}>
                    <div className='filter-item-icon px-5'>
                      <Form.Check.Input
                        name='postfilter'
                        type='radio'
                        className='mb-5 cursor-pointer'
                        value={x.id}
                        checked={x.id == filterData}
                        onChange={(e: any) => {
                          setFilterData(x.id)
                        }}
                      />
                      <Form.Check.Label>
                        <SVG
                          src="/media/ilead/post-type/tracksheet.svg"
                          className='me-3'
                          width={20}
                          height={20} />
                        {x.label}
                      </Form.Check.Label>
                    </div>
                  </Form.Check>
                ) : x.id == 11 ? (
                  <Form.Check type="radio" id={`filter_post_${x.id}`}>
                    <div className='filter-item-icon px-5'>
                      <Form.Check.Input
                        name='postfilter'
                        type='radio'
                        className='mb-5 cursor-pointer'
                        value={x.id}
                        checked={x.id == filterData}
                        onChange={(e: any) => {
                          setFilterData(x.id)
                        }}
                      />
                      <Form.Check.Label>
                        <SVG
                          src="/media/ilead/post-type/visitor_invitation.svg"
                          className='me-3'
                          width={20}
                          height={20} />
                        {x.label}
                      </Form.Check.Label>
                    </div>
                  </Form.Check>
                ) : x.id == 8 ? (
                  <Form.Check type="radio" id={`filter_post_${x.id}`}>
                    <div className='filter-item-icon px-5'>
                      <Form.Check.Input
                        name='postfilter'
                        type='radio'
                        className='mb-5 cursor-pointer'
                        value={x.id}
                        checked={x.id == filterData}
                        onChange={(e: any) => {
                          setFilterData(x.id)
                        }}
                      />
                      <Form.Check.Label>
                        <SVG
                          src="/media/ilead/post-type/tyca_presentation.svg"
                          className='me-3'
                          width={20}
                          height={20} />
                        {x.label}
                      </Form.Check.Label>
                    </div>
                  </Form.Check>
                ) : x.id == 10 ? (
                  <Form.Check type="radio" id={`filter_post_${x.id}`}>
                    <div className='filter-item-icon px-5'>
                      <Form.Check.Input
                        name='postfilter'
                        type='radio'
                        className='mb-5 cursor-pointer'
                        value={x.id}
                        checked={x.id == filterData}
                        onChange={(e: any) => {
                          setFilterData(x.id)
                        }}
                      />
                      <Form.Check.Label>
                        <SVG
                          src="/media/ilead/post-type/group_meeting.svg"
                          className='me-3'
                          width={20}
                          height={20} />
                        {x.label}
                      </Form.Check.Label>
                    </div>
                  </Form.Check>
                ) : x.id == 16 ? (
                  <Form.Check type="radio" id={`filter_post_${x.id}`}>
                    <div className='filter-item-icon px-5'>
                      <Form.Check.Input
                        name='postfilter'
                        type='radio'
                        className='mb-5 cursor-pointer'
                        value={x.id}
                        checked={x.id == filterData}
                        onChange={(e: any) => {
                          setFilterData(x.id)
                        }}
                      />
                      <Form.Check.Label>
                        <SVG
                          src="/media/ilead/post-type/session_flow.svg"
                          className='me-3'
                          width={20}
                          height={20} />
                        {x.label}
                      </Form.Check.Label>
                    </div>
                  </Form.Check>
                ) : x.id == 17 ? (
                  <Form.Check type="radio" id={`filter_post_${x.id}`}>
                    <div className='filter-item-icon px-5'>
                      <Form.Check.Input
                        name='postfilter'
                        type='radio'
                        className='mb-5 cursor-pointer'
                        value={x.id}
                        checked={x.id == filterData}
                        onChange={(e: any) => {
                          setFilterData(x.id)
                        }}
                      />
                      <Form.Check.Label>
                        <SVG
                          src="/media/ilead/post-type/tyca-questions.svg"
                          className='me-3'
                          width={20}
                          height={20} />
                        {x.label}
                      </Form.Check.Label>
                    </div>
                  </Form.Check>
                ) : x.id == 0 ? (
                  <Form.Check type="radio" id={`filter_post_${x.id}`}>
                    <div className='filter-item-icon px-5'>
                      <Form.Check.Input
                        name='postfilter'
                        type='radio'
                        className='mb-5 cursor-pointer'
                        value={x.id}
                        checked={x.id == filterData}
                        onChange={(e: any) => {
                          setFilterData(x.id)
                        }}
                      />
                      <Form.Check.Label>
                        <SVG
                          src="/media/ilead/post-type/bookmark.svg"
                          className='me-3'
                          width={20}
                          height={20} />
                        {x.label}
                      </Form.Check.Label>
                    </div>
                  </Form.Check>
                ) : x.id == 21 ? (
                  <Form.Check type="radio" id={`filter_post_${x.id}`}>
                    <div className='filter-item-icon px-5'>
                      <Form.Check.Input
                        name='postfilter'
                        type='radio'
                        className='mb-5 cursor-pointer'
                        value={x.id}
                        checked={x.id == filterData}
                        onChange={(e: any) => {
                          setFilterData(x.id)
                        }}
                      />
                      <Form.Check.Label>
                        <SVG
                          src="/media/ilead/post-type/business_referral.svg"
                          className='me-3'
                          width={20}
                          height={20} />
                        {x.label}
                      </Form.Check.Label>
                    </div>
                  </Form.Check>
                ) : x.id == 22 ? (
                  <Form.Check type="radio" id={`filter_post_${x.id}`}>
                    <div className='filter-item-icon px-5'>
                      <Form.Check.Input
                        name='postfilter'
                        type='radio'
                        className='mb-5 cursor-pointer'
                        value={x.id}
                        checked={x.id == filterData}
                        onChange={(e: any) => {
                          setFilterData(x.id)
                        }}
                      />
                      <Form.Check.Label>
                        <SVG
                          src="/media/ilead/post-type/business_generated.svg"
                          className='me-3'
                          width={20}
                          height={20} />
                        {x.label}
                      </Form.Check.Label>
                    </div>
                  </Form.Check>
                ) : x.id == 23 ? (
                  <Form.Check type="radio" id={`filter_post_${x.id}`}>
                    <div className='filter-item-icon px-5'>
                      <Form.Check.Input
                        name='postfilter'
                        type='radio'
                        className='mb-5 cursor-pointer'
                        value={x.id}
                        checked={x.id == filterData}
                        onChange={(e: any) => {
                          setFilterData(x.id)
                        }}
                      />
                      <Form.Check.Label>
                        <SVG
                          src="/media/ilead/post-type/mr_tracking.svg"
                          className='me-3'
                          width={20}
                          height={20} />
                        {x.label}
                      </Form.Check.Label>
                    </div>
                  </Form.Check>
                ) : (
                  <></>
                )}
              </>
            )
          })
          }
        </div>
        <div className='filter-button py-5 px-7'>
          <div className='d-flex justify-content-end'>
            <button
              className='btn btn-sm btn-light btn-active-light-primary me-2 w-100'
              data-kt-menu-dismiss='true'
              onClick={() => {
                setFilterId("all")
                setFilterData("all")
              }}
            >
              CLEAR
            </button>
            <button onClick={() => { setFilterId(filterData) }} className='btn btn-primary w-100' data-kt-menu-dismiss='true'>
              {/* {!loading && 'FILTER'} */}
              Filter
            </button>
          </div>
        </div>
      </div>
    </div >
  )
}
