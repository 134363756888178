import { useMemo } from 'react'
import { useTable, ColumnInstance, Row } from 'react-table'
import { CustomHeaderColumn } from './columns/CustomHeaderColumn'
import { CustomRow } from './columns/CustomRow'
import { useQueryResponseData, useQueryResponseLoading } from '../core/QueryResponseProvider'
import { monthlyreportsColumns } from './columns/_columns'
import { Monthlyreport } from '../core/_models'
import { MonthlyreportsListLoading } from '../components/loading/MonthlyreportsListLoading'
import { MonthlyreportsListPagination } from '../components/pagination/MonthlyreportsListPagination'
import { KTCardBody, defaultMonthlyreports } from '../../../../../_metronic/helpers'
import { checkDisplayValue } from '../../../../../utils'
// import {KTCardBody} from '../../../../../../_metronic/helpers'

const MonthlyreportsTable = () => {
  const monthlyreports = useQueryResponseData()
  //  const monthlyreports = defaultMonthlyreports
  const isLoading = useQueryResponseLoading()
  const data = useMemo(() => monthlyreports, [monthlyreports])
  const columns = useMemo(() => monthlyreportsColumns, [])
  const { getTableProps, getTableBodyProps, headers, rows, prepareRow } = useTable({
    columns,
    data,
  })

  return (
    <KTCardBody className='p-0'>
      <div className='table-responsive mb-8 data-table-report'>
        <table
          id='kt_table_monthlyreports'
          className='table align-middle table-row-dashed fs-6 gy-5 dataTable table-striped  no-footer'
          {...getTableProps()}
        >
          <thead>
            <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0 border-bottom border-gray-100'>
              {headers.map((column: ColumnInstance<Monthlyreport>) => (
                <CustomHeaderColumn key={column.id} column={column} />
              ))}
            </tr>
          </thead>

          <tbody className='text-gray-600 fw-bold' {...getTableBodyProps()}>
            {rows.length > 0 ? (
              rows.map((row: Row<Monthlyreport>, i) => {
                prepareRow(row)
                return <CustomRow row={row} key={`row-${i}-${row.id}`} />
              })
            ) : (
              <tr>
                <td colSpan={14}>
                  <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                    No matching records found
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>

        <table
          id='copy_kt_table_monthlyreports'
          className='table align-middle table-row-dashed fs-6 gy-5 dataTable table-striped  no-footer'
          {...getTableProps()}
          style={{ display: 'none' }}
        >
          <thead>
            <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0 border-bottom border-gray-100'>
              {headers.map((column: ColumnInstance<Monthlyreport>) => {
                if (column.id == 'actions') {
                  return <></>
                }
                return < CustomHeaderColumn key={column.id} column={column} />
              })}
            </tr>
          </thead>

          <tbody className='text-gray-600 fw-bold' {...getTableBodyProps()}>
            {rows.length > 0 ? (
              rows.map((row: Row<any>, i) => {
                prepareRow(row)
                return <tr key={i}>
                  <td>{checkDisplayValue(row?.original?.user_name)}</td>
                  <td>{checkDisplayValue(row?.original?.present_count)}</td>
                  <td>{checkDisplayValue(row?.original?.vi)}</td>
                  <td>{checkDisplayValue(row?.original?.va)}</td>
                  <td>{checkDisplayValue(row?.original?.vj)}</td>
                  <td>{checkDisplayValue(row?.original?.tg)}</td>
                  <td>{checkDisplayValue(row?.original?.tr)}</td>
                  <td>{checkDisplayValue(row?.original?.gma)}</td>
                  <td>{checkDisplayValue(row?.original?.tracksheet_count)}</td>
                  <td>{checkDisplayValue(row?.original?.fg)}</td>
                  <td>{checkDisplayValue(row?.original?.help)}</td>
                  <td>{checkDisplayValue(row?.original?.icaniwill_count)}</td>
                  <td>{checkDisplayValue(row?.original?.mr_tracking)}</td>
                  <td>{checkDisplayValue(row?.original?.total)}</td>
                </tr>
              })
            ) : (
              <tr>
                <td colSpan={14}>
                  <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                    No matching records found
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>

      </div>
      <MonthlyreportsListPagination />
      {isLoading && <MonthlyreportsListLoading />}
    </KTCardBody>
  )
}

export { MonthlyreportsTable }
