import React, { useState, useEffect } from 'react'
import { toAbsoluteUrl } from '../../../_metronic/helpers'
import { Image } from 'react-bootstrap'
import axios from 'axios'
import { apiUrl } from '../../../apiurl'
import { checkDisplayValue, getFileUrl } from '../../../utils'
import { Link } from 'react-router-dom'
import ReactAudioPlayer from 'react-audio-player'

const API_URL = apiUrl
const GET_LIBRARY_URL = `${API_URL}/libraryposts`

const Library: React.FC = () => {
  const [data, setData] = useState<any>([])
  const [isLoading, setIsLoading] = useState(true)
  const [postId, setPostId] = useState<any>('')
  const [originalData, setOriginalData] = useState<any>([])
  const [postList, setPostList] = useState<any>([])
  const [hasMore, setHasMore] = useState(true);
  const [isExpanded, setIsExpanded] = useState(false);
  const toggleExpansion = () => {
    setIsExpanded(!isExpanded);
  };

  useEffect(() => {
    fetchData()
    fetchPost()
  }, [])

  useEffect(() => {
    // filterData()
    fetchData()
  }, [postId])

  const fetchData = async () => {
    try {
      let apiUrls = `${GET_LIBRARY_URL}?filter[include]=librarypostmedia&filter[order]=id DESC&filter[where][isdelete]=0&filter[where][id]=${postId}`

      // if (postId) {
      //   apiUrls += `&filter[where][id]=${postId}`
      // }

      const res = await axios.get(apiUrls)
      setData(res?.data ?? [])
      // setOriginalData(res?.data ?? []) // Store the original fetched data
      // setIsLoading(false)
    } catch (error) {
      console.error('Error -->:', error)
      setIsLoading(false)
    }
  }

  const fetchPost = async () => {
    try {
      const res = await axios.get(`${GET_LIBRARY_URL}?filter[include]=librarypostmedia&filter[order]=id DESC&filter[where][isdelete]=0`)

      setPostList(res?.data ?? [])
      setIsLoading(false)
    } catch (error) {
      console.error('Error -->:', error)
      setIsLoading(false)
    }
  }

  const filterData = () => {
    const filteredData = originalData.filter((item: any) => {
      const matchesPost = postId === '' || item.id.toString() === postId
      return matchesPost
    })

    setData(filteredData) // Update the filtered data state
  }

  const handleButtonClick = async (pdf: any) => {
    if (pdf) {
      let url = await getFileUrl(`${API_URL}/containers/topicimages/download/${pdf}`,`${API_URL}/containers/public/download/${pdf}` as any) as any
      window.open(url, '_blank')
    }
  }

  const styles = {
    borderRadius: '0.475rem',
    boxShadow: '0 0 50px 0 rgb(82 63 105 / 15%)',
    backgroundColor: '#fff',
    color: '#7e8299',
    fontWeight: '500',
    margin: '0',
    width: 'auto',
    padding: '1rem 2rem',
    top: 'calc(50% - 2rem)',
    left: 'calc(50% - 4rem)',
  }

  if (isLoading) {
    return <div style={{ ...styles, position: 'absolute', textAlign: 'center' }}>Processing...</div>
  }

  return (
    <>
      <div className='d-flex flex-column flex-md-row justify-content-between align-items-center border-bottom border-secondary pb-5'>
        <h1 className='inv-hst mb-3 mb-md-0'>Library</h1>
        <div className='d-flex top__filter'>
          <div className='form-group floating-labels'>
            <select
              className='form-select border-top-0 border-left-0 border-right-0 border-bottom rounded-0'
              aria-label='.form-select-sm example'
              value={postId}
              onChange={(e) => setPostId(e.target.value)}
              style={{ minWidth: '280px' }}
            >
              <option value=''> Select Category </option>
              {postList?.map((i: any) => (
                <>
                  <option value={i?.id}> {i?.title} </option>
                </>
              ))}
            </select>
          </div>
        </div>
      </div >
      {
        data?.map((item: any) => (
          <div className='row library-row mobile-padding'>
            <h3 className='mb-5'>{checkDisplayValue(item?.title)} </h3>
            {
            item?.librarypostmedia.length >0 ?  
            (
             item?.librarypostmedia.map((i: any) => (
              <>
                {item?.description ? (
                  <div className='col-md-5'>
                    <div className='card ilead-card library__box border border-secondary h-100'>
                      <div className='card-body'>
                        <p
                          className='ileade-lyrics text-dark'
                          dangerouslySetInnerHTML={{ __html: item?.description }}
                        ></p>
                      </div>
                    </div>
                  </div>
                ) : (
                  ''
                )}
                {i.type == 3 ? (
                  <div className='col-md-6 col-lg-6 col-xl-4 mb-3'>
                    <div className='card ilead-card library__box border border-secondary h-100'>
                      <div className='card-body p-3'>
                        <iframe src={i?.file ? i?.file : '-'} title='video' className='w-100' height='280px' style={{ borderRadius: '0.475rem 0.475rem 0 0' }}></iframe>
                        <h5 className='mt-3 text-center px-3 text-dark twoline-truncate' style={{ lineHeight: '1.5' }}>{checkDisplayValue(i?.description)} </h5>
                      </div>
                    </div>
                  </div>
                ) : (
                  <></>
                )}
                {i.type == 2 ? (
                  <div className='col-md-7 mb-3 library'>
                    <div className='card ilead-card library__box border border-secondary h-100'>
                      <div className='card-body p-3'>
                        <div className='song-div position-relative overflow-hidden d-flex align-items-center'>
                          <div className='song-cover position-absolute top-0 start-0'>
                            <Image src={toAbsoluteUrl('./cover.png')} alt='' />
                          </div>
                          <div className='song-audio w-100'>
                            <ReactAudioPlayer
                              src={`${API_URL}/containers/public/download/${i?.file}`}
                              autoPlay={false}
                              controls
                              className='w-100'
                            />
                            {i?.description ? <h5 className='text-dark'> {checkDisplayValue(i?.description)} </h5> : ''}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  ''
                )}
                {i.type == 1 ? (
                  <div className='col-md-5 col-lg-4 col-xl-3 mb-3'>
                    <div className='card ilead-card library__box border border-secondary h-100'>
                      <div className='card-body p-3'>
                        <Link
                          to='#'
                          onClick={() => handleButtonClick(i?.file)}
                          className='btn btn-sm fw-bold p-0'
                          data-bs-toggle='modal'
                          data-bs-target='#kt_modal_create_app'
                        >
                          <Image src={toAbsoluteUrl('./media/svg/files/pdf2.png')} alt='' className='w-100 mb-5' style={{ borderRadius: '0.475rem 0.475rem 0 0' }} />
                          {i?.description ? <h5 className='text-dark'> {checkDisplayValue(i?.description)} </h5> : ''}
                        </Link>
                      </div>
                    </div>
                  </div>
                ) : (
                  ''
                )}
              </>
            )))
           : (<></>)}
          </div>
        ))
      }
      {/* {
        data?.map((item: any) => (
          <div className='card p-8 mt-3 library'>
            <h4> {checkDisplayValue(item?.title)} </h4>

            <div className='row flex-wrap bd-highlight mb-3'>
              {item?.librarypostmedia.map((i: any) => (
                <>
                  {item?.description ? (
                    <div className='col-md-5 col-sm-12 col-xs-12'>
                      <p
                        className='ileade-lyrics'
                        dangerouslySetInnerHTML={{ __html: item?.description }}
                      ></p>
                    </div>
                  ) : (
                    ''
                  )}
                  {i.type == 3 ? (
                    <div className='col-md-6 col-sm-12 col-xs-12'>
                      <div className='m-3 bg-dark'>
                        <iframe src={i?.file ? i?.file : '-'} title='video'></iframe>
                        <span className='fw-semibold'> {checkDisplayValue(i?.description)} </span>
                      </div>
                    </div>
                  ) : (
                    ''
                  )}
                  {i.type == 2 ? (
                    <div className='col-md-7 col-sm-12 col-xs-12'>
                      <div className='row song-div'>
                        <div className='col-3 p-0'>
                          <Image src={toAbsoluteUrl('./cover.png')} alt='' />
                        </div>
                        <div className='col-9 p-0'>
                          <ReactAudioPlayer
                            src={`${API_URL}/containers/public/download/${i?.file}`}
                            autoPlay={false}
                            controls
                          />
                          {i?.description ? <h5> {checkDisplayValue(i?.description)} </h5> : ''}
                        </div>
                      </div>
                    </div>
                  ) : (
                    ''
                  )}
                  {i.type == 1 ? (
                    <div className='col-md-4 col-sm-12 col-xs-12'>
                      <div className='m-3'>
                        <Link
                          to='#'
                          onClick={() => handleButtonClick(i?.file)}
                          className='btn btn-sm fw-bold badge-light-primary mt-2 p-0'
                          data-bs-toggle='modal'
                          data-bs-target='#kt_modal_create_app'
                        >
                          <Image src={toAbsoluteUrl('./media/svg/files/pdf.png')} alt='' className='w-100' />
                        </Link>

                        {i?.description ? <h5> {checkDisplayValue(i?.description)} </h5> : ''}
                      </div>
                    </div>
                  ) : (
                    ''
                  )}
                </>
              ))}
            </div>
          </div>
        ))
      } */}
    </>
  )
}

export default Library
