/* eslint-disable react/jsx-no-target-blank */
import {useEffect} from 'react'
import {ILayout, useLayout} from '../../core'
import { ActivityPointsModal } from '../../../../app/pages/ActivityPointsModal'


const ActivityPoints = () => {
  
  return (
    <>
      <ActivityPointsModal></ActivityPointsModal>
      
    </>
  )
}


export {ActivityPoints}
