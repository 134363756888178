import { useEffect, useMemo, useState } from 'react'
import { useTable, ColumnInstance, Row } from 'react-table'
import { CustomHeaderColumn } from './columns/CustomHeaderColumn'
import { CustomRow } from './columns/CustomRow'
import { useQueryResponseData, useQueryResponseLoading } from '../core/QueryResponseProvider'
import { sessionattendancesColumns } from './columns/_columns'
import { Sessionattendance } from '../core/_models'
import { SessionattendancesListLoading } from '../components/loading/SessionattendancesListLoading'
import { SessionattendancesListPagination } from '../components/pagination/SessionattendancesListPagination'
import { KTCardBody, defaultSessionattendances } from '../../../../../_metronic/helpers'
import { useQueryRequest } from '../core/QueryRequestProvider'
import { getChaptersList } from '../core/_requests'
import { checkDisplayValue } from '../../../../../utils'
import moment from 'moment'
// import {KTCardBody} from '../../../../../../_metronic/helpers'

const CopySessionattendancesTable = () => {
    const sessionattendances = useQueryResponseData()
    const isLoading = useQueryResponseLoading()
    const data = useMemo(() => sessionattendances, [sessionattendances])
    const columns = useMemo(() => sessionattendancesColumns, [])
    const { getTableProps, getTableBodyProps, headers, rows, prepareRow } = useTable({
        columns,
        data,
    })

    const getAttendance = (value:any) => {
        if(value == 0){
            return "Absent"
        }
        else if(value == 1){
            return "Present"
        }
        else if(value == 2){
            return "Substitute Present"
        }
        else{
            return "-"
        }
    }

    return (
        <table
            id='copy_kt_table_sessionattendances'
            className='table align-middle table-row-dashed fs-6 gy-5 dataTable table-striped no-footer m-5 p-5'
            {...getTableProps()}
            style={{ display: 'none' }}
        >
            <thead>
                <tr 
                className='text-center text-muted fw-bolder fs-7 text-uppercase gs-0 border-bottom border-gray-100'
                >
                    {headers.map((column: ColumnInstance<Sessionattendance>) => (
                        <CustomHeaderColumn key={column.id} column={column} />
                    ))}
                </tr>
            </thead>
            <tbody className='text-gray-600 fw-bold text-center' {...getTableBodyProps()}>
                {data.length > 0 ? (
                    data.map((row:any, i:any) => {
                        return <tr key={i}>
                            <td>
                                {checkDisplayValue(row?.chapter_name)}
                            </td>
                            <td>
                                {checkDisplayValue(row?.leader_name)}
                            </td>
                            <td>
                                {row?.event_date ? moment(row?.event_date).format('DD-MM-YYYY') : checkDisplayValue(row?.event_date)}
                            </td>
                            <td>
                                {getAttendance(row?.isattended)}
                            </td>
                            <td>
                                {row?.scanned_at ? moment(row?.scanned_at,"HH:mm:ss").format('hh:mm:ss A') : checkDisplayValue(row?.scanned_at)}
                            </td>
                        </tr>
                    })
                ) : (
                    <tr>
                        <td colSpan={7}>
                            <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                                No matching records found
                            </div>
                        </td>
                    </tr>
                )}
            </tbody>
        </table>

    )
}

export { CopySessionattendancesTable }
