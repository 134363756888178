import { ID, Response } from "../../../../../_metronic/helpers"

export type Vertical = {
  id?: ID
  name?: string
  avatar?: string
  email?: string
  position?: string
  role?: string
  title?:string
  notification_type?:string
 
}

export type VerticalsQueryResponse = Response<Array<any>>

export const initialVertical: any = {
  avatar: 'avatars/300-6.jpg',
  position: 'Art Director',
  role: 'Administrator',
  name: '',
  email: '',

}
