import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { Button, Form, InputGroup } from 'react-bootstrap'
import { SearchLeaderListing } from './SearchLeaderListing'
import {
  addInvitationHistories,
  listingForChapter,
  listingForEventDate,
  sendSmsAndNotification,
} from '../_requests'
import moment from 'moment'
import { formatTargetDate } from './formatTargetDate'
import { UserContext } from '../../../../../../contextStore'
import * as Yup from 'yup'
import { useFormik } from 'formik'

export function InviteVisitor() {
  const [searchLeaderListing, setsearchLeaderListing] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const [chapterDetail, setChapterDetail] = useState<any>([])
  const [chapterId, setChapterId] = useState('')
  const { token, userDetail } = useContext(UserContext)
  const { setMessage, dashBoardReload } = useContext(UserContext)
  const [eventDetailId, setEventDetailId] = useState('')
  const [eventDetail, setEventDetail] = useState([])
  const [inviteHistories, setInviteHistories] = useState<any>({})
  const closeButton = useRef<any>()
  const currentDate = moment().format('YYYY-MM-DD')
  const [errorMessage, setErrorMessage] = useState(false)
  const [errorchapterMessage, setErrorchapterMessage] = useState(false)
  
  useEffect(() => {
      fetchData()
  }, [chapterId, currentDate])

  const fetchData = async () => {
    try {
      const chapterDetail = await listingForChapter()
      setChapterDetail(() => chapterDetail ?? [])
      setIsLoading(false)
    } catch (error) {
      console.error('Error -->:', error)
      setIsLoading(false)
    }
    try {
      const eventListing = await listingForEventDate(chapterId, currentDate)
      setEventDetail(() => eventListing ?? [])
      setIsLoading(false)
    } catch (error) {
      console.error('Error -->:', error)
      setIsLoading(false)
    }
  }

  const settings = {
    multiple: false,
    address: false,
    email: false,
    icon: false,
    name: true,
    tel: true,
  }

  const handlePickClick = useCallback(async () => {
    try {
      if ('contacts' in navigator && 'select' in navigator.contacts) {
        const selectedContacts = await navigator.contacts.select(['name', 'tel'], {
          multiple: settings.multiple,
        })

        if (selectedContacts.length > 0) {
          formik.setFieldValue('name', selectedContacts[0].name)
          formik.setFieldValue('cellnumber', selectedContacts[0].tel[0])
        }
      } else {
        setMessage('error', "Sorry, Your device doesn't support Contact Picker.")
      }
    } catch (error: any) {
      setMessage('error', error?.message ? error?.message : 'Something Went Wrong')
    }
  }, [settings])

  const initialValue = {
    name: '',
    cellnumber: '',
    email: '',
    companyname: '',
  }
  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    cellnumber: Yup.string()
      .required('Cell Number is required')
      .matches(/^[6789]\d{9}$/, 'Invalid phone number'),
    email: Yup.string().email(),
    companyname: Yup.string(),
  })

  const formik = useFormik({
    initialValues: initialValue,
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm }) => {
      try {
        if (!chapterId) {
          setErrorMessage(true)
          return
        }
        if (!eventDetailId) {
          setErrorchapterMessage(true)
          return
        }
        const postManageGoal = {
          chapter_id: chapterId,
          event_id: eventDetailId,
          name: values.name,
          cellnumber: values.cellnumber,
          email: values.email,
          comapanyname: values.companyname,
        }

        await addInvitationHistories(postManageGoal)
          .then((res: any) => {
            if (res) {
              setMessage('success', 'Invitation Sent Successfully')
              setErrorMessage(false)
              setErrorchapterMessage(false)
              setEventDetailId('')
              setChapterId('')
              setIsLoading(false)
              resetForm()
              sendSmsAndNotification({
                thankyouhistoryid: null,
                user_topic_id: res?.usertopic?.id,
                userid: userDetail?.id ?? '',
                accesstoken: localStorage.getItem('authToken'),
              })
              dashBoardReload()

            }
          })
          .catch((error: any) => {
            if (error?.response?.data?.error?.message) {
              setMessage('error', error?.response?.data?.error?.message)
            } else {
              setMessage('error', 'Something Went Wrong')
            }
            setIsLoading(false)
          })
      } catch (error) {
        console.error('Error -->:', error)
        setMessage('error', 'Something went wrong')
        setIsLoading(false)
      }
    },
  })

  return (
    <div
      className='modal fade ilead-default-modal'
      id='InviteVisitorModal'
      aria-hidden='true'
      aria-labelledby='InviteVisitorModallLabel'
    >
      <div className='modal-dialog modal-dialog modal-dialog-scrollable modal-fullscreen-md-down modal-md'>
        <div className='modal-content'>
          <div className='modal-header py-5'>
            <h3 className='modal-title fs-3 fw-bold' id='InviteVisitorModallLabelToggleLabel'>
              Invite Visitor
            </h3>
            <button
              type='button'
              className='btn-close text-white'
              data-bs-target='#write_post_modal'
              data-bs-toggle='modal'
              data-bs-dismiss='modal'
              onClick={() => {
                setChapterId('')
                formik.setFieldValue('name', '')
                formik.setFieldValue('cellnumber', '')
                formik.setFieldValue('email', '')
                formik.setFieldValue('companyname', '')
                setEventDetailId('')
                formik.resetForm()
                setErrorMessage(false)
                setErrorchapterMessage(false)
              }}
              ref={closeButton}
            ></button>
          </div>
          <div className='modal-body pt-7 write-post-wrapper'>
            {searchLeaderListing ? (
              <>
                <form className='mb-7' onSubmit={formik.handleSubmit}>
                  <Form.Label className='text-gray-700 mb-7'>
                    Use Below Form To Send An Invitation To Visitor.
                  </Form.Label>
                  <Form.Group className='mb-4'>
                    <Form.Select
                      aria-label='select chapter'
                      className='ilead-input'
                      value={chapterId}
                      onChange={(e) => {
                        setChapterId(e.target.value)
                        setEventDetailId('')
                      }}
                    >
                      <option value='0'>Select Chapter</option>

                      {chapterDetail.map((item: any) => (
                        <option key={item.id} value={item.id}>
                          {item.name}
                        </option>
                      ))}
                    </Form.Select>
                    {errorMessage && !chapterId ? (
                      <div className='fv-plugins-message-container text-danger'>
                        <span role='alert'>This field is required</span>
                      </div>
                    ) : (
                      ''
                    )}
                  </Form.Group>
                  <Form.Group className='mb-4'>
                    <Form.Select
                      aria-label='select event'
                      className='ilead-input'
                      value={eventDetailId}
                      onChange={(e) => {
                        setEventDetailId(e.target.value)
                      }}
                    >
                      <option>Select Event Date</option>
                      {eventDetail.map((item: any) => (
                        <option key={item.id} value={item.id}>
                          {formatTargetDate(item.event_date)}
                        </option>
                      ))}
                    </Form.Select>
                    {errorchapterMessage && !eventDetailId ? (
                      <div className='fv-plugins-message-container text-danger'>
                        <span role='alert'>This field is required</span>
                      </div>
                    ) : (
                      ''
                    )}
                  </Form.Group>
                  <Form.Group className='mb-4'>
                    <InputGroup className='mb-2'>
                      <Form.Control
                        placeholder='Name'
                        aria-label='name'
                        id='name'
                        maxLength={30}
                        className='border-right-0 ilead-input'
                        onChange={(e: any) => {
                          formik.setFieldValue('name', e.target.value)
                        }}
                        value={formik?.values?.name}
                      />

                      <InputGroup.Text
                        id='name'
                        className='bg-white ilead-input-group'
                        onClick={handlePickClick}
                      >
                        <i className='fa-solid fa-address-book' style={{ fontSize: '18px' }}></i>
                      </InputGroup.Text>
                    </InputGroup>
                    {formik?.touched?.name && formik?.errors?.name && (
                      <div className='fv-plugins-message-container text-danger'>
                        <span role='alert'>{formik?.errors?.name as any}</span>
                      </div>
                    )}
                  </Form.Group>
                  <Form.Group className='mb-4'>
                    <Form.Control
                      type='text'
                      id='mobileNumber'
                      className='ilead-input mb-2'
                      placeholder='Mobile Number'
                      maxLength={10}
                      onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                        const key = e.key
                        const isNumeric = /^[0-9]$/.test(key)
                        const isSpecialKey =
                          key === 'Backspace' ||
                          key === 'Delete' ||
                          key === 'ArrowLeft' ||
                          key === 'ArrowRight' ||
                          key === 'ArrowUp' ||
                          key === 'ArrowDown' ||
                          (e.ctrlKey && key === 'c') ||
                          (e.ctrlKey && key === 'v') ||
                          (e.ctrlKey && key === 'x') ||
                          (e.ctrlKey && key === 'a')
                        if (!isNumeric && !isSpecialKey) {
                          e.preventDefault()
                        }
                      }}
                      onChange={(e: any) => {
                        formik.setFieldValue('cellnumber', e.target.value)
                      }}
                      value={formik?.values?.cellnumber}
                    />
                    {formik?.touched?.cellnumber && formik?.errors?.cellnumber && (
                      <div className='fv-plugins-message-container text-danger'>
                        <span role='alert'>{formik?.errors?.cellnumber as any}</span>
                      </div>
                    )}
                  </Form.Group>
                  <Form.Group className='mb-4'>
                    <Form.Control
                      type='email'
                      id='email'
                      className='ilead-input'
                      maxLength={30}
                      placeholder='Email'
                      onChange={(e: any) => {
                        formik.setFieldValue('email', e.target.value)
                      }}
                      value={formik?.values?.email}
                    />
                    {formik?.touched?.email && formik?.errors?.email && (
                      <div className='fv-plugins-message-container text-danger'>
                        <span role='alert'>{formik?.errors?.email as any}</span>
                      </div>
                    )}
                  </Form.Group>
                  <Form.Group className='mb-4'>
                    <Form.Control
                      type='text'
                      id='companyName'
                      className='ilead-input'
                      placeholder='Company Name'
                      maxLength={30}
                      onChange={(e: any) => {
                        formik.setFieldValue('companyname', e.target.value)
                      }}
                      value={formik?.values?.companyname}
                    />
                    {formik?.touched?.companyname && formik?.errors?.companyname && (
                      <div className='fv-plugins-message-container text-danger'>
                        <span role='alert'>{formik?.errors?.companyname as any}</span>
                      </div>
                    )}
                  </Form.Group>
                  <Form.Group className='mt-5 d-flex'>
                    <Button
                      variant='primary'
                      type='submit'
                      className='w-100'
                      data-bs-toggle={formik.isValid ? 'modal' : undefined}
                      data-bs-dismiss={formik.isValid ? 'modal' : undefined}
                      disabled={formik.isSubmitting || !(chapterId && eventDetailId) || !formik.values.name || !formik.values.cellnumber || !formik.values.companyname || !formik.values.email}
                      onClick={() => {
                        if (!chapterId) {
                          setErrorMessage(true)
                        }
                        if (!eventDetailId) {
                          setErrorchapterMessage(true)
                        }
                      }}
                    >
                      Send Invitation
                    </Button>
                  </Form.Group>
                </form>
              </>
            ) : (
              <SearchLeaderListing onClick={setsearchLeaderListing}></SearchLeaderListing>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
