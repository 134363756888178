import {ListViewProvider, useListView} from './core/ListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
import {TemplatesListHeader} from './components/header/TemplatesListHeader'
import {TemplatesTable} from './table/TemplatesTable'
import {TemplateEditModal} from './template-edit-modal/TemplateEditModal'
import { KTCard } from '../../../../../_metronic/helpers'
// import { KTCard } from '../../../../_metronic/helpers'
// import {KTCard} from '../../../../../_metronic/helpers'

const TemplatesList = () => {
  const {itemIdForUpdate} = useListView()
  return (
    <>
      <>
        {/* <TemplatesListHeader /> */}
        <TemplatesTable />
      </>
      {itemIdForUpdate !== undefined && <TemplateEditModal />}
    </>
  )
}

const TemplatesListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <TemplatesList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {TemplatesListWrapper}
