import axios from 'axios'
import {AuthModel, UserModel} from './_models'
import {apiUrl} from '../../../../apiurl'

const API_URL = apiUrl

// const API_URL = process.env.REACT_APP_API_URL

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/verify_token`
export const LOGIN_URL = `${API_URL}/users/loginotp`
export const REGISTER_URL = `${API_URL}/register`
export const REQUEST_PASSWORD_URL = `${API_URL}/forgot_password`
export const OTP_URL = `${API_URL}/users/verifyotp`
export const LOGOUT_URL = `${API_URL}/users/userLogout`
const getAllChaptersURL=`${API_URL}/chapters?filter[where][isdelete]=0`

// Server should return AuthModel
export function loginByMobile(cellnumber: any) {
  return axios
    .post<any>(LOGIN_URL, {
      cellnumber,
    })
    .then((res: any) => {
      return res
    })
}

export function OTPVerify(token:any,otp:any) {
  const headers = {
    accesstoken: token
  }
  return axios
    .post<any>(
      OTP_URL,
      {
        otp,
        devicetoken: localStorage.getItem('devicetoken')
      },
      {
        headers,
      }
    )
    .then((res: any) => {
      return res
    })
}

export function logoutApi(accesstoken:any) {
  const headers = {
    accesstoken: accesstoken,
    'Content-Type': 'application/json',
  }
  return axios.post<any>(LOGOUT_URL,{},{headers}).then((res: any) => res)
}

// Server should return AuthModel
export function register(
  email: string,
  firstname: string,
  lastname: string,
  password: string,
  password_confirmation: string
) {
  return axios.post(REGISTER_URL, {
    email,
    first_name: firstname,
    last_name: lastname,
    password,
    password_confirmation,
  })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<{result: boolean}>(REQUEST_PASSWORD_URL, {
    email,
  })
}

export function getUserByToken(token: string) {
  return axios.post<UserModel>(GET_USER_BY_ACCESSTOKEN_URL, {
    api_token: token,
  })
}

export function getChapterList() {
  return axios
    .get<any>(getAllChaptersURL)
    .then((res: any) => {
      return res
    })
}

