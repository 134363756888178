import React, { useState } from "react";
import AddSection from "./AddSection";
import { LibrarymanagementsListWrapper } from "./librarymanagements-list/LibrarymanagementsList";
import { Button, Modal } from "react-bootstrap";
import { QueryRequestProvider } from "./librarymanagements-list/core/QueryRequestProvider";
import { QueryResponseProvider } from "./librarymanagements-list/core/QueryResponseProvider";
import { ListViewProvider } from "./librarymanagements-list/core/ListViewProvider";



const LibraryManageListing = () => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (

        <>
        <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
                
            <div className="row flex-md-block ">
                <div className='col-sm-6 col-md-6 col-lg-6'><h1 className='inv-hst sm-ms-2'> Library Management </h1></div>

                <div className='col-sm-6  col-md-6 col-lg-6 mb-5 text-end'>
                    <button data-bs-toggle="modal"  onClick={()=>setShow(true)} className="btn btn-primary waves-effect waves-light btn-sm"  > Add Section </button></div>
            <AddSection show={show} handleClose={handleClose} />
            </div>

            <div className='card mx-3 mx-md-0 py-8 px-5 px-sm-8 mt-5 ilead__card'>
                {/* <div className="row mx-0 justify-content-end align-items-center mb-5 mt-6 fs-5 ">
            <div className='mx-3 mx-md-0 pt-5'>
                <div className="row mx-0 justify-content-end align-items-center mb-5 mt-6 fs-5 ">
                    <div className="col-sm-4 col-md-6 col-lg-4 text-end sm-text-start" >Search: </div>
                    <div className="ps-2 col-sm-8 col-md-6 col-lg-4"> <input type="search" className="form-control" placeholder="Search library section here" /> </div>
                </div> */}

                <LibrarymanagementsListWrapper />
            </div>

            </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
        </>
    )

}

export default LibraryManageListing