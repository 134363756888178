import axios, {AxiosResponse} from 'axios'
// import {ID, Response} from '../../../../../../_metronic/helpers'
import {Template} from './_models'
import { ID, Response } from '../../../../../../_metronic/helpers'
import { apiUrl, messageCenterUrl } from '../../../../../../apiurl'
// import { ID, Response } from '../../../../../_metronic/helpers'

const TEMPLATE_URL = `${apiUrl}/template`
const MESSAGE_CENTER_TEMPLATES_URL = `${messageCenterUrl}/notificationtemplates`
const GET_CHAPTER_LIST_URL = `${apiUrl}/chapters?filter[where][isdelete]=0`


const getTemplates = (query: any): Promise<any> => {
  return axios
    .get(`${MESSAGE_CENTER_TEMPLATES_URL}`)
    .then((d: AxiosResponse<any>) => d.data)
}

const getTemplateById = (id: any): Promise<any> => {
  return axios
    .get(`${MESSAGE_CENTER_TEMPLATES_URL}/${id}`)
    .then((response: any) => response.data)
}

const getChaptersList = (): Promise<any> => {
  return axios
    .get(`${GET_CHAPTER_LIST_URL}`)
    .then((d: AxiosResponse<any>) => d.data)
}

const createTemplate = (template: Template): Promise<Template | undefined> => {
  return axios
    .put(TEMPLATE_URL, template)
    // .then((response: AxiosResponse<Response<Template>>) => response.data).catch((e) => console.log(e))
    .then((response: Response<any>) => response.data).catch((e) => console.log(e))
}

const updateTemplate = (id: any,template: any): Promise<any> => {
  return axios
    .patch(`${MESSAGE_CENTER_TEMPLATES_URL}/${id}`, template,{
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((response: Response<any>) => response.data)
}

const deleteTemplate = (templateId: ID): Promise<void> => {
  return axios.delete(`${TEMPLATE_URL}/${templateId}`).then(() => {})
}

const deleteSelectedTemplates = (templateIds: Array<ID>): Promise<void> => {
  const requests = templateIds.map((id) => axios.delete(`${TEMPLATE_URL}/${id}`))
  return axios.all(requests).then(() => {})
}

export {getTemplates, deleteTemplate, deleteSelectedTemplates,
  getTemplateById, createTemplate, updateTemplate,
  getChaptersList
}
