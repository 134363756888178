import axios, {AxiosResponse} from 'axios'
// import {ID, Response} from '../../../../../../_metronic/helpers'
import {Groupmeeting, GroupmeetingsQueryResponse} from './_models'
import { ID, Response } from '../../../../../_metronic/helpers'
import { apiUrl } from '../../../../../apiurl'

const API_URL = apiUrl
const GROUPMEETING_URL = `${API_URL}/groupmeetingreports/getallreport`
const GET_GROUPMEETINGS_URL = `${API_URL}/users/query`
const GET_CHAPTER_LIST_URL = `${API_URL}/chapters?filter[where][isdelete]=0`
const VIEWBYID_GROUPMEETING_URL = `${API_URL}/groupmeetingreports/viewreport`

const getGroupmeetings = (query: string): Promise<GroupmeetingsQueryResponse> => {
  return axios
    .get(`${GROUPMEETING_URL}?${query}`)
    .then((d: AxiosResponse<any>) => d?.data).catch((e) => console.log(e))
}

const getChaptersList = (): Promise<any> => {
  return axios
    .get(`${GET_CHAPTER_LIST_URL}`)
    .then((d: AxiosResponse<any>) => d?.data)
}

const getGroupmeetingById = (id: any): Promise<any> => {
  return axios
    .get(`${VIEWBYID_GROUPMEETING_URL}/${id}`)
    // .then((response: AxiosResponse<Response<Groupmeeting>>) => response.data).catch((e) => console.log(e))
    .then((response: Response<any>) => response?.data).catch((e) => console.log(e))
}

const createGroupmeeting = (groupmeeting: Groupmeeting): Promise<Groupmeeting | undefined> => {
  return axios
    .put(GROUPMEETING_URL, groupmeeting)
    // .then((response: AxiosResponse<Response<Groupmeeting>>) => response.data).catch((e) => console.log(e))
    .then((response: Response<any>) => response?.data).catch((e) => console.log(e))
}

const updateGroupmeeting = (groupmeeting: Groupmeeting): Promise<Groupmeeting | undefined> => {
  return axios
    .post(`${GROUPMEETING_URL}/${groupmeeting.id}`, groupmeeting)
    // .then((response: AxiosResponse<Response<Groupmeeting>>) => response.data).catch((e) => console.log(e))
    .then((response: Response<any>) => response?.data).catch((e) => console.log(e))
}

const deleteGroupmeeting = (groupmeetingId: ID): Promise<void> => {
  return axios.delete(`${GROUPMEETING_URL}/${groupmeetingId}`).then(() => {})
}

const deleteSelectedGroupmeetings = (groupmeetingIds: Array<ID>): Promise<void> => {
  const requests = groupmeetingIds.map((id) => axios.delete(`${GROUPMEETING_URL}/${id}`))
  return axios.all(requests).then(() => {})
}

export {getGroupmeetings, getChaptersList, deleteGroupmeeting, deleteSelectedGroupmeetings, getGroupmeetingById, createGroupmeeting, updateGroupmeeting}
