/* eslint-disable react-hooks/exhaustive-deps */
import {FC, useContext, useState, useEffect, useMemo} from 'react'
import {useQuery} from 'react-query'
// import {
//   createResponseContext,
//   initialQueryResponse,
//   initialQueryState,
//   PaginationState,
//   QUERIES,
//   stringifyRequestQuery,
//   WithChildren,
// } from '../../../../../../_metronic/helpers'
import {getLibrarymanagements} from './_requests'
import {Librarymanagement} from './_models'
import {useQueryRequest} from './QueryRequestProvider'
import { PaginationState, QUERIES, WithChildren, createResponseContext,  initialQueryResponse, initialQueryState, stringifyRequestQuery } from '../../../../../_metronic/helpers'

const QueryResponseContext = createResponseContext<any>(initialQueryResponse)
const QueryResponseProvider: FC<WithChildren> = ({children}) => {
  const {state} = useQueryRequest()
  const [query, setQuery] = useState<string>(stringifyRequestQuery(state))
  const updatedQuery = useMemo(() => stringifyRequestQuery(state), [state])
  useEffect(() => {
    if (query !== updatedQuery) {
      setQuery(updatedQuery)
    }
  }, [updatedQuery])

  const {
    isFetching,
    refetch,
    data: response,
  } = useQuery(
    `${QUERIES.LIBRARYMANAGEMENTS_LIST}-${query}`,
    () => {
      const transformedString = transformApiParams(query);
      return getLibrarymanagements(transformedString)
    },
    {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
  )

  return (
    <QueryResponseContext.Provider value={{isLoading: isFetching, refetch, response, query}}>
      {children}
    </QueryResponseContext.Provider>
  )
}

const useQueryResponse = () => useContext(QueryResponseContext)

const useQueryResponseData = () => {
  const {response} = useQueryResponse() as any
  console.log("eeeeeeeeeee","response",response)
  if (!response || !response?.data) {
    return []
  }
  return response?.data || []
}



const useQueryResponsePagination = () => {
  const defaultPaginationState: any = {
    links: [],
    ...initialQueryState,
  }

  const {response} = useQueryResponse()
  if (!response || !response.length) {
    return defaultPaginationState
  }
  if (!response || !response.pagination) {
    return {}
  }
  
  return response.pagination
}

const useQueryResponseLoading = (): boolean => {
  const {isLoading} = useQueryResponse()
  return isLoading
}


export {
  QueryResponseProvider,
  useQueryResponse,
  useQueryResponseData,
  useQueryResponsePagination,
  useQueryResponseLoading,
}

function transformApiParams(inputStr: string): string {
  // Split the input string into key-value pairs
  const params = inputStr.split('&');
  const transformedParams: string[] = [];
  let orderValue = 'created_at';
  let order='desc'
  // Iterate through the key-value pairs and transform them
  for (const param of params) {
      const [key, value] = param.split('=');
      if (key === 'page') {
        transformedParams.push(`page=${parseInt(value)}`);
    } else if (key === 'items_per_page') {
        transformedParams.push(`perPage=${parseInt(value)}`);
    }else if (key == 'search') {
        console.log(" ",value)
        transformedParams.push(`filter[where][title]=${value}`);
      }else if (key == 'sort') {
        orderValue = value;
      }
      else if (key == 'order') {
        order=value
        // transformedParams.push(`filter[order]=${orderValue}%20${value}`);
      }
  }
  transformedParams.push(`filter[order]=${orderValue}%20${order}`);
  // Join the transformed parameters back into a string
  const transformedStr = transformedParams.join('&');
  return transformedStr;
}