/* eslint-disable jsx-a11y/anchor-is-valid */
import { useContext, useState } from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import { useFormik } from 'formik'
import SVG from 'react-inlinesvg'
import { OTPVerify, loginByMobile } from '../core/_requests'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'
import { useAuth } from '../core/Auth'
import { Button, Image } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { UserContext } from '../../../../contextStore'
import { connect } from 'react-redux'
import * as actions from '../../../../store/action';
import { COMETCHAT_CONSTANTS } from '../../../../consts'
import { showOTPAPI } from '../../../../utils/showOTP'

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

const MobileSchema = Yup.object().shape({
  cellnumber: Yup.string()
    .min(10, 'Minimum 10 digit required')
    .max(10, 'Maximum 10 digit')
    .required('Mobile Number is required')
    .matches(phoneRegExp, 'Mobile number is not valid'),
})

const OTPSchema = Yup.object().shape({
  otp: Yup.string()
    .min(5, 'Minimum 5 digit required')
    .max(5, 'Maximum 5 digit')
    .required('Please enter valid OTP'),
})

const initialValues = {
  cellnumber: '',
}

const initialOTPValues = {
  otp: '',
}

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

function Login(props: any) {
  const [loading, setLoading] = useState(false)
  const { saveAuth, setCurrentUser } = useAuth()
  const [otp, setOTP] = useState<any>(0)
  const [token, setToken] = useState<any>('')
  const [mobile, setMobile] = useState<any>(0)
  const { setAuth, setMessage ,setChapterID} = useContext(UserContext);

  const navigate = useNavigate()

  const [otpForm, SetOTPForm] = useState(false)

  const Mobileformik = useFormik({
    initialValues,
    validationSchema: MobileSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true)
      try {
        await loginByMobile(values.cellnumber)
          .then((res: any) => {
            if (res?.status == 200) {
              if (res?.data) {
                if (res?.data?.token && res?.data?.otp) {
                  setOTP(res?.data?.otp ?? '')
                  setToken(res?.data?.token ?? '')
                  SetOTPForm(true)
                  setMessage('success', 'OTP sent')
                  setMobile(values.cellnumber)
                  if (showOTPAPI.find((x:any)=>x==process.env.REACT_APP_API_URL)) {
                    OTPformik.setFieldValue('otp', res?.data?.otp)
                  }
                } else {
                  setMessage('error', 'User not found')
                }
              } else {
                setMessage('error', 'Something Went Wrong ')
              }
              setLoading(false)
            }
          })
          .catch((error) => {
            if (error?.response?.data?.error?.message) {
              setMessage('error', error?.response?.data?.error?.message)
            } else {
              setMessage('error', 'Something went wrong ')
            }
            setLoading(false)
          })
      } catch (error: any) {
        setMessage('error', error?.message ? error?.message : 'Something Went Wrong')
        setLoading(false)
      }
      // SetOTPForm(true)
      // setSubmitting(false)
    },
  })

  const resendOTP = () => {
    try {
      loginByMobile(mobile).then((res: any) => {
        if (res?.status == 200) {
          if (res?.data) {
            if (res?.data?.token && res?.data?.otp) {
              setOTP(res?.data?.otp ?? '')
              setToken(res?.data?.token ?? '')
              if (showOTPAPI.find((x:any)=>x==process.env.REACT_APP_API_URL)) {
                OTPformik.setFieldValue('otp', res?.data?.otp)
              }
              setMessage('success', 'OTP sent')
            } else {
              setMessage('error', 'User not found')
            }
          } else {
            setMessage('error', 'Something Went Wrong')
          }
          setLoading(false)
        }
      }).catch((error) => {
        if (error?.response?.data?.error?.message) {
          setMessage('error', error?.response?.data?.error?.message)
        } else {
          setMessage('error', 'User Not Found')
        }
        setLoading(false)
      })
    } catch (error: any) {
      setMessage('error', error?.message ? error?.message : 'Something Went Wrong')
      setLoading(false)
    }
  }

  const OTPformik = useFormik({
    initialValues: initialOTPValues,
    validationSchema: OTPSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true)
      try {
        await OTPVerify(token, values.otp).then((res: any) => {
          if (res?.status == 200) {
            if (values.otp == otp) {
              let role = res?.data?.role_id ?? ''
              let user = res?.data ?? ''
              setAuth(token, role, true, user)
              setChapterID(res?.data?.chapter_id ?? '')
              setTimeout(() => {
                window.location.reload();
              })
              setMessage('success', `Welcome ${user?.name}`)
            } else {
              setMessage('error', 'Invalid OTP')
              setLoading(false)
            }
          }
        }).catch((error) => {
          if (error?.response?.data?.error?.message) {
            setMessage('error', error?.response?.data?.error?.message)
          } else {
            setMessage('error', 'User Not Found')
          }
          setLoading(false)
        })
      } catch (error) {
        console.error(error)
        saveAuth(undefined)
        setStatus('The login details are incorrect')
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  return (
    <div className='loginForm'>
      {otpForm ? (
        <div className='otp'>
          <form
            className='form w-100'
            onSubmit={OTPformik.handleSubmit}
            noValidate
            id='kt_login_signin_form'
          >
            {/* begin::Heading */}
            <div className='text-center mb-11'>
              <div className='symbol symbol-70px symbol-circle mb-5'>
                <div className='symbol-label bg-primary text-inverse-primary fw-bold'>
                  <SVG src={toAbsoluteUrl('/media/ilead/otp.svg')} width={'44px'} fill='white' />
                </div>
              </div>
              <h1 className='text-dark fw-bolder mb-3'>Mobile Verification</h1>
              <p className='text-dark fs-4 mb-3'>
                We have sent you an access code via email for mobile number verification
              </p>
            </div>
            {/* begin::Heading */}
            {/* begin::Form group */}
            <div className='fv-row mb-8'>
              <label className='form-label fs-6 fw-bolder text-dark d-none d-md-block'>OTP</label>
              <input
                placeholder='OTP'
                {...OTPformik.getFieldProps('otp')}
                className={clsx(
                  'form-control bg-transparent rounded-pill',
                  { 'is-invalid': OTPformik.touched.otp && OTPformik.errors.otp },
                  {
                    'is-valid': OTPformik.touched.otp && !OTPformik.errors.otp,
                  }
                )}
                type='text'
                name='otp'
                autoComplete='off'
              />
              {OTPformik.touched.otp && OTPformik.errors.otp && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{OTPformik.errors.otp}</span>
                  </div>
                </div>
              )}
            </div>
            {/* end::Form group */}
            {/* begin::Action */}
            <div className='d-grid mb-10'>
              <button
                type='submit'
                id='kt_sign_in_submit'
                className='btn btn-primary rounded-pill'
              // disabled={OTPformik.isSubmitting || !OTPformik.isValid}
              >
                {!loading && <span className='indicator-label'>Continue</span>}
                {loading && (
                  <span className='indicator-progress' style={{ display: 'block' }}>
                    Please wait...
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
            {/* end::Action */}

            <div className='text-gray-500 text-center fw-semibold fs-6 mt-4'>
              Didn't receive the OTP?{' '}
              <Button
                variant='link'
                className='text-primary'
                onClick={() => {
                  resendOTP()
                }}
              >
                Resend Code
              </Button>
            </div>
            <div className='text-gray-500 text-center fw-semibold fs-6 mt-5  d-flex align-items-center justify-content-center'>
              <Button
                className='link-primary'
                variant='link'
                onClick={() => {
                  SetOTPForm(false)
                }}
              >
                <i className='ki-duotone ki-black-left fs-2x text-primary'></i> Back
              </Button>
            </div>
          </form>
        </div>
      ) : (
        <div className='mobileNumber'>
          <form
            className='form w-100'
            onSubmit={Mobileformik.handleSubmit}
            noValidate
            id='kt_login_signin_form'
          >
            {/* begin::Heading */}
            <div className='text-center mb-11'>
              <Image
                src={toAbsoluteUrl('/media/logos/ilead-logo.jpg')}
                className='mb-5'
                style={{ maxWidth: '180px' }}
              ></Image>
            </div>
            {/* begin::Heading */}

            {/* begin::Form group */}
            <div className='fv-row mb-8'>
              <label className='form-label fs-6 fw-bolder text-dark d-none d-md-block'>
                Mobile Number
              </label>
              <input
                placeholder='Mobile Number'
                {...Mobileformik.getFieldProps('cellnumber')}
                className={clsx(
                  'form-control bg-transparent rounded-pill',
                  { 'is-invalid': Mobileformik.touched.cellnumber && Mobileformik.errors.cellnumber },
                  {
                    'is-valid': Mobileformik.touched.cellnumber && !Mobileformik.errors.cellnumber,
                  }
                )}
                type='mobile'
                name='cellnumber'
                autoComplete='off'
                onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                  const key = e.key;
                  const isNumeric = /^[0-9]$/.test(key);
                  const isSpecialKey =
                    key === 'Backspace' ||
                    key === 'Delete' ||
                    key === 'ArrowLeft' ||
                    key === 'ArrowRight' ||
                    key === 'ArrowUp' ||
                    key === 'ArrowDown' ||
                    (e.ctrlKey && key === 'c') ||
                    (e.ctrlKey && key === 'v') ||
                    (e.ctrlKey && key === 'x') ||
                    (e.ctrlKey && key === 'a');
                  if (!isNumeric && !isSpecialKey) {
                    e.preventDefault();
                  }
                }}
              />
              {Mobileformik.touched.cellnumber && Mobileformik.errors.cellnumber && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{Mobileformik.errors.cellnumber}</span>
                  </div>
                </div>
              )}
            </div>
            {/* begin::Action */}
            <div className='d-grid mb-10'>
              <button
                type='submit'
                id='kt_sign_in_submit'
                className='btn btn-primary rounded-pill'
                disabled={Mobileformik.isSubmitting || !Mobileformik.isValid}
              >
                {!loading && <span className='indicator-label'>Send OTP</span>}
                {loading && (
                  <span className='indicator-progress' style={{ display: 'block' }}>
                    Please wait...
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
            {/* end::Action */}
          </form>
        </div>
      )}
    </div>
  )
}

const mapStateToProps = (state: any) => {
  return {
    isLoggedIn: state.isLoggedIn
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    getLoggedinUser: () => dispatch(actions.authCheckState()),
    onLogin: (uid: any, authKey: any) => dispatch(actions.auth(uid, authKey)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
