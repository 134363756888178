import React, {useCallback, useContext, useEffect, useRef, useState} from 'react'
import {Button, Form, Image, InputGroup} from 'react-bootstrap'
import {toAbsoluteUrl} from '../../../../../../_metronic/helpers'
import {UserContext} from '../../../../../../contextStore'
import {addUserTopic, listingAllChapter, listingAllLeaderUser, singleLeaderUser} from '../_requests'
import {apiUrl} from '../../../../../../apiurl'
import * as Yup from 'yup'
import {useFormik} from 'formik'

const API_URL = apiUrl

export function BusinessReferral() {
  const [businessReferralStepOne, setbusinessReferralStepOne] = useState(true)
  const {setMessage, dashBoardReload} = useContext(UserContext)
  const [chapterData, setChapterData] = useState<any>([])
  const [leaderData, setLeaderData] = useState<any>([])
  const {userDetail} = useContext(UserContext)
  const [id, setId] = useState<any>('')
  const [singleLeaderData, setSingleLeaderData] = useState({name: '', id: ''})
  const [isLoading, setIsLoading] = useState(true)
  const userid = userDetail?.id ?? ''
  const [leaderName, setLeaderName] = useState<any>('')
  const [originalData, setOriginalData] = useState<any>([])
  const closebutton = useRef<any>()
  
  useEffect(() => {
    const fetchData = async () => {
      // Fetch chapter data
      try {
        const chapterResponseData = await listingAllChapter()
        setChapterData((prevData: any) => [...(chapterResponseData ?? [])])
      } catch (error) {
        console.error('Error fetching chapter data:', error)
      }

      try {
        const leaderResponseData = await listingAllLeaderUser(id, leaderName, userid)
        setOriginalData((prevData: any) => [...(leaderResponseData ?? [])])
      } catch (error) {
        console.error('Error fetching leader data:', error)
      }
      setIsLoading(false)
    }

    fetchData()
  }, [id, leaderName])

  useEffect(() => {
    filterData()
  }, [id, leaderName, originalData])

  const filterData = () => {
    const filteredData = originalData.filter((item: any) => {
      // Filter based on chapterId if it's selected
      const matchesChapter = id === '' || item.chapter_id.toString() === id

      // Filter based on leaderName if it's provided
      const nameRegex = new RegExp(leaderName, 'i') // Case-insensitive search
      const matchesName = leaderName === '' || nameRegex.test(item.name)

      return matchesChapter && matchesName
    })

    setLeaderData(filteredData) // Update the filtered data state
  }

  const fetchSingleLeaderData = async (leaderId: any) => {
    try {
      const singleLeaderResponseData = await singleLeaderUser(leaderId)
      setSingleLeaderData(singleLeaderResponseData ?? [])
    } catch (error) {
      console.error('Error fetching single leader data:', error)
    }
  }
  const settings = {
    multiple: false,
    address: false,
    email: false,
    icon: false,
    name: true,
    tel: true,
  }

  const handlePickClick = useCallback(async () => {
    try {
      if ('contacts' in navigator && 'select' in navigator.contacts) {
        const selectedContacts = await navigator.contacts.select(['name', 'tel'], {
          multiple: settings.multiple,
        })

        if (selectedContacts.length > 0) {
          formik.setFieldValue('referralName', selectedContacts[0].name)
          formik.setFieldValue('referralContact', selectedContacts[0].tel[0])
        }
      } else {
        setMessage('error', "Sorry, Your device doesn't support Contact Picker")
       
      }
    } catch (error: any) {
      setMessage('error', error?.message ? error?.message : 'Something Went Wrong')
    }
  }, [settings])
  const initialValue = {
    referralName: '',
    referralContact: '',
    workRequied: '',
  }
  // validation
  const validationSchema = Yup.object().shape({
    referralName: Yup.string()
      .required('This field is required')
      .matches(/^[A-Za-z\s]+$/, 'Only letters are allowed in the name')
      .max(20, 'Please enter 20 character referral name'),
    referralContact: Yup.string()
      .required('This field is required')
      .matches(/^[6789]\d{9}$/, 'Invalid phone number'),
    workRequied: Yup.string()
      .required('This field is required')
      .matches(/^[A-Za-z0-9\s]+$/, 'Only letters are allowed in the work')
      .max(200, 'Please enter valid work'),
  })

  const formik = useFormik({
    initialValues: initialValue,
    validationSchema: validationSchema,
    onSubmit: async (values, {resetForm}) => {
      try {
        const postBusinessReferralData = {
          topic_id: 20,
          referralName: values.referralName,
          referralContact: values.referralContact,
          workRequired: values.workRequied,
          user_id: userDetail.id,
          chapter_id: id,
          descriptiontext: 'Business referral ',
          isactive: 1,
          isdelete: 0,
          created_by: userDetail.id,
          business_referral_receiver: singleLeaderData.id,
          business_referral_sender: userDetail.id,
        }

        await addUserTopic(postBusinessReferralData)
          .then((res: any) => {
            if (res) {
              setMessage('success', ' Business Referral Submitted Successfully')
              setId('')
              setLeaderName('')
              resetForm()
              setbusinessReferralStepOne(true)
             
              dashBoardReload()
            }
          })
          .catch((error: any) => {
            if (error?.response?.data?.error?.message) {
              setMessage('error', error?.response?.data?.error?.message)
            } else {
              setMessage('error', 'Something Went Wrong')
            }
            setIsLoading(false)
          })
      } catch (error) {
        setMessage('error', 'Something went wrong')
      }
    },
  })
  
  return (
    <>
      <div
        className='modal fade ilead-default-modal'
        id='BusinessReferrralModal'
        aria-hidden='true'
        aria-labelledby='BusinessReferrralModalLabel'
      >
        <div className='modal-dialog modal-dialog modal-dialog-scrollable modal-fullscreen-md-down modal-md'>
          <div className='modal-content'>
            <div className='modal-header py-5'>
              <h3 className='modal-title fs-3 fw-bold' id='BusinessReferrralModalToggleLabel'>
                Business Referral
              </h3>
              <button
                type='button'
                className='btn-close text-white'
                data-bs-target='#write_post_modal'
                data-bs-toggle='modal'
                data-bs-dismiss='modal'
                onClick={() => {
                  setbusinessReferralStepOne(true)
                  formik.setFieldValue('referralName', '')
                  formik.setFieldValue('referralContact', '')
                  formik.setFieldValue('workRequied', '')
                  setId('')
                  setLeaderName('')
                  formik.resetForm()
                }}
                ref={closebutton}
              ></button>
            </div>
            <div className='modal-body pt-7 write-post-wrapper'>
              {businessReferralStepOne ? (
                <>
                  <h3 className='text-black fw-bold fs-4 mb-6'>Search Leader</h3>
                  <Form.Group className='mb-7'>
                    <Form.Select
                      aria-label='Default select example'
                      className='ilead-input'
                      value={id}
                      onChange={(e) => setId(e.target.value)}
                    >
                      <option value={userDetail?.chapter_id}>Select Chapter</option>
                      {chapterData.map((chapter: any) => (
                        <option value={chapter.id}>{chapter.name}</option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                  <Form.Group className='mb-3'>
                    <Form.Label>Search Leader</Form.Label>
                    <InputGroup>
                      <InputGroup.Text id='SearchLeader' className='bg-white ilead-input-group'>
                        <i className='fa-solid fa-magnifying-glass'></i>
                      </InputGroup.Text>
                      <Form.Control
                        placeholder='Type leader name'
                        aria-label='LeaderSearch'
                        aria-describedby='LeaderSearch'
                        className='border-start-0 ps-0 ilead-input-group'
                        value={leaderName}
                        onChange={(e: any) => setLeaderName(e.target.value)}
                      />
                    </InputGroup>
                  </Form.Group>
                  <div className='leader_list_scroll_wrapper mt-7'>
                    {/* begin: leader box */}
                    {leaderData.map((item: any) => (
                      <div
                        className='small_leader_box d-flex align-items-center mb-5 cursor-pointer'
                        onClick={() => {
                          setbusinessReferralStepOne(false)

                          fetchSingleLeaderData(item.id)
                        }}
                      >
                        <div className='symbol symbol-45px me-3'>
                          <Image
                            src={
                              item.profile
                                ? `${API_URL}/containers/userprofile/download/${item.profile}`
                                : `${toAbsoluteUrl('./media/avatars/default-user.png')}`
                            }
                            onError={({currentTarget}: any) => {
                              currentTarget.onerror = null // prevents looping
                              currentTarget.src = `${toAbsoluteUrl(
                                './media/avatars/default-user.png'
                              )}`
                            }}
                          />
                        </div>
                        <div className='leader_details'>
                          <div className='text-black fs-6 fs-md-5 fw-bold mb-0'>{item.name}</div>
                          <span className='fs-14 text-gray-600'>{item.companyname}</span>
                        </div>
                      </div>
                    ))}
                    {/* end: leader box */}
                  </div>
                </>
              ) : (
                <>
                  {Object.keys(singleLeaderData).length > 0 && (
                    <div className='business-ref-ineer'>
                      <div className='border-bottom'>
                        <div className='position-absolute'>
                          <Image
                            src={toAbsoluteUrl('/media/logos/ilead-logo.jpg')}
                            className='mh-30px'
                          ></Image>
                        </div>
                        <div className='ref-icon text-center'>
                          <Image src={toAbsoluteUrl('/media/ilead/business-referral.svg')}></Image>
                        </div>
                        <h5 className='text-center text-black fs-3 fw-bold'>{userDetail.name}</h5>
                        <p className='mb-1 mt-4 text-center fs-6 fw-semibold'>
                          Gives Business Referral for <br />
                          Leader
                        </p>
                        <div className='mt-4 gratitude-title ilead-text w-100 p-3 d-block text-center fw-semibold'>
                          {singleLeaderData.name}
                        </div>
                      </div>
                      <form
                        className='mt-7'
                        onSubmit={(e) => {
                          formik.handleSubmit(e)
                        }}
                      >
                        <Form.Group className='mb-4'>
                          <Form.Label htmlFor='name' className='fs-14'>
                            Name Referred <span style={{color: 'red'}}> *</span>
                          </Form.Label>
                          <InputGroup className='mb-0'>
                            <Form.Control
                              placeholder='Enter name'
                              aria-label='name'
                              id='name'
                              maxLength={30}
                              className='border-right-0 ilead-input'
                              onChange={(e) => {
                                formik.setFieldValue('referralName', e.target.value)
                              }}
                              value={formik.values.referralName}
                            />

                            <InputGroup.Text
                              id='name'
                              className='bg-white ilead-input-group'
                              onClick={handlePickClick}
                            >
                              <i className='fa-solid fa-address-book'></i>
                            </InputGroup.Text>
                          </InputGroup>
                          {formik?.touched?.referralName && formik?.errors?.referralName && (
                            <div className='fv-plugins-message-container text-danger'>
                              <span role='alert'>{formik?.errors?.referralName as any}</span>
                            </div>
                          )}
                        </Form.Group>
                        <Form.Group className='mb-4'>
                          <Form.Label htmlFor='contactDetails' className='fs-14'>
                            Contact Details <span style={{color: 'red'}}> *</span>
                          </Form.Label>
                          <Form.Control
                            type='text'
                            id='contactDetails'
                            className='ilead-input'
                            placeholder='Enter contact number'
                            maxLength={10}
                            onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                              const key = e.key
                              const isNumeric = /^[0-9]$/.test(key)
                              const isSpecialKey =
                                key === 'Backspace' ||
                                key === 'Delete' ||
                                key === 'ArrowLeft' ||
                                key === 'ArrowRight' ||
                                key === 'ArrowUp' ||
                                key === 'ArrowDown' ||
                                (e.ctrlKey && key === 'c') ||
                                (e.ctrlKey && key === 'v') ||
                                (e.ctrlKey && key === 'x') ||
                                (e.ctrlKey && key === 'a')
                              if (!isNumeric && !isSpecialKey) {
                                e.preventDefault()
                              }
                            }}
                            onChange={(e) => {
                              formik.setFieldValue('referralContact', e.target.value)
                            }}
                            value={formik.values.referralContact}
                          />
                          {formik?.touched?.referralContact && formik?.errors?.referralContact && (
                            <div className='fv-plugins-message-container text-danger'>
                              <span role='alert'>{formik?.errors?.referralContact as any}</span>
                            </div>
                          )}
                        </Form.Group>
                        <Form.Group className='mb-4'>
                          <Form.Label htmlFor='workRequired' className='fs-14'>
                            Work Required <span style={{color: 'red'}}> *</span>
                          </Form.Label>
                          <Form.Control
                            type='text'
                            id='workRequired'
                            className='ilead-input'
                            placeholder='Enter required work'
                            maxLength={50}
                            onChange={(e) => {
                              formik.setFieldValue('workRequied', e.target.value)
                            }}
                            value={formik.values.workRequied}
                          />

                          {formik?.touched?.workRequied && formik?.errors?.workRequied && (
                            <div className='fv-plugins-message-container text-danger'>
                              <span role='alert'>{formik?.errors?.workRequied as any}</span>
                            </div>
                          )}
                        </Form.Group>
                        <Form.Group className='mt-5 d-flex'>
                          <Button
                            variant='secondary'
                            type='button'
                            className='w-50 me-3'
                            onClick={() => {
                              setbusinessReferralStepOne(true)
                              setId('')
                              setLeaderName('')
                              formik.resetForm()
                            }}
                          >
                            Cancel
                          </Button>
                          <Button
                            variant='primary'
                            type='submit'
                            data-bs-toggle={formik.isValid ? 'modal' : undefined}
                            data-bs-dismiss={formik.isValid ? 'modal' : undefined}
                            className='w-50'
                            disabled={
                              !formik.values.referralName ||
                              !formik.values.referralContact ||
                              !formik.values.workRequied ||
                              formik.isSubmitting
                            }
                          >
                            Submit
                          </Button>
                        </Form.Group>
                      </form>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
