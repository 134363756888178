import React, { useEffect } from "react";
import { Button, Form } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import AddEditChatGroup from "./AddEditChatGroup";




const EditChatGroup = () => {
    const { id } = useParams()

    const navigate = useNavigate()
    const navigateChatGroupManagement = () => {
        // 👇️ navigate to /
        navigate('/chat-group-management')
    }

    const fetchData = async () => {

    }

    useEffect(() => {
        fetchData()
    }, [id])



    return (

        <>

            <div className="row flex-md-block ">
                <div className='col-sm-6 col-md-6 col-lg-6'><h1 className='inv-hst sm-ms-2'> Edit Chat Group </h1></div>

                <div className='col-sm-6  col-md-6 col-lg-6 mb-5 text-end'>

                    <button onClick={navigateChatGroupManagement} className="btn btn-primary waves-effect waves-light btn-sm" > Back </button></div>
            </div>

            <div className='card mx-3 py-8 px-5 px-sm-8 mt-5 '>

                <AddEditChatGroup />

            </div>


        </>
    )
}
export default EditChatGroup