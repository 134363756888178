import {ListViewProvider, useListView} from './core/ListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
import {ChaptersListHeader} from './components/header/ChaptersListHeader'
import {ChaptersTable} from './table/ChaptersTable'
import {ChapterEditModal} from './chapter-edit-modal/ChapterEditModal'
import {KTCard} from '../../../../_metronic/helpers'
// import { KTCard } from '../../../../_metronic/helpers'
// import {KTCard} from '../../../../../_metronic/helpers'

const ChaptersList = () => {
  const {itemIdForUpdate} = useListView()
  return (
    <>
      <>
        {/* <ChaptersListHeader /> */}
        <ChaptersTable />
      </>
      {itemIdForUpdate !== undefined && <ChapterEditModal />}
    </>
  )
}

const ChaptersListWrapper = () => (
  <ListViewProvider>
    <ChaptersList />
  </ListViewProvider>
)

export {ChaptersListWrapper}
