const QUERIES = {
  USERS_LIST: 'users-list',
  SUMMARYSESSIONS_LIST: 'summarysessions-list',
  SESSIONATTENDANCES_LIST: 'sessionattendances-list',
  MONTHLYREPORTS_LIST: 'monthlyrepotrts-list',
  VISITORPANELS_LIST: 'visitorpanels-list',
  EVENTS_LIST: 'events-list',
  GROUPMEETINGS_LIST: 'groupmeetings-list',
  GROUPMANAGEMENTS_LIST: 'groupmanagements-list',
  GROUPCHATS_LIST: 'groupchats-list',
  LIBRARYMANAGEMENTS_LIST: 'librarymanagements-list',
  TEMPLATES_LIST: 'templates-list',
  NOTIFICATIONS_LIST: 'notifications-list',
  REMINDERS_LIST: 'reminders-list',
  EXITINGUSERS_LIST: 'exitingusers-list',
  REQUESTUSERS_LIST: 'requestusers-list',
  VISITORS_LIST: 'visitors-list',
  VERTICALS_LIST: 'verticals-list',
  CHAPTERS_LIST: 'chapters-list'
}

export {QUERIES}
