import axios, {AxiosResponse} from 'axios'
// import {ID, Response} from '../../../../../../_metronic/helpers'
import {Exitinguser, ExitingusersQueryResponse} from './_models'
import { ID, Response } from '../../../../../_metronic/helpers'
import { apiUrl } from '../../../../../apiurl'
const API_URL = apiUrl
const EXITINGUSER_URL = `${API_URL}/users`
const USER_URL = `${API_URL}/users`
const GET_EXITINGUSERS_URL = `${API_URL}/users/userlist?type=existinguser`
const GET_GROUP_API = `${API_URL}/groups?Filte[where][isdelete]=0`


const getExitingusers = (query: string): Promise<ExitingusersQueryResponse> => {
  return axios
    .get(`${GET_EXITINGUSERS_URL}&${query}`)
    .then((d: AxiosResponse<any>) => d.data).catch((e) => console.log(e))
}

// const GropusListAPI = (): Promise<ExitingusersQueryResponse> => {
//   return axios
//     .get(`${GET_GROUP_API}`)
//     .then((d: AxiosResponse<any>) => d.data).catch((e) => console.log(e))
// }


const GropusListAPI  = () => {
  return axios
    .get<any>(GET_GROUP_API)
    .then((res: any) => {
      return res
    })
}

const userSingleAPI  = (ID:any) => {
  return axios
    .get<any>(`${EXITINGUSER_URL}/${ID}`)
    .then((res: any) => {
      return res
    })
} 

const paddingCountAPI  = () => {
  return axios
    .get<any>(`${API_URL}/requestusers/paddingrequestcount/ `)
    .then((res: any) => {
      return res
    })
}
const getExitinguserById = (id: ID): Promise<Exitinguser | undefined> => {
  return axios
    .get(`${EXITINGUSER_URL}/${id}`)
    // .then((response: AxiosResponse<Response<Exitinguser>>) => response.data).catch((e) => console.log(e))
    .then((response: Response<any>) => response.data).catch((e) => console.log(e))
}


const createExitinguser = (exitinguser: any ): Promise<Exitinguser | undefined> => {
  return axios
    .post(`${USER_URL}/addUser`, exitinguser)
    // .then((response: AxiosResponse<Response<Exitinguser>>) => response.data).catch((e) => console.log(e))
    .then((response: any) => response)
}

const updateExitinguser = (exitinguser: any): Promise<Exitinguser | undefined> => {
  return axios
    .put(`${USER_URL}/UpdateUser`, exitinguser)
    // .then((response: AxiosResponse<Response<Exitinguser>>) => response.data).catch((e) => console.log(e))
    .then((response: any) => response)
}

const activeOrDeactiveAPI= (exitinguser: any): Promise<any> => {
  return axios
    .post(`${EXITINGUSER_URL}/activateordeactiveuser`, exitinguser)
    // .then((response: AxiosResponse<Response<Exitinguser>>) => response.data).catch((e) => console.log(e))
    .then((response: Response<any>) => response)
}
const deleteUserAPI= (id: any): Promise<any> => {
  return axios
    .delete(`${EXITINGUSER_URL}/deleteUser/${id}`)
    // .then((response: AxiosResponse<Response<Exitinguser>>) => response.data).catch((e) => console.log(e))
    .then((response: Response<any>) => response)
}

const deleteExitinguser = (exitinguserId: ID): Promise<void> => {
  return axios.delete(`${EXITINGUSER_URL}/${exitinguserId}`).then(() => {})
}

const deleteSelectedExitingusers = (exitinguserIds: Array<ID>): Promise<void> => {
  const requests = exitinguserIds.map((id) => axios.delete(`${EXITINGUSER_URL}/${id}`))
  return axios.all(requests).then(() => {})
}

export {getExitingusers, deleteExitinguser,GropusListAPI,userSingleAPI,deleteUserAPI, paddingCountAPI,deleteSelectedExitingusers,activeOrDeactiveAPI, getExitinguserById, createExitinguser, updateExitinguser}
