import React, { useContext, useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { UserContext } from "../../../../contextStore";
import * as Yup from 'yup';
import { useFormik } from 'formik';
import Multiselect from 'multiselect-react-dropdown'
import { createGroupChatParticipet, getAllCometchatUserListing, getFindOneData, updateChatGroup } from "./chatgroups-list/core/_requests";
import { Query } from "react-query";
import { useQueryResponse } from "./chatgroups-list/core/QueryResponseProvider";
import { useNavigate, useParams } from "react-router-dom";



const AddEditChatGroup = (closeButton: any) => {
    console.log(closeButton, "closeButton")
    const { id } = useParams()
    const [isLoading, setLoading] = useState<any>(false)
    const { setMessage } = useContext(UserContext)
    const { refetch } = useQueryResponse()
    const [cometchatUser, setCometChatUser] = useState<any>([])
    const [storeSelectedId, setStoreSelectedId] = useState<any>([])

    const navigate = useNavigate()
    const initialValues = {
        name: "",
        description: "",
        participants: [],
        removeUserArrays: []
    }
    const validationSchema = Yup.object().shape({
        name: Yup.string().required('Group name is required'),
        description: Yup.string().required("Group description is required")

    })

    const fetchCometchatUser = async () => {
        await getAllCometchatUserListing("").then((responce: any) => {
            setCometChatUser(responce?.data?.data.map((x: any) => {
                x.key = x.name
                x.cat = x.uid
                return x
            }))
        })
            .catch((error: any) => {

                if (error?.response?.data?.error?.message) {
                    setMessage('error', error?.response?.data?.error?.message)
                } else {
                    setMessage('error', 'Something Went Wrong')
                }

            })
    }

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: async (values, { resetForm }) => {
            setLoading(true)
            try {
                const postData = {
                    name: values.name,
                    description: values.description,
                    participants: values.participants,

                }

                const updateData = {
                    name: values.name,
                    description: values.description,
                    newUserArray: values.participants,
                    removeUserArrays: values.removeUserArrays
                }

                if (id) {
                    await updateChatGroup(id, updateData)
                        .then((res: any) => {
                            setMessage('success', 'Group Updated')
                            resetForm()
                            refetch()
                            navigate('/chat-group-management')
                            // closeButton.closeButton.current.click()
                            setLoading(false)
                        })
                        .catch((error: any) => {
                            setLoading(false)
                            if (error?.response?.data?.error?.message) {
                                setMessage('error', error?.response?.data?.error?.message)
                            } else {
                                setMessage('error', 'Something Went Wrong')
                            }

                        })
                }
                else {
                    await createGroupChatParticipet(postData)
                        .then((res: any) => {

                            setMessage('success', 'Group Added')
                            resetForm()
                            navigate('/chat-group-management')
                            closeButton.closeButton.current.click()
                            refetch()
                            setLoading(false)
                        })
                        .catch((error: any) => {
                            setLoading(false)
                            if (error?.response?.data?.error?.message) {
                                setMessage('error', error?.response?.data?.error?.message)
                            } else {
                                setMessage('error', 'Something Went Wrong')
                            }

                        })
                }
            } catch (error) {
                setLoading(false)
                setMessage('error', 'Something went wrong')
            }
        },
    })
    const fetctData = async () => {
        await getFindOneData(id).then((res: any) => {
            if (res?.data) {
                if (res?.data?.data?.data?.name) {
                    formik.setFieldValue("name", res?.data?.data?.data?.name)
                }
                if (res?.data?.data?.data?.description) {
                    formik.setFieldValue("description", res?.data?.data?.data?.description)
                }
                if (res?.data?.members?.data) {
                    const participantsData = res?.data?.members?.data;

                    const participantIDs = participantsData.map((participant: any) => participant.uid);
                    setStoreSelectedId(participantIDs);

                    const participantsList = participantsData.map((participant: any) => ({
                        select_user: participant.uid,
                        name: participant.name,
                    }));
                    formik.setFieldValue("participants", participantsList);


                }

            }
        })
    }

    useEffect(() => {
        if (id) {
            fetctData()
        }
    }, [id])

    useEffect(() => {
        fetchCometchatUser()
    }, [])
    return (
        <>
            <Form onSubmit={formik.handleSubmit}>
                <div className="modal-body visitor-details">

                    <div className='col-md-12 col-lg-12'>
                        <div className="form-floating mb-3">
                            <input type="text" className="form-control ps-0 pt-12" id="floatingInput" placeholder=""
                                onChange={(e) => {
                                    formik.setFieldValue('name', e.target.value)
                                }}
                                value={formik.values.name} />
                            <label className="ps-0"> Group Name <span className="text-danger"> * </span></label>
                        </div>
                        {formik?.touched?.name && formik?.errors?.name && (
                            <div className='fv-plugins-message-container text-danger'>
                                <span role='alert'>{formik?.errors?.name as any}</span>
                            </div>
                        )}
                    </div>


                    <div className='col-md-12 col-lg-12'>
                        <div className="form-floating mb-3">
                            <textarea rows={5} className="form-control ps-0 pt-10" id="floatingInput" placeholder="" onChange={(e) => {
                                formik.setFieldValue('description', e.target.value)
                            }}
                                value={formik.values.description} />
                            <label className="ps-0"> Group Description </label>
                        </div>
                        {formik?.touched?.description && formik?.errors?.description && (
                            <div className='fv-plugins-message-container text-danger'>
                                <span role='alert'>{formik?.errors?.description as any}</span>
                            </div>
                        )}
                    </div>

                    <div className='col-md-12 mb-8 add-chat-multi-select'>
                        {/* <select className="form-select ps-0" aria-label="Default select example" required>
                            <option selected>Choose Member </option>
                            <option value="1">Ajal Patel</option>
                            <option value="1">Gaurav Shah</option>
                            <option value="1">Aakash Goyal</option>
                            <option value="1">Tejas Rajkotia</option>
                            <option value="1">Sachit Brahmabatt</option>
                        </select> */}

                        <Multiselect
                            style={{
                                optionContainer: {
                                    bottom: '1px',
                                    menuPlacement: 'auto',
                                    menuPosition: 'fixed',
                                    maxHeight: '200px',
                                }
                            }}
                            className='form-select p-0'
                            options={cometchatUser.map((cometUser: any) => ({
                                select_user: cometUser.cat,
                                name: cometUser.name
                            }))}
                            displayValue='name'
                            onSelect={(selectedList) => {
                                const updatedList = selectedList.map((item: any) => {
                                    if (typeof item === "string") {
                                        return { select_user: item }
                                    }
                                    return item;
                                });
                                formik.setFieldValue("participants", updatedList);
                            }}
                            onRemove={(removedItem: any) => {
                                const removeIDs = removedItem.map((remove: any) => remove.select_user);

                                let difference = storeSelectedId.filter((x: any) => !removeIDs.includes(x));

                                formik.setFieldValue("removeUserArrays", difference);

                                const updatedRemoveUserArrays = [...formik.values.removeUserArrays] as any;
                                updatedRemoveUserArrays.push(removedItem.select_user);

                                const updatedList = formik.values.participants.filter((item: any) => {
                                    if (typeof item === "string") {
                                        return item !== removedItem.select_user;
                                    }
                                    return item.select_user !== removedItem.select_user;
                                });
                                formik.setFieldValue("participants", updatedList);

                                // Add the removed user to the removeUserArrays

                            }}

                            selectedValues={formik.values.participants}
                        />
                        {formik?.touched?.participants && formik?.errors?.participants && (
                            <div className='fv-plugins-message-container text-danger'>
                                <span role='alert'>{formik?.errors?.participants as any}</span>
                            </div>
                        )}

                    </div>


                    <div className='mt-10 d-flex justify-content-end mx-0 '>
                        <Button variant='primary' type='submit' className='me-3' style={{ backgroundColor: '#f5f5f5' }} disabled={isLoading}>
                            {isLoading ? "Please wait" : "Submit"}
                        </Button>

                        <Button variant='' type='button' className='bg-info text-white' data-bs-dismiss="modal" aria-label="Close" style={{ backgroundColor: '#f5f5f5' }} onClick={() => {
                            navigate('/chat-group-management')
                        }}>
                            Cancel
                        </Button>
                    </div>
                </div>
            </Form>

        </>
    )
}
export default AddEditChatGroup