import { FC } from 'react'
import clsx from 'clsx'
import { Link } from 'react-router-dom'
import { useLocation } from 'react-router'
import { checkIsActive, KTIcon} from '../../../../helpers'
import { useLayout } from '../../../core'
import {Button, Image ,Modal} from 'react-bootstrap'
import { useState } from 'react'

type Props = {
  to: string
  title: string
  icon?: string
  fontIcon?: string
  action?: string
  logout?: any
  hasBullet?: boolean
  redirect?: boolean
}

const SidebarMenuItem: FC<any> = ({
  children,
  to,
  title,
  icon,
  fontIcon,
  action = "",
  logout = () => { },
  hasBullet = false,
  redirect=false
}) => {
  const { pathname } = useLocation()
  const isActive = checkIsActive(pathname, to)
  const { config } = useLayout()
  const { app } = config



  return (
    <div className='menu-item'>
      {action === "button" ?
        <>
          <a className="menu-link without-sub justify-content-center" onClick={(e: any) => {
            e.preventDefault()
            // logout()
          }}>
            {hasBullet && (
              <span className='menu-bullet'>
                <span className='bullet bullet-dot'></span>
              </span>
            )}
            {icon && app?.sidebar?.default?.menu?.iconType === 'svg' && (
              <span className='menu-icon'>
                {' '}
                <KTIcon iconName={icon} className='fs-2' />
              </span>
            )}
            {fontIcon && app?.sidebar?.default?.menu?.iconType === 'font' && (
              <i className={clsx('bi fs-3', fontIcon)}></i>
            )}
            <span className='menu-title'>{title}</span>
          </a>
          {children}
        </>
        : redirect ?
        (<>
          <a className={clsx('menu-link without-sub justify-content-center', { active: isActive })} href={to} target='_blank'>
            {hasBullet && (
              <span className='menu-bullet'>
                <span className='bullet bullet-dot'></span>
              </span>
            )}
            {icon && app?.sidebar?.default?.menu?.iconType === 'svg' && (
              <span className='menu-icon'>
                {' '}
                <KTIcon iconName={icon} className='fs-2' />
              </span>
            )}
            {fontIcon && app?.sidebar?.default?.menu?.iconType === 'font' && (
              <i className={clsx('bi fs-3', fontIcon)}></i>
            )}
            <span className='menu-title'>{title}</span>
          </a>
          {children}
        </>) :
        (<>
          <Link className={clsx('menu-link without-sub ', { active: isActive })} to={to}>
            {hasBullet && (
              <span className='menu-bullet'>
                <span className='bullet bullet-dot'></span>
              </span>
            )}
            {icon && app?.sidebar?.default?.menu?.iconType === 'svg' && (
              <span className='menu-icon'>
                {' '}
                <KTIcon iconName={icon} className='fs-2' />
              </span>
            )}
            {fontIcon && app?.sidebar?.default?.menu?.iconType === 'font' && (
              <i className={clsx('bi fs-3', fontIcon)}></i>
            )}
            <span className='menu-title'>{title}</span>
          </Link>
          {children}
        </>)
        }
    </div>
  )
}

export { SidebarMenuItem }