// @ts-nocheck
import { Column } from 'react-table'
import { MonthlyreportInfoCell } from './MonthlyreportInfoCell'
import { MonthlyreportLastLoginCell } from './MonthlyreportLastLoginCell'
import { MonthlyreportTwoStepsCell } from './MonthlyreportTwoStepsCell'
import { MonthlyreportActionsCell } from './MonthlyreportActionsCell'
import { MonthlyreportSelectionCell } from './MonthlyreportSelectionCell'
import { MonthlyreportCustomHeader } from './MonthlyreportCustomHeader'
import { MonthlyreportSelectionHeader } from './MonthlyreportSelectionHeader'
import { Monthlyreport } from '../../core/_models'
import { checkDisplayValue } from '../../../../../../utils'

const monthlyreportsColumns: ReadonlyArray<Column<Monthlyreport>> = [
  // {
  //   Header: (props) => <MonthlyreportSelectionHeader tableProps={props} />,
  //   id: 'selection',
  //   Cell: ({...props}) => <MonthlyreportSelectionCell id={props.data[props.row.index].id} />,
  // },

  {
    Header: (props) => <MonthlyreportCustomHeader tableProps={props} title='Leader Name' className='min-w-125px  ps-2 fs-5' />,
    accessor: 'user_name',
    Cell: (value) => checkDisplayValue(value?.row?.original?.user_name)
    // id: 'name',
    // Cell: ({...props}) => <MonthlyreportInfoCell monthlyreport={props.data[props.row.index]} />,
  },

  {
    Header: (props) => <MonthlyreportCustomHeader tableProps={props} title='A' className=' fs-5' />,
    accessor: 'present_count',
    Cell: (value) => checkDisplayValue(value?.row?.original?.present_count)
    // id: 'name',
    // Cell: ({...props}) => <MonthlyreportInfoCell monthlyreport={props.data[props.row.index]} />,
  },


  {
    Header: (props) => <MonthlyreportCustomHeader tableProps={props} title='VI' className=' fs-5' />,
    accessor: 'vi',
    Cell: (value) => checkDisplayValue(value?.row?.original?.vi)
    // id: 'name',
    // Cell: ({...props}) => <MonthlyreportInfoCell monthlyreport={props.data[props.row.index]} />,
  },

  {
    Header: (props) => <MonthlyreportCustomHeader tableProps={props} title='VA' className=' fs-5' />,
    accessor: 'va',
    Cell: (value) => checkDisplayValue(value?.row?.original?.va)
    // id: 'name',
    // Cell: ({...props}) => <MonthlyreportInfoCell monthlyreport={props.data[props.row.index]} />,
  },

  {
    Header: (props) => <MonthlyreportCustomHeader tableProps={props} title='VJ' className=' fs-5' />,
    accessor: 'vj',
    Cell: (value) => checkDisplayValue(value?.row?.original?.vj)
    // id: 'name',
    // Cell: ({...props}) => <MonthlyreportInfoCell monthlyreport={props.data[props.row.index]} />,
  },

  {
    Header: (props) => <MonthlyreportCustomHeader tableProps={props} title='TG' className=' fs-5' />,
    accessor: 'tg',
    Cell: (value) => checkDisplayValue(value?.row?.original?.tg)

    // id: 'name',
    // Cell: ({...props}) => <MonthlyreportInfoCell monthlyreport={props.data[props.row.index]} />,
  },

  {
    Header: (props) => <MonthlyreportCustomHeader tableProps={props} title='TR' className=' fs-5' />,
    accessor: 'tr',
    Cell: (value) => checkDisplayValue(value?.row?.original?.tr)
    // id: 'name',
    // Cell: ({...props}) => <MonthlyreportInfoCell monthlyreport={props.data[props.row.index]} />,
  },

  {
    Header: (props) => <MonthlyreportCustomHeader tableProps={props} title='GMA' className=' fs-5' />,
    accessor: 'gma',
    Cell: (value) => checkDisplayValue(value?.row?.original?.gma)
    // id: 'name',
    // Cell: ({...props}) => <MonthlyreportInfoCell monthlyreport={props.data[props.row.index]} />,
  },

  {
    Header: (props) => <MonthlyreportCustomHeader tableProps={props} title='TU' className=' fs-5' />,
    accessor: 'tracksheet_count',
    Cell: (value) => checkDisplayValue(value?.row?.original?.tracksheet_count)
    // id: 'name',
    // Cell: ({...props}) => <MonthlyreportInfoCell monthlyreport={props.data[props.row.index]} />,
  },

  {
    Header: (props) => <MonthlyreportCustomHeader tableProps={props} title='FG' className=' fs-5' />,
    accessor: 'fg',
    Cell: (value) => checkDisplayValue(value?.row?.original?.fg)
    // id: 'name',
    // Cell: ({...props}) => <MonthlyreportInfoCell monthlyreport={props.data[props.row.index]} />,
  },

  {
    Header: (props) => <MonthlyreportCustomHeader tableProps={props} title='H' className=' fs-5' />,
    accessor: 'help',
    Cell: (value) => checkDisplayValue(value?.row?.original?.help)
    // id: 'name',
    // Cell: ({...props}) => <MonthlyreportInfoCell monthlyreport={props.data[props.row.index]} />,
  },

  {
    Header: (props) => <MonthlyreportCustomHeader tableProps={props} title='ICIW' className=' fs-5' />,
    accessor: 'icaniwill_count',
    Cell: (value) => checkDisplayValue(value?.row?.original?.icaniwill_count)
    // id: 'name',
    // Cell: ({...props}) => <MonthlyreportInfoCell monthlyreport={props.data[props.row.index]} />,
  },
  {
    Header: (props) => <MonthlyreportCustomHeader tableProps={props} title='MR' className=' fs-5' />,
    accessor: 'mr_tracking',
    Cell: (value) => checkDisplayValue(value?.row?.original?.mr_tracking)
    // id: 'name',
    // Cell: ({...props}) => <MonthlyreportInfoCell monthlyreport={props.data[props.row.index]} />,
  },

  {
    Header: (props) => <MonthlyreportCustomHeader tableProps={props} title='Points' className=' fs-5' />,
    accessor: 'total',
    Cell: (value) => checkDisplayValue(value?.row?.original?.total)
  },

  // {
  //   Header: (props) => (
  //     <MonthlyreportCustomHeader tableProps={props} title='Last login' className='' />
  //   ),
  //   id: 'last_login',
  //   Cell: ({...props}) => <MonthlyreportLastLoginCell last_login={props.data[props.row.index].last_login} />,
  // },
  // {
  //   Header: (props) => (
  //     <MonthlyreportCustomHeader tableProps={props} title='Two steps' className='min-w-125px' />
  //   ),
  //   id: 'two_steps',
  //   Cell: ({...props}) => <MonthlyreportTwoStepsCell two_steps={props.data[props.row.index].two_steps} />,
  // },
  // {
  //   Header: (props) => (
  //     <MonthlyreportCustomHeader tableProps={props} title='Joined day' className='min-w-125px' />
  //   ),
  //   accessor: 'joined_day',
  // },
  // {
  //   Header: (props) => (
  //     <MonthlyreportCustomHeader tableProps={props} title='Actions' className='text-end min-w-100px' />
  //   ),
  //   id: 'actions',
  //   Cell: ({...props}) => <MonthlyreportActionsCell id={props.data[props.row.index].id} />,
  // },
]

export { monthlyreportsColumns }
