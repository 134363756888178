import {useQuery} from 'react-query'
import {SummarysessionEditModalForm} from './SummarysessionEditModalForm'
// import {isNotEmpty, QUERIES} from '../../../../../../_metronic/helpers'
import {useListView} from '../core/ListViewProvider'
import { QUERIES, isNotEmpty } from '../../../../../_metronic/helpers'
import { getSummarysessionById } from '../core/_requests'
// import { getSummarysessionById } from '../../../apps/summarysession-management/summarysessions-list/core/_requests'
// import {getSummarysessionById} from '../core/_requests'

const SummarysessionEditModalFormWrapper = () => {
  const {itemIdForUpdate, setItemIdForUpdate} = useListView()
  const enabledQuery: boolean = isNotEmpty(itemIdForUpdate)
  const {
    isLoading,
    data: summarysession,
    error,
  } = useQuery(
    `${QUERIES.MONTHLYREPORTS_LIST}-summarysession-${itemIdForUpdate}`,
    () => {
      return getSummarysessionById(itemIdForUpdate)
    },
    {
      cacheTime: 0,
      enabled: enabledQuery,
      onError: (err) => {
        setItemIdForUpdate(undefined)
        console.error(err)
      },
    }
  )

  if (!itemIdForUpdate) {
    return <SummarysessionEditModalForm isSummarysessionLoading={isLoading} summarysession={{id: undefined}} />
  }

  if (!isLoading && !error && summarysession) {
    return <SummarysessionEditModalForm isSummarysessionLoading={isLoading} summarysession={summarysession} />
  }

  return null
}

export {SummarysessionEditModalFormWrapper}
