import { ID, Response } from "../../../../../_metronic/helpers"

// import {ID, Response} from '../../../../../../_metronic/helpers'
export type Event = {
  id?: ID
  name?: string
  avatar?: string
  email?: string
  position?: string
  role?: string
  event_name?:string
  event_date?:string
  chapter?:string
  venue?:string
  address?:string
 
}

export type EventsQueryResponse = Response<Array<Event>>

export const initialEvent: Event = {
  avatar: 'avatars/300-6.jpg',
  position: 'Art Director',
  role: 'Administrator',
  name: '',
  email: '',

}
