import React, { useRef } from "react";
import { Button, Form } from "react-bootstrap";
import AddEditChatGroup from "./AddEditChatGroup";




const AddChatGroup = () => {
    const closeButton = useRef<any>()
    return (

        <>

            <div className="modal fade" id="addchatgroup" tabIndex={1} aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title text-primary fs-4 fw-bold" id="exampleModalLabel"> Add New Chat Group </h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" ref={closeButton}></button>
                        </div>

                        <AddEditChatGroup closeButton={closeButton} />

                    </div>
                </div>
            </div>

        </>
    )
}
export default AddChatGroup