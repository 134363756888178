import React, {useEffect, useState} from 'react'
import {useNavigate, useParams} from 'react-router-dom'
import {apiUrl} from '../../../apiurl'
import axios from 'axios'
import {checkDisplayValue} from '../../../utils'
import Zoom from 'react-medium-image-zoom'

const API_URL = apiUrl
const ViewChapter = () => {
  const {id} = useParams()
  const [data, setData] = useState<any>({})
  const [isLoading, setIsLoading] = useState(true)
  const VIEWBYID_REMINDER_URL = `${API_URL}/chapters/findOneChapters`

  const navigate = useNavigate()
  const navigateChapterListing = () => {
    // 👇️ navigate to /
    navigate('/chapters')
  }

  useEffect(() => {
    fetchData()
  }, [])

  const fetchData = async () => {
    try {
      const res = await axios.get(`${VIEWBYID_REMINDER_URL}/${id}`)
      setData(res?.data?.responseData ?? {})
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
    }
  }

  const styles = {
    borderRadius: '0.475rem',
    boxShadow: '0 0 50px 0 rgb(82 63 105 / 15%)',
    backgroundColor: '#fff',
    color: '#7e8299',
    fontWeight: '500',
    margin: '0',
    width: 'auto',
    padding: '1rem 2rem',
    top: 'calc(50% - 2rem)',
    left: 'calc(50% - 4rem)',
  }

  if (isLoading) {
    return <div style={{...styles, position: 'absolute', textAlign: 'center'}}>Processing...</div>
  }

  return (
    <>
      <div className='row mx-0 flex-md-block '>
        <div className='col-sm-12 col-md-6 col-lg-6'>
          <h1 className='inv-hst sm-ms-2'> View Chapter </h1>
        </div>
      </div>

      <div className='card  p-8 mt-5 visitor-details'>
        <div className='row pb-10 mb-8 border-bottom border-gray-300'>
          <div className='col-sm-12 col-lg-6 mb-8'>
            <div className='text-dark fs-4 text-start fw-bold '>Name:</div>
            <div className='text-dark fs-4 text-start '>{checkDisplayValue(data?.name)}</div>
          </div>
          {/* <div className='col-sm-12 col-lg-6 mb-8'>
            <div className='text-dark fs-4 text-start fw-bold '>State:</div>
            <div className='text-dark fs-4 text-start  '>
              {checkDisplayValue(data?.state?.name)}
            </div>
          </div>
          <div className='col-sm-12 col-lg-6 mb-8'>
            <div className='text-dark fs-4 text-start fw-bold '>City:</div>
            <div className='text-dark fs-4 text-start  '>{checkDisplayValue(data?.city?.name)}</div>
          </div> */}
          <div className='col-sm-12 col-lg-6 mb-8'>
            <div className='text-dark fs-4 text-start fw-bold '>Created By:</div>
            <div className='text-dark fs-4 text-start  '>{checkDisplayValue(data?.user?.name)}</div>
          </div>
          {/* <div className='col-sm-12 col-lg-6 mb-8'>
            <div className='text-dark fs-4 text-start fw-bold'>Image:</div>
            <div className='text-dark fs-4 text-start'>
              <Zoom>
                <img
                  src={
                    data?.imagename
                      ? `${API_URL}/containers/chapterimages/download/${data?.imagename}`
                      : `${process.env.PUBLIC_URL}/media/icons/duotune/default-user.png`
                  }
                  alt=''
                  width={200}
                  className='thumbnail-img'
                  onError={({currentTarget}: any) => {
                    currentTarget.onerror = null // prevents looping
                    currentTarget.src = `${process.env.PUBLIC_URL}/media/avatars/default-user.png`
                  }}
                />
              </Zoom>
            </div>
          </div> */}
        </div>
        <div className='row'>
          <div className='col-md-12 col-lg-10 text-center pe-6'>
            <button
              type='button'
              className='btn btn-info  waves-effect ms-5 waves-light '
              onClick={navigateChapterListing}
            >
              Back
            </button>
          </div>
        </div>
      </div>
    </>
  )
}
export default ViewChapter
