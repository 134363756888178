import { useState } from 'react'
import AddSection from '../../../AddSection'
import {useListView} from '../../core/ListViewProvider'
// import {LibrarymanagementsListToolbar} from './LibrarymanagementListToolbar'
// import {LibrarymanagementsListGrouping} from './LibrarymanagementsListGrouping'
import {LibrarymanagementsListSearchComponent} from './LibrarymanagementsListSearchComponent'

const LibrarymanagementsListHeader = () => {
  const {selected} = useListView()
  const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
  return (
    <>
    {/* <div className="row flex-md-block mb-5"> */}
                {/* <div className='col-sm-6 col-md-6 col-lg-6'><h1 className='inv-hst sm-ms-2'> Library Management </h1></div> */}

                {/* <div className="" style={{display:"flex",flexDirection:"row-reverse"}}>
                    <button data-bs-toggle="modal"  onClick={()=>setShow(true)}  className="btn btn-primary waves-effect waves-light btn-sm"  > Add Section </button></div>
            <AddSection show={show} handleClose={handleClose} /> */}
            {/* </div> */}
    <div className=''>
      <LibrarymanagementsListSearchComponent />
      {/* begin::Card toolbar */}
      <div className='card-toolbar'> 
        {/* begin::Group actions */}
        {/* {selected.length > 0 ? <LibrarymanagementsListGrouping /> : <LibrarymanagementsListToolbar />} */}
        {/* end::Group actions */}
      </div>
      {/* end::Card toolbar */}
    </div>
    </>
  )
}

export {LibrarymanagementsListHeader}
