import React, { useContext, useEffect, useRef, useState } from 'react'
import { Button, Form, Image, Modal } from 'react-bootstrap'
import TextareaAutosize from 'react-textarea-autosize'
import { toAbsoluteUrl } from '../../../../../../_metronic/helpers'
import { createGoals, createMrTracking, editGoals, listingForManageGoal } from '../_requests'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { UserContext } from '../../../../../../contextStore'
import { formatTargetDate } from './formatTargetDate'
import moment from 'moment'

const ButtonStyle = {
  boxShadow: '0px 1px 2px 0 rgba(0, 0, 0, 0.2)',
}
// Prorps passed for Turday  Auto MR models

export function SubmitMRTracking({
  show,
  setShow,
  reloadSubmitMR = false,

  setReloadSubmitMR = (value: any) => { },
}: any) {
  const [visible, setVisible] = useState(show ? false : true)
  const [manageGoalListing, setManageGoalListing] = useState<any>([])
  const [isLoading, setIsLoading] = useState<any>('')
  const { userDetail, dashBoardReload } = useContext(UserContext)
  const { setMessage } = useContext(UserContext)
  const [manageGoal, setManageGoal] = useState<any>('')
  const [manageGoalId, setManageGoalId] = useState<any>('')
  // Mile Stone Model open with useState.
  const [milestoneModalVisible, setMilestoneModalVisible] = useState(false)
  const closeButton = useRef<any>()
  const [modelClose, setModelClose] = useState(false)
  const [showAddGoals, setShowAddGoals] = useState<any>(false)
  const [editedGoalId, setEditedGoalId] = useState<any>(null)


  useEffect(() => {
    fetchData()
  }, [])

  useEffect(() => {
    if (reloadSubmitMR) {
      fetchData()
      setReloadSubmitMR(false)
    }
  }, [reloadSubmitMR])

  const fetchData = async () => {
    try {
      const listingLeaderData = await listingForManageGoal(userDetail.id)
      setManageGoalListing(() => listingLeaderData ?? [])
      setIsLoading(false)
    } catch (error) {
      console.error('Error -->:', error)
      setIsLoading(false)
    }
  }


  const initialValue = {
    milestones: '',
  }
  // validation
  const validationSchema = Yup.object().shape({
    milestones: Yup.string().required('Milestones is required')
      .max(100, 'Maximum 100 characters allow'),
  })
  const formik = useFormik({
    initialValues: initialValue,
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm }) => {
      try {
        const postBusinessConnectlData = {
          milestones: values.milestones,
          goal_id: manageGoalId,
          isdelete: 0,
          created_by: userDetail.id,
        }

        await createMrTracking(postBusinessConnectlData)
        setMessage('success', 'MR post created')
        setMilestoneModalVisible(false)
        fetchData()
        // show && setShow(false)
        resetForm()
        !milestoneModalVisible && setModelClose(true)
        dashBoardReload()
      } catch (error) {
        setMessage('error', 'Something went wrong')
      }
    },
  })

  const addGoalsFormik = useFormik({
    initialValues: {
      description: '',
      goal: '',
      current_state: '',
      target_date: `${moment()}`,
    },
    validationSchema: Yup.object().shape({
      description: Yup.string().required('Description is required')
        .max(500, 'Maximum 500 characters allow').nullable(),
      current_state: Yup.string().required('Current state is required')
        .max(100, 'Maximum 100 characters allow').nullable(),
      goal: Yup.string().required('Goal is required')
        .max(100, 'Maximum 100 characters allow').nullable(),
      target_date: Yup.date().required('Date is required'),
    }),
    onSubmit: async (values, { resetForm }) => {
      try {
        const postManageGoal = {
          created_by: userDetail.id,
          current_state: values.current_state,
          description: values.description,
          goal: values.goal,
          isdelete: 0,
          target_date: values.target_date,
          user_id: userDetail.id,
        }
        if (editedGoalId) {
          await editGoals(editedGoalId, postManageGoal)
          setMessage('success', 'My Goal is Edited')
          setEditedGoalId(null)
          setTimeout(() => {
            resetForm()
          }, 2000)
        } else {
          await createGoals(postManageGoal)
          setMessage('success', 'My Goal is Created')
          setTimeout(() => {
            resetForm()
          }, 2000)
        }
        await fetchData()
        setManageGoalListing((prevListing: any) =>
          prevListing.map((item: any) =>
            item.id === editedGoalId ? { ...item, ...postManageGoal } : item
          )
        )
        fetchData()
        dashBoardReload()
      } catch (error) {
        console.error('Error -->:', error)
        setIsLoading(false)
      }
    },
  })

  const handleOutsideClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (event.target === event.currentTarget) {
      setShow(false)
      setMilestoneModalVisible(false)
    }
  }

  useEffect(() => {
    const hideDivAfterTimeout = () => {
      setTimeout(() => { }, 7000)
    }

    // Call the function to hide the div after the component mounts
    hideDivAfterTimeout()
  }, [])
  return (
    <>
      <div
        className={`modal fade ilead-default-modal ${show ? 'show modal-dashboard-overlay' : ''} `}
        // className='modal fade ilead-default-modal show'
        id='SubmitMRTrackingModal'
        onClick={handleOutsideClick}
        // onClick={(e) => e.stopPropagation()}
        style={show ? { display: 'block' } : { display: 'none' }}
        aria-hidden='true'
        role='dialog'
        aria-labelledby='SubmitMRTrackingModalLabel'
      >
        <div className='modal-dialog modal-dialog modal-dialog-scrollable modal-fullscreen-md-down modal-md'>
          <div className='modal-content'>
            <div className='modal-header py-5'>
              <h3 className='modal-title fs-3 fw-bold' id='SubmitMRTrackingModalToggleLabel'>
                Submit MR
              </h3>
              {/* if model open with  useState ? withOut Id Button  :   with Id Button */}
              {show ? (
                <button
                  type='button'
                  className='btn-close text-white'
                  // data-bs-target='#write_post_modal'
                  // data-bs-toggle='modal'
                  // data-bs-dismiss='modal'
                  onClick={() => {
                    formik.setFieldValue('milestones', '')
                    formik.resetForm()
                    fetchData()
                    setShow(false)
                    // closeButton.current.click()
                  }}
                  ref={closeButton}
                ></button>
              ) : (
                <button
                  type='button'
                  className='btn-close text-white'
                  data-bs-target='#write_post_modal'
                  data-bs-toggle='modal'
                  data-bs-dismiss='modal'
                  onClick={() => {
                    formik.setFieldValue('milestones', '')
                    formik.resetForm()
                  }}
                  ref={closeButton}
                ></button>
              )}

              {/* <button
                type='button'
                className='btn-close text-white'
                data-bs-target='#write_post_modal'
                data-bs-toggle='modal'
                data-bs-dismiss='modal'
                onClick={() => {
                  formik.setFieldValue('milestones', '')
                  const modalElement = document.getElementById('write_post_modal');
                  if (document.getElementById('SubmitMRTrackingModalLabel') != null) {
                    let str = document.getElementById("SubmitMRTrackingModalLabel");
                   }
                }}
                ref={closeButton}
              ></button> */}
            </div>
            <div className='modal-body pt-7 write-post-wrapper'>
              {manageGoalListing.length > 0 ? (
                <>
                  <div className='goal-listing d-flex align-items-start mb-3 flex-column'>
                    <Form.Label className='fw-bold mb-5'>Today {moment().format('ll')}</Form.Label>
                    {manageGoalListing.map((item: any) => (
                      <div className='goal_box mb-4 p-5 w-100'>
                        <p className='fw-bold fs-6'>
                          My Goal is to {item.description} from {item.current_state} to {item.goal}{' '}
                          by {formatTargetDate(item.target_date)}
                        </p>
                        <hr />
                        <h3 className='fs-6 ilead-text'>Goal: {item.goal}</h3>
                        <hr />
                        <div className='d-flex justify-content-center'>
                          {/* if model open with  useState ? withOut Id Button  :   with Id Button */}
                          {item.mr == null ? (
                            show == true ? (
                              <Button
                                variant='light-primary'
                                className='btn-light-primary'
                                style={ButtonStyle}
                                // data-bs-target='#MileStoneReachedModal'
                                // data-bs-toggle='modal'
                                // data-bs-dismiss='modal'
                                onClick={() => {
                                  setManageGoal(item.goal)
                                  setManageGoalId(item.id)
                                  setMilestoneModalVisible(true)
                                  // setShow(false)
                                }}
                              >
                                Submit MR
                              </Button>
                            ) : (
                              <Button
                                variant='light-primary'
                                className='btn-light-primary'
                                style={ButtonStyle}
                                data-bs-target='#MileStoneReachedModal'
                                data-bs-toggle='modal'
                                data-bs-dismiss='modal'
                                onClick={() => {
                                  setManageGoal(item.goal)
                                  setManageGoalId(item.id)
                                  setMilestoneModalVisible(true)
                                }}
                              >
                                Submit MR
                              </Button>
                            )
                          ) : (
                            <h6>MR : {item?.mr?.milestones}</h6>
                          )}
                          {/* {item.mr == null ? <Button
                          variant='light-primary'
                          className='btn-light-primary'
                          style={ButtonStyle}
                          data-bs-target='#MileStoneReachedModal'
                          data-bs-toggle='modal'
                          data-bs-dismiss='modal'
                          onClick={() => {
                            setManageGoal(item.goal)
                            setManageGoalId(item.id)
                          }}
                        >
                          Submit MR
                        </Button> : <h6>MR : {item?.mr?.milestones}</h6>} */}
                        </div>
                      </div>
                    ))}
                  </div>
                </>
              ) : (
                <div className='single_comment_view d-flex align-items-start py-7'>
                  <div className='empty-comments text-center w-100'>
                    <Image
                      fluid
                      src={toAbsoluteUrl('/media/ilead/mr-empty.png')}
                      className='w-150px mb-4'
                    ></Image>
                    <h4 className='text-gray-900'>Set Goal and Track the Milestone</h4>
                    <span className='text-gray-500 d-block'>
                      Please add your goal first to track the milestone
                    </span>
                    <Button
                      variant='primary'
                      className='mt-7 d-inline px-7 py-3'
                      // data-bs-target='#AddEditGoalModalInSubmitMRModal'
                      data-bs-toggle='modal'
                      // data-bs-dismiss='modal'
                      onClick={() => {
                        setShow(false)
                        setShowAddGoals(true)
                      }}>
                      Add Goal
                    </Button>
                  </div>
                  <Modal show={showAddGoals} onhide={() => {setShowAddGoals(false)}}>
                    <div className='modal-content'>
                      <div className='modal-header py-5' style={{backgroundColor:"#e3371e"}}>
                        <h3 className='modal-title fs-3 fw-bold' style={{color:"white"}}>
                          {'Add'} Goal
                        </h3>
                        <button
                          type='button'
                          className='btn-close text-white'
                          data-bs-target='#ManageGoalModal'
                          data-bs-toggle='modal'
                          data-bs-dismiss='modal'
                          onClick={() => {
                            addGoalsFormik.setFieldValue('description', '')
                            addGoalsFormik.setFieldValue('goal', '')
                            addGoalsFormik.setFieldValue('current_state', '')
                            addGoalsFormik.setFieldValue('target_date', '')
                            addGoalsFormik.resetForm()
                            setShowAddGoals(false)
                          }}
                        ></button>
                      </div>
                      <div className='modal-body pt-7 write-post-wrapper'>
                        <form
                          onSubmit={addGoalsFormik.handleSubmit}
                        // addGoalsFormik.handleSubmit
                        >
                          <Form.Group>
                            <Form.Label className='text-dark fw-semibold required'>My goal is to</Form.Label>
                          </Form.Group>
                          <Form.Group className='mt-7'>
                            <Form.Control
                              as='textarea'
                              rows={10}
                              placeholder='Describe your goal...'
                              className='ilead-input'
                              onChange={(e: any) => {
                                addGoalsFormik.setFieldValue('description', e.target.value)
                              }}
                              value={addGoalsFormik?.values?.description}
                            />
                            {addGoalsFormik?.touched?.description && addGoalsFormik?.errors?.description && (
                              <div className='fv-plugins-message-container text-danger'>
                                <span role='alert'>{addGoalsFormik?.errors?.description as any}</span>
                              </div>
                            )}
                            {/* error msg * Please enter the goal ! */}
                          </Form.Group>
                          <div className='row'>
                            <Form.Group className='mt-7 col-6'>
                              <Form.Label className='text-dark fw-semibold required'>From</Form.Label>
                              <Form.Control
                                type='text'
                                placeholder='Current state'
                                className='ilead-input'
                                onChange={(e: any) => {
                                  addGoalsFormik.setFieldValue('current_state', e.target.value)
                                }}
                                value={addGoalsFormik?.values?.current_state}
                              />
                              {addGoalsFormik?.touched?.current_state && addGoalsFormik?.errors?.current_state && (
                                <div className='fv-plugins-message-container text-danger'>
                                  <span role='alert'>{addGoalsFormik?.errors?.current_state as any}</span>
                                </div>
                              )}
                              {/* error msg * Please enter your current state ! */}
                            </Form.Group>
                            <Form.Group className='mt-7 col-6'>
                              <Form.Label className='text-dark fw-semibold required'>To</Form.Label>
                              <Form.Control
                                type='text'
                                placeholder='Goal'
                                className='ilead-input'
                                onChange={(e: any) => {
                                  addGoalsFormik.setFieldValue('goal', e.target.value)
                                }}
                                value={addGoalsFormik?.values?.goal}
                              />
                              {addGoalsFormik?.touched?.goal && addGoalsFormik?.errors?.goal && (
                                <div className='fv-plugins-message-container text-danger'>
                                  <span role='alert'>{addGoalsFormik?.errors?.goal as any}</span>
                                </div>
                              )}
                              {/* error msg * Please enter your target ! */}
                            </Form.Group>
                          </div>
                          <Form.Group className='mt-7'>
                            <Form.Label className='text-dark fw-semibold required'>By</Form.Label>
                            <Form.Control
                              type='date'
                              placeholder='25 Jul 2023'
                              className='ilead-input'
                              onChange={(e: any) => {
                                addGoalsFormik.setFieldValue('target_date', moment(e.target.value))
                              }}
                              value={
                                addGoalsFormik?.values?.target_date
                                  ? new Date(addGoalsFormik?.values?.target_date).toISOString().split('T')[0]
                                  : ''
                              }
                            />
                            {addGoalsFormik?.touched?.target_date && addGoalsFormik?.errors?.target_date && (
                              <div className='fv-plugins-message-container text-danger'>
                                <span role='alert'>{addGoalsFormik?.errors?.target_date as any}</span>
                              </div>
                            )}
                            {/* error msg * Please select the date! and by default today date will show */}
                          </Form.Group>
                          <Button
                            variant='primary'
                            type='submit'
                            data-bs-toggle={addGoalsFormik.isValid ? 'modal' : undefined}
                            data-bs-dismiss={addGoalsFormik.isValid ? 'modal' : undefined}
                            className='w-100 mt-7'
                            disabled={!addGoalsFormik.dirty || !addGoalsFormik?.values?.goal || !addGoalsFormik?.values?.current_state || !addGoalsFormik?.values?.description}
                          >
                            {'ADD'} GOAL
                          </Button>
                        </form>
                      </div>
                    </div>
                  </Modal>
                </div>
              )}
              {/* <div
                className='modal fade ilead-default-modal'
                id='AddEditGoalModalInSubmitMRModal'
                aria-hidden='true'
                aria-labelledby='AddEditGoalModalModalLabel'
              > */}

              {/* <div className='modal-dialog modal-dialog-scrollable modal-fullscreen-md-down modal-md'> */}

              {/* </div> */}
              {/* </div> */}
              {/* create make goal end */}
            </div>
          </div>
        </div>
      </div>
      {/* Milestone Reached */}
      {/* <div
        className={`modal fade ilead-default-modal ${
          milestoneModalVisible ? 'show modal-dashboard-overlay' : ''
        }`}
        // className='modal fade ilead-default-modal'
        onClick={handleOutsideClick}
        id='MileStoneReachedModal'
        style={milestoneModalVisible ? {display: 'block'} : {}}
        aria-hidden='true'
        aria-labelledby='MileStoneReachedModalLabel'
      > */}
      {/* <div className='modal-dialog modal-md modal-dialog-centered'> */}
      <Modal show={milestoneModalVisible} onHide={() => { setMilestoneModalVisible(false) }} style={{zIndex:10000}}>
        <div className='modal-content'>
          <div className='modal-header py-5'>
            <h3 className='modal-title fs-3 fw-bold' id='MileStoneReachedModalToggleLabel'>
              Milestone Reached
            </h3>
            {/* if model open with  useState ? withOut Id Button  :   with Id Button */}
            {milestoneModalVisible ? (
              <button
                type='button'
                className='btn-close text-white'
                // data-bs-target='#write_post_modal'
                // data-bs-toggle='modal'
                // data-bs-dismiss='modal'
                onClick={() => {
                  setMilestoneModalVisible(false)
                }}
              ></button>
            ) : (
              <button
                type='button'
                className='btn-close text-white'
                data-bs-target='#write_post_modal'
                data-bs-toggle='modal'
                data-bs-dismiss='modal'
                onClick={() => {
                  formik.setFieldValue('milestones', '')
                  formik.resetForm()
                }}
              ></button>
            )}
            {/* <button
                type='button'
                className='btn-close text-white'
                data-bs-target='#write_post_modal'
                data-bs-toggle='modal'
                data-bs-dismiss='modal'
              ></button> */}
          </div>
          <div className='modal-body pt-7 write-post-wrapper'>
            <form
              onSubmit={(e) => {
                formik.handleSubmit(e)
              }}
            >
              <Form.Group>
                <h4 className='ilead-text h5 mb-7'>Goal: {manageGoal}</h4>
              </Form.Group>
              <Form.Group className='mt-7'>
                <TextareaAutosize
                  minRows={4}
                  autoFocus
                  className='custom-text-area form-control fw-normal ilead-input'
                  placeholder='Enter your milestone reached'
                  onChange={(e) => {
                    formik.setFieldValue('milestones', e.target.value)
                  }}
                  value={formik.values.milestones}
                />
                {/* error msg: * Please enter milestone! */}
              </Form.Group>
              {
                formik.touched.milestones &&
                formik.errors.milestones && (
                  <span role='alert' style={{ color: 'red' }}>
                    {formik.errors.milestones as any}
                  </span>
                )}
              <Button
                variant='primary'
                type='submit'
                data-bs-toggle={formik.isValid ? 'modal' : undefined}
                data-bs-dismiss={formik.isValid ? 'modal' : undefined}
                className='w-100 mt-7'
                disabled={!(formik.dirty)}
              >
                SAVE
              </Button>
            </form>
          </div>
        </div>
      </Modal>
      {/* </div>
      </div> */}
    </>
  )
}
