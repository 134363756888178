/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect} from 'react'
import {useMutation, useQueryClient} from 'react-query'
// import {MenuComponent} from '../../../../../../../_metronic/assets/ts/components'
// import {ID, KTIcon, QUERIES} from '../../../../../../../_metronic/helpers'
import {useListView} from '../../core/ListViewProvider'
import {useQueryResponse} from '../../core/QueryResponseProvider'
import {deleteReminder} from '../../core/_requests'
// import { MenuComponent } from '../../../../../../_metronic/assets/ts/components'
// import { ID, KTIcon, QUERIES } from '../../../../../../_metronic/helpers'
import {useNavigate} from 'react-router-dom'
// import EditReminders from '../../../EditReminder'
import {MenuComponent} from '../../../../../../../_metronic/assets/ts/components'
import {ID, QUERIES} from '../../../../../../../_metronic/helpers'

type Props = {
  id: any
}

const ReminderActionsCell: FC<Props> = ({id}) => {
  //   const {setItemIdForUpdate} = useListView()
  const {query} = useQueryResponse()
  const queryClient = useQueryClient()

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  // const openEditModal = () => {
  //   setItemIdForUpdate(id)
  // }

  const deleteItem = async () => {
    console.log('===>id', id)
    await deleteReminder(id)
    queryClient.invalidateQueries([`${QUERIES.REMINDERS_LIST}-${query}`])
  }

  const navigate = useNavigate()
  const navigateViewReminder = () => {
    // 👇️ navigate to /
    navigate(`/view-reminder/${id}`)
  }

  const navigateEditReminder = () => {
    // 👇️ navigate to /
    navigate(`/edit-reminder/${id}`)
  }

  return (
    <>
      <a
        href='#'
        className='btn btn-light-success rounded-circle py-2 px-3 btn-sm me-2'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
        style={{lineHeight: '11px !important'}}
        onClick={navigateViewReminder}
      >
        <i className='fa-regular fa-eye pe-0 fs-6'></i>
      </a>

      <a
        href='#'
        className='btn btn-light-warning rounded-circle py-2 px-3 btn-sm me-2'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
        style={{lineHeight: '11px !important'}}
        onClick={navigateEditReminder}
      >
        <i className='fa-regular fa-pen-to-square pe-0 fs-6'></i>
      </a>

      <a
        href='#'
        className='btn btn-light-danger rounded-circle py-2 px-3 btn-sm'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
        style={{lineHeight: '11px !important'}}
        data-bs-toggle='modal'
        data-bs-target={`#deletReminderModal${id}`}
      >
        <i className='fa-solid fa-trash-can pe-0 fs-6'></i>
      </a>
      {/* begin::Menu */}

      <div
        className='modal fade'
        id={`deletReminderModal${id}`}
        tabIndex={-1}
        aria-labelledby='exampleModalLabel'
        aria-hidden='true'
      >
        <div className='modal-dialog'>
          <div className='modal-content'>
            <div className='modal-body pb-0'>
              <div className='col-12 text-center pt-5 pb-5'>
                <i
                  className='fa-solid fa-trash-can text-danger mb-10'
                  style={{fontSize: '80px'}}
                ></i>
                <h3> Are you sure you want to delete this Reminder? </h3>
              </div>
            </div>
            <div className='modal-footer'>
              <button
                type='button'
                className='btn btn-primary'
                onClick={deleteItem}
                data-bs-dismiss='modal'
              >
                {' '}
                YES{' '}
              </button>
              <button type='button' className='btn btn-secondary' data-bs-dismiss='modal'>
                {' '}
                NO{' '}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export {ReminderActionsCell}
