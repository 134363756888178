// @ts-nocheck
import { Column } from 'react-table'
import { GroupmanagementInfoCell } from './GroupManagementInfoCell'
import { GroupmanagementLastLoginCell } from './GroupManagementLastLoginCell'
import { GroupmanagementTwoStepsCell } from './GroupManagementTwoStepsCell'
import { GroupmanagementActionsCell } from './GroupManagementActionsCell'
import { GroupmanagementSelectionCell } from './GroupManagementSelectionCell'
import { GroupmanagementCustomHeader } from './GroupManagementCustomHeader'
import { GroupmanagementSelectionHeader } from './GroupManagementSelectionHeader'
import { Groupmanagement } from '../../core/_models'
import { checkDisplayValue } from '../../../../../../utils'

const groupmanagementsColumns: ReadonlyArray<Column<Groupmanagement>> = [
  // {
  //   Header: (props) => <GroupmanagementSelectionHeader tableProps={props} />,
  //   id: 'selection',
  //   Cell: ({...props}) => <GroupmanagementSelectionCell id={props.data[props.row.index].id} />,
  // },


  {
    Header: (props) => <GroupmanagementCustomHeader tableProps={props} title='Group Name' className='min-w-125px text-center  ps-2 fs-5' />,
    accessor: 'name',
    // id: 'name',
    Cell: (value) => checkDisplayValue(value?.row?.original?.name),
  },
  {
    Header: (props) => <GroupmanagementCustomHeader tableProps={props} title='Chapter Name' className='min-w-125px text-center fs-5' />,
    accessor: 'chapter',
    // id: 'name',
    Cell: (value) => checkDisplayValue(value?.row?.original?.chapter)
    // Cell: ({...props}) => <GroupmanagementInfoCell groupmanagement={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <GroupmanagementCustomHeader tableProps={props} title='Actions' className=' min-w-150px text-center' />
    ),
    id: 'actions',
    Cell: ({ ...props }) =>
      <GroupmanagementActionsCell id={props.data[props.row.index].id} />,
  },

  // {
  //   Header: (props) => (
  //     <GroupmanagementCustomHeader tableProps={props} title='Last login' className='' />
  //   ),
  //   id: 'last_login',
  //   Cell: ({...props}) => <GroupmanagementLastLoginCell last_login={props.data[props.row.index].last_login} />,
  // },
  // {
  //   Header: (props) => (
  //     <GroupmanagementCustomHeader tableProps={props} title='Two steps' className='min-w-125px' />
  //   ),
  //   id: 'two_steps',
  //   Cell: ({...props}) => <GroupmanagementTwoStepsCell two_steps={props.data[props.row.index].two_steps} />,
  // },
  // {
  //   Header: (props) => (
  //     <GroupmanagementCustomHeader tableProps={props} title='Joined day' className='min-w-125px' />
  //   ),
  //   accessor: 'joined_day',
  // },

]

export { groupmanagementsColumns }
