import {useMemo} from 'react'
import {useTable, ColumnInstance, Row} from 'react-table'
import {CustomHeaderColumn} from './columns/CustomHeaderColumn'
import {CustomRow} from './columns/CustomRow'
import {useQueryResponseData, useQueryResponseLoading} from '../core/QueryResponseProvider'
import {chaptersColumns} from './columns/_columns'
import {Chapter} from '../core/_models'
import {ChaptersListLoading} from '../components/loading/ChaptersListLoading'
import {ChaptersListPagination} from '../components/pagination/ChaptersListPagination'
import {KTCardBody, defaultChapters} from '../../../../../_metronic/helpers'
import {useQueryRequest} from '../core/QueryRequestProvider'
import {checkDisplayValue} from '../../../../../utils'
// import { KTCardBody, defaultChapters } from '../../../../../_metronic/helpers'
// import {KTCardBody} from '../../../../../../_metronic/helpers'

const CopyChapterTable = () => {
  const chapters = useQueryResponseData()
  const isLoading = useQueryResponseLoading()
  const {state, updateState} = useQueryRequest() as any
  const data = useMemo(() => chapters, [chapters])
  const columns = useMemo(() => chaptersColumns, [])
  const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
    columns,
    data,
  })

  return (
    <table
      id='copy_kt_table_chapters'
      className='table align-middle table-row-dashed fs-6 gy-5 dataTable table-striped  no-footer'
      {...getTableProps()}
      style={{display: 'none'}}
    >
      <thead>
        <tr className='text-center text-muted fw-bolder fs-7 text-uppercase gs-0 border-bottom border-gray-100'>
          <td>NAME</td>
          {/* <td>STATE</td>
          <td>CITY</td> */}
          <td>CREATED BY</td>
        </tr>
      </thead>

      <tbody className='text-gray-600 text-center fw-bold' {...getTableBodyProps()}>
        {data.length > 0 ? (
          data.map((row: any, i: any) => {
            return (
              <tr key={i}>
                <td>{checkDisplayValue(row?.name)}</td>
                {/* <td>{checkDisplayValue(row?.state)}</td>
                <td>{checkDisplayValue(row?.city)}</td> */}
                <td>{checkDisplayValue(row?.createdByName)}</td>
              </tr>
            )
          })
        ) : (
          <tr>
            <td colSpan={7}>
              <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                No matching records found
              </div>
            </td>
          </tr>
        )}
      </tbody>
    </table>
  )
}

export {CopyChapterTable}
