import axios, {AxiosResponse} from 'axios'
// import {ID, Response} from '../../../../../../_metronic/helpers'
import {Visitorpanel, VisitorpanelsQueryResponse} from './_models'
import { ID, Response } from '../../../../../_metronic/helpers'
import { apiUrl } from '../../../../../apiurl'
const API_URL = apiUrl
const VISITORPANEL_URL = `${API_URL}/visitorpanel`
const GET_VISITORPANELS_URL = `${API_URL}/invitionhistories/getList`
const CHAPTER_API=`${API_URL}/chapters?filter[where][isdelete]=0`
const EVENTDATE_API =`${apiUrl}/eventdates/`
const USERS_API =`${apiUrl}/users/?filter[where][isdelete]=0&filter[where][isactive]=1`
const CARETE_POST_EVENT = `${apiUrl}/invitionhistories/pastEventsPost`
const SAVE_INVITON = `${apiUrl}/invitionhistories/sendInvitationPost`
const UPDATE_VISITORPANELS_API = `${apiUrl}/invitionhistories`

const getVisitorpanels = (query: string): Promise<VisitorpanelsQueryResponse> => {
  return axios
    .get(`${GET_VISITORPANELS_URL}?${query}`)
    .then((d: AxiosResponse<any>) => d.data).catch((e) => console.log(e))
}

const getevents = (date:any,chapterId:any,ispast:boolean): Promise<VisitorpanelsQueryResponse> => {

  return axios
    .get(`${EVENTDATE_API}?filter[where][event_date][${ispast ? "lte":"gte"}]=${date}&filter[where][chapter_id]=${chapterId}&filter[limit]=4&filter[order]=event_date%20asc`)
    .then((d: AxiosResponse<any>) => d.data).catch((e) => console.log(e))
}
const getUser = (chapterId:any): Promise<VisitorpanelsQueryResponse> => {
  return axios
    .get(`${USERS_API}&filter[where][chapter_id]=${chapterId}`)
    .then((d: AxiosResponse<any>) => d.data).catch((e) => console.log(e))
}

const getVisitorpanelById = (id: ID): Promise<Visitorpanel | undefined> => {
  return axios
    .get(`${VISITORPANEL_URL}/${id}`)
    // .then((response: AxiosResponse<Response<Visitorpanel>>) => response.data).catch((e) => console.log(e))
    .then((response: Response<any>) => response.data).catch((e) => console.log(e))
}

const createVisitorpanel = (visitorpanel: Visitorpanel): Promise<Visitorpanel | undefined> => {
  return axios
    .put(VISITORPANEL_URL, visitorpanel)
    // .then((response: AxiosResponse<Response<Visitorpanel>>) => response.data).catch((e) => console.log(e))
    .then((response: Response<any>) => response.data).catch((e) => console.log(e))
}

const updateVisitorpanel = (visitorpanel: Visitorpanel): Promise<Visitorpanel | undefined> => {
  return axios
    .post(`${VISITORPANEL_URL}/${visitorpanel.id}`, visitorpanel)
    // .then((response: AxiosResponse<Response<Visitorpanel>>) => response.data).catch((e) => console.log(e))
    .then((response: Response<any>) => response.data).catch((e) => console.log(e))
}

const deleteVisitorpanel = (visitorpanelId: ID): Promise<void> => {
  return axios.delete(`${VISITORPANEL_URL}/${visitorpanelId}`).then(() => {})
}

const deleteSelectedVisitorpanels = (visitorpanelIds: Array<ID>): Promise<void> => {
  const requests = visitorpanelIds.map((id) => axios.delete(`${VISITORPANEL_URL}/${id}`))
  return axios.all(requests).then(() => {})
}

const eventPastCreate = (visitorpanel: any): Promise<Visitorpanel | undefined> => {
  return axios
    .post(CARETE_POST_EVENT, visitorpanel)
    // .then((response: AxiosResponse<Response<Visitorpanel>>) => response.data).catch((e) => console.log(e))
    .then((res: any) => {
      return res
    })
}

const sendInvitation = (visitorpanel: any): Promise<any | undefined> => {
  return axios
    .post(SAVE_INVITON, visitorpanel)
    // .then((response: AxiosResponse<Response<Visitorpanel>>) => response.data).catch((e) => console.log(e))
    .then((res: any) => {
      return res
    })
}

const UpdateInvitationsAPI= (visitorpanel: any): Promise<Visitorpanel | undefined> => {
  return axios
    .post(`${UPDATE_VISITORPANELS_API}/changeinvitedstatus`, visitorpanel)
    // .then((response: AxiosResponse<Response<Visitorpanel>>) => response.data).catch((e) => console.log(e))
    .then((res: any) => {
      return res
    })
}

const UpdateMemberStatusAPI= (visitorpanel: any): Promise<Visitorpanel | undefined> => {
  return axios
    .post(`${UPDATE_VISITORPANELS_API}/changemembershipstatus`, visitorpanel)
    // .then((response: AxiosResponse<Response<Visitorpanel>>) => response.data).catch((e) => console.log(e))
    .then((res: any) => {
      return res
    })
}

export {getVisitorpanels, deleteVisitorpanel,getevents,getUser,UpdateInvitationsAPI,UpdateMemberStatusAPI,sendInvitation,eventPastCreate, deleteSelectedVisitorpanels, getVisitorpanelById, createVisitorpanel, updateVisitorpanel}
