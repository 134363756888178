import axios from 'axios'
import { apiUrl } from '../../../apiurl'
const API_URL = apiUrl
const TYCA_URL = `${API_URL}/userpresentations`
const IMAGE_UPLOAD = `${API_URL}/containers/topicimages/upload`
const TYCA_URL_Post = `${API_URL}/tycaquestions`
const User_URL = `${API_URL}/users`
const SEARCHLEADER = `${API_URL}/users/searchTycaLeader`
const EVENTDATES = `${API_URL}/eventdates/getEventDates`
const ADDNEWTYCA = `${API_URL}/tycaquestions/addnewtyca`
const FINDONETYCA = `${API_URL}/userpresentations`
const DELETETYCAPDF = `${API_URL}/tycaquestions/deleteTycaQuestion`
const UPDATE_TYCA = `${API_URL}/tycaquestions/updatetyca`
const GET_GROUPMANAGEMENTS_URL = `${API_URL}/groups/getGroupListing`
const ADD_GROUP_DETAILS_URL = `${API_URL}/tycaquestions/addtycatimeslot`


export function UploadFiile(formData: any) {
  return axios
    .patch<any>(`${IMAGE_UPLOAD}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then((responce: any) => {
      return responce.data
    })
    .catch((error) => {
      console.error('Error All  Profile upData :', error)
      throw error
    })
}
export function TycaQuestionsPost(body: any) {
  return axios
    .post<any>(`${TYCA_URL_Post}/questions`, body, {
      // headers: new HttpHeaders().set('Content-Type', 'application/json')
      // .set('accesstoken', localStorage.getItem('token'))
    })
    .then((responce: any) => {
      return responce
    })
    .catch((error) => {
      console.error('Error All  Profile upData :', error)
      throw error
    })
}

export function userProfileDetials(userid: any,) {
  return axios
    .get<any>(`${User_URL}/userprofile`)
    .then((responce: any) => {
      return responce
    })
    .catch((error) => {
      console.error('Error All  Profile upData :', error)
      throw error
    })
}

export function searchLeaderDetail(chapter_id: any, q: any) {
  return axios
    .get<any>(`${SEARCHLEADER}?chapter_id=${chapter_id}&q=${q}`)
    .then((responce: any) => {
      return responce
    })
    .catch((error) => {
      console.error('Error All  Profile upData :', error)
      throw error
    })
}

export function eventDates(chapter_id: any) {
  return axios
    .get<any>(`${EVENTDATES}?chapter_id=${chapter_id}`)
    .then((responce: any) => {
      return responce
    })
    .catch((error) => {
      console.error('Error All  Profile upData :', error)
      throw error
    })
}

export function addTYCAQuestion(body: any) {
  return axios
    .post<any>(`${ADDNEWTYCA}`, body)
    .then((responce: any) => {
      return responce
    })
    .catch((error) => {
      console.error('Error All  Profile upData :', error)
      throw error
    })
}

export function addGroupDetails(body: any) {
  return axios
    .post<any>(`${ADD_GROUP_DETAILS_URL}`, body)
    .then((responce: any) => {
      return responce
    })
    .catch((error) => {
      console.error('Error All  Profile upData :', error)
      throw error
    })
}

export function deleteTycaPdfFile(body: any) {
  return axios
    .post<any>(`${DELETETYCAPDF}`, body)
    .then((responce: any) => {
      return responce
    })
    .catch((error) => {
      console.error('Error All  Profile upData :', error)
      throw error
    })
}


export function findOneTyca(id: any) {
  return axios
    .get(`${FINDONETYCA}/${id}`)
    // .then((response: AxiosResponse<Response<Librarymanagement>>) => response.data).catch((e) => console.log(e))
    .then((response: any) => response.data).catch((e) => console.log(e))
}

export function GroupListAPI(chapter_id: any, q: any) {
  return axios
    .get<any>(`${GET_GROUPMANAGEMENTS_URL}?chapter_id=${chapter_id}&isdelete=0`)
    .then((responce: any) => {
      return responce
    })
    .catch((error) => {
      console.error('Error All  GroupListAPI upData :', error)
      throw error
    })
}


export function updateTYCA(id: any, body: any) {
  return axios
    .patch<any>(`${UPDATE_TYCA}/${id}`, body)
    .then((responce: any) => {
      return responce
    })
    .catch((error) => {
      console.error('Error :', error)
      throw error
    })
}