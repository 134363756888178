import React, { useEffect, useRef, useState } from 'react'
import { Image } from 'react-bootstrap'
import { toAbsoluteUrl } from '../../../../../_metronic/helpers'
import { apiUrl } from '../../../../../apiurl'
import { checkDisplayValue } from '../../../../../utils'
import { Link, NavLink, useNavigate } from 'react-router-dom'
import Zoom from 'react-medium-image-zoom'

import { useReward } from 'react-rewards'
const API_URL = apiUrl

const useInterval = (callback: () => void) => {
  const callbackRef = useRef(() => { })

  useEffect(() => {
    callbackRef.current = callback
  }, [callback])

  useEffect(() => {
    const timerId = setInterval(() => callbackRef.current(), 100)
    return () => clearInterval(timerId)
  }, [])
}

const AnimationStyle = {
  width: '100%',
  display: 'flex',
  alignItems: 'flex-end',
  justifyContent: 'space-between',
  zIndex: '1',
}
export function ViewPost({ businessrefrral, item }: any) {
  const navigate = useNavigate()
  const [isShowMore, setIsShowMore] = useState(false)

  const toggleReadMoreLess = () => {
    setIsShowMore(!isShowMore)
  }

  const { reward: rewardLeft, isAnimating: isAnimatingLeft } = useReward(
    `rewardLeft-${item.id}`,
    `${item.topic_id == 15 ? 'balloons' : 'confetti'}`,
    {
      angle: 45,
      position: 'absolute',
      spread: 45,
      decay: 0.96,
      startVelocity: 50,
      // elementCount: 105,
      // elementSize: 7,
    }
  )
  const { reward: rewardRight, isAnimating: isAnimatingRight } = useReward(
    `rewardRight-${item.id}`,
    `${item.topic_id == 15 ? 'balloons' : 'confetti'}`,
    {
      angle: 135,
      position: 'absolute',
      spread: 45,
      decay: 0.96,
      startVelocity: 50,
      // elementCount: 105,
      // elementSize: 7,
    }
  )
  const { reward: balloonLeft, isAnimating: isAnimatingballoonLeft } = useReward(
    `balloonLeft-${item.id}`,
    'balloons',
    {
      angle: -45,
      position: 'absolute',
      spread: 50,
      decay: 0.99999999,
      startVelocity: 10,
      elementCount: 4,
      elementSize: 15,
    }
  )
  const { reward: balloonRight, isAnimating: isAnimatingBalloonRight } = useReward(
    `balloonRight-${item.id}`,
    'balloons',
    {
      angle: -135,
      position: 'absolute',
      spread: 50,
      decay: 0.99999999,
      startVelocity: 10,
      elementCount: 4,
      elementSize: 17,
    }
  )

  useInterval(() => {
    if (!isAnimatingRight && !isAnimatingLeft) {
      rewardLeft()
      rewardRight()
      balloonRight()
      balloonLeft()
    }
  })
  // string to link convert

  const getMeetingPassword = (url: any) => {
    const zoomLink = url;

    // Define a regex pattern to extract the password
    const regexPattern = /pwd=([^&]+)/;

    // Use the regex pattern to match and extract the password
    const match = zoomLink.match(regexPattern);

    if (match && match[1]) {
      const meetingPassword = match[1];
      return meetingPassword
    } else {
      return null
    }
  }

  const getMeetingId = (url: any) => {
    // Sample Zoom link
    const zoomLink = url;

    // Define a regex pattern to extract the meeting ID
    const regexPattern = /j\/(\d+)/;

    // Use the regex pattern to match and extract the meeting ID
    const match = zoomLink.match(regexPattern);

    if (match && match[1]) {
      const meetingID = match[1];
      return meetingID
    } else {
      return null
    }
  }

  const extractVideoID = (youtubeUrl: string) => {
    const pattern = /(?:youtu\.be\/|youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=))([^"&?\/\s]{11})/;
    const match = youtubeUrl.match(pattern);
    const id = match ? match[1] : null;
    console.log("match===>",id)
    return id
  }

  const convertTextToLinks = (text: any) => {
    const urlRegex = /(https?:\/\/[^\s]+|www[^\s]+)/
    const parts = text.split(urlRegex)

    return parts.map((part: any, index: any) => {
      if (part.match(urlRegex)) {
        let url = part

        if (part.startsWith('https://www.zoom.us') || part.startsWith('https://zoom.us')) {
          if (getMeetingId(url) && getMeetingPassword(url)) {
            return <NavLink to={`/zoom-meeting/${getMeetingId(url)}/${getMeetingPassword(url)}`} >{url}</NavLink>
          }
          else if (getMeetingId(url)) {
            return <NavLink to={`/zoom-meeting/${getMeetingId(url)}`} >{url}</NavLink>
          }
          else {
            <a key={index} href={url} target='_blank' rel='noopener noreferrer'>
              {part}
            </a>
          }
        }
        else if (!part.includes('youtu')) {
          if (url.startsWith('www.')) {
            url = 'http://' + part
          }
          return (
            <a key={index} href={url} target='_blank' rel='noopener noreferrer'>
              {part}
            </a>
          )
        } else {
          // Set isYtURL to true if it's a YouTube link
          // setYtURL(true);
          const videoID = extractVideoID(part)
          if (videoID) {
            const embedURL = `//www.youtube.com/embed/${videoID}`
            console.log("embedURL", embedURL)
            return (
              <div key={index}>
                <a href={part} target='_blank' rel='noopener noreferrer'>
                  {part}
                </a>
                <iframe
                  title='video'
                  width='450'
                  height='250'
                  src={embedURL}
                  frameBorder='0'
                  allowFullScreen
                ></iframe>
              </div>
            )
          }
        }
      } else {
        return <span key={index}>{part}</span>
      }
    })
  }

  const getJsonDataReferralName = () => {
    try {
      return JSON.parse(item?.others ?? "")?.referralName
    }
    catch (err) {
      return "-"
    }
  }

  const getJsonDataWorkRequired = () => {
    try {
      return JSON.parse(item?.others ?? "")?.workRequired
    }
    catch (err) {
      return "-"
    }
  }

  const getZoomData = (itemKey: any) => {
    try {
      return item?.zoom_description?.[itemKey] ?? "-"
    }
    catch (err) {
      return "-"
    }
  }


  return (
    <>
      <div
        className={`fs-7 fs-md-6 fw-normal text-black ${item?.description?.trim() == 'Top GI Performers' ||
          item?.description?.trim() == 'Top BI Performers'
          ? ''
          : 'mb-5 px-6'
          }`}
      >
        <div className=''>
          {item?.topic_id == 25 ? (
            <>
              <div style={{ whiteSpace: "pre-line" }}>
                {convertTextToLinks(checkDisplayValue(item?.description ?? ""))}
                <div className='row'>
                  <div className='col-md-12 col-lg-12 fs-6 text-start p-2 border-bottom mb-3'>
                    <div className=' '> <i className="fa-solid fa-laptop fs-4 text-primary"></i> Event Title: </div>
                    <div className='text-truncate '>  <span className='fw-bold fs-5  ps-7'> {getZoomData('topic_name')} </span></div>
                  </div>

                  <div className='col-md-12 col-lg-6 text-start p-2 border-bottom mb-3'>
                    <div className=' '> <i className="fa-solid fa-calendar-day fs-4 text-primary"></i> Event Date and Time: </div>
                    <div className=''>  <span className='fw-bold fs-5  ps-6'> {getZoomData('event_date_time')} </span></div>
                  </div>

                  <div className='col-md-12 col-lg-6 text-start p-2 border-bottom mb-3'>
                    <div className=' '> <i className="fa-solid fa-hourglass-start fs-4 text-primary"></i> Event Duration: </div>
                    <div className=''>  <span className='fw-bold fs-5  ps-6'> {getZoomData('event_duration')} </span></div>
                  </div>

                  <div className='col-md-12 col-lg-6 text-start p-2 border-bottom mb-3'>
                    <div className=' '> <i className="fa-solid fa-user fs-4 text-primary"></i> Zoom Meeting Id: </div>
                    <div className=''>  <span className='fw-bold fs-5  ps-6'> {getZoomData('zoom_id')} </span></div>
                  </div>

                  <div className='col-md-12 col-lg-6 text-start p-2 border-bottom mb-3'>
                    <div className=' '> <i className="fa-solid fa-key fs-4 text-primary"></i> Zoom Meeting Password: </div>
                    <div className=''>  <span className='fw-bold fs-5  ps-6'> {getZoomData('zoom_password')} </span></div>
                  </div>

                  <div className='row justify-content-between text-start align-items-center text-start p-2 border-bottom mb-3'>
                    <div className='col-md-9 col-sm-12'>
                      <div className=' '> <i className="fa-solid fa-link fs-4 text-primary"></i> Zoom Meeting URL: </div>
                      <div className=''>
                        <div className='text-truncate w-100'>
                          <NavLink to={`/zoom-meeting/${getZoomData('zoom_id')}/${getZoomData('zoom_password')}`} className="fw-bold  fs-5 ps-6"> {getZoomData('zoom_meeting_url')} </NavLink>
                        </div>
                      </div>
                    </div>
                    <div className='ps-10 col-md-3 col-sm-12 ps-md-0 text-start text-sm-end mt-5 mt-sm-0'>
                      <button type="button" className='btn btn-primary btn-sm text-nowrap'
                        onClick={() => {
                          navigate(`/zoom-meeting/${getZoomData('zoom_id')}/${getZoomData('zoom_password')}`)
                        }}>
                        Join Now
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : item?.description?.trim() == 'Business referral' || item?.topic_id == 20 ? (
            <>
              <div className='d-flex'>
                <div className='post-content pe-7 flex-1'>
                  <Link
                    to={`/leaders/${item?.businessreferralsendUser?.id}`}
                    className='leaderlink'
                  >
                    Lr. {item?.businessreferralsendUser?.name}
                  </Link>{' '}
                  gave Business Referral to{' '}
                  <Link
                    to={`/leaders/${item?.businessreferralreceiveruser?.id}`}
                    className='leaderlink'
                  >
                    Lr. {item?.businessreferralreceiveruser?.name}
                  </Link>
                  <div>
                    Referral Name : <b>{getJsonDataReferralName()}</b>
                  </div>
                  <div>
                    Work Required : <b>{getJsonDataWorkRequired()}</b>{' '}
                  </div>
                </div>

                <div className='thankx_image'>
                  <div className='symbol symbol-md-55px symbol-md-55px symbol-circle'>
                    <Image
                      src={
                        item?.user?.profile
                          ? `${API_URL}/containers/userprofile/download/${item?.businessreferralreceiveruser?.profile}`
                          : `${toAbsoluteUrl('./media/avatars/default-user.png')}`
                      }
                      onError={({ currentTarget }: any) => {
                        currentTarget.onerror = null // prevents looping
                        currentTarget.src = `${toAbsoluteUrl('./media/avatars/default-user.png')}`
                      }}
                    ></Image>
                  </div>
                </div>
              </div>
            </>
          ) : item?.topic_id == 7 ? (
            <>
              <div className='d-flex'>
                <div className='post-content pe-7 flex-1'>
                  {/* {isShowMore ? ( */}
                  <span>
                    I express sincere gratitude to{' '}
                    <Link to={`/leaders/${item?.thankyoureceiveUser?.id}`} className='leaderlink'>
                      Lr. {item?.thankyoureceiveUser?.name}
                    </Link>
                    <br />
                    {item?.description}
                  </span>
                  <br />
                </div>
                <div className='thankx_image'>
                  <div className='symbol symbol-md-55px symbol-md-55px symbol-circle'>
                    <Image
                      src={
                        item?.user?.profile
                          ? `${API_URL}/containers/userprofile/download/${item?.thankyoureceiveUser?.profile}`
                          : `${toAbsoluteUrl('./media/avatars/default-user.png')}`
                      }
                      onError={({ currentTarget }: any) => {
                        currentTarget.onerror = null // prevents looping
                        currentTarget.src = `${toAbsoluteUrl('./media/avatars/default-user.png')}`
                      }}
                    ></Image>
                  </div>
                </div>
              </div>
            </>
          ) : item?.description?.trim() == 'Business connect' || item?.topic_id == 21 ? (
            <>
              <div className='d-flex'>
                <div className='post-content pe-7 flex-1'>
                  <Link to={`/leaders/${item?.businessConnectsendUser?.id}`} className='leaderlink'>
                    Lr. {item?.businessConnectsendUser?.name}
                  </Link>{' '}
                  conducted a business connect meeting for{' '}
                  <Link
                    to={`/leaders/${item?.businessConnectreceiveruser?.id}`}
                    className='leaderlink'
                  >
                    Lr. {item?.businessConnectreceiveruser?.name}
                  </Link>
                  <br />
                  Business connect details :{' '}
                  <b>
                    {JSON.parse(item?.others ?? "")?.name}, {JSON.parse(item?.others ?? "")?.companyName}
                  </b>
                </div>

                <div className='thankx_image'>
                  <div className='symbol symbol-md-55px symbol-md-55px symbol-circle'>
                    <Image
                      src={
                        item?.user?.profile
                          ? `${API_URL}/containers/userprofile/download/${item?.businessConnectreceiveruser?.profile}`
                          : `${toAbsoluteUrl('./media/avatars/default-user.png')}`
                      }
                      onError={({ currentTarget }: any) => {
                        currentTarget.onerror = null // prevents looping
                        currentTarget.src = `${toAbsoluteUrl('./media/avatars/default-user.png')}`
                      }}
                    ></Image>
                  </div>
                </div>
              </div>
            </>
          ) : item?.description?.trim() == 'Business generated' || item?.topic_id == 22 ? (
            <>
              <div className='d-flex'>
                <div className='post-content pe-7 flex-1'>
                  {/* {isShowMore ? ( */}
                  <span>
                    Gratitude to{' '}
                    <Link
                      to={`/leaders/${item?.businessGeneratereceiveruser?.id}`}
                      className='leaderlink'
                    >
                      Lr. {item?.businessGeneratereceiveruser?.name}
                    </Link>{' '}
                    for giving me business.
                  </span>
                </div>
                <div className='thankx_image'>
                  <div className='symbol symbol-md-55px symbol-md-55px symbol-circle'>
                    <Image
                      src={
                        item?.user?.profile
                          ? `${API_URL}/containers/userprofile/download/${item?.businessGeneratereceiveruser?.profile}`
                          : `${toAbsoluteUrl('./media/avatars/default-user.png')}`
                      }
                      onError={({ currentTarget }: any) => {
                        currentTarget.onerror = null // prevents looping
                        currentTarget.src = `${toAbsoluteUrl('./media/avatars/default-user.png')}`
                      }}
                    ></Image>
                  </div>
                </div>
              </div>
            </>
          ) : item?.description?.trim() == 'Top GI Performers' ||
            item?.description?.trim() == 'Top BI Performers' ? (
            <>
              <div className='card-body pt-0 pb-0 ps-0 pe-0  position-relative overflow-hidden'>
                <div className='media'>
                  <div style={AnimationStyle} className='position-absolute w-100 h-100'>
                    <div className='popper'>
                      <span id={`rewardLeft-${item?.id}`} />
                    </div>
                    <div className='popper'>
                      <span id={`rewardRight-${item?.id}`} />
                    </div>
                  </div>

                  <div>
                    <div className='winner-title d-flex flex-column'>
                      <b style={{ fontWeight: 'bold' }}> {checkDisplayValue(item?.description)}</b>
                      <div className='ribbon' style={{ backgroundColor: 'green' }}>
                        {JSON.parse(item?.others ?? "")?.month}
                      </div>
                    </div>
                  </div>

                  <div className='winner-wrapper'>
                    {JSON.parse(item?.others ?? "")?.data?.map((item: any, index: any) => (
                      <>
                        <div className='leader'>
                          <div className='leader-avatar'>
                            <Zoom>
                              <Image
                                style={{ width: '100%' }}
                                src={
                                  item?.profile
                                    ? `${API_URL}/containers/userprofile/download/${item?.profile}`
                                    : `${toAbsoluteUrl('./media/avatars/default-user.png')}`
                                }
                                onError={({ currentTarget }: any) => {
                                  currentTarget.onerror = null // prevents looping
                                  currentTarget.src = `${toAbsoluteUrl(
                                    './media/avatars/default-user.png'
                                  )}`
                                }}
                              ></Image>
                            </Zoom>
                          </div>
                          <div className='leader-details'>
                            <h4>{item?.name}</h4>
                            <h3>{item?.total_points}</h3>
                          </div>
                        </div>
                      </>
                    ))}
                  </div>
                </div>
              </div>
            </>
          ) : item?.topic_id == 15 ? (
            <>
              <span>{checkDisplayValue(item?.description)}</span>
              {/* <div className='card-body pt-0 pb-0 ps-0 pe-0  position-relative'>
                <div className='media postion-relative overflow-hidden'>
                  <div style={AnimationStyle} className='position-absolute w-100 h-100'>
                    <div className='popper'>
                      <span id={`balloonLeft-${item.id}`} />
                    </div>
                    <div className='popper'>
                      <span id={`balloonRight-${item.id}`} />
                    </div>
                  </div>
                </div>
              </div>               */}
            </>

          ) : (
            <>{convertTextToLinks(checkDisplayValue(item?.description))}</>
          )}
        </div>
      </div>
      {/* end:post */}
    </>
  )
}

