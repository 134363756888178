import { ID, Response } from "../../../../../_metronic/helpers"

// import {ID, Response} from '../../../../../../_metronic/helpers'
export type Visitorpanel = {
  id?: ID
  name?: string
  avatar?: string
  email?: string
  position?: string
  role?: string
  event_date?: string
  invitee_name?: string
  invited_by?: string
  cell_no?: string
  company?: string
  chapter?: string
  
 
}

export type VisitorpanelsQueryResponse = Response<Array<Visitorpanel>>

export const initialVisitorpanel: Visitorpanel = {
  avatar: 'avatars/300-6.jpg',
  position: 'Art Director',
  role: 'Administrator',
  name: '',
  email: '',

}
