import {useQuery} from 'react-query'
import {ReminderEditModalForm} from './ReminderEditModalForm'
// import {isNotEmpty, QUERIES} from '../../../../../../_metronic/helpers'
import {useListView} from '../core/ListViewProvider'
// import { QUERIES, isNotEmpty } from '../../../../../_metronic/helpers'
import { getReminderById } from '../core/_requests'
import { QUERIES, isNotEmpty } from '../../../../../../_metronic/helpers'
// import { getReminderById } from '../../../apps/reminder-management/reminders-list/core/_requests'
// import {getReminderById} from '../core/_requests'

const ReminderEditModalFormWrapper = () => {
  const {itemIdForUpdate, setItemIdForUpdate} = useListView()
  const enabledQuery: boolean = isNotEmpty(itemIdForUpdate)
  const {
    isLoading,
    data: reminder,
    error,
  } = useQuery(
    `${QUERIES.REMINDERS_LIST}-reminder-${itemIdForUpdate}`,
    () => {
      return getReminderById(itemIdForUpdate)
    },
    {
      cacheTime: 0,
      enabled: enabledQuery,
      onError: (err) => {
        setItemIdForUpdate(undefined)
        console.error(err)
      },
    }
  )

  if (!itemIdForUpdate) {
    return <ReminderEditModalForm isReminderLoading={isLoading} reminder={{id: undefined}} />
  }

  if (!isLoading && !error && reminder) {
    return <ReminderEditModalForm isReminderLoading={isLoading} reminder={reminder} />
  }

  return null
}

export {ReminderEditModalFormWrapper}
