import React,{useContext, useEffect, useRef, useState} from "react";
import { Button, FloatingLabel, Form } from "react-bootstrap";
import { useNavigate } from "react-router";
import { useFormik } from "formik";
import * as Yup from 'yup'
import { eventPastCreate, getevents,getUser,sendInvitation } from "./visitorpanels-list/core/_requests";
import { formatDate } from "../../../utils";
import { UserContext } from "../../../contextStore";
import moment from "moment";
import { useQueryResponse } from "./visitorpanels-list/core/QueryResponseProvider";


const phoneRegExp = /^\d{10}$/

const AddDetails = () => {
  const { refetch } = useQueryResponse()
  const [changesFrom,setChangesFrom] =useState(true)
  const [eventList, setEventList] = useState<any>([])
  const [userList, setUserList] = useState<any>([])
  const { userDetail, chapterID, reload ,setMessage} = useContext(UserContext);
  const today = moment().format('YYYY-MM-DD')
  const closeButton =useRef<any>(null)
 
  const navigate = useNavigate()
  const navigateVisitorPanel = () => {
    // 👇️ navigate to /
    navigate('/visitor-panel')
  }

  const editLibrarymanagementSchema = Yup.object().shape({
    event_data: Yup.string()
      .required('Please enter date.'),
    name : Yup.string().required("required").min(3, 'Name must be more than 2 characters.').max(50, 'Name must be less than 50 characters.'),
    // comapanyname: Yup.string().required("required").min(3, 'Company Name must be more than 2 characters.').max(50, 'Name must be less than 50 characters.'),
    invited_by:  Yup.string().required("required"),
    email:  Yup.string().email("Email must be valid"),
    comapanyname:  Yup.string(),
    // email : Yup.string().required("required").email("Email must be valid"),
    mobileno : Yup.string().required("required").matches(phoneRegExp, 'Phone number is not valid'),
  })
  const InitData={
    event_data : '',
    name: '',
    comapanyname:"",
    invited_by:"",
    email:"",
    mobileno:""
  }

 const getEventList= async ()=>{
  const chapterData= await getevents(today,chapterID,!changesFrom)
  setEventList(chapterData ?? [])
  // setChapterdata(chapterData ?? [])
 }

 const getUserList= async ()=>{
  const userData= await getUser(chapterID)
  setUserList(userData ?? [])
  // setChapterdata(chapterData ?? [])
 }

  const formik = useFormik({
    initialValues: InitData,
    validationSchema: editLibrarymanagementSchema,
    onSubmit: async (values, {setSubmitting,resetForm}) => {
      setSubmitting(true)
      try {
        if (!changesFrom) {
          await eventPastCreate(values).then((res:any)=>{
            console.log("res",res?.data)
            if(res?.data?.id ){
              resetForm()
              refetch()
              // navigate('/visitor-panel')
              closeButton.current.click()
              setMessage('success', 'Invitations send successfully')
              // handleClose()
            }
          }).catch((error:any)=>{
            console.log("error",error)
            if (error?.response?.data?.error?.message) {
              console.log("error?.response?",error?.response?.status)
              if(error?.response?.status == 500 )
              {
              setMessage('error', 'Something went wrong')
              }else{

                setMessage('error', error?.response?.data?.error?.message)
              }
            } else {
              setMessage('error', 'Something went wrong')
            }
          })
        } else {

          await sendInvitation({...values,...{cellnumber:values.mobileno,event_id:values.event_data } }).then((res:any)=>{
            console.log("res",res.data)
            if(res?.data?.id ){
              resetForm()
              closeButton.current.click()
              refetch()
              // navigate('/visitor-panel')
              setMessage('success', 'Invitations send successfully')
              // handleClose()
            }
          }).catch((error:any)=>{
            console.log("error",error)
            if (error?.response?.data?.error?.message) {
              console.log("error?.response?",error?.response?.status)
              if(error?.response?.status == 500 )
              {
              setMessage('error', 'Something went wrong')
              }else{

                setMessage('error', error?.response?.data?.error?.message)
              }
            } else {
              setMessage('error', 'Something went wrong')
            }
          })
        }
        
      } catch (ex) {
        console.error(ex)
      } finally {
        setSubmitting(true)
        // cancel(true)
      }
    },
  })


  useEffect (()=>{
    formik.resetForm()
    getEventList()
    getUserList()

  },[changesFrom])

  useEffect(()=>{
    getEventList()
    getUserList()
  },[])
  console.log("eventList",eventList)
  return (

    <>

      <div
        className='modal fade visitor-details'
        id='adddetails'
        data-bs-backdrop='static'
        data-bs-keyboard='false'
        tabIndex={-1}
        aria-labelledby='staticBackdropLabel'
        aria-hidden='true'
      >
        <div className='modal-dialog me-0 mb-0 mt-0 border-radius-0'>
          <div className='modal-content '>

            <div className='modal-header border-bottom border-gray-300'>
              <h5 className='modal-title text-primary' id='staticBackdropLabel'>
                Sent Invitation/Add Details
              </h5>
              <button
                type='button'
                className='btn-close'
                data-bs-dismiss='modal'
                aria-label='Close'
                ref={closeButton}
              ></button>
            </div>

      <div className='card  p-8 mt-5 visitor-details ilead__card'>
        <nav>
          <div className="nav nav-tabs" id="nav-tab" role="tablist">
            <button className="nav-link col-6 active" id="nav-home-tab" data-bs-toggle="tab"  onClick={()=>{setChangesFrom(true)}}type="button" role="tab" aria-controls="nav-home" aria-selected="true">Send Invitation</button>
            <button className="nav-link col-6"  id="nav-profile-tab" data-bs-toggle="tab" onClick={()=>{setChangesFrom(false)}} type="button" role="tab" aria-controls="nav-profile" aria-selected="false">Past Events</button>

          </div>
        </nav>

      
        <div className="tab-content" id="nav-tabContent">
          <div className="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
            {
              changesFrom ? (<form  action='' onSubmit={formik.handleSubmit} noValidate>
                <div className="col-lg-8 ms-auto me-auto border border-gray-600 p-8 mt-8">
                  <h3 className="mb-6 text-dark "> Use Below Form To Send An Invitation. </h3>
                  <div className="form-floating mb-8">
                    <select className="form-select" onChange={(event:any)=>{
                      formik.setFieldValue('event_data', event.target.value.trimStart())
                    }} id="floatingSelect" aria-label="Floating label select example" required>
                      <option value=''> Event Date <span className="text-danger"> * </span></option>
                      {
                        eventList && eventList?.map ((item:any,i:any)=>{
                          return (<option value={item?.id} selected={item?.id == formik?.values?.event_data ? true :false}key={i}>{formatDate(item?.event_date)} </option>)
                        })
                      }
                      {/* <option value="1"> 23-01-20 </option>
                      <option value="2"> 27-05-2021 </option> */}
                    </select>
                    {formik.touched.event_data && formik?.errors.event_data && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors?.event_data}</span>
                      </div>
                    </div>
                    )}
                  </div>
  
                  <div className="form-floating mb-8">
                    <input type="text" className="form-control"
                    value={formik?.values?.name}
                    onChange={(event:any)=>{
                      formik.setFieldValue('name', event.target.value.trimStart())
                    }}
                    placeholder="Leave a comment here" id="floatingTextarea" required />
                    <label> Name <span className="text-danger"> * </span></label>
                    {formik.touched.name && formik?.errors.name && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors?.name}</span>
                      </div>
                    </div>
                  )}
                  </div>
  
                  <div className="form-floating mb-8">
                    <input type="text" className="form-control"
                    value={formik?.values?.mobileno}
                    onChange={(event:any)=>{
                      formik.setFieldValue('mobileno', event.target.value.trimStart())
                    }}
                    placeholder="Leave a comment here" id="floatingTextarea" required />
                    <label> Mobile Number <span className="text-danger"> * </span></label>
                    {formik.touched.mobileno && formik?.errors.mobileno && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors?.mobileno}</span>
                      </div>
                    </div>
                  )}
                  </div>
  
                  <div className="form-floating mb-8">
                    <input type="email" className="form-control" 
                    value={formik?.values?.email}

                    onChange={(event:any)=>{
                      formik.setFieldValue('email', event.target.value.trimStart())
                    }}
                    placeholder="Leave a comment here" id="floatingTextarea" />
                    <label> Email </label>
                    {formik.touched.email && formik?.errors.email && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors?.email}</span>
                      </div>
                    </div>
                  )}
                  </div>
  
                  <div className="form-floating mb-8">
                    <input type="text" className="form-control"
                    value={formik?.values?.comapanyname}

                    onChange={(event:any)=>{
                      formik.setFieldValue('comapanyname', event.target.value.trimStart())
                    }}
                    placeholder="Leave a comment here" id="floatingTextarea" />
                    <label> Company Name </label>
                    {formik.touched.comapanyname && formik?.errors.comapanyname && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors?.comapanyname}</span>
                      </div>
                    </div>
                  )}
                  </div>
  
                  <div className="form-floating mb-8">
                    <select className="form-select"
                    onChange={(event:any)=>{
                      formik.setFieldValue('invited_by', event.target.value.trimStart())
                    }}
                    id="floatingSelect" aria-label="Floating label select example" required>
                      <option selected value="">Invited By <span className="text-danger"> * </span> </option>
                      {
                        userList && userList?.map ((item:any,i:any)=>{
                          return (<option value={item?.id} selected={item?.id == formik?.values?.invited_by ? true :false} key={i}>{item?.name} </option>)
                        })
                      }
                      {/* <option value="1">Prem</option> */}
                    </select>
                    {formik.touched.invited_by && formik?.errors.invited_by && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors?.invited_by}</span>
                      </div>
                    </div>
                  )}
                  </div>
                  <Button  type='submit' className='w-100 me-12 mt-12 fs-4' style={{ backgroundColor: '#f5f5f5' }}>
                    Send Invitation
                  </Button>
  
  
                </div>
              </form>) : 
              (
                <form  action='' onSubmit={formik.handleSubmit} noValidate>
                <div className="col-lg-8 ms-auto me-auto border border-gray-600 p-8 mt-8">
                  <h3 className="mb-6 text-dark "> Use Below Form To Add Visitor Details For Past Events. </h3>
                <div className="form-floating mb-8">
                    <select className="form-select" onChange={(event:any)=>{
                      formik.setFieldValue('event_data', event.target.value.trimStart())
                    }} id="floatingSelect" aria-label="Floating label select example" required>
                      <option value=''> Event Date <span className="text-danger"> * </span></option>
                      {
                        eventList && eventList?.map ((item:any,i:any)=>{
                          return (<option value={item?.id} selected={item?.id == formik?.values?.event_data ? true :false}key={i}>{formatDate(item?.event_date)} </option>)
                        })
                      }
                      {/* <option value="1"> 23-01-20 </option>
                      <option value="2"> 27-05-2021 </option> */}
                    </select>
                    {formik.touched.event_data && formik?.errors.event_data && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors?.event_data}</span>
                      </div>
                    </div>
                  )}
                  </div>
  
                  <div className="form-floating mb-8">
                    <input type="text" className="form-control"
                    value={formik?.values?.name}
                    onChange={(event:any)=>{
                      formik.setFieldValue('name', event.target.value.trimStart())
                    }}
                    placeholder="Leave a comment here" id="floatingTextarea" required />
                    <label> Name <span className="text-danger"> * </span></label>
                    {formik.touched.name && formik?.errors.name && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors?.name}</span>
                      </div>
                    </div>
                  )}
                  </div>
  
                  <div className="form-floating mb-8">
                    <input type="text" className="form-control"
                    value={formik?.values?.mobileno}
                    onChange={(event:any)=>{
                      formik.setFieldValue('mobileno', event.target.value.trimStart())
                    }}
                    placeholder="Leave a comment here" id="floatingTextarea" required />
                    <label> Mobile Number <span className="text-danger"> * </span></label>
                    {formik.touched.mobileno && formik?.errors.mobileno && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors?.mobileno}</span>
                      </div>
                    </div>
                  )}
                  </div>
  
                  <div className="form-floating mb-8">
                    <input type="email" className="form-control" 
                    value={formik?.values?.email}

                    onChange={(event:any)=>{
                      formik.setFieldValue('email', event.target.value.trimStart())
                    }}
                    placeholder="Leave a comment here" id="floatingTextarea" />
                    <label> Email </label>
                    {formik.touched.email && formik?.errors.email && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors?.email}</span>
                      </div>
                    </div>
                  )}
                  </div>
  
                  <div className="form-floating mb-8">
                    <input type="text" className="form-control"
                    value={formik?.values?.comapanyname}

                    onChange={(event:any)=>{
                      formik.setFieldValue('comapanyname', event.target.value.trimStart())
                    }}
                    placeholder="Leave a comment here" id="floatingTextarea" />
                    <label> Company Name </label>
                    {formik.touched.comapanyname && formik?.errors.comapanyname && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors?.comapanyname}</span>
                      </div>
                    </div>
                  )}
                  </div>
  
                  <div className="form-floating mb-8">
                    <select className="form-select"
                    onChange={(event:any)=>{
                      formik.setFieldValue('invited_by', event.target.value.trimStart())
                    }}
                    id="floatingSelect" aria-label="Floating label select example" required>
                      <option selected value="">Invited By <span className="text-danger"> * </span> </option>
                      {
                        userList && userList?.map ((item:any,i:any)=>{
                          return (<option value={item?.id} selected={item?.id == formik?.values?.invited_by ? true :false} key={i}>{item?.name} </option>)
                        })
                      }
                      {/* <option value="1">Prem</option> */}
                    </select>
                    {formik.touched.invited_by && formik?.errors.invited_by && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors?.invited_by}</span>
                      </div>
                    </div>
                  )}
                  </div>
                  <Button  type='submit' className='w-100 me-12 mt-12 fs-4' style={{ backgroundColor: '#f5f5f5' }}>
                    Send Invitation
                  </Button>
  
  
                </div>
              </form>
              )
            }
            
            
          </div>


          {/* <div className="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
          <form>
              <div className="col-lg-8 ms-auto me-auto border border-gray-600 p-8 mt-8">
                <h3 className="mb-6 text-dark "> Use Below Form To Add Visitor Details For Past Events. </h3>
              <div className="form-floating mb-8">
                  <select className="form-select" id="floatingSelect" aria-label="Floating label select example" required>
                    <option selected> Event Date <span className="text-danger"> * </span></option>
                    <option value="1"> 23-01-20 </option>
                    <option value="2"> 27-05-2021 </option>
                  </select>
                </div>

                          <div className="form-floating mb-8">
                            <input type="text" className="form-control" placeholder="Leave a comment here" id="floatingTextarea" required />
                            <label> Name <span className="text-danger"> * </span></label>
                          </div>

                          <div className="form-floating mb-8">
                            <input type="text" className="form-control" placeholder="Leave a comment here" id="floatingTextarea" required />
                            <label> Mobile Number <span className="text-danger"> * </span></label>
                          </div>

                          <div className="form-floating mb-8">
                            <input type="email" className="form-control" placeholder="Leave a comment here" id="floatingTextarea" />
                            <label> Email <span className="text-danger"> * </span></label>
                          </div>

                          <div className="form-floating mb-8">
                            <input type="text" className="form-control" placeholder="Leave a comment here" id="floatingTextarea" />
                            <label> Company Name <span className="text-danger"> * </span></label>
                          </div>

                          <div className="form-floating mb-8">
                            <select className="form-select" id="floatingSelect" aria-label="Floating label select example" required>
                              <option selected>Invited By <span className="text-danger"> * </span> </option>
                              <option value="1">Prem</option>
                            </select>
                          </div>
                          <Button type='submit' className='w-100 me-12 mt-12 fs-4 bg-success'>
                            Add Details
                          </Button>


                        </div>
                      </form>

                    </div>
                  </div>
              </div>
            </form>

          </div> */}
        </div>
      </div>



   
   </div></div>
   </div>
    </>
  )


}


export default AddDetails