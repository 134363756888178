import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'
import { Link } from "react-router-dom";

export function LoadingPost() {
    return (
        <div className={`card card-flush mb-4 post-card`} >
            <div className='card-header p-4 align-items-start'>
                <div className='d-flex align-items-start w-100'>
                    <div
                        className={'symbol symbol-md-55px me-3 '}
                    >
                        <Skeleton height='100%' containerClassName='avatar-skeleton w-50px h-50px d-block' />
                    </div>

                    <div className='flex-grow-1'>
                        <Link to='' className='text-black text-hover-primary fs-5 fs-md-4 fw-bold'>
                            <Skeleton containerClassName='' width={'100%'} />
                        </Link>
                        <span className='text-gray-400 fw-semibold d-block fs-13'>
                            <Skeleton containerClassName='' width={'100%'} />
                        </span>
                        <div className='d-flex align-items-center'>
                            <span className='text-gray-400 fw-semibold d-block fs-13 me-3'><Skeleton containerClassName='' width={'100%'} /></span>
                        
                        </div>
                    </div>
                </div>
             
            </div>
            <div className='card-body pt-3 pb-3 ps-4 pe-4'>
                <div className={'description-content'}>
                    <Skeleton containerClassName='' width={'95%'} />
                    <Skeleton containerClassName='' width={'95%'} />
                    <Skeleton containerClassName='' width={'75%'} />
                </div>
                <div className='media mb-2'>
                <Skeleton height='400px' containerClassName='w-100' />
                </div>
            </div>
          
        </div>
    )
}