import React, { useState, useContext, useRef, useEffect } from 'react'
import { Button, Form } from 'react-bootstrap'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import TextareaAutosize from 'react-textarea-autosize'
import Webcam from 'react-webcam'
import { apiUrl } from '../../../../../../apiurl'
import axios from 'axios'
import { UserContext } from '../../../../../../contextStore'
import './webcamPopup.css'
import { toAbsoluteUrl } from '../../../../../../_metronic/helpers'
import { findOneChapterData, sendSmsAndNotification } from '../_requests'
import { getChapterList } from '../../../../../modules/auth/core/_requests'
import moment from 'moment'
const API_URL = apiUrl
const Make_Annoucement = `${API_URL}/usertopics`
const IMAGE_UPLOAD = `${API_URL}/containers/topicimages/upload`

export function MakeAnnoucement() {
  const { setMessage, userDetail, dashBoardReload, chapterID } = useContext(UserContext)
  const [loading, setLoading] = useState(false)
  const webcamRef = useRef<Webcam | null>(null)
  const [capturedImage, setCapturedImage] = useState<string | null>(null)
  const [showWebcam, setShowWebcam] = useState(false)
  const [webcamAvailable, setWebcamAvailable] = useState<boolean>(false)
  const [selectedImage, setSelectedImage] = useState('')
  const [selectedFile, setSelectedFile] = useState('')
  const [uploading, setUploading] = useState<boolean>(false)
  const closeButton = useRef<any>()
  const [arrayOfFile, setArrayOfFile] = useState<any>([])
  const [pervFileUpload, setPervFileUpload] = useState('')
  const [descTextCount, setDescTextCount] = useState<number>(2000)
  const [chapterData, setChapterData] = useState({ name: '', id: '' })
  const [errorMessage, setErrorMessage] = useState<any>(false)
  const FACING_MODE_USER = 'user'
  const FACING_MODE_ENVIRONMENT = 'environment'
  const [facingMode, setFacingMode] = React.useState(FACING_MODE_USER)
  const [chapterList, setChapterList] = useState<any>([])

  const fetchchapterList = async () => {
    try {
      const res = await getChapterList()
      if (res?.data) {
        let newObj = res?.data?.find((x: any) => x.id == chapterID)
        setChapterData({ name: newObj?.name, id: newObj?.id })
      }
      setChapterList(res?.data)
    } catch (error) {
      console.error("error", error)
    }
  }

  useEffect(() => {
    fetchchapterList()
  }, [chapterID])

  useEffect(() => {
    fetchData()
  }, [])

  const fetchData = async () => {
    try {
      const findOneChapterDataa = await findOneChapterData(userDetail.chapter_id)
      setChapterData(() => findOneChapterDataa ?? {})
      setLoading(true)
    } catch (error) {
      console.error('Error -->:', error)
      setLoading(true)
    }
  }

  const handleFileChange = async (event: any) => {
    const file = event.target.files[0]
    if (file) {
      // setSelectedFile(file.name);
      await uploadFile(file, 'file')
    } else {
      setSelectedFile('')
    }
  }

  const handleImageChange = async (event: any) => {
    const file = event.target.files[0]
    if (file) {
      if (pervFileUpload == 'file') {
        setArrayOfFile([])
      }
      // setSelectedImage(file.name);
      await uploadFile(file, 'image')
      // setSelectedFile('')
    } else {
      setSelectedImage('')
    }
  }

  const uploadFile = async (file: File, typeOfFile: string) => {
    try {
      if (!file) {
        console.error('No file selected')
        return
      }

      const formData = new FormData()
      formData.append('file', file)

      const response = await axios.post(`${IMAGE_UPLOAD}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      if (typeOfFile == 'file') {
        setArrayOfFile([
          {
            name: response?.data?.result?.files?.file[0]?.name ?? '',
            image: response?.data?.result?.files?.file[0]?.name ?? '',
          },
        ])
        setPervFileUpload('file')
      }
      if (typeOfFile == 'image') {
        if (pervFileUpload == 'file') {
          setArrayOfFile([
            {
              name: response?.data?.result?.files?.file[0]?.name ?? '',
              image: response?.data?.result?.files?.file[0]?.name ?? '',
            },
          ])
        } else {
          if (arrayOfFile.length === 0) {
            setArrayOfFile([
              {
                name: response?.data?.result?.files?.file[0]?.name ?? '',
                image: response?.data?.result?.files?.file[0]?.name ?? '',
              },
            ])
          } else if (
            arrayOfFile.length === 1 ||
            arrayOfFile.length === 2 ||
            arrayOfFile.length === 3
          ) {
            setArrayOfFile([
              ...arrayOfFile,
              {
                name: response?.data?.result?.files?.file[0]?.name ?? '',
                image: response?.data?.result?.files?.file[0]?.name ?? '',
              },
            ])
          } else {
            setMessage('error', 'Only 4 images are allowed')
            return
          }
        }
        setPervFileUpload('image')
      }

      // setFileName(response?.data?.result?.files?.file[0]?.name ?? '');
    } catch (error) {
      setMessage('error', 'Something Went Wrong')

      // setMessage('error', 'Error uploading the file');
    }
  }

  const checkCameraPermission = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true })
      setWebcamAvailable(true)
      stream.getTracks().forEach((track) => track.stop())
    } catch (error) {
      setWebcamAvailable(false)
    }
  }

  const uploadImage = async (imageData: any) => {
    try {
      if (!imageData) {
        console.error('No image captured')
        return
      }

      // Create an Image element to load the captured image data
      const img = new Image()
      img.src = imageData

      // Wait for the image to load
      await new Promise((resolve) => (img.onload = resolve))

      // Create a canvas element
      const canvas = document.createElement('canvas')
      canvas.width = img.width
      canvas.height = img.height

      // Get the canvas context and draw the image on the canvas
      const ctx = canvas.getContext('2d')
      if (ctx) {
        ctx.drawImage(img, 0, 0) // Ensure ctx is not null before using it
      } else {
        console.error('Canvas context is null')
        return
      }

      // Convert the canvas content to a data URL with 'image/jpeg' format and quality set to 1 (maximum quality)
      const jpgDataUrl = canvas.toDataURL('image/jpeg', 1)

      // Convert the data URL back to a Blob
      const byteCharacters = atob(jpgDataUrl.split(',')[1])
      const byteArrays = new Uint8Array(byteCharacters.length)
      for (let i = 0; i < byteCharacters.length; i++) {
        byteArrays[i] = byteCharacters.charCodeAt(i)
      }
      const jpgBlob = new Blob([byteArrays], { type: 'image/jpeg' })

      // Create a FormData object to send the image file to the API
      const formData = new FormData()
      formData.append('image', jpgBlob, 'filename.jpg')

      // Set the loading state to true while uploading
      setUploading(true)

      // Make the API call to upload the image
      const response = await axios.post(`${IMAGE_UPLOAD}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      // Muti File Upload.
      if (arrayOfFile.length === 0) {
        setArrayOfFile([
          {
            name: response?.data?.result?.files?.image[0]?.name ?? '',
            image: response?.data?.result?.files?.image[0]?.name ?? '',
          },
        ])
      } else if (arrayOfFile.length === 1 || arrayOfFile.length === 2 || arrayOfFile.length === 3) {
        setArrayOfFile([
          ...arrayOfFile,
          {
            name: response?.data?.result?.files?.image[0]?.name ?? '',
            image: response?.data?.result?.files?.image[0]?.name ?? '',
          },
        ])
      } else {
        setMessage('error', 'Only 4 images are allowed')
        return
      }

      // setFileName(response?.data?.result?.files?.image[0]?.name ?? "")

      let { filename } = response.data

      setUploading(false)
    } catch (error) {
      setMessage('error', 'Error uploading the images')
      setUploading(false)
    }
  }

  // initial value defined
  const initialValues = {
    chapter_id: 4,
    descriptiontext: '',
    deadline_announcement: ""
  }
  // const toggleWebcam = () => {
  //   setShowWebcam((prev) => !prev);
  //   if (!webcamAvailable) {
  //     checkCameraPermission(); // Check camera permission when the camera button is clicked
  //   }
  // };

  const toggleCameraFacingMode = () => {
    setFacingMode((prevFacingMode) =>
      prevFacingMode === FACING_MODE_USER ? FACING_MODE_ENVIRONMENT : FACING_MODE_USER
    )
  }

  const toggleWebcam = async () => {
    if (!webcamAvailable) {
      await checkCameraPermission() // Check camera permission when the camera button is clicked
      setShowWebcam(true)
    } else {
      setShowWebcam(true)
    }
  }

  const captureImage = () => {
    if (webcamRef.current) {
      const imageSrc = webcamRef.current.getScreenshot()
      setCapturedImage(imageSrc)
      uploadImage(imageSrc)
    }
    setShowWebcam(false)
  }

  // Modify the cancelWebcam function to close the webcam popup when canceling or capturing
  const cancelWebcam = () => {
    // setCapturedImage(null);
    setShowWebcam(false) // Close the webcam pop-up when the user cancels or captures the image
  }

  // validation
  const validationSchema = Yup.object().shape({
    descriptiontext: Yup.string()
      .required('Description is required')
      .max(2000, 'Maximum 2000 characters allow'),
  })

  const [selectedChapter, setSelectedChapter] = useState('')

  const handleChapterChange = (event: any) => {
    setSelectedChapter(event.target.value)
  }

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm }) => {
      try {
        // Validate selectedChapter value before making the API call
        if (!selectedChapter) {
          setErrorMessage(true)
          return
        }

        const postData = {
          descriptiontext: values.descriptiontext,
          isactive: 1,
          isdelete: 0,
          created_by: userDetail.id,
          user_id: userDetail.id,
          chapter_id: selectedChapter,
          topic_id: 1,
          postmedia: arrayOfFile,
          deadline_announcement: values.deadline_announcement
        }

        await axios
          .post(`${Make_Annoucement}?filter[where][isdelete]=0`, postData)
          .then((res: any) => {
            if (res) {
              setMessage('success', 'Announcement created successfully')
              const sendDataForNotification = {
                thankyouhistoryid: null,
                user_topic_id: res?.data?.id,
                userid: userDetail?.id ?? '',
                accesstoken: localStorage.getItem('authToken'),
              }
              sendSmsAndNotification(sendDataForNotification)
              setErrorMessage(false)
              resetForm()
              setDescTextCount(2000)
              setSelectedChapter('')
              setArrayOfFile([])
              dashBoardReload()
            }
          })
          .catch((error: any) => {
            if (error?.response?.data?.error?.message) {
              setMessage('error', error?.response?.data?.error?.message)
            } else {
              setMessage('error', 'Something Went Wrong')
            }
            setLoading(false)
          })
      } catch (error) {
        setMessage('error', 'Something went wrong')
      }
    },
  })
  
  const handleCancelButtonClick = (index: any) => {
    const updatedArray = arrayOfFile.filter((item: any, i: any) => i !== index)
    setArrayOfFile(updatedArray)
  }

  return (
    <>
      <div
        className='modal fade ilead-default-modal'
        id='MakeAnnoucementModal'
        aria-hidden='true'
        aria-labelledby='MakeAnnoucementModalLabel'
      >
        <div className='modal-dialog modal-dialog modal-dialog-scrollable modal-fullscreen-md-down modal-md'>
          <div className='modal-content'>
            <div className='modal-header py-5'>
              <h3 className='modal-title fs-3 fw-bold' id='MakeAnnoucementModalToggleLabel'>
                Make Announcement
              </h3>
              <button
                type='button'
                className='btn-close text-white'
                data-bs-target='#write_post_modal'
                data-bs-toggle='modal'
                data-bs-dismiss='modal'
                onClick={() => {
                  setDescTextCount(2000)
                  setSelectedChapter('')
                  formik.setFieldValue('descriptiontext', '')
                  setArrayOfFile([])
                  setErrorMessage(false)
                  formik.resetForm()
                }}
                ref={closeButton}
              ></button>
            </div>

            <div className='modal-body pt-7 write-post-wrapper'>
              <form
                className='mb-7'
                onSubmit={(e) => {
                  e.preventDefault()
                  formik.handleSubmit(e)
                }}
              >
                <h3 className='text-black fw-bold fs-4 mb-6'>Select Chapter to Post</h3>
                <Form.Group className='mb-3'>
                  <Form.Check
                    label={chapterData?.name}
                    name='chapter'
                    type='radio'
                    className='mb-5 me-7'
                    checked={selectedChapter == chapterData?.id}
                    onChange={handleChapterChange}
                    value={chapterData?.id}
                  />
                  <Form.Check
                    label='All Chapter'
                    name='chapter'
                    type='radio'
                    className='mb-5'
                    checked={selectedChapter === '0'}
                    onChange={handleChapterChange}
                    value='0'
                    inline
                  />
                  {!selectedChapter && errorMessage ? (
                    <div className='fv-plugins-message-container text-danger'>
                      <span role='alert'>This field is required</span>
                    </div>
                  ) : (
                    ''
                  )}
                </Form.Group>
                <Form.Group className='mb-3'>
                  <TextareaAutosize
                    minRows={3}
                    autoFocus
                    name='descriptiontext'
                    maxLength={2000}
                    className='custom-text-area form-control fw-normal ilead-input'
                    placeholder='Enter description here'
                    onChange={(e) => {
                      formik.setFieldValue('descriptiontext', e.target.value)
                      const currentLength = e.target.value.length
                      const remainingCharacters = 2000 - currentLength
                      setDescTextCount(remainingCharacters)
                    }}
                    value={formik.values.descriptiontext}
                  />
                  {descTextCount <= 2000 && descTextCount >= 0 && (
                    <p className='text-gray-500 fs-13'>
                      Available Characters{' '}
                      {descTextCount <= 2000 ? descTextCount : descTextCount + 1}{' '}
                    </p>
                  )}
                  {formik?.touched?.descriptiontext && formik?.errors?.descriptiontext && (
                    <div className='fv-plugins-message-container text-danger'>
                      <span role='alert'>{formik?.errors?.descriptiontext as any}</span>
                    </div>
                  )}
                </Form.Group>

                <Form.Group className='mb-3 mt-7'>
                  <h5 className='mt-7 mb-7'>Add Attachment</h5>

                  <Button
                    variant='primary'
                    className='me-3 px-4 py-3'
                    onClick={() => toggleWebcam()}
                  >
                    <i className='bi bi-camera-fill pe-0' style={{ fontSize: '20px' }}></i>
                  </Button>

                  {showWebcam && webcamAvailable ? (
                    <div className='webcam-popup'>
                      <div className='webcam-popup-content'>
                        <Webcam
                          audio={false}
                          ref={webcamRef}
                          videoConstraints={{
                            facingMode: facingMode,
                          }}
                        />
                        <button
                          type='button'
                          className='btn-close text-white cancel-button'
                          onClick={cancelWebcam}
                        ></button>
                        <div className='webcam-buttons'>
                          <Button
                            variant='primary'
                            className='me-3 px-4 py-3'
                            onClick={captureImage}
                          >
                            <i className='bi bi-camera-fill pe-0' style={{ fontSize: '20px' }}></i>
                          </Button>

                          <Button
                            variant='primary'
                            className='me-3 px-4 py-3 toggle-button'
                            onClick={toggleCameraFacingMode}
                            style={{ borderRadius: '30px' }}
                          >
                            <i className='bi bi-arrow-repeat pe-0' style={{ fontSize: '20px' }}></i>
                          </Button>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ''
                  )}
                  <div className='me-3 px-4 py-3 position-relative btn btn-primary'>
                    <Form.Control
                      type='file'
                      accept='image/jpeg, image/png'
                      className='position-absolute w-100 h-100 start-0 top-0 opacity-0'
                      onChange={handleImageChange} // Handle file input change
                    />
                    <i className='bi bi-image-fill pe-0' style={{ fontSize: '20px' }}></i>
                  </div>

                  <div className='me-3 px-4 py-3 position-relative btn btn-primary'>
                    <Form.Control
                      type='file'
                      accept='.pdf,.doc,.docx,.xls,.xlsx,.ppt,.pptx' // Specify accepted file types
                      className='position-absolute w-100 h-100 start-0 top-0 opacity-0'
                      onChange={handleFileChange} // Handle file input change
                    />
                    <i className='bi bi-folder-fill pe-0' style={{ fontSize: '20px' }}></i>
                  </div>

                  <div style={{ display: 'flex', marginTop: '10px' }}>
                    <div className='row'>
                      {arrayOfFile.map((item: any, i: any) => {
                        let itemLast = item.image.split('.').pop()
                        if (
                          itemLast == 'jpeg' ||
                          itemLast == 'jpeg' ||
                          itemLast == 'png' ||
                          itemLast == 'jpg'
                        ) {
                          return (
                            <div className='col'>
                              <img
                                src={`${API_URL}/containers/topicimages/download/${item.image}`}
                                onClick={() => {
                                  const url = `${API_URL}/containers/topicimages/download/${item.image}`
                                  window.open(url, '_blank')
                                }}
                                alt='Captured'
                                style={{ width: 130, height: 90 }}
                              />
                              <button
                                type='button'
                                style={{ backgroundColor: 'red' }}
                                className='btn-close text-white'
                                onClick={() => {
                                  handleCancelButtonClick(i)
                                }}
                              ></button>
                            </div>
                          )
                        } else if (itemLast == 'pdf') {
                          return (
                            <div className='col'>
                              <img
                                src={`${toAbsoluteUrl('./media/ilead/pdf.png')}`}
                                onClick={() => {
                                  const url = `${API_URL}/containers/topicimages/download/${item.image}`
                                  window.open(url, '_blank')
                                }}
                                alt=''
                                className='w-50px'
                              />
                              <button
                                type='button'
                                style={{ backgroundColor: 'red' }}
                                className='btn-close text-white'
                                onClick={() => {
                                  handleCancelButtonClick(i)
                                }}
                              ></button>
                            </div>
                          )
                        } else if (
                          itemLast == 'doc' ||
                          itemLast == 'docm' ||
                          itemLast == 'docx' ||
                          itemLast == 'dot'
                        ) {
                          return (
                            <div className='col'>
                              <img
                                src={`${toAbsoluteUrl('./media/ilead/docs.png')}`}
                                onClick={() => {
                                  const url = `${API_URL}/containers/topicimages/download/${item.image}`
                                  window.open(url, '_blank')
                                }}
                                alt=''
                                className='w-50px'
                              />
                              <button
                                type='button'
                                style={{ backgroundColor: 'red' }}
                                className='btn-close text-white'
                                onClick={() => {
                                  handleCancelButtonClick(i)
                                }}
                              ></button>
                            </div>
                          )
                        } else if (
                          itemLast == 'xlsx' ||
                          itemLast == 'xlsm' ||
                          itemLast == 'xlsb' ||
                          itemLast == 'xltx'
                        ) {
                          return (
                            <div className='col'>
                              <img
                                src={`${toAbsoluteUrl('./media/ilead/sheets.png')}`}
                                onClick={() => {
                                  const url = `${API_URL}/containers/topicimages/download/${item.image}`
                                  window.open(url, '_blank')
                                }}
                                alt=''
                                className='w-50px'
                              />
                              <button
                                type='button'
                                style={{ backgroundColor: 'red' }}
                                className='btn-close text-white'
                                onClick={() => {
                                  handleCancelButtonClick(i)
                                }}
                              ></button>
                            </div>
                          )
                        } else if (itemLast == 'pptx' || itemLast == 'pptm' || itemLast == 'ppt') {
                          return (
                            <div className='col'>
                              <img
                                src={`${toAbsoluteUrl('./media/ilead/ppt.png')}`}
                                onClick={() => {
                                  const url = `${API_URL}/containers/topicimages/download/${item.image}`
                                  window.open(url, '_blank')
                                }}
                                alt=''
                                className='w-50px'
                              />
                              <button
                                type='button'
                                style={{ backgroundColor: 'red' }}
                                className='btn-close text-white'
                                onClick={() => {
                                  handleCancelButtonClick(i)
                                }}
                              ></button>
                            </div>
                          )
                        } else {
                          return (
                            <div className='col'>
                              <p>{`The file upload with ${itemLast}`}</p>
                              <button
                                type='button'
                                style={{ backgroundColor: 'red' }}
                                className='btn-close text-white'
                                onClick={() => {
                                  handleCancelButtonClick(i)
                                }}
                              ></button>
                            </div>
                          )
                        }
                      })}
                    </div>
                  </div>
                </Form.Group>
                <Form.Group className='mt-7'>
                  <Form.Label className='text-dark fw-semibold required'>Deadline for the Announcement</Form.Label>
                  <Form.Control
                    type='date'
                    placeholder='25 Jul 2023'
                    className='ilead-input'
                    min={new Date().toISOString().split('T')[0]}
                    onChange={(e: any) => {
                      
                      formik.setFieldValue('deadline_announcement', e?.target?.value)

                    }}
                    value={
                      formik?.values?.deadline_announcement
                        ? new Date(formik?.values?.deadline_announcement).toISOString().split('T')[0]
                        : ''
                    }
                  />
                </Form.Group>
                <Button
                  variant='primary'
                  type='submit'
                  data-bs-toggle={formik.isValid ? 'modal' : undefined}
                  data-bs-dismiss={formik.isValid ? 'modal' : undefined}
                  className='w-100 mt-7'
                  disabled={formik.isSubmitting || !selectedChapter || !formik.dirty}
                  onClick={() => {
                    if (!selectedChapter) {
                      setErrorMessage(true)
                    }
                  }}
                >
                  Submit
                </Button>
              </form>
              <div className='note-description mt-7 fs-6 text-gray-700'>
                *Note : Only image(jpg/png) or file(PDF/Word/Excel/PPT) is allowed to upload !
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
