import React, {useContext, useEffect, useRef, useState} from 'react'
import {Button, Form, Modal, NavLink} from 'react-bootstrap'
import TextareaAutosize from 'react-textarea-autosize'
import {currentPerformanceApi, getCheckICANIWILL} from '../_requests'
import * as Yup from 'yup'
import {Link, useNavigate} from 'react-router-dom'
import {useFormik} from 'formik'
import {apiUrl} from '../../../../../apiurl'
import axios from 'axios'
import {UserContext} from '../../../../../contextStore'

const API_URL = apiUrl
const ICANIWILL_API = `${API_URL}/usertopics`

export function ICANIWILLModal({showModal, data}: {showModal: any; data: any}) {
  const [missionAndVisionData, setMissionAndVisionData] = useState<any>({})
  const {setMessage, userDetail, dashBoardReload} = useContext(UserContext)
  const [Loading, setIsLoading] = useState<any>(true)
  const [modelClose, setModelClose] = useState(false)
  const [icaniwillShowModal,setIcanIwillShowModal] = useState(false)
  const closebutton = useRef<any>()
  const iciwModal = useRef<any>()
  const navigate = useNavigate()
  useEffect(() => {
    fetchData()
    console.log("datadata",data)
    if (data?.data?.result === false) {
      setTimeout(() => {
        setIcanIwillShowModal(true)
      }, 100)
    }
  }, [data])

  // useEffect(()=>{
  //     setTimeout(() => {
  //       iciwModal?.current?.click()
  //     }, 100)
  // },[])
  // currentPerformanceApi
  const fetchData = async () => {
    try {
      const profileData = await currentPerformanceApi()
      setMissionAndVisionData(() => profileData ?? [])
      setIsLoading(false)
    } catch (error) {
      console.error('Error -->:', error)
      setIsLoading(false)
    }
  }

  const initialValues = {
    descriptiontext: '',
  }
  const validationSchema = Yup.object().shape({
    descriptiontext: Yup.string().required('Description is required'),
  })

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values, {resetForm}) => {
      setIsLoading(true)
      try {
        const Data = {
          topic_id: 19,
          user_id: userDetail.id,
          descriptiontext: values.descriptiontext,
          isactive: 1,
          group_id: userDetail.group_id,
          isdelete: 0,
          created_by: userDetail.id,
        }

        if (values.descriptiontext.toLocaleLowerCase().trim() === 'i can i will') {
          await axios.post(`${ICANIWILL_API}`, Data)
          setIsLoading(false)
          resetForm()
          setModelClose(true)
          dashBoardReload()
          setIcanIwillShowModal(false)
          closebutton?.current?.click()
        } else {
          setMessage('error', 'Please write " I CAN I WILL"')
        }
      } catch (error) {
        setIsLoading(false)
      }
    },
  })

  return (
    <>
      <button
        type='button'
        className='thanks_count1'
        data-bs-toggle='modal'
        data-bs-target='#IcanIwillModal1'
        ref={iciwModal}
        style={{display: 'none'}}
      >
        {' '}
        hello
      </button>

      {/* <div
        className='modal'
        id='IcanIwillModal1'
        aria-hidden='true'
        aria-labelledby='IcanIwillModalLabel'
        role='dialog'
        data-bs-backdrop='static'
      > */}
      <Modal show={icaniwillShowModal} onHide={()=>{setIcanIwillShowModal(false)}} backdrop={'static'}>
      {/* <div className='modal-dialog modal-md modal-dialog-center'> */}
          <div className='modal-content'>
            <div className='modal-header m-0 p-0'>
              {/* <h3
                className='modal-title fs-3 fw-bold'
                id='IcanIwillModalToggleLabel'
                style={{color: 'white'}}
              >
                I CAN I WILL
              </h3> */}
              <button
                type='button'
                className='btn-close text-white'
                data-bs-target='#write_post_modal1'
                data-bs-toggle='modal'
                data-bs-dismiss='modal'
                style={{display: 'none'}}
                ref={closebutton}
              ></button>
            </div>
            <div className='modal-body pt-7 write-post-wrapper'>
              <div className='d-flex justify-content-between'>
                {/* IF Mission is already added */}
                <Form.Label>
                  <b>My Mission</b>
                </Form.Label>
                {missionAndVisionData.mission ? (
                  <button
                    data-bs-dismiss='modal'
                    aria-label='Close'
                    onClick={(e: any) => {
                      navigate('/leader-profile')
                    }}
                    style={{color: '#e3371e', border: '0px', backgroundColor: '#ffffff00'}}
                  >
                    <i className='fa fa-pencil' aria-hidden='true'></i>
                  </button>
                ) : (
                  ''
                )}
              </div>
              {/* IF Mission is not added */}
              {missionAndVisionData ? (
                <div>
                  {missionAndVisionData.mission ? (
                    <div className='d-flex justify-content-between'>
                      {missionAndVisionData.mission}
                    </div>
                  ) : (
                    <div className=' justify-content-between mb-5'>
                      Please{' '}
                      <button
                        data-bs-dismiss='modal'
                        aria-label='Close'
                        onClick={(e: any) => {
                          navigate('/leader-profile')
                        }}
                        style={{color: '#e3371e', border: '0px', backgroundColor: '#ffffff00'}}
                      >
                        click here
                      </button>{' '}
                      to update your mission
                    </div>
                  )}
                </div>
              ) : (
                <div className='mb-5'>
                  <div className=' justify-content-between'>
                    Please{' '}
                    <button
                      data-bs-dismiss='modal'
                      aria-label='Close'
                      onClick={(e: any) => {
                        navigate('/leader-profile')
                      }}
                      style={{color: '#e3371e', border: '0px', backgroundColor: '#ffffff00'}}
                    >
                      click here
                    </button>{' '}
                    to update your mission
                  </div>
                </div>
              )}

              {/* IF Vision is already added */}
              <div className='d-flex justify-content-between'>
                <Form.Label>
                  <b>My Vision</b>
                </Form.Label>
                {missionAndVisionData.vision ? (
                  <button
                    data-bs-dismiss='modal'
                    aria-label='Close'
                    onClick={(e: any) => {
                      navigate('/leader-profile')
                    }}
                    style={{color: '#e3371e', border: '0px', backgroundColor: '#ffffff00'}}
                  >
                    <i className='fa fa-pencil' aria-hidden='true'></i>
                  </button>
                ) : (
                  ''
                )}
              </div>
              {/* IF Vision is not added */}

              {missionAndVisionData ? (
                <div>
                  {missionAndVisionData.vision ? (
                    <div className='d-flex justify-content-between'>
                      {missionAndVisionData.vision}
                    </div>
                  ) : (
                    <div className=' justify-content-between'>
                      Please{' '}
                      <button
                        data-bs-dismiss='modal'
                        aria-label='Close'
                        onClick={(e: any) => {
                          navigate('/leader-profile')
                        }}
                        style={{color: '#e3371e', border: '0px', backgroundColor: '#ffffff00'}}
                      >
                        click here
                      </button>{' '}
                      to update your vision
                    </div>
                  )}
                </div>
              ) : (
                <div className=' justify-content-between'>
                  Please{' '}
                  <button
                    data-bs-dismiss='modal'
                    aria-label='Close'
                    onClick={(e: any) => {
                      navigate('/leader-profile')
                    }}
                    style={{color: '#e3371e', border: '0px', backgroundColor: '#ffffff00'}}
                  >
                    click here
                  </button>{' '}
                  to update your vision
                </div>
              )}
            </div>
            <form
              onSubmit={(e) => {
                formik.handleSubmit(e)
              }}
            >
              <div
                className=''
                style={{
                  backgroundColor: '#e3371e',
                  display: 'flex',
                  alignItems: 'center',
                  paddingTop: '18px',
                  paddingBottom: '20px',
                  paddingLeft: '12px',
                  borderRadius: '8px',
                  flexDirection: 'row',
                  paddingRight: '60px',
                }}
              >
                <input
                  type='text'
                  autoFocus
                  name='descriptiontext'
                  className='custom-text-area form-control fw-normal ilead-input w-100 icaniwill-input text-white'
                  placeholder='I CAN I WILL'
                  onKeyDown={(e)=>{
                    if(e.key === "Enter"){
                      formik.handleSubmit()
                    }
                  }}
                  onChange={(e: any) => {
                    formik.setFieldValue('descriptiontext', e.target.value)
                  }}
                  value={formik?.values?.descriptiontext}
                  style={{
                    flex: '1',
                    marginRight: '10px',
                    marginLeft: '12px',
                    borderRadius: '0',
                    backgroundColor: 'transparent',
                    border: 'none',
                    borderBottom: '1px solid white',
                  }}
                />
                <button
                  style={{
                    borderRadius: '25px',
                    cursor: 'pointer',
                    fontSize: '2.0em',
                    right: '20px',
                    width: '40px',
                    height: '40px',
                  }}
                  type='submit'
                  data-bs-toggle={modelClose ? 'modal' : ''}
                  data-bs-dismiss={modelClose ? 'modal' : ''}
                  disabled={formik.isSubmitting}
                  className='position-absolute bg-white text-primary border-0 d-flex align-items-center justify-content-center '
                >
                  <i className='fa fa-paper-plane text-primary fs-5'></i>
                </button>
              </div>
            </form>
          </div>
        {/* </div> */}
      </Modal>
        
      {/* </div> */}
      {/* begin: Ask for Help Modal */}
    </>
  )
  // document.getElementById('element')
}
