import React, {useContext, useEffect, useState} from 'react'
import {Image, Spinner} from 'react-bootstrap'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {apiUrl} from '../../../apiurl'
import {Link} from 'react-router-dom'
import {MonthlyreportsListWrapper} from './monthlyreports-list/MonthlyreportsList'
// import XLSX from 'sheetjs-style';
import * as FileSaver from 'file-saver'
import MonthlyReportBarChart from './MonthlyReportBarChart'
import MonthlyReportPieChart from './MonthlyReportPieChart'
import MonthlyReportVisitorDoughnutChart from './MonthlyVisitorReportDoughnutChart'
import Multiselect from 'multiselect-react-dropdown'
import {
  LeaderActivityCustomData,
  LeaderGoalVsMrReport,
  MonthlyBiReport,
  GroupsDropDown,
  MonthlyReportTableData,
  GetAverageFeedback,
  GetAttendanceGraph,
  GetInvitedAttendedJoinedRatio,
  ChapterName,
  UserDetail,
  GetTrackSheetCount,
  TopPerformersData,
  ExportGiData,
} from './monthlyreports-list/core/_requests'
import moment from 'moment'

import download from 'downloadjs'
import jsPDF from 'jspdf'
import 'jspdf-autotable'
import html2canvas from 'html2canvas'
import * as XLSX from 'xlsx'
import axios from 'axios'
import {UserContext} from '../../../contextStore'
import {
  QueryRequestProvider,
  useQueryRequest,
} from './monthlyreports-list/core/QueryRequestProvider'
import {QueryResponseProvider} from './monthlyreports-list/core/QueryResponseProvider'
const API_URL = apiUrl
const LEADERACTIVITYCUSTOMDATA = `${API_URL}/usertopics/getLeaderActivityCustomData` //10
const MONTHLYBI_REPORT = `${API_URL}/monthlybusinessleaderpoints/exportBIReport` //11
const LEADERGOALVSMRREPORT = `${API_URL}/managegoals/leaderGoalVsMRReport` //12
const EXPORT_GI_DATA = `${API_URL}/monthlyleaderpoints/exportGIdata` //13
const LEADERGROUPACTIVITYCUSTOMDATA = `${API_URL}/usertopics/getLeaderActivityCustomDataByGroupWise` //14
// import Multiselect from 'multiselect-react-dropdown'
const MonthlyReports = () => {
  const currentMonth = moment().month() + 1
  const [loading, setLoading] = useState(false)

  const currentMonthName = moment().format('MMMM')
  const {state, updateState} = useQueryRequest() as any
  const currentYear = moment().year()
  const {token, setMessage, userDetail} = useContext(UserContext)
  const [isLoading, setIsLoading] = useState(false)
  const [yearsList, setYearsList] = useState([{value: '', label: ''}])
  // console.log("userDetailuserDetail",userDetail)

  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const [leaderGroupWiseStartDate, setLeaderGroupWiseStartDate] = useState('')
  const [leaderGroupWiseEndDate, setLeaderGroupWiseEndDate] = useState('')
  const [biReportStartDate, setBiReportStartDate] = useState('')
  const [biReportEndDate, setBiReportEndDate] = useState('')
  const [goalVsMrReportStartDate, setGoalVsMrReportStartDate] = useState('')
  const [goalVsMrReportEndDate, setGoalVsMrReportEndDate] = useState('')
  const [groupData, setGroupData] = useState([])
  // const [monthlyReportTableData, setMonthlyReportTableData] = useState([])
  const [top5Performers, setTop5Performers] = useState([])
  const [chapterData, setChapterData] = useState([])
  const [selectChapterId, setSelectChapterId] = useState('')
  const [selectYear, setSelectYear] = useState('')
  const [selectMonth, setSelectMonth] = useState('')
  const [trackSheetCount, setTrackSheetCount] = useState({tracksheetcount: '', notsubmitted: ''})
  const [inviteAttendedJoinRatio, setInviteAttendedJoinRatio] = useState({
    invitedsum: '',
    attendedsum: '',
    joinedsum: '',
    invitedPercentage: '',
    attendedPercentage: '',
    joinedPercentage: '',
  })
  const [attandanceGraph, setAttendanceGraph] = useState({
    attendadantPercentage: '',
    absentPercentage: '',
  })
  const [getAverageFeedback, setGetAverageFeedback] = useState({
    sumfor1va: '',
    sumfor2va: '',
    sumfor3va: '',
    sumfor4va: '',
    sumfor5va: '',
    sumfor1tyca: '',
    sumfor2tyca: '',
    sumfor3tyca: '',
    sumfor4tyca: '',
    sumfor5tyca: '',
    averageva: '',
    averagetyca: '',
    averagess: '',
    given: '',
    sumfor1ss: '',
    sumfor2ss: '',
    sumfor3ss: '',
    sumfor4ss: '',
    sumfor5ss: '',
  })
  const [conversionRatio, setConversionRatio] = useState('')

  const [selectedMonths, setSelectedMonths] = useState([])
  const [monthList, setMonthList] = useState([{value: '', label: ''}])

  const handleMonthChange = (selectedOptions: any) => {
    setSelectedMonths(selectedOptions)
  }
  const selectedMonth = monthList.map((item) => item?.value)
  const fetchGetAverageFeedback = async () => {
    try {
      await GetAverageFeedback(selectChapterId, selectYear, selectedMonth)
        .then((res: any) => {
          if (res) {
            setGetAverageFeedback(res)
          }
        })
        .catch((error: any) => {
          console.log(error, 'error !!')
        })
    } catch (error) {
      console.log('error!!', error)
    }
  }
  const fetchGetAttendanceGraph = async () => {
    try {
      await GetAttendanceGraph(selectChapterId, selectYear, selectedMonth)
        .then((res: any) => {
          if (res) {
            setAttendanceGraph(res)
          }
        })
        .catch((error: any) => {
          console.log(error, 'error !!')
        })
    } catch (error) {
      console.log('error!!', error)
    }
  }

  const fetchGetInvitedAttendedRatio = async () => {
    try {
      await GetInvitedAttendedJoinedRatio(selectChapterId, selectYear, selectedMonth)
        .then((res: any) => {
          if (res) {
            setInviteAttendedJoinRatio(res)
            setIsLoading(false)

            let percentage = 0

            if (
              typeof res?.joinedPercentage === 'number' &&
              typeof res?.attendedPercentage === 'number' &&
              res?.joinedPercentage != 0 &&
              res?.attendedPercentage != 0
            ) {
              percentage = (res?.joinedPercentage / res?.attendedPercentage) * 100
            }

            setConversionRatio(percentage.toFixed(2))
          }
        })
        .catch((error: any) => {
          console.log(error, 'error !!')
          setIsLoading(false)
        })
    } catch (error) {
      console.log('error!!', error)
    }
  }

  const fetchChapterDetail = async () => {
    try {
      await ChapterName()
        .then((res: any) => {
          if (res) {
            setChapterData(res)
          }
        })
        .catch((error: any) => {
          console.log(error, 'error !!')
        })
    } catch (error) {
      console.log('error!!', error)
    }
  }

  const fetchGroupAll = async () => {
    try {
      await GroupsDropDown()
        .then((res: any) => {
          if (res) {
            setGroupData(res)
          }
        })
        .catch((error: any) => {
          console.log(error, 'error !!')
        })
    } catch (error) {
      console.error('Error:', error)
    }
  }
  const fetchUserDetail = async () => {
    try {
      await UserDetail()
    } catch (error) {
      console.log('error!!', error)
    }
  }

  const fetchTrackSheetCount = async () => {
    try {
      setIsLoading(true)
      await GetTrackSheetCount(selectChapterId, selectYear, selectedMonth)
        .then((res: any) => {
          if (res) {
            setTrackSheetCount(res)
            setIsLoading(false)
          }
        })
        .catch((error: any) => {
          console.log(error, 'error !!')
          setIsLoading(false)
        })
    } catch (error) {
      console.log('error!!', error)
    }
  }

  const fetchTopPerformersData = async () => {
    try {
      setIsLoading(true)
      await TopPerformersData(selectChapterId, selectYear, selectedMonth)
        .then((res: any) => {
          if (res) {
            setTop5Performers(res)
            setIsLoading(false)
          }
        })
        .catch((error: any) => {
          console.log(error, 'error !!')
          setIsLoading(false)
        })
    } catch (error) {
      console.log('error!!', error)
    }
  }

  // const fetchMonthlyReportData = async () => {
  //   try {
  //     await MonthlyReportTableData().then((res: any) => {
  //       if (res) {

  //         setMonthlyReportTableData(res)
  //       }
  //     })
  //   } catch (error) {
  //     console.error('Error:', error)
  //   }
  // }

  const handleLeaderActivityReportExport = async () => {
    setLoading(true)
    if (!startDate || !endDate) {
      setMessage('error', 'Please select both Start Date and End Date.')
      setLoading(false)
      return
    }
    if (startDate >= endDate) {
      setMessage('error', 'Selected date is Invalid.')
      setLoading(false)
      return
    }

    await axios
      .get(`${LEADERACTIVITYCUSTOMDATA}?startdate=${startDate}&enddate=${endDate}`, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          token,
        },
        responseType: 'blob',
      })
      .then((res: any) => {
        const content = res.headers['content-type']
        setLoading(false)
        download(res.data, 'Leader activity reports.xls', content)
      })
      .catch((error: any) => {
        setLoading(false)
        console.log('error', error)
      })
  }

  const handleLeaderGroupActivityReportExport = async () => {
    setLoading(true)
    if (!leaderGroupWiseStartDate || !leaderGroupWiseEndDate) {
      setMessage('error', 'Please select both Start Date and End Date.')
      setLoading(false)
      return
    }
    if (leaderGroupWiseStartDate >= leaderGroupWiseEndDate) {
      setMessage('error', 'Selected date is Invalid.')
      setLoading(false)
      return
    }

    await axios
      .get(`${LEADERGROUPACTIVITYCUSTOMDATA}?startdate=${leaderGroupWiseStartDate}&enddate=${leaderGroupWiseEndDate}`, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          token,
        },
        responseType: 'blob',
      })
      .then((res: any) => {
        const content = res.headers['content-type']
        setLoading(false)
        download(res.data, 'Leader group activity reports.xls', content)
      })
      .catch((error: any) => {
        setLoading(false)
        console.log('error', error)
      })
  }

  const handleBIReportExport = async () => {
    setLoading(true)
    if (!biReportStartDate || !biReportEndDate) {
      setMessage('error', 'Please select both Start Date and End Date')
      setLoading(false)
      return
    }
    console.log(biReportStartDate, 'biReportStartDate')
    if (biReportStartDate >= biReportEndDate) {
      setMessage('error', 'Selected date is Invalid.')

      setLoading(false)
    } else {
      await axios
        .get(`${MONTHLYBI_REPORT}?startdate=${biReportStartDate}&enddate=${biReportEndDate}`, {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            token,
          },
          responseType: 'blob',
        })
        .then((res: any) => {
          const content = res.headers['content-type']
          setLoading(false)
          download(res.data, 'bi reports.xls', content)
        })
        .catch((error: any) => {
          setLoading(false)
          console.log('error', error)
        })
    }
  }

  // export excel
  const handleGoalVsMrReportExport = async () => {
    setLoading(true)
    if (!goalVsMrReportStartDate || !goalVsMrReportEndDate) {
      setMessage('error', 'Please select both Start Date and End Date')
      setLoading(false)
      return
    }
    if (goalVsMrReportStartDate >= goalVsMrReportEndDate) {
      setMessage('error', 'Selected date is Invalid.')
      setLoading(false)
      return
    }
    await axios
      .get(
        `${LEADERGOALVSMRREPORT}?startdate=${goalVsMrReportStartDate}&enddate=${goalVsMrReportEndDate}`,
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            token,
          },
          responseType: 'blob',
        }
      )
      .then((res: any) => {
        const content = res.headers['content-type']
        setLoading(false)
        download(res.data, 'goal vs mr reports.xls', content)
      })
      .catch((error: any) => {
        setLoading(false)
        console.log('error', error)
      })
  }

  // export pdf
  const handleExportGiReport = async () => {
    setLoading(true)
    await axios
      .get(
        `${EXPORT_GI_DATA}?filter[where][leader_isdelete]=0&filter[where][chapter_id]=${selectChapterId}&filter[where][month]=${selectedMonth}&filter[where][year]=${selectYear}`,
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            token,
          },
          responseType: 'blob',
        }
      )
      .then((res: any) => {
        const content = res.headers['content-type']
        setLoading(false)
        download(res.data, 'Gi reports.pdf', content)
      })
      .catch((error: any) => {
        setLoading(false)
        console.log('error', error)
      })
  }

  const copyTable = () => {
    try {
      // Get a reference to the table
      let table = document.getElementById('copy_kt_table_monthlyreports') as any

      // Make the table briefly visible
      table.style.display = 'table'

      // Create a range object to select the table content
      const range = document.createRange()
      range.selectNode(table)

      // Create a selection object and add the range
      const selection = window.getSelection() as any
      selection.removeAllRanges()
      selection.addRange(range)

      // Execute the copy command
      document.execCommand('copy')

      // Deselect the content
      selection.removeAllRanges()

      // Hide the table again (if it was originally hidden)
      table.style.display = 'none'

      // Optionally, provide feedback to the user
      alert('Table data copied to clipboard')
    } catch (err) {
      // Get a reference to the table
      let table = document.getElementById('copy_kt_table_monthlyreports') as any

      // Hide the table again (if it was originally hidden)
      table.style.display = 'none'
    }
  }

  function ExportToExcel() {
    try {
      // Acquire Data (reference to the HTML table)
      let table = document.getElementById('copy_kt_table_monthlyreports') as any

      // Make the table briefly visible
      table.style.display = 'table'

      // Extract Data (create a workbook object from the table)
      var workbook = XLSX.utils.table_to_book(table)

      // Hide the table again (if it was originally hidden)
      table.style.display = 'none'

      // Process Data (add a new row)
      var ws = workbook.Sheets['Sheet1']
      // XLSX.utils.sheet_add_aoa(ws, [["Created "+new Date().toISOString()]], {origin:-1});

      // Package and Release Data (`writeFile` tries to write and save an XLSB file)
      XLSX.writeFile(workbook, 'monthlyReportExcel.xlsb')
    } catch (err) {
      // Get a reference to the table
      let table = document.getElementById('copy_kt_table_monthlyreports') as any

      // Hide the table again (if it was originally hidden)
      table.style.display = 'none'
    }
  }

  function convertTableToCSV() {
    try {
      let table = document.getElementById('copy_kt_table_monthlyreports') as any

      // Make the table briefly visible
      table.style.display = 'table'

      const rows = table.querySelectorAll('tr') as any
      let csv = []

      for (const row of rows) {
        const cells = row.querySelectorAll('td, th') as any
        const rowData = Array.from(cells).map((cell: any) => cell.textContent)
        csv.push(rowData.join(','))
      }

      // Hide the table again (if it was originally hidden)
      table.style.display = 'none'

      const csvContent = 'data:text/csv;charset=utf-8,' + csv.join('\n')
      const encodedURI = encodeURI(csvContent)
      const link = document.createElement('a')
      link.setAttribute('href', encodedURI)
      link.setAttribute('download', 'monthlyReportCsv.csv')
      document.body.appendChild(link)
      link.click()
    } catch (err) {
      // Get a reference to the table
      let table = document.getElementById('copy_kt_table_monthlyreports') as any

      // Hide the table again (if it was originally hidden)
      table.style.display = 'none'
    }
  }
  const convertTableToPrint = () => {
    try {
      // Define the source table
      let table = document.getElementById('copy_kt_table_monthlyreports') as any

      // Make the table briefly visible
      table.style.display = 'table'

      html2canvas(document.querySelector('#copy_kt_table_monthlyreports') as any).then(function (
        canvas: any
      ) {
        let imgWidth = 210
        let pageHeight = 290
        let imgHeight = (canvas.height * imgWidth) / canvas.width
        let heightLeft = imgHeight

        let doc = new jsPDF('p', 'mm')
        let position = 0
        let pageData = canvas.toDataURL('image/jpeg', 1.0)
        let imgData = encodeURIComponent(pageData)
        doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight)
        doc.setLineWidth(5)
        doc.setDrawColor(255, 255, 255)
        doc.rect(0, 0, 210, 295)
        heightLeft -= pageHeight

        while (heightLeft >= 0) {
          position = heightLeft - imgHeight
          doc.addPage()
          doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight)
          doc.setLineWidth(5)
          doc.setDrawColor(255, 255, 255)
          doc.rect(0, 0, 210, 295)
          heightLeft -= pageHeight
        }
        doc.autoPrint()
        //This is a key for printing
        doc.output('dataurlnewwindow')
      })

      // Hide the table again (if it was originally hidden)
      table.style.display = 'none'
    } catch (err) {
      // Get a reference to the table
      let table = document.getElementById('copy_kt_table_monthlyreports') as any

      // Hide the table again (if it was originally hidden)
      table.style.display = 'none'
    }
  }

  useEffect(() => {
    fetchGetAverageFeedback()
    fetchGetAttendanceGraph()
    fetchGroupAll()
    fetchTrackSheetCount()
    fetchGetInvitedAttendedRatio()
    fetchChapterDetail()
    fetchUserDetail()
    fetchTopPerformersData()
    // fetchMonthlyReportData()
    if (!selectChapterId) {
      setSelectChapterId('4')
    }
    if (!selectYear) {
      setSelectYear('2023')
    }
    if (!selectMonth) {
      setSelectMonth(currentMonth.toString())
      setMonthList([{value: currentMonth.toString(), label: currentMonthName}])
    }
  }, [selectChapterId, selectYear, monthList])

  useEffect(() => {
    getYearsArray()
  }, [])

  function getMinDate() {
    const today = new Date()
    const day = String(today.getDate()).padStart(2, '0')
    const month = String(today.getMonth() + 1).padStart(2, '0')
    const year = today.getFullYear()

    // Format the date in YYYY-MM-DD (ISO format)
    const minDate = `${year}-${month}-${day}`

    return minDate
  }

  function getYearsArray() {
    let yearsArray = []
    for (let year = 2020; year <= new Date().getFullYear(); year++) {
      yearsArray.push({value: year.toString(), label: year.toString()})
    }
    setYearsList(yearsArray)
  }

  const months = [
    {value: '1', label: 'January'},
    {value: '2', label: 'February'},
    {value: '3', label: 'March'},
    {value: '4', label: 'April'},
    {value: '5', label: 'May'},
    {value: '6', label: 'June'},
    {value: '7', label: 'July'},
    {value: '8', label: 'August'},
    {value: '9', label: 'September'},
    {value: '10', label: 'October'},
    {value: '11', label: 'November'},
    {value: '12', label: 'December'},
  ]

  return (
    <>
      <div className='your-component-container'>
        {loading && (
          <div className='loading-screen'>
            <Spinner animation='grow' variant='danger'></Spinner>
            <div className='text-primary'> Loading...</div>
          </div>
        )}
        <h1 className='inv-hst'> Monthly Report </h1>

        <div className='my-8 pt-8 pb-5 px-4 px-sm-6 card mx-3 '>
          <h3 className='text-primary fs-4 fw-bold mb-6 ps-3'> Leader Activity Report </h3>
          <div className='row mx-0 align-items-center'>
            <div className='col-sm-12 col-md-5 col-lg-5 col-xl-5 mb-5'>
              <h3 className='text-dark fs-5 fw-bold'> Start Date </h3>
              <input
                type='date'
                className='form-control'
                placeholder=' '
                aria-label=''
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                aria-describedby='addon-wrapping'
                max={getMinDate()}
              />
            </div>
            <div className='col-sm-12 col-md-5 col-lg-5 col-xl-5 mb-5'>
              <h3 className='text-dark fs-5 fw-bold'> End Date </h3>
              <input
                type='date'
                className='form-control'
                placeholder=' '
                aria-label=''
                aria-describedby='addon-wrapping'
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                max={getMinDate()}
              />
            </div>
            <div className='col-sm-12 col-md-2 col-lg-2 col-xl-2 text-end '>
              <button
                type='button'
                className='btn px-5 py-4 btn-success w-100 mb-3 mt-6'
                onClick={handleLeaderActivityReportExport}
              >
                {' '}
                Export{' '}
              </button>
            </div>
          </div>
          <p className='text-danger ps-3 fs-7 fw-bold'>
            {' '}
            *It will take approx 5 minutes to generate a report.
          </p>
        </div>

        {(userDetail?.iscaptain == 1 || userDetail?.iscaptain == true) ?
        <div className='my-8 pt-8 pb-5 px-4 px-sm-6 card mx-3 '>
          <h3 className='text-primary fs-4 fw-bold mb-6 ps-3'> Leader Group Activity Report </h3>
          <div className='row mx-0 align-items-center'>
            <div className='col-sm-12 col-md-5 col-lg-5 col-xl-5 mb-5'>
              <h3 className='text-dark fs-5 fw-bold'> Start Date </h3>
              <input
                type='date'
                className='form-control'
                placeholder=' '
                aria-label=''
                value={leaderGroupWiseStartDate}
                onChange={(e) => setLeaderGroupWiseStartDate(e.target.value)}
                aria-describedby='addon-wrapping'
                max={getMinDate()}
              />
            </div>
            <div className='col-sm-12 col-md-5 col-lg-5 col-xl-5 mb-5'>
              <h3 className='text-dark fs-5 fw-bold'> End Date </h3>
              <input
                type='date'
                className='form-control'
                placeholder=' '
                aria-label=''
                aria-describedby='addon-wrapping'
                value={leaderGroupWiseEndDate}
                onChange={(e) => setLeaderGroupWiseEndDate(e.target.value)}
                max={getMinDate()}
              />
            </div>
            <div className='col-sm-12 col-md-2 col-lg-2 col-xl-2 text-end '>
              <button
                type='button'
                className='btn px-5 py-4 btn-success w-100 mb-3 mt-6'
                onClick={handleLeaderGroupActivityReportExport}
              >
                {' '}
                Export{' '}
              </button>
            </div>
          </div>
          <p className='text-danger ps-3 fs-7 fw-bold'>
            {' '}
            *It will take approx 5 minutes to generate a report.
          </p>
        </div> : "" }

        <div className='my-8 pt-8 pb-5 px-4 px-sm-6 card mx-3 '>
          <h3 className='text-primary fs-4 fw-bold mb-6 ps-3'> Leader Business Index Report </h3>
          <div className='row mx-0'>
            <div className='col-sm-12 col-md-5 col-lg-5 col-xl-5 mb-5'>
              <h3 className='text-dark fs-5 fw-bold'> Start Date </h3>
              <input
                type='date'
                className='form-control'
                placeholder=' '
                aria-label=''
                aria-describedby='addon-wrapping'
                value={biReportStartDate}
                onChange={(e) => setBiReportStartDate(e.target.value)}
                max={getMinDate()}
              />
            </div>
            <div className='col-sm-12 col-md-5 col-lg-5 col-xl-5 mb-5'>
              <h3 className='text-dark fs-5 fw-bold'> End Date </h3>
              <input
                type='date'
                className='form-control'
                placeholder=' '
                aria-label=''
                aria-describedby='addon-wrapping'
                value={biReportEndDate}
                onChange={(e) => setBiReportEndDate(e.target.value)}
                max={getMinDate()}
              />
            </div>
            <div className='col-sm-12 col-md-2 col-lg-2 col-xl-2 text-end'>
              <button
                type='button'
                className='btn px-5 py-4 btn-success w-100 mb-3 mt-6'
                onClick={handleBIReportExport}
              >
                {' '}
                Export{' '}
              </button>
            </div>
          </div>
          <p className='text-danger ps-3 fs-7 fw-bold'>
            {' '}
            *It will take approx 5 minutes to generate a report.
          </p>
        </div>

        <div className='my-8 pt-8 pb-5 px-4 px-sm-6 card mx-3 '>
          <h3 className='text-primary fs-4 fw-bold mb-6 ps-3'> Goal Vs MR Report </h3>
          <div className='row mx-0'>
            <div className='col-sm-12 col-md-5 col-lg-5 col-xl-5 mb-5'>
              <h3 className='text-dark fs-5 fw-bold'> Start Date </h3>
              <input
                type='date'
                className='form-control'
                placeholder=' '
                aria-label=''
                aria-describedby='addon-wrapping'
                value={goalVsMrReportStartDate}
                onChange={(e) => setGoalVsMrReportStartDate(e.target.value)}
                max={getMinDate()}
              />
            </div>
            <div className='col-sm-12 col-md-5 col-lg-5 col-xl-5 mb-5'>
              <h3 className='text-dark fs-5 fw-bold'> End Date </h3>
              <input
                type='date'
                className='form-control'
                placeholder=' '
                aria-label=''
                aria-describedby='addon-wrapping'
                value={goalVsMrReportEndDate}
                onChange={(e) => setGoalVsMrReportEndDate(e.target.value)}
                max={getMinDate()}
              />
            </div>
            <div className='col-sm-12 col-md-2 col-lg-2 col-xl-2 text-end'>
              <button
                type='button'
                className='btn px-5 py-4 btn-success w-100 mb-3 mt-6'
                onClick={handleGoalVsMrReportExport}
              >
                {' '}
                Export{' '}
              </button>
            </div>
          </div>
          <p className='text-danger ps-3 fs-7 fw-bold'>
            {' '}
            *It will take approx 5 minutes to generate a report.
          </p>
        </div>

        <div className='my-8 pt-8 pb-5 px-4 px-sm-6 card mx-3 '>
          <h3 className='text-primary fs-4 fw-bold mb-6 ps-3'> GI Data </h3>
          <div className='row mx-0'>
            <div className='col-sm-12 col-md-6 col-lg-6 col-xl-3 mb-5'>
              <h3 className='text-dark fs-5 fw-bold'> Chapter </h3>
              <select
                className='form-select form-select-lg mb-3 mt-3'
                aria-label='.form-select-lg example'
                value={selectChapterId}
                onChange={(e: any) => {
                  let filter = state?.filter ?? {}
                  filter['chapter_id'] = e.target.value
                  updateState({...state, ...{filter: filter}})
                  setSelectChapterId(e.target.value)
                }}
              >
                <option value={''}>All</option>
                {chapterData?.map((item: any, index: any) => (
                  <option value={item.id}>{item.name}</option>
                ))}
              </select>
            </div>
            <div className='col-sm-12 col-md-6 col-lg-6 col-xl-3 mb-5'>
              <h3 className='text-dark fs-5 fw-bold'> Year </h3>
              <select
                className='form-select form-select-lg mb-3 mt-3'
                aria-label='.form-select-lg example'
                value={selectYear}
                onChange={(e: any) => {
                  setSelectYear(e.target.value)
                  let filter = state?.filter ?? {}
                  filter['year'] = e.target.value
                  updateState({...state, ...{filter: filter}})
                }}
              >
                {yearsList.map((yearOption:any) => (
                  <option value={yearOption.value}>
                    {yearOption.label}
                  </option>
                ))}
              </select>
            </div>
            <div className='col-sm-12 col-md-6 col-lg-6 col-xl-3 mb-5'>
              <h3 className='text-dark fs-5 fw-bold'> Month </h3>
              <Multiselect
                style={{
                  optionContainer: {
                    bottom: '1px',
                    menuPlacement: 'auto',
                    menuPosition: 'fixed',
                    maxHeight: '200px',
                  },
                }}
                className='form-select p-0'
                options={months}
                displayValue='label'
                onSelect={(selectedList) => {
                  const updatedList = selectedList.map((item: any) => {
                    if (typeof item === 'string') {
                      return {
                        id: item,
                        name: '',
                      }
                    }
                    return item
                  })
                  setMonthList(updatedList)
                }}
                onRemove={(selectedList, removedItem) => {
                  const updatedList = selectedList.filter((item: any) => {
                    return item !== removedItem
                  })
                  setMonthList(updatedList)
                }}
                selectedValues={monthList}
              />
            </div>
            <div className='col-sm-12 col-md-6 col-lg-6 col-xl-3 mb-5'>
              <h3 className='text-dark fs-5 fw-bold'> Group </h3>
              <select
                className='form-select form-select-lg mb-3 mt-3'
                aria-label='.form-select-lg example'
              >
                <option selected>All</option>
                {groupData?.map((item: any, index: any) => (
                  <option value={item.id}>{item.name}</option>
                ))}
              </select>
            </div>
            <div className='col-sm-12 col-md-6 col-lg-6 col-xl-3 mb-5'>
              <h3 className='text-dark fs-5 fw-bold'> Leader </h3>
              <select
                className='form-select form-select-lg mb-3 mt-3'
                aria-label='.form-select-lg example'
              >
                <option selected>All</option>
              </select>
            </div>

            <div className='col-md-6 col-lg-6 col-xl-9 d-flex flex-column text-start text-sm-end  mb-5'>
              {/* <h3 className='text-primary fs-5 fw-bold'> Export GI data </h3> */}
              <div className=''>
                <button
                  type='button'
                  className='btn px-5 py-4 btn-success w-100 w-sm-auto mb-3 mt-2'
                  onClick={handleExportGiReport}
                >
                  {' '}
                  Export{' '}
                </button>
              </div>
              <p className='text-danger fs-7 fw-normal'> *Please select the Chapter and month </p>
            </div>
          </div>
          {/* <div className="d-flex justify-content-start mb-5">
            <p className="text-danger fs-7 fw-normal"> *Please select the Chapter and month </p>
          </div> */}
        </div>

        <div className='my-8 py-8 px-4 px-sm-6 card mx-3 mx-md-0 ilead__card'>
          <div className='row mx-0 mb-6'>
            <div className='col-md-12 col-lg-6 col-xl-6'>
              <h3 className='text-primary fs-3 fw-bold mb-6 mb-5'>
                {' '}
                Average Session Feedback Rating{' '}
              </h3>
            </div>
            <div className='col-md-12 col-lg-6 col-xl-6 text-end'>
              <p className='mb-0 fs-5 text-info fw-semibold'>
                {' '}
                Value Addition Session Average Rating -{' '}
                {Number(getAverageFeedback?.averageva).toFixed(2)}{' '}
              </p>
              <p className='mb-0 fs-5 text-info fw-semibold'>
                {' '}
                TYCA Session Average Rating - {Number(getAverageFeedback?.averagetyca).toFixed(
                  2
                )}{' '}
              </p>
              <p className='mb-0 fs-5 text-info fw-semibold'>
                {' '}
                Success Sharing Average Rating - {Number(getAverageFeedback?.averagess).toFixed(
                  2
                )}{' '}
              </p>
            </div>
          </div>
          <MonthlyReportBarChart getAverageFeedback={getAverageFeedback} />
        </div>

        <div className='my-8 pt-8 pb-5 px-4 px-sm-6 card mx-3 '>
          <div className='row m-0 comn-field'>
            <h3 className='text-primary fs-4 fw-bold mb-6 ps-3'> Session Statistics </h3>
            <div className='table-responsive px-0'>
              <table className='table table-striped text-center'>
                <thead>
                  <tr className='bg-primary text-white fw-bold fs-6'>
                    <th
                      scope='col'
                      colSpan={2}
                      className='border-right border border-white-800 ps-3'
                    >
                      Attandance
                    </th>
                    <th scope='col' colSpan={1} className='border-right border border-white-800'>
                      {' '}
                      Session Feedback{' '}
                    </th>
                    <th scope='col' colSpan={2}>
                      Tracksheet
                    </th>
                  </tr>
                  <tr className='bg-primary text-white fw-bold fs-6'>
                    <th scope='col' className='border-right border border-white-800 ps-3 '>
                      {' '}
                      Presence{' '}
                    </th>
                    <th scope='col' className='border-right border border-white-800'>
                      {' '}
                      Absence{' '}
                    </th>
                    <th scope='col' className='border-right border border-white-800'>
                      {' '}
                      Received{' '}
                    </th>
                    <th scope='col' className='border-right border border-white-800'>
                      {' '}
                      Submitted{' '}
                    </th>
                    <th scope='col' className='border-right border border-white-800'>
                      {' '}
                      Note Submitted{' '}
                    </th>
                  </tr>
                </thead>
                <tbody className='text-dark'>
                  <tr>
                    <td data-label='Presence' className='ps-3 '>
                      {' '}
                      {attandanceGraph ? `${attandanceGraph?.attendadantPercentage}` : 0}{' '}
                    </td>
                    <td data-label='Absence' className=''>
                      {' '}
                      {attandanceGraph ? `${attandanceGraph?.absentPercentage}` : 0}{' '}
                    </td>
                    <td data-label='Received' className=''>
                      {' '}
                      {getAverageFeedback ? `${getAverageFeedback?.given}` : 0}
                    </td>
                    <td data-label='Submitted' className=''>
                      {' '}
                      {trackSheetCount ? `${trackSheetCount?.tracksheetcount}` : 0}{' '}
                    </td>
                    <td data-label='Note Submitted' className=''>
                      {' '}
                      {trackSheetCount ? `${trackSheetCount?.notsubmitted}` : 0}{' '}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className='table-responsive px-0'>
              <table className='table table-striped mt-10 text-center'>
                <thead>
                  <tr className='bg-primary text-white fw-bold fs-6'>
                    <th scope='col' colSpan={4} className='border-right border-white-800 ps-3'>
                      Visitor
                    </th>
                  </tr>
                  <tr className='bg-primary text-white fw-bold fs-6'>
                    <th scope='col' className='border-right border border-white-800 ps-3 '>
                      {' '}
                      Invited{' '}
                    </th>
                    <th scope='col' className='border-right border border-white-800'>
                      {' '}
                      Attended{' '}
                    </th>
                    <th scope='col' className='border-right border border-white-800'>
                      {' '}
                      Joined{' '}
                    </th>
                    <th scope='col' className='border-right border border-white-800'>
                      {' '}
                      Conversion Ratio{' '}
                    </th>
                  </tr>
                </thead>
                <tbody className='text-dark'>
                  <tr>
                    <td data-label='Invited' className='ps-3 '>
                      {' '}
                      {inviteAttendedJoinRatio ? `${inviteAttendedJoinRatio?.invitedsum}` : 0}{' '}
                      {/* inviteAttendedJoinRatio */}
                    </td>
                    <td data-label='Attended' className=''>
                      {' '}
                      {inviteAttendedJoinRatio ? `${inviteAttendedJoinRatio?.attendedsum}` : 0}{' '}
                    </td>
                    <td data-label='Joined' className=''>
                      {' '}
                      {inviteAttendedJoinRatio ? `${inviteAttendedJoinRatio?.joinedsum}` : 0}{' '}
                    </td>
                    <td data-label='Conversion Ratio ' className=''>
                      {' '}
                      {conversionRatio ? conversionRatio : 0}{' '}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div className='my-8 pt-8 pb-5 px-4 px-sm-6 card mx-3 '>
          <div className='row m-0 justify-content-between comn-field'>
            <div className='col-md-5 '>
              <div className='row mx-0 mb-6'>
                <div className='col-md-12 col-lg-6 col-xl-6'>
                  <h3 className='text-primary fs-3 fw-bold mb-6 mb-5'> Session Attandance </h3>
                </div>
              </div>

              {attandanceGraph?.attendadantPercentage && attandanceGraph?.absentPercentage ? (
                <>
                  <MonthlyReportPieChart attandanceGraph={attandanceGraph} />
                </>
              ) : (
                <div style={{textAlign: 'center'}}>
                  <strong>No matching records found</strong>
                </div>
              )}
            </div>
            <div className='col-md-5 '>
              <div className='row mx-0 mb-6'>
                <div className='col-md-12 col-lg-6 col-xl-6'>
                  <h3 className='text-primary fs-3 fw-bold mb-6 mb-5'> Visitor </h3>
                </div>
              </div>
              {inviteAttendedJoinRatio?.invitedsum &&
              inviteAttendedJoinRatio?.attendedsum &&
              inviteAttendedJoinRatio?.joinedsum ? (
                <>
                  <MonthlyReportVisitorDoughnutChart
                    inviteAttendedJoinRatio={inviteAttendedJoinRatio}
                  />
                </>
              ) : (
                <div style={{textAlign: 'center'}}>
                  <strong>No matching records found</strong>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className='my-8 pt-8 pb-5 px-4 px-sm-6 card mx-3 '>
          <div className='row m-0 top-five-prfmr'>
            <h3 className='text-primary fs-4 fw-bold mb-6'> Top 5 Performar </h3>

            <div className='all bigi-members d-flex overflow-y-auto comn-field'>
              {top5Performers?.length > 0 ? (
                <>
                  {top5Performers?.map((item: any, index: any) => (
                    <div className='member-box '>
                      <div className='member-pic'>
                        <Image
                          src={
                            item?.profile
                              ? `${API_URL}/containers/userprofile/download/${item?.profile}`
                              : `${toAbsoluteUrl('./media/avatars/default-user.png')}`
                          }
                          onError={({currentTarget}: any) => {
                            currentTarget.onerror = null
                            currentTarget.src = `${toAbsoluteUrl(
                              './media/avatars/default-user.png'
                            )}`
                          }}
                          className='w-100'
                        />
                      </div>
                      <div className='member-details position-absolute p-3 w-100 bottom-0 d-flex flex-column justify-content-end'>
                        <span className='points fw-bold ilead-text'>{item?.total_points}</span>
                        <h6 className='text-white text-truncate fw-normal mb-0'>{item?.name}</h6>
                      </div>
                    </div>
                  ))}
                </>
              ) : (
                <div style={{textAlign: 'center'}}>
                  <strong>No matching records found</strong>
                </div>
              )}

              {/* <div className='member-box '>
                <div className='member-pic'>
                  <Image
                    src={toAbsoluteUrl('/media/ilead/pulin-patel.jpg')}
                    className='position-absolute w-100 h-100 object-fit-cover'
                  ></Image>
                </div>
                <div className='member-details position-relative p-3 w-100 h-100 d-flex flex-column justify-content-end'>
                  <span className='points fw-bold ilead-text'>973</span>
                  <h6 className='text-white text-truncate fw-normal mb-0'>Punit Patel</h6>
                </div>
              </div> */}
              {/* <div className='member-box '>
                <div className='member-pic'>
                  <Image
                    src={toAbsoluteUrl('/media/ilead/kirit-patel.jpg')}
                    className='position-absolute w-100 h-100 object-fit-cover'
                  ></Image>
                </div>
                <div className='member-details position-relative p-3 w-100 h-100 d-flex flex-column justify-content-end'>
                  <span className='points fw-bold ilead-text'>973</span>
                  <h6 className='text-white text-truncate fw-normal mb-0'>Kirit Patel </h6>
                </div>
              </div> */}
              {/* <div className='member-box '>
                <div className='member-pic'>
                  <Image
                    src={toAbsoluteUrl('/media/ilead/raja-chainani.jpg')}
                    className='position-absolute w-100 h-100 object-fit-cover'
                  ></Image>
                </div>
                <div className='member-details position-relative p-3 w-100 h-100 d-flex flex-column justify-content-end'>
                  <span className='points fw-bold ilead-text'>973</span>
                  <h6 className='text-white text-truncate fw-normal mb-0'>Raja Chainani</h6>
                </div>
              </div> */}
              {/* <div className='member-box '>
                <div className='member-pic'>
                  <Image
                    src="toAbsoluteUrl('./media/ilead/prashant-desai.jpg)"
                    className='position-absolute w-100 h-100 object-fit-cover'
                  ></Image>
                </div>
                <div className='member-details position-relative p-3 w-100 h-100 d-flex flex-column justify-content-end'>
                  <span className='points fw-bold ilead-text'>973</span>
                  <h6 className='text-white text-truncate fw-normal mb-0'> Prashant Desai </h6>
                </div>
              </div> */}
            </div>
          </div>
        </div>

        <div className='my-8 pt-8 pb-5 px-4 px-sm-6 card mx-3 '>
          <div className='row m-0 comn-field'>
            <h3 className='text-primary fs-4 fw-bold mb-6 ps-3'> Leaders </h3>
            <div className='full-form-main'>
              <ul className='ng-tns-c6-0 list-style-none ps-0'>
                <li className='ng-tns-c6-0 d-inline-block p-2 text-dark'>A - Attandance</li>
                <li className='ng-tns-c6-0 d-inline-block p-2 text-dark'>VI - Visitor invition</li>
                <li className='ng-tns-c6-0 d-inline-block p-2 text-dark'>
                  VA - Visitor attandance
                </li>
                <li className='ng-tns-c6-0 d-inline-block p-2 text-dark'>VJ - Visior joined</li>
                <li className='ng-tns-c6-0 d-inline-block p-2 text-dark'>TG - Thank you given</li>
                <li className='ng-tns-c6-0 d-inline-block p-2 text-dark'>
                  TR - Thank you received
                </li>
                <li className='ng-tns-c6-0 d-inline-block p-2 text-dark'>
                  GMA - Group meeting attendance
                </li>
                <li className='ng-tns-c6-0 d-inline-block p-2 text-dark'>
                  TU - TrackSheet Uploaded
                </li>
                <li className='ng-tns-c6-0 d-inline-block p-2 text-dark'>FG - Feedback given</li>
                <li className='ng-tns-c6-0 d-inline-block p-2 text-dark'>
                  ICIW - I CAN I WILL Message
                </li>
                <li className='ng-tns-c6-0 d-inline-block p-2 text-dark'>H - Help</li>
                <li className='ng-tns-c6-0 d-inline-block p-2 text-dark'>MR - MR Tracking</li>
              </ul>
            </div>
            <div className='d-flex flex-wrap  justify-content-start mb-3 ps-0 mt-6 '>
              <button
                type='button'
                className='btn px-5 py-2 me-3 mb-3 btn-primary'
                onClick={() => {
                  copyTable()
                }}
              >
                Copy
              </button>
              <button
                type='button'
                className='btn px-5 py-2 mb-3 me-3 btn-success'
                onClick={() => {
                  ExportToExcel()
                }}
              >
                Excel
              </button>
              <button
                type='button'
                className='btn px-5 py-2 mb-3 me-3 btn-warning'
                onClick={() => {
                  convertTableToCSV()
                }}
              >
                CSV
              </button>
              <button
                type='button'
                className='btn px-5 py-2 mb-3 me-3 btn-info'
                onClick={() => {
                  convertTableToPrint()
                }}
              >
                Print
              </button>
            </div>
            <div className='table-responsive px-0'>
              {/* <table className='table table-striped text-start leader-table ' >
<thead>
<tr className="bg-primary text-white fw-bold fs-6">
<th scope='col' style={{ width: '20%' }} className="ps-3 " > Leader Name </th>
<th scope='col' className=""> A </th>
<th scope='col' className=""> VI </th>
<th scope='col' className=""> VA </th>
<th scope='col' className=""> VJ </th>
<th scope='col' className=""> TG </th>
<th scope='col' className=""> TR </th>
<th scope='col' className=""> GMA </th>
<th scope='col' className=""> TU </th>
<th scope='col' className=""> FG </th>
<th scope='col' className=""> H </th>
<th scope='col' className=""> ICIW </th>
<th scope='col' className=""> MR </th>
<th scope='col' className="pe-3 "> Points </th>
</tr>
</thead>
<tbody className="text-dark">
<tr>
<td data-label='Leader Name' style={{ width: '20%' }} className="ps-3 "> Chetan Chavda </td>
<td data-label='A' className="pe-3 "> 0 </td>
<td data-label='VI' className="pe-3 "> 0 </td>
<td data-label='VA' className="pe-3 "> 0 </td>
<td data-label='VJ' className="pe-3 "> 0 </td>
<td data-label='TG' className="pe-3 "> 0 </td>
<td data-label='TR' className="pe-3 "> 0 </td>
<td data-label='GMA' className="pe-3 "> 0 </td>
<td data-label='TU' className="pe-3 "> 0 </td>
<td data-label='FG' className="pe-3 "> 0 </td>
<td data-label='H' className="pe-3 "> 0 </td>
<td data-label='ICIW' className="pe-3 "> 0 </td>
<td data-label='MR' className="pe-3 "> 0 </td>
<td data-label='Points' className="pe-3 "> 0 </td>
</tr>
<tr>
<td data-label='Leader Name' style={{ width: '20%' }} className="ps-3 "> Nirav Agravat </td>
<td data-label='A' className="pe-3 "> 0 </td>
<td data-label='VI' className="pe-3 "> 0 </td>
<td data-label='VA' className="pe-3 "> 0 </td>
<td data-label='VJ' className="pe-3 "> 0 </td>
<td data-label='TG' className="pe-3 "> 4 </td>
<td data-label='TR' className="pe-3 "> 0 </td>
<td data-label='GMA' className="pe-3 "> 0 </td>
<td data-label='TU' className="pe-3 "> 1 </td>
<td data-label='FG' className="pe-3 "> 1 </td>
<td data-label='H' className="pe-3 "> 4 </td>
<td data-label='ICIW' className="pe-3 "> 0 </td>
<td data-label='MR' className="pe-3 "> 0 </td>
<td data-label='Points' className="pe-3 "> 0 </td>
</tr>
<tr>
<td data-label='Leader Name' style={{ width: '20%' }} className="ps-3 "> Prem </td>
<td data-label='A' className=" "> 0 </td>
<td data-label='VI' className=" "> 0 </td>
<td data-label='VA' className=" "> 0 </td>
<td data-label='VJ' className=" "> 0 </td>
<td data-label='TG' className=" "> 0 </td>
<td data-label='TR' className=" "> 0 </td>
<td data-label='GMA' className=" "> 0 </td>
<td data-label='TU' className=" "> 0 </td>
<td data-label='FG' className=" "> 0 </td>
<td data-label='H' className=" "> 0 </td>
<td data-label='ICIW' className=" "> 0 </td>
<td data-label='MR' className=" "> 0 </td>
<td data-label='Points' className=" "> 0 </td>
</tr>
<tr>
<td data-label='Leader Name' style={{ width: '20%' }} className="ps-3 "> Nirav Agravat </td>
<td data-label='A' className=" "> 0 </td>
<td data-label='VI' className=" "> 0 </td>
<td data-label='VA' className=" "> 0 </td>
<td data-label='VJ' className=" "> 0 </td>
<td data-label='TG' className=" "> 4 </td>
<td data-label='TR' className=" "> 0 </td>
<td data-label='GMA' className=" "> 0 </td>
<td data-label='TU' className=" "> 1 </td>
<td data-label='FG' className=" "> 1 </td>
<td data-label='H' className=" "> 4 </td>
<td data-label='ICIW' className=" "> 0 </td>
<td data-label='MR' className=" "> 0 </td>
<td data-label='Points' className=" "> 0 </td>
</tr>
</tbody>
</table> */}

              <MonthlyreportsListWrapper />
            </div>
          </div>

          {/* <div className="row justify-content-between ">
<div className="col-xl-6 col-lg-12 text-dark text-start ps-6 fs-5">
Showing 1 to 100 of 172 entries
</div>
<div className="col-xl-6 col-lg-12 d-flex flex-end">
<nav aria-label="...">
<ul className="pagination">
<li className="page-item disabled">
<span className="page-link">Previous</span>
</li>
<li className="page-item"><a className="page-link" href="#">1</a></li>
<li className="page-item active">
<span className="page-link">
2
<span className="sr-only">(current)</span>
</span>
</li>
<li className="page-item"><a className="page-link" href="#">3</a></li>
<li className="page-item">
<a className="page-link" href="#">Next</a>
</li>
</ul>
</nav>

</div>

</div> */}
        </div>
      </div>
    </>
  )
}

const MonthlyReport = () => {
  return (
    <>
      <QueryRequestProvider>
        <QueryResponseProvider>
          <MonthlyReports />
        </QueryResponseProvider>
      </QueryRequestProvider>
    </>
  )
}

export default MonthlyReport
