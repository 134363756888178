import React, {useState, useEffect, useContext, useRef} from 'react'
import {apiUrl, redirectAdminURL} from '../../../apiurl'
// import {sendInvitation, shareNotification} from './core/_request'
import axios from 'axios'
import {UserContext} from '../../../contextStore'
import {checkDisplayValue} from '../../../utils'
import moment from 'moment'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {send} from 'process'
import { Modal } from 'react-bootstrap'
import { reSendInvitationAPI } from './visitors-list/core/_requests'
import { QUERIES } from '../../../_metronic/helpers'
import { useQueryResponse } from './visitors-list/core/QueryResponseProvider'
import { useQueryClient } from 'react-query'

const API_URL = apiUrl
const CHAPTER_LIST_URL = `${API_URL}/chapters`
const Date_LIST_URL = `${API_URL}/eventdates`

const validationForm = Yup.object().shape({
  eventid: Yup.number().required('event date is required'),
  chapter_id: Yup.string().required('chapter is required')
})

interface ResetDateChapterProps {
  show: boolean;
  // handleClose: () => void;
  id: any;
  setShow: (show: boolean) => void;
}
const ResetDateChapter = ({show, id,setShow }:ResetDateChapterProps ) => {
  const { query } = useQueryResponse()
  const queryClient = useQueryClient()
  const [chapterList, setChapterList] = useState<any>([])
  const [dateList, setDateList] = useState<any>([])
  const [isLoading, setIsLoading] = useState(true)
  const [chapterId, setChapterId] = useState<any>('')
  const {userDetail, setMessage} = useContext(UserContext)
  const [loading, setLoading] = useState(false)
  const todays_date = new Date().toISOString().split('T')[0]
  const closebutton = useRef<any>()

  useEffect(() => {
    fetchChaptersList()
  }, [])

  

  const fetchChaptersList = async () => {
    try {
      const res = await axios.get(
        `${CHAPTER_LIST_URL}?filter[where][show_in_visitor_invitation]=1&filter[where][isdelete]=0`
      )

      setChapterList(res?.data ?? [])
      setIsLoading(false)
    } catch (error) {
      console.error('Error -->:', error)
      setIsLoading(false)
    }
  }

  const fetchDateList = async () => {
    try {
      const res = await axios.get(
        `${Date_LIST_URL}?filter[order]=event_date&filter[limit]=4&filter[where][event_date][gt]=${todays_date}&filter[where][chapter_id]=${chapterId}`
      )

      setDateList(res?.data ?? [])
      setIsLoading(false)
    } catch (error) {
      console.error('Error -->:', error)
      setIsLoading(false)
    }
  }

  const initialValues = {
    chapter_id : '',
    eventid: '',
  }

  

  const formik = useFormik({
    initialValues,
    validationSchema: validationForm,
    onSubmit: async (values, {setStatus, resetForm, setSubmitting}) => {
      // setLoading(true)
      console.log("value ",{...values, historyid :id})
      try {
        await reSendInvitationAPI({...values, historyid :id}).then((res:any)=>{
          console.log("res",res.data)
          if(res?.data?.id ){
            //navigate('/visitor-panel')
            resetForm()
            queryClient.invalidateQueries([`${QUERIES.VISITORS_LIST}-${query}`])
            setMessage('success', 'Invitations send successfully')
            setShow(false)
            // handleClose()
          }
        }).catch((error:any)=>{
          console.log("error",error)
          if (error?.response?.data?.error?.message) {
            console.log("error?.response?",error?.response?.status)
            if(error?.response?.status == 500 )
            {
            setMessage('error', 'Something went wrong')
            }else{

              setMessage('error', error?.response?.data?.error?.message)
            }
          } else {
            setMessage('error', 'Something went wrong')
          }
        })
      } catch (error: any) {
        setMessage('error', error?.message ? error?.message : 'Something Went Wrong')
        setLoading(false)
        resetForm()
        setChapterId('')
      }
    },
  })

  useEffect(() => {
    fetchDateList()
  }, [formik?.values?.chapter_id])

  return (
    <>
      <Modal className='reset-date-psw'show={show}  onHide={()=>{
        formik.resetForm()
        setShow(false)}}>
        <Modal.Header   style={{backgroundColor:"#e3371e"}}   closeButton>
          <Modal.Title style={{color:"white"}}>Select Resend Date And Chapter </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <form method='' action='' className=''onSubmit={formik.handleSubmit} noValidate>
                <div className='row'>
                  <div className='col-md-12 mb-3'>
                    <div className='form-group floating-labels'>
                      <select
                        className='form-select border-top-0 border-left-0 border-right-0 border-bottom rounded-0'
                        aria-label='Default select example'
                        value={formik.values.chapter_id}
                        onChange={(e:any ) =>{ 
                          formik.setFieldValue('chapter_id', e.target.value)
                          formik.setFieldValue("eventid","")
                          setChapterId(e.target.value)
                        }}
                      >
                        <option> Select Chapter </option>
                        {chapterList?.map((i: any) => (
                          <>
                            <option value={i?.id}> {i?.name} </option>
                          </>
                        ))}
                      </select>
                    </div>
                    {formik.touched.chapter_id && formik?.errors.chapter_id && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors?.chapter_id}</span>
                      </div>
                    </div>
                  )}
                  </div>

                  <div className='col-md-12 mb-5'>
                    <div className='form-group floating-labels'>
                      <select
                        className='form-select border-top-0 border-left-0 border-right-0 border-bottom rounded-0'
                        aria-label='Default select example'
                        value={formik.values.eventid}
                        onChange={(e: any) => {
                          formik.setFieldValue('eventid', e.target.value)
                        }}
                      >
                        <option selected style={{background: '6610f2', height: '50px'}}>
                          Select Event Date
                        </option>
                        {dateList?.map((i: any) => (
                          <>
                            <option value={i?.id}>
                              {' '}
                              {checkDisplayValue(moment(i?.event_date).format('MMM DD, YYYY'))}{' '}
                            </option>
                          </>
                        ))}
                      </select>
                    </div>
                    {formik.touched.eventid && formik?.errors.eventid && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors?.eventid}</span>
                      </div>
                    </div>
                  )}
                  </div>
                </div>
                <div className='d-flex justify-content-end'>
                  <button
                    className='btn bg-secondary me-4'
                    type='button'
                    data-bs-dismiss='modal'
                    // id='closemodel'
                    // onClick={handleClose}
                    onClick={() => {
                      setShow(false)
                      formik.resetForm()
                      // setChapterId('')
                      // formik.setFieldValue('eventid', '')
                    }}
                  >
                    Close
                  </button>
                  <button
                    type='submit'
                    className='btn btn-success'
                    disabled={formik.isSubmitting}
                    id='modalresend'
                  >
                    Resend
                  </button>
                </div>
              </form>
        </Modal.Body>
        {/* <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button>
        </Modal.Footer> */}
      </Modal> 
    </>
  )
}

export {ResetDateChapter}
