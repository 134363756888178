import React, { useEffect, useState } from 'react'
import { toAbsoluteUrl } from '../../../_metronic/helpers'
import { Link } from 'react-router-dom'
import { apiUrl } from '../../../apiurl'
import { checkDisplayValue, checkDisplayValueProfile } from '../../../utils'
import { Button } from 'react-bootstrap'
import axios from 'axios'
import moment from 'moment'
import { useParams } from 'react-router-dom'
import { RWebShare } from 'react-web-share'

const API_URL = apiUrl
const LEADER_PROFILE_URL = `${API_URL}/users`

const LeaderView = () => {
  const { id } = useParams()
  const [data, setData] = useState<any>([])
  const [isLoading, setIsLoading] = useState(true)
  const [showPDF, setShowPDF] = useState<boolean>(false)

  useEffect(() => {
    fetchData()
  }, [])

  const fetchData = async () => {
    try {
      const res = await axios.get(`${LEADER_PROFILE_URL}/viewProfile?filter[where][user_id]=${id}`)
      setData(res?.data ?? [])
      setIsLoading(false)
    } catch (error) {
      console.error('Error -->:', error)
      setIsLoading(false)
    }
  }

  const handleButtonClick = (pdf: any) => {
    if (pdf) {
      const url = `${API_URL}/containers/userprofile/download/${pdf}`
      window.open(url, '_blank')
    }
  }

  const searchString = (web: string) => {
    let webData
    if (web.startsWith('www')) {
      webData = `http://${web}`
    } else {
      webData = `${web}`
    }
    return webData
  }

  const styles = {
    borderRadius: '0.475rem',
    boxShadow: '0 0 50px 0 rgb(82 63 105 / 15%)',
    backgroundColor: '#fff',
    color: '#7e8299',
    fontWeight: '500',
    margin: '0',
    width: 'auto',
    padding: '1rem 2rem',
    top: 'calc(50% - 2rem)',
    left: 'calc(50% - 4rem)',
  }

  if (isLoading) {
    return <div style={{ ...styles, position: 'absolute', textAlign: 'center' }}>Processing...</div>
  }

  return (
    <>
      <h1 className='inv-hst mb-3'>Leader Profile</h1>
      <div className='row mobile-padding'>
        <div className='col-12'>
          <div className='card ilead__card mb-5 mb-xl-7'>
            <div className='card-body pt-9 pb-0'>
              <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
                {/* Profile Pic */}
                <div className='me-7 mb-4'>
                  <div className='symbol symbol-100px symbol-lg-160px symbol-fixed position-relative'>
                    <img
                      src={
                        data?.profile
                          ? `${API_URL}/containers/userprofile/download/${data?.profile}`
                          : `${process.env.PUBLIC_URL}/media/icons/duotune/default-user.png`
                        // : `${toAbsoluteUrl('./media/icons/duotune/default-user.png')}`
                      }
                      alt=''
                      className='thumbnail-img'
                      onError={({ currentTarget }: any) => {
                        currentTarget.onerror = null // prevents looping
                        currentTarget.src = `${process.env.PUBLIC_URL}/media/avatars/default-user.png`
                        // currentTarget.src =`${toAbsoluteUrl('./media/icons/duotune/default-user.png')}`
                      }}
                    />
                  </div>
                </div>
                {/* Profile Details */}
                <div className='flex-grow-1'>
                  <div className='d-flex justify-content-between align-items-start flex-wrap mb-2 border-bottom'>
                    <div className='d-flex flex-column'>
                      <div className='d-flex align-items-center mb-2'>
                        <h4 className='text-black text-hover-primary fs-2 fw-bolder me-1'>
                          {checkDisplayValue(data?.name)}
                        </h4>
                      </div>
                      {/* Default View of Profile Name */}
                      <div className='d-flex flex-wrap fs-6 mb-4 pe-2'>
                        <div className='d-flex align-items-center text-gray-400 me-5 mb-2'>
                          <i className="las la-phone-volume text-primary me-2" style={{ fontSize: '18px' }}></i>
                          <a href={`tel: +91 ${data?.mobileno}`} className='text-black'>
                            +91 {checkDisplayValueProfile(data?.mobileno)}
                          </a>
                        </div>
                        <div className='d-flex align-items-center text-gray-400 me-5 mb-2'>
                          <i className="las la-envelope text-primary me-2" style={{ fontSize: '18px' }}></i>
                          <a href={`mailto:${data?.email}`} className='text-black'>
                            {checkDisplayValueProfile(data?.email)}
                          </a>
                        </div>
                      </div>
                    </div>
                    {/* Default view Share and Edit  */}
                    <div className='d-flex my-4'>
                      <RWebShare
                        data={{
                          text: `${data?.name ?? ''}\n ${data?.companyname ?? ''}\n ${data?.mobileno ?? ''
                            }\n ${data?.email ?? ''}\n ${data?.website ?? ''}`,
                          url: `${data?.shortURL ?? ''}`,
                          title: `${data?.name ?? ''}`,
                        }}
                        onClick={() => console.log('shared successfully!')}
                      >
                        <Button
                          variant='primary'
                          className='btn-sm me-2'
                        >
                          <i className="bi bi-share-fill me-2"></i>
                          <span className='indicator-label'>Share</span>
                        </Button>
                      </RWebShare>
                    </div>
                  </div>
                  {/* Default View editable contact details*/}
                  <div className='d-flex flex-wrap flex-stack pt-3'>
                    <div className='d-flex flex-wrap fs-6 mb-4 pe-2'>
                      <div className='d-flex align-items-center text-gray-400 me-3 mb-2'>
                        <i className="las la-suitcase text-dark me-2" style={{ fontSize: '18px' }}></i>
                        <span className='text-black'>
                          {checkDisplayValueProfile(data?.profilestatus)}
                        </span>
                      </div>
                      <div className='d-flex align-items-center text-gray-400 me-3 mb-2'>
                        <i className="las la-phone-volume text-dark me-2" style={{ fontSize: '18px' }}></i>
                        {data?.alternate_mobileno ? (
                          <a href={`tel: +91 ${data?.alternate_mobileno}`} style={{ color: '#3f4254' }}>
                            +91{checkDisplayValueProfile(data?.alternate_mobileno)}
                          </a>
                        ) : (
                          <span className='text-dark'>Not Updated</span>
                        )}
                      </div>
                      <div className='d-flex align-items-center text-gray-400 me-3 mb-2'>
                        <i className="bi bi-globe2 text-dark me-2" style={{ fontSize: '18px' }}></i>
                        {data.website ? (
                          <a
                            href={`${searchString(data.website)}`}
                            target='_blank'
                            className='ml-3 text-dark'
                          >
                            {checkDisplayValueProfile(data?.website)}
                          </a>
                        ) : (
                          <> {checkDisplayValueProfile(data?.website)}</>
                        )}
                      </div>
                    </div>
                    <div className='d-flex flex-wrap fs-6 mb-4 pe-2'>
                      <div className='d-flex align-items-center text-gray-400 me-3 mb-2'>
                        <i className="las la-birthday-cake text-dark me-2" style={{ fontSize: '18px' }}></i>
                        <span className='text-dark'>
                          {data?.birthdate
                            ? checkDisplayValueProfile(moment(data?.birthdate).format('MMM DD, YYYY'))
                            : 'Not Updated'}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='row mobile-padding'>
        {/* My Performer */}
        <div className='col-md-4'>
          <div className='card ilead__card'>
            <div className='card-header'>
              <h3 className='text-black card-title'>Leader Performance</h3>
            </div>
            <div className='card-body'>
              <div className='row leader-perform'>
                <div className='col-6 mb-4'>
                  <div className='leader_box mb-0'>
                    <button
                      type='button'
                      className='thanks_count'
                      data-bs-toggle='modal'
                      data-bs-target=''
                    >
                      {data?.Thank_You_Card_Received ? data?.Thank_You_Card_Received : 0}
                    </button>
                  </div>
                  <div className='p-0 thankyou py-5'>
                    <h4 className='fs-6 text-white fw-bold text-center mb-0'>
                      Thank You Card Received
                    </h4>
                  </div>
                </div>
                <div className='col-6 mb-4'>
                  <div className='leader_box mb-0'>
                    <button
                      type='button'
                      className='thanks_count'
                      data-bs-toggle='modal'
                      data-bs-target=''
                    >
                      {data?.Thank_You_Card_Given ? data?.Thank_You_Card_Given : 0}
                    </button>
                  </div>
                  <div className='p-0 thankyou py-5'>
                    <h4 className='fs-6 text-white fw-bold text-center mb-0'>
                      Thank You Card Given
                    </h4>
                  </div>
                </div>
                <div className='col-6'>
                  <div className='leader_box mb-0'>
                    <button
                      type='button'
                      className='thanks_count'
                      data-bs-toggle='modal'
                      data-bs-target=''
                    >
                      {data?.visitor_invited ? data?.visitor_invited : 0}
                    </button>
                  </div>
                  <div className='p-0 thankyou py-5'>
                    <h4 className='fs-6 text-white fw-bold text-center mb-0'>
                      Visitor(s) Invited
                    </h4>
                  </div>
                </div>
                <div className='col-6'>
                  <div className='leader_box mb-0'>
                    <button
                      type='button'
                      className='thanks_count'
                      data-bs-toggle='modal'
                      data-bs-target=''
                    >
                      {data?.visitors_joined ? data?.visitors_joined : 0}
                    </button>
                  </div>
                  <div className='p-0 thankyou py-5'>
                    <h4 className='fs-6 text-white fw-bold text-center mb-0'>
                      Member(s) Joined
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Company Details */}
        <div className='col-md-8'>
          <div className='card ilead__card mb-7'>
            <div className='card-header'>
              <h3 className='text-black card-title'>Business Details</h3>
            </div>
            <div className='card-body'>
              <div className='row'>
                <div className='col-lg-6 mb-7'>
                  <h4 className='mb-3'>Company Name</h4>
                  <span className='text-black'>
                    {checkDisplayValueProfile(data?.companyname)}
                  </span>
                </div>
                <div className='col-lg-6 mb-7'>
                  <h4 className='mb-3'>Business Category</h4>
                  <span className='text-black'>
                    {checkDisplayValueProfile(data?.business_category)}
                  </span>
                </div>
                <div className='col-lg-6 mb-7'>
                  <h4 className='mb-3'>Company Address</h4>
                  {checkDisplayValueProfile(data?.company_address)}
                </div>
                <div className='col-lg-6 mb-7'>
                  <h4 className='mb-3'>Correspondence Address</h4>
                  {checkDisplayValueProfile(data?.correspndance_address)}
                </div>
                <div className='col-lg-12 mb-7'>
                  <h4 className='mb-3'>Vision</h4>
                  {checkDisplayValueProfile(data?.vision)}
                </div>
                <div className='col-lg-12 mb-7'>
                  <h4 className='mb-3'>Mission</h4>
                  {checkDisplayValueProfile(data?.mission)}
                </div>
                <div className='col-lg-12 mb-7'>
                  <h4 className='mb-4'>
                    Business Card
                  </h4>
                  <div className='row'>
                    <div className='col-md-5'>
                      <img
                        src={
                          data?.business_card_front
                            ? `${API_URL}/containers/userprofile/download/${data?.business_card_front}`
                            : `${process.env.PUBLIC_URL}/media/icons/duotune/business-card.png`
                          // : `${toAbsoluteUrl('./media/icons/duotune/business-card.png')}`
                        }
                        alt='example'
                        className='w-100 h-100 object-fit-cover'
                        onError={({ currentTarget }: any) => {
                          currentTarget.onerror = null // prevents looping
                          currentTarget.src = `${process.env.PUBLIC_URL}/media/icons/duotune/business-card.png`
                          // currentTarget.src = `${toAbsoluteUrl('./media/icons/duotune/business-card.png')}`
                        }}
                      />
                    </div>
                    <div className='col-md-5'>
                      <img
                        src={
                          data?.business_card_back
                            ? `${API_URL}/containers/userprofile/download/${data?.business_card_back}`
                            : `${process.env.PUBLIC_URL}/media/icons/duotune/business-card.png`
                          // : `${toAbsoluteUrl('./media/icons/duotune/business-card.png')}`
                        }
                        alt=''
                        className='w-100 h-100 object-fit-cover'
                        onError={({ currentTarget }: any) => {
                          currentTarget.onerror = null // prevents looping
                          currentTarget.src = `${process.env.PUBLIC_URL}/media/icons/duotune/business-card.png`
                          // currentTarget.src = `${toAbsoluteUrl('./media/icons/duotune/business-card.png')}`
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='card ilead__card'>
            <div className='card-header'>
              <h3 className='text-black card-title'>Other Details</h3>
            </div>
            <div className='card-body'>
              <div className='row mb-5'>
                <div className='col-md-6 mb-7'>
                  <h4 className="mb-4">Organization</h4>
                  {checkDisplayValueProfile(data?.organization)}
                </div>
                <div className='col-md-6 mb-7'>
                  <h4 className="mb-4">Products Of Company</h4>
                  {checkDisplayValueProfile(data?.products_of_company)}
                </div>
                <div className='col-md-6 mb-7'>
                  <h4 className="mb-4">Growth Story Of Business</h4>
                  {checkDisplayValueProfile(data?.growthstory_of_business)}
                </div>
                <div className='col-md-6 mb-7'>
                  <h4 className="mb-4">Achievements</h4>
                  {checkDisplayValueProfile(data?.achievements)}
                </div>
                <div className='col-md-6 mb-7'>
                  <h4 className="mb-4">Business Challanges</h4>
                  {checkDisplayValueProfile(data?.business_challanges)}
                </div>
                <div className='col-md-6 mb-7'>
                  <h4 className="mb-4">Goal For Next 3 To 5 Years</h4>
                  {checkDisplayValueProfile(data?.goal_for_next_years)}
                </div>
                <div className='col-md-6'>
                  <h4 className="mb-4">Membership Enrollment Date</h4>
                  <p className='themes'>
                    <span>
                      <i className='fa fa-calendar-days fs-3 me-2'></i>
                    </span>
                    {data?.membership_enrollment_date
                      ? checkDisplayValue(
                        moment(data?.membership_enrollment_date).format('YYYY-MM-DD')
                      )
                      : '-'}
                  </p>
                </div>
                <div className='col-md-6'>
                  <h4 className="mb-4">Membership Renewal Date</h4>
                  <p className='themes'>
                    <span>
                      <i className='fa fa-calendar-days fs-3 me-2'></i>
                    </span>
                    {data?.membership_renewal_date
                      ? checkDisplayValue(
                        moment(data?.membership_renewal_date).format('YYYY-MM-DD')
                      )
                      : '-'}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className='modal fade'
        id='exampleModal'
        tabIndex={-1}
        // aria-labelledby='exampleModalLabel'
        aria-hidden='true'
      >
        <div className='modal-dialog modal-lg leader-counter-modal ' role='document'>
          <div className='modal-content'>
            <div className='modal-header text-left border-bottom-0 border-radius-0'>
              <h4 className='text-white'>Received Thank You Cards</h4>
              <button type='button' className='close' data-dismiss='modal' aria-label='Close'>
                <span aria-hidden='true' className='text-white fs-2'>
                  ×
                </span>
              </button>
            </div>
            <div className='modal-body border-radius-0'>
              <div className='row mb-4'>
                <div className='col-sm-6 mb-4'>
                  <div className='dataTables_length' id='thankyou_receive_tbl_length'>
                    <label>
                      Show{' '}
                      <select
                        name='thankyou_receive_tbl_length'
                        aria-controls='thankyou_receive_tbl'
                        className=''
                      >
                        <option value='10'>10</option>
                        <option value='25'>25</option>
                        <option value='50'>50</option>
                        <option value='100'>100</option>
                      </select>{' '}
                      entries
                    </label>
                  </div>
                </div>
                <div className='col-sm-6 text-md-end text-sm-start mb-4'>
                  <div id='thankyou_receive_tbl_filter' className='dataTables_filter'>
                    <label>
                      {' '}
                      Search: <input name='myInput' />{' '}
                    </label>
                  </div>
                </div>
              </div>
              <div className='card invitation-history'>
                <table className='table table-striped'>
                  <thead>
                    <tr>
                      <th scope='col'>Id</th>
                      <th scope='col'>Sender Name</th>
                      <th scope='col'> Reason </th>
                      <th scope='col'> Date </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td data-label='Id'> 123 </td>
                      <td data-label='Sender Name'> Aiyub Panara </td>
                      <td data-label='Reason'> Lorem Ipsum </td>
                      <td data-label='Date'> 15th july 2023 </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div className='row mt-8'>
                {' '}
                <nav aria-label='Page navigation example'>
                  <ul className='pagination justify-content-end'>
                    <li className='page-item disabled'>
                      <a className='page-link' href='#' tabIndex={-1}>
                        Previous
                      </a>
                    </li>
                    <li className='page-item'>
                      <a className='page-link' href='#'>
                        1
                      </a>
                    </li>
                    <li className='page-item'>
                      <a className='page-link' href='#'>
                        2
                      </a>
                    </li>
                    <li className='page-item'>
                      <a className='page-link' href='#'>
                        3
                      </a>
                    </li>
                    <li className='page-item'>
                      <a className='page-link' href='#'>
                        Next
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export { LeaderView }
