import { useContext } from 'react'
import { Link } from 'react-router-dom'
import { KTIcon, toAbsoluteUrl } from '../../../_metronic/helpers'
import { FaRegEye } from 'react-icons/fa'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useEffect, useState } from 'react'
import { apiUrl } from '../../../apiurl'
import { checkDisplayValue } from '../../../utils'
import download from 'downloadjs'
import axios from 'axios'
import { getChapterLeader, getGroupFacilitator, getUser } from './core/_requests'
import { UserContext } from '../../../contextStore'
import { accessUser } from '../../../utils/privileges'
const API_URL = apiUrl
const GROUPS_API = `${API_URL}/groups?filter[where][isdelete]=0`
const USERS_API = `${API_URL}/users?filter[where][isdelete]=0&filter[include]=chapters`
const MLREPORT = `${API_URL}/users/memberLeaderReport`

const LeaderDirectory = () => {
  const [data, setData] = useState<any>([])
  const { userDetail, chapterID, reload, token } = useContext(UserContext)
  const [groupFacilitatorData, setGroupFacilitatorData] = useState<any>([])
  const [ChapterData, setChapterData] = useState<any>([])
  const [userData, setUserData] = useState<any>([])
  const [hasMore, setHasMore] = useState(true)
  const [groupId, setGroupId] = useState<any>('')
  const [skip, setSkip] = useState<any>(0)
  const userManagementUsers = accessUser.usermanagement
  const userid = userDetail?.id ?? ''
  const [pevChapterId, setPevChapterId] = useState<any>('')
  const [isLoading, setIsLoading] = useState(true)
  const limit = 10
  const [isShowUserManagement, setIsShowUserManagement] = useState(false)
  useEffect(() => {
    if (userManagementUsers.includes(userid)) {
      setIsShowUserManagement(true)
    }
  }, [])

  useEffect(() => {
    fetchChapterLeaderData()
    fetchGroupFacilitatorData()
    fetchGroupData(chapterID)
  }, [])

  // useEffect(() => {
  // }, [])

  useEffect(() => {
    // setUserData([])
    // setSkip(0)
    // setHasMore(true)
    fetchUserData()
  }, [groupId])

  useEffect(() => {
    setGroupId('')
    fetchChapterLeaderData()
    fetchGroupFacilitatorData()
    fetchGroupData(chapterID)
    fetchUserData()
  }, [reload])

  const fetchUserData = async () => {
    try {
      const resDaTa = await getUser(chapterID, groupId)
      
      let newData = resDaTa ?? []
      setUserData([...newData])

      // setIsLoading(false)
    } catch (error) {
      console.error('Error -->:', error)
      // setIsLoading(false)
    }
  }

  const fetchGroupData = async (c_id: any) => {
    try {
      const res = await axios.get(`${GROUPS_API}&filter[where][chapter_id]=${c_id}`)
      setChapterData(res?.data ?? [])
      // setIsLoading(false)
    } catch (error) {
      console.error('Error -->:', error)
      // setIsLoading(false)
    }
  }
  const MLReport = async () => {
    try {
      await axios
        .get(`${MLREPORT}`, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            token
          },
          responseType: "blob",
        })
        .then((res: any) => {
          const content = res.headers["content-type"];

          download(res.data, "MLreport.xls", content);
        })
        .catch((error: any) => {

          console.log("error", error)
        });

    } catch (err) {
      console.log("error", err)
    }
  }





  const fetchChapterLeaderData = async () => {
    try {
      const responseData = await getChapterLeader(chapterID)
      setData(responseData ?? [])
      setIsLoading(false)
    } catch (error) {
      console.error('Error -->:', error)
      setIsLoading(false)
    }
  }
  const fetchGroupFacilitatorData = async () => {
    try {
      const responseData = await getGroupFacilitator(chapterID)
      setGroupFacilitatorData(responseData ?? [])
      setIsLoading(false)
    } catch (error) {
      console.error('Error -->:', error)
      setIsLoading(false)
    }
  }

  const styles = {
    borderRadius: '0.475rem',
    boxShadow: '0 0 50px 0 rgb(82 63 105 / 15%)',
    backgroundColor: '#fff',
    color: '#7e8299',
    fontWeight: '500',
    margin: '0',
    width: 'auto',
    padding: '1rem 2rem',
    top: 'calc(50% - 2rem)',
    left: 'calc(50% - 4rem)',
  }

  if (isLoading) {
    return <div style={{ ...styles, position: 'absolute', textAlign: 'center' }}>Processing...</div>
  }

  return (
    <>
      {/* <h1 className='inv-hst mb-3 mb-md-0'> Leader Directory </h1> */}
      <div className='row'>
        <h1 className='inv-hst mb-3 mb-md-0 d-md-none mb-5 mb-md-0 px-5'>Leader Directory</h1>
      </div>
      {/* Chapter Leadership */}
      <div className='row mobile-padding mb-5 five-div'>
        <div className='col-12 mb-5 mobile-padding'>
          <div className='d-flex justify-content-between align-items-center'>
            <h1 className='fw-bold fw-bolder'>Chapter Leadership</h1>
            {isShowUserManagement ? (
              <button
                className='btn btn-primary py-4 py-sm-0 waves-effect waves-light text-nowrap'
                style={{ height: '46px' }}
                onClick={() => {
                  MLReport()
                }}
              >
                Export ML Report
              </button>) : null}
          </div>
        </div>

        {data.map((item: any, index: any) => (
          <div className='col-md-3 col-lg-3 col-xl-3 mb-5'>
            <div className='card ilead__card overflow-hidden main__leaders'>
              <div className='card-body p-0'>
                <div className='p-5 border-bottom mobile-border-none border-secondary d-flex d-md-block'>
                  <Link
                    to={`/leaders/${item.id}`}
                    className='symbol symbol-100 bg-white border-dark mb-4 me-4 me-md-0'
                  >
                    <img
                      src={
                        item?.profile
                          ? `${API_URL}/containers/userprofile/download/${item?.profile}`
                          : `${toAbsoluteUrl('./media/avatars/default-user.png')}`
                      }
                      onError={({ currentTarget }: any) => {
                        currentTarget.onerror = null // prevents looping
                        currentTarget.src = `${toAbsoluteUrl('./media/avatars/default-user.png')}`
                      }}
                      className='w-100'
                      alt={checkDisplayValue(item?.name)}
                    />
                  </Link>
                  <div className='leader__details text-truncate'>
                    <Link to={`/leaders/${item.id}`}>
                      <h2 className='fs-6 text-dark leader__name text-md-center text-truncate'>
                        {checkDisplayValue(item.name)}
                      </h2>
                      <div className='leader__chapter mb-5 mb-md-0'>
                        <span className='chapter__name text-primary d-block text-md-center text-truncate'>
                          {checkDisplayValue(item?.title)} -{' '}
                          {checkDisplayValue(item?.chapter_label)}
                        </span>
                      </div>
                    </Link>
                    <div className='leader__contact justify-content-md-center d-flex d-md-none'>
                      <a
                        href={`mailto:${checkDisplayValue(item?.email)}`}
                        className='symbol symbol-40px leader__email me-2'
                      >
                        <div className='symbol-label fs-2 fw-semibold text-primary'>
                          <img src='/media/ilead/mail.svg' alt='' />
                        </div>
                      </a>
                      <a
                        href={`tel:+91${item.mobileno}`}
                        className='symbol symbol-40px leader__mobile me-2'
                      >
                        <div className='symbol-label fs-2 fw-semibold text-success'>
                          <img src='/media/ilead/phone-call.svg' alt='' />
                        </div>
                      </a>
                      {item.website ? (
                        <a
                          href={item.website}
                          target='_blank'
                          className='symbol symbol-40px leader__website'
                        >
                          <div className='symbol-label fs-2 fw-semibold text-primary'>
                            <img src='/media/ilead/website.svg' alt='' />
                          </div>
                        </a>
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                </div>
                <div className='p-5 d-none d-md-block'>
                  <div className='leader__contact justify-content-center d-flex'>
                    <a
                      href={`mailto:${checkDisplayValue(item?.email)}`}
                      className='symbol symbol-40px leader__email me-2'
                    >
                      <div className='symbol-label fs-2 fw-semibold text-primary'>
                        <img src='/media/ilead/mail.svg' alt='' />
                      </div>
                    </a>
                    <a
                      href={`tel:+91${item.mobileno}`}
                      className='symbol symbol-40px leader__mobile me-2'
                    >
                      <div className='symbol-label fs-2 fw-semibold text-success'>
                        <img src='/media/ilead/phone-call.svg' alt='' />
                      </div>
                    </a>
                    {item.website ? (
                      <a
                        href={item.website}
                        target='_blank'
                        className='symbol symbol-40px leader__website'
                      >
                        <div className='symbol-label fs-2 fw-semibold text-primary'>
                          <img src='/media/ilead/website.svg' alt='' />
                        </div>
                      </a>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      {/* Group Facilitator */}
      <div className='row mobile-padding mb-5 five-div'>
        <div className='col-12 mb-5 mobile-padding'>
          <h1 className='fw-bold fw-bolder'> Group Facilitator </h1>
        </div>
        {groupFacilitatorData.map((item: any, index: any) => (
          <div className='col-md-3 col-lg-3 col-xl-3 mb-5'>
            <div className='card ilead__card overflow-hidden main__leaders'>
              <div className='card-body p-0'>
                <div className='p-5 border-bottom mobile-border-none border-secondary d-flex d-md-block'>
                  <Link
                    to={`/leaders/${item.id}`}
                    className='symbol symbol-100 bg-white border-dark mb-4 me-4 me-md-0'
                  >
                    <img
                      src={
                        item?.profile
                          ? `${API_URL}/containers/userprofile/download/${item?.profile}`
                          : `${toAbsoluteUrl('./media/avatars/default-user.png')}`
                      }
                      onError={({ currentTarget }: any) => {
                        currentTarget.onerror = null // prevents looping
                        currentTarget.src = `${toAbsoluteUrl('./media/avatars/default-user.png')}`
                      }}
                      className='w-100'
                      alt={checkDisplayValue(item?.name)}
                    />
                  </Link>
                  <div className='leader__details text-truncate'>
                    <Link to={`/leaders/${item.id}`}>
                      <h2 className='fs-6 text-dark leader__name text-md-center text-truncate'>
                        {checkDisplayValue(item.name)}
                      </h2>
                      <div className='leader__chapter mb-5 mb-md-0'>
                        <span className='chapter__name text-primary d-block text-md-center text-truncate'>
                          {checkDisplayValue(item?.group?.name)}
                        </span>
                      </div>
                    </Link>
                    <div className='leader__contact justify-content-md-center d-flex d-md-none'>
                      <a
                        href={`mailto:${checkDisplayValue(item?.email)}`}
                        className='symbol symbol-40px leader__email me-2'
                      >
                        <div className='symbol-label fs-2 fw-semibold text-primary'>
                          <img src='/media/ilead/mail.svg' alt='' />
                        </div>
                      </a>
                      <a
                        href={`tel:+91${item.mobileno}`}
                        className='symbol symbol-40px leader__mobile me-2'
                      >
                        <div className='symbol-label fs-2 fw-semibold text-success'>
                          <img src='/media/ilead/phone-call.svg' alt='' />
                        </div>
                      </a>
                      {item.website ? (
                        <a
                          href={item.website}
                          target='_blank'
                          className='symbol symbol-40px leader__website'
                        >
                          <div className='symbol-label fs-2 fw-semibold text-primary'>
                            <img src='/media/ilead/website.svg' alt='' />
                          </div>
                        </a>
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                </div>
                <div className='p-5 d-none d-md-block'>
                  <div className='leader__contact justify-content-center d-flex'>
                    <a
                      href={`mailto:${checkDisplayValue(item?.email)}`}
                      className='symbol symbol-40px leader__email me-2'
                    >
                      <div className='symbol-label fs-2 fw-semibold text-primary'>
                        <img src='/media/ilead/mail.svg' alt='' />
                      </div>
                    </a>
                    <a
                      href={`tel:+91${item.mobileno}`}
                      className='symbol symbol-40px leader__mobile me-2'
                    >
                      <div className='symbol-label fs-2 fw-semibold text-success'>
                        <img src='/media/ilead/phone-call.svg' alt='' />
                      </div>
                    </a>
                    {item.website ? (
                      <a
                        href={item.website}
                        target='_blank'
                        className='symbol symbol-40px leader__website'
                      >
                        <div className='symbol-label fs-2 fw-semibold text-primary'>
                          <img src='/media/ilead/website.svg' alt='' />
                        </div>
                      </a>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      {/* ALL LEADERS */}
      <div className='justify-content-end'>
        <div className='d-flex flex-column flex-md-row justify-content-between align-items-center'>
          <h1 className='fw-bold fw-bolder'>Member Leaders</h1>
          <div className='d-flex top__filter'>
            <div className='form-group floating-labels me-3'>
              <select
                className='form-select border-top-0 border-left-0 border-right-0 border-bottom rounded-0'
                aria-label='.form-select-sm example'
                onChange={(event: any) => {
                  setGroupId(+event.target.value)
                }}
                value={groupId}
              >
                <option value=''>All</option>
                {ChapterData.map((obj: any, ind: any) => {
                  return <option value={obj.id}>{obj.name}</option>
                })}
              </select>
            </div>
          </div>
        </div>
      </div>
      <InfiniteScroll
        dataLength={userData.length}
        next={fetchUserData}
        // height={600}
        hasMore={hasMore}
        loader={<></>}
      >
        <div className='row mt-5 mobile-padding'>
          {userData.map((obj: any, ind: any) => {
            return (
              <div className='col-md-6 col-lg-6 col-xl-4' key={ind}>
                <div className='card ilead__card mb-3'>
                  <div className='card-body p-0'>
                    <Link to={`/leaders/${obj.id}`}>
                      <div className='p-5 d-flex border-bottom border-secondary'>
                        <div className='symbol symbol-70px bg-white border-dark'>
                          <img
                            src={
                              obj?.profile
                                ? `${API_URL}/containers/userprofile/download/${obj?.profile}`
                                : `${toAbsoluteUrl('./media/avatars/default-user.png')}`
                            }
                            onError={({ currentTarget }: any) => {
                              currentTarget.onerror = null // prevents looping
                              currentTarget.src = `${toAbsoluteUrl(
                                './media/avatars/default-user.png'
                              )}`
                            }}
                            alt={obj.name}
                          />{' '}
                          {obj?.chapter_id == 5 ? (
                            <div
                              className='trustees__leader position-absolute'
                              style={{ bottom: '-8px', right: '-8px' }}
                            >
                              <img src='/media/ilead/trustees__leader.svg' width={24} />
                            </div>
                          ) : (
                            ''
                          )}
                        </div>
                        <div className='flex-1 ps-4 pt-1 text-truncate pe-3'>
                          <h2 className='fs-6 text-dark leader__name'>{obj.name}</h2>
                          <span
                            className='leader__company text-truncate text-black d-block'
                            style={{ fontWeight: '400' }}
                          >
                            {obj.companyname}
                          </span>
                          <div className='leader__chapter'>
                            <span className='chapter__name text-primary'>
                              {obj?.group?.name}{' '}
                              {obj?.iscaptain == 1 ? <i className='bi bi-gem'></i> : ''}
                            </span>
                          </div>
                        </div>
                        <div className='leader__contact d-flex flex-column'>
                          <a
                            href={`mailto:${obj.email}`}
                            className='symbol symbol-40px leader__email mb-2'
                          >
                            <div className='symbol-label fs-2 fw-semibold text-primary'>
                              <img src='/media/ilead/mail.svg' alt='' />
                            </div>
                          </a>
                          <a
                            href={`tel:+91${obj.mobileno}`}
                            className='symbol symbol-40px leader__mobile'
                          >
                            <div className='symbol-label fs-2 fw-semibold text-success'>
                              <img src='/media/ilead/phone-call.svg' alt='' />
                            </div>
                          </a>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </InfiniteScroll>
    </>
  )
}

export { LeaderDirectory }
