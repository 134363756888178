import React from "react";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useDropzone } from "react-dropzone";
import { useNavigate } from "react-router-dom";



const GroupMeetingForm = () => {

    const { acceptedFiles, getRootProps, getInputProps } = useDropzone()
    const files = acceptedFiles.map((file: any) => <li> {file?.path} - {file?.size} bytes </li>)

    return (

        <>
       
                    <div className="row mb-8">
                        <div className="col-sm-12 col-md-6 col-lg-6">
                            <label className="form-label fs-5">Report Submitted For Group</label>
                            <p className="pb-5 fs-5 fw-semibold text-start text-gray-700"> Beta 1 </p>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-6">
                            <label className="form-label fs-5">Agenda of the meeting <span className="text-danger"> * </span></label>
                            <select className="form-select border border-gray-400" aria-label="Default select example">
                                <option selected>Select Option</option>
                                <option value="1">Tyca Discussion</option>
                                <option value="2">Goal Review</option>
                                <option value="3">Others</option>
                            </select>
                        </div>
                    </div>

                    <div className="row mb-8">
                        <div className="col-sm-12 col-md-12 col-lg-4">
                            <label className="form-label fs-5">Meeting Date <span className="text-danger"> * </span></label>
                            <input type="date" className="form-control" id="" placeholder="" required/>
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-4">
                            <label className="form-label fs-5">Meeting Start Time <span className="text-danger"> * </span></label>
                            <input type="time" className="form-control" id="" placeholder="" required />
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-4">
                            <label className="form-label fs-5">Meeting End Time <span className="text-danger"> * </span></label>
                            <input type="time" className="form-control" id="" placeholder="" required />
                        </div>
                    </div>

                    <div className="row mb-8">
                        <div className="col-sm-12 col-md-12">
                            <label className="form-label mb-3 fs-5">Participants <span className="text-danger"> * </span></label>
                            <div className="row col-8">
                                <div className="col-6">
                                    <div className="form-check checkbox-bdr">
                                        <input className="form-check-input border" type="checkbox" value="" id="flexCheckChecked"   />
                                        <label className="form-check-label" >
                                            Checked checkbox
                                        </label>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="form-check checkbox-bdr">
                                        <input className="form-check-input border" type="checkbox" value="" id="flexCheckChecked" />
                                        <label className="form-check-label" >
                                            Checked checkbox
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-5">
                        <label className="form-label fs-5">What Went Well / What Can Be Improved <span className="text-danger"> * </span></label>
                    </div>
                    <div className="row border mx-0 p-2 align-items-center border-gray-300 box-1">
                        <div className="col-sm-12 col-md-8">
                            Meeting scheduled in advance.
                        </div>
                        <div className="col-sm-12 col-md-4 text-end position-relative">
                            <label className="toggle mt-2 me-6">
                                <input type="checkbox" />
                                <span className="slider"></span>
                                <span className="labels" data-on="Went Well" data-off="Can be Improved"></span>
                            </label>
                        </div>
                    </div>
                    <div className="row border mx-0 p-2 align-items-center border-gray-300 box-1">
                        <div className="col-sm-12 col-md-8">
                            Meeting agenda communicated in advance.
                        </div>
                        <div className="col-sm-12 col-md-4 text-end position-relative">
                            <label className="toggle mt-2 me-6">
                                <input type="checkbox" />
                                <span className="slider"></span>
                                <span className="labels" data-on="Went Well" data-off="Can be Improved"></span>
                            </label>
                        </div>
                    </div>
                    <div className="row border mx-0 p-2 align-items-center border-gray-300 box-1">
                        <div className="col-sm-12 col-md-8">
                            Meeting started on time.
                        </div>
                        <div className="col-sm-12 col-md-4 text-end position-relative">
                            <label className="toggle mt-2 me-6">
                                <input type="checkbox" />
                                <span className="slider"></span>
                                <span className="labels" data-on="Went Well" data-off="Can be Improved"></span>
                            </label>
                        </div>
                    </div>
                    <div className="row border mx-0 p-2 align-items-center border-gray-300 box-1">
                        <div className="col-sm-12 col-md-8">
                            All the participants arrived on time.
                        </div>
                        <div className="col-sm-12 col-md-4 text-end position-relative">
                            <label className="toggle mt-2 me-6">
                                <input type="checkbox" />
                                <span className="slider"></span>
                                <span className="labels" data-on="Went Well" data-off="Can be Improved"></span>
                            </label>
                        </div>
                    </div>
                    <div className="row border mx-0 p-2 align-items-center border-gray-300 box-1">
                        <div className="col-sm-12 col-md-8">
                            Meeting remained focused on the agenda.
                        </div>
                        <div className="col-sm-12 col-md-4 text-end position-relative">
                            <label className="toggle mt-2 me-6">
                                <input type="checkbox" />
                                <span className="slider"></span>
                                <span className="labels" data-on="Went Well" data-off="Can be Improved"></span>
                            </label>
                        </div>
                    </div>
                    <div className="row border mx-0 p-2 align-items-center border-gray-300 box-1">
                        <div className="col-sm-12 col-md-8">
                            Meeting completed on time.
                        </div>
                        <div className="col-sm-12 col-md-4 text-end position-relative">
                            <label className="toggle mt-2 me-6">
                                <input type="checkbox" />
                                <span className="slider"></span>
                                <span className="labels" data-on="Went Well" data-off="Can be Improved"></span>
                            </label>
                        </div>
                    </div>
                    <div className="row border mx-0 p-2 align-items-center border-gray-300 box-1">
                        <div className="col-sm-12 col-md-8">
                            Minutes of meeting has been prepared.
                        </div>
                        <div className="col-sm-12 col-md-4 text-end position-relative">
                            <label className="toggle mt-2 me-6">
                                <input type="checkbox" />
                                <span className="slider"></span>
                                <span className="labels" data-on="Went Well" data-off="Can be Improved"></span>
                            </label>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-12 mt-6">
                        <div className="form-floating mb-8">
                            <textarea className="form-control" placeholder=" " id="floatingTextarea" required style={{ height: '80px' }}></textarea>
                            <label> Any Other Comments <span className="text-danger"> * </span></label>
                        </div>
                    </div>
                    <div className='col-12 mt-6'>
                        <section className='container px-0'>
                            <h4 className='fw-normal fs-6 border-gray-600'> Upload Group Photo
                                <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="text-white">
                                    (Supported files: .jpg, .jpeg <br /> Max File Size: 10MB) </Tooltip>}>
                                    <span className="d-inline-block">
                                        <i className="fa-solid fs-3 text-primary ms-3 fa-circle-info" ></i>
                                    </span>
                                </OverlayTrigger>
                                {/* <span> 
                                                <i className="fa-solid fs-3 text-primary ms-3 fa-circle-info" data-bs-toggle="tooltip" data-bs-placement="top" title="Tooltip on top"></i> 
                                                </span> */}
                            </h4>
                            <div {...getRootProps({ className: 'dropzone' })}>
                                <input {...getInputProps()} required />
                                <p>Drag 'n' drop some files here, or click to select files</p>
                            </div>
                            <aside>
                                <ul>{files}</ul>
                            </aside>
                        </section>
                    </div>

                    <div className='col-sm-12 col-md-3 mt-6'>
                    <label className="form-label fs-5">Submitted By:</label>
                            <p className="fs-5 fw-semibold text-start text-gray-700 pb-1 mt-2 border-bottom border-gray-300"> Nirav Agravat </p>
                    </div>

                    <div className='col-12 mt-15 text-center pe-5'>
                                    <Button variant='primary' type='submit' className='w-auto' style={{ backgroundColor: '#f5f5f5' }}>
                                        Submit
                                    </Button>

                                </div>
              
        
        </>
    )
}

export default GroupMeetingForm