import React, {useContext, useEffect, useRef, useState} from 'react'
import {Button, Form} from 'react-bootstrap'
import TextareaAutosize from 'react-textarea-autosize'
import {SearchLeaderListing} from './SearchLeaderListing'
import {addUserTopic, singleLeaderUser} from '../_requests'
import {UserContext} from '../../../../../../contextStore'
import * as Yup from 'yup'
import {useFormik} from 'formik'

export function GratitudeForBusiness() {
  const [searchLeaderListing, setsearchLeaderListing] = useState(true)
  const [selectedLeaderId, setSelectedLeaderId] = useState(null)
  const {userDetail, dashBoardReload} = useContext(UserContext)
  const [singleLeaderData, setSingleLeaderData] = useState({name: '', id: ''})
  const {setMessage} = useContext(UserContext)
  const closeButton = useRef<any>()
  const [errorMessage, setErrorMessage] = useState<any>(false)
  

  const handleLeaderSelect = async (leaderId: any) => {
    try {
      setSelectedLeaderId(leaderId)
      const singleLeaderResponseData = await singleLeaderUser(leaderId)
      setSingleLeaderData(singleLeaderResponseData ?? [])
    } catch (error) {
      console.error('Error fetching single leader data:', error)
    }
  }

  const initialValue = {
    others: {
      generateAmount: '',
      productService: '',
    },
  }
  // validation
  const validationSchema = Yup.object().shape({
    others: Yup.object().shape({
      generateAmount: Yup.string()
        .required('This field is required')
        .matches(/^(?:[1-9]\d{0,6}|1000000)$/, 'Invalid amount'),
      productService: Yup.string()
        .required('This field is required')
        .matches(/^[A-Za-z\s]+$/, 'Only letters are allowed in the product Service'),
    }),
  })

  const formik = useFormik({
    initialValues: initialValue,
    validationSchema: validationSchema,
    onSubmit: async (values, {resetForm}) => {
      try {
        if (!selectedLeaderId) {
          setErrorMessage(true)
          return
        } else {
          const postGratitudeForBusinessData = {
            topic_id: 22,
            user_id: userDetail.id,
            chapter_id: userDetail.chapter_id,
            others: {
              generateAmount: values.others.generateAmount,
              productService: values.others.productService,
            },
            descriptiontext: 'Business generated',
            isactive: 1,
            isdelete: 0,
            created_by: userDetail.id,
            business_generate_receiver: singleLeaderData.id,
            business_generate_sender: userDetail.id,
          }
          await addUserTopic(postGratitudeForBusinessData)
          setMessage('success', 'Gratitude for business created')
          resetForm()        
          setSingleLeaderData({name: '', id: ''})
          setsearchLeaderListing(true)
          setSelectedLeaderId(null)
          setErrorMessage(false)
          dashBoardReload()
        }
      } catch (error) {
        setMessage('error', 'Something went wrong')
      }
    },
  })

  return (
    <>
      <div
        className='modal fade ilead-default-modal'
        id='GratitudeForBusinessModal'
        aria-hidden='true'
        aria-labelledby='GratitudeForBusinessModal'
      >
        <div className='modal-dialog modal-dialog modal-dialog-scrollable modal-fullscreen-md-down modal-md'>
          <div className='modal-content'>
            <div className='modal-header py-5'>
              <h3 className='modal-title fs-3 fw-bold' id='GratitudeForBusinessModalToggleLabel'>
                Gratitude for Business
              </h3>
              <button
                type='button'
                className='btn-close text-white'
                data-bs-target='#write_post_modal'
                data-bs-toggle='modal'
                data-bs-dismiss='modal'
                onClick={() => {
                  formik.setFieldValue('others', {
                    generateAmount: '',
                    productService: '',
                  })
                  setSingleLeaderData({name: '', id: ''})
                  setsearchLeaderListing(true)
                  setSelectedLeaderId(null)
                  setErrorMessage(false)
                  formik.resetForm()
                }}
                ref={closeButton}
              ></button>
            </div>
            <div className='modal-body pt-7 write-post-wrapper'>
              {searchLeaderListing ? (
                <form
                  className='mb-7'
                  onSubmit={(e) => {
                    formik.handleSubmit(e)
                  }}
                >
                  <h3 className='text-black fw-bold fs-4 mb-6'>Gratitude For Leader</h3>
                  <Form.Group className='mb-4'>
                    <Form.Label
                      className='fs-14 border-bottom w-100 py-2 text-gray-700'
                      onClick={() => {
                        setsearchLeaderListing(false)
                      }}
                    >
                      {selectedLeaderId ? (
                        <b>{singleLeaderData.name}</b>
                      ) : (
                        <b className='required'>Select leader name</b>
                      )}
                    </Form.Label>
                    {!selectedLeaderId && errorMessage ? (
                      <div className='fv-plugins-message-container text-danger'>
                        <span role='alert'>This field is required</span>
                      </div>
                    ) : (
                      ''
                    )}
                  </Form.Group>
                  <Form.Group className='mb-4'>
                    <Form.Label className='w-100 py-2 text-gray-900 fw-bold required'>
                      Business Generated of
                    </Form.Label>
                    <Form.Control
                      type='text'
                      placeholder='Enter Amount'
                      aria-label='amount'
                      id='amount'
                      maxLength={7}
                      onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                        const key = e.key
                        const isNumeric = /^[0-9]$/.test(key)
                        const isSpecialKey =
                          key === 'Backspace' ||
                          key === 'Delete' ||
                          key === 'ArrowLeft' ||
                          key === 'ArrowRight' ||
                          key === 'ArrowUp' ||
                          key === 'ArrowDown' ||
                          (e.ctrlKey && key === 'c') ||
                          (e.ctrlKey && key === 'v') ||
                          (e.ctrlKey && key === 'x') ||
                          (e.ctrlKey && key === 'a')
                        if (!isNumeric && !isSpecialKey) {
                          e.preventDefault()
                        }
                      }}
                      className='border-right-0 ilead-input'
                      onChange={(e) => {
                        formik.setFieldValue('others.generateAmount', e.target.value)
                      }}
                      value={formik.values.others.generateAmount}
                    />
                    {formik?.touched?.others?.generateAmount &&
                      formik?.errors?.others?.generateAmount && (
                        <div className='fv-plugins-message-container text-danger'>
                          <span role='alert'>{formik?.errors?.others?.generateAmount as any}</span>
                        </div>
                      )}
                  </Form.Group>
                  <Form.Group className='mb-4'>
                    <Form.Label className='w-100 py-2 text-gray-900 fw-bold required'>
                      For Product/Service
                    </Form.Label>
                    <TextareaAutosize
                      minRows={3}
                      autoFocus
                      className='custom-text-area form-control fw-normal ilead-input'
                      maxLength={200}
                      placeholder='Enter product/service'
                      onChange={(e) => {
                        formik.setFieldValue('others.productService', e.target.value)
                      }}
                      value={formik.values.others.productService}
                    />
                    {formik?.touched?.others?.productService &&
                      formik?.errors?.others?.productService && (
                        <div className='fv-plugins-message-container text-danger'>
                          <span role='alert'>{formik?.errors?.others?.productService as any}</span>
                        </div>
                      )}
                  </Form.Group>
                  <Form.Group className='mt-5 d-flex'>
                    <Button
                      variant='secondary'
                      type='button'
                      className='w-50 me-3'
                      data-bs-target='#write_post_modal'
                      data-bs-toggle='modal'
                      data-bs-dismiss='modal'
                      onClick={() => {
                        formik.setFieldValue('others', {
                          generateAmount: '',
                          productService: '',
                        })
                        setSingleLeaderData({name: '', id: ''})
                        setsearchLeaderListing(true)
                        setSelectedLeaderId(null)
                        setErrorMessage(false)
                        formik.resetForm()
                      }}
                    >
                      Close
                    </Button>
                    <Button
                      variant='primary'
                      type='submit'
                      data-bs-toggle={formik.isValid ? 'modal' : undefined}
                      data-bs-dismiss={formik.isValid ? 'modal' : undefined}
                      className='w-50'
                      disabled={
                        formik.isSubmitting ||
                        !selectedLeaderId ||
                        !formik.values.others.productService ||
                        !formik.values.others.generateAmount
                      }
                      onClick={() => {
                        if (!selectedLeaderId) {
                          setErrorMessage(true)
                        }
                      }}
                    >
                      Submit
                    </Button>
                  </Form.Group>
                </form>
              ) : (
                <SearchLeaderListing
                  onClick={setsearchLeaderListing}
                  onSelectLeader={handleLeaderSelect}
                ></SearchLeaderListing>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
