import React, { useContext, useEffect, useRef, useState } from 'react'
import { ResetDateChapter } from './ResetDateChapter'
import { apiUrl } from '../../../apiurl'
import { checkDisplayValue } from '../../../utils'
import axios from 'axios'
import moment from 'moment'
import { UserContext } from '../../../contextStore'
import InfiniteScroll from 'react-infinite-scroll-component'
import { shareInvitation, shareInvitationWhatappAPI } from './core/_request'
import { RWebShare } from 'react-web-share'

const API_URL = apiUrl
const INVITATION_HISTORY_URL = `${API_URL}/invitionhistories`

const InvitationHistory: React.FC = () => {
  const [data, setData] = useState<any>([])
  const [isLoading, setIsLoading] = useState(true)
  const { userDetail, setMessage } = useContext(UserContext)
  const userid = userDetail?.id ?? ''
  const [hasMore, setHasMore] = useState(true)
  const [skip, setSkip] = useState(0)
  const limit = 15
  const [selectedItem, setSelectedItem] = useState<any>(null)
  const [whatsappInvi, setWhatsappInvi] = useState<string>("")
  const whatsappBtnRef = useRef<any>(null)

  useEffect(() => {
    fetchData()
  }, [])

  const fetchData = async () => {
    try {
      const res = await axios.get(
        `${INVITATION_HISTORY_URL}?filter[where][invited_by]=${userid}&filter[order]=id DESC&filter[skip]=${skip}&filter[limit]=${limit}`
      )

      let newData = res?.data ?? []

      if (newData.length === 0) {
        setHasMore(false)
        setIsLoading(false)
      } else {
        setData([...data, ...newData])
        setSkip(skip + limit)
        setIsLoading(false)
      }
    } catch (error) {
      console.error('Error -->:', error)
      setIsLoading(false)
    }
  }

  const resetList = () => {
    // setData([])
    setSkip(0)
    // setHasMore(true)
    fetchData()
  }

  const whatsappAPI = async (eventid: any, invitee_name: any) => {
    try {
      await shareInvitationWhatappAPI(eventid, invitee_name).then((resp: any) => {
        setWhatsappInvi(resp?.data?.message ?? '')
        if (resp?.data?.message) {
          setTimeout(() => {
            whatsappBtnRef.current.click()
          }, 500)
        } else {
          setMessage("error", "Something Went Wrong")
        }
      }).catch((erorr: any) => {
        setMessage("error", "Something Went Wrong")
        console.log(erorr, "erorr?.data")
      })
    }
    catch (err) {
      setMessage("error", "Something Went Wrong")
    }
  }

  const styles = {
    borderRadius: '0.475rem',
    boxShadow: '0 0 50px 0 rgb(82 63 105 / 15%)',
    backgroundColor: '#fff',
    color: '#7e8299',
    fontWeight: '500',
    margin: '0',
    width: 'auto',
    padding: '1rem 2rem',
    top: 'calc(50% - 2rem)',
    left: 'calc(50% - 4rem)',
  }

  if (isLoading) {
    return <div style={{ ...styles, position: 'absolute', textAlign: 'center' }}>Processing...</div>
  }


  // text:
  // whatsappInvi?.replaceAll('&', 'and')?.replaceAll('\n', '%0a') ??
  // 'rajjjj',

  return (
    <>
      <h1 className='inv-hst mb-3 mb-md-0'>Invitation History</h1>
      <div style={{ display: "none" }}>
        <RWebShare
          data={{
            text: whatsappInvi?.replaceAll('&', 'and')?.replaceAll('\n', '%0a'),
            url: " ",
            title: "Send Invitation"
          }}
          onClick={() => console.info("share successful!")}
          disableNative={true}
        >
          <button ref={whatsappBtnRef}>btn</button>
        </RWebShare>
      </div>
      {data.length > 0 ? (
        <div className='card ilead__card mt-5 invitation-history'>
          <InfiniteScroll
            dataLength={data.length}
            next={fetchData}
            // height={600}
            hasMore={hasMore}
            loader={<></>}
          >
            <table className='table table-striped'>
              <thead>
                <tr>
                  <th scope='col'>Event Date</th>
                  <th scope='col'>Name</th>
                  <th scope='col'>Mobile Number</th>
                  <th scope='col'>Emails</th>
                  <th scope='col'>Company</th>
                  <th scope='col'>Action</th>
                </tr>
              </thead>
              <tbody>
                {data?.map((item: any, index: any) => (
                  <tr key={index}>
                    <td data-label='Event Date'>
                      {item?.event_date
                        ? checkDisplayValue(moment(item?.event_date).format('YYYY-MM-DD'))
                        : '-'}
                    </td>
                    <td data-label='Name'>{checkDisplayValue(item?.invitee_name)}</td>
                    <td data-label='Mobile Number'>{checkDisplayValue(item?.cell_no)}</td>
                    <td data-label='Email'>{checkDisplayValue(item?.email)}</td>
                    <td data-label='Company'>{checkDisplayValue(item?.company)}</td>
                    <td data-label='Action'>
                      <button
                        onClick={() => setSelectedItem(item)}
                        type='button'
                        data-bs-toggle='modal'
                        data-bs-target='#reset_date_chapter_modal'
                        className='btn btn-success rounded-circle py-2 ps-3 pe-2 invitation-resend-btn'
                      >
                        <i className='fa fa-undo'></i>
                      </button>{' '}
                      {Date.parse(item.event_date) >= Date.parse(moment().format()) ? (
                        <button
                          onClick={() => {
                            whatsappAPI(item?.event_id ?? '', item?.invitee_name ?? '')
                          }}
                          type='button'
                          className='btn btn-success rounded-circle py-2 ps-3 pe-2 invitation-resend-btn'
                        >
                          {<i className='fa-brands fa-whatsapp'></i>}
                        </button>
                      ) : null}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </InfiniteScroll>
          <ResetDateChapter selectedItem={selectedItem} fetchData={resetList} />
        </div>
      ) : (
        <div style={{ ...styles, position: 'absolute', textAlign: 'center' }}>No Data Found</div>
      )}
    </>
  )
}

export default InvitationHistory
