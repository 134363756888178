import axios, {AxiosResponse} from 'axios'
import {Vertical, VerticalsQueryResponse} from './_models'
import {ID, Response} from '../../../../../_metronic/helpers'
import {apiUrl} from '../../../../../apiurl'

const API_URL = apiUrl
const Vertical_URL = `${API_URL}/verticals/findAllVertical`
const Vertical_one_url = `${API_URL}/verticals/findOneVertical`
const DELETE_VERTICAL_URL = `${API_URL}/verticals/deleteVertical`
const EDIT_VERTICAL_URL = `${API_URL}/verticals/updateVertical`
const ADD_VERTICAL_URL = `${API_URL}/verticals/createVertical`

const getVertical = (query: string): Promise<VerticalsQueryResponse> => {
  return axios
    .get(`${Vertical_URL}?${query}`)
    .then((d: AxiosResponse<any>) => d.data)
    .catch((e) => console.log(e))
}

const getVerticalById = (id: ID): Promise<Vertical | undefined> => {
  return axios
    .get(`${Vertical_one_url}/${id}`)
    .then((response: Response<any>) => response.data)
    .catch((e) => console.log(e))
}

const deleteVertical = (verticalId: ID): Promise<void> => {
  return axios
    .delete(`${DELETE_VERTICAL_URL}/${verticalId}`)
    .then((response: Response<any>) => response.data)
    .catch((e) => console.log(e))
}

const createVertical = (data: any): Promise<Vertical | undefined> => {
  return axios
    .post(ADD_VERTICAL_URL, data)
    .then((response: any) => response)
    .catch((e) => e)
}

const updateVertical = (verticalId: any, data: any): Promise<Vertical | undefined> => {
  return axios
    .patch(`${EDIT_VERTICAL_URL}/${verticalId}`, data)
    .then((response: any) => response)
    .catch((e) => e)
}

export {getVertical, getVerticalById, deleteVertical, createVertical, updateVertical}
