// @ts-nocheck
import {Column} from 'react-table'
import {TemplateInfoCell} from './TemplateInfoCell'
import {TemplateLastLoginCell} from './TemplateLastLoginCell'
import {TemplateTwoStepsCell} from './TemplateTwoStepsCell'
import {TemplateActionsCell} from './TemplateActionsCell'
import {TemplateSelectionCell} from './TemplateSelectionCell'
import {TemplateCustomHeader} from './TemplateCustomHeader'
import {TemplateSelectionHeader} from './TemplateSelectionHeader'
import {Template} from '../../core/_models'

const getSerialNumber = (value:any) => {
  try{
    return parseInt(value) + 1
  }
  catch(err){
    return "-"
  }
}

const templatesColumns: ReadonlyArray<Column<Template>> = [
  {
    Header: (props) => <TemplateCustomHeader tableProps={props} title='Sr. No.' className='text-center min-w-125px ps-2 fs-5' />,
    accessor: 'index',
    Cell: ({...props}) => getSerialNumber(props?.row?.index)
  },
  {
    Header: (props) => <TemplateCustomHeader tableProps={props} title='template Name' className='text-center min-w-125px fs-5' />,
    accessor: 'name',
    Cell: ({value}) => value ?? "-"
  },
  {
    Header: (props) => (
      <TemplateCustomHeader tableProps={props} title='Actions' className='text-center min-w-150px' />
    ),
    id: 'actions',
    Cell: ({...props}) => <TemplateActionsCell id={props.data[props.row.index].id} data={props.data[props.row.index]} />,
  },
]

export {templatesColumns}
