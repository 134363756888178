import React, {useContext, useEffect, useRef, useState} from 'react'
import {Form} from 'react-bootstrap'
import {useNavigate, useParams} from 'react-router-dom'
import Multiselect from 'multiselect-react-dropdown'
import * as Yup from 'yup'
import {apiUrl} from '../../../../apiurl'
import {useFormik} from 'formik'
import axios from 'axios'
import {UserContext} from '../../../../contextStore'
import moment from 'moment'
import {createReminder, updateReminder} from './reminders-list/core/_requests'
import {isNotEmpty} from '../../../../_metronic/helpers'
import Select from 'react-select'

const API_URL = apiUrl
const CHAPTER_URL = `${API_URL}/chapters?filter[where][isdelete]=0`
const USER_URL = `${API_URL}/users?filter[where][isdelete]=0&filter[where][isactive]=1`
const GROUP_URL = `${API_URL}/groups?filter[where][isdelete]=0`
const VIEWBYID_REMINDER_URL = `${API_URL}/messagecenterreminders/viewOneReminder`

const validationForm = Yup.object().shape({
  title: Yup.string().required('This field is required'),
  notificationtemplateid: Yup.number()
    .test('is-non-zero', 'This field is required', (value) => value !== 0)
    .required('This field is required'),
  description: Yup.string().required('This field is required'),
  batches: Yup.array().required('This field is required'),
  groups: Yup.array().required('This field is required'),
  users: Yup.array().required('This field is required'),
})

const AddEditReminder = () => {
  const {id} = useParams()
  console.log('===>', id)
  const [data, setData] = useState<any>([])
  const [isLoading, setLoading] = useState<any>(false)
  const [userList, setUserList] = useState<any>([])
  const [groupList, setGroupList] = useState<any>([])
  const [chapterList, setChapterList] = useState<any>([])
  const {userDetail, setMessage} = useContext(UserContext)
  const [datasourceurl, setDatasourceurl] = useState('')
  const userId = userDetail?.id ?? ''
  const closeModel = useRef<any>()
  console.log('idddd', userId)
  const navigate = useNavigate()
  const navigateReminderListing = () => {
    // 👇️ navigate to /
    navigate('/message-center-reminders')
  }
  const [selectedChapters, setSelectedChapters] = useState<any>([])
  const [selectedUsers, setSelectedUsers] = useState<any>([])
  const [selectedGroups, setSelectedGroups] = useState<any>([])

  const chapterOptions = [
    {value: 'all', label: 'All Chapter'},
    {value: 'active', label: 'Active Chapter'},
    {value: 'inactive', label: 'In-Active Chapter'},
    ...chapterList,
  ]

  const groupOptions = [
    {value: 'all', label: 'All Group'},
    {value: 'active', label: 'Active Group'},
    {value: 'inactive', label: 'In-Active Group'},
    ...groupList,
  ]
  console.log('===groupOptions', groupOptions)
  const userOptions = [
    {value: 'all', label: 'All User'},
    {value: 'active', label: 'Active User'},
    {value: 'inactive', label: 'In-Active User'},
    ...userList,
  ]

  useEffect(() => {
    getUserList()
    getGroupList()
    getChapterList()
    fetchData()
  }, [])

  const fetchData = async () => {
    try {
      if (id) {
        const res = await axios.get(`${VIEWBYID_REMINDER_URL}/${id}`)
        setData(res?.data ?? [])
      }else{
        formik.setFieldValue('is_sendsms', true);
      }
    } catch (error) {
      console.log('Error -->:', error)
    }
  }

  const getChapterList = async () => {
    try {
      const res = await axios.get(CHAPTER_URL)
      if (res?.data) {
        const list = res?.data.map((x: any) => {
          var label = x.name
          var value = x.id
          return {value: value, label: label}
        })
        console.log('===>l', list)
        setChapterList(list)
        console.log('====>>', chapterList)
      }
    } catch (err: any) {
      let message = err?.message
        ? err.message
        : err?.responseMessage
        ? err.responseMessage
        : ('Something went wrong' as any)
      console.log('errrrrrr', message)
    }
  }

  const getUserList = async () => {
    try {
      const resu = await axios.get(USER_URL)
      if (resu?.data) {
        const list = resu?.data.map((x: any) => {
          var label = x.name
          var value = x.id
          return {value: value, label: label}
        })
        console.log('===>l', list)
        setUserList(list)
        console.log('====>>', userList)
      }
    } catch (err: any) {
      let message = err?.message
        ? err.message
        : err?.responseMessage
        ? err.responseMessage
        : ('Something went wrong' as any)
      console.log('errrrrrr', message)
    }
  }

  const getGroupList = async () => {
    try {
      const resg = await axios.get(GROUP_URL)
      if (resg?.data) {
        const list = resg?.data.map((x: any) => {
          var label = x.name
          var value = x.id
          return {value: value, label: label}
        })
        console.log('===>l', list)
        setGroupList(list)
        console.log('====>>', groupList)
      }
    } catch (err: any) {
      let message = err?.message
        ? err.message
        : err?.responseMessage
        ? err.responseMessage
        : ('Something went wrong' as any)
      console.log('errrrrrr', message)
    }
  }

  const batchesList = selectedChapters.map((option: any) => option.value.toString())
  const usersList = selectedUsers.map((option: any) => option.value.toString())
  const groupsList = selectedGroups.map((option: any) => option.value.toString())

  console.log('===s', batchesList)
  console.log('===s', usersList)
  console.log('===s', groupsList)
  const initialValues = {
    is_sendsms: false,
    is_sendemail: false,
    is_sendpush: false,
    users: usersList,
    batches: batchesList,
    groups: groupsList,
    is_repeat: {repeats: 0},
    title: '',
    createdby: userId,
    updated_at: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
    created_at: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
    notificationtemplateid: '',
    datasource: datasourceurl,
    event_time: '12:00:00',
    event_date: moment(new Date()).format('YYYY-MM-DD'),
    is_general: 1,
    onbehalfuser_id: 52,
    description: '',
  }

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationForm,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      setLoading(true)
      var postData = {
        is_sendsms: values.is_sendsms,
        is_sendemail: values.is_sendemail,
        is_sendpush: values.is_sendpush,
        users: usersList,
        batches: batchesList,
        groups: groupsList,
        is_repeat: {repeats: 0},
        title: values.title,
        createdby: userId,
        updated_at: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
        created_at: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
        notificationtemplateid: values.notificationtemplateid,
        datasource: datasourceurl,
        event_time: '12:00:00',
        event_date: moment(new Date()).format('YYYY-MM-DD'),
        is_general: 1,
        onbehalfuser_id: values.onbehalfuser_id,
        description: values.description,
      }
      try {
        if (isNotEmpty(id)) {
          await updateReminder(id, postData)
            .then((res: any) => {
              if (res?.status == true) {
                setMessage('success', res?.message ?? 'Reminder updated.')
                closeModel.current.click()
              } else {
                setMessage('error', 'Something went wrong.')
              }
              setLoading(false)
            })
            .catch((error: any) => {
              if (error) {
                setMessage('error', 'Something went wrong.')
              }
              setLoading(false)
            })
        } else if (!id) {
          await createReminder(postData)
            .then((res: any) => {
              console.log('==>res', res)
              if (res?.status == 200) {
                setMessage('success', res?.data?.message ?? 'Reminder added.')
                closeModel.current.click()
              } else {
                setMessage('error', 'Something went wrong.')
              }
              setLoading(false)
            })
            .catch((error: any) => {
              if (error) {
                setMessage('error', 'Something went wrong.')
              }
              setLoading(false)
            })
        }
      } catch (ex) {
        console.error(ex)
      }
    },
  })

  useEffect(() => {
    if (id) {
      formik.setFieldValue('title', data?.title ?? '')
      formik.setFieldValue('notificationtemplateid', data?.notificationtemplateid ?? '')
      formik.setFieldValue('description', data?.description ?? '')
      formik.setFieldValue('onbehalfuser_id', data?.onbehalfuser_id ?? '')
      formik.setFieldValue('datasource', data?.datasource ?? '')
      formik.setFieldValue('is_sendsms', data?.notification_type?.is_sendsms)
      formik.setFieldValue('is_sendemail', data?.notification_type?.is_sendemail)
      formik.setFieldValue('is_sendpush', data?.notification_type?.is_sendpush)
      setSelectedUsers(data?.selectedUsers ?? [])
      setSelectedChapters(data?.selectedBatches ?? [])
      setSelectedGroups(data?.selectedGroups ?? [])
    }
  }, [data])

  const generateDatasource = () => {
    const userIds = usersList.map((user: any) => user).join(',')
    const groupIds = groupsList.map((group: any) => group).join(',')
    const batchIds = batchesList.map((batch: any) => batch).join(',')

    const queryParams = []

    if (userIds) {
      queryParams.push(`userid=[${userIds}]`)
    } else if (groupIds) {
      queryParams.push(`groupid=[${groupIds}]`)
    } else if (batchIds) {
      queryParams.push(`batchid=[${batchIds}]`)
    }

    const queryString = queryParams.join('&')
    const newDatasource = `https://www.ileadsampark.org/userDataSource${
      queryString ? '?' + queryString : ''
    }&&templateid=7`
    setDatasourceurl(newDatasource)
  }
  useEffect(() => {
    generateDatasource()
  }, [usersList, groupsList, batchesList])

  console.log('====ERR', formik)

  return (
    <>
      <form
        action=''
        onSubmit={(e) => {
          e.preventDefault()
          formik.handleSubmit(e)
        }}
      >
        <div className='row'>
          <div className='col-sm-12 col-md-6 mb-8'>
            <Form.Label className='fs-6 fw-semibold text-primary w-100 text-dark text-start'>
              {' '}
              Event Title <span className='text-danger'> * </span>{' '}
            </Form.Label>
            <Form.Control
              required
              type='text'
              placeholder=''
              className='ps-0'
              onChange={(e) => formik.setFieldValue('title', e.target.value)}
              value={formik.values.title}
            />
          </div>

          <div className='col-sm-12 col-md-6 mb-8'>
            <Form.Label className='fs-6 fw-semibold text-primary w-100 text-dark text-start'>
              {' '}
              Notification Template <span className='text-danger'> * </span>{' '}
            </Form.Label>
            <Form.Select
              aria-label='Default select example'
              className='ps-0'
              onChange={(e) => {
                formik.setFieldValue('notificationtemplateid', e.target.value)
              }}
              value={formik.values.notificationtemplateid}
              required
            >
              <option> Select Notification Template </option>
              <option value={7}> General </option>
            </Form.Select>
          </div>
        </div>

        <div className='row'>
          <div className='col-12 mb-8'>
            <Form.Label className='fs-6 fw-semibold text-primary w-100 text-dark text-start'>
              {' '}
              Event Description <span className='text-danger'> * </span>{' '}
            </Form.Label>
            <Form.Control
              required
              as='textarea'
              rows={4}
              placeholder=''
              className='ps-0'
              onChange={(e) => {
                formik.setFieldValue('description', e.target.value)
              }}
              value={formik.values.description}
            />
          </div>
        </div>

        <div className='row'>
          {formik?.values?.notificationtemplateid == '7' ? null : (
            <div className='col-md-12 col-lg-6  mb-8'>
              <Form.Label className='fs-6 fw-semibold text-primary w-100 text-dark text-start'>
                {' '}
                Event Time <span className='text-danger'> * </span>{' '}
              </Form.Label>
              <Form.Control
                required
                type='time'
                placeholder='Presentation Title'
                className='ilead-input1'
              />
            </div>
          )}

          <div className='col-md-12 col-lg-6  mb-8'>
            <Form.Label className='fs-6 fw-semibold text-primary w-100 text-dark text-start'>
              {' '}
              Select Chapter <span className='text-danger'> * </span>{' '}
            </Form.Label>
            <Select
              isMulti
              options={chapterOptions}
              value={selectedChapters}
              onChange={(selectedOptions) => {
                setSelectedChapters(selectedOptions)
                generateDatasource()
              }}
            />
          </div>
        </div>

        <div className='row'>
          <div className='col-md-12 col-lg-6  mb-8'>
            <Form.Label className='fs-6 fw-semibold text-primary w-100 text-dark text-start'>
              {' '}
              Select Group{' '}
            </Form.Label>

            <Select
              isMulti
              options={groupOptions}
              value={selectedGroups}
              onChange={(selectedOptions) => {
                setSelectedGroups(selectedOptions)
                generateDatasource()
              }}
            />
          </div>

          <div className='col-md-12 col-lg-6  mb-8'>
            <Form.Label className='fs-6 fw-semibold text-primary w-100 text-dark text-start'>
              {' '}
              Select Leaders{' '}
            </Form.Label>
            <Select
              isMulti
              options={userOptions}
              value={selectedUsers}
              onChange={(selectedOptions) => {
                setSelectedUsers(selectedOptions)
                generateDatasource()
              }}
            />
          </div>
        </div>

        <div className='row'>
          <div className='col-sm-12 col-md-6 mb-8'>
            <Form.Label className='fs-6 fw-semibold text-primary w-100 text-dark text-start'>
              {' '}
              Select Post On Behalf <span className='text-danger'> * </span>{' '}
            </Form.Label>
            <Form.Select
              aria-label='Default select example'
              className='ps-0'
              required
              onChange={(e) => {
                formik.setFieldValue('onbehalfuser_id', e.target.value)
              }}
              value={formik.values.onbehalfuser_id}
            >
              <option >Select Post On Behalf</option>
              <option value={52}> Shyam Taneja </option>
              <option value={64}> Team ILead </option>
              <option value={140}> Neeru Gupta </option>
              <option value={141}> Sama Taneja </option>
              <option value={259}> Anil Gupta </option>
            </Form.Select>
          </div>

          <div className='col-sm-12 col-md-6 mb-8'>
            <Form.Label className='fs-6 fw-semibold text-primary w-100 text-dark text-start'>
              {' '}
              DataSourse <span className='text-danger'> * </span>{' '}
            </Form.Label>
            <Form.Control
              disabled
              required
              type='text'
              placeholder='Data Source Url'
              className='ps-0'
              value={datasourceurl}
            />
          </div>
        </div>

        <div className='row'>
          <div className='col-12 mb-8'>
            {['checkbox'].map((type) => (
              <div key={`inline-${type}`} className='mb-3'>
                <Form.Check
                  inline
                  defaultChecked={data?.notification_type?.is_sendsms}
                  label='SMS'
                  name='group1'
                  onChange={(e) => {
                    formik.setFieldValue('is_sendsms', e.target.checked)
                  }}
                  checked={formik.values.is_sendsms}
                  // type={type}
                  id={`inline-${type}-1`}
                />
                <Form.Check
                  inline
                  defaultChecked={data?.notification_type?.is_sendemail}
                  label='Email'
                  name='group1'
                  onChange={(e) => {
                    formik.setFieldValue('is_sendemail', e.target.checked);
                    // Check the SMS checkbox if Email and Push are not checked
                    if (!e.target.checked && !formik.values.is_sendpush) {
                      formik.setFieldValue('is_sendsms', true);
                    }
                  }}
                  checked={formik.values.is_sendemail}
                  // type={type}
                  id={`inline-${type}-2`}
                />
                <Form.Check
                  inline
                  defaultChecked={data?.notification_type?.is_sendpush}
                  label='Push'
                  onChange={(e) => {
                    formik.setFieldValue('is_sendpush', e.target.checked);
                    // Check the SMS checkbox if Email and Push are not checked
                    if (!e.target.checked && !formik.values.is_sendemail) {
                      formik.setFieldValue('is_sendsms', true);
                    }
                  }}
                  checked={formik.values.is_sendpush}
                  // type={type}
                  id={`inline-${type}-3`}
                />
              </div>
            ))}
          </div>
        </div>

        <div className='row'>
          <div className='col-md-12 col-lg-10 text-center pe-6'>
            <button
              type='submit'
              className='btn btn-primary  waves-effect waves-light '
              disabled={
                isLoading ||
                !formik.values.title ||
                !formik.values.notificationtemplateid ||
                !formik.values.description ||
                !selectedChapters.length ||
                !selectedUsers.length
              }
            >
              Save
            </button>
            <button
              type='button'
              className='btn btn-info  waves-effect ms-5 waves-light '
              onClick={navigateReminderListing}
              ref={closeModel}
            >
              Cancel
            </button>
          </div>
        </div>
      </form>
    </>
  )
}
export default AddEditReminder
