// import React from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { useFormik } from 'formik'
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { UserContext } from '../../../contextStore'

import * as Yup from 'yup'
import { addNewGroup, getChapterList, getGroupData, getGroupmanagements, updateGroup } from "./groups-list/core/_requests";
import { useListView } from "./groups-list/core/ListViewProvider";
import { useQueryResponse } from "./groups-list/core/QueryResponseProvider";

const AddEditNewGroup = ({ show, handleClose, closebutton, idGet, setId }: any) => {
    const { userDetail, setMessage } = useContext(UserContext)
    const [chapterData, setChapterData] = useState([])
    const { refetch } = useQueryResponse()
    const [isLoading, setLoading] = useState<any>(false)

    const initialValues = {
        chapter_id: "",
        name: ""
    }


    const fetchchapterList = async () => {
        try {
            const res = await getChapterList()
            if (res) {
                setChapterData(res ? res : [])
            }
        } catch (error) {
            console.error("error", error)
        }
    }



    useEffect(() => {
        fetchchapterList()

    }, [])
    const validationSchema = Yup.object().shape({
        chapter_id: Yup.string().required('chapter is required'),
        name: Yup.string().required('Group Name is required'),
    })

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: async (values, { resetForm }) => {
            setLoading(true)
            try {
                var postData = {
                    chapter_id: values.chapter_id,
                    name: values.name,
                }
                if (idGet) {
                    await updateGroup(idGet, postData)
                        .then((res: any) => {
                            if (res) {
                                refetch()
                                setMessage('success', 'Group updated')
                                resetForm()
                                handleClose()
                                closebutton?.current?.click()
                                setLoading(false)
                                setId('')
                            }
                        })
                        .catch((error: any) => {
                            setLoading(false)
                            if (error?.response?.data?.error?.message) {
                                setMessage('error', error?.response?.data?.error?.message)
                            } else {
                                setMessage('error', 'Something Went Wrong')
                            }

                        })
                }
                else {
                    await addNewGroup(postData)
                        .then((res: any) => {
                            if (res) {
                                setLoading(false)
                                refetch()
                                setMessage('success', 'Group Added')
                                resetForm()
                                handleClose()
                                closebutton.current.click()
                            }
                        })
                        .catch((error: any) => {
                            setLoading(false)
                            if (error?.response?.data?.error?.message) {
                                setMessage('error', error?.response?.data?.error?.message)
                            } else {
                                setMessage('error', 'Something Went Wrong12')
                            }

                        })
                }

            } catch (error) {

                setMessage('error', 'Something went wrong1')
            }
        },
    })


    const fetctData = async () => {
        await getGroupData(idGet).then((res) => {
            if (res?.data) {
                if (res?.data?.chapter_id) {
                    formik.setFieldValue("chapter_id", res?.data?.chapter_id)
                }
                if (res?.data?.name) {
                    formik.setFieldValue("name", res?.data?.name)
                }
            }
        }).catch((error: any) => {

            if (error?.response?.data?.error?.message) {
                setMessage('error', error?.response?.data?.error?.message)
            } else {
                setMessage('error', 'Something Went Wrong')
            }

        })
    }

    useEffect(() => {
        if (idGet) {
            fetctData()
        }
    }, [idGet])


    return (

        <>

            {/* <Form onSubmit={formik.handleSubmit}>
                <div className="modal-body visitor-details">
                    <div className='col-md-12 mb-8'>
                        <select className="form-select ps-0" aria-label="Default select example"
                            onChange={(event: any) => {
                                formik.setFieldValue('chapter_id', event.target.value)
                            }}
                            value={formik.values.chapter_id}>
                            <option defaultValue="">Select Chapter <span className="text-danger"> * </span></option>
                            {
                                chapterData.map((obj: any, ind: any) => {
                                    return <option key={ind} value={obj.id}>{obj.name}</option>
                                })
                            }
                        </select>
                        {formik?.touched?.chapter_id && formik?.errors?.chapter_id && (
                            <div className='fv-plugins-message-container text-danger'>
                                <span role='alert'>{formik?.errors?.chapter_id as any}</span>
                            </div>
                        )}
                    </div>
                    <div className='col-md-12 col-lg-12'>
                        <div className="form-floating mb-3">
                            <input type="text" className="form-control ps-0" id="floatingInput" placeholder=""
                                onChange={(e) => {
                                    formik.setFieldValue('name', e.target.value)
                                }}
                                value={formik.values.name} />
                            <label className="ps-0"> Group Name <span className="text-danger"> * </span></label>
                        </div>
                        {formik?.touched?.name && formik?.errors?.name && (
                            <div className='fv-plugins-message-container text-danger'>
                                <span role='alert'>{formik?.errors?.name as any}</span>
                            </div>
                        )}
                    </div>


                    <div className='mt-10'>
                        <Button variant='primary' type='submit' className='w-100' style={{ backgroundColor: '#f5f5f5' }}>
                            Submit
                        </Button>
                    </div>
                </div>
            </Form> */}



            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <h5 className='modal-title text-primary fs-4 fw-bold' id='exampleModalLabel'>
                        {' '}{
                            idGet ? ("Update Group") : ("Add Group")
                        }
                        {' '}
                    </h5>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={formik.handleSubmit}>
                        <div className=" visitor-details">
                            <div className='col-md-12 mb-8'>
                                <select className="form-select ps-0" aria-label="Default select example"
                                    onChange={(event: any) => {
                                        formik.setFieldValue('chapter_id', event.target.value)
                                    }}
                                    value={formik.values.chapter_id}>
                                    <option defaultValue="">Select Chapter <span className="text-danger"> * </span></option>
                                    {
                                        chapterData.map((obj: any, ind: any) => {
                                            return <option key={ind} value={obj.id}>{obj.name}</option>
                                        })
                                    }
                                </select>
                                {formik?.touched?.chapter_id && formik?.errors?.chapter_id && (
                                    <div className='fv-plugins-message-container text-danger'>
                                        <span role='alert'>{formik?.errors?.chapter_id as any}</span>
                                    </div>
                                )}
                            </div>
                            <div className='col-md-12 col-lg-12'>
                                <div className="form-floating mb-3">
                                    <input type="text" className="form-control ps-0 pt-12" id="floatingInput" placeholder=""
                                        onChange={(e) => {
                                            formik.setFieldValue('name', e.target.value)
                                        }}
                                        value={formik.values.name} />
                                    <label className="ps-0"> Group Name <span className="text-danger"> * </span></label>
                                </div>
                                {formik?.touched?.name && formik?.errors?.name && (
                                    <div className='fv-plugins-message-container text-danger'>
                                        <span role='alert'>{formik?.errors?.name as any}</span>
                                    </div>
                                )}
                            </div>


                            <div className='mt-10'>
                                <Button variant='primary' type='submit' className='w-100' style={{ backgroundColor: '#f5f5f5' }} disabled={isLoading}>
                                    {isLoading ? "Please wait" : "Submit"}
                                </Button>
                            </div>
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>

        </>
    )

}
export default AddEditNewGroup