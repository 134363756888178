import { useEffect, useMemo, useState } from 'react'
import { useTable, ColumnInstance, Row } from 'react-table'
import { CustomHeaderColumn } from './columns/CustomHeaderColumn'
import { CustomRow } from './columns/CustomRow'
import { useQueryResponseData, useQueryResponseLoading } from '../core/QueryResponseProvider'
import { sessionattendancesColumns } from './columns/_columns'
import { Sessionattendance } from '../core/_models'
import { SessionattendancesListLoading } from '../components/loading/SessionattendancesListLoading'
import { SessionattendancesListPagination } from '../components/pagination/SessionattendancesListPagination'
import { KTCardBody, defaultSessionattendances } from '../../../../../_metronic/helpers'
import { useQueryRequest } from '../core/QueryRequestProvider'
import { getChaptersList } from '../core/_requests'
import { CopySessionattendancesTable } from './CopySessionAttendanceTable'

const SessionattendancesTable = () => {
  const [chapterList,setChapterList] = useState<any>([
    {label:"All",value:""}
  ])
  const [inputType, setInputType] = useState('text');
  const{state,updateState} = useQueryRequest() as any
  const sessionattendances = useQueryResponseData()
  const isLoading = useQueryResponseLoading()
  const data = useMemo(() => sessionattendances, [sessionattendances])
  const columns = useMemo(() => sessionattendancesColumns, [])
  const { getTableProps, getTableBodyProps, headers, rows, prepareRow } = useTable({
    columns,
    data,
  })
  const getChapter = async () => {
    await getChaptersList().then((response:any)=>{
      if(response && response?.length > 0){
        let clist = response?.map((x:any)=>{
          return {label:x?.name, value:x?.id}
        }) as any ?? []
        clist.unshift({label:"All",value:""})
        setChapterList(clist)
      }
      // response 
    }).catch((error:any)=>{
      console.log("error",error)
    })
  }

  useEffect(()=>{
    getChapter()
  },[]) 

  return (
    <KTCardBody className='p-0'>
        <CopySessionattendancesTable />
      <div className='table-responsive mb-8 data-table-report'>
        <table
          id='kt_table_sessionattendances'
          className='table align-middle table-row-dashed fs-6 gy-5 dataTable table-striped  no-footer'
          {...getTableProps()}
        >
          <thead>
            <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0 border-bottom border-gray-100'>
              {headers.map((column: ColumnInstance<Sessionattendance>) => (
                <CustomHeaderColumn key={column.id} column={column} />
              ))}
            </tr>
          </thead>
          <thead className='table-sub-hdr'>

            <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
              {/* {headers.map((column: ColumnInstance<Sessionattendance>) => (
                <input type="text"/>
              ))} */}
              <th className='p-3'> <select 
                className="form-select form-select-lg mb-3 mt-2" 
                aria-label=".form-select-lg example"
                value={state?.filter?.chapter_id}
                onChange={(e:any)=>{
                  let filter = state?.filter ?? {}
                  filter['chapter_id'] = e.target.value
                  updateState({...state,...{filter:filter}})
                }}
              >
                {chapterList.map((x:any)=>{
                  return <option value={x?.value}>{x?.label}</option>
                })}
              </select> </th>
              <th className='p-3'> 
                <input type="text" 
                  className="form-control mb-3" 
                  placeholder=" "
                  value={state?.filter?.leadername}
                  onChange={(e:any)=>{
                    let filter = state?.filter ?? {}
                    filter['leadername'] = e.target.value
                    updateState({...state,...{filter:filter}})
                  }} 
                /> 
              </th>
              {/* filter_event_date */}
              <th className='p-3'> 
                <input 
                  type={inputType}
                  // type="date" 
                  className="form-control mb-3" 
                  placeholder="Events Date"
                  value={state?.filter?.event_date}
                  onChange={(e:any)=>{
                    let filter = state?.filter ?? {}
                    filter['event_date'] = e.target.value
                    updateState({...state,...{filter:filter}})
                  }} 
                  onFocus={() => setInputType('date')}
                  onBlur={() => setInputType('text')} 
                /> 
              </th>
              <th className='p-3'> 
              <select className="form-select form-select-lg mb-3  mt-2" 
                aria-label=".form-select-lg example"
                value={state?.filter?.attendance}
                onChange={(e:any)=>{
                  let filter = state?.filter ?? {}
                  filter['attendance'] = e.target.value !== "" ? parseInt(e.target.value) : ""
                  updateState({...state,...{filter:filter}})
                }} 
                
              >
                <option value="" selected>All</option>
                <option value="1">Present</option>
                <option value="0">Absent</option>
                <option value="2">Substitute Present</option>
              </select> 
              </th>
              <th className='p-3'> &nbsp; </th>
            </tr>
          </thead>
          <tbody className='text-gray-600 fw-bold text-start' {...getTableBodyProps()}>
            {rows.length > 0 ? (
              rows.map((row: Row<Sessionattendance>, i) => {
                prepareRow(row)
                return <CustomRow row={row} key={`row-${i}-${row.id}`} />
              })
            ) : (
              <tr>
                <td colSpan={7}>
                  <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                    No matching records found
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <SessionattendancesListPagination />
      {isLoading && <SessionattendancesListLoading />}
    </KTCardBody>
  )
}

export { SessionattendancesTable }
