import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import AddLibraryDetails from "./AddLibraryDetails";
import { createLibraryViewMedia, deleteLibraryViewMediaById, getLibraryViewMediaById, getLibrarymanagementById, getLibrarymanagements } from "./librarymanagements-list/core/_requests";
import { toAbsoluteUrl } from "../../../_metronic/helpers";
import { Button, Image, Modal } from "react-bootstrap";
import { checkDisplayValue } from "../../../utils";
import { apiUrl } from "../../../apiurl";
import ReactAudioPlayer from "react-audio-player";
import { UserContext } from "../../../contextStore";


const API_URL = apiUrl
const ViewSection = () => {
  const { userDetail, setMessage, reload, chapterID } = useContext(UserContext)
    console.log("----")
    const { id } = useParams()
    const [addpopUp, setAddpopUp] = useState(false)
    const [sectionpostName,setSectionPostName]=useState("")
    const [deleteModal, setDeleteModal] = useState<boolean>(false)
    const [deletetId ,setDeletetId] =useState<string>('')
    // const id = 4
    const [viewData,setViewData] =useState([])

    const navigate = useNavigate()
    const navigateLibraryManagement = () => {
        // 👇️ navigate to /
        navigate('/libraries-management')
    }
    const fetchviewById = async () => {
      await getLibrarymanagementById(id)
        .then((data: any) => {
          // setViewData(res)
          setSectionPostName(data?.title)
        })
        .catch((error: any) => {
          console.log(error)
        })
    }

    const fetchPostById = async()=>{
      await getLibraryViewMediaById(id).then((res:any)=>{
        setViewData(res)
     }).catch((error:any)=>{
        console.log(error)

     })
    }

    const deletedMedia = async(Mid:any)=>{
       await deleteLibraryViewMediaById(Mid).then(async(res:any)=>{
        console.log("res",res)
        if(res?.data?.count){
          setMessage('success', 'Deleted Successfully')
          await fetchPostById()
        }
     }).catch((error:any)=>{
        console.log(error)
        setMessage('error', 'Something Went Wrong')
     })
    }
    const handleButtonClick = (pdf: any) => {
        if (pdf) {
          const url = `${API_URL}/containers/public/download/${pdf}`
          window.open(url, '_blank')
        }
      }

    useEffect(() => {
      fetchviewById()
      fetchPostById()
    }, [])
    useEffect(() => {
      fetchviewById()
      fetchPostById()
    }, [addpopUp])
    
    
    return (

        <>

            <div className="row flex-md-block ">
                <div className='col-sm-6 col-md-6 col-lg-6'><h1 className='inv-hst sm-ms-2'> Detail Library View  </h1></div>

                <div className='card mx-3 py-8 px-5 px-sm-8 mt-5 ilead__card'>
                    <div className="row mx-0 align-items-center  mb-5 ">
                        <div className='col-md-12 col-lg-6 fw-bold text-primary fs-3'> {sectionpostName}</div>
                        <div className='col-md-12 col-lg-6 text-end'>
                            <button data-bs-toggle="modal"  onClick={()=>{setAddpopUp(true)}} className="btn btn-primary waves-effect waves-light btn-sm me-3" > Add Details </button>
                            <button onClick={navigateLibraryManagement} className="btn btn-info waves-effect waves-light btn-sm" > Back </button>
                        </div>
                    </div>

                    {/* <div className="row library-row mobile-padding">

                        <div className="col-md-5 col-lg-4 col-xl-3 mb-3">
                            <div className="card ilead-card library__box border border-secondary h-100">
                                <div className="card-body p-3">
                                    <a className="btn btn-sm fw-bold p-0" data-bs-toggle="modal" data-bs-target="#kt_modal_create_app" href="/library">
                                        <img src="./media/svg/files/pdf2.png" alt="" className="w-100 mb-5" style={{ borderRadius: '0.475rem 0.475rem 0px 0px' }} />
                                        <h5 className="text-dark"> ILEAD Sampark Presentation </h5>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div> */}

                    <div className="row library-row mobile-padding">
                    {viewData?.map((item:any,i:any)=>{
                        if (item?.type == 3 ){
                        return <>
                        <div className="col-md-6 col-lg-6 col-xl-4 mb-3">
                            <div className="card ilead-card library__box border border-secondary h-100">
                                <div className="card-body p-3">
                                    <iframe src={`${item?.file}`} title="video" className="w-100" height="280px" style={{ borderRadius: '0.475rem 0.475rem 0px 0px' }}></iframe>
                                    <h5 className="mt-3 text-center px-3 text-dark twoline-truncate" style={{ lineHeight: 1.5 }}>
                                         {item?.description}
                                    </h5>
                                </div>
                                <button type="button" className="btn-close text-white fs-4"  
                                      onClick={()=>{
                                        // deletedMedia(item?.id)
                                        setDeleteModal(true)
                                        setDeletetId(item?.id)

                                      }}
                                      style={{
                                        backgroundColor: 'red',
                                        position: 'absolute',
                                        right: '5px',
                                        top: '5px',
                                      }}
                                      ></button>
                            </div>
                        </div>

                        </>
                        } 
                        else if(item?.type == 1 ) {
                            return (
                              <>
                                <div className='col-md-6 col-lg-6 col-xl-4 mb-3'>
                                  <div className='card ilead-card library__box border border-secondary h-100'>
                                    <div className='card-body p-3'>
                                      <Link
                                        to=''
                                        onClick={() => handleButtonClick(item?.file)}
                                        className='btn btn-sm fw-bold p-0'
                                        data-bs-toggle='modal'
                                        data-bs-target='#kt_modal_create_app'
                                      >
                                        <Image
                                          src={toAbsoluteUrl('../../media/svg/files/pdf2.png')}
                                          alt=''
                                          className='w-100 mb-5'
                                          style={{borderRadius: '0.475rem 0.475rem 0 0'}}
                                        />
                                        {item?.description ? (
                                          <h5 className='text-dark'>
                                            {' '}
                                            {checkDisplayValue(item?.description)}{' '}
                                          </h5>
                                        ) : (
                                          ''
                                        )}
                                      </Link>
                                      <button type="button" className="btn-close text-white fs-4"  
                                      onClick={()=>{
                                        // deletedMedia(item?.id)
                                        setDeleteModal(true)
                                        setDeletetId(item?.id)
                                      }}
                                      style={{
                                        backgroundColor: 'red',
                                        position: 'absolute',
                                        right: '5px',
                                        top: '5px',
                                      }}
                                      ></button>
                                    </div>
                                  </div>
                                </div>
                              </>
                            )
                        }
                        else if(item?.type == 2){
                            return (
                              <>
                                <div className='col-md-7 mb-3 library'>
                                  <div className='card ilead-card library__box border border-secondary h-100'>
                                    <div className='card-body p-3'>
                                      <div className='song-div position-relative overflow-hidden d-flex align-items-center'>
                                        <div className='song-cover position-absolute top-0 start-0'>
                                          <Image src={toAbsoluteUrl('../../cover.png')} alt='' />
                                        </div>
                                        <div className='song-audio w-100'>
                                          <ReactAudioPlayer
                                            src={`${API_URL}/containers/public/download/${item?.file}`}
                                            autoPlay={false}
                                            controls
                                            className='w-100'
                                          />
                                          {item?.description ? (
                                            <h5 className='text-dark'>
                                              {' '}
                                              {checkDisplayValue(item?.description)}{' '}
                                            </h5>
                                          ) : (
                                            ''
                                          )}
                                        </div>
                                        <button type="button" className="btn-close text-white fs-4"  
                                      onClick={()=>{
                                        // deletedMedia(item?.id)
                                        setDeleteModal(true)
                                        setDeletetId(item?.id)
                                      }}
                                      style={{
                                        backgroundColor: 'red',
                                        position: 'absolute',
                                        right: '5px',
                                        top: '5px',
                                      }}
                                      ></button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </>
                            )
                        }
                        return <></>
                    })} 
                    <Modal
                      show={deleteModal}
                      onHide={() => {
                        setDeleteModal(false)
                        setDeletetId('')
                      }}
                    >
                      <Modal.Header closeButton>
                        <Modal.Title>Delete Media</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>Are you sure you want to delete this media ?</Modal.Body>
                      <Modal.Footer>
                        <Button
                          variant='secondary'
                          onClick={() => {
                            setDeleteModal(false)
                            setDeletetId('')
                          }}
                        >
                          No
                        </Button>
                        <Button
                          variant='primary'
                          onClick={() => {
                            setDeleteModal(false)
                            deletedMedia(deletetId)
                          }}
                        >
                          Yes
                        </Button>
                      </Modal.Footer>
                    </Modal>
                    </div>
                    {/* <div className="row library-row mobile-padding">
                       
                        <div className="col-md-6 col-lg-6 col-xl-4 mb-3">
                            <div className="card ilead-card library__box border border-secondary h-100">
                                <div className="card-body p-3">
                                    <iframe src="https://www.youtube.com/embed/9mBmVC6H1Vw" title="video" className="w-100" height="280px" style={{ borderRadius: '0.475rem 0.475rem 0px 0px' }}></iframe>
                                    <h5 className="mt-3 text-center px-3 text-dark twoline-truncate" style={{ lineHeight: 1.5 }}>
                                        Example Video
                                    </h5>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-6 col-xl-4 mb-3">
                            <div className="card ilead-card library__box border border-secondary h-100">
                                <div className="card-body p-3">
                                    <iframe src="https://www.youtube.com/embed/9mBmVC6H1Vw" title="video" className="w-100" height="280px" style={{ borderRadius: '0.475rem 0.475rem 0px 0px' }}></iframe>
                                    <h5 className="mt-3 text-center px-3 text-dark twoline-truncate" style={{ lineHeight: 1.5 }}>
                                        Example Video
                                    </h5>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-6 col-xl-4 mb-3">
                            <div className="card ilead-card library__box border border-secondary h-100">
                                <div className="card-body p-3">
                                    <iframe src="https://www.youtube.com/embed/9mBmVC6H1Vw" title="video" className="w-100" height="280px" style={{ borderRadius: '0.475rem 0.475rem 0px 0px' }}></iframe>
                                    <h5 className="mt-3 text-center px-3 text-dark twoline-truncate" style={{ lineHeight: 1.5 }}>
                                        Example Video
                                    </h5>
                                </div>
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>

            <AddLibraryDetails  show={addpopUp} handleset={setAddpopUp} id={id} fetchviewById={fetchviewById}/>
        </>
    )
}

export default ViewSection