import { ListViewProvider, useListView } from './core/ListViewProvider'
import { QueryRequestProvider } from './core/QueryRequestProvider'
import { QueryResponseProvider } from './core/QueryResponseProvider'
import { GroupchatsListHeader } from './components/header/GroupchatsListHeader'
import { GroupchatsTable } from './table/GroupchatsTable'
import { GroupchatEditModal } from './groupchat-edit-modal/GroupchatEditModal'
import { KTCard } from '../../../../../_metronic/helpers'
// import { KTCard } from '../../../../_metronic/helpers'
// import {KTCard} from '../../../../../_metronic/helpers'

const GroupchatsList = () => {
  const { itemIdForUpdate } = useListView()
  return (
    <>

      {/* <GroupchatsListHeader /> */}
      <GroupchatsTable />

      {itemIdForUpdate !== undefined && <GroupchatEditModal />}
    </>
  )
}

const GroupchatsListWrapper = () => (

  <ListViewProvider>
    <GroupchatsList />
  </ListViewProvider>


)

export { GroupchatsListWrapper }
