import React, { useCallback, useContext, useEffect, useState } from "react";
import { Button, Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useDropzone } from 'react-dropzone'
import Select from 'react-select'
import { Formik, useFormik } from "formik";
import * as Yup from 'yup'
import { getChapterList } from "../auth/core/_requests";
import { GropusListAPI, createExitinguser, updateExitinguser, userSingleAPI } from "./exitingusers-list/core/_requests";
import { UserContext } from "../../../contextStore";
import { apiUrl } from "../../../apiurl";
import moment from "moment";
import axios from "axios";
import { useQueryClient } from "react-query";
// improt {QUERIES}
import { useQueryResponse } from "./exitingusers-list/core/QueryResponseProvider";
import { QUERIES } from "../../../_metronic/helpers";
import zIndex from "@mui/material/styles/zIndex";
const API_URL = apiUrl
const IMAGE_UPLOAD = `${API_URL}/containers/userprofile/upload`

const phoneRegExp = /^\d{10}$/

const AddEditNewLeader = ({id,onHide}:any) => {
  const queryClient = useQueryClient()
  const { query } = useQueryResponse()
  const { refetch } = useQueryResponse()
  const [error, setError] = useState('');
    const onDrop = useCallback((acceptedFiles: any) => {
      
        console.log("acceptedFiles",acceptedFiles[0]?.type)
        setError("")
        if( acceptedFiles[0].type == 'image/jpeg' || acceptedFiles[0]?.type == 'image/png'){
          const file = acceptedFiles[0]
        setImage(null)
        setSelectedFile(file)
        } else {
          setError('Only JPEG and PNG files are allowed.');
        }
        
    }, [])
    const { acceptedFiles,fileRejections, getRootProps, getInputProps } = useDropzone({onDrop,accept: { 'image/jpeg': [], 'image/png': [] },maxSize:5242880,maxFiles:1})
    const Id = id 
    const { userDetail, setMessage, reload, chapterID } = useContext(UserContext)
    const [chapterList,setchapterList] =useState([])
    const [groupList,setGroupList] =useState([])
    const [image, setImage] = useState<any>(null)
    const [imageName, setImageName] = useState<any>(null)
    const [selectedFile, setSelectedFile] = useState<File | null>(null)
    // const [imageName, setImageName] = useState<String | null>('')
    // const closeModal = useRef<any>()

    const acceptedFileItems = acceptedFiles.map((file:any ) => (
      <li key={file?.path}>
        {file?.path} - {file?.size} bytes
      </li>
    )); 
    const fileRejectionItems: JSX.Element[] = fileRejections.map(
      ({ file, errors }: any) => (
        <li key={file?.path}>
          {file?.path} - {file?.size} bytes
          <ul>
            {errors.map((e:any) => (
              <li  key={e.code}>
              <div  style={{color:"red"}} className='fv-help-block'>
                      <span style={{color:"red"}} role='alert'>{e?.message}</span>
                    </div>
              </li>
            ))}
          </ul>
        </li>
      )
    )
    
    const getSingleData =async (id:any) => {
      try {
        const res = await userSingleAPI(id).then((res:any)=>{
          if(res?.data){
            console.log("res?.data",res?.data?.name)
            formik.setFieldValue("name",res?.data?.name)
            formik.setFieldValue("companyname",res?.data?.companyname)
            formik.setFieldValue("membership_enrollment_date",
            res?.data?.membership_enrollment_date ? moment(res?.data?.membership_enrollment_date).format('YYYY-MM-DD'):"")
            formik.setFieldValue("membership_renewal_date",
            res?.data?.membership_renewal_date ? moment(res?.data?.membership_renewal_date).format('YYYY-MM-DD') : "")
            formik.setFieldValue("email",res?.data?.email)
            formik.setFieldValue("mobileno",res?.data?.mobileno)
            formik.setFieldValue("group_id",res?.data?.group_id)
            formik.setFieldValue("chapter_id",res?.data?.chapter_id)
            formik.setFieldValue("website",res?.data?.website)
            formik.setFieldValue("business_category",res?.data?.business_category)
            formik.setFieldValue("lifetime_member",res?.data?.lifetimemember ?  true : false )
            formik.setFieldValue("iscaptain",res?.data?.iscaptain == 1 ?  true : false )
            // formik.setFieldValue("profile",res?.data?.profile)
            setImage(res?.data?.profile)
            convertURLToBlob(`${API_URL}/containers/userprofile/download/${res?.data?.profile}`)
              .then((imageBlob: any) => {
                // Set the Blob as selectedFile
                setSelectedFile(new File([imageBlob], res?.data?.image)) // Create a File object from the Blob
                setImageName(res?.data?.image)
                console.log('selecttttt', selectedFile)
                
              })
              .catch((error) => {
                console.error(error)
              })
          }
        })
        .catch((error:any)=>{
          console.log("error",error)
        })
        // setchapterList(res?.data)
    } catch (error) {
      console.log("error",error)
    }
    }
    // const files = acceptedFiles.map((file: any,index:number) => {
    //     console.log("file",file)
    //     return <li key={file.path}>
    //     <img
    //       src={URL.createObjectURL(file)}
    //       alt={`Image: ${file.path}`}
    //       style={{ maxWidth: '100px', maxHeight: '100px' }}
    //     />
    //     <p>
    //       {file.path} - {file.size} bytes
    //     </p>
    //     <button onClick={() => handleRemoveImage(index)}>Remove</button>
    //   </li>})



    // <li> 
    // <img
    // alt={`img}`}
    // src={file?.path}
    // className="file-img"/>
    // {file?.path} - {file?.size} bytes </li>)
    function convertURLToBlob(imageURL: any) {
      return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest()
        xhr.responseType = 'blob'

        xhr.onload = () => {
          if (xhr.status === 200) {
            resolve(xhr.response)
          } else {
            reject(new Error(`Failed to fetch image: ${xhr.status} - ${xhr.statusText}`))
          }
        }

        xhr.onerror = () => {
          reject(new Error('Network error'))
        }

        xhr.open('GET', imageURL)
        xhr.send()
      })
    }


    const formSchema = Yup.object().shape({
        name : Yup.string().required("required").min(3, 'Name must be more than 2 characters.').max(50, 'Name must be less than 50 characters.'),
        companyname: Yup.string().required("required").min(3, 'Company Name must be more than 2  characters.').max(50, 'Company Name must be less than 50 characters.'),
        membership_enrollment_date:  Yup.string().required("Enrollment date is required"),
        email : Yup.string().required("required").email("Email must be valid"),
        mobileno : Yup.string().required("required").matches(phoneRegExp, 'Phone number is not valid'),
        group_id :  Yup.string().required("Group is required"),
        chapter_id:  Yup.string().required("Chapter is required"),
        website:Yup.string(),
        business_category: Yup.string(),
        lifetime_member : Yup.boolean(),
        iscaptain : Yup.boolean(),
        // profile:Yup.string(),
        membership_renewal_date :Yup.string()
      })

    const initdata = {
        name:"",
        companyname:"",
        membership_enrollment_date:"",
        email:"",
        mobileno:"",
        group_id:"",
        chapter_id: '',
        website:"",
        business_category:'',
        lifetime_member:false,
        iscaptain:false,
        // profile:"",
        membership_renewal_date:""
        // data?.meeting_date ? moment(data?.meeting_date).format('YYYY-MM-DD')
        // ...(Id && { membership_renewal_date : "" })
    } 
    const formik = useFormik({
        initialValues:initdata,
        validationSchema: formSchema,
        onSubmit: async (values, {setSubmitting,resetForm}) => {
          setSubmitting(true)
          console.log("value",values)
          try {
            let profile = ""
            if(selectedFile && !image ){
              const formData = new FormData()
                 formData.append('file', selectedFile)
              await axios
              .post(`${IMAGE_UPLOAD}`, formData, {
                headers: {
                  'Content-Type': 'multipart/form-data',
                },
              })
              .then(async (res: any) => {
                const FileName = res?.data?.result?.files?.file[0]?.name
                // formik.setFieldValue('profile', FileName)
                // setImageName(FileName)
                profile=FileName
              })
              .catch((error: any) => {
                // setMessage('error', 'Something Went Wrong')
              })
            }else {
             profile=image 
            }
            console.log({...values,...(profile ? {profile} : {})})
            if(!Id) {
              await createExitinguser({...values,...(profile ? {profile} : {})}).then((res:any)=>{
                if(res?.data?.id ){
                  // navigate('/visitor-panel')
                  queryClient.invalidateQueries([`${QUERIES.EXITINGUSERS_LIST}-${query}`])
                  setMessage('success', 'User added successfully')
                  resetForm()
                  refetch()
                  setSelectedFile(null)
                  onHide()
                }
              }).catch((error:any )=>{
                if (error?.response?.data?.error?.message) {
                  console.log("error?.response?",error?.response?.status)
                  if(error?.response?.status == 500 )
                  {
                  setMessage('error', 'Something went wrong')
                }else{
    
                    setMessage('error', error?.response?.data?.error?.message)
                  }
                } else {
                  setMessage('error', 'Something went wrong')
                }
              })
            }else {
              await updateExitinguser({...values,...(profile ? {profile} : {}),id:Id}).then((res:any)=>{
                if(res?.data?.count){
                  // navigate('/visitor-panel')
                  queryClient.invalidateQueries([`${QUERIES.EXITINGUSERS_LIST}-${query}`])
                  setMessage('success', 'User updated successfully')
                  resetForm()
                  setSelectedFile(null)
                  onHide()
                }
              }).catch((error:any )=>{
                if (error?.response?.data?.error?.message) {
                  console.log("error?.response?",error?.response?.status)
                  if(error?.response?.status == 500 )
                  {
                  setMessage('error', 'Something went wrong')
                }else{
    
                  setMessage('error', error?.response?.data?.error?.message)
                }
              } else {
                setMessage('error', 'Something went wrong')
              }
            })
            
          }
            
        } catch (ex) {
            console.error(ex)
          } finally {
            // setSubmitting(true)
            // cancel(true)
          }
        },
      })
      
    const getchpterList= async ()=>{
      try {
        const res = await getChapterList()
            setchapterList(res?.data ?? res?.data )
        } catch (error) {
          console.log("error",error)
        }
    } 

    // const onChangeHandler = (event: any) => {
    //     const file = event.target.files[0]
    //     if (file) {
    //       var pattern = /image-*/
    //       // setSelectedImage(file.name)
    //       if (!file.type.match(pattern) && file.type !== 'application/pdf') {
    //         setMessage('error', 'Invalid format, only Images and Pdf !')
    //         return
    //       }
    //       setImageName(file)
    //       setImage(URL.createObjectURL(event.target.files[0]))
    //     } else {
    //       setImageName(null)
    //       setImageName(null)
    //     }
    //   }
    const getgroupList= async ()=>{
        try {
            const res = await GropusListAPI()
            setGroupList(res?.data ?? res?.data)
        } catch (error:any) {
    
        }
    }

      useEffect(()=>{
        getchpterList()
        getgroupList()
        
      },[])

      useEffect(()=>{
        if(Id){
          getSingleData(Id)
        }
      },[Id])

      // console.log("IdId",Id)


      console.log("taa",formik?.values?.membership_enrollment_date)

    return (
      <>
        <Form onSubmit={formik.handleSubmit} noValidate>
          <div className='modal-body'>
            <div className='row mt-5'>
              <div className='col-md-12 col-lg-6'>
                <Form.Label className='fs-7 text-primary w-100  text-start'>
                  {' '}
                  Select Chapter <span className='text-danger'> * </span>{' '}
                </Form.Label>
                <Form.Select
                  aria-label='Default select example'
                  onChange={(e: any) => {
                    formik.setFieldValue('chapter_id', e.target.value.trimStart())
                  }}
                  className='ps-0'
                  required
                >
                  <option value=''> Select </option>
                  {/* <option value='1'>Eklavya</option> */}
                  {chapterList &&
                    chapterList?.map((item: any, i: any) => {
                      return (
                        <option
                          value={item?.id}
                          selected={item?.id == formik?.values?.chapter_id ? true : false}
                          key={i}
                        >
                          {item?.name}
                        </option>
                      )
                    })}
                </Form.Select>
                {formik.touched.chapter_id && formik?.errors.chapter_id && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors?.chapter_id}</span>
                    </div>
                  </div>
                )}
              </div>
              <div className='col-md-12 col-lg-6'>
                <Form.Label className='fs-7 text-primary w-100  text-start'>
                  {' '}
                  Select Group <span className='text-danger'> * </span>{' '}
                </Form.Label>
                <Form.Select
                  aria-label='Default select example'
                  onChange={(e: any) => {
                    formik.setFieldValue('group_id', e.target.value.trimStart())
                  }}
                  className='ps-0'
                  required
                >
                  <option> Select </option>
                  {groupList &&
                    groupList?.map((item: any, i: any) => {
                      return (
                        <option
                          value={item?.id}
                          selected={item?.id == formik?.values?.group_id ? true : false}
                          key={i}
                        >
                          {item?.name}
                        </option>
                      )
                    })}
                  {/* <option value='1'> ILead </option>
                  <option value='2'> Bhagirath </option>
                  <option value='2'> Vishwmitra </option>
                  <option value='2'> Valmiki </option>
                  <option value='2'> Trustee </option>
                  <option value='2'> Beta 1 </option>
                  <option value='2'> Chanakya </option>
                  <option value='2'> Atmashodh </option> */}
                </Form.Select>
                {formik.touched.group_id && formik?.errors.group_id && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors?.group_id}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mt-5'>
              <div className='col-md-12 col-lg-6'>
                <Form.Label className='fs-7 text-primary w-100  text-start'>
                  {' '}
                  Leader Name <span className='text-danger'> * </span>{' '}
                </Form.Label>
                <Form.Control
                  required
                  type='text'
                  value={formik?.values?.name}
                  onChange={(event: any) => {
                    formik.setFieldValue('name', event.target.value.trimStart())
                  }}
                  placeholder=''
                  className='ps-0'
                />
                {formik.touched.name && formik?.errors.name && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors?.name}</span>
                    </div>
                  </div>
                )}
              </div>

              <div className='col-md-12 col-lg-6'>
                <Form.Label className='fs-7 text-primary w-100  text-start'>
                  {' '}
                  Mobile <span className='text-danger'> * </span>{' '}
                </Form.Label>
                <Form.Control
                  required
                  type='text'
                  value={formik?.values?.mobileno}
                  onChange={(event: any) => {
                    formik.setFieldValue('mobileno', event.target.value.trimStart())
                  }}
                  placeholder=' '
                  className='ps-0'
                />
                {formik.touched.mobileno && formik?.errors.mobileno && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors?.mobileno}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mt-5'>
              <div className='col-md-12 col-lg-6'>
                <Form.Label className='fs-7 text-primary w-100  text-start'>
                  {' '}
                  Email <span className='text-danger'> * </span>{' '}
                </Form.Label>
                <Form.Control
                  required
                  type='text'
                  value={formik?.values?.email}
                  onChange={(event: any) => {
                    formik.setFieldValue('email', event.target.value.trim())
                  }}
                  placeholder=''
                  className='ps-0'
                />
                {formik.touched.email && formik?.errors.email && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors?.email}</span>
                    </div>
                  </div>
                )}
              </div>

              <div className='col-md-12 col-lg-6'>
                <Form.Label className='fs-7 text-primary w-100  text-start'>
                  {' '}
                  Company Name <span className='text-danger'> * </span>{' '}
                </Form.Label>
                <Form.Control
                  required
                  type='text'
                  value={formik?.values?.companyname}
                  onChange={(event: any) => {
                    formik.setFieldValue('companyname', event.target.value.trimStart())
                  }}
                  placeholder=' '
                  className='ps-0'
                />

                {formik.touched.companyname && formik?.errors.companyname && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors?.companyname}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mt-5'>
              <div className='col-md-12 col-lg-6'>
                <Form.Label className='fs-7 text-primary w-100  text-start'>
                  {' '}
                  Membership Enrollment Date <span className='text-danger'> * </span>{' '}
                </Form.Label>
                <Form.Control
                  required
                  type='date'
                  value={formik?.values?.membership_enrollment_date}
                  onChange={(event: any) => {
                    console.log("event.target.value",event.target.value)
                    formik.setFieldValue(
                      'membership_enrollment_date',
                      event.target.value.trimStart()
                    )
                  }}
                  placeholder=''
                  className='ps-0'
                />
                {formik.touched.membership_enrollment_date &&
                  formik?.errors.membership_enrollment_date && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors?.membership_enrollment_date}</span>
                      </div>
                    </div>
                  )}
              </div>

              <div className='col-md-12 col-lg-6 mb-8 ps-3 checkbox-bdr'>
                {['checkbox'].map((type) => (
                  <div key={`inline-${type}`} className='mb-3'>
                    <Form.Check
                      inline
                      label='Lifetime Member'
                      name='group1'
                      // type={type}
                      checked={formik?.values?.lifetime_member}
                      id={`inline-${type}-1`}
                      onChange={(e: any) => {
                        formik.setFieldValue('lifetime_member', e.target.checked)
                      }}
                    />
                  </div>
                ))}
              </div>
            </div>
            {
              id && (<div className='row mt-5'>
              <div className='col-md-12 col-lg-6'>
                <Form.Label className='fs-7 text-primary w-100  text-start'>
                  {' '}
                  Membership Renewal Date{' '}
                </Form.Label>
                <Form.Control
                  required
                  type='date'
                  value={formik?.values?.membership_renewal_date}
                  onChange={(event: any) => {
                    formik.setFieldValue(
                      'membership_renewal_date',
                      event.target.value
                    )
                  }}
                  placeholder=''
                  className='ps-0'
                />
                {formik.touched.membership_renewal_date &&
                  formik?.errors.membership_renewal_date && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors?.membership_renewal_date}</span>
                      </div>
                    </div>
                  )}
              </div>
            </div>
)
            }

            <div className='row mt-5'>
              <div className='col-md-12 col-lg-6'>
                <Form.Label className='fs-7 text-primary w-100  text-start'>
                  {' '}
                  Business Category{' '}
                </Form.Label>
                <Form.Control type='text' 
                value={formik?.values?.business_category}
                onChange={(event: any) => {
                  formik.setFieldValue('business_category', event.target.value.trimStart())
                }}
                placeholder='Enter Business Category' className='ps-0' />
                {formik.touched.business_category && formik?.errors?.business_category && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors?.business_category}</span>
                    </div>
                  </div>
                )}
              </div>

              <div className='col-md-12 col-lg-6'>
                <Form.Label className='fs-7 text-primary w-100  text-start'> Website </Form.Label>
                <Form.Control
                  type='text'
                  value={formik?.values?.website}
                  onChange={(event: any) => {
                    formik.setFieldValue('website', event.target.value.trimStart())
                  }}
                  placeholder='Enter Website '
                  className='ps-0'
                />
                {formik.touched.website && formik?.errors?.website && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors?.website}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mt-5'>
            <div className='col-md-12 col-lg-6 mb-8 ps-3 checkbox-bdr'>
                {['checkbox'].map((type) => (
                  <div key={`inline-${type}`} className='mb-3'>
                    <Form.Check
                      inline
                      label='Group Facilitator'
                      name='group1'
                      // type={type}
                      checked={formik?.values?.iscaptain}
                      id={`inline-${type}-1`}
                      onChange={(e: any) => {
                        formik.setFieldValue('iscaptain', e.target.checked)
                      }}
                    />
                  </div>
                ))}
              </div>
            <div className='col-md-12 col-lg-6'></div>
            </div>

            <div className='col-12 mt-10'>
              <section className='container'>
                <h4 className='fw-normal fs-6 border-gray-600'>
                  {' '}
                  Upload Profile Picture:
                  <OverlayTrigger
                    overlay={
                      <Tooltip id='tooltip-disabled' className='text-white'>
                        (Supported files: .jpg, .jpeg <br /> Max File Size: 5MB){' '}
                      </Tooltip>
                    }
                  >
                    <span className='d-inline-block'>
                      <i className='fa-solid fs-3 text-primary ms-3 fa-circle-info'></i>
                    </span>
                  </OverlayTrigger>
                  {/* <span> 
                                                <i className="fa-solid fs-3 text-primary ms-3 fa-circle-info" data-bs-toggle="tooltip" data-bs-placement="top" title="Tooltip on top"></i> 
                                                </span> */}
                </h4>
                {/* <aside> */}
                  
                  {/* {selectedFile ? (
                    <div>
                      <p>Selected File: {selectedFile.name}</p>
                      <img
                        width={100}
                        src={URL.createObjectURL(selectedFile)}
                        alt='Selected'
                        style={{maxWidth: '100%', maxHeight: '200px'}}
                      />
                      <button onClick={()=>{
                        setSelectedFile(null)
                        setImage(null)}}>remove</button>
                    </div>

                  ) : (
                    'No file selected'
                  )} */}
                  {/* {done()} */}
                  {/* <ul>{files}</ul> */}
                {/* </aside> */}
                {error && <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{error}</span>
                    </div>
                  </div>}
                <div {...getRootProps({className: 'dropzone'})}>
                  <input onClick={()=>{
                    // setSelectedFile(null)
                    // setImage(null)
                    setError("")
                  }} {...getInputProps()} required />
                  
                  {selectedFile ? (
                    
                    <div style={{ position: 'relative' }}>
                          <div >
                            <h2>
                            <i className="fa fa-trash fa-lg text-dark " onClick={(e) => {
                              e.stopPropagation()
                              setImage(null)
                              setSelectedFile(null)
                            }} style={{ position: 'absolute', top: '-42px', right: '-34px', backgroundColor: '#f89494', padding: "4px", borderRadius: "50%" }}></i></h2></div>
                          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                            <img
                              src={URL.createObjectURL(selectedFile)}
                              // alt=''
                              // className='w-50px'
                              alt='Selected'
                              style={{maxWidth: '100%', maxHeight:  '70px'}}
                            />
                            <p>{selectedFile?.name}</p> </div></div>

                  ) : (
                    <p>Drag 'n' drop some files here, or click to select files</p>
                  )}
                  
                </div>
              </section>
            </div>
            {fileRejectionItems && fileRejectionItems}
            <div className='modal-footer position-absolute bottom-0 w-100 pe-5'>
              <Button
                variant='primary'
                type='submit'
                className='w-100 me-12'
                disabled={formik?.isSubmitting}
                style={{backgroundColor: '#f5f5f5'}}
              >
                Submit
              </Button>
            </div>
          </div>
        </Form>
      </>
    )

}

export default AddEditNewLeader