import {useQuery} from 'react-query'
import {VisitorpanelEditModalForm} from './VisitorpanelEditModalForm'
// import {isNotEmpty, QUERIES} from '../../../../../../_metronic/helpers'
import {useListView} from '../core/ListViewProvider'
import { QUERIES, isNotEmpty } from '../../../../../_metronic/helpers'
import { getVisitorpanelById } from '../core/_requests'
// import { getVisitorpanelById } from '../../../apps/visitorpanel-management/visitorpanels-list/core/_requests'
// import {getVisitorpanelById} from '../core/_requests'

const VisitorpanelEditModalFormWrapper = () => {
  const {itemIdForUpdate, setItemIdForUpdate} = useListView()
  const enabledQuery: boolean = isNotEmpty(itemIdForUpdate)
  const {
    isLoading,
    data: visitorpanel,
    error,
  } = useQuery(
    `${QUERIES.MONTHLYREPORTS_LIST}-visitorpanel-${itemIdForUpdate}`,
    () => {
      return getVisitorpanelById(itemIdForUpdate)
    },
    {
      cacheTime: 0,
      enabled: enabledQuery,
      onError: (err) => {
        setItemIdForUpdate(undefined)
        console.error(err)
      },
    }
  )

  if (!itemIdForUpdate) {
    return <VisitorpanelEditModalForm isVisitorpanelLoading={isLoading} visitorpanel={{id: undefined}} />
  }

  if (!isLoading && !error && visitorpanel) {
    return <VisitorpanelEditModalForm isVisitorpanelLoading={isLoading} visitorpanel={visitorpanel} />
  }

  return null
}

export {VisitorpanelEditModalFormWrapper}
