import React from 'react'
import { GroupchatsListWrapper } from './chatgroups-list/GroupchatsList'
import { useNavigate } from 'react-router-dom'
import AddChatGroup from './AddChatGroup'
import jsPDF from 'jspdf'
import 'jspdf-autotable'
import html2canvas from 'html2canvas'
import * as XLSX from 'xlsx'
import { QueryRequestProvider } from './chatgroups-list/core/QueryRequestProvider'
import { QueryResponseProvider } from './chatgroups-list/core/QueryResponseProvider'
import { GroupchatsListSearchComponent } from './chatgroups-list/components/header/GroupchatsListSearchComponent'

const ChatGroupListing = () => {
  const navigate = useNavigate()
  const navigateGroupManagement = () => {
    // 👇️ navigate to /
    navigate('/group-management')
  }

  // copy table
  const copyTable = () => {
    try {
      // Get a reference to the table
      let table = document.getElementById('copy_kt_table_groupchats') as any

      // Make the table briefly visible
      table.style.display = 'table'

      // Create a range object to select the table content
      const range = document.createRange()
      range.selectNode(table)

      // Create a selection object and add the range
      const selection = window.getSelection() as any
      selection.removeAllRanges()
      selection.addRange(range)

      // Execute the copy command
      document.execCommand('copy')

      // Deselect the content
      selection.removeAllRanges()

      // Hide the table again (if it was originally hidden)
      table.style.display = 'none'

      // Optionally, provide feedback to the user
      alert('Table data copied to clipboard')
    } catch (err) {
      // Get a reference to the table
      let table = document.getElementById('copy_kt_table_groupchats') as any

      // Hide the table again (if it was originally hidden)
      table.style.display = 'none'
    }
  }

  function ExportToExcel() {
    try {
      // Acquire Data (reference to the HTML table)
      let table = document.getElementById('copy_kt_table_groupchats') as any

      // Make the table briefly visible
      table.style.display = 'table'

      // Extract Data (create a workbook object from the table)
      var workbook = XLSX.utils.table_to_book(table)

      // Hide the table again (if it was originally hidden)
      table.style.display = 'none'

      // Process Data (add a new row)
      var ws = workbook.Sheets['Sheet1']
      // XLSX.utils.sheet_add_aoa(ws, [["Created "+new Date().toISOString()]], {origin:-1});

      // Package and Release Data (`writeFile` tries to write and save an XLSB file)
      XLSX.writeFile(workbook, 'chatGroupExcel.xlsb')
    } catch (err) {
      // Get a reference to the table
      let table = document.getElementById('copy_kt_table_groupchats') as any

      // Hide the table again (if it was originally hidden)
      table.style.display = 'none'
    }
  }

  function convertTableToCSV() {
    try {
      let table = document.getElementById('copy_kt_table_groupchats') as any

      // Make the table briefly visible
      table.style.display = 'table'

      const rows = table.querySelectorAll('tr') as any
      let csv = []

      for (const row of rows) {
        const cells = row.querySelectorAll('td, th') as any
        const rowData = Array.from(cells).map((cell: any) => cell.textContent)
        csv.push(rowData.join(','))
      }

      // Hide the table again (if it was originally hidden)
      table.style.display = 'none'

      const csvContent = 'data:text/csv;charset=utf-8,' + csv.join('\n')
      const encodedURI = encodeURI(csvContent)
      const link = document.createElement('a')
      link.setAttribute('href', encodedURI)
      link.setAttribute('download', 'chatGroupCSV.csv')
      document.body.appendChild(link)
      link.click()
    } catch (err) {
      // Get a reference to the table
      let table = document.getElementById('copy_kt_table_groupchats') as any

      // Hide the table again (if it was originally hidden)
      table.style.display = 'none'
    }
  }
  const convertTableToPrint = () => {
    try {
      // Define the source table
      let table = document.getElementById('copy_kt_table_groupchats') as any

      // Make the table briefly visible
      table.style.display = 'table'

      html2canvas(document.querySelector('#copy_kt_table_groupchats') as any).then(function (
        canvas: any
      ) {
        let imgWidth = 210
        let pageHeight = 290
        let imgHeight = (canvas.height * imgWidth) / canvas.width
        let heightLeft = imgHeight

        let doc = new jsPDF('p', 'mm')
        let position = 0
        let pageData = canvas.toDataURL('image/jpeg', 1.0)
        let imgData = encodeURIComponent(pageData)
        doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight)
        doc.setLineWidth(5)
        doc.setDrawColor(255, 255, 255)
        doc.rect(0, 0, 210, 295)
        heightLeft -= pageHeight

        while (heightLeft >= 0) {
          position = heightLeft - imgHeight
          doc.addPage()
          doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight)
          doc.setLineWidth(5)
          doc.setDrawColor(255, 255, 255)
          doc.rect(0, 0, 210, 295)
          heightLeft -= pageHeight
        }
        doc.autoPrint()
        //This is a key for printing
        doc.output('dataurlnewwindow')
      })

      // Hide the table again (if it was originally hidden)
      table.style.display = 'none'
    } catch (err) {
      // Get a reference to the table
      let table = document.getElementById('copy_kt_table_groupchats') as any

      // Hide the table again (if it was originally hidden)
      table.style.display = 'none'
    }
  }

  function convertTableToPDF() {
    try {
      const doc = new jsPDF() as any

      // Define the source table
      let table = document.getElementById('copy_kt_table_groupchats') as any

      // Make the table briefly visible
      // table.style.display = 'table'

      // Convert the table to a data array
      const tableData = [] as any
      const rows = table.querySelectorAll('tr')
      rows.forEach(function (row: any) {
        const rowData = [] as any
        const cells = row.querySelectorAll('td, th')
        cells.forEach(function (cell: any) {
          rowData.push(cell.textContent)
        })
        tableData.push(rowData)
      })

      // Set table column widths
      const columnWidths = [180]

      // Create the PDF table
      doc.autoTable({
        head: [tableData[0]], // First row as header
        body: tableData.slice(1), // Exclude header row
        theme: 'grid',
        margin: { top: 9 },
        startY: 20,
        columnStyles: {
          0: { columnWidth: columnWidths[0] },
        },
        headStyles: {
          halign: 'center', // Center align header text
        },
        bodyStyles: {
          halign: 'center', // Center align body text
        },
      })

      // Save the PDF with a file name
      doc.save('chatGroupPdf.pdf')
    } catch (err) {
      // Get a reference to the table
      let table = document.getElementById('copy_kt_table_groupchats') as any

      // Hide the table again (if it was originally hidden)
      table.style.display = 'none'
    }
  }

  return (
    <>
      <div className='row flex-md-block '>
        <div className='col-sm-12 col-md-6 col-lg-6'>
          <h1 className='inv-hst sm-ms-2'> Chat Group Management </h1>
        </div>

        <div className='col-sm-12  col-md-6 col-lg-6 mb-5 text-end pe-5'>
          <button
            className='btn btn-primary waves-effect waves-light btn-sm me-3'
            data-bs-toggle='modal'
            data-bs-target='#addchatgroup'
          >
            {' '}
            Add Chat Group Only{' '}
          </button>
          <button
            onClick={navigateGroupManagement}
            className='btn btn-info waves-effect waves-light btn-sm'
          >
            {' '}
            Back{' '}
          </button>
        </div>
      </div>

      <div className='row mx-0 justify-content-between mb-6 '>
        <div className='card mx-3 py-8 px-5 px-sm-8 mt-5 '>
          <div className="row">
            <div className='d-flex col-md-6 ps-5 sm-ps-0 col-lg-8 ps-0 flex-wrap col-md-8 justify-content-start  mb-3 mt-6'>
              <button
                type='button'
                className='btn px-5 py-2 me-3 mb-3 btn-primary'
                onClick={() => {
                  copyTable()
                }}
              >
                Copy
              </button>
              <button
                type='button'
                className='btn px-5 py-2 me-3 mb-3 btn-success'
                onClick={() => {
                  ExportToExcel()
                }}
              >
                Excel
              </button>
              <button
                type='button'
                className='btn px-5 py-2 me-3 mb-3 btn-warning'
                onClick={() => {
                  convertTableToCSV()
                }}
              >
                CSV
              </button>
              <button
                type='button'
                className='btn px-5 py-2 me-3 mb-3 btn-danger'
                onClick={() => {
                  convertTableToPDF()
                }}
              >
                PDF
              </button>
              <button
                type='button'
                className='btn px-5 py-2 me-3 mb-3 btn-info'
                onClick={() => {
                  convertTableToPrint()
                }}
              >
                Print
              </button>
            </div>
            <div className="col-md-6 col-lg-4">
              <GroupchatsListSearchComponent />
            </div>
          </div>

          <GroupchatsListWrapper />
        </div> </div>
      <AddChatGroup />
    </>
  )
}
const ChatGroupManagementModule = () => {
  return (
    <QueryRequestProvider>
      <QueryResponseProvider>
        <ChatGroupListing />
      </QueryResponseProvider>
    </QueryRequestProvider>
  )
}

export default ChatGroupManagementModule
