import {ListViewProvider, useListView} from './core/ListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
import {GroupmeetingsListHeader} from './components/header/GroupmeetingsListHeader'
import {GroupmeetingsTable} from './table/GroupmeetingsTable'
import {GroupmeetingEditModal} from './groupmeeting-edit-modal/GroupmeetingEditModal'
import { KTCard } from '../../../../_metronic/helpers'
// import {KTCard} from '../../../../../_metronic/helpers'

const GroupmeetingsList = () => {
  const {itemIdForUpdate} = useListView()
  return (
    <>
      
        {/* <GroupmeetingsListHeader /> */}
        <GroupmeetingsTable />
      
      {itemIdForUpdate !== undefined && <GroupmeetingEditModal />}
    </>
  )
}

const GroupmeetingsListWrapper = () => (
  // <QueryRequestProvider>
  //   <QueryResponseProvider>
      <ListViewProvider>
        <GroupmeetingsList />
      </ListViewProvider>
  //   </QueryResponseProvider>
  // </QueryRequestProvider>
)

export {GroupmeetingsListWrapper}
