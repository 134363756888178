// @ts-nocheck
import {Column} from 'react-table'
import {ReminderInfoCell} from './ReminderInfoCell'
import {ReminderLastLoginCell} from './ReminderLastLoginCell'
import {ReminderTwoStepsCell} from './ReminderTwoStepsCell'
import {ReminderActionsCell} from './ReminderActionsCell'
import {ReminderSelectionCell} from './ReminderSelectionCell'
import {ReminderCustomHeader} from './ReminderCustomHeader'
import {ReminderSelectionHeader} from './ReminderSelectionHeader'
import {Reminder} from '../../core/_models'
import { checkDisplayValue } from '../../../../../../../utils'

const remindersColumns: ReadonlyArray<Column<Reminder>> = [
  // {
  //   Header: (props) => <ReminderSelectionHeader tableProps={props} />,
  //   id: 'selection',
  //   Cell: ({...props}) => <ReminderSelectionCell id={props.data[props.row.index].id} />,
  // },
 


  {
    Header: (props) => <ReminderCustomHeader tableProps={props} title='Title' className='text-start min-w-125px fs-5' />,
    accessor: 'title',
    // id: 'name',
    Cell: ({value}) => checkDisplayValue(value),
  },


  {
    Header: (props) => <ReminderCustomHeader tableProps={props} title='Notification Type' className='text-start min-w-125px fs-5' />,
    accessor: 'notification_type',
    // id: 'name',
    Cell: ({value}) => checkDisplayValue(value),
  },

  {
      Header: (props) => (
        <ReminderCustomHeader tableProps={props} title='Actions' className='text-start min-w-150px' />
      ),
      id: 'actions',
      Cell: ({...props}) => <ReminderActionsCell id={props.data[props.row.index].id} />,
    },

  
]

export {remindersColumns}
