import React, {useContext, useEffect, useRef, useState} from 'react'
import {Button, Form} from 'react-bootstrap'
import {SearchLeaderListing} from './SearchLeaderListing'
import {useFormik} from 'formik'
import {UserContext} from '../../../../../../contextStore'
import * as Yup from 'yup'
import './webcamPopup.css'
import Webcam from 'react-webcam'
import axios from 'axios'
import {apiUrl} from '../../../../../../apiurl'
import moment from 'moment'
import { sendSmsAndNotification } from '../_requests'

const API_URL = apiUrl
const IMAGE_UPLOAD = `${API_URL}/containers/topicimages/upload`
const Group_name = `${API_URL}/groups`
const Group_Members = `${API_URL}/users`
const Meeting_Points = `${API_URL}/groupmeetingpoints`
const groupMeetingReport = `${API_URL}/groupmeetingreports/addreport`

const groupMRForm = Yup.object().shape({
  comments: Yup.string().min(5, 'Minimum 5 charaters').max(500, 'Maximum 500 characters'),
  agenda: Yup.string().required('This field is required'),
  starttime: Yup.string().required('This field is required'),
  endtime: Yup.string()
    .required('This field is required')
    .test('is-greater', 'End time must be greater than start time', function (value) {
      const {starttime} = this.parent
      if (starttime && value) {
        const startTimeMoment = moment(starttime, 'HH:mm')
        const endTimeMoment = moment(value, 'HH:mm')
        return endTimeMoment.isAfter(startTimeMoment)
      }
      return true
    }),
  participants: Yup.array().required('This field is required'),
  group_id: Yup.number(),
  user_id: Yup.string(),
  meetingpoints: Yup.array().of(Yup.number().oneOf([0, 1]).required()),
  filename: Yup.array(),
})

export function GroupMeetingReport() {
  const [loading, setLoading] = useState(false)
  const {userDetail, setMessage, dashBoardReload} = useContext(UserContext)
  const userid = userDetail?.id ?? ''
  const groupid = userDetail?.group_id ?? ''
  const userName = userDetail?.name ?? ''
  const webcamRef = useRef<Webcam | null>(null)
  const [capturedImage, setCapturedImage] = useState<string | null>(null)
  const [showWebcam, setShowWebcam] = useState(false)
  const [webcamAvailable, setWebcamAvailable] = useState<boolean>(false)
  const [selectedImage, setSelectedImage] = useState('')
  const [selectedFile, setSelectedFile] = useState('')
  const [selectedFileType, setSelectedFileType] = useState<'image' | 'file'>('image')
  const [fileName, setFileName] = useState<any>('')
  const [uploading, setUploading] = useState<boolean>(false)
  const [searchLeaderListing, setsearchLeaderListing] = useState(true)
  const [groupName, setGroupName] = useState<any>('')
  const [groupMember, setGroupMember] = useState<any>([])
  const [meetingPointsName, setMeetingPointsName] = useState<any>([])
  const [meetingPoints, setMeetingPoints] = useState<number[]>(() => meetingPointsName.map(() => 0))
  const [selectedParticipants, setSelectedParticipants] = useState<string[]>([])
  const participantsAsString = selectedParticipants.map((participant) => participant.toString())
  const [arrayOfFile, setArrayOfFile] = useState<any>([])
  const [pervFileUpload, setPervFileUpload] = useState('')
  const [selectedImageShow, setSelectedImageShow] = useState<any>()
  const MAX_CHARACTERS = 500
  const [messageCount, setMessageCount] = useState(MAX_CHARACTERS)
  const [errorMessage, setErrorMessage] = useState(false)
  const closeModal = useRef<any>()
  const [selectedTime, setSelectedTime] = useState('')
  const FACING_MODE_USER = 'user'
  const FACING_MODE_ENVIRONMENT = 'environment'
  const [facingMode, setFacingMode] = React.useState(FACING_MODE_USER)
  const handleTimeChange = (e: any) => {
    setSelectedTime(e.target.value)
  }
  

  useEffect(() => {
    
    fetchGroupName()
    fetchGroupMemberName()
    fetchMeetingPointsName()
  }, [])

  useEffect(() => {
    setMeetingPoints(meetingPointsName.map(() => 0))
  }, [meetingPointsName])

  useEffect(() => {}, [meetingPoints])

  const fetchGroupName = async () => {
    try {
      const res = await axios.get(`${Group_name}/${groupid}`)
      setGroupName(res?.data?.name ?? [])
    } catch (error) {
      console.error('Error -->:', error)
    }
  }

  const fetchGroupMemberName = async () => {
    try {
      const res = await axios.get(
        `${Group_Members}/?filter[where][group_id]=${groupid}&filter[where][isactive]=1&filter[where][isdelete]=0`
      )
      setGroupMember(res?.data ?? [])
    } catch (error) {
      console.error('Error -->:', error)
    }
  }

  const fetchMeetingPointsName = async () => {
    try {
      const res = await axios.get(`${Meeting_Points}?filter[isdelete]=0`)
      setMeetingPointsName(res?.data ?? [])
    } catch (error) {
      console.error('Error -->:', error)
    }
  }

  const handleImageChange = async (event: any) => {
    const file = event.target.files[0]
    if (file) {
      // setSelectedImage(file.name)
      if (pervFileUpload == 'file') {
        setArrayOfFile([])
      }
      const imageData = URL.createObjectURL(file)
      setSelectedImageShow(imageData)
      await uploadFile(file, 'image')
    } else {
      setSelectedImage('')
    }
  }
  const checkCameraPermission = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({video: true})
      setWebcamAvailable(true)
      stream.getTracks().forEach((track) => track.stop())
    } catch (error) {
      setWebcamAvailable(false)
    }
  }

  const uploadFile = async (file: File, typeOfFile: string) => {
    try {
      if (!file) {
        console.error('No file selected')
        return
      }

      const formData = new FormData()
      formData.append('file', file)

      const response = await axios.post(`${IMAGE_UPLOAD}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      if (typeOfFile === 'file') {
        setArrayOfFile([
          {
            name: response?.data?.result?.files?.file[0]?.name ?? '',
            image: response?.data?.result?.files?.file[0]?.name ?? '',
          },
        ])
        setPervFileUpload('file')
      }
      if (typeOfFile === 'image') {
        if (pervFileUpload === 'file') {
          setArrayOfFile([
            {
              name: response?.data?.result?.files?.file[0]?.name ?? '',
              image: response?.data?.result?.files?.file[0]?.name ?? '',
            },
          ])
        } else {
          if (arrayOfFile.length === 0) {
            setArrayOfFile([
              {
                name: response?.data?.result?.files?.file[0]?.name ?? '',
                image: response?.data?.result?.files?.file[0]?.name ?? '',
              },
            ])
          } else if (
            arrayOfFile.length === 1 ||
            arrayOfFile.length === 2 ||
            arrayOfFile.length === 3
          ) {
            setArrayOfFile([
              ...arrayOfFile,
              {
                name: response?.data?.result?.files?.file[0]?.name ?? '',
                image: response?.data?.result?.files?.file[0]?.name ?? '',
              },
            ])
          } else {
            setMessage('error', 'Only 4 images are allowed')
            return
          }
        }
        setPervFileUpload('image')
      }
    } catch (error) {
      setMessage('error', 'Something Went Wrong')
    }
  }

  const uploadImage = async (imageData: any) => {
    try {
      if (!imageData) {
        console.error('No image captured')
        return
      }
      // Create an Image element to load the captured image data
      const img = new Image()
      img.src = imageData

      // Wait for the image to load
      await new Promise((resolve) => (img.onload = resolve))

      // Create a canvas element
      const canvas = document.createElement('canvas')
      canvas.width = img.width
      canvas.height = img.height

      // Get the canvas context and draw the image on the canvas
      const ctx = canvas.getContext('2d')
      if (ctx) {
        ctx.drawImage(img, 0, 0) // Ensure ctx is not null before using it
      } else {
        console.error('Canvas context is null')
        return
      }
      // Convert the canvas content to a data URL with 'image/jpeg' format and quality set to 1 (maximum quality)
      const jpgDataUrl = canvas.toDataURL('image/jpeg', 1)

      // Convert the data URL back to a Blob
      const byteCharacters = atob(jpgDataUrl.split(',')[1])
      const byteArrays = new Uint8Array(byteCharacters.length)
      for (let i = 0; i < byteCharacters.length; i++) {
        byteArrays[i] = byteCharacters.charCodeAt(i)
      }
      const jpgBlob = new Blob([byteArrays], {type: 'image/jpeg'})

      // Create a FormData object to send the image file to the API
      const formData = new FormData()
      formData.append('image', jpgBlob, 'filename.jpg')

      // Set the loading state to true while uploading
      setUploading(true)

      // Make the API call to upload the image
      const response = await axios.post(`${IMAGE_UPLOAD}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      // Muti File Upload.
      if (pervFileUpload === 'file') {
        setArrayOfFile([
          {
            name: response?.data?.result?.files?.image[0]?.name ?? '',
            image: response?.data?.result?.files?.image[0]?.name ?? '',
          },
        ])
      } else {
        if (arrayOfFile.length === 0) {
          setArrayOfFile([
            {
              name: response?.data?.result?.files?.image[0]?.name ?? '',
              image: response?.data?.result?.files?.image[0]?.name ?? '',
            },
          ])
        } else if (
          arrayOfFile.length === 1 ||
          arrayOfFile.length === 2 ||
          arrayOfFile.length === 3
        ) {
          setArrayOfFile([
            ...arrayOfFile,
            {
              name: response?.data?.result?.files?.image[0]?.name ?? '',
              image: response?.data?.result?.files?.image[0]?.name ?? '',
            },
          ])
        } else {
          setMessage('error', 'Only 4 images are allowed')
          return
        }
      }

      // setFileName(response?.data?.result?.files?.image[0]?.name ?? "")
      let {filename} = response.data

      setUploading(false)
    } catch (error) {
      setMessage('error', 'Error uploading the image')
      setUploading(false)
    }
  }

  const toggleCameraFacingMode = () => {
    setFacingMode((prevFacingMode) =>
      prevFacingMode === FACING_MODE_USER ? FACING_MODE_ENVIRONMENT : FACING_MODE_USER
    )
  }

  const toggleWebcam = async () => {
    setShowWebcam((prev) => !prev)
    if (!webcamAvailable) {
      await checkCameraPermission()
      setShowWebcam(true)
    } else {
      setShowWebcam(true)
    }
  }

  const captureImage = () => {
    if (webcamRef.current) {
      const imageSrc = webcamRef.current.getScreenshot()
      setCapturedImage(imageSrc)
      uploadImage(imageSrc)
    }
    setShowWebcam(false)
  }

  const cancelWebcam = () => {
    setShowWebcam(false)
  }

  const handleCancelButtonClick = (index: any) => {
    const updatedArray = arrayOfFile.filter((item: any, i: any) => i !== index)
    setArrayOfFile(updatedArray)
  }

  const handleParticipantSelection = (participantId: string) => {
    setSelectedParticipants((prevSelectedParticipants) => {
      if (prevSelectedParticipants.includes(participantId)) {
        return prevSelectedParticipants.filter((id) => id !== participantId)
      } else {
        return [...prevSelectedParticipants, participantId]
      }
    })
  }

  const handleMeetingPointChange = (index: number, checked: boolean) => {
    setMeetingPoints((prevMeetingPoints) => {
      const updatedMeetingPoints = [...prevMeetingPoints]
      updatedMeetingPoints[index] = checked ? 1 : 0
      return updatedMeetingPoints
    })
  }

  const initialValues = {
    group_id: '',
    agenda: '',
    meetingdate: '',
    starttime: '',
    endtime: '',
    participants: [],
    comments: '',
    filename: '',
    meetingpoints: [],
    user_id: '',
  }

  const groupMRFormFormik = useFormik({
    initialValues,
    validationSchema: groupMRForm,
    onSubmit: async (values, {setStatus, resetForm, setSubmitting}) => {
      setLoading(true)
      if (selectedParticipants.length === 0) {
        setErrorMessage(true)
        return
      }
      try {
        const postData = {
          group_id: groupid,
          agenda: values.agenda,
          meetingdate: new Date().toISOString().split('T')[0],
          starttime: values.starttime,
          endtime: values.endtime,
          participants: participantsAsString,
          comments: values.comments,
          filename: arrayOfFile[0]?.name ? arrayOfFile[0].name : '',
          meetingpoints: meetingPoints,
          user_id: userid,
        }
        await axios
          .post(`${groupMeetingReport}`, postData)
          .then((res: any) => {
            if (res?.data) {
              setMessage('success', 'Group meeting post created')
              setLoading(false)
              setCapturedImage('')
              setSelectedFile('')
              setErrorMessage(false)
              setMeetingPoints([])
              setArrayOfFile([])
              setSelectedParticipants([])
              sendSmsAndNotification({
                thankyouhistoryid: null,
                group_meeting_report_id: res?.data?.id ?? '',
                user_topic_id: res?.data?.usertopicdata?.id ?? '',
                userid: userDetail?.id ?? '',
                accesstoken: localStorage.getItem('authToken'),
              })
              resetForm()
              setMessageCount(MAX_CHARACTERS)
              dashBoardReload()
            }
          })
          .catch((error: any) => {
            if (error?.response?.data?.error?.message) {
              setMessage('error', error?.response?.data?.error?.message)
            } else {
              setMessage('error', 'Something Went Wrong')
            }
            setLoading(false)
          })
      } catch (error: any) {
        setMessage('error', error?.message ? error?.message : 'Something Went Wrong')
        setLoading(false)
      }
    },
  })

  const handleDescriptionChange = (event: any) => {
    groupMRFormFormik.setFieldValue('comments', event.target.value.trimStart())
    const currentLength = event.target.value.length
    const remainingCharacters = MAX_CHARACTERS - currentLength
    setMessageCount(remainingCharacters)
    groupMRFormFormik.handleChange(event)
  }

  return (
    <div
      className='modal fade ilead-default-modal'
      id='SubmitGroupMeetingReportModal'
      aria-hidden='true'
      aria-labelledby='SubmitGroupMeetingReportModalLabel'
    >
      <div className='modal-dialog modal-dialog modal-dialog-scrollable modal-fullscreen-md-down modal-md'>
        <div className='modal-content'>
          <div className='modal-header py-5'>
            <h3 className='modal-title fs-3 fw-bold' id='SubmitGroupMeetingReportModalToggleLabel'>
              Submit Group Meeting Report
            </h3>
            <button
              type='button'
              className='btn-close text-white'
              data-bs-target='#write_post_modal'
              data-bs-toggle='modal'
              data-bs-dismiss='modal'
              onClick={() => {
                setMessageCount(MAX_CHARACTERS)
                setCapturedImage('')
                setSelectedFile('')
                setMeetingPoints([])
                setArrayOfFile([])
                setErrorMessage(false)
                setSelectedParticipants([])
                groupMRFormFormik.resetForm()
              }}
              ref={closeModal}
            ></button>
          </div>
          <div className='modal-body pt-7 write-post-wrapper'>
            {searchLeaderListing ? (
              <>
                <form
                  className='mb-7'
                  onSubmit={(e) => {
                    e.preventDefault()
                    groupMRFormFormik.handleSubmit(e)
                  }}
                >
                  <Form.Label className='text-gray-700 mb-7'>Report Submitted For Group</Form.Label>
                  <Form.Group className='mb-4'>
                    <Form.Control
                      type='text'
                      id='chaptername'
                      className='ilead-input mb-2'
                      placeholder='Chapter'
                      value={groupName}
                    />
                  </Form.Group>
                  <Form.Group className='mb-4'>
                    <Form.Label>
                      Agenda of the meeting <span style={{color: 'red'}}> *</span>
                    </Form.Label>
                    <Form.Select
                      aria-label='meeting agenfa'
                      className='ilead-input'
                      onChange={(e) => {
                        groupMRFormFormik.setFieldValue('agenda', e.target.value)
                      }}
                      value={groupMRFormFormik.values.agenda}
                    >
                      <option>Select Option</option>
                      <option value='TYCA Discussion'>TYCA Discussion</option>
                      <option value='Goal Review'>Goal Review</option>
                      <option value='Other'>Other</option>
                    </Form.Select>
                    {groupMRFormFormik.touched.agenda && groupMRFormFormik.errors.agenda && (
                      <div className='fv-plugins-message-container text-danger'>
                        <span role='alert'>{groupMRFormFormik.errors.agenda as any}</span>
                      </div>
                    )}
                  </Form.Group>
                  <Form.Group className='mb-4'>
                    <Form.Label>
                      Meeting Start Time <span style={{color: 'red'}}> *</span>
                    </Form.Label>
                    <Form.Control
                      type='time'
                      className={selectedTime ? 'ilead-input1 time-selected1' : 'ilead-input1'}
                      placeholder='HH:MM'
                      onChange={(e) => {
                        groupMRFormFormik.setFieldValue('starttime', e.target.value)
                        handleTimeChange(e)
                      }}
                      value={groupMRFormFormik.values.starttime}
                    ></Form.Control>
                    {groupMRFormFormik.touched.starttime && groupMRFormFormik.errors.starttime && (
                      <div className='fv-plugins-message-container text-danger'>
                        <span role='alert'>{groupMRFormFormik.errors.starttime as any}</span>
                      </div>
                    )}
                  </Form.Group>
                  <Form.Group className='mb-4'>
                    <Form.Label>
                      Meeting End Time <span style={{color: 'red'}}> *</span>
                    </Form.Label>
                    <Form.Control
                      type='time'
                      className={selectedTime ? 'ilead-input1 time-selected1' : 'ilead-input1'}
                      onChange={(e) => {
                        groupMRFormFormik.setFieldValue('endtime', e.target.value)
                        handleTimeChange(e)
                      }}
                      value={groupMRFormFormik.values.endtime}
                    ></Form.Control>
                    {groupMRFormFormik.touched.endtime && groupMRFormFormik.errors.endtime && (
                      <div className='fv-plugins-message-container text-danger'>
                        <span role='alert'>{groupMRFormFormik.errors.endtime as any}</span>
                      </div>
                    )}
                  </Form.Group>
                  <Form.Group className='mb-7 d-flex flex-column'>
                    <Form.Label className='mb-5'>
                      Participant <span style={{color: 'red'}}> *</span>
                    </Form.Label>
                    {groupMember.map((item: any) => (
                      <Form.Check
                        key={item.id}
                        reverse
                        type='checkbox'
                        name='participantleader'
                        id={`participant-${item.id}`}
                        label={item?.name}
                        // value={item.id ?? ''}
                        className='w-100 text-start mb-5'
                        onChange={() => handleParticipantSelection(item.id)}
                        checked={selectedParticipants.includes(item.id)}
                      />
                    ))}
                    {errorMessage && selectedParticipants.length === 0 ? (
                      <div className='fv-plugins-message-container text-danger'>
                        <span role='alert'>This field is required</span>
                      </div>
                    ) : (
                      ''
                    )}
                  </Form.Group>
                  <Form.Group className='mb-4 mt-7'>
                    <Form.Label>What went well / What can be improved</Form.Label>
                    {meetingPointsName.map((item: any, index: number) => (
                      <div key={index} className='whatwentwellbox p-4 w-100 mt-4'>
                        <div className='bt-switch d-flex justify-content-between align-items-center'>
                          <Form.Label className='w-100 text-black pe-5'>{item?.title}</Form.Label>
                          <Form.Check
                            reverse
                            type='switch'
                            id={`meeting-point-${index}`}
                            label='Went well?'
                            className='d-flex flex-column flex-column-reverse pe-0 text-nowrap'
                            onChange={(e) => handleMeetingPointChange(index, e.target.checked)}
                            checked={meetingPoints[index] === 1}
                          />
                        </div>
                      </div>
                    ))}
                  </Form.Group>
                  <Form.Group className='mb-4' controlId='anycomments'>
                    <Form.Label>Any Comments:</Form.Label>
                    <Form.Control
                      as='textarea'
                      rows={3}
                      maxLength={MAX_CHARACTERS}
                      onChange={handleDescriptionChange}
                      value={groupMRFormFormik.values.comments}
                    />
                    <p className='text-gray-500 fs-13 mt-2'>Available Characters {messageCount} </p>
                    {groupMRFormFormik.touched.comments && groupMRFormFormik.errors.comments && (
                      <div className='fv-plugins-message-container text-danger'>
                        <span role='alert'>{groupMRFormFormik.errors.comments as any}</span>
                      </div>
                    )}
                  </Form.Group>
                  <Form.Group className='mb-7 mt-7'>
                    <h5 className='mt-7 mb-7'>Add Attachment</h5>
                    <Button
                      variant='primary'
                      className='me-3 px-4 py-3'
                      onClick={() => toggleWebcam()}
                    >
                      CAMERA
                    </Button>
                    {showWebcam && webcamAvailable ? (
                      <div className='webcam-popup'>
                        <div className='webcam-popup-content'>
                          <Webcam
                            audio={false}
                            ref={webcamRef}
                            videoConstraints={{
                              facingMode: facingMode,
                            }}
                          />
                         <button
    type="button"
    className="btn-close text-white cancel-button"
    onClick={cancelWebcam}
  ></button>
                        <div className='webcam-buttons'>
                          <Button
                            variant='primary'
                            className='me-3 px-4 py-3'
                            onClick={captureImage}
                          >
                            <i className='bi bi-camera-fill pe-0' style={{ fontSize: '20px' }}></i>
                          </Button>
                         
                          <Button
                            variant="primary"
                            className="me-3 px-4 py-3 toggle-button"
                            onClick={toggleCameraFacingMode}
                            style={{borderRadius:'30px'}}
                          >
                            <i className="bi bi-arrow-repeat pe-0" style={{ fontSize: '20px' }}></i>
                          </Button>
                        </div>
                        </div>
                      </div>
                    ) : (
                      ''
                    )}
                    <div className='me-3 px-4 py-3 position-relative btn btn-primary'>
                      <Form.Control
                        type='file'
                        accept='image/jpeg, image/png'
                        onChange={handleImageChange}
                        className='position-absolute w-100 h-100 start-0 top-0 opacity-0'
                      ></Form.Control>
                      GALLERY
                    </div>
                  </Form.Group>
                  <div className='row'>
                    {arrayOfFile.map((item: any, i: any) => {
                      let itemLast = item.image.split('.').pop()

                      if (
                        itemLast == 'jpeg' ||
                        itemLast == 'jpeg' ||
                        itemLast == 'png' ||
                        itemLast == 'jpg'
                      ) {
                        return (
                          <div className='col mx-2'>
                            <div
                              className='white-close-image'
                              style={{position: 'relative', width: '130px'}}
                            >
                              <img
                                src={`${API_URL}/containers/topicimages/download/${item.image}`}
                                alt='Captured'
                                style={{width: 130, height: 90}}
                              />
                              <button
                                type='button'
                                className='btn-close text-white fs-4'
                                style={{
                                  backgroundColor: 'red',
                                  position: 'absolute',
                                  right: '5px',
                                  top: '5px',
                                }}
                                onClick={() => handleCancelButtonClick(i)}
                              />
                            </div>
                          </div>
                        )
                      }
                    })}
                  </div>
                  <Form.Group className='mb-5'>
                    <div className='name-wrapper'>
                      <Form.Label className='name-title fs-14'>Submitted By:</Form.Label>
                      <h2 className='fw-semibold mb-3'>{userName}</h2>
                    </div>
                  </Form.Group>
                  <div className='note-description mt-7 fs-6 text-gray-700'>
                    NOTE : Points will be counted for Sunday to Saturday - 2nd and 4th Week of the
                    month only.
                  </div>
                  <Form.Group className='mt-5 d-flex'>
                    <Button
                      variant='primary'
                      type='submit'
                      data-bs-toggle={groupMRFormFormik.isValid ? 'modal' : undefined}
                      data-bs-dismiss={groupMRFormFormik.isValid ? 'modal' : undefined}
                      disabled={groupMRFormFormik.isSubmitting || selectedParticipants.length === 0 || !groupMRFormFormik.values.agenda || !groupMRFormFormik.values.endtime || !groupMRFormFormik.values.starttime} 
                      // onClick={() => {
                      //   if (selectedParticipants.length === 0) {
                      //     setErrorMessage(true)
                      //   }
                      // }}
                      className='w-100'
                    >
                      Submit
                    </Button>
                  </Form.Group>
                </form>
              </>
            ) : (
              <SearchLeaderListing onClick={setsearchLeaderListing}></SearchLeaderListing>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
