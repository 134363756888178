import axios, {AxiosResponse} from 'axios'
// import {ID, Response} from '../../../../../../_metronic/helpers'
import {Groupmanagement, GroupmanagementsQueryResponse} from './_models'
import {ID, Response} from '../../../../../_metronic/helpers'
import {apiUrl} from '../../../../../apiurl'
const API_URL = apiUrl
// const API_URL = process.env.REACT_APP_THEME_API_URL
const GROUPMANAGEMENT_URL = `${API_URL}/groups/cometChatGroupDelete`
const GET_GROUPMANAGEMENTS_URL = `${API_URL}/groups/getGroupListing`
const ADD_NEW_GROUP = `${API_URL}/groups/createGroup`
const getAllChaptersURL = `${API_URL}/chapters?filter[where][isdelete]=0`
const GET_GROUP_DATA = `${API_URL}/groups/groupFindOne`
const UPDATE_GROUP = `${API_URL}/groups/updateGroup`

const getGroupmanagements = (query: string): Promise<any> => {
  return axios
    .get(`${GET_GROUPMANAGEMENTS_URL}?${query}&isdelete=0`)
    .then((d: any) => d.data)
    .catch((e) => console.log(e))
}

const addNewGroup = (body: any) => {
  return axios
    .post<any>(`${ADD_NEW_GROUP}`, body)
    .then((responce: any) => {
      return responce
    })
    .catch((error) => {
      console.error('Error :', error)
      throw error
    })
}
const getChapterList = () => {
  return axios.get<any>(getAllChaptersURL).then((res: any) => {
    return res.data
  })
}

const getGroupmanagementById = (id: ID): Promise<Groupmanagement | undefined> => {
  return (
    axios
      .get(`${GROUPMANAGEMENT_URL}/${id}`)
      // .then((response: AxiosResponse<Response<Groupmanagement>>) => response.data).catch((e) => console.log(e))
      .then((response: Response<any>) => response.data)
      .catch((e) => console.log(e))
  )
}
// update api
const updateGroup = (id: any, body: any) => {
  return axios
    .patch<any>(`${UPDATE_GROUP}/${id}`, body)
    .then((responce: any) => {
      return responce
    })
    .catch((error) => {
      console.error('Error :', error)
      throw error
    })
}

const createGroupmanagement = (
  groupmanagement: Groupmanagement
): Promise<Groupmanagement | undefined> => {
  return (
    axios
      .put(GROUPMANAGEMENT_URL, groupmanagement)
      // .then((response: AxiosResponse<Response<Groupmanagement>>) => response.data).catch((e) => console.log(e))
      .then((response: Response<any>) => response.data)
      .catch((e) => console.log(e))
  )
}

const updateGroupmanagement = (
  groupmanagement: Groupmanagement
): Promise<Groupmanagement | undefined> => {
  return (
    axios
      .post(`${GROUPMANAGEMENT_URL}/${groupmanagement.id}`, groupmanagement)
      // .then((response: AxiosResponse<Response<Groupmanagement>>) => response.data).catch((e) => console.log(e))
      .then((response: Response<any>) => response.data)
      .catch((e) => console.log(e))
  )
}

//groupFindOne

const getGroupData = (id: any) => {
  return axios.get<any>(`${GET_GROUP_DATA}/${id}`).then((res: any) => {
    return res
  })
}

const deleteGroupmanagement = (id: any): Promise<void> => {
  return axios.delete(`${GROUPMANAGEMENT_URL}/${id}`).then((res: any) => {
    return res.data
  })
}

const deleteSelectedGroupmanagements = (groupmanagementIds: Array<ID>): Promise<void> => {
  const requests = groupmanagementIds.map((id) => axios.delete(`${GROUPMANAGEMENT_URL}/${id}`))
  return axios.all(requests).then(() => {})
}

export {
  getGroupmanagements,
  deleteGroupmanagement,
  deleteSelectedGroupmanagements,
  getGroupmanagementById,
  createGroupmanagement,
  updateGroupmanagement,
  addNewGroup,
  getChapterList,
  getGroupData,
  updateGroup,
}
