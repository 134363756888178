import axios, {AxiosResponse} from 'axios'
// import {ID, Response} from '../../../../../../_metronic/helpers'
import {Sessionattendance, SessionattendancesQueryResponse} from './_models'
import { ID, Response } from '../../../../../_metronic/helpers'
import { apiUrl } from '../../../../../apiurl'

const API_URL = apiUrl
const SESSIONATTENDANCE_URL = `${apiUrl}/sessionattendances/list`
const GET_SESSIONATTENDANCES_URL = `${API_URL}/users/query`
const GET_CHAPTER_LIST_URL = `${API_URL}/chapters?filter[where][isdelete]=0`
const CHANGE_SESSIONATTENDANCE_URL = `${apiUrl}/sessionattendances/changeAttendanceStatus`

const getSessionattendances = (query: any): Promise<any> => {
  return axios
    .get(`${SESSIONATTENDANCE_URL}?${query}`)
    .then((d: AxiosResponse<any>) => d.data)
}

const getChaptersList = (): Promise<any> => {
  return axios
    .get(`${GET_CHAPTER_LIST_URL}`)
    .then((d: AxiosResponse<any>) => d.data)
}

const getSessionattendanceById = (id: ID): Promise<Sessionattendance | undefined> => {
  return axios
    .get(`${SESSIONATTENDANCE_URL}/${id}`)
    // .then((response: AxiosResponse<Response<Sessionattendance>>) => response.data).catch((e) => console.log(e))
    .then((response: Response<any>) => response.data).catch((e) => console.log(e))
}

const createSessionattendance = (sessionattendance: Sessionattendance): Promise<Sessionattendance | undefined> => {
  return axios
    .put(SESSIONATTENDANCE_URL, sessionattendance)
    // .then((response: AxiosResponse<Response<Sessionattendance>>) => response.data).catch((e) => console.log(e))
    .then((response: Response<any>) => response.data).catch((e) => console.log(e))
}

const updateSessionattendance = (sessionattendance: Sessionattendance): Promise<Sessionattendance | undefined> => {
  return axios
    .post(`${SESSIONATTENDANCE_URL}/${sessionattendance.id}`, sessionattendance)
    // .then((response: AxiosResponse<Response<Sessionattendance>>) => response.data).catch((e) => console.log(e))
    .then((response: Response<any>) => response.data).catch((e) => console.log(e))
}

const deleteSessionattendance = (sessionattendanceId: ID): Promise<void> => {
  return axios.delete(`${SESSIONATTENDANCE_URL}/${sessionattendanceId}`).then(() => {})
}

const deleteSelectedSessionattendances = (sessionattendanceIds: Array<ID>): Promise<void> => {
  const requests = sessionattendanceIds.map((id) => axios.delete(`${SESSIONATTENDANCE_URL}/${id}`))
  return axios.all(requests).then(() => {})
}

const changeSessionAttendanceStatus = (id:any,status:any): Promise<any> => {
  return axios
    .get(`${CHANGE_SESSIONATTENDANCE_URL}?id=${id}&status=${status}`)
    .then((d: AxiosResponse<any>) => d.data)
}

export {getSessionattendances, deleteSessionattendance, 
  deleteSelectedSessionattendances, getSessionattendanceById, 
  createSessionattendance, updateSessionattendance, getChaptersList,
  changeSessionAttendanceStatus
}
