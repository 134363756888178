import axios, { AxiosResponse } from 'axios'
import {apiUrl,redirectAdminURL, zoomApiUrl} from '../../../../../apiurl'

const API_URL = apiUrl
const ZOOM_API_URL = zoomApiUrl

export const GET_CHAPTER_LEADER = `${API_URL}/usertopics/chapters`
export const groupMeetingReport = `${API_URL}/groupmeetingreports/addreport`

export const GET_ALL_GOALS = `${API_URL}/managegoals`
export const GET_ALL_CHAPTER = `${API_URL}/chapters`
export const GET_ALL_LEADER = `${API_URL}/users`
export const ADD_USER_TOPIC = `${API_URL}/usertopics`
export const ADD_THANKYOU_HISTORY = `${API_URL}/thankyouhistories`
export const CREATE_MR_TRACKING = `${API_URL}/mrtrackings`
export const EVENT_DATE_LISTING = `${API_URL}/eventdates`
export const ADD_INVITATION_HISTORIES = `${API_URL}/invitionhistories/saveInvition`
export const SEND_SMS_AND_NOTIFICATION = `${API_URL}/usertopics/sendsmsandnotficationapi`
const CREATE_EVENT_POST = `${API_URL}/usertopics?filter[where][isdelete]=0` 
const CREATE_ZOOM_MEETING = `${ZOOM_API_URL}/createZoomMeeting` 

export function createAnnoucement(data: any) {
  return axios.post<any>(GET_CHAPTER_LEADER, data).then((responce: any) => {
    return responce.data
  })
}
//make goal api
// create api
export function createGoals(data: any) {
  return axios
    .post<any>(GET_ALL_GOALS, data)
    .then((responce: any) => {
      return responce.data
    })
    .catch((error) => {
      console.error('Error All Post Data:', error)
      throw error
    })
}
// edit api
export function editGoals(id: any, data: any) {
  return axios
    .patch<any>(`${GET_ALL_GOALS}/${id}`, data)
    .then((responce: any) => {
      return responce.data
    })
    .catch((error) => {
      console.error('Error All Post Data:', error)
      throw error
    })
}
// find one
export function findOneGoals(id: any) {
  return axios
    .get<any>(`${GET_ALL_GOALS}/${id}`)
    .then((responce: any) => {
      return responce.data
    })
    .catch((error) => {
      console.error('Error FindOne Data:', error)
      throw error
    })
}
// listing api
// submit MR
export function listingForManageGoal(userId: any) {
  return axios
    .get<any>(`${GET_ALL_GOALS}?filter[include]=user&filter[where][user_id]=${userId}`)
    .then((responce: any) => {
      return responce.data
    })
    .catch((error) => {
      console.error('Error FindOne Data:', error)
      throw error
    })
}


// delete api
export function deleteGoals(id: any) {
  return axios
    .delete<any>(`${GET_ALL_GOALS}/${id}`)
    .then((responce: any) => {
      return responce.data
    })
    .catch((error) => {
      console.error('Error All Post Data:', error)
      throw error
    })
}

// business referral api integration

export function listingAllChapter() {
  return axios
    .get<any>(`${GET_ALL_CHAPTER}?filter[where][isdelete]=0`)
    .then((responce: any) => {
      return responce.data
    })
    .catch((error) => {
      console.error('Error All Post Data:', error)
      throw error
    })
}

export function findOneChapterData(chapter_id :any) {
  return axios
    .get<any>(`${GET_ALL_CHAPTER}/${chapter_id}?filter[where][isdelete]=0`)
    .then((responce: any) => {
      return responce.data
    })
    .catch((error) => {
      console.error('Error All Post Data:', error)
      throw error
    })
}
export function listingAllLeaderUser(chapterId: any, leaderName: any, userID:any) {
  if (leaderName.length == 0) {
    return axios
      .get<any>(`${GET_ALL_LEADER}?filter[where][chapter_id]=${chapterId}&filter[where][id][neq]=${userID}&filter[where][isdelete]=0&filter[where][isactive]=1&filter[order]=name%20ASC`)
      .then((responce: any) => {
        return responce.data
      })
      .catch((error) => {
        console.error('Error All Post Data:', error)
        throw error
      })
  } else {
    return axios
      .get<any>(
        `${GET_ALL_LEADER}?filter={%22where%22:{${
          chapterId !== '' ? `%22chapter_id%22:${chapterId},` : ''
        }%22id%22:{%22neq%22:${userID}},%22name%22:{%20%22like%22:%22%25${leaderName}%25%22},%22isdelete%22:0,%22isactive%22:1}}`
      )
      .then((responce: any) => {
        return responce.data
      })
      .catch((error) => {
        console.error('Error All Post Data:', error)
        throw error
      })
   }
}

export function singleLeaderUser(id: any) {
  return axios
    .get<any>(`${GET_ALL_LEADER}/${id}`)
    .then((responce: any) => {
      return responce.data
    })
    .catch((error) => {
      console.error('Error All Post Data:', error)
      throw error
    })
}

// say thank you || business referral || business connection
export function addUserTopic(data: any) {
  return axios
    .post<any>(ADD_USER_TOPIC, data)
    .then((responce: any) => {
      return responce.data
    })
    .catch((error) => {
      console.error('Error user topic Data:', error)
      throw error
    })
}

export function addThankyouHistory(data: any) {
  return axios
    .post<any>(ADD_THANKYOU_HISTORY, data)
    .then((responce: any) => {
      return responce.data
    })
    .catch((error) => {
      console.error('Error thank you history Data:', error)
      throw error
    })
}

export function listingForAllUser(id:any, leaderName:any) {
  if (leaderName.length == 0) {
  return axios
    .get<any>(`${GET_ALL_LEADER}?filter[where][chapter_id]=6?filter[where][chapter_id]=4&filter[where][id][neq]=${id}&filter[where][isdelete]=0&filter[where][isactive]=1&filter[order]=name ASC`)
    .then((responce: any) => {
      return responce.data
    })
    .catch((error) => {
      console.error('Error All Post Data:', error)
      throw error
    })}
    else{
      return axios
      .get<any>(
        `${GET_ALL_LEADER}?filter={%22where%22:{%22chapter_id%22:6,%22id%22:{%22neq%22:${id}},%22name%22:{%20%22like%22:%22%25${leaderName}%25%22},%22isdelete%22:0,%22isactive%22:1}}`
      )
      .then((responce: any) => {
        return responce.data
      })
      .catch((error) => {
        console.error('Error All Post Data:', error)
        throw error
      })
    }
}

export function createMrTracking(data: any) {
  return axios
    .post<any>(CREATE_MR_TRACKING, data)
    .then((responce: any) => {
      return responce.data
    })
    .catch((error) => {
      console.error('Error All Post Data:', error)
      throw error
    })
}

//invite visitor

export function listingForChapter() {
  return axios
    .get<any>(`${GET_ALL_CHAPTER}?filter[where][isdelete]=0&filter[where][show_in_visitor_invitation]=1`)
    .then((responce: any) => {
      return responce.data
    })
    .catch((error) => {
      console.error('Error All Post Data:', error)
      throw error
    })
}
// EVENT_DATE_LISTING
export function listingForEventDate(chapterId:any, eventDate:any) {
  return axios
    .get<any>(`${EVENT_DATE_LISTING}?filter[where][chapter_id]=${chapterId}&filter[where][event_date][gte]=${eventDate}&filter[order]=event_date&filter[limit]=4`)
    .then((responce: any) => {
      return responce.data
    })
    .catch((error) => {
      console.error('Error All Post Data:', error)
      throw error
    })
}

// invitionhistories/saveInvition
export function addInvitationHistories(data: any) {
  return axios
    .post<any>(ADD_INVITATION_HISTORIES, data)
    .then((responce: any) => {
      return responce.data
    })
    .catch((error) => {
      console.error('Error thank you history Data:', error)
      throw error
    })
}

export function sendSmsAndNotification(data: any) {
  return axios
    .post<any>(SEND_SMS_AND_NOTIFICATION, data)
    .then((responce: any) => {
      return responce.data
    })
    .catch((error) => {
      console.error('Error thank you history Data:', error)
      throw error
    })
}

export const submitGroupMeetingReport = (data: any): Promise<any> => {
  return axios
    .post(groupMeetingReport, data)
    .then((response: AxiosResponse<any>) => response)
}

export function createEventPostApi(data: any) {
  return axios.post<any>(CREATE_EVENT_POST, data).then((responce: any) => {
    return responce.data
  })
}

export function createZoomMeetingApi(data: any) {
  return axios.post<any>(CREATE_ZOOM_MEETING, data).then((responce: any) => {
    return responce.data
  })
}
