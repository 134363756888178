/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useContext, useEffect, useRef, useState } from 'react'
import { useMutation, useQueryClient } from 'react-query'
// import {MenuComponent} from '../../../../../../../_metronic/assets/ts/components'
// import {ID, KTIcon, QUERIES} from '../../../../../../../_metronic/helpers'
import { useListView } from '../../core/ListViewProvider'
import { useQueryResponse } from '../../core/QueryResponseProvider'
import { deleteGroupmanagement } from '../../core/_requests'
import { MenuComponent } from '../../../../../../_metronic/assets/ts/components'
import { ID, KTIcon, QUERIES } from '../../../../../../_metronic/helpers'
import { Link, useNavigate } from 'react-router-dom'
import AddEditNewGroup from '../../../AddEditNewGroup'
import EditGroup from '../../../EditGroup'
import { UserContext } from '../../../../../../contextStore'
// import AddGroupmanagements from '../../../AddGroupmanagement'
// import EditGroupmanagements from '../../../EditGroupmanagement'


type Props = {
  id: any
}

const GroupmanagementActionsCell: FC<Props> = ({ id }) => {
  const { setItemIdForUpdate } = useListView()
  const { query } = useQueryResponse()
  const queryClient = useQueryClient()
  const [loading, setLoading] = useState<any>(false)
  const [idGet, setId] = useState<any>('')
  const closeButton = useRef<any>()
  const { setMessage } = useContext(UserContext)
  const [show, setShow] = useState(false)
  const handleClose = () => {
    setShow(false)
    setId('')
  }
  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const openEditModal = () => {
    setItemIdForUpdate(id)
  }

  const deleteItem = useMutation(() => deleteGroupmanagement(id), {
    // 💡 response of the mutation is passed to onSuccess
    onSuccess: () => {
      // ✅ update detail view directly
      queryClient.invalidateQueries([`${QUERIES.GROUPMANAGEMENTS_LIST}-${query}`])
    },
  })


  const deleteGroup = async () => {
    try {
      setLoading(true);
      const responseData = await deleteGroupmanagement(id);
      setLoading(false);
      queryClient.invalidateQueries([`${QUERIES.GROUPMANAGEMENTS_LIST}-${query}`])
      setMessage('success', "Delete group successful")
      closeButton.current.click();

    } catch (error) {
      setLoading(false);
      console.error("error", error);
    }
  };


  return (
    <>
      {/* <a
        href='#'
        className='btn btn-light-success rounded-circle py-2 px-3 btn-sm me-2'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
        style={{lineHeight: '11px !important'}}
        onClick={navigateViewGroupmanagement}
      >
        <i className="fa-regular fa-eye pe-0 fs-6"></i>
      </a> */}

      <Link
        to=''
        className='btn btn-light-warning rounded-circle py-2 px-3 btn-sm me-2'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
        style={{ lineHeight: '11px !important' }}
        data-bs-toggle="modal" data-bs-target={`#editgroup_${idGet}`}
        onClick={() => {
          setId(id)
          setShow(true)
        }}
      >
        <i className="fa-regular fa-pen-to-square pe-0 fs-6"></i>
      </Link>
      <AddEditNewGroup show={show} handleClose={handleClose} idGet={idGet} setId={setId} />
      <a
        href='#'
        className='btn btn-light-danger rounded-circle py-2 px-3 btn-sm'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
        style={{ lineHeight: '11px !important' }}
        data-bs-toggle="modal" data-bs-target={`#deletModal_${id}`}
      >
        <i className="fa-solid fa-trash-can pe-0 fs-6"></i>
      </a>



      <div className="modal fade" id={`deletModal_${id}`} tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">Delete Group</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" ref={closeButton}></button>
            </div>
            <div className="modal-body pb-0">
              <div className='col-12 text-center pt-5 pb-5'>
                <i className="fa-solid fa-trash-can text-danger mb-10" style={{ fontSize: '80px' }}></i>
                <h3 > Are you sure you want to delete this Group? </h3>
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-primary" disabled={loading} onClick={() => {
                deleteGroup()
              }}> {loading ? 'Please wait' : 'yes'} </button>
              <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={() => {
                closeButton.current.click();
              }}> No </button>
            </div>
          </div>
        </div>
      </div>


    </>

  )
}

export { GroupmanagementActionsCell }
