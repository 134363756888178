import { useMemo } from 'react'
import { useTable, ColumnInstance, Row } from 'react-table'
import { CustomHeaderColumn } from './columns/CustomHeaderColumn'
import { CustomRow } from './columns/CustomRow'
import { useQueryResponseData, useQueryResponseLoading } from '../core/QueryResponseProvider'
import { groupchatsColumns } from './columns/_columns'
import { Groupchat } from '../core/_models'
import { GroupchatsListLoading } from '../components/loading/GroupchatsListLoading'
import { GroupchatsListPagination } from '../components/pagination/GroupchatsListPagination'
import { KTCardBody, defaultGroupchats } from '../../../../../../_metronic/helpers'
import { checkDisplayValue } from '../../../../../../utils'
// import { KTCardBody, defaultGroupchats } from '../../../../../_metronic/helpers'
// import {KTCardBody} from '../../../../../../_metronic/helpers'

const GroupchatsTable = () => {
  const groupchats = useQueryResponseData()
  // const groupchats = defaultGroupchats
  const isLoading = useQueryResponseLoading()
  const data = useMemo(() => groupchats, [groupchats])
  const columns = useMemo(() => groupchatsColumns, [])
  const { getTableProps, getTableBodyProps, headers, rows, prepareRow } = useTable({
    columns,
    data,
  })

  return (
    <KTCardBody className='p-0'>
      <div className='table-responsive mb-8 data-table-report'>
        <table
          id='kt_table_groupchats'
          className='table align-middle table-row-dashed fs-6 gy-5 dataTable table-striped  no-footer'
          {...getTableProps()}
        >
          <thead>
            <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0 border-bottom border-gray-100'>
              {headers.map((column: ColumnInstance<Groupchat>) => (
                <CustomHeaderColumn key={column.id} column={column} />
              ))}
            </tr>
          </thead>

          <tbody className='text-gray-600 text-start fw-bold' {...getTableBodyProps()}>
            {rows.length > 0 ? (
              rows.map((row: Row<Groupchat>, i) => {
                prepareRow(row)
                return <CustomRow row={row} key={`row-${i}-${row.id}`} />
              })
            ) : (
              <tr>
                <td colSpan={7}>
                  <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                    No matching records found
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>

        <table
          id='copy_kt_table_groupchats'
          className='table align-middle table-row-dashed fs-6 gy-5 dataTable table-striped  no-footer'
          {...getTableProps()}
          style={{ display: "none" }}
        >
          <thead>
            <tr className='text-center text-muted fw-bolder fs-7 text-uppercase gs-0 border-bottom border-gray-100'>
              <td>GROUP NAME</td>
            </tr>
          </thead>

          <tbody className='text-gray-600 text-center fw-bold' {...getTableBodyProps()}>
            {rows.length > 0 ? (
              rows.map((row: Row<Groupchat>, i) => {
                prepareRow(row)
                return <tr key={i}>
                  <td>{checkDisplayValue(row?.values?.name)}</td>

                </tr>
              })
            ) : (
              <tr>
                <td colSpan={7}>
                  <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                    No matching records found
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>


      </div>
      <GroupchatsListPagination />
      {isLoading && <GroupchatsListLoading />}
    </KTCardBody>
  )
}

export { GroupchatsTable }
