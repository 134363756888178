import React, { useContext, useRef, useState } from 'react'
import { Button, Form } from 'react-bootstrap'
import * as Yup from 'yup'
import { UserContext } from '../../../contextStore'
import { useFormik } from 'formik'
import TextareaAutosize from 'react-textarea-autosize'
import { giveFeedback } from '../feedback/core/_request'
import Webcam from 'react-webcam'
import axios from 'axios'
import { apiUrl } from '../../../apiurl'
import './webcamPopup.css'
import { toAbsoluteUrl } from '../../../_metronic/helpers'

const API_URL = apiUrl
const IMAGE_UPLOAD = `${API_URL}/containers/supportfeedback/upload`

const feedBackForm = Yup.object().shape({
  description: Yup.string()
    .min(5, 'Minimum 5 charaters')
    .max(1000, 'Maximum 1000 characters')
    .required('Description is required'),
})

const FeedbackQuery: React.FC = () => {
  const [loading, setLoading] = useState(false)
  const { userDetail, setMessage } = useContext(UserContext)
  const userid = userDetail?.id ?? ''
  const MAX_CHARACTERS = 1000
  const webcamRef = useRef<Webcam | null>(null)
  const [messageCount, setMessageCount] = useState(MAX_CHARACTERS)
  const [capturedImage, setCapturedImage] = useState<string | null>(null)
  const [showWebcam, setShowWebcam] = useState(false)
  const [webcamAvailable, setWebcamAvailable] = useState<boolean>(false)
  const [selectedFile, setSelectedFile] = useState('')
  const [selectedFileType, setSelectedFileType] = useState<'image' | 'file'>('image')
  const [fileName, setFileName] = useState<any>('')
  const [selectedImage, setSelectedImage] = useState('')
  const [fileType, setFileType] = useState<any>()
  const [fileDataShow, setFileDataShow] = useState<any>()
  const [arrayOfFile, setArrayOfFile] = useState<any>([])
  const [pervFileUpload, setPervFileUpload] = useState('')
  const [uploading, setUploading] = useState<boolean>(false)
  const [selectedImageShow, setSelectedImageShow] = useState<any>()
  const FACING_MODE_USER = 'user'
  const FACING_MODE_ENVIRONMENT = 'environment'
  const [facingMode, setFacingMode] = React.useState(FACING_MODE_USER)

  const handleFileChange = async (event: any) => {
    const file = event.target.files[0]
    if (file) {
      setFileType(file.type)
      await uploadFile(file, 'file')
      const fileData = URL.createObjectURL(file)
      setFileDataShow(fileData)
    } else {
      setSelectedFile('')
    }
  }

  const handleImageChange = async (event: any) => {
    const file = event.target.files[0]
    if (file) {
      if (pervFileUpload == 'file') {
        setArrayOfFile([])
      }
      const imageData = URL.createObjectURL(file)
      setSelectedImageShow(imageData)
      await uploadFile(file, 'image')
      setFileType([])
      setFileDataShow('')
    } else {
      setSelectedImage('')
    }
  }

  const checkCameraPermission = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true })
      setWebcamAvailable(true)
      stream.getTracks().forEach((track) => track.stop())
    } catch (error) {
      setWebcamAvailable(false)
    }
  }

  const handleCancelButtonClick = (index: any) => {
    const updatedArray = arrayOfFile.filter((item: any, i: any) => i !== index)
    setArrayOfFile(updatedArray)
  }

  const handleDescriptionChange = (event: any) => {
    feedBackFormFormik.setFieldValue('description', event.target.value.trimStart())
    const currentLength = event.target.value.length
    const remainingCharacters = MAX_CHARACTERS - currentLength
    setMessageCount(remainingCharacters)
    feedBackFormFormik.handleChange(event)
  }

  const uploadFile = async (file: File, typeOfFile: string) => {
    try {
      if (!file) {
        console.error('No file selected')
        return
      }

      const formData = new FormData()
      formData.append('file', file)

      const response = await axios.post(`${IMAGE_UPLOAD}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      if (typeOfFile === 'file') {
        setArrayOfFile([
          {
            name: response?.data?.result?.files?.file[0]?.name ?? '',
            image: response?.data?.result?.files?.file[0]?.name ?? '',
          },
        ])
        setPervFileUpload('file')
      }
      if (typeOfFile === 'image') {
        if (pervFileUpload === 'file') {
          setArrayOfFile([
            {
              name: response?.data?.result?.files?.file[0]?.name ?? '',
              image: response?.data?.result?.files?.file[0]?.name ?? '',
            },
          ])
        } else {
          if (arrayOfFile.length === 0) {
            setArrayOfFile([
              {
                name: response?.data?.result?.files?.file[0]?.name ?? '',
                image: response?.data?.result?.files?.file[0]?.name ?? '',
              },
            ])
          } else if (
            arrayOfFile.length === 1 ||
            arrayOfFile.length === 2 ||
            arrayOfFile.length === 3
          ) {
            setArrayOfFile([
              ...arrayOfFile,
              {
                name: response?.data?.result?.files?.file[0]?.name ?? '',
                image: response?.data?.result?.files?.file[0]?.name ?? '',
              },
            ])
          } else {
            setMessage('error', 'Only 4 images is allowed')
            return
          }
        }
        setPervFileUpload('image')
      }
    } catch (error) {
      setMessage('error', 'Something Went Wrong')
    }
  }

  const uploadImage = async (imageData: any) => {
    try {
      if (!imageData) {
        console.error('No image captured')
        return
      }
      // Create an Image element to load the captured image data
      const img = new Image()
      img.src = imageData

      // Wait for the image to load
      await new Promise((resolve) => (img.onload = resolve))

      // Create a canvas element
      const canvas = document.createElement('canvas')
      canvas.width = img.width
      canvas.height = img.height

      // Get the canvas context and draw the image on the canvas
      const ctx = canvas.getContext('2d')
      if (ctx) {
        ctx.drawImage(img, 0, 0) // Ensure ctx is not null before using it
      } else {
        console.error('Canvas context is null')
        return
      }
      // Convert the canvas content to a data URL with 'image/jpeg' format and quality set to 1 (maximum quality)
      const jpgDataUrl = canvas.toDataURL('image/jpeg', 1)

      // Convert the data URL back to a Blob
      const byteCharacters = atob(jpgDataUrl.split(',')[1])
      const byteArrays = new Uint8Array(byteCharacters.length)
      for (let i = 0; i < byteCharacters.length; i++) {
        byteArrays[i] = byteCharacters.charCodeAt(i)
      }
      const jpgBlob = new Blob([byteArrays], { type: 'image/jpeg' })

      // Create a FormData object to send the image file to the API
      const formData = new FormData()
      formData.append('image', jpgBlob, 'filename.jpg')

      // Set the loading state to true while uploading
      setUploading(true)

      // Make the API call to upload the image
      const response = await axios.post(`${IMAGE_UPLOAD}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      // Muti File Upload.
      if (pervFileUpload === 'file') {
        setArrayOfFile([
          {
            name: response?.data?.result?.files?.image[0]?.name ?? '',
            image: response?.data?.result?.files?.image[0]?.name ?? '',
          },
        ])
      } else {
        if (arrayOfFile.length === 0) {
          setArrayOfFile([
            {
              name: response?.data?.result?.files?.image[0]?.name ?? '',
              image: response?.data?.result?.files?.image[0]?.name ?? '',
            },
          ])
        } else if (
          arrayOfFile.length === 1 ||
          arrayOfFile.length === 2 ||
          arrayOfFile.length === 3
        ) {
          setArrayOfFile([
            ...arrayOfFile,
            {
              name: response?.data?.result?.files?.image[0]?.name ?? '',
              image: response?.data?.result?.files?.image[0]?.name ?? '',
            },
          ])
        } else {
          setMessage('error', 'Only 4 images are allowed')
          return
        }
      }

      let { filename } = response.data

      setUploading(false)
    } catch (error) {
      setMessage('error', 'Error uploading the image')
      setUploading(false)
    }
  }

  const toggleCameraFacingMode = () => {
    setFacingMode((prevFacingMode) =>
      prevFacingMode === FACING_MODE_USER ? FACING_MODE_ENVIRONMENT : FACING_MODE_USER
    )
  }

  const toggleWebcam = async () => {
    setShowWebcam((prev) => !prev)
    if (!webcamAvailable) {
      await checkCameraPermission()
      setShowWebcam(true)
    } else {
      setShowWebcam(true)
    }
  }

  const initialValues = {
    description: '',
  }

  const captureImage = () => {
    if (webcamRef.current) {
      const imageSrc = webcamRef.current.getScreenshot()
      setCapturedImage(imageSrc)
      uploadImage(imageSrc)
    }
    setShowWebcam(false)
  }

  const cancelWebcam = () => {
    setShowWebcam(false) // Close the webcam pop-up when the user cancels or captures the image
  }

  const feedBackFormFormik = useFormik({
    initialValues,
    validationSchema: feedBackForm,
    onSubmit: async (values, { setStatus, resetForm, setSubmitting }) => {
      setLoading(true)
      try {
        const postData = {
          description: values.description,
          media: arrayOfFile,
          user_id: userid,
        }

        await giveFeedback(postData)
          .then((res: any) => {
            if (res?.status === 200) {
              setMessage('success', 'Feedback Submitted successfully')
              setLoading(false)
              setCapturedImage('')
              setSelectedFile('')
              setArrayOfFile([])
              setMessageCount(MAX_CHARACTERS)
              resetForm()
            }
          })
          .catch((error: any) => {
            if (error?.response?.data?.error?.message) {
              setMessage('error', error?.response?.data?.error?.message)
            } else {
              setMessage('error', 'Something Went Wrong')
            }
            setLoading(false)
          })
      } catch (error: any) {
        setMessage('error', error?.message ? error?.message : 'Something Went Wrong')
        setLoading(false)
      }
    },
  })

  return (
    <>
      <div className='row'>
        <div className='col-lg-2'></div>
        <div className='col-lg-8'>
          <h1 className='inv-hst mb-3 mb-md-0'>Feedback / Query</h1>
          <div className='mt-5'>
            <div className='card ilead__card'>
              <div className='card-body'>
                <h2 className='text-dark fs-3 text-start fw-bolder ps-0 pb-5 line-h-24 border-secondary'>
                  Please submit any queries or feedback regarding ILEAD Sampark
                </h2>
                <form
                  action=''
                  method=''
                  className=''
                  onSubmit={feedBackFormFormik.handleSubmit}
                  noValidate
                >
                  <div className='row'>
                    <div className='col-md-12 md-ps-0'>
                      <textarea
                        className='border-top-0 border-left-0 border-right-0 w-100 border-bottom border-secondary py-3 px-0 fs-6 fw-normal text-start'
                        placeholder='Write here...'
                        rows={4}
                        required
                        onChange={handleDescriptionChange}
                        maxLength={MAX_CHARACTERS}
                        // onChange={(e: any) => {
                        //   feedBackFormFormik.setFieldValue('description', e.target.value.trimStart())
                        // }}
                        value={feedBackFormFormik?.values?.description}
                      ></textarea>
                      <p className='text-gray-500 fs-13 required'>Available Characters {messageCount}</p>
                      {feedBackFormFormik.touched.description && feedBackFormFormik.errors.description && (
                        <div className='fv-plugins-message-container text-danger'>
                          <span role='alert'>{feedBackFormFormik.errors.description as any}</span>
                        </div>
                      )}
                    </div>

                    <div className='col-md-12 md-ps-0pt-3'>
                      <Form.Group className='mb-3 mt-7'>
                        <h5 className='mt-7 mb-7 fs-6'>Add Attachment</h5>
                        <div className='d-flex flex-wrap'>
                          <div className=''>
                            <Button
                              variant='primary'
                              className='me-3 px-4 py-3'
                              onClick={() => toggleWebcam()}
                            >
                              <i className='bi bi-camera-fill pe-0' style={{ fontSize: '20px' }}></i>
                            </Button>
                            {showWebcam && webcamAvailable ? (
                              <div className='webcam-popup'>
                                <div className='webcam-popup-content'>
                                  <Webcam
                                    audio={false}
                                    ref={webcamRef}
                                    videoConstraints={{
                                      facingMode: facingMode,
                                    }}
                                  />
                                  <button
                                    type="button"
                                    className="btn-close text-white cancel-button"
                                    onClick={cancelWebcam}
                                  ></button>
                                  <div className='webcam-buttons'>
                                    <Button
                                      variant='primary'
                                      className='me-3 px-4 py-3'
                                      onClick={captureImage}
                                    >
                                      <i className='bi bi-camera-fill pe-0' style={{ fontSize: '20px' }}></i>
                                    </Button>

                                    <Button
                                      variant="primary"
                                      className="me-3 px-4 py-3 toggle-button"
                                      onClick={toggleCameraFacingMode}
                                      style={{ borderRadius: '30px' }}
                                    >
                                      <i className="bi bi-arrow-repeat pe-0" style={{ fontSize: '20px' }}></i>
                                    </Button>
                                  </div>
                                </div>
                              </div>
                            ) : (
                              ''
                            )}
                          </div>
                          <div className=''>
                            <div className='me-3 px-4 py-3 position-relative btn btn-primary'>
                              <Form.Control
                                type='file'
                                accept='image/jpeg, image/png'
                                className='position-absolute w-100 h-100 start-0 top-0 opacity-0'
                                onChange={handleImageChange} // Handle file input change
                              />
                              <i className='bi bi-image-fill pe-0' style={{ fontSize: '20px' }}></i>
                            </div>
                          </div>
                          <div className=''>
                            <div className='me-3 px-4 py-3 position-relative btn btn-primary'>
                              <Form.Control
                                type='file'
                                accept='.pdf,.doc,.docx,.xls,.xlsx,.ppt,.pptx' // Specify accepted file types
                                className='position-absolute w-100 h-100 start-0 top-0 opacity-0'
                                onChange={handleFileChange} // Handle file input change
                              />
                              <i className='bi bi-folder-fill pe-0' style={{ fontSize: '20px' }}></i>
                            </div>
                          </div>
                        </div>
                      </Form.Group>
                      <div className='row'>
                        {arrayOfFile.map((item: any, i: any) => {
                          let itemLast = item.image.split('.').pop()

                          if (
                            itemLast == 'jpeg' ||
                            itemLast == 'jpeg' ||
                            itemLast == 'png' ||
                            itemLast == 'jpg'
                          ) {
                            return (
                              <div className='col mx-2'>
                                <div className='col'>
                                  <div
                                    className='white-close-image'
                                    style={{ position: 'relative', width: '130px' }}
                                  >
                                    <img
                                      src={`${API_URL}/containers/supportfeedback/download/${item.image}`}
                                      alt='Captured'
                                      style={{ width: 130, height: 90 }}
                                    />
                                    <button
                                      type='button'
                                      className='btn-close text-white fs-4'
                                      style={{
                                        backgroundColor: 'red',
                                        position: 'absolute',
                                        right: '5px',
                                        top: '5px',
                                      }}
                                      onClick={() => handleCancelButtonClick(i)}
                                    />
                                  </div>
                                </div>
                              </div>
                            )
                          } else {
                            return (
                              <>
                                {fileDataShow && (
                                  <div>
                                    <a href={fileDataShow} download>
                                      {fileType === 'application/pdf' ? (
                                        <img
                                          src={toAbsoluteUrl('/media/ilead/pdf.png')}
                                          alt=''
                                          className='w-50px'
                                        />
                                      ) : fileType ===
                                        'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ? (
                                        <img
                                          src={toAbsoluteUrl('/media/ilead/docs.png')}
                                          alt=''
                                          className='w-50px'
                                        />
                                      ) : fileType ===
                                        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ? (
                                        <img
                                          src={toAbsoluteUrl('/media/ilead/sheets.png')}
                                          className='w-50px'
                                          alt=''
                                        />
                                      ) : fileType ===
                                        'application/vnd.openxmlformats-officedocument.presentationml.presentation' ? (
                                        <img
                                          src={toAbsoluteUrl('/media/ilead/ppt.png')}
                                          alt=''
                                          className='w-50px'
                                        />
                                      ) : null}
                                      {/*  */}
                                    </a>
                                    <button
                                      type='button'
                                      className='btn-close text-white'
                                      onClick={() => {
                                        handleCancelButtonClick(i)
                                        setSelectedFile('')
                                        setFileDataShow('')
                                      }}
                                    ></button>
                                  </div>
                                )}
                              </>
                            )
                          }
                        })}
                      </div>
                    </div>
                  </div>
                  <div className='row justify-content-center mt-12'>
                    <div className='col-md-12 md-ps-0 text-center pt-3'>
                      <Button
                        type='submit'
                        className='py-3 px-5 fs-5 w-100 fw-normal text-white text-center'
                        disabled={feedBackFormFormik.isSubmitting || !feedBackFormFormik.isValid}
                      >
                        {' '}
                        Submit{' '}
                      </Button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className='col-lg-2'></div>
      </div>
    </>
  )
}

export default FeedbackQuery
