import {useQuery} from 'react-query'
import {GroupchatEditModalForm} from './GroupchatEditModalForm'
// import {isNotEmpty, QUERIES} from '../../../../../../_metronic/helpers'
import {useListView} from '../core/ListViewProvider'
// import { QUERIES, isNotEmpty } from '../../../../../_metronic/helpers'
import { getGroupchatById } from '../core/_requests'
import { QUERIES, isNotEmpty } from '../../../../../../_metronic/helpers'
// import { getGroupchatById } from '../../../apps/groupchat-management/groupchats-list/core/_requests'
// import {getGroupchatById} from '../core/_requests'

const GroupchatEditModalFormWrapper = () => {
  const {itemIdForUpdate, setItemIdForUpdate} = useListView()
  const enabledQuery: boolean = isNotEmpty(itemIdForUpdate)
  const {
    isLoading,
    data: groupchat,
    error,
  } = useQuery(
    `${QUERIES.GROUPCHATS_LIST}-groupchat-${itemIdForUpdate}`,
    () => {
      return getGroupchatById(itemIdForUpdate)
    },
    {
      cacheTime: 0,
      enabled: enabledQuery,
      onError: (err) => {
        setItemIdForUpdate(undefined)
        console.error(err)
      },
    }
  )

  if (!itemIdForUpdate) {
    return <GroupchatEditModalForm isGroupchatLoading={isLoading} groupchat={{id: undefined}} />
  }

  if (!isLoading && !error && groupchat) {
    return <GroupchatEditModalForm isGroupchatLoading={isLoading} groupchat={groupchat} />
  }

  return null
}

export {GroupchatEditModalFormWrapper}
