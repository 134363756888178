import {useLayout} from '../../core'
import {toAbsoluteUrl} from '../../../helpers'
import {Image} from 'react-bootstrap'
import {Link, useLocation} from 'react-router-dom'
import {useEffect, useState} from 'react'
import {apiUrl} from '../../../../apiurl'
import axios from 'axios'

const API_URL = apiUrl
const MobileFooter = () => {
  const VERTICAL_PERMISSION_URL = `${API_URL}/verticalspermissions/userPermission`
  const [data, setData] = useState<any>({})
  useEffect(() => {
    fetchVerticalData()
  }, [])
  let location = useLocation()
  const {config} = useLayout()
  if (!config.app?.footer?.display) {
    return null
  }

  const fetchVerticalData = async () => {
    try {
      const res = await axios.get(VERTICAL_PERMISSION_URL)
      setData(res?.data)
      console.log('userPermission=>', data)
    } catch (error) {
      console.error('Error -->:', error)
    }
  }

  return (
    <div className='d-flex d-md-none py-2' id='ilead_app_mobile_footer'>
      <Link
        to='/directory'
        className={`w-20 flex-1 text-center text-dark d-flex flex-column justify-content-center align-items-center ${
          location?.pathname === '/directory' ? 'active' : ''
        }`}
      >
        <i className='fa-solid fa-user-group fs-2x text-dark mb-1'></i>
        Directory
      </Link>
      {data?.tyca_presentation == 1 ? (
        <Link
          to='/tyca'
          className={`w-20 flex-1 text-center text-dark d-flex flex-column justify-content-center align-items-center ${
            location?.pathname === '/tyca' ? 'active' : ''
          }`}
        >
          <i className='fa-regular fa-rectangle-list fs-2x text-dark mb-1'></i>
          TYCA
        </Link>
      ) : (
        <Link
          to='/leader-profile'
          className={`w-20 flex-1 text-center text-dark d-flex flex-column justify-content-center align-items-center' ${
            location?.pathname === '/leader-profile' ? 'active' : ''
          }`}
        >
          <i className='fa-solid fa-user fs-2x text-dark mb-1'></i>
          My Profile
        </Link>
      )}
      <Link
        to='/dashboard'
        className='w-20 flex-1 text-center text-dark d-flex flex-column justify-content-center align-items-center'
      >
        <Image src={toAbsoluteUrl('/media/ilead/home1.png')} fluid className='w-50px'></Image>
      </Link>
      <Link
        to='/index'
        className={`w-20 flex-1 text-center text-dark d-flex flex-column justify-content-center align-items-center' ${
          location?.pathname === '/index' ? 'active' : ''
        }`}
      >
        <i className='fa-solid fa-chart-area fs-2x text-dark mb-1'></i>
        Index
      </Link>
      <Link
        to='/chat-list'
        className={`position-relative w-20 flex-1 text-center text-dark d-flex flex-column justify-content-center align-items-center' ${
          location?.pathname === '/chat-list' ? 'active' : ''
        }`}
      >
        <i className='fa-solid fa-comments fs-2x text-dark mb-1'></i>
        <span
          id='comet_chat_unread_count_tabbar'
          style={{width: '15px', position: 'absolute', left: '70%', top: '5px'}}
        ></span>
        Chat
      </Link>
    </div>
  )
}

export {MobileFooter}
