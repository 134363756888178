import React from "react";
import { Bar } from "react-chartjs-2";

const MonthlyReportBarChart = (getAverageFeedback: any) => {
  const get_data = getAverageFeedback?.getAverageFeedback
  let data = {
    labels: ["1", "2", "3", "4", "5"],
    datasets: [
      {
        label: "Value Addition Session Average Rating",
        backgroundColor: "rgb(255,161,181)",
        // borderColor: "rgba(255,161,181,1)",
        borderWidth: 1,
        // stack: 1,
        // hoverBackgroundColor: 'rgba(255,99,132,0.4)',
        hoverBorderColor: "rgba(255,161,181,1)",
        data: [get_data?.sumfor1va, get_data?.sumfor2va, get_data?.sumfor3va, get_data?.sumfor4va, get_data?.sumfor5va]
      },
      {
        label: "TYCA Session Average Rating",
        backgroundColor: "rgb(134,199,243)",
        // borderColor: "rgba(255,99,132,1)",
        borderWidth: 1,
        // stack: 1,
        // hoverBackgroundColor: 'rgba(255,99,132,0.4)',
        hoverBorderColor: "rgba(134,199,243,1)",
        data: [get_data?.sumfor1tyca, get_data?.sumfor2tyca, get_data?.sumfor3tyca, get_data?.sumfor4tyca, get_data?.sumfor5tyca]
      },

      {
        label: "Success Sharing Average Rating ",
        backgroundColor: "rgb(255,226,154)",
        // borderColor: "rgba(255,99,132,1)",
        borderWidth: 1,
        // stack: 1,
        // hoverBackgroundColor: 'rgba(255,99,132,0.4)',
        hoverBorderColor: "rgba(255,226,154,1)",//sumfor2ss
        data: [get_data?.sumfor1ss, get_data?.sumfor2ss, get_data?.sumfor3ss, get_data?.sumfor4ss, get_data?.sumfor5ss]
      }
    ]
  };

  const options = {
    responsive: true,
    legend: {
      display: false
    },
    type: "bar",
    scales: {
      xAxes: [
        {
          stacked: true
        }
      ],
      yAxes: [
        {
          stacked: true
        }
      ]
    }
  };
  return <Bar data={data as any} width={null as any} height={null as any} options={options as any} />;
};

export default MonthlyReportBarChart