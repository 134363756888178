import React, { useContext, useEffect, useRef, useState } from 'react'
import { toAbsoluteUrl } from '../../../_metronic/helpers'
import { Link } from 'react-router-dom'
import { apiUrl } from '../../../apiurl'
import {
  checkDisplayValue,
  indiaFormatDate,
  formatDate,
  checkDisplayValueProfile,
} from '../../../utils'
import axios from 'axios'
import moment from 'moment'
import { useFormik } from 'formik'
import { Button, Form } from 'react-bootstrap'
import Webcam from 'react-webcam'
import { RWebShare } from 'react-web-share'
// import { Button } from 'bootstrap'
import { PointListingCardGiven } from './PointListingCardGiven'
import { PointListingCardSend } from './PointListingCardSend'
import { PointListingCardMemberJoin } from './PointListingCardMemberJoin'
import { PointListingCardInvited } from './PointListingCardInvited'
import { EditProfile } from './_request'
import { UserContext } from '../../../contextStore'
import * as Yup from 'yup'
const API_URL = apiUrl
const MY_PROFILE_URL = `${API_URL}/users/viewProfile`
const IMAGE_UPLOAD = `${API_URL}/containers/userprofile/upload`

type PROFILE = {
  companyname: string
}
const initValue = {
  alternate_mobileno: '',
  business_category: '',
  company_address: '',
  correspndance_address: '',
  vision: '',
  mission: '',
  organization: '',
  products_of_company: '',
  achievements: '',
  business_challanges: '',
  goal_for_next_years: '',
  growthstory_of_business: '',
  profile: '',
  business_profile: '',
  business_card_front: '',
  business_card_back: '',
  birthdate: '',
  profilestatus: '',
  website: '',
  companyname: '',
}
const profileSchema = Yup.object().shape({
  alternate_mobileno: Yup.string().length(10, 'Phone number must be 10 characters').nullable(),
  profilestatus: Yup.string().max(100, 'Maximum 100 characters allow').nullable(),
  companyname: Yup.string().max(500, 'Maximum 500 characters allow').nullable(),
  business_category: Yup.string().max(500, 'Maximum 500 characters allow').nullable(),
  company_address: Yup.string().max(500, 'Maximum 500 characters allow').nullable(),
  correspndance_address: Yup.string().max(500, 'Maximum 500 characters allow').nullable(),
  vision: Yup.string().max(500, 'Maximum 500 characters allow').nullable(),
  mission: Yup.string().max(500, 'Maximum 500 characters allow').nullable(),
  organization: Yup.string().max(500, 'Maximum 500 characters allow').nullable(),
  products_of_company: Yup.string().max(500, 'Maximum 500 characters allow').nullable(),
  growthstory_of_business: Yup.string().max(500, 'Maximum 500 characters allow').nullable(),
  achievements: Yup.string().max(500, 'Maximum 500 characters allow').nullable(),
  business_challanges: Yup.string().max(500, 'Maximum 500 characters allow').nullable(),
  goal_for_next_years: Yup.string().max(500, 'Maximum 500 characters allow').nullable(),
})

const LeaderProfile = () => {
  const [isEdit, setIsEdit] = useState(false)
  const [data, setData] = useState<any>([])
  const [isLoading, setIsLoading] = useState(true)
  const [sendCardListng, setSendCardListng] = useState(false)
  const [receiverCard, setReceiverCard] = useState(false)
  const [memberJoinCard, setMemberJoinCard] = useState(false)
  const [invitedCard, setInvitedCard] = useState(false)
  const [showWebcam, setShowWebcam] = useState(false)
  const [webcamAvailable, setWebcamAvailable] = useState<boolean>(false)
  const webcamRef = useRef<Webcam | null>(null)
  const [webCamImageFor, setWebCamImageFor] = useState<string>('')
  const { userDetail, setMessage, setAuth, token, role, setChapterID } = useContext(UserContext)
  // const { userDetail, setMessage } = useContext(UserContext)
  const [updataProfileLoading, setupdataProfileLoading] = useState<boolean>(false)
  const userid = userDetail?.id ?? ''

  useEffect(() => {
    fetchData()
  }, [])

  const setForkiData = () => {
    formik.setFieldValue('business_category', data ? data.business_category : '')
    formik.setFieldValue('alternate_mobileno', data ? data.alternate_mobileno : '')
    formik.setFieldValue('company_address', data ? data.company_address : '')
    formik.setFieldValue('correspndance_address', data ? data.correspndance_address : '')
    formik.setFieldValue('vision', data ? data.vision : '')
    formik.setFieldValue('mission', data ? data.mission : '')
    formik.setFieldValue('products_of_company', data ? data.products_of_company : '')
    formik.setFieldValue('achievements', data ? data.achievements : '')
    formik.setFieldValue('business_challanges', data ? data.business_challanges : '')
    formik.setFieldValue('goal_for_next_years', data ? data.goal_for_next_years : '')
    formik.setFieldValue('growthstory_of_business', data ? data.growthstory_of_business : '')
    formik.setFieldValue('profile', data ? data.profile : '')
    formik.setFieldValue('business_profile', data ? data.business_profile : '')
    formik.setFieldValue('business_card_front', data ? data.business_card_front : '')
    formik.setFieldValue('business_card_back', data ? data.business_card_back : '')
    formik.setFieldValue('birthdate', data ? data.birthdate : '')
    formik.setFieldValue('profilestatus', data ? data.profilestatus : '')
    formik.setFieldValue('website', data ? data.website : '')
    formik.setFieldValue('companyname', data ? data.companyname : '')
  }

  const uploadFile = async (file: File, fieldtype: any) => {
    try {
      if (!file) {
        console.error('No file selected')
        return
      }

      const formData = new FormData()
      formData.append('file', file)

      const response = await axios.post(`${IMAGE_UPLOAD}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      if (fieldtype == 'profile') {
        formik.setFieldValue('profile', response?.data?.result?.files?.file[0]?.name ?? '')
        setMessage('success', 'Profile image uploaded')
        // setProfileName(response?.data?.result?.files?.file[0]?.name ?? '')
      }
      if (fieldtype == 'business_profile') {
        formik.setFieldValue('business_profile', response?.data?.result?.files?.file[0]?.name ?? '')
        setMessage('success', 'Business profile uploaded')
      }
      if (fieldtype == 'business_card_front') {
        formik.setFieldValue(
          'business_card_front',
          response?.data?.result?.files?.file[0]?.name ?? ''
        )
        setMessage('success', 'Businesses card uploaded')
      }
      if (fieldtype == 'business_card_back') {
        formik.setFieldValue(
          'business_card_back',
          response?.data?.result?.files?.file[0]?.name ?? ''
        )
        setMessage('success', 'Businesses card uploaded')
      }

      // setFileName(response?.data?.result?.files?.file[0]?.name ?? '');
    } catch (error) {
      setMessage('error', 'Something Went Wrong')

      // setMessage('error', 'Error uploading the file');
    }
  }

  const formik = useFormik({
    initialValues: initValue,
    validationSchema: profileSchema,
    onSubmit: async (values: any, { resetForm }) => {
      try {
        setupdataProfileLoading(true)
        await EditProfile(userid, values).then((res: any) => {
          if (res.status == 200) {
            setAuth(token, role, true, res?.data)
            setChapterID(res?.data?.chapter_id ?? '')
            resetForm()
            setMessage('success', 'Profile update Successful')
          }
        }).catch((error: any) => {
          setMessage('error', 'Something Went Wrong')
        })
        setIsEdit(false)
      } catch (error) {
        setMessage('error', 'Something Went Wrong')
      }
      setupdataProfileLoading(false)
      fetchData()
    },
  })

  const fetchData = async () => {
    try {
      const res = await axios.get(MY_PROFILE_URL)
      setData(res?.data ?? [])
      setIsLoading(false)
    } catch (error) {
      console.error('Error -->:', error)
      setIsLoading(false)
    }
  }
  const checkCameraPermission = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true })
      setWebcamAvailable(true)
      stream.getTracks().forEach((track) => track.stop())
    } catch (error) {
      setWebcamAvailable(false)
    }
  }

  const toggleWebcam = async () => {
    if (!webcamAvailable) {
      await checkCameraPermission() // Check camera permission when the camera button is clicked
      setShowWebcam(true)
    } else {
      setShowWebcam(true)
    }
  }
  const uploadImage = async (imageData: any, fileOF: any) => {
    try {
      if (!imageData) {
        console.error('No image captured')
        return
      }

      const img = new Image()
      img.src = imageData

      await new Promise((resolve) => (img.onload = resolve))

      const canvas = document.createElement('canvas')
      canvas.width = img.width
      canvas.height = img.height

      const ctx = canvas.getContext('2d')
      if (ctx) {
        ctx.drawImage(img, 0, 0)
      } else {
        console.error('Canvas context is null')
        return
      }

      const jpgDataUrl = canvas.toDataURL('image/jpeg', 1)
      // Convert the data URL back to a Blob
      const byteCharacters = atob(imageData.split(',')[1])
      const byteArrays = new Uint8Array(byteCharacters.length)
      for (let i = 0; i < byteCharacters.length; i++) {
        byteArrays[i] = byteCharacters.charCodeAt(i)
      }
      const jpgBlob = new Blob([byteArrays], { type: 'image/jpeg' })

      // Create a FormData object to send the image file to the API
      const formData = new FormData()
      formData.append('image', jpgBlob, 'filename.jpg')

      // Make the API call to upload the image
      const response = await axios.post(`${IMAGE_UPLOAD}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      if (fileOF == 'profile') {
        formik.setFieldValue('profile', response?.data?.result?.files?.image[0]?.name ?? '')
        setMessage('success', 'Profile image uploaded')
        // setProfileName(response?.data?.result?.files?.file[0]?.name ?? '')
      }
      if (fileOF == 'business_card_front') {
        formik.setFieldValue(
          'business_card_front',
          response?.data?.result?.files?.image[0]?.name ?? ''
        )
        setMessage('success', 'Businesses card uploaded')
      }
      if (fileOF == 'business_card_back') {
        formik.setFieldValue(
          'business_card_back',
          response?.data?.result?.files?.image[0]?.name ?? ''
        )
        setMessage('success', 'Businesses card uploaded')
      }

      // Reset the captured image and loading state
      // setCapturedImage(null);
    } catch (error) {
      setMessage('error', 'Something Went Wrong')
      // setMessage('error', 'Error uploading the image')
      // Reset the captured image and loading state in case of error
      // setCapturedImage(null);
    }
  }

  const captureImage = (fileOf: any) => {
    if (webcamRef.current) {
      const imageSrc = webcamRef.current.getScreenshot()
      uploadImage(imageSrc, webCamImageFor)
    }
    setShowWebcam(false)
  }
  const captureImageForBusinessCardFront = (fileOf: any) => {
    if (webcamRef.current) {
      const imageSrc = webcamRef.current.getScreenshot()
      uploadImage(imageSrc, 'business_card_front')
    }
    setShowWebcam(false)
  }
  const captureImageForBusinessCardBack = (fileOf: any) => {
    if (webcamRef.current) {
      const imageSrc = webcamRef.current.getScreenshot()
      uploadImage(imageSrc, 'business_card_back')
    }
    setShowWebcam(false)
  }

  const styles = {
    borderRadius: '0.475rem',
    boxShadow: '0 0 50px 0 rgb(82 63 105 / 15%)',
    backgroundColor: '#fff',
    color: '#7e8299',
    fontWeight: '500',
    margin: '0',
    width: 'auto',
    padding: '1rem 2rem',
    top: 'calc(50% - 2rem)',
    left: 'calc(50% - 4rem)',
  }

  if (isLoading) {
    return <div style={{ ...styles, position: 'absolute', textAlign: 'center' }}>Processing...</div>
  }

  const handleButtonClick = (pdf: any) => {
    if (pdf) {
      const url = `${API_URL}/containers/userprofile/download/${pdf}`
      window.open(url, '_blank')
    }
  }

  const searchString = (web: string) => {
    let webData
    if (web.startsWith('www')) {
      webData = `http://${web}`
    } else {
      webData = `${web}`
    }
    return webData
  }

  return (
    <>
      <h1 className='inv-hst mb-3'> My Profile </h1>
      <form
        id='kt_modal_add_categorymanagement_form'
        className='form'
        onSubmit={formik.handleSubmit}
        noValidate
      >
        <div className='card ilead__card mb-5 mb-xl-7'>
          <div className='card-body pt-9 pb-0'>
            <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
              {/* Profile Pic */}
              <div className='me-md-7 mb-4 flex-1 d-flex justify-content-between'>
                <div className='symbol symbol-100px symbol-lg-160px symbol-fixed position-relative'>
                  {isEdit && (
                    <img
                      src={
                        formik?.values?.profile
                          ? `${API_URL}/containers/userprofile/download/${formik?.values?.profile}`
                          : `${toAbsoluteUrl('./media/avatars/default-user.png')}`
                      }
                      onError={({ currentTarget }: any) => {
                        currentTarget.onerror = null // prevents looping
                        currentTarget.src = `${toAbsoluteUrl('./media/avatars/default-user.png')}`
                      }}
                      alt=''
                      className=''
                    />
                  )}
                  {!isEdit && (
                    <img
                      src={
                        data?.profile
                          ? `${API_URL}/containers/userprofile/download/${data?.profile}`
                          : `${toAbsoluteUrl('./media/avatars/default-user.png')}`
                      }
                      onError={({ currentTarget }: any) => {
                        currentTarget.onerror = null // prevents looping
                        currentTarget.src = `${toAbsoluteUrl('./media/avatars/default-user.png')}`
                      }}
                      alt=''
                      className=''
                    />
                  )}
                  {isEdit && (
                    <div className='d-flex justify-content-center position-absolute w-100 translate-middle top-50 start-50'>
                      <Button
                        variant='dark'
                        className='me-3 px-4 py-3'
                        disabled={updataProfileLoading}
                        onClick={() => {
                          toggleWebcam()
                          setWebCamImageFor('profile')
                        }}
                      >
                        <i className='bi bi-camera-fill pe-0' style={{ fontSize: '20px' }}></i>
                      </Button>
                      
                      <div className='me-3 px-4 py-3 position-relative btn btn-primary'>
                        <Form.Control
                          type='file'
                          accept='image/jpeg, image/png'
                          disabled={updataProfileLoading}
                          className='position-absolute w-100 h-100 start-0 top-0 opacity-0'
                          onChange={(event: any) => {
                            const file = event.target.files?.[0]
                            if (file) {
                              uploadFile(file, 'profile')
                            }
                          }} // Handle file input change
                        />
                        <i className='bi bi-folder-fill pe-0' style={{ fontSize: '20px' }}></i>
                      </div>
                    </div>
                  )}
                </div>
                {/* Default view Share and Edit  */}
                {!isEdit && (
                  <div className='d-flex flex-column d-md-none my-4'>
                    <RWebShare
                      data={{
                        text: `${data?.name ?? ''}\n ${data?.companyname ?? ''}\n ${data?.mobileno ?? ''
                          }\n ${data?.email ?? ''}\n ${data?.website ?? ''}`,
                        url: `${data?.business_card_front ?? ''}`,
                        title: `${data?.name ?? ''}`,
                      }}
                      onClick={() => console.log('shared successfully!')}
                    >
                      <Button
                        variant='primary'
                        className='btn-sm mb-2'
                      >
                        <i className="bi bi-share-fill me-2"></i>
                        <span className='indicator-label'>Share</span>
                      </Button>
                    </RWebShare>
                    <Button
                      type='button'
                      variant='secondary'
                      className='btn-sm'
                      onClick={() => {
                        setIsEdit(true)
                        setForkiData()
                      }}
                    >
                      <i className="las la-pen me-2"></i>
                      <span className='indicator-label'>Edit Profile</span>
                    </Button>
                  </div>
                )}
                {/* Edit View Share and Edit  */}
                {isEdit && (
                  <div className='d-flex flex-column d-flex d-md-none my-4'>
                    <Button
                      variant='success'
                      type='submit'
                      disabled={updataProfileLoading}
                      className='btn-sm mb-2'
                    >
                      Save
                    </Button>
                    <Button
                      variant='secondary'
                      disabled={updataProfileLoading}
                      type='button'
                      className='btn-sm'
                      onClick={() => {
                        if (Object.keys(formik.touched).length > 0) {
                          setMessage('error', 'Please save before you leave')
                        } else {
                          setIsEdit(false)
                        }
                      }}
                    >
                      {' '}
                      cancel{' '}
                    </Button>
                  </div>
                )}
                {/*  */}
              </div>

              {showWebcam && webcamAvailable ? (
                        <div className='webcam-popup'>
                          <div className='webcam-popup-content'>
                            <Webcam audio={false} ref={webcamRef} />
                            <div className='webcam-buttons'>
                              <Button
                                variant='dark'
                                className='me-3 px-4 py-3'
                                onClick={captureImage}
                              >
                                <i className='bi bi-camera-fill pe-0' style={{ fontSize: '20px' }}></i>
                              </Button>
                              <Button
                                variant='secondary'
                                onClick={() => {
                                  setShowWebcam(false)
                                }}
                              >
                                Cancel
                              </Button>
                            </div>
                          </div>
                        </div>
                      ) : (
                        ''
                      )}

              {/* Profile Details */}
              <div className='flex-grow-1'>
                <div className='d-flex justify-content-between align-items-start flex-wrap mb-2 border-bottom'>
                  <div className='d-flex flex-column'>
                    <div className='d-flex align-items-center mb-2'>
                      <h4 className='text-black text-hover-primary fs-2 fw-bolder me-1'>
                        {' '}
                        {checkDisplayValueProfile(data?.name)}{' '}
                      </h4>
                    </div>
                    {/* Default View of Profile Name */}
                    {!isEdit && (
                      <div className='d-flex flex-wrap fs-6 mb-4 pe-2'>
                        <div className='d-flex align-items-center text-gray-400 me-5 mb-2'>
                          <i className="las la-phone-volume text-primary me-2" style={{ fontSize: '18px' }}></i>
                          <a href={`tel: +91 ${data?.mobileno}`} className='text-black'>
                            +91 {checkDisplayValueProfile(data?.mobileno)}
                          </a>
                        </div>
                        <div className='d-flex align-items-center text-gray-400 me-5 mb-2'>
                          <i className="las la-envelope text-primary me-2" style={{ fontSize: '18px' }}></i>
                          <a href={`mailto:${data?.email}`} className='text-black'>
                            {checkDisplayValueProfile(data?.email)}
                          </a>
                        </div>
                      </div>
                    )}
                    {/* Edit View of Profile Name */}
                    {isEdit && (
                      <div className='d-flex flex-wrap fs-6 mb-4 pe-2'>
                        <div className='d-flex align-items-center text-gray-400 me-5 mb-2'>
                          <i className="las la-phone-volume text-primary me-2" style={{ fontSize: '18px' }}></i>
                          <a href={`tel: +91 ${data?.mobileno}`} className='text-black'>
                            +91 {checkDisplayValueProfile(data?.mobileno)}
                          </a>
                        </div>
                        <div className='d-flex align-items-center text-gray-400 me-5 mb-2'>
                          <i className="las la-envelope text-primary me-2" style={{ fontSize: '18px' }}></i>
                          <a href={`mailto:${data?.email}`} className='text-black'>
                            {checkDisplayValueProfile(data?.email)}
                          </a>
                        </div>
                      </div>
                    )}
                  </div>
                  {/* Default view Share and Edit  */}
                  {!isEdit && (
                    <div className='d-none d-md-flex my-4'>
                      <RWebShare
                        data={{
                          text: `${data?.name ?? ''}\n ${data?.companyname ?? ''}\n ${data?.mobileno ?? ''
                            }\n ${data?.email ?? ''}\n ${data?.website ?? ''}`,
                          url: `${data?.business_card_front ?? ''}`,
                          title: `${data?.name ?? ''}`,
                        }}
                        onClick={() => console.log('shared successfully!')}
                      >
                        <Button
                          variant='primary'
                          className='btn-sm me-2'
                        >
                          <i className="bi bi-share-fill me-2"></i>
                          <span className='indicator-label'>Share</span>
                        </Button>
                      </RWebShare>
                      <Button
                        type='button'
                        variant='secondary'
                        className='btn-sm'
                        onClick={() => {
                          setIsEdit(true)
                          setForkiData()
                        }}
                      >
                        <i className="las la-pen me-2"></i>
                        <span className='indicator-label'>Edit Profile</span>
                      </Button>
                    </div>
                  )}
                  {/* Edit View Share and Edit  */}
                  {isEdit && (
                    <div className='d-none d-md-flex my-4'>
                      <Button
                        variant='success'
                        type='submit'
                        disabled={updataProfileLoading}
                        className='btn-sm me-2'
                      >
                        Save
                      </Button>
                      <Button
                        variant='secondary'
                        disabled={updataProfileLoading}
                        type='button'
                        className='btn-sm'
                        onClick={() => {
                          if (Object.keys(formik.touched).length > 0) {
                            setMessage('error', 'Please save before you leave')
                          } else {
                            setIsEdit(false)
                          }
                        }}
                      >
                        {' '}
                        cancel{' '}
                      </Button>
                    </div>
                  )}
                  {/*  */}
                </div>
                {/* Default View editable contact details*/}
                {!isEdit && (
                  <div className='d-flex flex-wrap flex-stack pt-3'>
                    <div className='d-flex flex-wrap fs-6 mb-4 mb-md-0 pe-2'>
                      <div className='d-flex align-items-center text-gray-400 me-3 mb-2'>
                        <i className="las la-suitcase text-dark me-2" style={{ fontSize: '18px' }}></i>
                        <span className='text-black'>
                          {checkDisplayValueProfile(data?.profilestatus)}
                        </span>
                      </div>
                      <div className='d-flex align-items-center text-gray-400 me-3 mb-2'>
                        <i className="las la-phone-volume text-dark me-2" style={{ fontSize: '18px' }}></i>
                        {data?.alternate_mobileno ? (
                          <a href={`tel: +91 ${data?.alternate_mobileno}`} style={{ color: '#3f4254' }}>
                            +91{checkDisplayValueProfile(data?.alternate_mobileno)}
                          </a>
                        ) : (
                          <span className='text-dark'>Not Updated</span>
                        )}
                      </div>
                      <div className='d-flex align-items-center text-gray-400 me-3 mb-2'>
                        <i className="bi bi-globe2 text-dark me-2" style={{ fontSize: '18px' }}></i>
                        {data.website ? (
                          <a
                            href={`${searchString(data.website)}`}
                            target='_blank'
                            className='ml-3 text-dark'
                          >
                            {checkDisplayValueProfile(data?.website)}
                          </a>
                        ) : (
                          <> {checkDisplayValueProfile(data?.website)}</>
                        )}
                      </div>
                    </div>
                    <div className='d-flex flex-wrap fs-6 mb-4 mb-md-0 pe-2'>
                      <div className='d-flex align-items-center text-gray-400 me-3 mb-2'>
                        <i className="las la-birthday-cake text-dark me-2" style={{ fontSize: '18px' }}></i>
                        <span className='text-dark'>
                          {data?.birthdate
                            ? checkDisplayValueProfile(moment(data?.birthdate).format('MMM DD, YYYY'))
                            : 'Not Updated'}
                        </span>
                      </div>
                    </div>
                  </div>
                )}
                {/* Editable View Contact details */}
                {isEdit && (
                  <div className='d-flex flex-wrap flex-stack pt-3'>
                    <div className='d-flex flex-wrap mb-4 pe-2'>
                      <div className='text-gray-400 me-md-3 mb-2 mb-md-2 mb-md-2 w-100 w-md-auto'>
                        <span className='text-black'>
                          <input
                            data-id='2'
                            id='txtbx_updatealertnate'
                            name='alternate'
                            placeholder='Enter Company Name'
                            type='text'
                            className='form-control form-control-sm ilead-input form-control-solid'
                            style={{ borderBottom: '1px solid #b5b5b5' }}
                            aria-invalid='false'
                            onChange={(e: any) => {
                              formik.setFieldTouched('profilestatus', true)
                              formik.setFieldValue('profilestatus', e.target.value.trimStart())
                            }}
                            value={formik?.values?.profilestatus}
                          />
                        </span>
                      </div>
                      <div className='text-gray-400 me-md-3 mb-2 mb-md-2 mb-md-2 w-100 w-md-auto'>
                        <input
                          data-id='2'
                          id='txtbx_updatealertnate'
                          name='alternate'
                          placeholder='Enter Alternate Mobile Number'
                          type='text'
                          className='form-control form-control-sm ilead-input form-control-solid'
                          style={{ borderBottom: '1px solid #b5b5b5' }}
                          aria-invalid='false'
                          onChange={(e: any) => {
                            formik.setFieldTouched('alternate_mobileno', true)
                            formik.setFieldValue('alternate_mobileno', e.target.value.trimStart())
                          }}
                          value={formik?.values?.alternate_mobileno}
                        />
                        {formik.touched.alternate_mobileno && formik.errors.alternate_mobileno && (
                          // <div className='fv-plugins-message-container text-danger'>
                          <span role='alert' style={{ color: 'red' }}>
                            {formik.errors.alternate_mobileno as any}
                          </span>
                          // </div>
                        )}
                      </div>
                      <div className='text-gray-400 me-md-3 mb-2 mb-md-2 mb-md-2 w-100 w-md-auto'>
                        <input
                          name='alternate'
                          placeholder='Enter Website Link'
                          type='url'
                          className='form-control form-control-sm ilead-input form-control-solid'
                          style={{ borderBottom: '1px solid #b5b5b5' }}
                          // aria-invalid='false'
                          onChange={(e: any) => {
                            formik.setFieldTouched('website', true)
                            formik.setFieldValue('website', e.target.value.trimStart())
                          }}
                          value={formik?.values?.website}
                        />
                        {formik.touched.website && formik.errors.website && (
                          <span role='alert' style={{ color: 'red' }}>
                            {formik.errors.website as any}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className='fs-6 mb-4 pe-md-2  w-100 w-md-auto'>
                      <div className='d-flex align-items-center text-gray-400 me-3 mb-2'>
                        <input
                          data-id='2'
                          id='txtbx_updatealertnate'
                          defaultValue={formatDate(formik?.values?.birthdate)}
                          name='alternate'
                          placeholder='Enter birthdate Number'
                          type='date'
                          className='form-control form-control-sm ilead-input form-control-solid'
                          style={{ borderBottom: '1px solid #b5b5b5' }}
                          aria-invalid='false'
                          onChange={(e: any) => {
                            formik.setFieldTouched('birthdate', true)
                            formik.setFieldValue('birthdate', e.target.value.trimStart())
                          }}
                        />
                        {formik.touched.birthdate && formik.errors.birthdate && (
                          <span role='alert' style={{ color: 'red' }}>
                            {formik.errors.birthdate as any}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                )}
                {/*  */}
              </div>
            </div>
          </div>
        </div>
        <div className='row mobile-padding'>
          {/* My Performer */}
          <div className='col-md-4'>
            <div className='card ilead__card'>
              <div className='card-header'>
                <h3 className='text-black card-title'>My Performance</h3>
              </div>
              <div className='card-body'>
                <div className='row leader-perform'>
                  <div className='col-6 mb-4'>
                    <div className='leader_box mb-0'>
                      <button
                        type='button'
                        className='thanks_count'
                        data-bs-toggle='modal'
                        data-bs-target=''
                        onClick={() => {
                          // setReceiverCard(true)
                        }}
                      >
                        {data?.Thank_You_Card_Received}
                      </button>
                    </div>
                    <div className='p-0 thankyou py-5'>
                      <h4 className='fs-6 text-white fw-bold text-center mb-0'>
                        Thank You Card Received
                      </h4>
                    </div>
                  </div>
                  <div className='col-6 mb-4'>
                    <div className='leader_box mb-0'>
                      <button
                        type='button'
                        className='thanks_count'
                        data-bs-toggle='modal'
                        data-bs-target=''
                        onClick={() => {
                          // setSendCardListng(true)
                        }}
                      >
                        {data?.Thank_You_Card_Given}
                      </button>
                    </div>
                    <div className='p-0 thankyou py-5'>
                      <h4 className='fs-6 text-white fw-bold text-center mb-0'>
                        Thank You Card Given
                      </h4>
                    </div>
                  </div>
                  <div className='col-6'>
                    <div className='leader_box mb-0'>
                      <button
                        type='button'
                        className='thanks_count'
                        data-bs-toggle='modal'
                        data-bs-target=''
                        onClick={() => {
                          // setInvitedCard(true)
                        }}
                      >
                        {data?.visitor_invited}
                      </button>
                    </div>
                    <div className='p-0 thankyou py-5'>
                      <h4 className='fs-6 text-white fw-bold text-center mb-0'>
                        Visitor(s) Invited
                      </h4>
                    </div>
                  </div>
                  <div className='col-6'>
                    <div className='leader_box mb-0'>
                      <button
                        type='button'
                        className='thanks_count'
                        data-bs-toggle='modal'
                        data-bs-target=''
                        onClick={() => {
                          // setMemberJoinCard(true)
                        }}
                      >
                        {data?.visitors_joined}
                      </button>
                    </div>
                    <div className='p-0 thankyou py-5'>
                      <h4 className='fs-6 text-white fw-bold text-center mb-0'>
                        Member(s) Joined
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Company Details */}
          <div className='col-md-8'>
            <div className='card ilead__card mb-7'>
              <div className='card-header'>
                <h3 className='text-black card-title'>Business Details</h3>
              </div>
              <div className='card-body'>
                <div className='row'>
                  <div className='col-lg-6 mb-7'>
                    <h4 className='mb-3'>Company Name</h4>
                    {!isEdit && (
                      <span className='text-black'>
                        {checkDisplayValueProfile(data?.companyname)}
                      </span>
                    )}
                    {isEdit && (
                      <div>
                        <input
                          data-id='2'
                          id=''
                          name='business_category'
                          placeholder='Enter Company Name'
                          type='text'
                          className='form-control form-control-sm ilead-input form-control-solid mt-3'
                          style={{ borderBottom: '1px solid #b5b5b5' }}
                          aria-invalid='false'
                          onChange={(e: any) => {
                            formik.setFieldTouched('companyname', true)
                            formik.setFieldValue('companyname', e.target.value.trimStart())
                          }}
                          value={formik?.values?.companyname}
                        />
                        {formik.touched.companyname && formik.errors.companyname && (
                          <span role='alert' style={{ color: 'red' }}>
                            {formik.errors.companyname as any}
                          </span>
                        )}
                      </div>
                    )}
                  </div>
                  <div className='col-lg-6 mb-7'>
                    <h4 className='mb-3'>Business Category</h4>
                    {!isEdit && (
                      <span className='text-black'>
                        {checkDisplayValueProfile(data?.business_category)}
                      </span>
                    )}
                    {isEdit && (
                      <div>
                        <input
                          data-id='2'
                          id=''
                          name='business_category'
                          placeholder='Enter business category'
                          type='text'
                          className='form-control form-control-sm ilead-input form-control-solid mt-3'
                          style={{ borderBottom: '1px solid #b5b5b5' }}
                          aria-invalid='false'
                          onChange={(e: any) => {
                            formik.setFieldTouched('business_category', true)
                            formik.setFieldValue('business_category', e.target.value.trimStart())
                          }}
                          value={formik?.values?.business_category}
                        />
                        {formik.touched.business_category && formik.errors.business_category && (
                          <span role='alert' style={{ color: 'red' }}>
                            {formik.errors.business_category as any}
                          </span>
                        )}
                      </div>
                    )}
                  </div>
                  <div className='col-lg-6 mb-7'>
                    <h4 className='mb-3'>Company Address</h4>
                    {!isEdit && (
                      <span className='text-black'>
                        {checkDisplayValueProfile(data?.company_address)}
                      </span>
                    )}
                    {isEdit && (
                      <div>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                          <textarea
                            id='w3review'
                            cols={4}
                            rows={5}
                            className='form-control form-control-sm ilead-input form-control-solid mt-3 w-100'
                            style={{ borderBottom: '1px solid #b5b5b5' }}
                            placeholder='Enter Company Address '
                            name='business_category'
                            onChange={(e: any) => {
                              formik.setFieldTouched('company_address', true)
                              formik.setFieldValue('company_address', e.target.value.trimStart())
                            }}
                          >
                            {formik?.values?.company_address}
                            {/* At w3schools.com you will learn how to make a website. They offer free tutorials in all web development technologies. */}
                          </textarea>
                          {formik.touched.company_address && formik.errors.company_address && (
                            <span role='alert' style={{ color: 'red' }}>
                              {formik.errors.company_address as any}
                            </span>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                  <div className='col-lg-6 mb-7'>
                    <h4 className='mb-3'>Correspondence Address</h4>
                    {!isEdit && (
                      <span className='text-black '>
                        {checkDisplayValueProfile(data?.correspndance_address)}
                      </span>
                    )}
                    {isEdit && (
                      <div>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                          <textarea
                            id='w3review'
                            cols={5}
                            rows={5}
                            className='form-control form-control-sm ilead-input form-control-solid mt-3 w-100'
                            style={{ borderBottom: '1px solid #b5b5b5' }}
                            placeholder='Enter Address '
                            name='correspndance_address'
                            onChange={(e: any) => {
                              formik.setFieldTouched('correspndance_address', true)
                              formik.setFieldValue(
                                'correspndance_address',
                                e.target.value.trimStart()
                              )
                            }}
                          >
                            {formik?.values?.correspndance_address}
                            {/* At w3schools.com you will learn how to make a website. They offer free tutorials in all web development technologies. */}
                          </textarea>
                          {formik.touched.correspndance_address &&
                            formik.errors.correspndance_address && (
                              <span role='alert' style={{ color: 'red' }}>
                                {formik.errors.correspndance_address as any}
                              </span>
                            )}
                        </div>
                      </div>
                    )}
                  </div>
                  <div className='col-lg-12 mb-7'>
                    <h4 className='mb-3'>Vision</h4>
                    {!isEdit && <p className='themes'>{checkDisplayValueProfile(data?.vision)}</p>}
                    {isEdit && (
                      <div>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                          <textarea
                            id='w3review'
                            name='vision'
                            cols={10}
                            rows={2}
                            className='form-control form-control-sm ilead-input form-control-solid mt-3 w-100'
                            style={{ borderBottom: '1px solid #b5b5b5' }}
                            placeholder='Enter your Vision'
                            onChange={(e: any) => {
                              formik.setFieldTouched('vision', true)
                              formik.setFieldValue('vision', e.target.value.trimStart())
                            }}
                          >
                            {formik?.values?.vision}
                            {/* At w3schools.com you will learn how to make a website. They offer free tutorials in all web development technologies. */}
                          </textarea>
                          {formik.touched.vision && formik.errors.vision && (
                            <span role='alert' style={{ color: 'red' }}>
                              {formik.errors.vision as any}
                            </span>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                  <div className='col-lg-12 mb-7'>
                    <h4 className='mb-3'>Mission</h4>
                    {!isEdit && <p className='themes'>{checkDisplayValueProfile(data?.mission)}</p>}
                    {isEdit && (
                      <div
                        style={{ display: 'flex', flexDirection: 'column' }}
                      >
                        <textarea
                          id='w3review'
                          name='mission'
                          cols={10}
                          rows={2}
                          className='form-control form-control-sm ilead-input form-control-solid mt-3 w-100'
                          style={{ borderBottom: '1px solid #b5b5b5' }}
                          placeholder='Enter Your Mission'
                          onChange={(e: any) => {
                            formik.setFieldTouched('mission', true)
                            formik.setFieldValue('mission', e.target.value.trimStart())
                          }}
                        >
                          {formik?.values?.mission}
                          {/* At w3schools.com you will learn how to make a website. They offer free tutorials in all web development technologies. */}
                        </textarea>
                        {formik.touched.mission && formik.errors.mission && (
                          <span role='alert' style={{ color: 'red' }}>
                            {formik.errors.mission as any}
                          </span>
                        )}
                      </div>
                    )}
                  </div>
                  <div className='col-lg-12 mb-7'>
                    <h4 className='mb-4'>
                      Business Card
                    </h4>
                    <div className='row'>
                      <div className='col-md-5'>
                        {isEdit && (
                          <div>
                            <img
                              src={
                                formik?.values?.business_card_front
                                  ? `${API_URL}/containers/userprofile/download/${formik?.values?.business_card_front}`
                                  : `${toAbsoluteUrl('./media/icons/duotune/business-card.png')}`
                              }
                              onError={({ currentTarget }: any) => {
                                currentTarget.onerror = null // prevents looping
                                currentTarget.src = `${toAbsoluteUrl(
                                  './media/icons/duotune/business-card.png'
                                )}`
                              }}
                              alt='example'
                              className='w-100 h-100 object-fit-cover'
                            />
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                              <Button
                                variant='primary'
                                disabled={updataProfileLoading}
                                className='me-3 px-4 py-3'
                                onClick={() => {
                                  toggleWebcam()
                                  setWebCamImageFor('business_card_front')
                                }}
                              >
                                <i className='bi bi-camera-fill pe-0' style={{ fontSize: '20px' }}></i>
                              </Button>
                              <div className='me-3 px-4 py-3 position-relative btn btn-primary'>
                                <Form.Control
                                  type='file'
                                  disabled={updataProfileLoading}
                                  accept='image/jpeg, image/png'
                                  className='position-absolute w-100 h-100 start-0 top-0 opacity-0'
                                  onChange={(event: any) => {
                                    const file = event.target.files?.[0]
                                    if (file) {
                                      uploadFile(file, 'business_card_front')
                                    }
                                  }} // Handle file input change
                                />
                                <i className='bi bi-folder-fill pe-0' style={{ fontSize: '20px' }}></i>
                              </div>
                            </div>
                          </div>
                        )}
                        {!isEdit && (
                          <div>
                            <img
                              src={
                                data?.business_card_front
                                  ? `${API_URL}/containers/userprofile/download/${data?.business_card_front}`
                                  : `${toAbsoluteUrl('./media/icons/duotune/business-card.png')}`
                              }
                              onError={({ currentTarget }: any) => {
                                currentTarget.onerror = null // prevents looping
                                currentTarget.src = `${toAbsoluteUrl(
                                  './media/icons/duotune/business-card.png'
                                )}`
                              }}
                              // src={'./media/icons/duotune/business-card.png'}
                              alt='example'
                              className='w-100 h-100 object-fit-cover'
                            />
                          </div>
                        )}
                      </div>
                      <div className=' col-md-5'>
                        {isEdit && (
                          <div>
                            <img
                              src={
                                formik?.values?.business_card_back
                                  ? `${API_URL}/containers/userprofile/download/${formik?.values?.business_card_back}`
                                  : `${toAbsoluteUrl('./media/icons/duotune/business-card.png')}`
                              }
                              onError={({ currentTarget }: any) => {
                                currentTarget.onerror = null // prevents looping
                                currentTarget.src = `${toAbsoluteUrl(
                                  './media/icons/duotune/business-card.png'
                                )}`
                              }}
                              alt='example'
                              className='w-100 h-100 object-fit-cover'
                            />
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                              <Button
                                variant='primary'
                                className='me-3 px-4 py-3'
                                disabled={updataProfileLoading}
                                onClick={() => {
                                  toggleWebcam()
                                  setWebCamImageFor('business_card_back')
                                }}
                              >
                                <i className='bi bi-camera-fill pe-0' style={{ fontSize: '20px' }}></i>
                              </Button>
                              <div className='me-3 px-4 py-3 position-relative btn btn-primary'>
                                <Form.Control
                                  type='file'
                                  disabled={updataProfileLoading}
                                  accept='image/jpeg, image/png'
                                  className='position-absolute w-100 h-100 start-0 top-0 opacity-0'
                                  onChange={(event: any) => {
                                    const file = event.target.files?.[0]
                                    if (file) {
                                      uploadFile(file, 'business_card_back')
                                    }
                                  }} // Handle file input change
                                />
                                <i className='bi bi-folder-fill pe-0' style={{ fontSize: '20px' }}></i>
                              </div>
                            </div>
                          </div>
                        )}
                        {!isEdit && (
                          <div>
                            <img
                              src={
                                data?.business_card_back
                                  ? `${API_URL}/containers/userprofile/download/${data?.business_card_back}`
                                  : `${toAbsoluteUrl('./media/icons/duotune/business-card.png')}`
                              }
                              onError={({ currentTarget }: any) => {
                                currentTarget.onerror = null // prevents looping
                                currentTarget.src = `${toAbsoluteUrl(
                                  './media/icons/duotune/business-card.png'
                                )}`
                              }}
                              alt='example'
                              className='w-100 h-100 object-fit-cover'
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className='col-lg-12 mb-7'>
                    <h4 className='mb-4'>
                      Business Profile
                    </h4>
                    <div className='d-flex justify-content-between'>
                      <div className='business__profile__doc twoline-truncate'>
                        <Link
                          to='#'
                          onClick={() => handleButtonClick(data?.business_profile)}
                          data-bs-toggle='modal'
                          data-bs-target='#kt_modal_create_app'
                          className='justify-content-center align-items-center'
                        >
                          <img alt='' src={toAbsoluteUrl('./media/icons/duotune/pdf.png')} className='mb-2' />
                          Click to View
                        </Link>
                        <p className='twoline-truncate'>
                          {!isEdit && checkDisplayValueProfile(data?.business_profile)}
                          {isEdit && checkDisplayValueProfile(formik?.values?.business_profile)}
                        </p>
                      </div>
                      {isEdit && (
                        <div>
                          <i className='fa-solid fa-cloud-arrow-up fs-1 position-relative overflow-hidden' style={{ color: '#e3371e' }}>
                            <Form.Control
                              type='file'
                              disabled={updataProfileLoading}
                              accept='.pdf,.doc,.docx,.xls,.xlsx,.ppt,.pptx'
                              className='position-absolute w-100 h-100 start-0 top-0 opacity-0'
                              onChange={(event: any) => {
                                const file = event.target.files?.[0]
                                if (file) {
                                  uploadFile(file, 'business_profile')
                                }
                              }} // Handle file input change
                            />
                          </i>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='card ilead__card'>
              <div className='card-header'>
                <h3 className='text-black card-title'>Other Details</h3>
              </div>
              <div className='card-body'>
                <div className='row mb-5'>
                  <div className='col-md-6'>
                    <h4 className="mb-4">Organization</h4>
                    {!isEdit && (
                      <p className='themes'>{checkDisplayValueProfile(data?.organization)}</p>
                    )}
                    {isEdit && (
                      <div
                        style={{ display: 'flex', flexDirection: 'column' }}
                      >
                        <textarea
                          id='w3review'
                          name='organization'
                          cols={5}
                          rows={4}
                          className='form-control form-control-sm ilead-input form-control-solid mt-3 w-100'
                          style={{ borderBottom: '1px solid #b5b5b5' }}
                          placeholder='Enter Organization '
                          onChange={(e: any) => {
                            formik.setFieldTouched('organization', true)
                            formik.setFieldValue('organization', e.target.value.trimStart())
                          }}
                        >
                          {formik?.values?.organization}
                          {/* At w3schools.com you will learn how to make a website. They offer free tutorials in all web development technologies. */}
                        </textarea>
                        {formik.touched.organization && formik.errors.organization && (
                          <span role='alert' style={{ color: 'red' }}>
                            {formik.errors.organization as any}
                          </span>
                        )}
                      </div>
                    )}
                  </div>
                  <div className='col-md-6'>
                    <h4 className="mb-4">Products Of Company</h4>
                    {!isEdit && (
                      <p className='themes'>
                        {checkDisplayValueProfile(data?.products_of_company)}
                      </p>
                    )}
                    {isEdit && (
                      <div
                        style={{ display: 'flex', flexDirection: 'column' }}
                      >
                        <textarea
                          id='w3review'
                          cols={5}
                          rows={4}
                          className='form-control form-control-sm ilead-input form-control-solid mt-3 w-100'
                          style={{ borderBottom: '1px solid #b5b5b5' }}
                          name='products_of_company'
                          placeholder='Enter Company products'
                          onChange={(e: any) => {
                            formik.setFieldTouched('products_of_company', true)
                            formik.setFieldValue('products_of_company', e.target.value.trimStart())
                          }}
                        >
                          {formik?.values?.products_of_company}
                          {/* At w3schools.com you will learn how to make a website. They offer free tutorials in all web development technologies. */}
                        </textarea>
                        {formik.touched.products_of_company && formik.errors.products_of_company && (
                          <span role='alert' style={{ color: 'red' }}>
                            {formik.errors.products_of_company as any}
                          </span>
                        )}
                      </div>
                    )}
                  </div>
                </div>
                <div className='row mb-5'>
                  <div className='col-md-6'>
                    <h4 className="mb-4">Growth Story Of Business</h4>
                    {!isEdit && (
                      <p className='themes'>
                        {checkDisplayValueProfile(data?.growthstory_of_business)}
                      </p>
                    )}
                    {isEdit && (
                      <div
                        style={{ display: 'flex', flexDirection: 'column' }}
                      >
                        <textarea
                          id='w3review'
                          cols={5}
                          rows={4}
                          className='form-control form-control-sm ilead-input form-control-solid mt-3 w-100'
                          style={{ borderBottom: '1px solid #b5b5b5' }}
                          name='growthstory_of_business'
                          placeholder='Enter Yuor Business Growth And History'
                          onChange={(e: any) => {
                            formik.setFieldTouched('growthstory_of_business', true)
                            formik.setFieldValue(
                              'growthstory_of_business',
                              e.target.value.trimStart()
                            )
                          }}
                        >
                          {formik?.values?.growthstory_of_business}
                          {/* At w3schools.com you will learn how to make a website. They offer free tutorials in all web development technologies. */}
                        </textarea>
                        {formik.touched.growthstory_of_business &&
                          formik.errors.growthstory_of_business && (
                            <span role='alert' style={{ color: 'red' }}>
                              {formik.errors.growthstory_of_business as any}
                            </span>
                          )}
                      </div>
                    )}
                  </div>

                  <div className='col-md-6'>
                    <h4 className="mb-4">Achievements</h4>
                    {!isEdit && (
                      <p className='themes'>{checkDisplayValueProfile(data?.achievements)}</p>
                    )}
                    {isEdit && (
                      <div
                        style={{ display: 'flex', flexDirection: 'column' }}
                      >
                        <textarea
                          id='w3review'
                          cols={5}
                          rows={4}
                          className='form-control form-control-sm ilead-input form-control-solid mt-3 w-100'
                          style={{ borderBottom: '1px solid #b5b5b5' }}
                          name='achievements'
                          placeholder='Enter Your Achievements '
                          onChange={(e: any) => {
                            formik.setFieldTouched('achievements', true)
                            formik.setFieldValue('achievements', e.target.value.trimStart())
                          }}
                        >
                          {formik?.values?.achievements}
                          {/* At w3schools.com you will learn how to make a website. They offer free tutorials in all web development technologies. */}
                        </textarea>
                        {formik.touched.achievements && formik.errors.achievements && (
                          <span role='alert' style={{ color: 'red' }}>
                            {formik.errors.achievements as any}
                          </span>
                        )}
                      </div>
                    )}
                  </div>
                </div>
                <div className='row mb-5'>
                  <div className='col-md-6'>
                    <h4 className="mb-4">Business Challanges</h4>
                    {!isEdit && (
                      <p className='themes'>
                        {checkDisplayValueProfile(data?.business_challanges)}
                      </p>
                    )}
                    {isEdit && (
                      <div
                        style={{ display: 'flex', flexDirection: 'column' }}
                      >
                        <textarea
                          id='w3review'
                          cols={5}
                          rows={4}
                          name='business_challanges'
                          placeholder='Enter Your Challanges'
                          className='form-control form-control-sm ilead-input form-control-solid mt-3 w-100'
                          style={{ borderBottom: '1px solid #b5b5b5' }}
                          onChange={(e: any) => {
                            formik.setFieldTouched('business_challanges', true)
                            formik.setFieldValue('business_challanges', e.target.value.trimStart())
                          }}
                        >
                          {formik?.values?.business_challanges}
                          {/* At w3schools.com you will learn how to make a website. They offer free tutorials in all web development technologies. */}
                        </textarea>
                        {formik.touched.business_challanges && formik.errors.business_challanges && (
                          <span role='alert' style={{ color: 'red' }}>
                            {formik.errors.business_challanges as any}
                          </span>
                        )}
                      </div>
                    )}
                  </div>

                  <div className='col-md-6'>
                    <h4 className="mb-4">Goal For Next 3 To 5 Years</h4>
                    {!isEdit && (
                      <p className='themes'>
                        {checkDisplayValueProfile(data?.goal_for_next_years)}
                      </p>
                    )}
                    {/* <p className='themes'>{checkDisplayValue(data?.goal_for_next_years)}</p> */}
                    {isEdit && (
                      <div
                        style={{ display: 'flex', flexDirection: 'column' }}
                      >
                        <textarea
                          id='w3review'
                          cols={5}
                          rows={4}
                          className='form-control form-control-sm ilead-input form-control-solid mt-3 w-100'
                          style={{ borderBottom: '1px solid #b5b5b5' }}
                          placeholder='Enter Your Next Year Goals'
                          name='goal_for_next_years'
                          onChange={(e: any) => {
                            formik.setFieldTouched('goal_for_next_years', true)
                            formik.setFieldValue('goal_for_next_years', e.target.value.trimStart())
                          }}
                        >
                          {formik?.values?.goal_for_next_years}
                          {/* At w3schools.com you will learn how to make a website. They offer free tutorials in all web development technologies. */}
                        </textarea>
                        {formik.touched.goal_for_next_years && formik.errors.goal_for_next_years && (
                          <span role='alert' style={{ color: 'red' }}>
                            {formik.errors.goal_for_next_years as any}
                          </span>
                        )}
                      </div>
                    )}
                  </div>
                </div>
                <div className='row'>
                  <div className='col-md-6'>
                    <h4 className="mb-4">Membership Enrollment Date</h4>
                    <p className='themes'>
                      {' '}
                      <span>
                        {' '}
                        <i className='fa fa-calendar-days fs-3'></i>{' '}
                      </span>{' '}
                      {data?.membership_enrollment_date
                        ? checkDisplayValueProfile(
                          moment(data?.membership_enrollment_date).format('YYYY-MM-DD')
                        )
                        : '-'}{' '}
                    </p>
                  </div>

                  <div className='col-md-6'>
                    <h4 className="mb-4">Membership Renewal Date</h4>
                    <p className='themes'>
                      {' '}
                      <span>
                        {' '}
                        <i className='fa fa-calendar-days fs-3'></i>{' '}
                      </span>{' '}
                      {data?.membership_renewal_date
                        ? checkDisplayValueProfile(
                          moment(data?.membership_renewal_date).format('YYYY-MM-DD')
                        )
                        : '-'}{' '}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {sendCardListng && (
          <PointListingCardGiven closeCard={setSendCardListng}></PointListingCardGiven>
        )}
        {receiverCard && <PointListingCardSend closeCard={setReceiverCard}></PointListingCardSend>}

        {memberJoinCard && (
          <PointListingCardMemberJoin closeCard={setMemberJoinCard}></PointListingCardMemberJoin>
        )}
        {invitedCard && (
          <PointListingCardInvited closeCard={setInvitedCard}></PointListingCardInvited>
        )}
        <div
          className='modal fade'
          id='exampleModal'
          tabIndex={-1}
          aria-labelledby='exampleModalLabel'
          aria-hidden='true'
        >
          <div className='modal-dialog modal-lg leader-counter-modal ' role='document'>
            <div className='modal-content'>
              <div className='modal-header text-left border-bottom-0 border-radius-0'>
                <h4 className='text-white'>Received Thank You Cards</h4>
                <button type='button' className='close' data-dismiss='modal' aria-label='Close'>
                  <span aria-hidden='true' className='text-white fs-2'>
                    ×
                  </span>
                </button>
              </div>
              <div className='modal-body border-radius-0'>
                <div className='row mb-4'>
                  <div className='col-sm-6 mb-4'>
                    <div className='dataTables_length' id='thankyou_receive_tbl_length'>
                      <label>
                        Show{' '}
                        <select
                          name='thankyou_receive_tbl_length'
                          aria-controls='thankyou_receive_tbl'
                          className=''
                        >
                          <option value='10'>10</option>
                          <option value='25'>25</option>
                          <option value='50'>50</option>
                          <option value='100'>100</option>
                        </select>{' '}
                        entries
                      </label>
                    </div>
                  </div>
                  <div className='col-sm-6 text-md-end text-sm-start mb-4'>
                    <div id='thankyou_receive_tbl_filter' className='dataTables_filter'>
                      <label>
                        {' '}
                        Search: <input name='myInput' />{' '}
                      </label>
                    </div>
                  </div>
                </div>
                <div className='card invitation-history'>
                  <table className='table table-striped'>
                    <thead>
                      <tr>
                        <th scope='col'>Id</th>
                        <th scope='col'>Sender Name</th>
                        <th scope='col'> Reason </th>
                        <th scope='col'> Date </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td data-label='Id'> 123 </td>
                        <td data-label='Sender Name'> Aiyub Panara </td>
                        <td data-label='Reason'> Lorem Ipsum </td>
                        <td data-label='Date'> 15th july 2023 </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <div className='row mt-8'>
                  {' '}
                  <nav aria-label='Page navigation example'>
                    <ul className='pagination justify-content-end'>
                      <li className='page-item disabled'>
                        <a className='page-link' href='#' tabIndex={-1}>
                          Previous
                        </a>
                      </li>
                      <li className='page-item'>
                        <a className='page-link' href='#'>
                          1
                        </a>
                      </li>
                      <li className='page-item'>
                        <a className='page-link' href='#'>
                          2
                        </a>
                      </li>
                      <li className='page-item'>
                        <a className='page-link' href='#'>
                          3
                        </a>
                      </li>
                      <li className='page-item'>
                        <a className='page-link' href='#'>
                          Next
                        </a>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form >
    </>
  )
}

export { LeaderProfile }
