import React, {useContext, useEffect, useState} from 'react'
import {useNavigate, useLocation, Link, Routes, Route, Outlet, Navigate} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
// import { toAbsoluteUrl } from './_metronic/helpers'
import {apiUrl} from '../../../apiurl'
import axios from 'axios'
import {checkDisplayValue} from '../../../utils'
import moment from 'moment'
import * as XLSX from 'xlsx'
import {UserContext} from '../../../contextStore'

const API_URL = apiUrl
const VIEW_FEEDBACK = `${API_URL}/sessionfeedbacks/getsessionfeedback`
const CHAPTER_LIST_URL = `${API_URL}/chapters`
const Date_LIST_URL = `${API_URL}/eventdates`

const ViewFeedback = () => {
  const [data, setData] = useState<any>([])
  const [filter, setFilter] = useState<any>('')
  const [chapter, setChapter] = useState<any>('')
  const [event, setEvent] = useState<any>('')
  const [chapterList, setChapterList] = useState<any>([])
  const [dateList, setDateList] = useState<any>([])
  const {userDetail} = useContext(UserContext)
  const todays_date = new Date().toISOString().split('T')[0]
  const [date, setDate] = useState<any>('')

  useEffect(() => {
    fetchData()
  }, [filter, chapter, event, date])

  useEffect(() => {
    fetchChaptersList()
    fetchDateList1()
  }, [])

  useEffect(() => {
    fetchDateList()
  }, [chapter])

  const fetchChaptersList = async () => {
    try {
      const res = await axios.get(`${CHAPTER_LIST_URL}?filter[where][isdelete]=0`)

      setChapterList(res?.data ?? [])
    } catch (error) {
      console.error('Error -->:', error)
    }
  }

  const fetchDateList = async () => {
    try {
      const res = await axios.get(
        `${Date_LIST_URL}?filter[order]=event_date DESC&filter[limit]=4&filter[where][event_date][lte]=${todays_date}&filter[where][chapter_id]=${chapter}`
      )

      setDateList(res?.data ?? [])
    } catch (error) {
      console.error('Error -->:', error)
    }
  }

  const fetchDateList1 = async () => {
    try {
      const res = await axios.get(
        `${Date_LIST_URL}?filter[order]=event_date DESC&filter[limit]=1&filter[where][event_date][lte]=${todays_date}&filter[where][chapter_id]=${userDetail?.chapter_id}`
      )
      console.log('dkjhasdfkhkds', res?.data ?? [])
      setDate(res?.data[0]?.event_date ? moment(res?.data[0]?.event_date).format('YYYY-MM-DD') : '')
    } catch (error) {
      console.error('Error -->:', error)
    }
  }

  const fetchData = async () => {
    try {
      const params = new URLSearchParams()

      if (filter) {
        params.append('filter_data', filter)
      }
      if (chapter) {
        params.append('filter_chapter_id', chapter)
      }
      if (event) {
        params.append('filter_event_date', event)
      }

      const url = `${VIEW_FEEDBACK}${params.toString() ? `?${params.toString()}` : ''}`

      const res = await axios.get(url)
      setData(res?.data ?? [])
    } catch (error) {
      console.error('Error -->:', error)
    }
  }

  function ExportToExcel() {
    try {
      let table = document.getElementById('copy_kt_table_feedback') as any

      // Make the table briefly visible
      table.style.display = 'table'

      // Extract Data (create a workbook object from the table)
      var workbook = XLSX.utils.table_to_book(table)

      // Hide the table again (if it was originally hidden)
      table.style.display = 'none'

      // Process Data (add a new row)
      var ws = workbook.Sheets['Sheet1']
      // XLSX.utils.sheet_add_aoa(ws, [["Created "+new Date().toISOString()]], {origin:-1});

      // Package and Release Data (`writeFile` tries to write and save an XLSB file)
      XLSX.writeFile(workbook, 'Session_Feedback.xlsx')
    } catch (err) {
      // Get a reference to the table
      let table = document.getElementById('copy_kt_table_feedback') as any

      // Hide the table again (if it was originally hidden)
      table.style.display = 'none'
    }
  }
  console.log(data)

  return (
    <>
      <table id='copy_kt_table_feedback' style={{display: 'none'}}>
        <thead>
          <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0 border-bottom border-gray-100'>
            <th>Event Date</th>
            <th>Leader Name</th>
            <th>Company</th>
            <th>V.A Rating</th>
            <th>Tyca Rating</th>
            <th>S.S Rating</th>
            <th>Comment</th>
          </tr>
        </thead>
        <tbody>
          {data?.feedback?.map((row: any, i: any) => {
            return (
              <tr key={i}>
                <td>
                  {row?.event_date
                    ? moment(row?.event_date).format('DD-MM-YYYY')
                    : checkDisplayValue(row?.event_date)}
                </td>
                <td>{checkDisplayValue(row?.user_name)}</td>
                <td>{checkDisplayValue(row?.companyname)}</td>
                <td>{checkDisplayValue(row?.rating)}</td>
                <td>{checkDisplayValue(row?.tycarating)}</td>
                <td>{checkDisplayValue(row?.ssrating)}</td>
                <td>{checkDisplayValue(row?.comments)}</td>
              </tr>
            )
          })}
        </tbody>
      </table>
      <div className='row flex-md-block '>
        <div className='col-sm-12 col-md-6 col-lg-6'>
          <h1 className='inv-hst sm-ms-2'> View Feedback </h1>
          <div></div>
        </div>

        <div className='col-sm-12 col-md-6 col-lg-6 text-end pe-5'>
          <Link to='/session-feedback' className='btn btn-info waves-effect waves-light '>
            {' '}
            Back{' '}
          </Link>
        </div>
      </div>

      <div className='card  p-3  p-sm-8  mt-5'>
        <div className='row m-0'>
          <div className='col-md-6 mb-3'>
            <label className='fw-semibold fs-5 mb-5'> </label>
            <div className='form-group floating-labels'>
              <select
                className='form-select border-left-0 border-right-0 border-top-0 border-radius-0'
                aria-label='Default select example'
                onChange={(e) => setChapter(e.target.value)}
              >
                <option value=''> Select Chapter </option>
                {chapterList?.map((i: any) => (
                  <>
                    <option value={i?.id}> {i?.name} </option>
                  </>
                ))}
              </select>
            </div>
          </div>
          <div className='col-md-6 mb-5'>
            <label className='fw-semibold fs-4 ps-3 text-primary'> Select Event Date </label>
            <div className='search-group m-b-30'>
              <select
                className='form-select border-left-0 border-right-0 border-top-0'
                aria-label='Default select example'
                onChange={(e) => {
                  setEvent(e.target.value ? moment(e.target.value).format('YYYY-MM-DD') : '')
                  setDate('')
                }}
              >
                <option value=''> All </option>
                {dateList?.map((i: any) => (
                  <>
                    <option value={i?.event_date}>
                      {' '}
                      {checkDisplayValue(moment(i?.event_date).format('MMM DD, YYYY'))}{' '}
                    </option>
                  </>
                ))}
              </select>
              <button type='button'></button>
            </div>
          </div>
        </div>
      </div>

      <div className='row mt-8'>
        <div className='col-12 viw-feedback mob-view-pl-pr '>
          <ul className='nav nav-pills mb-3' id='pills-tab' role='tablist'>
            <li className='nav-item me-0' role='presentation'>
              <button
                className='nav-link active me-0'
                id='pills-home-tab'
                data-bs-toggle='pill'
                data-bs-target='#pills-home'
                type='button'
                role='tab'
                aria-controls='pills-home'
                aria-selected='true'
              >
                Who Given Feedback
              </button>
            </li>
            <li className='nav-item' role='presentation'>
              <button
                className='nav-link'
                id='pills-profile-tab'
                data-bs-toggle='pill'
                data-bs-target='#pills-profile'
                type='button'
                role='tab'
                aria-controls='pills-profile'
                aria-selected='false'
              >
                Who didn't Given Feedback.
              </button>
            </li>
          </ul>
        </div>
      </div>

      <div className='mt-6'>
        <div className='d-flex flex-column flex-md-row justify-content-between mb-5'>
          <div className='mb-5'>
            <label className='fw-semibold fs-4 mb-5 text-primary'> Filter By Rating </label>

            <div className='d-flex top__filter'>
              <div className='form-group floating-labels me-3'>
                <select
                  className='form-select border-left-0 border-right-0 rounded-0 border-top-0'
                  aria-label='Default select example'
                  onChange={(e) => {
                    setFilter(e.target.value)
                  }}
                >
                  <option selected> All </option>
                  <option value='bad'>bad</option>
                  <option value='poor'>poor</option>
                  <option value='average'>average</option>
                  <option value='good'>good</option>
                  <option value='excellent'>excellent</option>
                </select>
              </div>
            </div>
          </div>

          <div className='text-end'>
            <button
              className='btn btn-info waves-effect waves-light '
              onClick={() => {
                ExportToExcel()
              }}
            >
              <span>
                {' '}
                <i className='fa-solid fa-download'></i>{' '}
              </span>{' '}
              Export
            </button>
          </div>
        </div>

        <div className='col-12 viw-feedback mob-view-pl-pr '>
          <div className='tab-content mt-3 feedback-detail' id='pills-tabContent'>
            <div
              className='tab-pane fade show active'
              id='pills-home'
              role='tabpanel'
              aria-labelledby='pills-home-tab'
              tabIndex={0}
            >
              <div className='row mx-0 px-3 py-3 py-sm-8  card'>
                <div className='row mx-0'>
                  <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-3'>
                    <div className='border position-relative overflow-hidden border-gray-300 d-flex flex-column justify-content-center p-8'>
                      <div className='orange-strip'> Average Rating </div>
                      <div className='text-center mb-3'>
                        {data?.averageva <= 1 ? (
                          <i className='fa-regular fa-face-frown' style={{fontSize: '50px'}}></i>
                        ) : (
                          ''
                        )}
                        {data?.averageva == 2 ? (
                          <i
                            className='fa-regular fa-face-frown-open'
                            style={{
                              fontSize: '50px',
                              boxShadow: 'rgba(227,55,30, 0.5) 0 0 10px',
                              borderRadius: '50%',
                            }}
                          ></i>
                        ) : (
                          ''
                        )}
                        {data?.averageva == 3 ? (
                          <i
                            className='fa-regular fa-face-meh'
                            style={{
                              fontSize: '50px',
                              boxShadow: 'rgba(227,55,30, 0.5) 0 0 20px',
                              borderRadius: '50%',
                            }}
                          ></i>
                        ) : (
                          ''
                        )}
                        {data?.averageva == 4 ? (
                          <i
                            className='fa-regular fa-face-smile'
                            style={{
                              fontSize: '50px',
                              boxShadow: 'rgba(227,55,30, 0.5) 0 0 30px',
                              borderRadius: '50%',
                            }}
                          ></i>
                        ) : (
                          ''
                        )}
                        {data?.averageva == 5 ? (
                          <i
                            className='fa-regular fa-face-smile-beam'
                            style={{
                              fontSize: '50px',
                              boxShadow: 'rgba(227,55,30, 0.5) 0 0 40px',
                              borderRadius: '50%',
                            }}
                          ></i>
                        ) : (
                          ''
                        )}
                      </div>
                      <div className='text-center mb-2'>
                        {Array.from({length: 5}, (_, index) => index + 1).map((x) => (
                          <span
                            className={`fa fa-star ${x <= data?.averageva ? 'checked' : ''}`}
                          ></span>
                        ))}
                      </div>
                      <div className='fs-5 fw-bold text-center '>
                        Value Addition:{data?.averageva}
                      </div>
                    </div>
                  </div>

                  <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-3'>
                    <div className='border position-relative overflow-hidden border-gray-300 d-flex flex-column justify-content-center p-8'>
                      <div className='orange-strip'> Average Rating </div>
                      <div className='text-center mb-3'>
                        {data?.averagetyca <= 1 ? (
                          <i className='fa-regular fa-face-frown' style={{fontSize: '50px'}}></i>
                        ) : (
                          ''
                        )}
                        {data?.averagetyca == 2 ? (
                          <i
                            className='fa-regular fa-face-frown-open'
                            style={{
                              fontSize: '50px',
                              boxShadow: 'rgba(227,55,30, 0.5) 0 0 10px',
                              borderRadius: '50%',
                            }}
                          ></i>
                        ) : (
                          ''
                        )}
                        {data?.averagetyca == 3 ? (
                          <i
                            className='fa-regular fa-face-meh'
                            style={{
                              fontSize: '50px',
                              boxShadow: 'rgba(227,55,30, 0.5) 0 0 20px',
                              borderRadius: '50%',
                            }}
                          ></i>
                        ) : (
                          ''
                        )}
                        {data?.averagetyca == 4 ? (
                          <i
                            className='fa-regular fa-face-smile'
                            style={{
                              fontSize: '50px',
                              boxShadow: 'rgba(227,55,30, 0.5) 0 0 30px',
                              borderRadius: '50%',
                            }}
                          ></i>
                        ) : (
                          ''
                        )}
                        {data?.averagetyca == 5 ? (
                          <i
                            className='fa-regular fa-face-smile-beam'
                            style={{
                              fontSize: '50px',
                              boxShadow: 'rgba(227,55,30, 0.5) 0 0 40px',
                              borderRadius: '50%',
                            }}
                          ></i>
                        ) : (
                          ''
                        )}
                      </div>
                      <div className='text-center mb-2'>
                        {Array.from({length: 5}, (_, index) => index + 1).map((x) => (
                          <span
                            className={`fa fa-star ${x <= data?.averagetyca ? 'checked' : ''}`}
                          ></span>
                        ))}
                      </div>
                      <div className='fs-5 fw-bold text-center '>Tyca:{data?.averagetyca}</div>
                    </div>
                  </div>

                  <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-3'>
                    <div className='border position-relative overflow-hidden border-gray-300 d-flex flex-column justify-content-center p-8'>
                      <div className='orange-strip'> Average Rating </div>
                      <div className='text-center mb-3'>
                        {data?.averagess <= 1 ? (
                          <i className='fa-regular fa-face-frown' style={{fontSize: '50px'}}></i>
                        ) : (
                          ''
                        )}
                        {data?.averagess == 2 ? (
                          <i
                            className='fa-regular fa-face-frown-open'
                            style={{
                              fontSize: '50px',
                              boxShadow: 'rgba(227,55,30, 0.5) 0 0 10px',
                              borderRadius: '50%',
                            }}
                          ></i>
                        ) : (
                          ''
                        )}
                        {data?.averagess == 3 ? (
                          <i
                            className='fa-regular fa-face-meh'
                            style={{
                              fontSize: '50px',
                              boxShadow: 'rgba(227,55,30, 0.5) 0 0 20px',
                              borderRadius: '50%',
                            }}
                          ></i>
                        ) : (
                          ''
                        )}
                        {data?.averagess == 4 ? (
                          <i
                            className='fa-regular fa-face-smile'
                            style={{
                              fontSize: '50px',
                              boxShadow: 'rgba(227,55,30, 0.5) 0 0 30px',
                              borderRadius: '50%',
                            }}
                          ></i>
                        ) : (
                          ''
                        )}
                        {data?.averagess == 5 ? (
                          <i
                            className='fa-regular fa-face-smile-beam'
                            style={{
                              fontSize: '50px',
                              boxShadow: 'rgba(227,55,30, 0.5) 0 0 40px',
                              borderRadius: '50%',
                            }}
                          ></i>
                        ) : (
                          ''
                        )}
                      </div>
                      <div className='text-center mb-2'>
                        {Array.from({length: 5}, (_, index) => index + 1).map((x) => (
                          <span
                            className={`fa fa-star ${x <= data?.averagess ? 'checked' : ''}`}
                          ></span>
                        ))}
                      </div>
                      <div className='fs-5 fw-bold text-center '>
                        Success Sharing:{data?.averagess}
                      </div>
                    </div>
                  </div>

                  <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-3'>
                    <div className='border position-relative overflow-hidden border-gray-300 d-flex flex-column justify-content-center p-8'>
                      <div className='orange-strip'> Average Rating </div>
                      <div className='text-center mb-3'>
                        {data?.averagesrAr <= 1 ? (
                          <i className='fa-regular fa-face-frown' style={{fontSize: '50px'}}></i>
                        ) : (
                          ''
                        )}
                        {data?.averagesrAr == 2 ? (
                          <i
                            className='fa-regular fa-face-frown-open'
                            style={{
                              fontSize: '50px',
                              boxShadow: 'rgba(227,55,30, 0.5) 0 0 10px',
                              borderRadius: '50%',
                            }}
                          ></i>
                        ) : (
                          ''
                        )}
                        {data?.averagesrAr == 3 ? (
                          <i
                            className='fa-regular fa-face-meh'
                            style={{
                              fontSize: '50px',
                              boxShadow: 'rgba(227,55,30, 0.5) 0 0 20px',
                              borderRadius: '50%',
                            }}
                          ></i>
                        ) : (
                          ''
                        )}
                        {data?.averagesrAr == 4 ? (
                          <i
                            className='fa-regular fa-face-smile'
                            style={{
                              fontSize: '50px',
                              boxShadow: 'rgba(227,55,30, 0.5) 0 0 30px',
                              borderRadius: '50%',
                            }}
                          ></i>
                        ) : (
                          ''
                        )}
                        {data?.averagesrAr == 5 ? (
                          <i
                            className='fa-regular fa-face-smile-beam'
                            style={{
                              fontSize: '50px',
                              boxShadow: 'rgba(227,55,30, 0.5) 0 0 40px',
                              borderRadius: '50%',
                            }}
                          ></i>
                        ) : (
                          ''
                        )}
                      </div>
                      <div className='text-center mb-2'>
                        {Array.from({length: 5}, (_, index) => index + 1).map((x) => (
                          <span
                            className={`fa fa-star ${x <= data?.averagesrAr ? 'checked' : ''}`}
                          ></span>
                        ))}
                      </div>
                      <div className='fs-5 fw-bold text-center '>
                        Success Rating:{data?.averagesrAr}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {data?.feedback?.map((item: any) => (
                <div className='row mx-0 mt-8 px-3 py-3 py-sm-8  card'>
                  <div className='row mx-0 mt-8'>
                    <div className='col-sm-12 col-md-12 mb-5 fw-bold fs-4'>
                      {' '}
                      <span>
                        {' '}
                        <i className='fa-solid fa-calendar-days fs-4 text-dark me-2'></i>{' '}
                      </span>{' '}
                      Session Date:{' '}
                      {item?.event_date
                        ? moment(checkDisplayValue(item?.event_date)).format('DD-MM-YYYY')
                        : '-'}{' '}
                    </div>
                    <div className='row border-bottom border-gray-300 px-0 pb-6 m-0'>
                      <div className='col-sm-12 col-md-6 col-lg-4 col-xl-4 mb-3'>
                        <div className='border position-relative overflow-hidden border-gray-300 d-flex flex-column justify-content-center p-2'>
                          <div className='fs-6 fw-bold text-center mb-3'>
                            Value Addition Session Rating
                          </div>
                          <div className='text-center mb-3'>
                            {item?.rating <= 1 ? (
                              <i
                                className='fa-regular fa-face-frown'
                                style={{fontSize: '50px'}}
                              ></i>
                            ) : (
                              ''
                            )}
                            {item?.rating == 2 ? (
                              <i
                                className='fa-regular fa-face-frown-open'
                                style={{
                                  fontSize: '50px',
                                  boxShadow: 'rgba(227,55,30, 0.5) 0 0 10px',
                                  borderRadius: '50%',
                                }}
                              ></i>
                            ) : (
                              ''
                            )}
                            {item?.rating == 3 ? (
                              <i
                                className='fa-regular fa-face-meh'
                                style={{
                                  fontSize: '50px',
                                  boxShadow: 'rgba(227,55,30, 0.5) 0 0 20px',
                                  borderRadius: '50%',
                                }}
                              ></i>
                            ) : (
                              ''
                            )}
                            {item?.rating == 4 ? (
                              <i
                                className='fa-regular fa-face-smile'
                                style={{
                                  fontSize: '50px',
                                  boxShadow: 'rgba(227,55,30, 0.5) 0 0 30px',
                                  borderRadius: '50%',
                                }}
                              ></i>
                            ) : (
                              ''
                            )}
                            {item?.rating == 5 ? (
                              <i
                                className='fa-regular fa-face-smile-beam'
                                style={{
                                  fontSize: '50px',
                                  boxShadow: 'rgba(227,55,30, 0.5) 0 0 40px',
                                  borderRadius: '50%',
                                }}
                              ></i>
                            ) : (
                              ''
                            )}
                          </div>
                          <div className='text-center mb-2'>
                            {Array.from({length: 5}, (_, index) => index + 1).map((x) => (
                              <span
                                className={`fa fa-star ${x <= item?.rating ? 'checked' : ''}`}
                              ></span>
                            ))}
                          </div>
                        </div>
                      </div>

                      <div className='col-sm-12 col-md-6 col-lg-4 col-xl-4 mb-3'>
                        <div className='border position-relative overflow-hidden border-gray-300 d-flex flex-column justify-content-center p-2'>
                          <div className='fs-6 fw-bold text-center mb-3'>TYCA Session Rating</div>
                          <div className='text-center mb-3'>
                            {item?.tycarating <= 1 ? (
                              <i
                                className='fa-regular fa-face-frown'
                                style={{fontSize: '50px'}}
                              ></i>
                            ) : (
                              ''
                            )}
                            {item?.tycarating == 2 ? (
                              <i
                                className='fa-regular fa-face-frown-open'
                                style={{
                                  fontSize: '50px',
                                  boxShadow: 'rgba(227,55,30, 0.5) 0 0 10px',
                                  borderRadius: '50%',
                                }}
                              ></i>
                            ) : (
                              ''
                            )}
                            {item?.tycarating == 3 ? (
                              <i
                                className='fa-regular fa-face-meh'
                                style={{
                                  fontSize: '50px',
                                  boxShadow: 'rgba(227,55,30, 0.5) 0 0 20px',
                                  borderRadius: '50%',
                                }}
                              ></i>
                            ) : (
                              ''
                            )}
                            {item?.tycarating == 4 ? (
                              <i
                                className='fa-regular fa-face-smile'
                                style={{
                                  fontSize: '50px',
                                  boxShadow: 'rgba(227,55,30, 0.5) 0 0 30px',
                                  borderRadius: '50%',
                                }}
                              ></i>
                            ) : (
                              ''
                            )}
                            {item?.tycarating == 5 ? (
                              <i
                                className='fa-regular fa-face-smile-beam'
                                style={{
                                  fontSize: '50px',
                                  boxShadow: 'rgba(227,55,30, 0.5) 0 0 40px',
                                  borderRadius: '50%',
                                }}
                              ></i>
                            ) : (
                              ''
                            )}
                          </div>
                          <div className='text-center mb-2'>
                            {Array.from({length: 5}, (_, index) => index + 1).map((x) => (
                              <span
                                className={`fa fa-star ${x <= item?.tycarating ? 'checked' : ''}`}
                              ></span>
                            ))}
                          </div>
                        </div>
                      </div>

                      <div className='col-sm-12 col-md-6 col-lg-4 col-xl-4 mb-3'>
                        <div className='border position-relative overflow-hidden border-gray-300 d-flex flex-column justify-content-center p-2'>
                          <div className='fs-6 fw-bold text-center mb-3'>
                            Success Sharing Session Rating
                          </div>
                          <div className='text-center mb-3'>
                            {item?.ssrating <= 1 ? (
                              <i
                                className='fa-regular fa-face-frown'
                                style={{fontSize: '50px'}}
                              ></i>
                            ) : (
                              ''
                            )}
                            {item?.ssrating == 2 ? (
                              <i
                                className='fa-regular fa-face-frown-open'
                                style={{
                                  fontSize: '50px',
                                  boxShadow: 'rgba(227,55,30, 0.5) 0 0 10px',
                                  borderRadius: '50%',
                                }}
                              ></i>
                            ) : (
                              ''
                            )}
                            {item?.ssrating == 3 ? (
                              <i
                                className='fa-regular fa-face-meh'
                                style={{
                                  fontSize: '50px',
                                  boxShadow: 'rgba(227,55,30, 0.5) 0 0 20px',
                                  borderRadius: '50%',
                                }}
                              ></i>
                            ) : (
                              ''
                            )}
                            {item?.ssrating == 4 ? (
                              <i
                                className='fa-regular fa-face-smile'
                                style={{
                                  fontSize: '50px',
                                  boxShadow: 'rgba(227,55,30, 0.5) 0 0 30px',
                                  borderRadius: '50%',
                                }}
                              ></i>
                            ) : (
                              ''
                            )}
                            {item?.ssrating == 5 ? (
                              <i
                                className='fa-regular fa-face-smile-beam'
                                style={{
                                  fontSize: '50px',
                                  boxShadow: 'rgba(227,55,30, 0.5) 0 0 40px',
                                  borderRadius: '50%',
                                }}
                              ></i>
                            ) : (
                              ''
                            )}
                          </div>
                          <div className='text-center mb-2'>
                            {Array.from({length: 5}, (_, index) => index + 1).map((x) => (
                              <span
                                className={`fa fa-star ${x <= item?.ssrating ? 'checked' : ''}`}
                              ></span>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className='row mt-8'>
                      <div className='col-md-12 pb-6 '>
                        <h6 className='text-primary fw-bold '>Comment:</h6>
                        <h2 className='text-dark fw-bold '>{checkDisplayValue(item?.comments)}</h2>
                      </div>
                    </div>
                  </div>
                  <div className='row mx-0 mt-8 px-3 py-3 py-sm-8'>
                    <div className='row mt-6'>
                      <div className='col-md-12 mb-3'>
                        <h6 className='text-primary fw-bold fs-4 mb-3'>Feedback By:</h6>
                        <div className='row m-0'>
                          <div className='fdbk-user-icon'>
                            <Link to={`/leaders/${item.user_id}`}>
                              {' '}
                              <img
                                src={
                                  item?.profile
                                    ? `${API_URL}/containers/userprofile/download/${item?.profile}`
                                    : `${toAbsoluteUrl('./media/avatars/default-user.png')}`
                                }
                                onError={({currentTarget}: any) => {
                                  currentTarget.onerror = null // prevents looping
                                  currentTarget.src = `${toAbsoluteUrl(
                                    './media/avatars/default-user.png'
                                  )}`
                                }}
                                alt=''
                                className=''
                              />{' '}
                            </Link>
                          </div>

                          <div className='col-md-9 user-detail'>
                            <Link to={`/leaders/${item.user_id}`}>
                              <div className='fs-4 text-dark fw-bold px-3'>
                                {' '}
                                {checkDisplayValue(item?.user_name)}{' '}
                              </div>
                            </Link>
                            <div className='d-flex flex-lg-wrap flex-wrap ms-0 me-0 mt-3'>
                              <div className='px-3 '>
                                {' '}
                                <Link to='#' className='text-dark fw-bold fs-6'>
                                  {' '}
                                  {checkDisplayValue(item?.companyname)}{' '}
                                </Link>
                              </div>
                              <div className='text-dark fw-bold fs-6'>
                                {' '}
                                <span>
                                  {' '}
                                  <i className='fa-solid fa-phone'></i>{' '}
                                </span>{' '}
                                +91 {checkDisplayValue(item?.mobileno)}{' '}
                              </div>
                              <div className='px-3 '>
                                {' '}
                                <span className='float-left me-2 '>
                                  {' '}
                                  <i className='fa-solid fa-envelope-open'></i>{' '}
                                </span>
                                <strong className=''>
                                  <a href={`mailto:${item?.email}`} className='text-black'>
                                    {checkDisplayValue(item?.email)}
                                  </a>{' '}
                                </strong>{' '}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>

            <div
              className='tab-pane fade card p-3  p-sm-8  mt-5'
              id='pills-profile'
              role='tabpanel'
              aria-labelledby='pills-profile-tab'
              tabIndex={0}
            >
              <div className='row m-0'>
                {data?.thosewhonotgivenfeedback?.map((item: any) => (
                  <div className='col-md-6 col-lg-6 col-xl-4 col-sm-12 mb-3'>
                    <Link to={`/leaders/${item.id}`}>
                      <div className='row m-0 p-5 border border-gray-300 rounded-1'>
                        <div className='fdbk-user-icon'>
                          {' '}
                          <img
                            src={
                              item?.profile
                                ? `${API_URL}/containers/userprofile/download/${item?.profile}`
                                : `${toAbsoluteUrl('./media/avatars/default-user.png')}`
                            }
                            onError={({currentTarget}: any) => {
                              currentTarget.onerror = null // prevents looping
                              currentTarget.src = `${toAbsoluteUrl(
                                './media/avatars/default-user.png'
                              )}`
                            }}
                            alt=''
                            className=''
                          />{' '}
                        </div>
                        <div className='col-md-9 user-detail'>
                          <div className='fs-4 text-dark fw-bold px-3'>
                            {' '}
                            {checkDisplayValue(item?.name)}{' '}
                          </div>
                          <div className='px-3'> {checkDisplayValue(item?.companyname)} </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ViewFeedback
