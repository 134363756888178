import { useMemo, useState, useEffect, useContext } from 'react'
import { useTable, ColumnInstance, Row } from 'react-table'
import { CustomHeaderColumn } from './columns/CustomHeaderColumn'
import { CustomRow } from './columns/CustomRow'
import { useQueryResponseData, useQueryResponseLoading } from '../core/QueryResponseProvider'
import { eventsColumns, tempColumns } from './columns/_columns'
import { Event } from '../core/_models'
import { checkDisplayValue } from '../../../../../utils'
// import {KTCardBody} from '../../../../../../_metronic/helpers'

const CopyEventsTable = () => {

  const events = useQueryResponseData()
  // const events = defaultEvents
  const isLoading = useQueryResponseLoading()
  const data = useMemo(() => events, [events])

  const columns = useMemo(() => tempColumns, [])
  const { getTableProps, getTableBodyProps, headers, rows, prepareRow } = useTable({
    columns,
    data,
  })

  return (
    <table
      id='copy_kt_table_events'
      className='table align-middle table-row-dashed fs-6 gy-5 dataTable table-striped  no-footer'
      {...getTableProps()}
      style={{ display: 'none' }}
    >
      <thead>
        <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0 border-bottom border-gray-100'>
          {headers.map((column: ColumnInstance<Event>) => (
            <CustomHeaderColumn key={column.id} column={column} />
          ))}
        </tr>
      </thead>

      <tbody className='text-gray-600 fw-bold text-start' {...getTableBodyProps()}>
        {data.length > 0 ? (
          data.map((row: any, i: any) => {
            return <tr key={i}>
              <td>
                {checkDisplayValue(row?.event_name)}
              </td>
              <td>
                {checkDisplayValue(row?.event_date)}
              </td>
              <td>
                {checkDisplayValue(row?.chapter)}
              </td>
              <td>
                {checkDisplayValue(row?.venue)}
              </td>
              <td>
                {checkDisplayValue(row?.address)}
              </td>
            </tr>
          })
        ) : (
          <tr>
            <td colSpan={7}>
              <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                No matching records found
              </div>
            </td>
          </tr>
        )}
      </tbody>
    </table>
  )
}

export { CopyEventsTable }
// export default CopyEventsTable
