import React, {useContext, useEffect, useRef, useState} from 'react'
import {Button, Form, Image, InputGroup} from 'react-bootstrap'
import TextareaAutosize from 'react-textarea-autosize'
import {UserContext} from '../../../../../../contextStore'
import {toAbsoluteUrl} from '../../../../../../_metronic/helpers'
import {
  addThankyouHistory,
  addUserTopic,
  listingAllChapter,
  listingAllLeaderUser,
  sendSmsAndNotification,
  singleLeaderUser,
} from '../_requests'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {apiUrl} from '../../../../../../apiurl'
import moment from 'moment'
const API_URL = apiUrl

export function SayThankYou() {
  const [businessReferralStepOne, setbusinessReferralStepOne] = useState(true)
  const MAX_CHARACTERS = 2000
  const {setMessage, dashBoardReload} = useContext(UserContext)
  const [chapterData, setChapterData] = useState<any>([])
  const [leaderData, setLeaderData] = useState<any>([])
  const [messageCount, setMessageCount] = useState(MAX_CHARACTERS)
  const [id, setId] = useState<any>('')
  const [singleLeaderData, setSingleLeaderData] = useState({name: '', id: ''})
  const [isLoading, setIsLoading] = useState(true)
  const {userDetail} = useContext(UserContext)
  const userid = userDetail?.id ?? ''
  const [chapterId, setChapterId] = useState<any>('')
  const [leaderName, setLeaderName] = useState<any>('')
  const [originalData, setOriginalData] = useState<any>([])
 
  const closeButton = useRef<any>()
  const [userTopicId, setUserTopicID] = useState<any>()
  // const date = new Date()
  const currentDate = moment().format('DD-MM-YYYY')
  const newDate = moment()
  useEffect(() => {
    // Define an async function to fetch the data and update state
    const fetchData = async () => {
      // Fetch chapter data
      try {
        const chapterResponseData = await listingAllChapter()
        setChapterData((prevData: any) => [...(chapterResponseData ?? [])])
      } catch (error) {
        console.error('Error fetching chapter data:', error)
      }
      // listing for leaders
      try {
        const leaderResponseData = await listingAllLeaderUser(chapterId, leaderName, userid)
        setOriginalData((prevData: any) => [...(leaderResponseData ?? [])])
      } catch (error) {
        console.error('Error fetching leader data:', error)
      }
      setIsLoading(false)
    }

    fetchData()
  }, [chapterId, leaderName])

  useEffect(() => {
    filterData()
  }, [chapterId, leaderName, originalData])

  const filterData = () => {
    const filteredData = originalData.filter((item: any) => {
      // Filter based on chapterId if it's selected
      const matchesChapter = chapterId === '' || item.chapter_id.toString() === chapterId

      // Filter based on leaderName if it's provided
      const nameRegex = new RegExp(leaderName, 'i') // Case-insensitive search
      const matchesName = leaderName === '' || nameRegex.test(item.name)

      return matchesChapter && matchesName
    })

    setLeaderData(filteredData) // Update the filtered data state
  }

  const handleDescriptionChange = (event: any) => {
    const currentLength = event.target.value.length
    const remainingCharacters = MAX_CHARACTERS - currentLength
    setMessageCount(remainingCharacters)
    formik.handleChange(event)
  }
  const fetchSingleLeaderData = async (leaderId: any) => {
    try {
      const singleLeaderResponseData = await singleLeaderUser(leaderId)
      setSingleLeaderData(singleLeaderResponseData ?? [])
    } catch (error) {
      console.error('Error fetching single leader data:', error)
    }
  }

  const initialValue = {
    descriptiontext: '',
  }
  // validation
  const validationSchema = Yup.object().shape({
    descriptiontext: Yup.string().required('Comment is required'),
  })

  const formik = useFormik({
    initialValues: initialValue,
    validationSchema: validationSchema,
    onSubmit: async (values, {resetForm}) => {
      setIsLoading(true)
      try {
        const postUserTopicData = {
          descriptiontext: values.descriptiontext,
          isactive: 1,
          isdelete: 0,
          created_by: userDetail.id,
          user_id: userDetail.id,
          chapter_id: id,
          topic_id: 7,
          thankyou_receive_userid: singleLeaderData.id,
          thankyou_send_userid: userDetail.id,
        }
        const postThankyouHistoryData = {
          send_by: userDetail.id,
          receive_by: singleLeaderData.id,
          chat_id: 0,
          reason: values.descriptiontext,
          send_date: newDate,
          isactive: 1,
          isdelete: 0,
          created_by: userDetail.idy,
        }
        // user topic API call
        await addUserTopic(postUserTopicData).then(async (res: any) => {
          if (res) {
            setUserTopicID(res?.id)

            // thankyou history API call
            await addThankyouHistory(postThankyouHistoryData)
              .then((response: any) => {
                if (response) {
                  console.log('thank you========>', response.id)
                  setMessage('success', 'Thank you post is created')

                  // send SMS API call
                  sendSmsAndNotification({
                    thankyouhistoryid: response?.id ?? '',
                    user_topic_id: res?.id ?? '',
                    userid: userDetail?.id ?? '',
                    accesstoken: localStorage.getItem('authToken'),
                  })
                  setMessageCount(MAX_CHARACTERS)
                  setLeaderName('')
                  setId('')
                  setbusinessReferralStepOne(true)
                  resetForm()
                 
                  dashBoardReload()
                  setIsLoading(false)
                }
              })
              .catch((error: any) => {
                if (error?.response?.data?.error?.message) {
                  setMessage('error', error?.response?.data?.error?.message)
                } else {
                  setMessage('error', 'Something Went Wrong')
                }
                setIsLoading(false)
              })
          }
        })
      } catch (error) {
        setIsLoading(false)
        setMessage('error', 'Something went wrong')
      }
    },
  })

  return (
    <>
      <div
        className='modal fade ilead-default-modal'
        id='SayThankYouModal'
        aria-hidden='true'
        aria-labelledby='SayThankYouModalLabel'
      >
        <div className='modal-dialog modal-dialog modal-dialog-scrollable modal-fullscreen-md-down modal-md'>
          <div className='modal-content'>
            <div className='modal-header py-5'>
              <h3 className='modal-title fs-3 fw-bold' id='SayThankYouModalToggleLabel'>
                Say Thank You
              </h3>
              <button
                type='button'
                className='btn-close text-white'
                data-bs-target='#write_post_modal'
                data-bs-toggle='modal'
                data-bs-dismiss='modal'
                onClick={() => {
                  setbusinessReferralStepOne(true)
                  formik.setFieldValue('descriptiontext', '')
                  setMessageCount(MAX_CHARACTERS)
                  setId('')
                  setLeaderName('')
                  formik.resetForm()
                }}
                ref={closeButton}
              ></button>
            </div>
            <div className='modal-body pt-7 write-post-wrapper'>
              {businessReferralStepOne ? (
                <>
                  <h3 className='text-black fw-bold fs-4 mb-6'>Search Leader</h3>
                  <Form.Group className='mb-7'>
                    <Form.Select
                      aria-label='Default select example'
                      className='ilead-input'
                      value={chapterId}
                      onChange={(e) => setChapterId(e.target.value)}
                    >
                      <option value=''>Select Chapter</option>
                      {chapterData.map((chapter: any) => (
                        <option key={chapter.id} value={chapter.id}>
                          {chapter.name}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                  <Form.Group className='mb-3'>
                    <Form.Label>Search Leader</Form.Label>
                    <InputGroup>
                      <InputGroup.Text id='SearchLeader' className='bg-white ilead-input-group'>
                        <i className='fa-solid fa-magnifying-glass'></i>
                      </InputGroup.Text>
                      <Form.Control
                        placeholder='Type leader name'
                        aria-label='LeaderSearch'
                        aria-describedby='LeaderSearch'
                        className='border-start-0 ps-0 ilead-input-group'
                        value={leaderName}
                        onChange={(e: any) => setLeaderName(e.target.value)}
                      />
                    </InputGroup>
                  </Form.Group>
                  <div className='leader_list_scroll_wrapper mt-7'>
                    {/* begin: leader box */}
                    {leaderData.map((item: any) => (
                      <div
                        className='small_leader_box d-flex align-items-center mb-5 cursor-pointer'
                        onClick={() => {
                          setbusinessReferralStepOne(false)
                          fetchSingleLeaderData(item.id)
                        }}
                      >
                        <div className='symbol symbol-45px me-3'>
                          <Image
                            src={
                              item.profile
                                ? `${API_URL}/containers/userprofile/download/${item.profile}`
                                : `${toAbsoluteUrl('./media/avatars/default-user.png')}`
                            }
                            onError={({currentTarget}: any) => {
                              currentTarget.onerror = null // prevents looping
                              currentTarget.src = `${toAbsoluteUrl(
                                './media/avatars/default-user.png'
                              )}`
                            }}
                          />
                        </div>
                        <div className='leader_details'>
                          <div className='text-black fs-6 fs-md-5 fw-bold mb-0'>{item.name}</div>
                          <span className='fs-14 text-gray-600'>{item.companyname}</span>
                        </div>
                      </div>
                    ))}
                    {/* end: leader box */}
                  </div>
                </>
              ) : (
                <>
                  <div className='business-ref-ineer'>
                    <div className='border-bottom'>
                      <p className='mb-1 mt-4 text-center fs-6 fw-semibold'>
                        I express my sincere gratitude to Leader
                      </p>
                      <div className='mt-4 gratitude-title ilead-text w-100 p-3 d-block text-center fw-semibold'>
                        {singleLeaderData.name}
                      </div>
                    </div>
                    <form
                      className='mt-7'
                      onSubmit={(e) => {
                        e.preventDefault()
                        formik.handleSubmit(e)
                      }}
                    >
                      <Form.Group className='mb-4'>
                        <Form.Label htmlFor='name' className='fs-14'>
                          Comment <span style={{color: 'red'}}> *</span>
                        </Form.Label>
                        <TextareaAutosize
                          minRows={3}
                          autoFocus
                          name='descriptiontext'
                          className='custom-text-area form-control fw-normal ilead-input'
                          placeholder='Enter comment here'
                          onChange={handleDescriptionChange}
                          maxLength={MAX_CHARACTERS}
                          value={formik.values.descriptiontext}
                        />
                        <p className='text-gray-500 fs-13'>Available Characters {messageCount}</p>
                        {formik?.touched?.descriptiontext && formik?.errors?.descriptiontext && (
                          <div className='fv-plugins-message-container text-danger'>
                            <span role='alert'>{formik?.errors?.descriptiontext as any}</span>
                          </div>
                        )}
                      </Form.Group>
                      <Form.Group className='mb-5'>
                        <div className='name-wrapper'>
                          <Form.Label className='name-title fs-14'>Name:</Form.Label>
                          <h2 className='fw-semibold mb-3'>{userDetail.name}</h2>
                        </div>
                        <div className='name-wrapper w-100'>
                          <Form.Label className='name-title'>Date:</Form.Label>
                          <h2 className='fw-semibold mb-3'>{currentDate}</h2>
                        </div>
                      </Form.Group>
                      <Form.Group className='mt-5 d-flex'>
                        <Button
                          variant='secondary'
                          type='button'
                          className='w-md-50 me-3'
                          onClick={() => {
                            setbusinessReferralStepOne(true)
                            formik.setFieldValue('descriptiontext', '')
                            setMessageCount(MAX_CHARACTERS)
                            setId('')
                            setLeaderName('')
                            formik.resetForm()
                          }}
                        >
                          Close
                        </Button>
                        <Button
                          variant='primary'
                          type='submit'
                          data-bs-toggle={formik.isValid ? 'modal' : undefined}
                          data-bs-dismiss={formik.isValid ? 'modal' : undefined}
                          className='w-md-50 text-uppercase flex-1 white-nowrap'
                          disabled={formik.isSubmitting || !formik.values.descriptiontext}
                        >
                          Say Thank You
                        </Button>
                      </Form.Group>
                    </form>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
