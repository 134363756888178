import {useMemo} from 'react'
import {useTable, ColumnInstance, Row} from 'react-table'
import {CustomHeaderColumn} from './columns/CustomHeaderColumn'
import {CustomRow} from './columns/CustomRow'
import {useQueryResponseData, useQueryResponseLoading} from '../core/QueryResponseProvider'
import {remindersColumns} from './columns/_columns'
import {Reminder} from '../core/_models'
import {RemindersListLoading} from '../components/loading/RemindersListLoading'
import {RemindersListPagination} from '../components/pagination/RemindersListPagination'
import {KTCardBody, defaultReminders} from '../../../../../../_metronic/helpers'
import { useQueryRequest } from '../core/QueryRequestProvider'
import { CopyReminderTable } from './CopyReminderTable'
// import { KTCardBody, defaultReminders } from '../../../../../_metronic/helpers'
// import {KTCardBody} from '../../../../../../_metronic/helpers'

const RemindersTable = () => {
  const reminders = useQueryResponseData()
  const isLoading = useQueryResponseLoading()
  const {state, updateState} = useQueryRequest() as any
  const data = useMemo(() => reminders, [reminders])
  const columns = useMemo(() => remindersColumns, [])
  const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
    columns,
    data,
  })

  return (
    <KTCardBody className='p-0'>
      <CopyReminderTable/>
      <div className='table-responsive mb-8 data-table-report'>
        <table
          id='kt_table_reminders'
          className='table align-middle table-row-dashed fs-6 gy-5 dataTable table-striped  no-footer'
          {...getTableProps()}
        >
          <thead>
            <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0 border-bottom border-gray-100'>
              {headers.map((column: ColumnInstance<Reminder>) => (
                <CustomHeaderColumn key={column.id} column={column} />
              ))}
            </tr>
          </thead>

          <thead className='table-sub-hdr'>
            <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
              {/* {headers.map((column: ColumnInstance<Sessionattendance>) => (
    <input type="text"/>
  ))} */}
              <th className='p-3'>
                {' '}
                <input
                  type='text'
                  className='form-control mb-3'
                  placeholder=' Title'
                  onChange={(e: any) => {
                    let filter = state?.filter ?? {}
                    filter['title'] = e.target.value
                    console.log('stste', filter['title'])
                    updateState({...state, ...{filter: filter}})
                    console.log('stste', {...state, ...{filter: filter}})
                  }}
                  value={state?.filter?.title}
                />{' '}
              </th>

              <th className='p-3'>
                {' '}
                <select
                  className='form-select form-select-lg mb-3  mt-2'
                  aria-label='.form-select-lg example'
                  onChange={(e: any) => {
                    let filter = state?.filter ?? {}
                    filter['notification_type'] = e.target.value
                    console.log('stste', filter['notification_type'])
                    updateState({...state, ...{filter: filter}})
                    console.log('stste', {...state, ...{filter: filter}})
                  }}
                  value={state?.filter?.notification_type}
                >
                  <option value="">Select Notification Type</option>
                  <option value='General'>General</option>
                </select>{' '}
              </th>
              <th className='p-3'> &nbsp; </th>
            </tr>
          </thead>

          <tbody className='text-gray-600 text-left fw-bold' {...getTableBodyProps()}>
            {rows.length > 0 ? (
              rows.map((row: Row<Reminder>, i) => {
                prepareRow(row)
                return <CustomRow row={row} key={`row-${i}-${row.id}`} />
              })
            ) : (
              <tr>
                <td colSpan={7}>
                  <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                    No matching records found
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <RemindersListPagination />
      {isLoading && <RemindersListLoading />}
    </KTCardBody>
  )
}

export {RemindersTable}
