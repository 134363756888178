import clsx from 'clsx'
import {useLayout} from '../../core'
import { ActivityPoints } from './ActivityPoints'
// import {Footer} from './Footer'

const FooterWrapper = () => {
  const {config} = useLayout()
  if (!config.app?.footer?.display) {
    return null
  }

  return (<>
    {/* <div className='app-footer w-100 bg-dark' id='kt_app_footer'> */}
      {config.app.footer.containerClass ? (
        <div className={clsx('app-container', config.app.footer.containerClass)}>
          <ActivityPoints/>
        </div>
      ) : (
        <ActivityPoints/>
      )}
    {/* </div> */}
    </>
  )
}

export {FooterWrapper}
