import React from "react";
import { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from "react-router-dom";
import { apiUrl } from '../../../apiurl'
import axios from 'axios'
import { viewEventData } from './events-list/core/_requests'
import moment from 'moment'


const API_URL = apiUrl
const ViewEvents = () => {

    // const {id} = useParams()
    const params = useParams()
    const id: string = params.id ? params.id : ''

    const [eventData, setEventData] = useState<any>({})
    const [imageError, setImageError] = useState(false);
    const [isLoading, setIsLoading] = useState(true)
    const VIEWBYID_EVENT_URL = `${API_URL}/eventdates/vieweventlist`

    const navigate = useNavigate()
    const navigateEventManagement = () => {
        // 👇️ navigate to /
        navigate('/event-management')
    }

    useEffect(() => {
        fetchData()
    }, [])

    const fetchData = async () => {

        try {
            const res = await axios.get(`${VIEWBYID_EVENT_URL}/${id}`)
            let fetchedEventData = res.data.data[0];
            setEventData(fetchedEventData)

            let chapter_id = fetchedEventData?.chapter_id;

            setIsLoading(false)
        } catch (error) {
            console.log('Error -->:', error)
            setIsLoading(false)
        }
    }

    const styles = {
        borderRadius: '0.475rem',
        boxShadow: '0 0 50px 0 rgb(82 63 105 / 15%)',
        backgroundColor: '#fff',
        color: '#7e8299',
        fontWeight: '500',
        margin: '0',
        width: 'auto',
        padding: '1rem 2rem',
        top: 'calc(50% - 2rem)',
        left: 'calc(50% - 4rem)',
    }

    if (isLoading) {
        return <div style={{ ...styles, position: 'absolute', textAlign: 'center' }}>Processing...</div>
    }

    const handleImageError = () => {
        setImageError(true);
    };

    return (
        <>
            <div className='row flex-md-block '>
                <div className='col-sm-12 col-md-6 col-lg-6'>
                    <h1 className='inv-hst sm-ms-2'> View Event </h1>
                </div>

                <div className='col-sm-12  col-md-6 col-lg-6 text-end pe-5'>
                    <button
                        onClick={navigateEventManagement}
                        className='btn btn-info btn-sm waves-effect waves-light '
                    >
                        {' '}
                        Back{' '}
                    </button>
                </div>
            </div>


            <div className='card  p-8 mt-5 visitor-details ilead__card'>
                <div className="row">
                    <div className="col-sm-12 col-md-6 mb-8">
                        <div className="text-dark fs-4 text-start ">Event Name:</div>
                        <div className="text-dark fs-4 text-start fw-bold " >{eventData?.event_name}</div>
                    </div>
                    <div className="col-sm-12 col-md-6 mb-8">
                        <div className="text-dark fs-4 text-start ">Event Date:</div>
                        <div className="text-dark fs-4 text-start fw-bold" >{moment(eventData.event_date).format('DD-MM-YYYY')}</div>
                    </div>
                    <div className="col-sm-12 col-md-6 mb-8">
                        <div className="text-dark fs-4 text-start ">Schedule Description:</div>
                        <div className="text-dark fs-4 text-start fw-bold" >{eventData?.schedule_description}</div>
                    </div>
                    <div className="col-sm-12 col-md-6 mb-8">
                        <div className="text-dark fs-4 text-start ">Chapter Name</div>
                        <div className="text-dark fs-4 text-start fw-bold" >{eventData?.name} </div>
                    </div>
                    <div className="col-sm-12 col-md-6 mb-8">
                        <div className="text-dark fs-4 text-start "> Venue </div>
                        <div className="text-dark fs-4 text-start fw-bold" >{eventData?.venue}</div>
                    </div>
                    <div className="col-sm-12 col-md-6 mb-8">
                        <div className="text-dark fs-4 text-start "> Address </div>
                        <div className="text-dark fs-4 text-start fw-bold" >{eventData?.address}</div>
                    </div>
                    <div className="col-sm-12 col-md-6 mb-8">
                        <div className="text-dark fs-4 text-start "> Start Time </div>
                        <div className="text-dark fs-4 text-start fw-bold" >{eventData?.start_time}</div>
                    </div>
                    <div className="col-sm-12 col-md-6 mb-8">
                        <div className="text-dark fs-4 text-start "> End Time </div>
                        <div className="text-dark fs-4 text-start fw-bold" >{eventData?.end_time}</div>
                    </div>
                    <div className="col-sm-12 col-md-6 mb-8">
                        <div className="text-dark fs-4 text-start "> Breakfast Fees: </div>
                        <div className="text-dark fs-4 text-start fw-bold" >{eventData?.breakfast_fees}</div>
                    </div>
                    <div className="col-sm-12 col-md-6 mb-8">
                        <div className="text-dark fs-4 text-start "> Registration Fees: </div>
                        <div className="text-dark fs-4 text-start fw-bold" > {eventData?.registration_fees} </div>
                    </div>
                    <div className="col-sm-12 col-md-6 mb-8">
                        <div className="text-dark fs-4 text-start "> Zoom Id: </div>
                        <div className="text-dark fs-4 text-start fw-bold" >{eventData?.zoom_id}</div>
                    </div>
                    <div className="col-sm-12 col-md-6 mb-8">
                        <div className="text-dark fs-4 text-start "> Google Map Link: </div>
                        <div className="text-dark fs-4 text-start fw-bold" > {eventData?.google_map_link} </div>
                    </div>
                    <div className="col-sm-12 col-md-6 mb-8">
                        <div className="text-dark fs-4 text-start "> Registration Link: </div>
                        <div className="text-dark fs-4 text-start fw-bold" > {eventData?.registration_link} </div>
                    </div>
                    <div className="col-sm-12 col-md-6 mb-8">
                        <div className="text-dark fs-4 text-start "> Hotel Logo: </div>
                        {/* <div className="text-dark fs-4 text-start fw-bold" > {eventData.hotel_logo} </div> */}
                        <div>
                            {eventData?.hotel_logo ?
                                <div>
                                    {imageError ? (
                                        <p>Image not found</p>
                                    ) : (
                                        <img
                                            width={100}
                                            src={`${API_URL}/containers/topicimages/download/${eventData?.hotel_logo}`}
                                            // src={`${API_URL}/containers/topicimages/download/1696941691894_downloadassddd_(1).png`}
                                            onError={handleImageError}
                                        // alt="Image Alt Text"
                                        />
                                    )}
                                </div>
                                :
                                "--"
                            }
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-6 mb-8">
                        <div className="text-dark fs-4 text-start "> Email Inviation Image: </div>
                        <div>
                            {eventData?.email_inviation_image ?
                                <div>
                                    {imageError ? (
                                        <p>Image not found</p>
                                    ) : (
                                        <img
                                            width={100}
                                            src={`${API_URL}/containers/topicimages/download/${eventData?.email_inviation_image}`}
                                            onError={handleImageError}
                                            style={{ maxWidth: '100%', maxHeight: '200px' }}
                                        // alt="Image Alt Text"
                                        />
                                    )}
                                </div>
                                :
                                "--"
                            }

                            {/* </Zoom> */}
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-6 mb-8">
                        <div className="text-dark fs-4 text-start "> Sms Inviation Image: </div>
                        <div>
                            {eventData?.sms_inviation_image ?
                                <div>
                                    {imageError ? (
                                        <p>Image not found</p>
                                    ) : (
                                        <img
                                            width={100}
                                            src={`${API_URL}/containers/topicimages/download/${eventData?.sms_inviation_image}`}
                                            onError={handleImageError}
                                            style={{ maxWidth: '100%', maxHeight: '200px' }}
                                        // alt="Image Alt Text"
                                        />
                                    )}
                                </div>
                                :
                                "--"
                            }
                        </div>
                    </div>
                </div>

            </div>
        </>
    )

}

export default ViewEvents