import axios from 'axios'
import { apiUrl } from '../../../../apiurl'

const API_URL = apiUrl
const USER_CURRENT_PERFORMANCE = `${API_URL}/users/userprofile`
const MONTH_TOPPER_FORMERS_API = `${API_URL}/users/monthtopperformers`;
const BUSINESS_MONTH_TOPPER_FORMERS_API = `${API_URL}/users/businessMonthtopperformers`;
const ICANIWILL = `${API_URL}/usertopics/checkiciw`;

const GET_ICIW_MESSAGE_API = `${API_URL}/usertopics/checkiciw`;
export function currentPerformanceApi() {
  return axios.get<any>(USER_CURRENT_PERFORMANCE)
    .then((responce: any) => {
      return responce.data
    })
}

export function getTopPerformanceApi(chapter_id:any, all:any, group:any) {

  let tempQueryforMonth = '?filter[where][leader_isactive]=1';

  if (chapter_id) {
    tempQueryforMonth += '&filter[where][chapter_id]=' + chapter_id;
  }

  if (all) {
    tempQueryforMonth += '&filter[where][all]=' + all;
  }

  if (group) {
    tempQueryforMonth += '&filter[where][group]=' + group;
  }

  return axios.get<any>(MONTH_TOPPER_FORMERS_API+tempQueryforMonth)
    .then((responce: any) => {
      return responce.data
    })
}

export function getBusinessMonthlyPerformanceApi(chapter_id:any, all:any, group:any) {
  let tempQueryforMonth = '?filter[where][leader_isactive]=1';

  if (chapter_id) {
    tempQueryforMonth += '&filter[where][chapter_id]=' + chapter_id;
  }

  if (all) {
    tempQueryforMonth += '&filter[where][all]=' + all;
  }

  if (group) {
    tempQueryforMonth += '&filter[where][group]=' + group;
  }

  return axios.get<any>(BUSINESS_MONTH_TOPPER_FORMERS_API+tempQueryforMonth)
    .then((responce: any) => {
      return responce.data
    })
}

export function getCheckICANIWILL (){

  return axios.get<any>(ICANIWILL)}
export function getICIWMessage() {
  return axios.get<any>(GET_ICIW_MESSAGE_API)
    .then((responce: any) => {
      return responce.data
    })
}