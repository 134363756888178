import axios, {AxiosResponse} from 'axios'
// import {ID, Response} from '../../../../../../_metronic/helpers'
import {Librarymanagement, LibrarymanagementsQueryResponse} from './_models'
import { ID, Response } from '../../../../../_metronic/helpers'

// const API_URL = process.env.REACT_APP_THEME_API_URL
import { apiUrl } from '../../../../../apiurl'
const API_URL = apiUrl

const LIBRARYMANAGEMENT_URL = `${API_URL}/libraryposts`
const GET_LIBRARYMANAGEMENTS_URL = `${API_URL}/libraryposts?filter[where][isactive]=1&filter[where][isdelete]=0&isWeb=true`
const LIBRARYMEDIA_URL = `${API_URL}/librarypostmedia`

const getLibrarymanagements = (query: string): Promise<any> => {
  const mainquery = query
  // console.log("quer1y",query.split("&"))
  
  return axios
    .get(`${GET_LIBRARYMANAGEMENTS_URL}&${query}`)
    .then((d: AxiosResponse<any>) => d.data).catch((e) => console.log(e))
}

const getLibrarymanagementById = (id: any): Promise<Librarymanagement | undefined> => {
  return axios
    .get(`${LIBRARYMANAGEMENT_URL}/${id}`)
    // .then((response: AxiosResponse<Response<Librarymanagement>>) => response.data).catch((e) => console.log(e))
    .then((response: Response<any>) => response.data).catch((e) => console.log(e))
}

const createLibrarymanagement = (librarymanagement: Librarymanagement): Promise<Librarymanagement | undefined> => {
  return axios
    .put(LIBRARYMANAGEMENT_URL, librarymanagement)
    // .then((response: AxiosResponse<Response<Librarymanagement>>) => response.data).catch((e) => console.log(e))
    .then((response: Response<any>) => response.data).catch((e) => console.log(e))
}

const updateLibrarymanagement = (librarymanagement: Librarymanagement): Promise<any> => {
  return axios
    .patch(`${LIBRARYMANAGEMENT_URL}/${librarymanagement.id}`, librarymanagement)
    // .then((response: AxiosResponse<Response<Librarymanagement>>) => response.data).catch((e) => console.log(e))
    .then((response: Response<any>) => response.data).catch((e) => console.log(e))
}

const deleteLibrarymanagement = (librarymanagementId: ID): Promise<void> => {
  return axios.post(`${LIBRARYMANAGEMENT_URL}/deletepost/`,{id:librarymanagementId}).then((response: any) => response)
}

const deleteSelectedLibrarymanagements = (librarymanagementIds: Array<ID>): Promise<void> => {
  const requests = librarymanagementIds.map((id) => axios.delete(`${LIBRARYMANAGEMENT_URL}/${id}`))
  return axios.all(requests).then(() => {})
}
const createLibraryViewMedia = (librarymanagement: any): Promise<any | undefined> => {
  return axios
    .put(LIBRARYMEDIA_URL, librarymanagement)
    // .then((response: AxiosResponse<Response<Librarymanagement>>) => response.data).catch((e) => console.log(e))
    .then((response: Response<any>) => response.data).catch((e) => console.log(e))
}

const getLibraryViewMediaById = (id:any): Promise<any | undefined> => {
  return axios
    .get(`${LIBRARYMEDIA_URL}?filter[where][post_id]=${id}`)
    // .then((response: AxiosResponse<Response<Librarymanagement>>) => response.data).catch((e) => console.log(e))
    .then((response: Response<any>) => response.data).catch((e) => console.log(e))
}

const deleteLibraryViewMediaById = (librarymanagementId: any): Promise<void> => {
  return axios.post(`${LIBRARYMEDIA_URL}/deletemedia/`,{id:librarymanagementId}).then((response: any) => response)
}
export {getLibrarymanagements, deleteLibrarymanagement, deleteSelectedLibrarymanagements, getLibrarymanagementById, createLibrarymanagement, updateLibrarymanagement,createLibraryViewMedia ,getLibraryViewMediaById ,deleteLibraryViewMediaById}
