/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useContext, useState, useEffect, useMemo } from 'react'
import { useQuery } from 'react-query'
// import {
//   createResponseContext,
//   initialQueryResponse,
//   initialQueryState,
//   PaginationState,
//   QUERIES,
//   stringifyRequestQuery,
//   WithChildren,
// } from '../../../../../../_metronic/helpers'
import { getGroupchats } from './_requests'
import { Groupchat } from './_models'
import { useQueryRequest } from './QueryRequestProvider'
import { PaginationState, QUERIES, WithChildren, createResponseContext, initialQueryResponse, initialQueryState, stringifyRequestQuery } from '../../../../../../_metronic/helpers'
// import { PaginationState, QUERIES, WithChildren, createResponseContext,  initialQueryResponse, initialQueryState, stringifyRequestQuery } from '../../../../../_metronic/helpers'

const QueryResponseContext = createResponseContext<Groupchat>(initialQueryResponse)
const QueryResponseProvider: FC<WithChildren> = ({ children }) => {
  const { state } = useQueryRequest()
  const [query, setQuery] = useState<string>(stringifyRequestQuery(state))
  const updatedQuery = useMemo(() => stringifyRequestQuery(state), [state])

  useEffect(() => {
    if (query !== updatedQuery) {
      setQuery(updatedQuery)
    }
  }, [updatedQuery])

  const {
    isFetching,
    refetch,
    data: response,
  } = useQuery(
    `${QUERIES.GROUPCHATS_LIST}-${query}`,
    () => {
      return getGroupchats(query)
    },
    { cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false }
  )

  return (
    <QueryResponseContext.Provider value={{ isLoading: isFetching, refetch, response, query }}>
      {children}
    </QueryResponseContext.Provider>
  )
}

const useQueryResponse = () => useContext(QueryResponseContext)

const useQueryResponseData = () => {
  const { response } = useQueryResponse() as any

  if (!response || !response?.data?.data) {
    return []
  }
  return response?.data?.data || []
}



const useQueryResponsePagination = () => {
  const defaultPaginationState: PaginationState = {
    links: [],
    ...initialQueryState,
  }

  const { response }: any = useQueryResponse() as any
  if (!response || !response?.data?.meta || !response?.data?.meta.pagination) {
    return defaultPaginationState
  }

  return {
    perPage: response?.data?.meta?.pagination?.per_page,
    page: response?.data?.meta?.pagination?.current_page,
    totalRecords: response?.data?.meta?.pagination?.total,
  }
}

const useQueryResponseLoading = (): boolean => {
  const { isLoading }: any = useQueryResponse()
  return isLoading
}

export {
  QueryResponseProvider,
  useQueryResponse,
  useQueryResponseData,
  useQueryResponsePagination,
  useQueryResponseLoading,
}
