import React, {useState, useEffect, useContext, useRef} from 'react'
import {apiUrl, redirectAdminURL} from '../../../apiurl'
import {sendInvitation, shareNotification} from './core/_request'
import axios from 'axios'
import {UserContext} from '../../../contextStore'
import {checkDisplayValue} from '../../../utils'
import moment from 'moment'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {send} from 'process'

const API_URL = apiUrl
const CHAPTER_LIST_URL = `${API_URL}/chapters`
const Date_LIST_URL = `${API_URL}/eventdates`

const validationForm = Yup.object().shape({
  event_id: Yup.number().required('This is required'),
})

const ResetDateChapter = ({selectedItem, fetchData}: any) => {
  const [chapterList, setChapterList] = useState<any>([])
  const [dateList, setDateList] = useState<any>([])
  const [isLoading, setIsLoading] = useState(true)
  const [chapterId, setChapterId] = useState<any>('')
  const {userDetail, setMessage} = useContext(UserContext)
  const [loading, setLoading] = useState(false)
  const todays_date = new Date().toISOString().split('T')[0]
  const closebutton = useRef<any>()

  useEffect(() => {
    fetchChaptersList()
  }, [])

  useEffect(() => {
    fetchDateList()
  }, [chapterId])

  const fetchChaptersList = async () => {
    try {
      const res = await axios.get(
        `${CHAPTER_LIST_URL}?filter[where][show_in_visitor_invitation]=1&filter[where][isdelete]=0`
      )

      setChapterList(res?.data ?? [])
      setIsLoading(false)
    } catch (error) {
      console.error('Error -->:', error)
      setIsLoading(false)
    }
  }

  const fetchDateList = async () => {
    try {
      const res = await axios.get(
        `${Date_LIST_URL}?filter[order]=event_date&filter[limit]=4&filter[where][event_date][gt]=${todays_date}&filter[where][chapter_id]=${chapterId}`
      )

      setDateList(res?.data ?? [])
      setIsLoading(false)
    } catch (error) {
      console.error('Error -->:', error)
      setIsLoading(false)
    }
  }

  const initialValues = {
    cellnumber: '',
    companyname: '',
    name: '',
    event_id: '',
    email: '',
  }

  const formik = useFormik({
    initialValues,
    validationSchema: validationForm,
    onSubmit: async (values, {setStatus, resetForm, setSubmitting}) => {
      setLoading(true)
      try {
        const postData = {
          cellnumber: selectedItem.cell_no,
          companyname: selectedItem.company,
          name: selectedItem.invitee_name,
          event_id: values.event_id,
          email: selectedItem.email,
        }
        await sendInvitation(postData)
          .then((res: any) => {
            if (res?.status == 200) {
              setMessage('success', 'Invitation sent successfully')
              setLoading(false)
              resetForm()
              setChapterId('')
              shareNotification({
                thankyouhistoryid: null,
                user_topic_id: res?.data?.usertopic?.id ?? '',
                userid: userDetail?.id ?? '',
                accesstoken: localStorage.getItem('authToken'),
              })
              fetchData()
              closebutton.current.click()
            }
          })
          .catch((error: any) => {
            if (error?.response?.data?.error?.message) {
              setMessage('error', error?.response?.data?.error?.message)
            } else {
              setMessage('error', 'Something Went Wrong')
            }
            setLoading(false)
            resetForm()
            setChapterId('')
          })
      } catch (error: any) {
        setMessage('error', error?.message ? error?.message : 'Something Went Wrong')
        setLoading(false)
        resetForm()
        setChapterId('')
      }
    },
  })

  return (
    <>
      <div className='modal fade reset-date-psw' tabIndex={-1} id='reset_date_chapter_modal'>
        <div className='modal-dialog'>
          <div className='modal-content'>
            <div className='modal-header' style={{backgroundColor:"#e3371e"}} >
              <h4 className='modal-title text-white fs-3 fw-bold' id='exampleModalLabel'>
                {' '}
                Select Resend Date And Chapter
              </h4>
              <button
                type='button'
                className='text-white border-0 bg-transparent'
                data-bs-dismiss='modal'
                aria-label='Close'
                ref={closebutton}
                onClick={() => {
                  setChapterId('')
                  formik.setFieldValue('event_id', '')
                }}
              >
                {' '}
                <i className='fa-solid fa-xmark fs-2 text-white'></i>{' '}
              </button>
            </div>
            <div className='modal-body'>
              <form method='' action='' className='' onSubmit={formik.handleSubmit} noValidate>
                <div className='row'>
                  <div className='col-md-12 mb-3'>
                    <div className='form-group floating-labels'>
                      <select
                        className='form-select border-top-0 border-left-0 border-right-0 border-bottom rounded-0'
                        aria-label='Default select example'
                        value={chapterId}
                        onChange={(e) => setChapterId(e.target.value)}
                      >
                        <option> Select Chapter </option>
                        {chapterList?.map((i: any) => (
                          <>
                            <option value={i?.id}> {i?.name} </option>
                          </>
                        ))}
                      </select>
                    </div>
                  </div>

                  <div className='col-md-12 mb-5'>
                    <div className='form-group floating-labels'>
                      <select
                        className='form-select border-top-0 border-left-0 border-right-0 border-bottom rounded-0'
                        aria-label='Default select example'
                        value={formik.values.event_id}
                        onChange={(e: any) => {
                          formik.setFieldValue('event_id', e.target.value)
                        }}
                      >
                        <option selected style={{background: '6610f2', height: '50px'}}>
                          Select Event Date
                        </option>
                        {dateList?.map((i: any) => (
                          <>
                            <option value={i?.id}>
                              {' '}
                              {checkDisplayValue(moment(i?.event_date).format('MMM DD, YYYY'))}{' '}
                            </option>
                          </>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                <div className='d-flex justify-content-end'>
                  <button
                    className='btn bg-secondary me-4'
                    type='button'
                    data-bs-dismiss='modal'
                    id='closemodel'
                    onClick={() => {
                      setChapterId('')
                      formik.setFieldValue('event_id', '')
                    }}
                  >
                    Close
                  </button>
                  <button
                    type='submit'
                    className='btn btn-success'
                    disabled={formik.isSubmitting || !formik.isValid}
                    id='modalresend'
                  >
                    Resend
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export {ResetDateChapter}
