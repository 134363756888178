import {useMemo,useState} from 'react'
import {useTable, ColumnInstance, Row} from 'react-table'
import {CustomHeaderColumn} from './columns/CustomHeaderColumn'
import {CustomRow} from './columns/CustomRow'
import {useQueryResponseData, useQueryResponseLoading} from '../core/QueryResponseProvider'
import {librarymanagementsColumns} from './columns/_columns'
import {Librarymanagement} from '../core/_models'
import {LibrarymanagementsListLoading} from '../components/loading/LibrarymanagementsListLoading'
import {LibrarymanagementsListPagination} from '../components/pagination/LibrarymanagementsListPagination'
import { KTCardBody,initialQueryState, defaultLibrarymanagements } from '../../../../../_metronic/helpers'
import { useQueryRequest } from '../core/QueryRequestProvider'
// import {KTCardBody} from '../../../../../../_metronic/helpers'

const LibrarymanagementsTable = () => {
  const librarymanagements = useQueryResponseData()
  const {state, updateState} = useQueryRequest()
  // const librarymanagements = defaultLibrarymanagements
  const isLoading = useQueryResponseLoading()
  const [sortBy, setSortBy] = useState<string>('')
  const [order, setOrder] = useState<string>('ASC')
  const data = useMemo(() => librarymanagements, [librarymanagements])
  const columns = useMemo(() => librarymanagementsColumns, [])
  const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
    columns,
    data,
  })


  const onSorting = (name: string) => {
    setSortBy(name)
    if (order == 'ASC') {
      let oldData = state.filter as any
      updateState({
        filter: {...oldData, order, sort:name},
        ...initialQueryState
      })
      setOrder('DESC')
    } else {
      let oldData = state.filter as any
      updateState({
        filter: {...oldData, order, sort:name},
        ...initialQueryState
      })
      setOrder('ASC')
    }
  }

  return (
    <KTCardBody className='p-0'>
      <div className='table-responsive mb-8 data-table-report'>
        <table
          id='kt_table_librarymanagements'
          className='table align-middle table-row-dashed fs-6 gy-5 dataTable table-striped  no-footer'
          {...getTableProps()}
        >
          <thead>
            <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0 border-bottom border-gray-100'>
              {headers.map((column: ColumnInstance<any>) => (
                <CustomHeaderColumn  key={column.id} column={column} />
              ))}
            </tr>
          </thead>

          <tbody className='text-gray-600 text-start fw-bold' {...getTableBodyProps()}>
            {rows.length > 0 ? (
              rows.map((row: Row<Librarymanagement>, i) => {
                prepareRow(row)
                return <CustomRow row={row} key={`row-${i}-${row.id}`} />
              })
            ) : (
              <tr>
                <td colSpan={7}>
                  <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                    No matching records found
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <LibrarymanagementsListPagination />
      {isLoading && <LibrarymanagementsListLoading />}
    </KTCardBody>
  )
}

export {LibrarymanagementsTable}
