import axios from 'axios'
import {apiUrl} from '../../../../apiurl'

const API_URL = apiUrl

export const GET_CHAPTER_LEADER = `${API_URL}/users/chapterleaders`
const GET_GROUP_FACILITATOR = `${API_URL}/users`
const USERS_API = `${API_URL}/users?filter[where][isdelete]=0&filter[order]=name%20ASC&filter[where][isactive]=1&filter[include]=group&filter[include]=chapters`

export function getChapterLeader(chapterid: any) {
  return axios
    .get<any>(`${GET_CHAPTER_LEADER}?chapter_id=${chapterid}`)
    .then((responce: any) => {
      return responce.data
    })
    .catch((error) => {
      console.error('Error All Post Data:', error)
      throw error
    })
}

export function getGroupFacilitator(chapterid: any) {
  return axios
    .get<any>(
      `${GET_GROUP_FACILITATOR}?filter[where][isdelete]=0&filter[where][iscaptain]=1&filter[where][chapter_id]=${chapterid}&filter[include]=group&filter[order]=group_id`
    )
    .then((responce: any) => {
      return responce.data
    })
    .catch((error) => {
      console.error('Error All Post Data:', error)
      throw error
    })
}

export function getUser(chapterId: any,group_id:any) {
  return axios
    .get<any>(
      `${USERS_API}&filter[where][chapter_id]=${chapterId}${group_id ? `&filter[where][group_id]=${group_id}`: ''}`
    )
    .then((responce: any) => {
      return responce.data
    })
    .catch((error) => {
      console.error('Error All user Data:', error)
      throw error
    })
}