import React, {useState, useContext, useRef, useEffect} from 'react'
import {Button, Form} from 'react-bootstrap'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import Webcam from 'react-webcam'
import {apiUrl} from '../../../../../../apiurl'
import axios from 'axios'
import {UserContext} from '../../../../../../contextStore'
import './webcamPopup.css'

const API_URL = apiUrl
const SUBMIT_TRACKSHEET = `${API_URL}/usertopics`
const IMAGE_UPLOAD = `${API_URL}/containers/topicimages/upload`

export function SubmitTracksheet() {
  const {setMessage, userDetail, dashBoardReload} = useContext(UserContext)
  const [uploading, setUploading] = useState<boolean>(false)
  const webcamRef = useRef<Webcam | null>(null)
  const [selectedImage, setSelectedImage] = useState('')
  const [capturedImage, setCapturedImage] = useState<string | null>(null)
  const [showWebcam, setShowWebcam] = useState(false)
  const [webcamAvailable, setWebcamAvailable] = useState<boolean>(false)
  const [selectedFile, setSelectedFile] = useState('')
  const [selectedFileType, setSelectedFileType] = useState<'image' | 'file'>('image')
  const [fileName, setFileName] = useState<any>('')
  const closeButton = useRef<any>()
  const [arrayOfFile, setArrayOfFile] = useState<any>([])
  const [selectedImageShow, setSelectedImageShow] = useState<any>()
  const [pervFileUpload, setPervFileUpload] = useState('')
  const FACING_MODE_USER = 'user'
  const FACING_MODE_ENVIRONMENT = 'environment'
  const [facingMode, setFacingMode] = React.useState(FACING_MODE_USER)

  const handleImageChange = async (event: any) => {
    const file = event.target.files[0]
    if (file) {
      var pattern = /image-*/
      // setSelectedImage(file.name)
      if (!file.type.match(pattern)) {
        setMessage('error','Invalid format ! Please upload Images');
        return;
      }
      const imageData = URL.createObjectURL(file)
      setSelectedImageShow(imageData)
      await uploadFile(file, 'image')
    } else {
      setSelectedImage('')
    }
  }

  const checkCameraPermission = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({video: true})
      setWebcamAvailable(true)
      stream.getTracks().forEach((track) => track.stop())
    } catch (error) {
      setWebcamAvailable(false)
    }
  }

  const uploadFile = async (file: File, typeOfFile: string) => {
    try {
      if (!file) {
        console.error('No file selected')
        return
      }

      const formData = new FormData()
      formData.append('file', file)

      const response = await axios.post(`${IMAGE_UPLOAD}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      if (typeOfFile === 'file') {
        setArrayOfFile([
          {
            name: response?.data?.result?.files?.file[0]?.name ?? '',
            image: response?.data?.result?.files?.file[0]?.name ?? '',
          },
        ])
        setPervFileUpload('file')
      }
      if (typeOfFile === 'image') {
        if (pervFileUpload === 'file') {
          setArrayOfFile([
            {
              name: response?.data?.result?.files?.file[0]?.name ?? '',
              image: response?.data?.result?.files?.file[0]?.name ?? '',
            },
          ])
        } else {
          if (arrayOfFile.length === 0) {
            setArrayOfFile([
              {
                name: response?.data?.result?.files?.file[0]?.name ?? '',
                image: response?.data?.result?.files?.file[0]?.name ?? '',
              },
            ])
          } else if (
            arrayOfFile.length === 1 ||
            arrayOfFile.length === 2 ||
            arrayOfFile.length === 3
          ) {
            setArrayOfFile([
              ...arrayOfFile,
              {
                name: response?.data?.result?.files?.file[0]?.name ?? '',
                image: response?.data?.result?.files?.file[0]?.name ?? '',
              },
            ])
          } else {
            setMessage('error', 'Only 4 images are allowed')
            return
          }
        }
        setPervFileUpload('image')
      }
    } catch (error) {
      setMessage('error', 'Something Went Wrong')
    }
  }

  const handleCancelButtonClick = (index: any) => {
    const updatedArray = arrayOfFile.filter((item: any, i: any) => i !== index)
    setArrayOfFile(updatedArray)
  }
  const uploadImage = async (imageData: any) => {
    try {
      if (!imageData) {
        console.error('No image captured')
        return
      }
      // Create an Image element to load the captured image data
      const img = new Image()
      img.src = imageData

      // Wait for the image to load
      await new Promise((resolve) => (img.onload = resolve))

      // Create a canvas element
      const canvas = document.createElement('canvas')
      canvas.width = img.width
      canvas.height = img.height

      // Get the canvas context and draw the image on the canvas
      const ctx = canvas.getContext('2d')
      if (ctx) {
        ctx.drawImage(img, 0, 0) // Ensure ctx is not null before using it
      } else {
        console.error('Canvas context is null')
        return
      }
      // Convert the canvas content to a data URL with 'image/jpeg' format and quality set to 1 (maximum quality)
      const jpgDataUrl = canvas.toDataURL('image/jpeg', 1)

      // Convert the data URL back to a Blob
      const byteCharacters = atob(jpgDataUrl.split(',')[1])
      const byteArrays = new Uint8Array(byteCharacters.length)
      for (let i = 0; i < byteCharacters.length; i++) {
        byteArrays[i] = byteCharacters.charCodeAt(i)
      }
      const jpgBlob = new Blob([byteArrays], {type: 'image/jpeg'})

      // Create a FormData object to send the image file to the API
      const formData = new FormData()
      formData.append('image', jpgBlob, 'filename.jpg')

      // Set the loading state to true while uploading
      setUploading(true)

      // Make the API call to upload the image
      const response = await axios.post(`${IMAGE_UPLOAD}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      // Muti File Upload.
      if (pervFileUpload === 'file') {
        setArrayOfFile([
          {
            name: response?.data?.result?.files?.image[0]?.name ?? '',
            image: response?.data?.result?.files?.image[0]?.name ?? '',
          },
        ])
      } else {
        if (arrayOfFile.length === 0) {
          setArrayOfFile([
            {
              name: response?.data?.result?.files?.image[0]?.name ?? '',
              image: response?.data?.result?.files?.image[0]?.name ?? '',
            },
          ])
        } else if (
          arrayOfFile.length === 1 ||
          arrayOfFile.length === 2 ||
          arrayOfFile.length === 3
        ) {
          setArrayOfFile([
            ...arrayOfFile,
            {
              name: response?.data?.result?.files?.image[0]?.name ?? '',
              image: response?.data?.result?.files?.image[0]?.name ?? '',
            },
          ])
        } else {
          setMessage('error', 'Only 4 images are allowed')
          return
        }
      }

      // setFileName(response?.data?.result?.files?.image[0]?.name ?? "")
      let {filename} = response.data

      setUploading(false)
    } catch (error) {
      setMessage('error', 'Error uploading the image')
      setUploading(false)
    }
  }

  const initialValues = {
    postmedia: '',
  }

  const toggleCameraFacingMode = () => {
    setFacingMode((prevFacingMode) =>
      prevFacingMode === FACING_MODE_USER ? FACING_MODE_ENVIRONMENT : FACING_MODE_USER
    )
  }

  const toggleWebcam = async () => {
    setShowWebcam((prev) => !prev)
    if (!webcamAvailable) {
      await checkCameraPermission()
      setShowWebcam(true)
    } else {
      setShowWebcam(true)
    }
  }

  const captureImage = () => {
    if (webcamRef.current) {
      const imageSrc = webcamRef.current.getScreenshot()
      setCapturedImage(imageSrc)
      uploadImage(imageSrc)
    }
    setShowWebcam(false)
  }

  const cancelWebcam = () => {
    setShowWebcam(false)
  }

  const validationSchema = Yup.object().shape({
    postmedia: Yup.array().of(
      Yup.object().shape({
        name: Yup.string().required('File name is required'),
      })
    ),
  })

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: async () => {
      try {
        const postData = {
          isactive: 1,
          isdelete: 0,
          created_by: userDetail.id,
          user_id: userDetail.id,
          group_id: userDetail.group_id,
          topic_id: 14,
          postmedia: arrayOfFile,
        }

        await axios.post(`${SUBMIT_TRACKSHEET}?filter[where][isdelete]=0`, postData)
        setMessage('success', 'Tracksheet  post created')
        setArrayOfFile([])
        setPervFileUpload('')
        setArrayOfFile([])
        setPervFileUpload('')
        dashBoardReload()
      } catch (error) {
        setMessage('error', 'Something went wrong')
      }
    },
  })

  return (
    <>
      <div
        className='modal fade ilead-default-modal'
        id='SubmitTracksheetModal'
        aria-hidden='true'
        aria-labelledby='SubmitTracksheetModalLabel'
      >
        <div className='modal-dialog modal-dialog modal-dialog-scrollable modal-fullscreen-md-down modal-md'>
          <div className='modal-content'>
            <div className='modal-header py-5'>
              <h3 className='modal-title fs-3 fw-bold' id='SubmitTracksheetModalToggleLabel'>
                Submit Tracksheet
              </h3>
              <button
                type='button'
                className='btn-close text-white'
                data-bs-target='#write_post_modal'
                ref={closeButton}
                data-bs-toggle='modal'
                data-bs-dismiss='modal'
                onClick={() => {
                  setArrayOfFile([])
                  setPervFileUpload('')
                }}
              ></button>
            </div>
            <div className='modal-body pt-7 write-post-wrapper'>
              <form className='mb-7' onSubmit={formik.handleSubmit}>
                <Form.Group className='mb-3 mt-7'>
                  <h5 className='mt-7 mb-7'>Add Attachment</h5>
                  <Button
                    variant='primary'
                    className='me-3 px-4 py-3'
                    onClick={() => toggleWebcam()}
                  >
                    <i className='bi bi-camera-fill pe-0' style={{fontSize: '20px'}}></i>
                  </Button>

                  {showWebcam && webcamAvailable ? (
                    <div className='webcam-popup'>
                      <div className='webcam-popup-content'>
                        <Webcam
                          audio={false}
                          ref={webcamRef}
                          videoConstraints={{
                            facingMode: facingMode,
                          }}
                        />
                        <button
                          type='button'
                          className='btn-close text-white cancel-button'
                          onClick={cancelWebcam}
                        ></button>
                        <div className='webcam-buttons'>
                          <Button
                            variant='primary'
                            className='me-3 px-4 py-3'
                            onClick={captureImage}
                          >
                            <i className='bi bi-camera-fill pe-0' style={{fontSize: '20px'}}></i>
                          </Button>

                          <Button
                            variant='primary'
                            className='me-3 px-4 py-3 toggle-button'
                            onClick={toggleCameraFacingMode}
                            style={{borderRadius: '30px'}}
                          >
                            <i className='bi bi-arrow-repeat pe-0' style={{fontSize: '20px'}}></i>
                          </Button>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ''
                  )}
                  <div className='me-3 px-4 py-3 position-relative btn btn-primary'>
                    <Form.Control
                      type='file'
                      accept='image/jpeg, image/png,'
                      className='position-absolute w-100 h-100 start-0 top-0 opacity-0'
                      onChange={handleImageChange}
                    />
                    <i className='bi bi-image-fill pe-0' style={{fontSize: '20px'}}></i>
                  </div>
                </Form.Group>
                <div className='row'>
                  {arrayOfFile.map((item: any, i: any) => {
                    let itemLast = item.image.split('.').pop()

                    if (
                      itemLast == 'jpeg' ||
                      itemLast == 'jpeg' ||
                      itemLast == 'png' ||
                      itemLast == 'jpg'
                    ) {
                      return (
                        <div className='col'>
                          <div
                            className='white-close-image'
                            style={{position: 'relative', width: '130px'}}
                          >
                            <img
                              src={`${API_URL}/containers/topicimages/download/${item.image}`}
                              alt='Captured'
                              style={{width: 130, height: 90}}
                            />
                            <button
                              type='button'
                              className='btn-close text-white fs-4'
                              style={{
                                backgroundColor: 'red',
                                position: 'absolute',
                                right: '5px',
                                top: '5px',
                              }}
                              onClick={() => handleCancelButtonClick(i)}
                            />
                          </div>
                        </div>
                      )
                    }
                  })}
                </div>
                <Button
                  variant='primary'
                  type='submit'
                  className='w-100 mt-7'
                  data-bs-toggle={formik.isValid ? 'modal' : undefined}
                  data-bs-dismiss={formik.isValid ? 'modal' : undefined}
                  disabled={!arrayOfFile.length}
                >
                  Submit
                </Button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
