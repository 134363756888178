import React, {useCallback, useContext, useEffect, useRef, useState} from 'react'
import {Form, OverlayTrigger, Tooltip} from 'react-bootstrap'
import {useNavigate, useParams} from 'react-router-dom'
import * as Yup from 'yup'
import {apiUrl} from '../../../apiurl'
import {useFormik} from 'formik'
import axios from 'axios'
import {useDropzone} from 'react-dropzone'
import {UserContext} from '../../../contextStore'
import {createChapter, updateChapter} from './chapters-list/core/_requests'
import {isNotEmpty} from '../../../_metronic/helpers'
import Select from 'react-select'

const API_URL = apiUrl
const CITY_URL = `${API_URL}/cities?filter[where][isdelete]=0&filter[fields][id]=true&filter[fields][name]=true&filter[fields][state_id]=true`
const STATE_URL = `${API_URL}/states?filter[where][isdelete]=0&filter[fields][id]=true&filter[fields][name]=true&filter[fields][city_id]=true`
const VIEWBYID_CHAPTER_URL = `${API_URL}/chapters/findOneChapters`
const IMAGE_UPLOAD = `${API_URL}/containers/chapterimages/upload`

const validationForm = Yup.object().shape({
  name: Yup.string().required('This field is required'),
  city_id: Yup.string(),
  state_id: Yup.string(),
  imagename: Yup.string(),
})

const AddEditChapter = () => {
  const {id} = useParams()
  const [data, setData] = useState<any>([])
  const [isLoading, setLoading] = useState<any>(false)
  const [selectedFile, setSelectedFile] = useState<any>(null)
  const [error, setError] = useState('')
  const [cityList, setCityList] = useState<any>([])
  const [stateList, setStateList] = useState<any>([])
  const {setMessage} = useContext(UserContext)
  const closeModel = useRef<any>()
  const navigate = useNavigate()
  const navigateChapterListing = () => {
    // 👇️ navigate to /
    navigate('/chapters')
  }

  const onDrop = useCallback((acceptedFiles: any) => {
    setError('')
    if (
      acceptedFiles[0].type == 'image/jpeg' ||
      acceptedFiles[0]?.type == 'image/png' ||
      acceptedFiles[0].type == 'image/jpg'
    ) {
      const file = acceptedFiles[0]
      setSelectedFile(file)
      uploadImage(file)
    } else {
      setError('Only image is allowed.')
    }
  }, [])

  const {getRootProps, getInputProps} = useDropzone({
    onDrop,
    accept: ['image/jpeg', 'image/png'] as any, // Specify accepted file types (e.g., images)
    maxFiles: 1, // Maximum number of files to upload (1 in this case)
  })

  useEffect(() => {
    getCityList()
    getStateList()
    fetchData()
  }, [])

  const fetchData = async () => {
    try {
      if (id) {
        const res = await axios.get(`${VIEWBYID_CHAPTER_URL}/${id}`)
        setData(res?.data?.responseData ?? [])
      } else {
        formik.setFieldValue('is_sendsms', true)
      }
    } catch (error) {
      console.log('Error -->:', error)
    }
  }

  const getCityList = async () => {
    try {
      const resu = await axios.get(CITY_URL)
      if (resu?.data) {
        const list = resu?.data.map((x: any) => {
          var label = x.name
          var value = x.id
          return {value: value, label: label, ...x}
        })
        setCityList(list)
      }
    } catch (err: any) {
      let message = err?.message
        ? err.message
        : err?.responseMessage
        ? err.responseMessage
        : ('Something went wrong' as any)
      console.log('errrrrrr', message)
    }
  }

  const getStateList = async () => {
    try {
      const resg = await axios.get(STATE_URL)
      if (resg?.data) {
        const list = resg?.data.map((x: any) => {
          var label = x.name
          var value = x.id
          return {value: value, label: label, ...x}
        })
        setStateList(list)
      }
    } catch (err: any) {
      let message = err?.message
        ? err.message
        : err?.responseMessage
        ? err.responseMessage
        : ('Something went wrong' as any)
      console.log('errrrrrr', message)
    }
  }

  const initialValues = {
    name: '',
    imagename: '',
    city_id: '',
    state_id: '',
  }

  const uploadImage = async (file: File) => {
    try {
      if (file) {
        const formData = new FormData()
        formData.append('file', file)

        const response = await axios.post(IMAGE_UPLOAD, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Content-Disposition': `attachment; filename="${file.name}"`,
          },
        })

        if (response.status === 200) {
          formik.setFieldValue('imagename', response?.data?.result?.files?.file[0]?.name)
        } else {
          console.error('Image upload failed:', response.statusText)
        }
      }
    } catch (error) {
      console.error('Error uploading image:', error)
    }
  }

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationForm,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      setLoading(true)
      var postData = {
        name: values?.name ?? null,
        imagename: 'default-user.png',
        city_id: '1',
        state_id: '1',
      }
      try {
        if (isNotEmpty(id)) {
          await updateChapter(id, postData)
            .then((res: any) => {
              if (res?.status == 200) {
                setMessage('success', res?.message ?? 'Chapter updated.')
                closeModel.current.click()
              } else {
                setMessage('error', res?.response?.data?.error?.message ?? 'Something went wrong.=')
              }
              setLoading(false)
            })
            .catch((error: any) => {
              if (error) {
                setMessage('error', 'Something went wrong.')
              }
              setLoading(false)
            })
        } else if (!id) {
          await createChapter(postData)
            .then((res: any) => {
              if (res?.status == 200) {
                setMessage('success', res?.data?.message ?? 'Chapter added.')
                closeModel.current.click()
              } else {
                setMessage('error', res?.response?.data?.error?.message ?? 'Something went wrong.')
              }
              setLoading(false)
            })
            .catch((error: any) => {
              if (error) {
                setMessage('error', 'Something went wrong.')
              }
              setLoading(false)
            })
        }
      } catch (ex) {
        console.error(ex)
      }
    },
  })

  function convertURLToBlob(imageURL: any) {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest()
      xhr.responseType = 'blob'

      xhr.onload = () => {
        if (xhr.status === 200) {
          resolve(xhr.response)
        } else {
          reject(new Error(`Failed to fetch image: ${xhr.status} - ${xhr.statusText}`))
        }
      }

      xhr.onerror = () => {
        reject(new Error('Network error'))
      }

      xhr.open('GET', imageURL)
      xhr.send()
    })
  }

  useEffect(() => {
    if (id) {
      formik.setFieldValue('name', data?.name ?? '')
      formik.setFieldValue('city_id', data?.city_id ?? '')
      formik.setFieldValue('state_id', data?.state_id ?? '')
      if (data?.imagename) {
        convertURLToBlob(`${API_URL}/containers/chapterimages/download/${data?.imagename}`)
          .then((imageBlob: any) => {
            setSelectedFile(new File([imageBlob], data?.imagename))
            formik.setFieldValue('imagename', data?.imagename ?? '')
          })
          .catch((error) => {
            console.error(error)
          })
      }
    }
  }, [data])

  console.log('====ERR', formik)

  return (
    <>
      <form
        action=''
        onSubmit={(e) => {
          e.preventDefault()
          formik.handleSubmit(e)
        }}
      >
        <div className='row'>
          <div className='col-sm-12 mb-8'>
            <Form.Label className='fs-6 fw-semibold text-primary w-100 text-dark text-start'>
              {' '}
              Chapter Name <span className='text-danger'> * </span>{' '}
            </Form.Label>
            <Form.Control
              type='text'
              placeholder=''
              className='ps-0'
              onChange={(e) => formik.setFieldValue('name', e.target.value)}
              value={formik.values.name}
            />
            {formik.touched.name && formik.errors.name && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.name}</span>
                </div>
              </div>
            )}
          </div>

          {/* <div className='col-sm-12 col-md-6 mb-8'>
            <Form.Label className='fs-6 fw-semibold text-primary w-100 text-dark text-start'>
              {' '}
              State <span className='text-danger'> * </span>{' '}
            </Form.Label>
            <Select
              options={stateList}
              className='custom_select mb-3'
              classNamePrefix='Select'
              placeholder='Select State'
              onChange={(e: any) => {
                formik.setFieldValue('city_id', '')
                formik.setFieldValue('state_id', e.value)
              }}
              value={
                formik?.values?.state_id
                  ? stateList.find((x: any) => x.value == formik?.values?.state_id)
                  : []
              }
            />
            {formik.touched.state_id && formik.errors.state_id && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.state_id}</span>
                </div>
              </div>
            )}
          </div>

          <div className='col-sm-12 col-md-6 mb-8'>
            <Form.Label className='fs-6 fw-semibold text-primary w-100 text-dark text-start'>
              {' '}
              City <span className='text-danger'> * </span>{' '}
            </Form.Label>
            <Select
              options={
                formik?.values?.state_id
                  ? cityList.filter((x: any) => x.state_id == formik?.values?.state_id)
                  : []
              }
              className='custom_select mb-3'
              classNamePrefix='Select'
              placeholder='Select City'
              onChange={(e: any) => {
                formik.setFieldValue('city_id', e.value)
              }}
              value={
                formik?.values?.city_id
                  ? cityList.find((x: any) => x.value == formik?.values?.city_id)
                  : []
              }
            />
            {formik.touched.city_id && formik.errors.city_id && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.city_id}</span>
                </div>
              </div>
            )}
          </div>

          <div className='col-12 col-md-6 mb-8'>
            <section className='container px-0'>
              <h4 className='fw-normal fs-6 border-gray-600'>
                {' '}
                Upload Group Photo
                <OverlayTrigger
                  overlay={
                    <Tooltip id='tooltip-disabled' className='text-white'>
                      (Supported files: .jpg, .jpeg, .png <br /> Max File Size: 10MB){' '}
                    </Tooltip>
                  }
                >
                  <span className='d-inline-block'>
                    <i className='fa-solid fs-3 text-primary ms-3 fa-circle-info'></i>
                  </span>
                </OverlayTrigger>
              </h4>
              {error && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{error}</span>
                  </div>
                </div>
              )}
              <div {...getRootProps({className: 'dropzone'})}>
                <input
                  onClick={() => {
                    setError('')
                  }}
                  {...getInputProps()}
                />
                {selectedFile ? (
                  <div style={{position: 'relative'}}>
                    <div>
                      <h2>
                        <i
                          className='fa fa-trash fa-lg text-dark '
                          onClick={(e) => {
                            e.stopPropagation()
                            setSelectedFile(null)
                            formik.setFieldValue('imagename', null)
                          }}
                          style={{
                            position: 'absolute',
                            top: '-42px',
                            right: '-34px',
                            backgroundColor: '#f89494',
                            padding: '4px',
                            borderRadius: '50%',
                            zIndex: '1',
                          }}
                        ></i>
                      </h2>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                      }}
                    >
                      <img
                        src={URL.createObjectURL(selectedFile)}
                        alt='Selected'
                        style={{maxWidth: '100%', maxHeight: '70px'}}
                      />
                      <p>{selectedFile?.name}</p>{' '}
                    </div>
                  </div>
                ) : (
                  <p>Drag 'n' drop some files here, or click to select files</p>
                )}
              </div>
            </section>
            {formik.touched.imagename && formik.errors.imagename && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.imagename}</span>
                </div>
              </div>
            )}
          </div>  */}
        </div>

        <div className='row'>
          <div className='col-md-12 text-center'>
            <button
              type='submit'
              className='btn btn-primary  waves-effect waves-light '
              disabled={isLoading}
            >
              Save
            </button>
            <button
              type='button'
              className='btn btn-info  waves-effect ms-5 waves-light '
              onClick={navigateChapterListing}
              ref={closeModel}
            >
              Cancel
            </button>
          </div>
        </div>
      </form>
    </>
  )
}
export default AddEditChapter
