import axios from 'axios'
import {apiUrl} from '../../../apiurl'
import download from "downloadjs";

const BusinessIndex_URL = `${apiUrl}/Monthlybusinessleaderpoints?filter[include]=user&filter[where][leader_isactive]=1
&filter[where][leader_isdelete]=0`
const Leader_URL = `${apiUrl}/users?filter[where][isdelete]=0&filter[where][isactive]=1`
const GrowthIndex_URL = `${apiUrl}/Monthlyleaderpoints?filter[include]=user&filter[where][leader_isactive]=1
&filter[where][leader_isdelete]=0`
const Broad_URL =`${apiUrl}/Monthlyleaderpoints/getMonthsForGI`
const ExportPDF =`${apiUrl}/Monthlyleaderpoints/exportGIdata`

export function getBusinessIndex(monthFilter: any,yearFilter: any,leaderFilter: any,sortBIColumns: any,orderBIColumns: any,chapter_id: any,skip: any) {
    return axios
      .get<any>(`${BusinessIndex_URL}${monthFilter}${yearFilter}${leaderFilter}&filter[order]=${sortBIColumns} ${orderBIColumns}&filter[where][chapter_id]=${chapter_id}`,)
      .then((responce: any) => {
        return responce
      })
      .catch((error) => {
        console.error('Error All  Monthlybusinessleaderpoints Data:', error)
        throw error
      })
  }

  export function getLeaders(chapter_id: any,) {
    return axios
      .get<any>(`${Leader_URL}&filter[where][chapter_id]=${chapter_id}&filter[order]=name ASC`)
      .then((responce: any) => {
        return responce.data
      })
      .catch((error) => {
        console.error('Error All  Monthlybusinessleaderpoints Data:', error)
        throw error
      })
  }
  export function getBoardGI() {
    return axios
      .get<any>(`${Broad_URL}`)
      .then((responce: any) => {
        return responce.data
      })
      .catch((error) => {
        console.error('Error All  Monthlybusinessleaderpoints Data:', error)
        throw error
      })
  }

  export function getGrowthIndex(monthFilter: any,yearFilter: any,leaderFilter: any,sortGIColumns: any,orderGIColumns: any,chapter_id: any,skip: any) {
    return axios
      .get<any>(`${GrowthIndex_URL}${leaderFilter}${yearFilter}${monthFilter}&filter[order]=${sortGIColumns} ${orderGIColumns}&filter[where][chapter_id]=${chapter_id}`)
      .then((responce: any) => {
        return responce.data
      })
      .catch((error) => {
        console.error('Error All  Monthlybusinessleaderpoints Data:', error)
        throw error
      })
  }
  export function getExportReportPdf(month: any,year: any,chapter_id: any) {
    return axios
      .get<any>(`${ExportPDF}?filter[where][leader_isdelete]=0&filter[where][chapter_id]=${chapter_id}&filter[where][month]=${month}&filter[where][year]=${year}`,{responseType: 'arraybuffer', })
      .then((responce: any) => {
        return responce
      })
      .catch((error) => {
        console.error('Error All  Monthlybusinessleaderpoints Data:', error)
        throw error
      })
  }