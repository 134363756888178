import React, {useEffect, useState, useContext} from 'react'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {Button} from 'react-bootstrap'
import {apiUrl} from '../../../apiurl'
import InfiniteScroll from 'react-infinite-scroll-component'
import {UserContext} from '../../../contextStore'
import moment from 'moment'
import {
  getBusinessIndex,
  getGrowthIndex,
  getBoardGI,
  getLeaders,
  getExportReportPdf,
} from './_requests'
import IndexDetails from './IndexDetails'
import IndexDetailsBI from './IndexDetailsBI'
import download from 'downloadjs'
// import IndexDetailsBI from './IndexDetails';
const IndexTab = () => {
  const [bussinessIndex, setBussinessIndex] = useState<any>([])
  const [hasMoreForBI, setHasMoreForBI] = useState(true)
  const [skipForBI, setSkipForBI] = useState<any>(0)
  const [sortBIColumns, setSortBIColumns] = useState<any>('total_points')
  const [orderBIColumns, setOrderBIColumns] = useState<any>('desc')
  const [monthBI, setMonthBI] = useState<any>(moment().format('M'))
  const [yearBI, setYearBI] = useState<any>(moment().format('YYYY'))
  const [leaderIdBI, setLeaderIdBI] = useState<any>('')
  const [BIurlchange, setBIurlchange] = useState<any>(false)
  // useState Growth Index
  const [growthIndex, setGrowthIndex] = useState<any>([])
  const [hasMoreForGI, setHasMoreForGI] = useState(true)
  const [leaderIdGI, setLeaderIdGI] = useState<any>('')
  const [skipForGI, setSkipForGI] = useState<any>(0)
  const [sortGIColumns, setSortGIColumns] = useState<any>('total_points')
  const [orderGIColumns, setOrderGIColumns] = useState<any>('desc')
  const [GIurlchange, setGIurlchange] = useState<any>(false)
  const [monthGI, setMonthGI] = useState<any>(moment().format('M'))
  const [yearGI, setYearGI] = useState<any>(moment().format('YYYY'))
  // GI Broad
  const [broadGI, setBrodGI] = useState<any>([])
  const [yearsList, setYearsList] = useState([{value: '', label: ''}])

  //Pdfdownload is loading usestatus =
  const [pdfLoading, setPdfLoading] = useState<any>(0)

  const [leaderData, setLeaderData] = useState<any>([])
  const {userDetail, setMessage, chapterID, reload} = useContext(UserContext)
  const chapter_id = chapterID ?? ''
  const limit = 10

  useEffect(() => {
    fetchBussinessIndex()
  }, [sortBIColumns, orderBIColumns, leaderIdBI, monthBI, yearBI, reload])
  // useEffect(() => {
  //   fetchLeaders()
  //   boardGI()
    // fetchBussinessIndex()
    // fetchGrowthIndex()
  // }, [])

  const currentYear = moment().format('YYYY')

  useEffect(() => {
    setSkipForGI(0)
    setSkipForBI(0)
    setBIurlchange(true)
    setGIurlchange(true)
    fetchLeaders()
    boardGI()
    // fetchBussinessIndex()
    // fetchGrowthIndex()
  }, [reload])

  useEffect(() => {
    getYearsArray()
  }, [])

  function getYearsArray() {
    let yearsArray = []
    for (let year = 2021; year <= new Date().getFullYear(); year++) {
      yearsArray.push({value: year.toString(), label: year.toString()})
    }
    setYearsList(yearsArray)
  }

  useEffect(() => {
    fetchGrowthIndex()
  }, [leaderIdGI, orderGIColumns, monthGI, sortGIColumns, yearGI, reload])
  const fetchGrowthIndex = async () => {
    try {
      let leaderFilterGI = leaderIdGI ? `&filter[where][leader_id]=${leaderIdGI}` : ''
      let monthFilter = monthGI ? `&filter[where][month]=${monthGI}` : ``
      let yearFilter = yearGI ? `&filter[where][year]=${yearGI}` : ``
      // const resData=await axios.get(`${GrowthIndex_URL}${leaderFilterGI}${yearFilter}${monthFilter}&filter[limit]=10&filter[order]=${sortGIColumns} ${orderGIColumns}&filter[where][chapter_id]=${chapter_id}&filter[skip]=${skipForGI}`)
      const resData = await getGrowthIndex(
        monthFilter,
        yearFilter,
        leaderFilterGI,
        sortGIColumns,
        orderGIColumns,
        chapter_id,
        skipForGI
      )

      // let newDataGI = resData ?? []
      setGrowthIndex(resData ?? [])
      // if (GIurlchange) {
      //   if (newDataGI.length === 0) {
          // setGrowthIndex(newDataGI)
      //     setHasMoreForGI(false)
      //   } else {
      //     setGrowthIndex(newDataGI)
      //     setSkipForGI(skipForGI + limit)
      //   }
      //   setGIurlchange(false)
      // } else {
      //   if (newDataGI.length === 0) {
      //     setHasMoreForGI(false)
      //     setGrowthIndex([...growthIndex, ...newDataGI])
      //   } else {
      //     setGrowthIndex([...growthIndex, ...newDataGI])
      //     setSkipForGI(skipForGI + limit)
      //   }
      //   setGIurlchange(false)
      // }
    } catch (error) {}
  }

  // fetch the Data for BI
  const fetchBussinessIndex = async () => {
    try {
      let leaderFilter = leaderIdBI ? `&filter[where][leader_id]=${leaderIdBI}` : ''
      let monthFilter = monthBI ? `&filter[where][month]=${monthBI}` : ``
      let yearFilter = yearBI ? `&filter[where][year]=${yearBI}` : ``
      // const resData=await axios.get(`${BusinessIndex_URL}${monthFilter}${yearFilter}${leaderFilter}&filter[limit]=10&filter[order]=${sortBIColumns} ${orderBIColumns}&filter[where][chapter_id]=${chapter_id}&filter[skip]=${skipForBI}`)
      const resDATA = await getBusinessIndex(
        monthFilter,
        yearFilter,
        leaderFilter,
        sortBIColumns,
        orderBIColumns,
        chapter_id,
        skipForBI
      )

      // let newData = resDATA?.data ?? []

      setBussinessIndex(resDATA?.data ?? [])
      // if (BIurlchange) {
      //   if (newData.length === 0) {
      //     setBussinessIndex(newData)
      //     setHasMoreForBI(false)
      //   } else {
          // setBussinessIndex(newData)
      //     setSkipForBI(skipForBI + limit)
      //   }
      //   setBIurlchange(false)
      // } else {
      //   if (newData.length === 0) {
      //     setHasMoreForBI(false)
      //     setBussinessIndex([...bussinessIndex, ...newData])
      //   } else {
      //     setBussinessIndex([...bussinessIndex, ...newData])
      //     setSkipForBI(skipForBI + limit)
      //   }
      //   setBIurlchange(false)
      // }
      // if(newData.length === 0){
      //   setHasMoreForBI(false);
      // }else{
      //   setBussinessIndex([...bussinessIndex, ...newData]);p
      //   setSkipForBI(skipForBI + limit);
      // }
    } catch (error) {}
  }

  const boardGI = async () => {
    try {
      const resData = await getBoardGI()
      setBrodGI(resData ?? [])
    } catch (error) {}
  }
  const fetchLeaders = async () => {
    try {
      const resData = await getLeaders(chapter_id)

      setLeaderData(resData ?? [])
    } catch (error) {}
  }

  const exportGIReportPDF = async (year: any, month: any, chapertId: any) => {
    await getExportReportPdf(month, year, chapertId)
      .then((res: any) => {
        download(res.data, 'downloaded_file.pdf', 'application/download')
        setMessage('success', 'GI file downloaded successfully')
        setPdfLoading(0)
      })
      .catch(() => {
        setMessage('error', 'GI file download failed')
        setPdfLoading(0)
      })
  }
  const sortByPoint = [
    {value: 'total_points', label: 'Total Points'},
    {value: 'present_count', label: 'Attendance'},
    {value: 'visitors_invited', label: 'Visitor Invited'},
    {value: 'visitors_attended', label: 'Visitor Attended'},
    {value: 'visitors_joined', label: "Member's Joined"},
    {value: 'thankyou_given', label: 'Thank You Given'},
    {value: 'group_meeting_attended', label: 'Group Meeting Report'},
    {value: 'feedbackgiven_count', label: 'Feedback Given'},
    {value: 'help_posts', label: 'Help'},
    {value: 'tracksheet_count', label: 'Tracksheet'},
    {value: 'icaniwill_count', label: 'I CAN I WILL'},
    {value: 'mr_tracking', label: 'MR Tracking'},
  ]
  const monthsArray = [
    {value: '1', label: 'January'},
    {value: '2', label: 'February'},
    {value: '3', label: 'March'},
    {value: '4', label: 'April'},
    {value: '5', label: 'May'},
    {value: '6', label: 'June'},
    {value: '7', label: 'July'},
    {value: '8', label: 'August'},
    {value: '9', label: 'September'},
    {value: '10', label: 'October'},
    {value: '11', label: 'November'},
    {value: '12', label: 'December'},
  ]
  const biSortByPoint = [
    { value: 'total_points', label: 'Total Points' },
    { value: 'business_referral_sent', label: 'Business Referral Sent' },
    { value: 'business_referral_received', label: 'Business Referral Received' },
    { value: 'business_connect_sent', label: 'Business Connect Sent' },
    { value: 'business_connect_received', label: 'Business Connect Received' },
    { value: 'business_generate_sent', label: 'Business Generate Sent' },
    { value: 'business_generate_received', label: 'Business Generate Received' },
  ];
  const orderOptions = [
    {value: 'desc', label: 'Descending'},
    {value: 'asc', label: 'Ascending'},
  ]
  return (
    <>
      <ul className='nav nav-tabs index-tab' id='myTab' role='tablist'>
        <li className='nav-item' role='presentation'>
          <button
            className='nav-link active'
            id='home-tab'
            data-bs-toggle='tab'
            data-bs-target='#home'
            type='button'
            role='tab'
            aria-controls='home'
            aria-selected='true'
          >
            Growth
          </button>
        </li>
        <li className='nav-item' role='presentation'>
          <button
            className='nav-link'
            id='profile-tab'
            data-bs-toggle='tab'
            data-bs-target='#profile'
            type='button'
            role='tab'
            aria-controls='profile'
            aria-selected='false'
            onClick={() => {
              fetchBussinessIndex()
            }}
          >
            Business
          </button>
        </li>
      </ul>

      <div className='tab-content ' id='myTabContent'>
        <div
          className='tab-pane fade show active'
          id='home'
          role='tabpanel'
          aria-labelledby='home-tab'
        >
          <div className='pt-8 px-5 pb-5 px-7 index-hdr justify-content-between report-box card ilead__card'>
            <h4 className='fw-bold text-start fs-4 '> Export Average GI Reports </h4>
            <div className='row  mx-0 pt-8  pb-3'>
              <div className='col-4'>
                <div className='d-flex justify-content-between p-5 box-hdr'>
                  <div className='w-dot'></div>
                  <div className='w-dot'></div>
                </div>
                <div className='report-month p-5'>
                  {pdfLoading != 1 && (
                    <h3
                      className='fw-bold fs-4 text-dark text-center  text-uppercase'
                      style={{cursor: 'pointer'}}
                      onClick={() => {
                        if (broadGI?.length > 0) {
                          setPdfLoading(1)
                          exportGIReportPDF(broadGI[0]?.yearname, broadGI[0]?.monthNo, chapter_id)
                        }
                      }}
                    >
                      {broadGI?.length > 0
                        ? ` ${broadGI[0]?.monthname} ${broadGI[0]?.yearname}`
                        : '-  -'}
                    </h3>
                  )}

                  {pdfLoading == 1 && (
                    <>
                      <h3 className='fw-bold fs-4 text-dark text-center  text-uppercase'>
                        Please Wait..{' '}
                      </h3>
                    </>
                  )}
                </div>
              </div>

              <div className='col-4 '>
                <div className='d-flex justify-content-between p-5 box-hdr'>
                  <div className='w-dot'></div>
                  <div className='w-dot'></div>
                </div>
                <div className='report-month p-5'>
                  {pdfLoading != 2 && (
                    <>
                      <h3
                        className='fw-bold fs-4 text-dark text-center text-uppercase'
                        style={{cursor: 'pointer'}}
                        onClick={() => {
                          if (broadGI?.length > 1) {
                            setPdfLoading(2)
                            exportGIReportPDF(broadGI[1]?.yearname, broadGI[1]?.monthNo, chapter_id)
                          }
                        }}
                      >
                        {broadGI?.length > 1
                          ? ` ${broadGI[1]?.monthname} ${broadGI[1]?.yearname}`
                          : '-  -'}
                      </h3>
                    </>
                  )}
                  {pdfLoading == 2 && (
                    <>
                      <h3 className='fw-bold fs-4 text-dark text-center text-uppercase'>
                        Please Wait..
                      </h3>
                    </>
                  )}

                  {/* <h3 className='fw-bold fs-4 text-dark text-center text-uppercase' style={{cursor: "pointer"}}
                    onClick={() => {
                      if (broadGI?.length > 1) {
                        exportGIReportPDF(broadGI[1]?.yearname, broadGI[1]?.monthNo, chapter_id)
                      }
                    }}
                  >
                    {broadGI?.length > 1 ? ` ${broadGI[1]?.monthname} ${broadGI[1]?.yearname}` : '-  -'}
                  </h3> */}
                </div>
              </div>

              <div className='col-4'>
                <div className='d-flex justify-content-between p-5 box-hdr'>
                  <div className='w-dot'></div>
                  <div className='w-dot'></div>
                </div>
                <div className='report-month p-5'>
                  {pdfLoading != 3 && (
                    <>
                      <h3
                        className='fw-bold fs-4 text-dark text-center  text-uppercase'
                        style={{cursor: 'pointer'}}
                        onClick={() => {
                          if (broadGI?.length > 2) {
                            setPdfLoading(3)
                            exportGIReportPDF(broadGI[2]?.yearname, broadGI[2]?.monthNo, chapter_id)
                          }
                        }}
                      >
                        {broadGI?.length > 2
                          ? ` ${broadGI[2]?.monthname} ${broadGI[2]?.yearname}`
                          : '-  -'}{' '}
                      </h3>
                    </>
                  )}

                  {pdfLoading == 3 && (
                    <>
                      <h3 className='fw-bold fs-4 text-dark text-center text-uppercase'>
                        Please Wait..
                      </h3>
                    </>
                  )}
                  {/* <h3
                    className='fw-bold fs-4 text-dark text-center  text-uppercase'
                    style={{cursor: 'pointer'}}
                    onClick={() => {
                      if (broadGI?.length > 2) {
                        exportGIReportPDF(broadGI[2]?.yearname, broadGI[2]?.monthNo, chapter_id)
                      }
                    }}
                  >
                    {broadGI?.length > 2
                      ? ` ${broadGI[2]?.monthname} ${broadGI[2]?.yearname}`
                      : '-  -'}{' '}
                  </h3>{' '} */}
                </div>
              </div>
            </div>
          </div>

          <div className='row  mx-0 mt-5 pt-8 px-5 pb-3 index-hdr justify-content-between tyca-hdr card ilead__card'>
            <h4 className='text-start text-dark fw-bold mb-6'> View Growth Index </h4>
            <div className='row'>
              <div className='col-md-2 mb-3'>
                <div className='filter-div  index-filter mx-0'>
                  <div className='fltr-lbl'>
                    {' '}
                    <h4 className='text-dark fw-bold'> Month </h4>{' '}
                  </div>
                  <div className=' card rounded-0 py-3'>
                    <select
                      className='form-select px-5 py-3 form-select-sm '
                      aria-label='.form-select-sm example'
                      onChange={(event) => {
                        setGIurlchange(true)
                        setMonthGI(event.target.value)
                        setSkipForGI(0)
                      }}
                    >
                      {monthsArray.map((month, index) => (
                        <option key={index} value={month.value} selected={monthGI == month.value}>
                          {month.label}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
              <div className='col-md-2 mb-3'>
                <div className='filter-div  index-filter mx-0'>
                  <div className='fltr-lbl'>
                    {' '}
                    <h4 className='text-dark fw-bold'> Year </h4>{' '}
                  </div>
                  <div className=' card rounded-0 py-3'>
                    <select
                      className='form-select px-5 py-3 form-select-sm '
                      aria-label='.form-select-sm example'
                      onChange={(event: any) => {
                        setGIurlchange(true)
                        setYearGI(event.target.value)
                        setSkipForGI(0)
                        // setHasMoreForGI(true)
                      }}
                    >
                      {yearsList.map((yearOption:any, index) => (
                        <option key={index} value={yearOption.value} selected={yearGI === yearOption.value}>
                          {yearOption.label}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
              <div className='col-md-2 mb-3'>
                <div className='filter-div  index-filter mx-0'>
                  <div className='fltr-lbl'>
                    {' '}
                    <h4 className='text-dark fw-bold'> Select Leader Name </h4>{' '}
                  </div>
                  <div className=' card rounded-0 py-3'>
                    <select
                      className='form-select px-5 py-3 form-select-sm '
                      aria-label='.form-select-sm example'
                      onChange={(event: any) => {
                        setGIurlchange(true)
                        setLeaderIdGI(event.target.value)
                        setSkipForGI(0)
                        // setHasMoreForGI(true)
                      }}
                    >
                      <option selected value=''>
                        All Leaders
                      </option>
                      {leaderData?.map((leader: any, i: any) => {
                        return <option value={leader?.id}> {leader?.name} </option>
                      })}
                    </select>
                  </div>
                </div>
              </div>
              <div className='col-md-2 mb-3'>
                <div className='filter-div  index-filter mx-0'>
                  <div className='fltr-lbl'>
                    {' '}
                    <h4 className='text-dark fw-bold'> Sort By Points </h4>{' '}
                  </div>
                  <div className=' card rounded-0 py-3'>
                    <select
                      className='form-select px-5 py-3 form-select-sm '
                      aria-label='.form-select-sm example'
                      onChange={(event) => {
                        setGIurlchange(true)
                        setSortGIColumns(event.target.value)
                        setSkipForGI(0)
                      }}
                    >
                      {sortByPoint.map((option, index) => (
                        <option
                          key={index}
                          value={option.value}
                          selected={sortGIColumns === option.value}
                        >
                          {option.label}
                        </option>
                      ))}
                    </select>
                   
                  </div>
                </div>
              </div>
              <div className='col-md-2 mb-3'>
                <div className='filter-div  index-filter mx-0'>
                  <div className='fltr-lbl'>
                    {' '}
                    <h4 className='text-dark fw-bold'> Sort Order </h4>{' '}
                  </div>
                  <div className=' card rounded-0 py-3'>
                    <select
                      className='form-select px-5 py-3 form-select-sm '
                      aria-label='.form-select-sm example'
                      onChange={(event) => {
                        setGIurlchange(true)
                        setOrderGIColumns(event.target.value)
                        setSkipForGI(0)
                      }}
                    >
                      {orderOptions.map((option, index) => (
                        <option
                          key={index}
                          value={option.value}
                          selected={orderGIColumns === option.value}
                        >
                          {option.label}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='row mt-7 justify-content-between tyca-hdr mb-view-bg-trans bg-transparent'>
            {/* <InfiniteScroll
              dataLength={growthIndex?.length}
              next={fetchGrowthIndex}
              hasMore={hasMoreForGI}
              // height={600}
              loader={<></>}
            > */}
              <div className='growth-listing row'>
                {growthIndex?.length > 0 ? (
                  growthIndex?.map((DataGI: any, i: any) => {
                    let profile = DataGI?.user?.profile
                    return (
                      <div className='bg-transparent col-xl-6 d-flex -nowrap mb-5' key={i}>
                        <div className='card ilead__card w-100'>
                          <div className='card-body p-5 row'>
                            <div className='d-flex justify-content-between'>
                              <div className='d-flex text-truncate'>
                                <div
                                  className='symbol symbol-md-90px symbol-md-100px me-4'
                                  data-bs-toggle='modal'
                                  data-bs-target={`#index_detail_modal${i}`}
                                >
                                  <img
                                    src={
                                      profile
                                        ? `${apiUrl}/containers/userprofile/download/${profile}`
                                        : `${toAbsoluteUrl('./media/avatars/default-user.png')}`
                                    }
                                    alt={`Not Found.`}
                                    onError={({currentTarget}: any) => {
                                      currentTarget.onerror = null // prevents looping
                                      currentTarget.src = `${toAbsoluteUrl(
                                        './media/avatars/default-user.png'
                                      )}`
                                    }}
                                  />
                                </div>
                                <div
                                  className='leader__details text-truncate'
                                  data-bs-toggle='modal'
                                  data-bs-target={`#index_detail_modal${i}`}
                                >
                                  <h3 className='text-truncate'>{DataGI?.user?.name}</h3>
                                  <span className='leader__company text-truncate text-black d-block'>
                                    {DataGI?.user?.companyname}
                                  </span>
                                </div>
                              </div>
                              <Button
                                type='button'
                                data-bs-toggle='modal'
                                data-bs-target={`#index_detail_modal${i}`}
                              >
                                <h5 className='fw-semibold fe-6 text-center text-white'>
                                  {DataGI?.total_points}
                                </h5>
                                <p className='fw-normal fe-7 mb-0 text-center'> Point </p>
                              </Button>
                            </div>
                          </div>
                        </div>
                        <IndexDetails dataGI={DataGI} i={i} />
                      </div>
                    )
                  })
                ) : (
                  <h4>Data Not Found</h4>
                )}
              </div>
            {/* </InfiniteScroll> */}
          </div>
        </div>

        <div className='tab-pane fade' id='profile' role='tabpanel' aria-labelledby='profile-tab'>
          <div className='row  mx-0 mt-5 pt-8 px-5 pb-3 index-hdr justify-content-between tyca-hdr'>
            <h4 className='text-start text-dark fw-bold mb-6'> View Business Index </h4>
            <div className='col-md-2 mb-3'>
              <div className='filter-div  index-filter mx-0'>
                <div className='fltr-lbl'>
                  {' '}
                  <h4 className='text-dark fw-bold'> Month </h4>{' '}
                </div>
                <div className=' card rounded-0 py-3'>
                <select
                      className='form-select px-5 py-3 form-select-sm '
                      aria-label='.form-select-sm example'
                      onChange={(event) => {
                        setBIurlchange(true)
                        setMonthBI(event.target.value)
                        setSkipForBI(0)
                      }}
                    >
                      {monthsArray.map((month, index) => (
                        <option key={index} value={month.value} selected={monthBI == month.value}>
                          {month.label}
                        </option>
                      ))}
                    </select>
                </div>
              </div>
            </div>
            <div className='col-md-2 mb-3'>
              <div className='filter-div  index-filter mx-0'>
                <div className='fltr-lbl'>
                  {' '}
                  <h4 className='text-dark fw-bold'> Year </h4>{' '}
                </div>
                <div className=' card rounded-0 py-3'>
                  <select
                    className='form-select px-5 py-3 form-select-sm '
                    aria-label='.form-select-sm example'
                    onChange={(event: any) => {
                      setYearBI(event.target.value)
                    }}
                  >
                    {yearsList.map((yearOption:any, index) => (
                        <option key={index} value={yearOption.value} selected={yearBI === yearOption.value}>
                          {yearOption.label}
                        </option>
                      ))}
                  </select>
                </div>
              </div>
            </div>

            <div className='col-md-2 mb-3'>
              <div className='filter-div  index-filter mx-0'>
                <div className='fltr-lbl'>
                  {' '}
                  <h4 className='text-dark fw-bold'> Select Leader Name </h4>{' '}
                </div>
                <div className=' card rounded-0 py-3'>
                  <select
                    className='form-select px-5 py-3 form-select-sm '
                    aria-label='.form-select-sm example'
                    onChange={(event: any) => {
                      setBIurlchange(true)
                      setLeaderIdBI(event.target.value)
                      setSkipForBI(0)
                    }}
                  >
                    <option selected value=''>
                      All Leaders
                    </option>
                    {leaderData?.map((leader: any, i: any) => {
                      return <option value={leader?.id}> {leader?.name} </option>
                    })}
                  </select>
                </div>
              </div>
            </div>
            <div className='col-md-2 mb-3'>
              <div className='filter-div  index-filter mx-0'>
                <div className='fltr-lbl'>
                  {' '}
                  <h4 className='text-dark fw-bold'> Sort By Points </h4>{' '}
                </div>
                <div className=' card rounded-0 py-3'>
                <select
      className='form-select px-5 py-3 form-select-sm '
      aria-label='.form-select-sm example'
      onChange={(event) => {
        setBIurlchange(true);
        setSortBIColumns(event.target.value);
        setSkipForBI(0);
      }}
    >
      {biSortByPoint.map((option, index) => (
        <option key={index} value={option.value} selected={sortBIColumns === option.value}>
          {option.label}
        </option>
      ))}
    </select>
                </div>
              </div>
            </div>
            <div className='col-md-2 mb-3'>
              <div className='filter-div  index-filter mx-0'>
                <div className='fltr-lbl'>
                  {' '}
                  <h4 className='text-dark fw-bold'> Sort Order </h4>
                </div>
                <div className=' card rounded-0 py-3'>
                <select
                      className='form-select px-5 py-3 form-select-sm '
                      aria-label='.form-select-sm example'
                      onChange={(event) => {
                        setBIurlchange(true)
                        setOrderBIColumns(event.target.value)
                        setSkipForBI(0)
                      }}
                    >
                      {orderOptions.map((option, index) => (
                        <option
                          key={index}
                          value={option.value}
                          selected={orderBIColumns === option.value}
                        >
                          {option.label}
                        </option>
                      ))}
                    </select>
                </div>
              </div>
            </div>
          </div>

          <div className='row mt-7 justify-content-between tyca-hdr mb-view-bg-trans bg-transparent'>
            {/* <InfiniteScroll
              dataLength={bussinessIndex?.length}
              next={fetchBussinessIndex}
              hasMore={hasMoreForBI}
              // height={600}
              loader={<></>}
            > */}
              <div className='growth-listing row'>
                {bussinessIndex?.length > 0 ? (
                  bussinessIndex?.map((Data: any, i: any) => {
                    let profile = Data?.user?.profile
                    return (
                      <div className='bg-transparent col-xl-6 d-flex -nowrap mb-5' key={i}>
                        <div className='card ilead__card w-100'>
                          <div className='card-body p-5 row'>
                            <div className='d-flex justify-content-between'>
                              <div className='d-flex text-truncate'>
                                <div
                                  className='symbol symbol-md-90px symbol-md-100px me-4'
                                  data-bs-toggle='modal'
                                  data-bs-target={`#index_detail_modal11${i}`}
                                >
                                  <img
                                    src={
                                      profile
                                        ? `${apiUrl}/containers/userprofile/download/${profile}`
                                        : `${toAbsoluteUrl('./media/avatars/default-user.png')}`
                                    }
                                    alt={`Not Found.`}
                                    onError={({currentTarget}: any) => {
                                      currentTarget.onerror = null // prevents looping
                                      currentTarget.src = `${toAbsoluteUrl(
                                        './media/avatars/default-user.png'
                                      )}`
                                    }}
                                  />
                                </div>

                                <div
                                  className='leader__details text-truncate'
                                  data-bs-toggle='modal'
                                  data-bs-target={`#index_detail_modal11${i}`}
                                >
                                  <h3 className='text-truncate'> {Data?.user?.name}</h3>
                                  <span className='leader__company text-truncate text-black d-block'>
                                    {Data?.user?.companyname}
                                  </span>
                                </div>
                              </div>
                              <Button
                                type='button'
                                data-bs-toggle='modal'
                                data-bs-target={`#index_detail_modal11${i}`}
                              >
                                <h5 className='fw-semibold fe-6 text-center text-white'>
                                  {Data?.total_points}
                                </h5>
                                <p className='fw-normal fe-7 mb-0 text-center'> Point </p>
                              </Button>
                            </div>
                          </div>
                        </div>
                        <IndexDetailsBI dataBI={Data} i={i} />
                      </div>
                    )
                  })
                ) : (
                  <h4>Data Not Found</h4>
                )}
              </div>
            {/* </InfiniteScroll> */}
          </div>
        </div>
      </div>
    </>
  )
}

export {IndexTab}
