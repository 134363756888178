// import { ID, Response } from "../../../../../_metronic/helpers"

import { ID, Response } from "../../../../../../_metronic/helpers"

// import {ID, Response} from '../../../../../../_metronic/helpers'
export type Requestuser = {
  id?: ID
  name?: string
  avatar?: string
  position?: string
  role?: string
  chapter_name?:string
  leader_name?:string
  mobile?:string
  email?:string
  company_name?:string
  otp?:string
  active?:string
 
}

export type RequestusersQueryResponse = Response<Array<Requestuser>>

export const initialRequestuser: Requestuser = {
  avatar: 'avatars/300-6.jpg',
  position: 'Art Director',
  role: 'Administrator',
  name: '',
  email: '',

}
