import axios, {AxiosResponse} from 'axios'
// import {ID, Response} from '../../../../../../_metronic/helpers'
import {Notification, NotificationsQueryResponse} from './_models'
import { ID, Response } from '../../../../../../_metronic/helpers'
import { apiUrl } from '../../../../../../apiurl'
// import { ID, Response } from '../../../../../_metronic/helpers'

const API_URL = apiUrl
const NOTIFICATION_URL = `${API_URL}/messages/listNotification`
const NOTIFICATION_TYPE_URL = `${API_URL}/notificationtypes/listNotificationType`
const GET_NOTIFICATIONS_URL = `${API_URL}/users/query`


const getNotifications = (query: any): Promise<any> => {
  return axios
    .get(`${NOTIFICATION_URL}?${query}`)
    .then((d: AxiosResponse<any>) => d.data)
}

const getNotificationType = (): Promise<any> => {
  return axios
    .get(`${NOTIFICATION_TYPE_URL}`)
    .then((d: AxiosResponse<any>) => d.data)
}

const getNotificationById = (id: ID): Promise<Notification | undefined> => {
  return axios
    .get(`${NOTIFICATION_URL}/${id}`)
    // .then((response: AxiosResponse<Response<Notification>>) => response.data).catch((e) => console.log(e))
    .then((response: Response<any>) => response.data).catch((e) => console.log(e))
}

const createNotification = (notification: Notification): Promise<Notification | undefined> => {
  return axios
    .put(NOTIFICATION_URL, notification)
    // .then((response: AxiosResponse<Response<Notification>>) => response.data).catch((e) => console.log(e))
    .then((response: Response<any>) => response.data).catch((e) => console.log(e))
}

const updateNotification = (notification: Notification): Promise<Notification | undefined> => {
  return axios
    .post(`${NOTIFICATION_URL}/${notification.id}`, notification)
    // .then((response: AxiosResponse<Response<Notification>>) => response.data).catch((e) => console.log(e))
    .then((response: Response<any>) => response.data).catch((e) => console.log(e))
}

const deleteNotification = (notificationId: ID): Promise<void> => {
  return axios.delete(`${NOTIFICATION_URL}/${notificationId}`).then(() => {})
}

const deleteSelectedNotifications = (notificationIds: Array<ID>): Promise<void> => {
  const requests = notificationIds.map((id) => axios.delete(`${NOTIFICATION_URL}/${id}`))
  return axios.all(requests).then(() => {})
}

export {getNotifications, deleteNotification, deleteSelectedNotifications, 
  getNotificationById, createNotification, updateNotification,
  getNotificationType
}
