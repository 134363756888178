import { createRoot } from 'react-dom/client'
// Axios
import axios from 'axios'
import { Chart, registerables } from 'chart.js'
import { QueryClient, QueryClientProvider } from 'react-query'
// import { ReactQueryDevtools } from 'react-query/devtools'
// Apps
import { MetronicI18nProvider } from './_metronic/i18n/Metronici18n'
import './_metronic/assets/fonticon/fonticon.css'
import './_metronic/assets/keenicons/duotone/style.css'
import './_metronic/assets/keenicons/outline/style.css'
import './_metronic/assets/keenicons/solid/style.css'
/**
 * TIP: Replace this style import with rtl styles to enable rtl mode
 *
 * import './_metronic/assets/css/style.rtl.css'
 **/
import './_metronic/assets/sass/style.scss'
import './_metronic/assets/sass/plugins.scss'
import './_metronic/assets/sass/style.react.scss'
import { AppRoutes } from './app/routing/AppRoutes'
import { AuthProvider, setupAxios } from './app/modules/auth'
import serviceWorkerDev from "../src/service-worker-dev"
import { CometChat } from "@cometchat-pro/chat"
import { COMETCHAT_CONSTANTS } from './consts';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import reducer from './store/reducer';
import NoInternetConnection from './noInternetConnection'
import { ContextStore } from './contextStore'
import { ignoreAPI } from './utils/ignoreAPI'

axios.interceptors.request.use(function (config: any) {
  let token = localStorage.getItem('authToken') as any
  if (token) {
    config.headers.accesstoken = token
  }
  return config
})

let clearCacheData = async () => {
  await caches.keys().then((names:any) => {
    names.forEach((name:any) => {
        caches.delete(name);
    });
  });
  localStorage.clear()
  // window.location.reload()
}; 

axios.interceptors.response.use(
  function (response) {
    return response
  },
  function (error) {
    let apis = JSON.stringify(error.response.config.url)
    apis = apis.toLowerCase()
    if (error?.response?.status === 404 && !ignoreAPI.find((x) => apis.match(x))) {
      clearCacheData()
      window.location.href = '/login'
    }
    return Promise.reject(error)
  }
)
// axios.defaults.headers.common['accesstoken'] = localStorage.getItem('authToken') as any;

/**
 * Creates `axios-mock-adapter` instance for provided `axios` instance, add
 * basic Metronic mocks and returns it.
 *
 * @see https://github.com/ctimmerm/axios-mock-adapter
 */
/**
 * Inject Metronic interceptors for axios.
 *
 * @see https://github.com/axios/axios#interceptors
 */

axios.interceptors.response.use(
  function (response: any) {
    return response
  },
  function (error: any) {
    if (error?.response?.status === 401) {
      clearCacheData()
      localStorage.clear()
      setTimeout(() => {
        window.location.reload()
        window.location.href = '/login'
      }, 1000)
    }
    return Promise.reject(error)
  }
)

setupAxios(axios)
Chart.register(...registerables)
serviceWorkerDev()

const store = createStore(reducer, compose(applyMiddleware(thunk)))

var appID = COMETCHAT_CONSTANTS.APP_ID
var region = COMETCHAT_CONSTANTS.REGION

var appSetting = new CometChat.AppSettingsBuilder()
  .subscribePresenceForAllUsers()
  .setRegion(region)
  .build()
CometChat.init(appID, appSetting).then(
  () => {
    if (CometChat.setSource) {
      CometChat.setSource('ui-kit', 'web', 'reactjs')
    }
    console.log('Initialization completed successfully')
  },
  (error) => {
    console.log('Initialization failed with error:', error)
    // Check the reason for error and take appropriate action.
  }
)

const queryClient = new QueryClient()
const container = document.getElementById('root')
if (container) {
  createRoot(container).render(
    <NoInternetConnection>
      <ContextStore>
        <Provider store={store}>
          <QueryClientProvider client={queryClient}>
            <MetronicI18nProvider>
              <AuthProvider>
                <AppRoutes />
              </AuthProvider>
            </MetronicI18nProvider>
          </QueryClientProvider>
        </Provider>
      </ContextStore>
    </NoInternetConnection>
  )
}
