import React, { useEffect } from "react";
import { toAbsoluteUrl } from "../../../_metronic/helpers";
import { Button } from "react-bootstrap";
import { apiUrl } from "../../../apiurl";




const IndexDetails = ( { dataGI, i } :{ dataGI:any,i:any}) => {
  useEffect(() => {
    window.history.pushState(null, 'i can i will', window.location.href)
    window.addEventListener('popstate', function (event: any) {
      // closebutton.current.click()
    })
  })
  useEffect(() => {
    window.history.pushState(null, 'i can i will', window.location.href)
    window.addEventListener('popstate', function (event: any) {
      // closebutton.current.click()
    })
  })
  useEffect(() => {
    window.history.pushState(null, 'i can i will', window.location.href)
    window.addEventListener('popstate', function (event: any) {
      // closebutton.current.click()
    })
  })

  const clickOnbutton = () => {
    window.history.pushState({}, 'i can i will', `${window.location.origin}/dashboard`);
    window.history.pushState({}, 'i can i will', `${window.location.origin}/dashboard`);
  }

  return (

    <>
      <div className='modal fade reset-date-psw index_detail_modal_class' tabIndex={-1} id={`index_detail_modal${i}`}>
        <div className='modal-dialog  growth-listing'>
          <div className='modal-content'>
            {/* <div className='modal-header'>
              <button
                type='button'
                className='text-white border-0 bg-transparent close-btn'
                data-bs-dismiss='modal'
                aria-label='Close'
              > <i className="fa-solid fa-xmark fs-2 text-dark" ></i> </button>
            </div> */}
            <div className='modal-header py-5'>
            <h3 className="modal-title fs-3 fw-bold" style={ {color:"white"}} id="writePostModalToggleLabel"> Growth Index Detail </h3>
              <button
                type='button'
                className='text-white border-0 bg-transparent close-btn'
                data-bs-dismiss='modal'
                aria-label='Close'
                onClick={()=>clickOnbutton()}
              > <i className="fa-solid fa-xmark fs-2 text-white" ></i> </button>
            </div>
            <div className='modal-body'>
              <div className='col-lg-12 col-xl-12 d-flex flex-nowrap mb-5'>
                <div className='col-2'>
                  <div className='thumbnail-box'>
                    <img
                        style={{border: '1px solid #555'}}
                        src={dataGI?.user?.profile ? `${apiUrl}/containers/userprofile/download/${dataGI?.user?.profile}` : `${toAbsoluteUrl('./media/avatars/default-user.png')}`} 
                        alt={`Not Found.`}
                        onError={({currentTarget}: any) => {
                          currentTarget.onerror = null // prevents looping
                          currentTarget.src = `${toAbsoluteUrl('./media/avatars/default-user.png')}`
                        }}
                        className='thumbnail-img'
                      />
                  </div>
                </div>
                <div className='col-8 ps-3'>
                  <p className='mb-2 fw-bold' data-bs-toggle='modal' data-bs-target={`#index_detail_modal${i}`}> {dataGI?.user?.name } </p>
                  <p className='mb-2 fw-semibold '> {dataGI?.user?.companyname} </p>
                </div>

                <Button type='button'>
                  <h5 className='fw-semibold fe-6 text-center text-white'>{dataGI.total_points}</h5>
                  <p className='fw-normal fe-7 mb-0 text-center'> Point </p>
                </Button>
              </div>

              <hr className="mt-15 mb-8" />

              <div className="row">
                <div className="col-xl-6 col-md-6 col-sm-12 leader-perform">
                  <div className="leader_box">
                    <button type="button" className="thanks_count" data-bs-toggle="modal" data-bs-target="#exampleModal">
                      {dataGI.present_count}
                      </button>
                    <p className="mt-3 fw-6">  Attendance </p>
                  </div>
                </div>
                <div className="col-xl-6 col-md-6 col-sm-12 leader-perform">
                  <div className="leader_box">
                    <button type="button" className="thanks_count" data-bs-toggle="modal" data-bs-target="#exampleModal">
                      {dataGI.thankyou_given}
                      </button>
                    <p className="mt-3 fw-6">  Thank Card Given </p>
                  </div>
                </div>
                <div className="col-xl-6 col-md-6 col-sm-12 leader-perform">
                  <div className="leader_box">
                    <button type="button" className="thanks_count" data-bs-toggle="modal" data-bs-target="#exampleModal">
                      {dataGI.visitors_joined}
                      </button>
                    <p className="mt-3 fw-6">  Members Joined </p>
                  </div>
                </div>
                <div className="col-xl-6 col-md-6 col-sm-12 leader-perform">
                  <div className="leader_box">
                    <button type="button" className="thanks_count" data-bs-toggle="modal" data-bs-target="#exampleModal">
                      {dataGI?.feedbackgiven_count}
                      </button>
                    <p className="mt-3 fw-6"> Feedback Given </p>
                  </div>
                </div>
                <div className="col-xl-6 col-md-6 col-sm-12 leader-perform">
                  <div className="leader_box">
                    <button type="button" className="thanks_count" data-bs-toggle="modal" data-bs-target="#exampleModal">
                      {dataGI?.thankyou_received}
                      </button>
                    <p className="mt-3 fw-6"> Thank You Received </p>
                  </div>
                </div>
                <div className="col-xl-6 col-md-6 col-sm-12 leader-perform">
                  <div className="leader_box">
                    <button type="button" className="thanks_count" data-bs-toggle="modal" data-bs-target="#exampleModal">
                      {dataGI?.group_meeting_attended}
                      </button>
                    <p className="mt-3 fw-6"> Group Meeting Attended </p>
                  </div>
                </div>
                <div className="col-xl-6 col-md-6 col-sm-12 leader-perform">
                  <div className="leader_box">
                    <button type="button" className="thanks_count" data-bs-toggle="modal" data-bs-target="#exampleModal">
                      {dataGI?.mr_tracking}
                      </button>
                    <p className="mt-3 fw-6"> MR Tracking </p>
                  </div>
                </div>
                <div className="col-xl-6 col-md-6 col-sm-12 leader-perform">
                  <div className="leader_box">
                    <button type="button" className="thanks_count" data-bs-toggle="modal" data-bs-target="#exampleModal">
                      {dataGI?.visitors_invited}
                      </button>
                    <p className="mt-3 fw-6"> Visitors Invited </p>
                  </div>
                </div>
                <div className="col-xl-6 col-md-6 col-sm-12 leader-perform">
                  <div className="leader_box">
                    <button type="button" className="thanks_count" data-bs-toggle="modal" data-bs-target="#exampleModal">
                      {dataGI?.icaniwill_count}
                      </button>
                    <p className="mt-3 fw-6"> I Can I Will </p>
                  </div>
                </div>        
                <div className="col-xl-6 col-md-6 col-sm-12 leader-perform">
                  <div className="leader_box">
                    <button type="button" className="thanks_count" data-bs-toggle="modal" data-bs-target="#exampleModal">
                      {dataGI?.tracksheet_count}
                      </button>
                    <p className="mt-3 fw-6"> Track Sheet </p>
                  </div>
                </div>
                <div className="col-xl-6 col-md-6 col-sm-12 leader-perform">
                  <div className="leader_box">
                    <button type="button" className="thanks_count" data-bs-toggle="modal" data-bs-target="#exampleModal">
                     {dataGI?.visitors_attended}
                      </button>
                    <p className="mt-3 fw-6"> Visitors Attended </p>
                  </div>
                </div>
                {/* <div className="col-xl-6 col-md-6 col-sm-12 leader-perform">
                  <div className="leader_box"><button type="button" className="thanks_count" data-bs-toggle="modal" data-bs-target="#exampleModal">12</button></div>
                </div> */}
              </div>

            </div>
          </div>
        </div>
      </div>



    </>

  )

}

export default IndexDetails