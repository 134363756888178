import { useFormik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import * as Yup from 'yup'
import { createLibraryViewMedia } from "./librarymanagements-list/core/_requests";
import { UserContext } from "../../../contextStore";
import axios from "axios";
import { apiUrl } from "../../../apiurl";

const API_URL = apiUrl
const IMAGE_UPLOAD = `${API_URL}/containers/public/upload`
const AddLibraryDetails = ({show,handleset,id,fetchviewById}:any) => {
    const { userDetail, setMessage, reload, chapterID } = useContext(UserContext)
    const [fileObject,setFileObject] =useState<any>(null)
    const [link, setlink ]=useState<any>('youtubelink')
    let Id = id
    const editLibrarymanagementSchema = Yup.object().shape({
        description: Yup.string()
          .min(3, 'Title must be more than 2 characters.')
          .max(250, 'Title must be less than 250 characters.')
          .required('Please enter description.'),
        file : Yup.string().required("required")
      })

      const formik = useFormik({
        initialValues: {description:'',file:''},
        validationSchema: editLibrarymanagementSchema,
        onSubmit: async (values, {setSubmitting,resetForm}) => {
          setSubmitting(true)
          try {
              values={...values ,...{isactive:1,isdelete:0}}
            
              
              if(link == "youtubelink"){
                values={...values ,...{isactive:1,isdelete:0,type:3,post_id :Id}}
                console.log("values",values)
                // values.file="https://www.youtube.com/watch?v=kXHiIxx2atA"
                 const res =  await createLibraryViewMedia(values).then(async (res: any) => {
                  handleset(false)
                  resetForm()
                   await fetchviewById()
                  setMessage('success', 'Library details upload successfully')
                  
                })
                .catch((error: any) => {
                  console.log("error",error)

                  setMessage('error', 'Something Went Wrong')
                })


              }
              if(link == "pdf"){
                const formData = new FormData()
                  formData.append('file', fileObject)
                  const response = await axios
                  .post(`${IMAGE_UPLOAD}`, formData, {
                    headers: {
                      'Content-Type': 'multipart/form-data',
                    },
                  }).then( async (resMedia: any)=>{
                    values.file = resMedia?.data?.result?.files?.file[0]?.name
                    values={...values ,...{isactive:1,isdelete:0,type:1,post_id :Id}}
                    console.log("values",values)
                    const res =  await createLibraryViewMedia(values).then(async (res: any) => {
                      handleset(false)
                      resetForm()
                      fetchviewById()
                      setMessage('success', 'Library details upload successfully')
                    })
                    .catch((error: any) => {
                      console.log("error",error)
                      setMessage('error', 'Something Went Wrong')
                    })
                  }).catch((error:any)=>{
                    console.log("error",error)
                    setMessage('error', 'Something Went Wrong')
                  })

              }
            
          } catch (ex) {
            console.error(ex)
          } finally {
            setSubmitting(true)
            // cancel(true)
          }
        },
      })

      const onChangeHandler = (event: any) => {
        const file = event.target.files[0]
        if (file) {
          var pattern = /image-*/
          // setSelectedImage(file.name)
          if (!file.type.match(pattern) && file.type !== 'application/pdf') {
            setMessage('error', 'Invalid format, only Images and Pdf !')
            return
          }
          formik.setFieldValue("file",file.name)
          setFileObject(file)
        //   setImage(URL.createObjectURL(event.target.files[0]))
        } else {
        //   setImageName(null)
        //   setImageName(null)
        }
      }


      useEffect(()=>{
        formik.setFieldValue("file",'')
        formik.resetForm()
      },[link])

    return (
      <>
        <Modal show={show} onHide={handleset}>
          <Modal.Header closeButton>
            <h5 className='modal-title text-primary fs-4 fw-bold' id='exampleModalLabel'>
              {' '}
              Add Details{' '}
            </h5>
          </Modal.Header>
          <form action='' onSubmit={formik.handleSubmit} noValidate >
            <div className='row mx-0  align-items-center my-5 px-3 '>
              <div className='d-flex mb-8'>
                <div className=' me-5 form-check'>
                  <input
                    className='form-check-input'
                    type='radio'
                    name='flexRadioDefault'
                    id='flexRadioDefault1'
                    checked={link == 'youtubelink'}
                    value={'youtubelink'}
                    onChange={(e: any) => {
                      setlink(e.target.value)
                    }}
                  />
                  <label className='form-check-label'>Youtubelink</label>
                </div>
                <div className='form-check'>
                  <input
                    className='form-check-input'
                    type='radio'
                    name='flexRadioDefault'
                    id='flexRadioDefault2'
                    checked={link == 'pdf'}
                    value={'pdf'}
                    onChange={(e: any) => {
                      setlink(e.target.value)
                    }}
                  />
                  <label className='form-check-label'>PDF</label>
                </div>
              </div>
              {link == 'pdf' ? (
                <div className='col-sm-12 col-lg-12 mb-3'>
                  <h5 className='text-blk fs-5 fw-bold mb-3'>
                    {' '}
                    Add Attachment{' '}
                    <span>
                      {' '}
                      <i
                        className='fa-solid text-primary fs-4 fa-circle-info'
                        title='(Supported files: .pdf, Max File Size: 10MB)'
                      ></i>{' '}
                    </span>
                  </h5>
                  <input type='file' className='form-control' accept='application/pdf,' placeholder='Seleted the File 'onChange={onChangeHandler} />
                  {formik.touched.file && formik.errors.file && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.file == "required" && "Please choose pdf file!"}</span>
                      </div>
                    </div>
                  )}
                </div>
              ) : (
                ''
              )}
              {link == 'youtubelink' ? (
                <div className='col-sm-12 col-lg-12 mb-3'>
                  {' '}
                  <input
                    type='text'
                    className='form-control'
                    placeholder='Enter Youtube Link'
                    value={formik?.values?.file}
                    onChange={(event: any) => {
                    formik.setFieldValue('file', event.target.value.trimStart())
                    }}
                  />{' '}
                  {formik.touched.file && formik.errors.file && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.file == "required" && "Please enter YouTube URL !"}</span>
                      </div>
                    </div>
                  )}
                </div>
              ) : (
                ''
              )}

              <div className='col-sm-12 col-lg-12 mb-5'>
                {' '}
                <input
                  type='text'
                  className='form-control'
                  placeholder='Enter description'
                  value={formik?.values?.description}
                  onChange={(event: any) => {
                    formik.setFieldValue('description', event.target.value.trimStart())
                  }}

                />{' '}
                {formik.touched.description && formik.errors.description && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.description}</span>
                      </div>
                    </div>
                  )}
              </div>
              
              <div className='col-md-12 col-lg-12 text-right'>
                <button type='submit' className='btn btn-primary waves-effect waves-light btn-sm'>
                  {' '}
                  Submit{' '}
                </button>
              </div>
            </div>
          </form>
          <Modal.Footer>
            {/* <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button> */}
          </Modal.Footer>
        </Modal>
        {/* <div className="modal fade" id="addlibrarydetail" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title text-primary fs-4 fw-bold" id="exampleModalLabel"> Add Details </h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>

                        <form action="">


                            <div className="row mx-0  align-items-center my-5 px-3 ">
                                <div className="d-flex mb-8">
                                    <div className=" me-5 form-check">
                                        <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" checked={link == 'youtubelink'} value={'youtubelink'} onChange={(e:any)=>{setlink(e.target.value)}}/>
                                        <label className="form-check-label" >
                                            Youtubelink
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" checked={link == 'pdf'} value={'pdf'} onChange={(e:any)=>{setlink(e.target.value)}} />
                                        <label className="form-check-label" >
                                            PDF
                                        </label>
                                    </div>
                                </div>
                                {link == 'pdf' ? 
                                <div className="col-sm-12 col-lg-12 mb-3">
                                    <h5 className="text-blk fs-5 fw-bold mb-3"> Add Attachment  <span> <i className="fa-solid text-primary fs-4 fa-circle-info" title="(Supported files: .pdf, Max File Size: 10MB)"></i> </span></h5>
                                    <input type="file" className="form-control" placeholder="Enter section title" /> 
                                </div>: ""}
                                {link == 'youtubelink' ?
                                <div className="col-sm-12 col-lg-12 mb-3"> <input type="text" className="form-control" placeholder="Enter section title" /> </div>: ''}

                                <div className="col-sm-12 col-lg-12 mb-5"> <input type="text" className="form-control" placeholder="Enter section title" /> </div>
                                <div className="col-md-12 col-lg-12 text-center">
                                    <button type="submit" className="btn btn-primary waves-effect waves-light btn-sm" > Submit </button>
                                </div>
                            </div>
                        </form>

                    </div>
                </div>
            </div> */}
      </>
    )

}

export default AddLibraryDetails