/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useState} from 'react'
// import {initialQueryState, KTIcon, useDebounce} from '../../../../../../../_metronic/helpers'
import {useQueryRequest} from '../../core/QueryRequestProvider'
import { KTIcon, initialQueryState, useDebounce } from '../../../../../../_metronic/helpers'

const LibrarymanagementsListSearchComponent = () => {
  const {updateState,state} = useQueryRequest()
  const [searchTerm, setSearchTerm] = useState<string>('')
  // Debounce search term so that it only gives us latest value ...
  // ... if searchTerm has not been updated within last 500ms.
  // The goal is to only have the API call fire when librarymanagement stops typing ...
  // ... so that we aren't hitting our API rapidly.
  const debouncedSearchTerm = useDebounce(searchTerm, 150)
  // Effect for API call
  useEffect(
    () => {
      if (debouncedSearchTerm !== undefined && searchTerm !== undefined) {
        updateState({search: debouncedSearchTerm, ...initialQueryState})
      }
    },
    [debouncedSearchTerm] // Only call effect if debounced search term changes
    // More details about useDebounce: https://usehooks.com/useDebounce/
  )
  console.log("state",state)
  return (
    <div className='card-title'>
      {/* begin::Search */}
      {/* <div className="row mx-0 justify-content-end align-items-center mb-5 mt-6 fs-5 ">
        <div className="col-md-12 col-lg-6 text-end" >Search: </div>
        <div className="ps-2  col-md-12 col-lg-6"> 
        <input type="search" className="form-control" value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)} placeholder="Search library section here" /> </div>
      </div> */}
      {/* <div className='d-flex align-items-center position-relative my-1'>
        <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-6' />
        <input
          type='text'
          data-kt-librarymanagement-table-filter='search'
          className='form-control form-control-solid w-250px ps-14'
          placeholder='Search librarymanagement'
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div> */}
      <div className="row mx-0 justify-content-end align-items-center mb-5 mt-6 fs-5 ">
         <div className="col-sm-4 col-md-6 col-lg-4 text-end sm-text-start" >Search: </div>
          <div className="ps-2 col-sm-8 col-md-6 col-lg-4">
            <input type="search" className="form-control"placeholder='Search'
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)} />
          </div>
    </div>
      {/* end::Search */}
    </div>
  )
}

export {LibrarymanagementsListSearchComponent}
