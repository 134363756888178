import {useQuery} from 'react-query'
import {SessionattendanceEditModalForm} from './SessionattendanceEditModalForm'
// import {isNotEmpty, QUERIES} from '../../../../../../_metronic/helpers'
import {useListView} from '../core/ListViewProvider'
import { QUERIES, isNotEmpty } from '../../../../../_metronic/helpers'
import { getSessionattendanceById } from '../core/_requests'
// import { getSessionattendanceById } from '../../../apps/sessionattendance-management/sessionattendances-list/core/_requests'
// import {getSessionattendanceById} from '../core/_requests'

const SessionattendanceEditModalFormWrapper = () => {
  const {itemIdForUpdate, setItemIdForUpdate} = useListView()
  const enabledQuery: boolean = isNotEmpty(itemIdForUpdate)
  const {
    isLoading,
    data: sessionattendance,
    error,
  } = useQuery(
    `${QUERIES.SESSIONATTENDANCES_LIST}-sessionattendance-${itemIdForUpdate}`,
    () => {
      return getSessionattendanceById(itemIdForUpdate)
    },
    {
      cacheTime: 0,
      enabled: enabledQuery,
      onError: (err) => {
        setItemIdForUpdate(undefined)
        console.error(err)
      },
    }
  )

  if (!itemIdForUpdate) {
    return <SessionattendanceEditModalForm isSessionattendanceLoading={isLoading} sessionattendance={{id: undefined}} />
  }

  if (!isLoading && !error && sessionattendance) {
    return <SessionattendanceEditModalForm isSessionattendanceLoading={isLoading} sessionattendance={sessionattendance} />
  }

  return null
}

export {SessionattendanceEditModalFormWrapper}
