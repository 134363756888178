import {useQuery} from 'react-query'
import {TemplateEditModalForm} from './TemplateEditModalForm'
// import {isNotEmpty, QUERIES} from '../../../../../../_metronic/helpers'
import {useListView} from '../core/ListViewProvider'
// import { QUERIES, isNotEmpty } from '../../../../../_metronic/helpers'
import { getTemplateById } from '../core/_requests'
import { QUERIES, isNotEmpty } from '../../../../../../_metronic/helpers'
// import { getTemplateById } from '../../../apps/template-management/templates-list/core/_requests'
// import {getTemplateById} from '../core/_requests'

const TemplateEditModalFormWrapper = () => {
  const {itemIdForUpdate, setItemIdForUpdate} = useListView()
  const enabledQuery: boolean = isNotEmpty(itemIdForUpdate)
  const {
    isLoading,
    data: template,
    error,
  } = useQuery(
    `${QUERIES.TEMPLATES_LIST}-template-${itemIdForUpdate}`,
    () => {
      return getTemplateById(itemIdForUpdate)
    },
    {
      cacheTime: 0,
      enabled: enabledQuery,
      onError: (err) => {
        setItemIdForUpdate(undefined)
        console.error(err)
      },
    }
  )

  if (!itemIdForUpdate) {
    return <TemplateEditModalForm isTemplateLoading={isLoading} template={{id: undefined}} />
  }

  if (!isLoading && !error && template) {
    return <TemplateEditModalForm isTemplateLoading={isLoading} template={template} />
  }

  return null
}

export {TemplateEditModalFormWrapper}
