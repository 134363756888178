export interface MessageModel {
  user: number
  type: 'in' | 'out'
  text: string
  time: string
  template?: boolean
}

const defaultMessages: Array<MessageModel> = [
  {
    user: 4,
    type: 'in',
    text: 'How likely are you to recommend our company to your friends and family ?',
    time: '2 mins',
  },
  {
    user: 2,
    type: 'out',
    text: 'Hey there, we’re just writing to let you know that you’ve been subscribed to a repository on GitHub.',
    time: '5 mins',
  },
  {
    user: 4,
    type: 'in',
    text: 'Ok, Understood!',
    time: '1 Hour',
  },
  {
    user: 2,
    type: 'out',
    text: 'You’ll receive notifications for all issues, pull requests!',
    time: '2 Hours',
  },
  {
    user: 4,
    type: 'in',
    text: 'You can unwatch this repository immediately by clicking here: <a href="https://keenthemes.com">Keenthemes.com</a>',
    time: '3 Hours',
  },
  {
    user: 2,
    type: 'out',
    text: 'Most purchased Business courses during this sale!',
    time: '4 Hours',
  },
  {
    user: 4,
    type: 'in',
    text: 'Company BBQ to celebrate the last quater achievements and goals. Food and drinks provided',
    time: '5 Hours',
  },
  {
    template: true,
    user: 2,
    type: 'out',
    text: '',
    time: 'Just now',
  },
  {
    template: true,
    user: 4,
    type: 'in',
    text: 'Right before vacation season we have the next Big Deal for you.',
    time: 'Just now',
  },
]

export interface UserInfoModel {
  initials?: {label: string; state: 'warning' | 'danger' | 'primary' | 'success' | 'info'}
  name: string
  avatar?: string
  email: string
  position: string
  online: boolean
}

const defaultUserInfos: Array<UserInfoModel> = [
  {
    name: 'Emma Smith',
    avatar: 'avatars/300-6.jpg',
    email: 'e.smith@kpmg.com.au',
    position: 'Art Director',
    online: false,
  },
  {
    name: 'Melody Macy',
    initials: {label: 'M', state: 'danger'},
    email: 'melody@altbox.com',
    position: 'Marketing Analytic',
    online: true,
  },
  {
    name: 'Max Smith',
    avatar: 'avatars/300-1.jpg',
    email: 'max@kt.com',
    position: 'Software Enginer',
    online: false,
  },
  {
    name: 'Sean Bean',
    avatar: 'avatars/300-5.jpg',
    email: 'sean@dellito.com',
    position: 'Web Developer',
    online: false,
  },
  {
    name: 'Brian Cox',
    avatar: 'avatars/300-25.jpg',
    email: 'brian@exchange.com',
    position: 'UI/UX Designer',
    online: false,
  },
  {
    name: 'Mikaela Collins',
    initials: {label: 'M', state: 'warning'},
    email: 'mikaela@pexcom.com',
    position: 'Head Of Marketing',
    online: true,
  },
  {
    name: 'Francis Mitcham',
    avatar: 'avatars/300-9.jpg',
    email: 'f.mitcham@kpmg.com.au',
    position: 'Software Arcitect',
    online: false,
  },

  {
    name: 'Olivia Wild',
    initials: {label: 'O', state: 'danger'},
    email: 'olivia@corpmail.com',
    position: 'System Admin',
    online: true,
  },
  {
    name: 'Neil Owen',
    initials: {label: 'N', state: 'primary'},
    email: 'owen.neil@gmail.com',
    position: 'Account Manager',
    online: true,
  },
  {
    name: 'Dan Wilson',
    avatar: 'avatars/300-23.jpg',
    email: 'dam@consilting.com',
    position: 'Web Desinger',
    online: false,
  },
  {
    name: 'Emma Bold',
    initials: {label: 'E', state: 'danger'},
    email: 'emma@intenso.com',
    position: 'Corporate Finance',
    online: true,
  },
  {
    name: 'Ana Crown',
    avatar: 'avatars/300-12.jpg',
    email: 'ana.cf@limtel.com',
    position: 'Customer Relationship',
    online: false,
  },
  {
    name: 'Robert Doe',
    initials: {label: 'A', state: 'info'},
    email: 'robert@benko.com',
    position: 'Marketing Executive',
    online: true,
  },
  {
    name: 'John Miller',
    avatar: 'avatars/300-13.jpg',
    email: 'miller@mapple.com',
    position: 'Project Manager',
    online: false,
  },
  {
    name: 'Lucy Kunic',
    initials: {label: 'L', state: 'success'},
    email: 'lucy.m@fentech.com',
    position: 'SEO Master',
    online: true,
  },
  {
    name: 'Ethan Wilder',
    avatar: 'avatars/300-21.jpg',
    email: 'ethan@loop.com.au',
    position: 'Accountant',
    online: true,
  },
]

const messageFromClient: MessageModel = {
  user: 4,
  type: 'in',
  text: 'Thank you for your awesome support!',
  time: 'Just now',
}

export interface AlertModel {
  title: string
  description: string
  time: string
  icon: string
  state: 'primary' | 'danger' | 'warning' | 'success' | 'info'
}

const defaultAlerts: Array<AlertModel> = [
  {
    title: 'Project Alice',
    description: 'Phase 1 development',
    time: '1 hr',
    icon: 'technology-2',
    state: 'primary',
  },
  {
    title: 'HR Confidential',
    description: 'Confidential staff documents',
    time: '2 hrs',
    icon: 'information-5',
    state: 'danger',
  },
  {
    title: 'Company HR',
    description: 'Corporeate staff profiles',
    time: '5 hrs',
    icon: 'map001',
    state: 'warning',
  },
  {
    title: 'Project Red',
    description: 'New frontend admin theme',
    time: '2 days',
    icon: 'cloud-change',
    state: 'success',
  },
  {
    title: 'Project Breafing',
    description: 'Product launch status update',
    time: '21 Jan',
    icon: 'compass',
    state: 'primary',
  },
  {
    title: 'Banner Assets',
    description: 'Collection of banner images',
    time: '21 Jan',
    icon: 'graph-3',
    state: 'info',
  },
  {
    title: 'Icon Assets',
    description: 'Collection of SVG icons',
    time: '20 March',
    icon: 'color-swatch',
    state: 'warning',
  },
]
export interface LogModel {
  code: string
  state: 'success' | 'danger' | 'warning'
  message: string
  time: string
}

const defaultLogs: Array<LogModel> = [
  {code: '200 OK', state: 'success', message: 'New order', time: 'Just now'},
  {code: '500 ERR', state: 'danger', message: 'New customer', time: '2 hrs'},
  {code: '200 OK', state: 'success', message: 'Payment process', time: '5 hrs'},
  {code: '300 WRN', state: 'warning', message: 'Search query', time: '2 days'},
  {code: '200 OK', state: 'success', message: 'API connection', time: '1 week'},
  {code: '200 OK', state: 'success', message: 'Database restore', time: 'Mar 5'},
  {code: '300 WRN', state: 'warning', message: 'System update', time: 'May 15'},
  {code: '300 WRN', state: 'warning', message: 'Server OS update', time: 'Apr 3'},
  {code: '300 WRN', state: 'warning', message: 'API rollback', time: 'Jun 30'},
  {code: '500 ERR', state: 'danger', message: 'Refund process', time: 'Jul 10'},
  {code: '500 ERR', state: 'danger', message: 'Withdrawal process', time: 'Sep 10'},
  {code: '500 ERR', state: 'danger', message: 'Mail tasks', time: 'Dec 10'},
]

export interface SummarysessionModel {
  leader_name?: string
  a?: string
  vi?: string
  va?: string
  vj?: string
  tg?: string
  tr?: string
  gma?: string
  tu?: string
  fg?: string
  h?: string
  iciw?: string
  points?: string
}

const defaultSummarysessions: Array<any> = [
  {
  leader_name: 'Aayushi Shah',
  a: '0',
  vi: '0',
  va: '0',
  vj: '0',
  tg: '0',
  tr: '0',
  gma: '0',
  tu: '0',
  fg: '0',
  h: '0',
  iciw: '0',
  points: '0'
  },
  {
    leader_name: 'Ajay Thakkar',
    a: '5',
    vi: '0',
    va: '0',
    vj: '0',
    tg: '0',
    tr: '1',
    gma: '5',
    tu: '0',
    fg: '0',
    h: '0',
    iciw: '0',
    points: '100'
    },
    {
      leader_name: 'Akash Goyal',
      a: '9',
      vi: '6',
      va: '0',
      vj: '0',
      tg: '3',
      tr: '14',
      gma: '0',
      tu: '0',
      fg: '2',
      h: '0',
      iciw: '0',
      points: '100'
      },
      {
        leader_name: 'Amit Makhija',
        a: '17',
        vi: '23',
        va: '6',
        vj: '0',
        tg: '19',
        tr: '26',
        gma: '18',
        tu: '58',
        fg: '14',
        h: '3',
        iciw: '86',
        points: '494'
        }
       
  
]


export interface SessionattendanceModel {
  chapter_name?: string
  leader_name?: string
  event_date?: string
  present_time?: string
}

const defaultSessionattendances: Array<any> = [
  {
  chapter_name: 'Eklavya',
  leader_name: 'Sautik Shah',
  event_date: '27-09-2019',
  present_time: '08:00:00 AM',
  },
  
  {
    chapter_name: 'Eklavya',
    leader_name: 'Yogesh Khetan',
    event_date: '19-02-2021',
    present_time: 'Not Available',
    },
    {
      chapter_name: 'Eklavya',
      leader_name: 'Yashica Ahuja',
      event_date: '19-02-2021',
      present_time: 'Not Available',
      },
      {
        chapter_name: 'Eklavya',
        leader_name: 'Vivek Harsora',
        event_date: '19-02-2021',
        present_time: 'Not Available',
        },
        {
          chapter_name: 'Eklavya',
          leader_name: 'Vipul gandhi',
          event_date: '19-02-2021',
          present_time: 'Not Available',
          },
          {
            chapter_name: 'Eklavya',
            leader_name: 'Tejas Shukla',
            event_date: '19-02-2021',
            present_time: 'Not Available',
            },
  
]

export interface MonthlyReportModel {
  leader_name?: string
  a?: string
  vi?: string
  va?: string
  vj?: string
  tg?: string
  tr?: string
  gma?: string
  tu?: string
  fg?: string
  h?: string
  iciw?: string
  mr?: string
  points?: string
}

const defaultMonthlyreports: Array<any> = [
  {
    leader_name: 'Aayushi Shah',
    a: '0',
    vi: '0',
    va: '0',
    vj: '0',
    tg: '0',
    tr: '0',
    gma: '0',
    tu: '0',
    fg: '0',
    h: '0',
    iciw: '0',
    mr: '0',
    points: '0'
    },
    {
      leader_name: 'Ajay Thakkar',
      a: '5',
      vi: '0',
      va: '0',
      vj: '0',
      tg: '0',
      tr: '1',
      gma: '5',
      tu: '0',
      fg: '0',
      h: '0',
      iciw: '0',
      mr: '0',
      points: '100'
      },
      {
        leader_name: 'Akash Goyal',
        a: '9',
        vi: '6',
        va: '0',
        vj: '0',
        tg: '3',
        tr: '14',
        gma: '0',
        tu: '0',
        fg: '2',
        h: '0',
        iciw: '0',
        mr: '0',
        points: '100'
        },
        {
          leader_name: 'Amit Makhija',
          a: '17',
          vi: '23',
          va: '6',
          vj: '0',
          tg: '19',
          tr: '26',
          gma: '18',
          tu: '58',
          fg: '14',
          h: '3',
          iciw: '86',
          mr: '0',
          points: '494'
          }
]

export interface VisitorpanelModel {
  event_date?: string
  invitee_name?: string
  invited_by?: string
  cell_no?: string
  company?: string
  chapter?: string
}

const defaultVisitorpanels: Array<any> = [
 
  
  {
    event_date: '02-12-2022',
    invitee_name: 'abc',
    invited_by: 'Juhul Shah',
    cell_no: '9714270333',
    company: 'Business Connect',
    chapter: 'Eklavya'
  },

  {
    event_date: '02-12-2022',
    invitee_name: '	Ambulance',
    invited_by: 'Juhul Shah',
    cell_no: '102',
    company: 'Demo',
    chapter: 'Eklavya'
  },

      {
    event_date: '07-02-2022',
    invitee_name: '	aaaa',
    invited_by: '	Nirav Agravat',
    cell_no: '23232323',
    company: '',
    chapter: 'Eklavya'
  },

      {
    event_date: '07-02-2022',
    invitee_name: 'vshsbsbh',
    invited_by: 'Juhul Shah',
    cell_no: '36377588436',
    company: '',
    chapter: 'Eklavya'
  },
  {
    event_date: '07-02-2022',
    invitee_name: '	darshak',
    invited_by: 'Juhul Shah',
    cell_no: '12345667',
    company: '',
    chapter: 'Eklavya'
  },
  {
    event_date: '07-02-2022',
    invitee_name: 'cfghh',
    invited_by: 'Juhul Shah',
    cell_no: '467755',
    company: '',
    chapter: 'Eklavya'
  },
  
]

export interface EventModel {
  event_name?:string
  event_date?:string
  chapter?:string
  venue?:string
  address?:string
}

const defaultEvents: Array<any> = [
 
  
  {
  event_name:'Test Event',
  event_date:'26-07-2023',
  chapter:'Eklavya',
  venue:'Zoom',
  address:''
  },

  {
    event_name:'Test Event',
    event_date:'16-12-2022',
    chapter:'Eklavya',
    venue:'Zoom',
    address:''
    },

    {
      event_name:'Test Event',
      event_date:'02-12-2022',
      chapter:'Eklavya',
      venue:'Zoom',
      address:''
      },

      {
        event_name:'Test Event',
        event_date:'18-11-2022',
        chapter:'Eklavya',
        venue:'Zoom',
        address:''
        },

  {
  event_name:'Test Event',
  event_date:'04-11-2022',
  chapter:'Eklavya',
  venue:'Zoom',
  address:''
  },
  {
    event_name:'Test Event',
    event_date:'21-10-2022',
    chapter:'Eklavya',
    venue:'Zoom',
    address:''
    },
  
]


export interface GroupmeetingModel {
  group_name?:string
  meeting_date?:string
  captain?:string
}

const defaultGroupmeetings: Array<any> = [
 
  
  {
  meeting_date:'16-11-2022',
  group_name:'Arjun',
  captain:'Juhul Shah',

  },

  {
    meeting_date:'16-11-2022',
    group_name:'Arjun',
    captain:'Juhul Shah',
  
    },

    {
      meeting_date:'16-11-2022',
      group_name:'Arjun',
      captain:'Juhul Shah',
    
      },

      {
        meeting_date:'15-11-2022',
        group_name:'',
        captain:'Juhul Shah',
      
        },
        {
          meeting_date:'15-11-2022',
          group_name:'',
          captain:'Juhul Shah',
        
          }
]

export interface GroupmanagementModel {
  group_name?:string
  chapter_name?:string
  
}

const defaultGroupmanagements: Array<any> = [
 
  
  {

  group_name:'Testing',
  chapter_name:'Eklavya',

  },

  {
    group_name:'Test Group',
    chapter_name:'Juhul Shah',
  
    },

    {
      group_name:'Sufalamtech',
      chapter_name:'Juhul Shah',
    
      },

      {
        group_name:'Pawan Test',
        chapter_name:'Juhul Shah',
      
        },
        {
          group_name:'Achievers',
          chapter_name:'Juhul Shah',
        
          }
  

]


export interface GroupchatModel {
  group_name?:string
  chapter_name?:string
  
}

const defaultGroupchats: Array<any> = [
 
  
  {

  group_name:'Testing',
  chapter_name:'Eklavya',

  },

  {
    group_name:'Test Group',
    chapter_name:'Juhul Shah',
  
    },

    {
      group_name:'Sufalamtech',
      chapter_name:'Juhul Shah',
    
      },

      {
        group_name:'Pawan Test',
        chapter_name:'Juhul Shah',
      
        },
        {
          group_name:'Achievers',
          chapter_name:'Juhul Shah',
        
          }
  

]




export interface LibrarymanagementModel {
  title?:string
  
}

const defaultLibrarymanagements: Array<any> = [
 
  
  {
  title:'Tester',
  },

  {
    title:'VI',
    },

    {
      title:'TYCA',
      },

      {
        title:'ILEAD Sampark Presentation',
        },
        {
          title:'VI Sessions',
          }
  

]



export interface TemplateModel {
  sr_no?:string,
  template_name?:string
  
}

const defaultTemplates: Array<any> = [
 
  
  {
    sr_no:'1',
    template_name:'General'
  },

  {
    sr_no:'2',
    template_name:'General'
  },

  {
    sr_no:'3',
    template_name:'General'
  },
  {
    sr_no:'4',
    template_name:'General'
  },
  {
    sr_no:'5',
    template_name:'General'
  },

]



export interface NotificationModel {
  leader_name?:string,
  template_type?:string,
  notification_type?:string,
  send_date?:string
  
}

const defaultNotifications: Array<any> = [
 
  
  {
    leader_name:'Nirav Agravat',
  template_type:'	General',
  notification_type:'	SMS',
  send_date:'22-3-2022'
  },

  {
    leader_name:'Milap Gajjar',
  template_type:'General',
  notification_type:'SMS',
  send_date:'19-01-2022'
  },
  {
    leader_name:'Aiyub Panara',
  template_type:'General',
  notification_type:'Email',
  send_date:'07-11-2021'
  },
  {
    leader_name:'Raj Jangid',
  template_type:'General',
  notification_type:'Email',
  send_date:'25-10-2021'
  },
  {
    leader_name:'Pawan Garg',
  template_type:'General',
  notification_type:'SMS',
  send_date:'28-08-2021'
  },

]


export interface ReminderModel {
  title?:string,
  notification_type?:string
}

export interface ChapterModel {
  title?:string,
  notification_type?:string
}

const defaultReminders: Array<any> = [
 
  
  {
    title:'Test Event',
  notification_type:'	SMS'
  },

  {
    title:'Push Notification',
  notification_type:'SMS'
  },

  {
    title:'Event Check',
   notification_type:'Email',
  },
  {
    title:'Test Event',
  notification_type:'Email',
  },
  {
    title:'Push Notification',
  notification_type:'SMS',
  }

]

const defaultChapters: Array<any> = [
 
  
  {
    title:'Test Event',
  notification_type:'	SMS'
  },

  {
    title:'Push Notification',
  notification_type:'SMS'
  },

  {
    title:'Event Check',
   notification_type:'Email',
  },
  {
    title:'Test Event',
  notification_type:'Email',
  },
  {
    title:'Push Notification',
  notification_type:'SMS',
  }

]



export interface ExitinguserModel {
  chapter_name?:string,
  leader_name?:string,
  mobile?:string,
  email?:string,
  company_name?:string,
  otp?:string,
  active?:string,
  
}

const defaultExitingusers: Array<any> = [
 
  
  {
  chapter_name:'Eklavya',
  leader_name:'Snehal Thacker',
  mobile:'9725809123',
  email:'rivaapparels@gmail.com',
  company_name:'RIVA APPARELS INC.',
  otp:'15965',
  active:'Yes'
  },

  {
    chapter_name:'Eklavya',
    leader_name:'Manan Dhruv Vyas',
    mobile:'9987014815',
    email:'manandhruv15@gmail.com',
    company_name:'Spectrum Food',
    otp:'41871',
    active:'Yes'
    },

    {
      chapter_name:'Eklavya',
      leader_name:'Gaurav Shah',
      mobile:'8200310991',
      email:'mustufa.shaikh@sufalamtech.com',
      company_name:'ICIWF',
      otp:'38793',
      active:'Yes'
      },

      {
        chapter_name:'Eklavya',
        leader_name:'Mahesh Raghunath Padhye',
        mobile:'9821283670',
        email:'sales@micronind.com',
        company_name:'MICRON INDUSTRIES PRIVATE LIMITED',
        otp:'43759',
        active:'Yes'
        },

        {
          chapter_name:'Eklavya',
          leader_name:'	Dhaval Dagla',
          mobile:'9998011922',
          email:'dhavaldagla@yahoo.in',
          company_name:'Dhaval packaging pvt ltd.',
          otp:'99920',
          active:'No'
          },

          {
            chapter_name:'Eklavya',
            leader_name:'Hitesh Shah',
            mobile:'9327502175',
            email:'hitesh.radhey10@gmail.com',
            company_name:'Shree Radhe Caterers',
            otp:'54529',
            active:'Yes'
            },

            {
              chapter_name:'Eklavya',
              leader_name:'Tejas Rajkotiya',
              mobile:'9737947999',
              email:'trajkotia@gmail.com',
              company_name:'RAJKAP CHEMICALS PRIVATE LIMITED',
              otp:'82746',
              active:'Yes'
              },

              {
                chapter_name:'Eklavya',
                leader_name:'Deep prakash Jain',
                mobile:'9924496963',
                email:'sunodeep@gmail.com',
                company_name:'Shyam Sunder Food Products LLP',
                otp:'90511',
                active:'No'
                },

                
]


export interface RequestuserModel {
  chapter_name?:string,
  leader_name?:string,
  mobile?:string,
  email?:string,
  company_name?:string,
  otp?:string,
  active?:string,
  
}

const defaultRequestusers: Array<any> = [
 
  
  {
  chapter_name:'Eklavya',
  leader_name:'Hardik',
  mobile:'9825200074',
  email:'hardik.colorimage@gmail.com',
  company_name:'Color Image',
  otp:'',
  active:'-'
  },

  {
    chapter_name:'Eklavya',
    leader_name:'Jalpan',
    mobile:'9825012377',
    email:'jalpan_109@yahoo.com',
    company_name:'Shree Navrang Jewellers',
    otp:'',
    active:'-'
    },

    {
      chapter_name:'Eklavya',
      leader_name:'Yogesh Khetan',
      mobile:'9820211982',
      email:'yogesh@omocreation.com',
      company_name:'OMO TEXTILES CREATION PVT LTD',
      otp:'',
      active:'-'
      },

      {
        chapter_name:'Eklavya',
        leader_name:'ANDREA FRAZIER',
        mobile:'2196112215',
        email:'FRAZIERANDREA21@GMAIL.COM',
        company_name:'Tender Care',
        otp:'',
        active:'-'
        },

        {
          chapter_name:'Eklavya',
          leader_name:'Himanshu',
          mobile:'8076668748',
          email:'h.kathlete1986@gmail.com',
          company_name:'Vidyut enterprises',
          otp:'',
          active:'-'
          },

          {
            chapter_name:'Eklavya',
            leader_name:'Rosemary Stingley',
            mobile:'7654049372',
            email:'stingleyrose@yahoo.com',
            company_name:'Safe Harbor',
            otp:'',
            active:'-'
            },

            {
              chapter_name:'Eklavya',
              leader_name:'	Zinzin Gandhi',
              mobile:'9898946946',
              email:'tapurjewels@yahoo.co.in',
              company_name:'Tapur jewels',
              otp:'',
              active:'-'
              },

              {
                chapter_name:'Eklavya',
                leader_name:'	Linda Pruett',
                mobile:'7655462066',
                email:'lindajpruett@gmail.com',
                company_name:'	Randolph Co YMCA Childcare',
                otp:'',
                active:'-'
                },

                
]

export interface defaultVisitorsModel {
  event_date?:string,
  name?:string,
  mobile?:string,
  email?:string,
  company_name?:string,
  
}

const defaultVisitors: Array<any> = [
 
  
  {
  event_date:'23-12-2023',
  name:'ChetanTest',
  mobile:'9825200074',
  email:'chetan.patil@sufalamtech.com',
  company_name:'Color Image',
  },

  {
    event_date:'07-02-2022',
    name:'aaaa',
    mobile:'9825012377',
    email:'jalpan_109@yahoo.com',
    company_name:'Shree Navrang Jewellers',
 
    },

    {
      event_date:'07-02-2022',
      name:'Yogesh Khetan',
      mobile:'9820211982',
      email:'yogesh@omocreation.com',
      company_name:'OMO TEXTILES CREATION PVT LTD',

      },

      {
        event_date:'07-01-2022',
        name:'ANDREA FRAZIER',
        mobile:'2196112215',
        email:'FRAZIERANDREA21@GMAIL.COM',
        company_name:'Tender Care',
        },

        {
          event_date:'07-01-2022',
          name:'Himanshu',
          mobile:'8076668748',
          email:'h.kathlete1986@gmail.com',
          company_name:'Vidyut enterprises',
          },

          {
            event_date:'15-05-2020',
            name:'Rosemary Stingley',
            mobile:'7654049372',
            email:'stingleyrose@yahoo.com',
            company_name:'Safe Harbor',

            },
          ]




export {defaultMessages, defaultUserInfos, defaultVisitorpanels, defaultAlerts, defaultLogs, messageFromClient, defaultSummarysessions, 
  defaultSessionattendances, defaultMonthlyreports, defaultEvents, defaultGroupmeetings, defaultGroupmanagements, defaultGroupchats, defaultLibrarymanagements,
  defaultTemplates, defaultNotifications, defaultChapters,defaultReminders, defaultExitingusers, defaultRequestusers, defaultVisitors}
