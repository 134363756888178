import axios from 'axios'
import { apiUrl } from '../../apiurl'


const API_URL = apiUrl
const ALL_ACTIVITY_POINT = `${API_URL}/monthlyleaderpoints/getLeaderActivityInDetail`

export function allActivityPoint(leaderId: any) {
  return axios
    .get<any>(
      `${ALL_ACTIVITY_POINT}?filter[where][leader_id]=${leaderId}`
    )
    .then((responce: any) => {
      return responce.data
    })
    .catch((error) => {
      console.error('Error All Post Data:', error)
      throw error
    })
}



