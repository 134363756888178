import {useQuery} from 'react-query'
import {GroupmanagementEditModalForm} from './GroupManagementEditModalForm'
// import {isNotEmpty, QUERIES} from '../../../../../../_metronic/helpers'
import {useListView} from '../core/ListViewProvider'
import { QUERIES, isNotEmpty } from '../../../../../_metronic/helpers'
import { getGroupmanagementById } from '../core/_requests'
// import { getGroupmanagementById } from '../../../apps/groupmanagement-management/groupmanagements-list/core/_requests'
// import {getGroupmanagementById} from '../core/_requests'

const GroupmanagementEditModalFormWrapper = () => {
  const {itemIdForUpdate, setItemIdForUpdate} = useListView()
  const enabledQuery: boolean = isNotEmpty(itemIdForUpdate)
  const {
    isLoading,
    data: groupmanagement,
    error,
  } = useQuery(
    `${QUERIES.GROUPMANAGEMENTS_LIST}-groupmanagement-${itemIdForUpdate}`,
    () => {
      return getGroupmanagementById(itemIdForUpdate)
    },
    {
      cacheTime: 0,
      enabled: enabledQuery,
      onError: (err) => {
        setItemIdForUpdate(undefined)
        console.error(err)
      },
    }
  )

  if (!itemIdForUpdate) {
    return <GroupmanagementEditModalForm isGroupmanagementLoading={isLoading} groupmanagement={{id: undefined}} />
  }

  if (!isLoading && !error && groupmanagement) {
    return <GroupmanagementEditModalForm isGroupmanagementLoading={isLoading} groupmanagement={groupmanagement} />
  }

  return null
}

export {GroupmanagementEditModalFormWrapper}
