import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import {Link} from 'react-router-dom'

export function BIGIStarMemberLeaderLoadingSkeleton() {
  return (
    <div className={`card card-flush post-card`}>
      <div className='card-header p-2 align-items-start'>
        <div className='d-flex align-items-start w-100'>
          <div className={'symbol symbol-md-55px me-3 '}>
            <Skeleton height='100%' containerClassName='avatar-skeleton w-100px h-100px d-block' />
          </div>
          <div className={'symbol symbol-md-55px me-3 '}>
            <Skeleton height='100%' containerClassName='avatar-skeleton w-100px h-100px d-block' />
          </div>
          <div className={'symbol symbol-md-55px me-3 '}>
            <Skeleton height='100%' containerClassName='avatar-skeleton w-100px h-100px d-block' />
          </div>

          <div className={'symbol symbol-md-55px me-3 '}>
            <Skeleton height='100%' containerClassName='avatar-skeleton w-100px h-100px d-block' />
          </div>

          <div className={'symbol symbol-md-55px me-3 '}>
            <Skeleton height='100%' containerClassName='avatar-skeleton w-100px h-100px d-block' />
          </div>
          
        </div>
      </div>
    </div>
  )
}
