import React, { useEffect } from "react";
// import { ZoomMtg } from "@zoomus/websdk";
import { useLocation, useParams } from "react-router-dom";

export default function Meeting({ play }: { play: boolean }) {
  const {id,pwd} = useParams()
  const {} = useLocation()
  const getUserDetails = () => {
    try{
      let user = localStorage.getItem('authUserDetail') as any
      if(user){
        user = JSON.parse(user) as any
        return {
          userName:user?.name ?? "",
          email:user?.email ?? ""
        } 
      }else{
        return {
          userName:"",
          email:""
        } 
      }
    }
    catch(err){
      return {
        userName:"",
        email:""
      }
    }
  }

  const addedLeaveButton = () => {
    try{
      let zoomLeaveBtn = document.getElementsByClassName('footer__leave-btn')[0] as any
      zoomLeaveBtn.onclick = function(){window.location.replace('/dashboard')}  
    }
    catch(err){
      console.log("Zoom Error",err)
    }
  }

  const detectBackButton = () => {
    try{
      // Add an event listener for the popstate event
      window.addEventListener('popstate', function(event) {
        // This event is triggered when the back or forward button is clicked.
        // You can perform your custom actions here.
        window.location.replace('/dashboard')
      });
    }
    catch(err){
      console.log("Detect Back Button Error",err)
    }
  }

  // const {id,pwd} = useParams()
  const joinMeeting = async () => {
    try {
      const { ZoomMtg } = await import("@zoomus/websdk");
      ZoomMtg.setZoomJSLib("https://source.zoom.us/2.17.0/lib", "/av");
      ZoomMtg.prepareWebSDK();
      ZoomMtg.preLoadWasm();

      ZoomMtg.generateSDKSignature({
        meetingNumber: id ?? "",
        role: "1",
        sdkKey: process.env.REACT_APP_ZOOM_SDK_KEY as any,
        sdkSecret: process.env.REACT_APP_ZOOM_SDK_SECRET as any,
        success: (signature:any) => {
          ZoomMtg.init({
            leaveUrl: window.location.origin,
            disablePreview: true,
            success: (initData:any) => {
              let zObj = {
                meetingNumber: id ?? "",
                signature: signature.result,
                userName: getUserDetails().userName,
                userEmail: getUserDetails().email,
                tk: "",
                sdkKey:process.env.REACT_APP_ZOOM_SDK_KEY,
                success: () => {
                  addedLeaveButton()
                  // Handle successful join
                },
                error: (joinError:any) => {
                  console.log("Join Error: ", joinError);
                },
              } as any
              // if(pwd){
              zObj.passWord = pwd ?? ""
              // }
              ZoomMtg.join(zObj);
            },
            error: (initError:any) => {
              console.log("Init Error: ", initError);
            },
          });
        },
        error: (signatureError:any) => {
          console.log("Signature Error: ", signatureError.message);
        },
      });
    } catch (error) {
      console.error("Error: ", error);
    }
  };
  useEffect(() => {
    if(id){
      joinMeeting();
      detectBackButton();
    }
    else{
      window.location.replace('/dashboard')
    }
    return () => {

    }
  }, []);
  return <div id="root-modals"></div>;
}